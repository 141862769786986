import React from 'react';
import ProfilePanel from './Profile';
import ResetPassPanel from './ResetPassword';
import CardManagementPanel from './CardMangement';
import SubscriptionPanel from './Subscription';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

type EditProfilePanelProps = {
	selectedOption: string;
};

const EditProfilePanel: React.FC<EditProfilePanelProps> = ({ selectedOption }) => {
	
	const stripePublishableKey			 = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY;
	const getStripePromise 					 = stripePublishableKey;
	const stripePromise							 = loadStripe(getStripePromise);
	
	return (
		<div className='min-md:ml-0 max-md:ml-0 min-md:w-full max-md:w-full min-sm:ml-0 max-sm:ml-0 min-sm:w-full max-sm:w-full w-3/4 ml-6 shadow-md bg-white'>
			{selectedOption === 'profile' ? 
				<ProfilePanel />
				:
				selectedOption === 'passwordReset' ? 
					<ResetPassPanel />
					:
					selectedOption === 'cardManagement' ? 
						<Elements stripe={stripePromise}>
							<CardManagementPanel />
						</Elements>

						
						:
						<SubscriptionPanel />
			}

		</div>
		
	);
};

export default EditProfilePanel;
