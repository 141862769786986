import React, { useState } from 'react';
import { IMAGES } from 'utility/constant';
import {
	ChevronDownIcon,
	ArrowLeftOnRectangleIcon,
} from '@heroicons/react/24/solid';
import { Notification } from 'assets/CustomizeApp/Features/TabIcons/FeaturesIcons';
interface DropdownOption {
	value: string;
	label: string;
}

interface DropdownProps {
	options: DropdownOption[];
	onSelect: (value: string) => void;
}

const ApproverTopbar = ({ options, onSelect }: DropdownProps) => {
	const [selectedOption, setSelectedOption] = useState<
		DropdownOption | undefined
	>(undefined);
	const [isOpen, setIsOpen] = useState(false);

	const handleOptionClick = (option: DropdownOption) => {
		setSelectedOption(option);
		setIsOpen(false);
		onSelect(option.value);
	};

	return (
		<div className='relative w-full inline-block'>
			<div className='w-full rounded-md py-2 cursor-pointer'>
				{selectedOption ? (
					selectedOption.label
				) : (
					<>
						<div className='flex px-3 items-center w-full text-xs text-[#464255]'>
							<Notification fill={'#ffffff'} className='w-6 h-6 mt-2' />
							<div className='relative w-[27px] h-[27px] ml-3'>
								{/* <img src={IMAGES.userIcon} alt='user-icon' /> */}
								<p className='border-2 border-white rounded-full w-8 h-8'> </p>
								{/* <span className='flex justify-center items-center absolute -bottom-1 -right-1 bg-white rounded-full'>
									<ChevronDownIcon className='w-[14px] h-[14px]' />
								</span> */}
							</div>
							<span
								onClick={() => setIsOpen(!isOpen)}
								className='pl-5 text-white font-bold'
							>
								Glenne Headly
							</span>
						</div>
					</>
				)}
			</div>
			{isOpen && (
				<ul className='w-3/4 list-none absolute z-30 top-full right-0 bg-white border border-gray-300 rounded-md shadow-md py-1'>
					{options.map((option) => (
						<li
							key={option.value}
							className='flex flex-row py-2 text-end cursor-pointer hover:bg-gray-200'
							onClick={() => handleOptionClick(option)}
						>
							<span className='w-1/4 ml-5'>
								<ArrowLeftOnRectangleIcon className='w-8 h-6' />
							</span>
							<span className='w-3/4 mr-5'>{option.label}</span>
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

export default ApproverTopbar;
