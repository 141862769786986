/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useRef } from 'react';
import { ArrowUpTrayIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { IMAGES, classNames } from 'utility';
import { showFailedAlert } from 'utility/alerts';
import { useAtom } from 'jotai';
import { SOgTagsImgID, SOgTagsPreviewImage, SOgTagsUploadFile } from 'store/CustomizeApp/Appearance/imagesAtoms/ogTagsAtoms';

const Ogtag:React.FC = () => {

	const ogtagInput 		 					 = useRef<HTMLInputElement>(null);
	const imageTypeRegex                         = /image\/(png|jpg|jpeg)/gm;
	const [previewOgTags, setPreviewOgTags]      = useAtom(SOgTagsPreviewImage);
	const [, setImgID] 							 = useAtom(SOgTagsImgID);
	const [, setImageFile]           			 = useAtom(SOgTagsUploadFile);

	const onClickUploadOgTagHandler = () => {
		ogtagInput.current?.click();
	};

	const handleSelectOgTag = (event: any) => {
		const file = event.target.files[0];
		const fileReader = new FileReader();
	
		fileReader.onload = (event : any) => {
			const image : any = new Image();
			image.src = event.target.result;
		
			if (file.type.match(imageTypeRegex)) {
				image.onload = () => {
					if (file.size <= (1048576 * 10)) {
						setImageFile(file);
						setPreviewOgTags(fileReader.result);
					} else if (file.size > (1048576 * 10)) {
						showFailedAlert('Please upload an image file that is less than 10MB in size.');
					}
				};
			} else{
				showFailedAlert('File must be JPEG or PNG.');
			}
		};
		fileReader.readAsDataURL(file);
	};

	return(
		<div className='flex flex-col'>
			<span className='text-[#707070] font-bold opacity-80 mb-3 text-lg'>OG images</span>
			{/* <div className='flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row gap-10 sm:flex-col max-sm:flex-col'> */}
			<div className='grid md:grid-flow-col grid-flow-row gap-5'>
				<div className='flex'>
					<div 
						onClick={onClickUploadOgTagHandler}
						className={classNames('w-[160px] h-[150px] rounded-sm border bg-[#F5F5F5] cursor-pointer flex items-center justify-center p-1')}>
						<input ref={ogtagInput} type='file' className='h-0 absolute hidden' accept='.jpg, .jpeg, .png' onChange={(e) => handleSelectOgTag(e)} onClick={(event)=> { 
							event.currentTarget.value = '';
						}}/>
						{
							previewOgTags ?
								<div className='flex'>
									<img src={previewOgTags} alt='favicon' className='w-[160px] h-[150px] object-contain'/>
								</div>
								:
								<div className='flex justify-center'>
									<div className='flex-col flex gap-2'>
										<div className='flex justify-center'>
											<ArrowUpTrayIcon className='w-8 h-8 text-primary'/> 
										</div>
										<span className='text-primary text-xs block text-center'>
											Upload OG Image
										</span>
									</div>
								</div>
						}
					</div>
					{previewOgTags && 
								<div 
									className='rounded-full text-[#D9D7D7] bg-white border-2 h-7 w-7 flex items-center justify-center cursor-pointer -ml-4 -mt-3'
									onClick={() => {
										setPreviewOgTags(null); 
										setImgID(0); 
										setImageFile(null);}}>
									<XMarkIcon className='w-5 h-5 cursor-pointer hover:text-primary'/>
								</div>
					}
				</div>									
				<div className='flex flex-col gap-2'>
					<p className='text-[#464255] opacity-80 text-sm lg:text-base'>
						An Open Graph image, also known as an OG image, is an image associated with a web page that is used when the page is shared on social media platforms like Facebook, Twitter, LinkedIn, or WhatsApp.
					</p>
					<div className='mt-4 text-sm lg:text-base'>
						<b className='text-[#464255] opacity-80'>Recommended Size:</b>
						<p className='text-[#464255] opacity-80'>Your image shouldn’t be larger than 8MB. Image size should be 1200 X 630 pixels (px).</p>
					</div>
				</div>
				<div className='justify-center items-center flex'>
					<img src={IMAGES.Ogtags} className='w-[230px] xl:h-[160px]'/> 
				</div>
			</div>
		</div>
                        
	);
};

export default Ogtag;