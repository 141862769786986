import { showDeleteModal, toDeletePaymentMethodId } from 'store/EditProfile/cardManagement';
import { useAtom, useAtomValue } from 'jotai';

import { Button } from 'components/Common/Buttons';
import { Modal } from 'components/Common/Modals';
import React from 'react';

type Props = {
  isDeleteCardModalShow: boolean;
	onConfirm: (pmId: string) => void;
	disable: boolean;
};

const DeleteCreditCard: React.FC<Props> = ({isDeleteCardModalShow, onConfirm, disable}) => {

	const toDeletePmId = useAtomValue(toDeletePaymentMethodId);
	const [,setShowDeleteConfirm] = useAtom(showDeleteModal); 
	
	const handleCancel = () => {
		setShowDeleteConfirm(false);
	};

	const renderDeleteCardModal = (): JSX.Element => {
		return (
			<>
				<div className='sm:mx-auto sm:w-full sm:max-w-md'>
					<div className='py-[28px] px-[60px] max-sm:px-[50px] sm:rounded sm:px-10'>
						<p className='text-[#FF7F20] text-[24px] text-center mt-[0.5rem] font-semibold'>
                            Delete Credit Card
						</p>
						<p className='text-base text-[#707070] text-center mt-[0.5rem]'> 
							Are you sure you want to delete<br />this payment method?
						</p>
						<div className='flex justify-between mt-10'>
							<Button
								onClick={handleCancel}
								variant='secondary'
								className='py-[10px] px-[25px] max-sm:px-[20px]'
							>
						Cancel
							</Button>
							<Button
								onClick={() => onConfirm(toDeletePmId)}
								variant='primary'
								className='py-[10px] px-[25px] max-sm:px-[20px]'
								disabled={disable}
							>
						Confirm
							</Button>
						</div>
					</div>
				</div>
				{/* <div className='flex flex-col items-center justify-center w-[546px] h-[255px] rounded-lg shadow-md bg-white'>
					
				</div> */}
			</>
		
		);
	};

	return(
		<div>
			<Modal open={isDeleteCardModalShow} onClose={handleCancel} className='sm:max-w-md'>
				{renderDeleteCardModal()}
			</Modal>
		</div>
	);	
};

export default DeleteCreditCard;
