import z from 'zod';

export const GetLetUsBuildFormSchema = z.object({
	name:        z.string().regex(/^[a-zA-Z\s]*$/, {message: 'Input must be letters only'}),
	website:     z.string(),
	email:       z.string().email(),
	phoneNumber: z.string(),
	timeFrame:   z.string(),
	message:     z.string(),
});

export type GetLetUsBuildFormType = z.infer<typeof GetLetUsBuildFormSchema>;
export type GetLetUsBuildFormResponse = z.infer<typeof GetLetUsBuildFormSchema>;