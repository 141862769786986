import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PaymentTabContent } from './Content';
import { PaymentUseQueries } from './UserQueries';
import { useAtomValue } from 'jotai';
import * as subscriptionAtom from '../../../store/CustomizeApp/Payment/subscription';
import { PayTabLoadingScreen } from './PayTabLoadingScreen';

const PaymentPage: React.FC = () => {

	PaymentUseQueries();

	const stripePublishableKey			 = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY;
	const getStripePromise 				 = stripePublishableKey;
	const stripePromise					 = loadStripe(getStripePromise);
	const loadPayPage	 	 		 	 = useAtomValue(subscriptionAtom.loadPayPage);

	// API Call migrated to Payment > useQueries file, to test on deployement before deleting

	// if(loadPayPage){
	// 	return <PayTabLoadingScreen/>;
	// }

	return (
		<>
			<Elements stripe={stripePromise}>
				<div className='h-auto tw-shadow-md'>
					<PaymentTabContent />
					{/* <PaymentTabContent /> */}
				</div>
			</Elements>
		</>
	);
};

export default PaymentPage;