import z from 'zod';

export const GetTermsOfUseSchema = z.object({
	description: z.string(),
	isTurnedOn: z.boolean(),
});


export const EditTermsOfUseByAppIdResponse = z.object({
	termsOfUse: z.object({
		termsOfUseID: z.number(),
		companyTenantID: z.string(),
		appTenantID: z.string(),
		appID: z.string(),
		description: z.string(),
		isTurnedOn: z.boolean(),
		status: z.string(),
		whoAdded: z.number(),
		whoUpdated: z.number(),
		whenAdded: z.string(),
		whenUpdated: z.string()
	})
});



export type GetTermsOfUseFormType = z.infer<typeof GetTermsOfUseSchema>;
export type GetTermsOfUseFormResponse = z.infer<typeof EditTermsOfUseByAppIdResponse>;