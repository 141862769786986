import { AppFeatIsSaving, SBackgroundID, SIsBackgroundChanges } from 'store/CustomizeApp/Appearance';
import { EditBackgroundByAppIdParamsSchema, EditBackgroundByAppIdResponse } from 'services/requests/appearance/background/schema';
import { SBBackgroundColor, SBBackgroundFile, SBBackgroundRefresh, SBBlurBackgroundImage, SBCustomBackgroundID, SBDefaultBackgroundImageID, SBIsCustomBackgroundFocus, SBOpacityBackgroundImage } from 'store/CustomizeApp/Appearance/backgroundAtom';
import { UploadFlashScreenCustomBackgroundByAppIDParamasSchema, UploadFlashScreenCustomBackgroundByAppIDResponse } from 'services/requests/appearance/flash_screen/schema';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { AxiosError } from 'axios';
import NavigationMutation from '../Navigation';
import { editBackgroundByAppID } from 'services/requests/appearance/background';
import { showFailedAlert } from 'utility/alerts';
import { uploadFlashScreenCustomBackgroundByAppID } from 'services/requests/appearance/flash_screen';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

const BackgroundMutation = () => {
	const { id } = useParams();
	const selectedBackgroundID = useAtomValue(SBackgroundID);
	//Atoms for Background
	const opacityBackgroundImage = useAtomValue(SBOpacityBackgroundImage);
	const blurBackgroundValue = useAtomValue(SBBlurBackgroundImage);
	const colorBackground = useAtomValue(SBBackgroundColor);
	const backgroundID = useAtomValue(SBDefaultBackgroundImageID);
	const [customBackgroundID, setBackgroundID] = useAtom(SBCustomBackgroundID);
	const backgroundFile = useAtomValue(SBBackgroundFile);
	const setIsBackgroundRefresh = useSetAtom(SBBackgroundRefresh);

	const isCustomBackgroundFocus = useAtomValue(SBIsCustomBackgroundFocus);
	const setIsSaving = useSetAtom(AppFeatIsSaving);
	const isThereChanges = useAtomValue(SIsBackgroundChanges);
	const {saveNavigation} = NavigationMutation();
	
	//Upload Background
	const { mutate: uploadBackground, isSuccess: isUploadBackgroundSuccess } = useMutation<
	UploadFlashScreenCustomBackgroundByAppIDResponse,
	AxiosError,
	UploadFlashScreenCustomBackgroundByAppIDParamasSchema
	>((data) => uploadFlashScreenCustomBackgroundByAppID(id, data.file), {
		onSuccess: (data) => {
			setBackgroundID(data.ImageUpload.imageUploadID);
			// setBackgroundID(0);
		},
		onError: () => {
			setIsSaving(false);
			showFailedAlert('Cannot upload background image, please try again.');
		},
	});


	//Edit Background
	const { mutate: editAppBackground } = useMutation<
		EditBackgroundByAppIdResponse,
		AxiosError,
		EditBackgroundByAppIdParamsSchema
	>((data) => editBackgroundByAppID(id, selectedBackgroundID, data), {
		onSuccess: () => {
			setIsBackgroundRefresh(true);
			saveNavigation();
		},
		onError: () => {
			setIsSaving(false);
			showFailedAlert('Cannot save background, please try again.');
		},
	});
    
	const updateAppBackground = () => {
		editAppBackground({
			defaultBackgroundID: backgroundID,
			imageUploadID: isCustomBackgroundFocus ? customBackgroundID : 0,
			backgroundColor: colorBackground,
			opacity: opacityBackgroundImage,
			blur: parseFloat(blurBackgroundValue),
		});
	};

	const saveBackground = () => {
		if(isThereChanges){
			if(backgroundFile){
				uploadBackground({
					file: backgroundFile
				});
			}else updateAppBackground();  
		}else saveNavigation();
		
	};

	useEffect(() => {
		if (customBackgroundID && isUploadBackgroundSuccess)
			updateAppBackground();
	}, [customBackgroundID, isUploadBackgroundSuccess]);

	return {saveBackground,};
};

export default BackgroundMutation;