import { axiosInstance } from 'services/axios';
import { 
	GetOffersParams,
	GetOffersWithPaginationResponse,
	GetOffersResponse,
	OffersSchema,
	EditOfferSchemaPayload,
	OffersSchemaPayload
} from './schema';

export const getOffersApiLive = async (appId?: string) => {
	const response = await axiosInstance.get(`/organizations/apps/${appId}/offer?env=LIV`);

	return response.data as GetOffersWithPaginationResponse;
};

export const getOffersApi = async (appId?: string) => {
	const response = await axiosInstance.get(`/organizations/apps/${appId}/offer`);

	return response.data as GetOffersWithPaginationResponse;
};

export const createOfferApi = async (appId?: string, data?: GetOffersParams) => {
	const offersForm = OffersSchemaPayload.parse(data);
	const response = await axiosInstance.post(`/organizations/apps/${appId}/offer`, offersForm);
    
	return response.data.offer as GetOffersResponse;
};

export const getOfferByIdApi = async (appId?: string, offerId?: string) => {
	const response = await axiosInstance.get(`/organizations/apps/${appId}/offer/${offerId}`);
    
	return response.data as GetOffersWithPaginationResponse;
};

export const editOfferApi = async (appId?: string, offerId?: number, data?: GetOffersParams) => {
	const offersForm = EditOfferSchemaPayload.parse(data);
	const response = await axiosInstance.put(`/organizations/apps/${appId}/offer/${offerId}`, offersForm);
	
    
	return response.data.offer as GetOffersResponse;
};

export const deleteOfferApi = async (appId?: string, offerId?: number) => {
	const response = await axiosInstance.delete(`/organizations/apps/${appId}/offer/${offerId}`);
	return response.data;
};