import z from 'zod';

const textNameRegex = z
	.string()
	.regex(/^[a-zA-Z\s]*$/, { message: "Input must be letters only" })
	.regex(/^(?!\s*$)(\s*\S+\s*)+$/, { message: "Name is required" })
	.transform((val) => val.trim().replace(/\s+/g, " "));

const textCompanyNameRegex = z
	.string()
	.regex(/^(?!\s*$)(\s*\S+\s*)+$/, { message: "Company Name is required" })
	.transform((val) => val.trim().replace(/\s+/g, " "));

const textMessageNameRegex = z
	.string()
	.regex(/^(?!\s*$)(\s*\S+\s*)+$/, { message: "Message is required" })
	.transform((val) => val.trim().replace(/\s+/g, " "));


const textRegex = z
	.string()
	.regex(/^(?!\s*$)(\s*\S+\s*)+$/, { message: "Input must not be whitespace only" })
	.transform((val) => val.trim().replace(/\s+/g, " "));


export const GetContactUsFormSchema = z.object({
	name: textNameRegex,
	email: z.string().email(),
	company: textCompanyNameRegex,
	country: z.string(),
	message: textMessageNameRegex,
	adminEmail: z.string().email(),
});

export type GetContactUsFormType = z.infer<typeof GetContactUsFormSchema>;
export type GetContactUsFormResponse = z.infer<typeof GetContactUsFormSchema>;