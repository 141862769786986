import { AppFeatIsSaving, SIsSideBarChanges, SSideBarID } from 'store/CustomizeApp/Appearance';
import { EditSidebarSchema, TSidebar } from 'services/requests/appearance/side_bar/schema';
import { SIsSidebarRefresh, SSideBar } from 'store/CustomizeApp/Appearance/sideBarAtom';
import { useAtomValue, useSetAtom } from 'jotai';

import { AxiosError } from 'axios';
import ColorMutation from '../Color';
import { editSideBarByID } from 'services/requests/appearance/side_bar';
import { showFailedAlert } from 'utility/alerts';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

const SideBarMutation = () => {
	const { id } = useParams();
	const selectedSideBarID = useAtomValue(SSideBarID);
	//Atoms for Sidebar
	const sidebarOrder = useAtomValue(SSideBar);
	const setIsSideBarRefresh = useSetAtom(SIsSidebarRefresh);
	const setIsSaving = useSetAtom(AppFeatIsSaving);
	const isThereChanges = useAtomValue(SIsSideBarChanges);
	const {saveColor} = ColorMutation();
	// Edit Sidebar
	const { mutate: editSidebar } = useMutation<
		TSidebar,
		AxiosError,
		EditSidebarSchema
	>((data) => editSideBarByID(id, selectedSideBarID, data), {
		onSuccess: () => {
			setIsSideBarRefresh(true);
			saveColor();
		},
		onError: () => {
			setIsSaving(false);
			showFailedAlert('Cannot save sidebar, please try again.');
		},
	});

	const saveSideBar = () => {
		if(isThereChanges){
			if (id) {
				const items = sidebarOrder.map((item, index) => {
					return {
						sidebarOrderID: item.id,
						sortOrder: index + 1,
						visible: !item.isHidden,
						isSub: item.isSub,
					};
				});

				editSidebar({
					items: items,
				});
			}
		}else saveColor();
		
	};

	return {saveSideBar,};

};

export default SideBarMutation;