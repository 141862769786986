import { EditThemeByIdResponse, EditThemeByIdSchema, GetAllThemeResponse, GetThemeResponse } from './schema';

import { axiosInstance } from 'services/axios';

export const getThemeByID = async (id : number) => {
	const response = await axiosInstance.get(`/organizations/themes/${id}`);
	return response.data as GetThemeResponse;
};

export const getAllTheme = async () => {
	const response = await axiosInstance.get('/organizations/themes');
    
	return response.data as GetAllThemeResponse;
};

export const editThemeByID = async (params: EditThemeByIdSchema, id: number) => {
	const response = await axiosInstance.put(`/organizations/themes/${id}`, params);

	return response.data as EditThemeByIdResponse;
};