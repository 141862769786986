import { AppFeatIsSaving, SIsColorChanges, SSideBarID } from 'store/CustomizeApp/Appearance';
import { EditAppColorScehemeByIdResponse, EditAppColorSchemeByIdSchema } from 'services/requests/appearance/color_scheme/schema';
import { SCardBackgroundColor, SCardBorderColor, SCardContentColor, SCardIconColor, SCardTitleColor, SIsColorRefresh, SNavigationColor, SNavigationHighlightColor, SNavigationIconActiveColor, SNavigationIconColor, SNavigationTextActiveColor, SNavigationTextColor, STopBarColor, STopBarTextColor } from 'store/CustomizeApp/Appearance/colorAtom';
import { useAtomValue, useSetAtom } from 'jotai';

import { AxiosError } from 'axios';
import FontMutation from '../Font';
import { editAppColorSchemeByID } from 'services/requests/appearance/color_scheme';
import { showFailedAlert } from 'utility/alerts';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

const ColorMutation = () => {
	const { id } = useParams();
	const selectedSideBarID = useAtomValue(SSideBarID);
	
	//Atoms for Color
	const topBarColor 			= useAtomValue(STopBarColor);
	const topBarTextColor 		= useAtomValue(STopBarTextColor);

	const navBackgroundColor 	= useAtomValue(SNavigationColor);
	const navIconColor 			= useAtomValue(SNavigationIconColor);
	const navActiveIconColor 	= useAtomValue(SNavigationIconActiveColor);
	const navTextColor 			= useAtomValue(SNavigationTextColor);
	const navTextActiveColor 	= useAtomValue(SNavigationTextActiveColor);
	const navHighlightColor 	= useAtomValue(SNavigationHighlightColor);

	const cardBackgroundColor 	= useAtomValue(SCardBackgroundColor);
	const cardBorderColor 		= useAtomValue(SCardBorderColor);
	const cardIconColor 		= useAtomValue(SCardIconColor);
	const cardTitleColor 		= useAtomValue(SCardTitleColor);
	const cardContentColor 		= useAtomValue(SCardContentColor);

	
	const setIsColorRefresh = useSetAtom(SIsColorRefresh);
	const setIsSaving = useSetAtom(AppFeatIsSaving);
	const isThereChanges = useAtomValue(SIsColorChanges);
	const {saveFont} = FontMutation();
	// Edit Color
	const { mutate: editColor } = useMutation<
		EditAppColorScehemeByIdResponse,
		AxiosError,
		EditAppColorSchemeByIdSchema
	>((data) => editAppColorSchemeByID(data, id, selectedSideBarID), {
		onSuccess: () => {
			setIsColorRefresh(true);
			saveFont();
		},
		onError: () => {
			setIsSaving(false);
			showFailedAlert('Cannot save color, please try again');
		},
	});
    
	const saveColor = () => {
		if(isThereChanges){
			if (id) {
				editColor({
					topbarColor: 					topBarColor,
					topbarTextColor: 				topBarTextColor,
					navigationBackgroundColor: 		navBackgroundColor,
					navigationIconColor: 			navIconColor,
					navigationIconActiveStateColor: navActiveIconColor,
					navigationTextColor: 			navTextColor,
					navigationTextActiveStateColor: navTextActiveColor,
					navigationHighlightColor: 		navHighlightColor,
					cardBackgroundColor: 			cardBackgroundColor,
					cardBorderColor: 				cardBorderColor,
					cardIconColor: 					cardIconColor,
					cardTitleColor: 				cardTitleColor,
					cardContentTextColor: 			cardContentColor,
				});
			}
		}else saveFont();
		
	};

	return {saveColor,};

};

export default ColorMutation;