import { EditBackgroundByAppIdParamsSchema, EditBackgroundByAppIdResponse, GetAllAppBackgroundResponse, GetBackgroundByAppIdResponse, GetDefaultBackgroundsResponse, GetSubCategoryBackgroundsParamsSchema } from './schema';

import { axiosInstance } from 'services/axios';

export const getAllAppBackground = async (appID: number) => {
	const response = await axiosInstance.get(`/organizations/apps/${appID}/backgrounds`);
    
	return response.data as GetAllAppBackgroundResponse;
};


export const getBackgroundByAppIDLive = async (appID?: string, backgroundID?: number) => {
	const response = await axiosInstance.get(`/organizations/apps/${appID}/backgrounds/${backgroundID}?env=LIV`);
    
	return response.data as GetBackgroundByAppIdResponse;
};

export const getBackgroundByAppID = async (appID?: string, backgroundID?: number) => {
	const response = await axiosInstance.get(`/organizations/apps/${appID}/backgrounds/${backgroundID}`);
    
	return response.data as GetBackgroundByAppIdResponse;
};

export const editBackgroundByAppID = async (appID?: string, backgroundID?: number, params?: EditBackgroundByAppIdParamsSchema) => {
	const response = await axiosInstance.put(`/organizations/apps/${appID}/backgrounds/${backgroundID}`, params);
    
	return response.data as EditBackgroundByAppIdResponse;
};


export const getDefaultBackgrounds = async (param?: GetSubCategoryBackgroundsParamsSchema) => {
	const response = await axiosInstance.get('organizations/defaultBackgrounds', {params: param});
    
	return response.data as GetDefaultBackgroundsResponse;
};