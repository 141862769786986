import React,{ useState, useEffect } from 'react';
import { IMAGES } from 'utility';
import { useQuery } from 'react-query';
import { getAllIndustry } from 'services/requests/industry';
import { getUserById } from 'services/requests/user';
import { formatDate } from 'utility';
import { getAllUsers } from 'services/requests/user';
import { capitalizeWords } from 'utility';
interface App {
	appID?: number;
	companyTenantID?: string;
	appTenantID?: string;
	industryID?: number;
	name?: string;
	logoUrl?: string;
	websiteUrl?: string;
	status?: string;
	whoUpdated?: number;
	whenSubmitted?: string;
	whenReviewed?: string;
  }
  
  interface AppList {
	app: App[] | undefined;
  }

const AllApplication: React.FC<AppList> = ({ app }) => {

	const { data } = useQuery('industry', getAllIndustry);
	const livWebsites = app?.filter((website) => website.status !== 'DEL');
	const { data: userData } = useQuery('users', getAllUsers);

	return(
		<div className='relative flex-col bg-white w-full h-[60vh] shadow-md p-5'>
			<div className='relative flex flex-col'>
				{/* <form className='absolute right-4 bg-white rounded-[9px] w-[250px]'>
					<input className='w-full h-full block rounded-[5px] text-xs py-[8px] pl-[15px] pr-[41px] border focus:ring-1 focus:border-input-active outline-none' type='search' placeholder='Search..' />
					<img className='flex absolute top-2 right-3 cursor-pointer' src={IMAGES.SearchIcon} alt='Search Icon' />
				</form> */}
				<div className='mt-2'>
					<div className='flex flex-col'>
						<div className='overflow-x-auto sm:-mx-6 lg:-mx-8'>
							<div className='inline-block min-w-full py-2 sm:px-6 lg:px-8'>
								<div className='h-[50vh] overflow-y-auto'>
									<table className='min-w-full text-left text-sm font-light'>
										<thead
											className='border-b bg-white font-medium text-xs'>
											<tr>
												<th scope='col' className='px-2 py-2'>App Name</th>
												<th scope='col' className='px-6 py-2'>Created by</th>
												<th scope='col' className='px-6 py-2'>App Industry</th>
												<th scope='col' className='px-6 py-2'>Last Transaction Date</th>
												<th scope='col' className='px-6 text-start py-2'>Link</th>
												<th scope='col' className='px-12 text-center py-2'>ACTIONS</th>
											</tr>
										</thead>
										<tbody className='border-b bg-white font-medium text-xs'>
											{livWebsites?.map((app, index) => (
												<tr key={app.appID} className={`py-2 ${index % 2 === 0 ? 'bg-gray-100 shadow-sm border' : 'bg-white'}`}>
													<td className='px-2 py-2'>{app.name}</td>
													{userData?.user.filter((item : any) => item.userID === app.whoUpdated).map((item : any, key : any) => (	
														<td key={key} className='px-6 py-2 text-[#3C5491]'>{capitalizeWords(item.firstName + ' ' + item.lastName)}</td>
													))}
													{data?.rows.filter((item) => item.industryID === app.industryID).map((item, key) => (	
														<td key={key} className='px-6 py-2 text-[#3C5491]'>{item.name}</td>
													))}
													<td className='px-6 py-2'>{formatDate(app.whenSubmitted)}</td>
													<td className='px-6 text-start py-2 text-[#3C5491] underline'>
														<a id='nav-link' href={app.websiteUrl} target='_blank' rel='noreferrer'>
															{app.websiteUrl}
														</a>
													</td>
													<td className='px-6 flex text-center py-2 font-normal text-xs'>
														{app.status === 'LIV' ?
															<p className='text-[#3EAB45]'>Approved</p>
															:
															app.status === 'REJ'
																?
																<p className='text-[#AB5776]'>Rejected</p>
																:
																app.status === 'DRA'
																	?
																	<p className='text-[#7f7971]'>Draft</p>
																	:
																	app.status === 'PAU'
																		?
																		<p className='text-[#b1b133]'>PAUSED</p>
																		:
																		app.status === 'TRI'
																			?
																			<p className='text-[#7f7971]'>EXPIRED</p>
																			:
																			<p className='text-[#c98d3e]'>For Approval</p>
																
														}
													</td>
												</tr>
											))}
										</tbody>

                                        
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};


export default AllApplication;