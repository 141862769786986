import { FIsFeaturesChange, FIsStoreChanges } from 'store/CustomizeApp/Features';
import { FIsStoreRefresh, FStore, FStoreID } from 'store/CustomizeApp/Features/storeAtom';
import React, { useEffect } from 'react';
import { getStoreApi, getStoreApiLive } from 'services/requests/Features/Store';
import { useAtom, useSetAtom } from 'jotai';

import { AxiosError } from 'axios';
import { PIsPublishShowStore } from 'store/CustomizeApp/Publish/publishAtom';
import { StoreResponse } from 'services/requests/Features/Store/schema';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { log } from 'utility/log';

export const StoreUseQuery = () => {

	const [storeName, setStoreName]                   = useAtom(FStore);
	const [storeID, setStoreID]                       = useAtom(FStoreID);
	const [isStoreRefresh, setIsStoreRefresh]         = useAtom(FIsStoreRefresh);
	const setFeatureChange = useSetAtom(FIsFeaturesChange);
	const setStoreChanges = useSetAtom(FIsStoreChanges);
	const setPublishShow = useSetAtom(PIsPublishShowStore);
	
	const {id} = useParams();

	const {data: storeLive, refetch: refetchLive} = useQuery<StoreResponse, AxiosError>({
		queryKey: ['storeLive', id],
		queryFn: () => getStoreApiLive(id),
		enabled: false,
		retry: 0,
		onError: () => setIsStoreRefresh(false)
	});

	const {data, refetch, isFetching, isFetched} = useQuery<StoreResponse, AxiosError>({
		queryKey: ['store', id],
		queryFn: () => getStoreApi(id),
		enabled: false,
		retry: 0,
		onSuccess: (data) => {
			if(data?.store){
				setStoreName(data?.store?.storeName);
				setStoreID(data?.store?.storeID);
				setIsStoreRefresh(false);
			}else{
				setStoreName('');
				setStoreID(0);
				setIsStoreRefresh(false);
			}
		},
		onError: () => setIsStoreRefresh(false)
	});

	useEffect(() =>{
		if(isStoreRefresh){
			refetch();
			refetchLive();
		}
	},[isStoreRefresh]);

	// useEffect(() =>{
	// 	if(isFetching){
	// 		setFeatureChange(false);
	// 	}
	// },[isFetching]);

	useEffect(() => {
		if((data?.store?.storeName || storeName) && isFetched){
			if(data?.store?.storeName !== storeName){
				// setFeatureChange(true);
				setStoreChanges(true);
				log('storeChangesTrue');
			} else {
				// setFeatureChange(false);
				setStoreChanges(false);
			}
		} else {
			// setFeatureChange(false);
			setStoreChanges(false);
		}
	},[storeName, isFetched]);

	useEffect(() => {
		if(data?.store && storeLive?.store){
			if(data?.store?.storeName !== storeLive?.store?.storeName){
				log(data, storeLive);
				setPublishShow(true);
			}else setPublishShow(false);
		}
	},[data, storeLive]);
};