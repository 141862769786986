import { AuthorizeGoogleUserResponse, GetGoogleTokenType } from 'services/requests/auth/schema';
import React, { useEffect, useRef } from 'react';
import { authAtom, globalUserEmail } from 'store/authAtom';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AxiosError } from 'axios';
import { authorizeGoogleUser } from 'services/requests/auth';
import { useMutation } from 'react-query';
import { useSetAtom } from 'jotai';

const GoogleAuthCallback: React.FC = () => {
	const setAuth = useSetAtom(authAtom);
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const authRef = useRef(false);
	const googleCode = searchParams.get('code');
	const setGlobalEmail = useSetAtom(globalUserEmail);


	const { mutate: authorizeUser } = useMutation<AuthorizeGoogleUserResponse, AxiosError, GetGoogleTokenType>(
		(data) => authorizeGoogleUser(data),
		{
			onSuccess: (data) => {
				if (data.access_token) {
					setAuth(data);
					localStorage.removeItem('userData');
					navigate('/dashboard');
				} else {
					setGlobalEmail(data.email);
					navigate(data.redirect_url);
				}
			},
			onError: (error) => {
				//alert('Something went wrong!');
				console.error(error);
			}
		}
	);

	useEffect(() => {
		if (authRef.current === false) {
			const params = { code: googleCode || '' };
			authorizeUser(params);
			return () => {
				authRef.current = true;
			};
		}
	}, []);

	return (<div className='w-full h-screen bg-[#F6F6F6]'>Loading...</div>);
};

export default GoogleAuthCallback;