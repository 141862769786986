import z from 'zod';

export const resetPasswordSendEmailSchema = z.object({
	email: z.string().email(),
});

export const resetPasswordSendEmailResponse = z.object({
	success: z.boolean(),
	email: z.string()
});


export const resetPasswordValidateVerificationCodeSchema = z.object({
	verificationCode: z.coerce.number(),
	email:z.string().optional(),
});

export const resetPasswordValidateVerificationCodeResponse = z.object({
	success: z.boolean(),
	email: z.string()
});

const passwordSchema = z
	.string()
	.min(8, { message: 'Password must contain a minimum of 8 characters.' })
	.regex(/[a-z]/, { message: 'Password should have at least 1 lowercase character.' })
	.regex(/[A-Z]/, { message: 'Password should have at least 1 uppercase character.' })
	.regex(/\d/, { message: 'Password should have at least 1 number.' })
	.regex(/[@$!%*#?&]/, { message: 'Password should have at least 1 special character.' });


export const addPasswordSchema = z.object({
	email: z.string(),
	newPassword: passwordSchema,
	confirmPassword: z.string(),
}).superRefine(({ confirmPassword, newPassword}, ctx) => {
	if (confirmPassword !== newPassword) {
		ctx.addIssue({
			code: 'custom',
			message: 'The password did not match',
			path: ['confirmPassword'],
		});
	}
});

export const addPasswordResponse = z.object({
	success: z.boolean(),
	email: z.string()
});

export type ResetPasswordSendEmailRequest = z.infer<typeof resetPasswordSendEmailSchema>;
export type ResetPasswordSendEmailResponse = z.infer<typeof resetPasswordSendEmailResponse>;
export type ResetPasswordValidateVerificationCodeRequest = z.infer<typeof resetPasswordValidateVerificationCodeSchema>;
export type ResetPasswordValidateVerificationCodeResponse = z.infer<typeof resetPasswordValidateVerificationCodeResponse>;
export type AddPasswordRequest = z.infer<typeof addPasswordSchema>;
export type AddPasswordResponse = z.infer<typeof addPasswordResponse>;