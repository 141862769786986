import {
	FAddNotifPayload,
	FEditNotifPayload,
	FIsNotifcationRefresh,
	FNotif,
	FTempNotif,
} from 'store/CustomizeApp/Features/notificationAtom';
import {
	FIsFeaturesChange,
	FIsNotifChanges,
} from 'store/CustomizeApp/Features';
import React, { useEffect, useState } from 'react';
import {
	getAllNotifications,
	getAllNotificationsLive,
} from 'services/requests/Features/Notification';
import { useAtom, useSetAtom } from 'jotai';

import { AxiosError } from 'axios';
import { GetAllNotificationsResponse } from 'services/requests/Features/Notification/schema';
import { PIsPublishShowNotif } from 'store/CustomizeApp/Publish/publishAtom';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { log } from 'utility/log';

export const NotificationQuery = () => {
	const { id } = useParams();

	const [notif, setNotifData] = useAtom(FNotif);
	const [, setAddNotifData] = useAtom(FAddNotifPayload);
	const [, setEditNotifData] = useAtom(FEditNotifPayload);
	const [isNotifRefresh, setIsNotifRefresh] = useAtom(FIsNotifcationRefresh);
	const setFeaturesChange = useSetAtom(FIsFeaturesChange);
	const setNotifChanges = useSetAtom(FIsNotifChanges);
	const [prevNotif, setPrevNotif] = useAtom(FTempNotif);
	const setPublishShow = useSetAtom(PIsPublishShowNotif);

	const { data: notifLive, refetch: refetchNotifLive } = useQuery<
		GetAllNotificationsResponse,
		AxiosError
	>({
		queryKey: ['notificationsLive', id],
		queryFn: () => getAllNotificationsLive(id),
		enabled: false,
		retry: 0,
		onError: () => setIsNotifRefresh(false),
	});

	const { data, refetch, isFetching, isFetched } = useQuery<
		GetAllNotificationsResponse,
		AxiosError
	>({
		queryKey: ['notifications', id],
		queryFn: () => getAllNotifications(id),
		// enabled: false,
		retry: 0,
		onSuccess: (data) => {
			if (prevNotif) {
				setPrevNotif([]);
			}
			if (data?.rows) {
				setNotifData(data);
				data?.rows?.map((socmed) =>
					setPrevNotif((prev) => [
						...prev,
						{
							status: socmed.status,
							message: socmed.message,
							notificationID: socmed.notificationID,
							appID: socmed.appID,
							icon: socmed.icon,
							title: socmed.title,
							url: socmed.url,
							whoAdded: socmed.whoAdded,
							whenAdded: socmed.whenAdded,
							whenUpdated: socmed.whenUpdated,
						},
					])
				);
				setIsNotifRefresh(false);
			} else {
				setNotifData({
					limit: 0,
					page: 0,
					sort: '',
					totalRows: 0,
					totalPages: 0,
					rows: [],
				});
				setAddNotifData({
					notifications: [],
				});
				setEditNotifData({
					notifications: [],
				});
			}
		},
		onError: () => {
			setIsNotifRefresh(false);
			setNotifData({
				limit: 0,
				page: 0,
				sort: '',
				totalRows: 0,
				totalPages: 0,
				rows: [],
			});
			setAddNotifData({
				notifications: [],
			});
			setEditNotifData({
				notifications: [],
			});
		},
	});

	useEffect(() => {
		if (isNotifRefresh) {
			// setNotifData({
			// 	limit: 0,
			// 	page: 0,
			// 	sort: '',
			// 	totalRows: 0,
			// 	totalPages: 0,
			// 	rows: [],
			// });
			// setAddNotifData({
			// 	notifications: []
			// });
			// setEditNotifData({
			// 	notifications: []
			// });
			refetch();
			refetchNotifLive();
		}
	}, [isNotifRefresh]);

	// useEffect(() => {
	// 	if (isFetching) {
	// 		setFeaturesChange(false);
	// 	}
	// }, [isFetching]);

	useEffect(() => {
		if ((data?.rows || notif?.rows) && isFetched) {
			const checkChanges = data?.rows?.map((current) => ({
				match: prevNotif?.some(
					(temp) =>
						temp.title === current.title &&
						temp.message === current.message &&
						temp.url === current.url &&
						temp.icon === current.icon
				),
			}));
			const checkNotif = notif?.rows?.filter(
				(data) =>
					data.title === '' || data.message === '' || data.url === ''
			);

			if (
				(prevNotif?.length !== notif?.rows?.length &&
					checkNotif.length === 0) ||
				checkChanges?.find((check) => check.match === false) !==
					undefined
			) {
				// setFeaturesChange(true);
				setNotifChanges(true);
				log('NotifShowTrue');
			} else {
				// setFeaturesChange(false);
				setNotifChanges(false);
			}
		}
	}, [data, notif, isFetched]);

	useEffect(() => {
		if (data?.rows !== undefined && notifLive?.rows !== undefined) {
			const checkDraftLive = data?.rows?.map((current) => ({
				match: notifLive?.rows?.some(
					(temp) =>
						temp.title === current.title &&
						temp.message === current.message &&
						temp.url === current.url &&
						temp.icon === current.icon
				),
			}));
			if (
				checkDraftLive.find((check) => check.match === false) !==
					undefined ||
				data?.rows?.length !== notifLive?.rows?.length
			) {
				setPublishShow(true);
				log('NotifPublishShowTrue');
			} else setPublishShow(false);
		}
	}, [data, notifLive]);
};
