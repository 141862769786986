import { IMAGES } from 'utility/constant';
import React from 'react';

const LandingPageFeatures = () => {
	const techLinks = [
		{
			id: 1,
			src: `${IMAGES.landingPageFeatures1}`,
			title: 'Intuitive Interface',
			description: 'Our platform lets you drag-and-drop elements which makes user experience better and more straightforward – whether you\'re a newbie in app building or not.',
		},
		{
			id: 2,
			src: `${IMAGES.landingPageFeatures2}`,
			title: 'Cutting-Edge Customization Tools',
			description: 'Customizing your app can be overwhelming. But with SnapToApp, we\'re going to help you customize your app based on your business needs, brand identity, design requirements, and more.',
		},
		{
			id: 3,
			src: `${IMAGES.landingPageFeatures3}`,
			title: 'Integration',
			description: 'Our platform provides integration with popular tools and services, such as databases, payment gateways, and third-party APIs to ease your app building.',
		},
		{
			id: 4,
			src: `${IMAGES.landingPageFeatures4}`,
			title: 'Speed And Simplicity',
			description: 'No need for coding expertise. App development with SnapToApp can be done much faster which allows you to launch your apps quickly and efficiently – thanks to the platform\'s simplicity.',
		},
		{
			id: 5,
			src: `${IMAGES.landingPageFeatures5}`,
			title: 'Collaborative System',
			description: 'Seamlessly work with your team with our platform\'s collaborative feature and enhance teamwork and efficiency.',
		},
		{
			id: 6,
			src: `${IMAGES.landingPageFeatures6}`,
			title: 'Cost-Effective',
			description: 'Less resources, less money to spend. This makes SnapToApp accessible to small businesses and startups with limited budgets. This enables them to compete with larger players in the market.',
		},
	];

	return (
		<div className='max-sm:w-[100%] max-sm:mt-[10px] w-full bg-[#FDEFD6]'>
			<div className='flex flex-col items-center justify-center'>
				<div className='mt-[2rem] text-[#222222]'>
					<h1 className='text-5xl max-sm:text-2xl font-semibold text-center'>Our App Features</h1>
				</div>
				<div
					className={'grid w-full h-fit place-items-center grid-cols-1 sm:grid-cols-2 md:grid-cols-3 px-[5vw] pb-[6vw] mt-[5vw] gap-y-[45px] gap-x-[50px]'}
				>
					{techLinks.map(({ id, src, title, description }) => (
						<div
							key={id}
							//Past values = lg - 365, md - 520, h - 380
							className='flex flex-col p-[25px] lg:h-[100%] md:h-[100%] sm:h-[100%]  h-fit bg-[#F9F9FF] shadow-xl duration-500 rounded-xl w-full'
						>
							{/* flex shadow-xl md:w-[360px] sm:w-[326px] w-[295px] md:h-[330px] sm:h-[296px] h-fit bg-[#F9F9FF] duration-500 rounded-xl p-[25px] */}
							<div className='w-full'><img src={src} className='md:h-[69px] sm:h-[76px] h-[71px]' /></div>
							<p className='mt-[9px] font-medium text-[18.5px]'>{title}</p>
							<p className='mt-[14.5px] text-[16px] break-words text-[#6B6969]'>{description}</p>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default LandingPageFeatures;
