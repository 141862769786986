import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { 
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement,
	useStripe,
	useElements 
} from '@stripe/react-stripe-js';

import { Button } from 'components/Common/Buttons';
import { useState } from 'react';
import { classNames } from 'utility';
import { ensure } from 'utility/ensure';
// import paymentMethodIcons from '../../../../assets/Payment.png';
import StripeCardInputElement from '../StripeCardInput';
import { useAtom } from 'jotai';
import * as subscriptionAtom from '../../../../store/CustomizeApp/Payment/subscription';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import * as paymentApis from '../../../../services/requests/Payment/index';
import * as typeSchemas from '../../../../services/requests/Payment/schema';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { showFailedAlert } from 'utility/alerts';

export const freePriceId = import.meta.env.VITE_FREE_PRICE_ID;
export const litePriceId = import.meta.env.VITE_LITE_PRICE_ID;
export const monthlyPriceId = import.meta.env.VITE_MONTHLY_PRICE_ID;
export const annualPriceId = import.meta.env.VITE_ANNUAL_PRICE_ID;

const PARENT_CARD_CLASS = 'w-1/2 h-[415px] mr-2 cursor-pointer';
const CARD_CLASS = 'h-full w-full rounded-lg relative';
const DIV_ITEM_CLASS = 'h-1/4 flex justify-center';

export interface ISubscriptionDetails {
 id: number;
 planPrice: number;
 planInterval: string;
 planTitle: string;
 PriceId: string;
 rateDescription: string;
 planDescription: string;
}

export const subscriptionPlans = [
	{
		id: 0,
		planPrice: 99,
		planInterval: 'Monthly',
		planTitle: 'Lite',
		PriceId: litePriceId,
		rateDescription: 'Per month billed monthly',
		planDescription: 'Extra charges will apply for any integration of storefront, creation of customized forms, etc.'
	},
	{
		id: 1,
		planPrice: 175,
		planInterval: 'Quarterly',
		planTitle: 'Standard',
		PriceId: monthlyPriceId,
		rateDescription: 'Per month billed quarterly',
		planDescription: 'Extra charges will apply for any integration of storefront, creation of customized forms, etc.'
	},
	{
		id: 2,
		planPrice: 150,
		planInterval: 'Annually',
		planTitle: 'Premium',
		PriceId: annualPriceId,
		rateDescription: 'Per month billed annually',
		planDescription: 'Extra charges will apply for any integration of storefront, creation of customized forms, etc.'
	},
	
];

const FREE_TRIAL_PRICE_ID = freePriceId;

const XPaymentTabContent : React.FC = () => {

	const { id } = useParams();

	const stripe = useStripe();
	const elements = useElements();

	const [subscriptionData, ] = useAtom(subscriptionAtom.FSubscription);
	const [cardBrand, ] = useAtom(subscriptionAtom.FCardBrand);
	const [isAlreadySubscribed, ] = useAtom(subscriptionAtom.FIsAlreadySubscribed);

	const [ ,setInlineError] = useAtom(subscriptionAtom.FInlineError);
	const [cardNumberError ,setCardNumberError] = useAtom(subscriptionAtom.FCardNumberError);
	const [cardExpiryError ,setCardExpiryError] = useAtom(subscriptionAtom.FExpiryError);
	const [cardCVCError ,setCardCVCError] = useAtom(subscriptionAtom.FCVCError);

	const [selectedPlanPriceId, setSelectedPlanPriceId] = useState<string>(subscriptionData.PriceId);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleOnSelect = (data: ISubscriptionDetails) => {
		if (data.PriceId === selectedPlanPriceId) return;

		setSelectedPlanPriceId(data.PriceId);
	};

	const handleSubmit = async () => {
		// Elements knows how to find your CardElement because there 
		// can only ever be one of each type of element.

		const errorMessage = 'This value was promised to be there.'; 
		const cardNumberElement = ensure(elements, errorMessage).getElement(CardNumberElement);

		const cardExpiryElement = ensure(elements, errorMessage).getElement(CardExpiryElement);
		const cardCvcElement = ensure(elements, errorMessage).getElement(CardCvcElement);

		// Card Number Element, to tokenize payment details
		const { error, paymentMethod } = await ensure(stripe, errorMessage).createPaymentMethod({
			type: 'card',
			card: ensure(cardNumberElement, errorMessage)
		});

		if (error) {

			const errorCode = ensure(error?.code).replaceAll('_', ' ');
		
			if (errorCode === 'invalid number') {
				setCardNumberError(errorCode);
			}

			if (errorCode === 'invalid expiry year past') {
				setCardExpiryError(errorCode);
			}

			if (errorCode === 'incomplete cvc') {
				setCardCVCError(errorCode);
			}

			// return (
			//setInlineError(errorCode)
			// setCardError(error), --> this is from stripe, optional to be use
			// you can throw an error message here
			// );
		}

		const paymentMethodId = ensure(paymentMethod, errorMessage).id;

		// if (!error) {
		// 	setPaymentData({
		// 		appId:  id?.toString(),
		// 		priceId:  selectedPlanPriceId,
		// 		paymentMethodId: paymentMethodId
		// 	});
		// }
	};

	const { mutate: setPaymentData, isLoading } = 
	useMutation<typeSchemas.SubscriptionResponse, AxiosError<any>, typeSchemas.SubscriptionParams>(
		data => paymentApis.SubscribePerAppId(data), {
			onSuccess: () => {
				setIsModalOpen(true);
			},
			onError: (error) => {
				const err = error?.response?.data?.error?.message;
				if (err) {
					const errParse = JSON.parse(err);
					//const errCode = errParse?.code;
					const errMsg = errParse?.decline_code ? `${errParse?.message} - ${errParse?.decline_code}` : errParse?.message;
	
					setInlineError(errMsg); // optional, to delete if not needed
					showFailedAlert(errMsg);
				}
				else
				{
					setInlineError(error);
					showFailedAlert('Payment Failed');
				}
			}
		}
	);

	const formValidation = () => {
		if (selectedPlanPriceId === FREE_TRIAL_PRICE_ID
			|| cardBrand === '' 
			|| cardBrand === 'unknown'
		) return true;

		if (cardNumberError !== '') return true;
		if (cardExpiryError !== '') return true;
		if (cardCVCError !== '') return true;

		return false;
	};

	const renderSubscriptionPlans = () : JSX.Element => {

		const xxlViewPortAlready = useMediaQuery({ maxWidth: 1309 });
		
		return (
			<div
				className={classNames(
					xxlViewPortAlready
						? ''
						: 'flex',
					'w-auto'
				)}
			> 
				<div className={classNames(
					xxlViewPortAlready
						? 'w-full flex'
						: 'w-1/2 flex flex-row'
				)}>
					{subscriptionPlans.map((data: ISubscriptionDetails, index: number) => {

						const isPlanNotSelected = data.PriceId !== selectedPlanPriceId;

						return (
							<div key={index} className={classNames(PARENT_CARD_CLASS)}>
								<button
									onClick={() => handleOnSelect(data)}
									disabled={isAlreadySubscribed}
									className={classNames(CARD_CLASS,  // container h and w to adjust 	
										isPlanNotSelected
											? 'bg-white text-gray-600'
											: 'bg-primary text-white',
										isAlreadySubscribed
											? 'cursor-not-allowed'
											: ''
									)}>
									{isPlanNotSelected 
										? <> </>
										: <CheckCircleIcon className='h-8 w-8 text-white absolute top-1.5 right-2'/>
									}
									<div className={classNames(DIV_ITEM_CLASS, 'items-end')}>
										<p className='mb-4 text-lg font-semibold text-center'>
											{data.planInterval}
										</p>
									</div>
									<div className={classNames(DIV_ITEM_CLASS, 'items-center')}>
										<p className='mb-4 text-4xl font-bold'>
											$ {data.planPrice.toLocaleString()} 
										</p>
									</div>
									<p className='mx-2 mb-10 text-sm text-center'>
										{data.rateDescription}
									</p>
									<p className='mx-2 h-1/2 text-sm text-center'>
										{data.planDescription}
									</p>
								</button>
							</div>
						);
					})}
				</div>
				<div
					className={classNames(
						xxlViewPortAlready
							? 'w-full'
							: '',
						'w-1/2 bg-white flex-col'
					)}>
					<div className='mt-5 text-lg font-semibold text-gray-600 text-center'>
						Choose a Payment Method
					</div>
					{/* <img
						className='mt-5 px-24 w-full'
						alt='Payment Method Icons' 
						src={paymentMethodIcons} // to change it to dynamic and clickable
					/> */}
					<div className='relative mt-6 mx-5'>
						<hr className='border-0 border-t border-gray-300' />
						<span className='px-3 bg-white text-md absolute -top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
							or
						</span>
					</div>
					<div className='mt-6 mx-5'>
						<StripeCardInputElement/>
						<div className='mt-4 flex justify-center items-center'>
							<Button
								variant='primary'
								className={classNames(
									'my-5 px-24 py-3 rounded-lg text-white'
								)}
								disabled={formValidation() || isLoading}
								onClick={() => handleSubmit()}
							>
								Proceed
							</Button>
						</div>
					</div>
				</div>	
			</div>
		);
	};

	const renderAlreadySubscribedPlan = () : JSX.Element => {
		return (
			<>
				{subscriptionPlans.map((data: ISubscriptionDetails, index: number) => {
					if (data.PriceId === subscriptionData.PriceId)
						return (
							<div key={index}>
								<div className='w-80 h-32 bg-[#FDDDCB] rounded-md'>
									<div className='mx-4 h-3/4 flex justify-between items-center'>
										<div className='text-[#1C180F] text-lg font-semibold'> {data.planInterval} Plan </div>
										<div className='text-[#1C180F] text-3xl font-bold'> $ {data.planPrice.toLocaleString()} </div>
									</div>
									<div className='mx-4'>
										<hr className='bg-gray-500 h-[1px] border-0'/>
										<div className='mt-1 flex justify-end'>
											<span className='text-[#4663AC] text-sm cursor-pointer hidden'>
												Change Plan
											</span>
										</div>
									</div>
								</div>
							</div>
						);
				})}
			</>
		);
	};

	return (
		<>
			<div className='h-auto w-full'> {/* set a parent container height */}
				<div className='mx-10 h-auto'>
					<p className='pt-5 text-[#464255] font-semibold text-xl'> 
						{isAlreadySubscribed
							? 'Your current plan'
							: 'Choose Your Subscription'
						}
					</p>
					<div className='mt-5'>
						{isAlreadySubscribed
							? renderAlreadySubscribedPlan()
							: renderSubscriptionPlans()
						}
					</div>
				</div>
			</div>
			{/* <SuccessPaymentModal
				isOpen={isModalOpen} 
				onClose={() => setIsModalOpen(false)}
			/> */}
		</>
	);
};

export default XPaymentTabContent;