import { Button } from 'components/Common/Buttons';
import FormInput from 'components/Common/Forms/Inputs';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import {
	SignUpType,
	SignUpTypeResponse,
	signUpSchema,
} from 'services/requests/auth/schema';
import { AxiosError } from 'axios';
import { signUpApi } from 'services/requests/auth';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate, Link } from 'react-router-dom';
import { useAtom } from 'jotai';
import { globalVariableAtom } from '../../../store/authAtom';
import FormInputPassword from 'components/Common/Forms/Password';
import ReCAPTCHA from 'react-google-recaptcha';
import { useMediaQuery } from 'react-responsive';
import { classNames } from 'utility';
import { log } from 'utility/log';

const SignUp: React.FC = () => {
	const navigate = useNavigate();
	const [globalVariable, setGlobalVariable] = useAtom(globalVariableAtom);
	const [showCriteria, setShowCriteria] = useState(false);
	const [captChaCheck, setCaptChaCheck] = useState(true);

	useLayoutEffect(() => {
		document.body.style.backgroundColor = '#f6f6f6';
	});

	useEffect(() => {
		window.scrollTo(0,0);
	}, []);

	function handleChange(e: React.ChangeEvent<HTMLInputElement>) {setChecked(e.target.checked);}

	const handleHideCriteria = () => {
		setShowCriteria(true);
	};

	const defaultValues = {
		enabled: true,
		credentials: [
			{
				type: 'password',
				value: '',
				temporary: false,
			},
		],
		attributes: {
			Tenant_ID: 'tenant123',
		},
	};

	const getDefaultValue = (defaultValues: any, fieldName: any) => {
		const fieldNames = fieldName.split('.');
		let value = defaultValues;
		for (const name of fieldNames) {
			if (!value) {
				break;
			}
			value = value[name];
		}
		return value;
	};

	const hiddenFields = [
		'enabled',
		'credentials.0.type',
		'credentials.0.value',
		'credentials.0.temporary',
		'attributes.Tenant_ID',
	];

	const { control, handleSubmit, formState } = useForm<SignUpType>({
		defaultValues: { firstName: '', lastName: '', email: '' },
		mode: 'onBlur',
		resolver: zodResolver(signUpSchema),
	});

	const { mutate: signUpMU } = useMutation<
		SignUpTypeResponse,
		AxiosError,
		SignUpType
	>((data) => signUpApi(data), {
		onSuccess: (data) => {
			log(data);
			navigate('/thankyou-signup');
		},
		onError: (error) => {
			log(error);
			setGlobalVariable('Email already exists.');
		},
	});

	const onSubmit = (data: SignUpType) => {
		signUpMU(data);
	};

	const[checked, setChecked] = useState(false);

	const onChangeCaptcha = () => {
		setCaptChaCheck(false);
	};

	const onExpiredCaptcha = () => {
		setCaptChaCheck(true);
	};

	const handleEmailChange = () => {
		setGlobalVariable('');
	};
	
	const captchaSmallerResize = useMediaQuery({ maxWidth: 475 });



	// <div className='2xl:w-full xl:w-full lg:w-[35%] md:w-full p-6 pl-12 pr-12 min-sm:w-[100%] bg-white rounded-xl'>
	return (
		<div className={classNames('h-full py-[2.5%] mb-2 flex items-center justify-center bg-gray-100 max-sm:p-6', captchaSmallerResize ? 'py-[2%]' : 'py-[2.5%]')}>
			<div className='w-full max-w-md bg-white p-6 rounded-xl'>
				<h1 className='text-center text-[#464255] text-lg font-bold mt-5'>
					Create a New Account
				</h1>
				<form className='mt-7 2xl:space-y-6 xl:space-y-6 lg:space-y-6 md:space-y-3 sm:space-y-3 max-sm:space-y-3'>
					<div className='grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 gap-4 max-sm:grid-rows-2'>
						<div className='font-light'>
							<Controller
								control={control}
								name='firstName'
								render={({ field: { name, onChange }, fieldState: { error } }) => (
									<FormInput
										onChange={onChange}
										name={name}
										error={error?.message}
										type='text'
										label='First Name'
										className='font-normal placeholder:text-[11px]'
									/>
								)}
							/>
						</div>
						<div className='font-light'>
							<Controller
								control={control}
								name='lastName'
								render={({ field: { name, onChange }, fieldState: { error } }) => (
									<FormInput
										onChange={onChange}
										name={name}
										error={error?.message}
										type='text'
										label='Last Name'
										className='font-normal placeholder:text-[11px]'
									/>
								)}
							/>
						</div>
					</div>
					<div className='font-light'>
						<Controller
							control={control}
							name='email'
							render={({ field: { name, onChange }, fieldState: { error } }) => (
								<>
									<FormInput
										onChange={(e) => {
											onChange(e);
											handleEmailChange();
										}}
										name={name}
										error={error?.message}
										type='email'
										label='Email Address'
										className='font-normal placeholder:text-[11px]'
									/>
									<div className='mt-2'>
										{globalVariable === 'Email already exists.' ? (
											<p className='text-red-700 text-xs pl-1'>{globalVariable}</p>
										) : null}
									</div>
								</>
							)}
						/>
					</div>

					<div className='font-light'>
						<Controller
							control={control}
							name='credentials.0.value'
							render={({ field: { name, onChange }, fieldState: { error } }) => (
								<FormInputPassword
									onChange={onChange}
									name={name}
									error={error?.message}
									type='password'
									label='Password'
									onFocus={handleHideCriteria}
									criteria={showCriteria}
									className='font-normal placeholder:text-[11px]'
								/>
							)}
						/>
					</div>

					<div className='font-light'>
						<Controller
							control={control}
							name='credentials.0.confirmPass'
							render={({ field: { name, onChange }, fieldState: { error } }) => (
								<FormInputPassword
									onChange={onChange}
									name={name}
									error={error?.message}
									type='password'
									label='Retype your Password'
									className='font-normal placeholder:text-[11px]'
								/>
							)}
						/>
					</div>

					<div>
						{hiddenFields.map((fieldName: any) => (
							<Controller
								key={fieldName}
								control={control}
								name={fieldName}
								defaultValue={getDefaultValue(defaultValues, fieldName)}
								render={({ field }) => <input type='hidden' {...field} />}
							/>
						))}
					</div>
					<div className='mt-5 pt-5 pb-2 w-full justify-start flex sm:justify-start max-sm:flex max-sm:justify-center'>
						<ReCAPTCHA
							onChange={onChangeCaptcha}
							onExpired={onExpiredCaptcha}
							sitekey={import.meta.env.VITE_RECAPTCHA_PROD}
							size={captchaSmallerResize ? 'compact' : 'normal'}
						/>
					</div>
					<div className='mt-7 px-1 tracking-wide text-sm flex justify-center'>
						<div>
							<input className='mr-3 hover:cursor-pointer' type='checkbox' checked={checked} onChange={handleChange} id='checkboxDefault' />
						</div>
						<div className='-mt-[0.18rem]'>
							<label className='text-gray-500 text-[11px] font-normal'> By checking this box, you consent to Snap to App&apos;s 
								<Link id='nav-link' to='/terms-of-use' target='_blank' rel='noopener noreferrer' className='ml-1 text-[#e98923] font-semibold'>
								Terms of Use
								</Link> and acknowledge that you have reviewed our 
								<Link  id='nav-link' to='/privacy-policy' target='_blank' rel='noopener noreferrer' className='ml-1 text-[#e98923] font-semibold'>
								Privacy Policy
								</Link>.</label>
						</div>
					</div>
					<div className='mt-5 pb-5'>
						<Button
							type='submit'
							disabled={
								!formState.dirtyFields.firstName ||
								!formState.dirtyFields.lastName ||
								!formState.dirtyFields.email ||
								!formState.dirtyFields.credentials || !checked ||
								captChaCheck
							}
							variant='primary'
							className='w-full py-1.5'
							onClick={handleSubmit(onSubmit)}
						>
							<span className='text-sm'>Register</span>
						</Button>
						<div className='mt-7 tracking-wide text-sm flex justify-center items-center'>
							<span className='text-gray-500'>Have an account?</span>
							<Link id='nav-link' to='/login' className='ml-1 text-[#e98923] font-normal underline'>
								Sign In.
							</Link>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default SignUp;