import { FAbout, FAboutID, FIsAboutRefresh } from 'store/CustomizeApp/Features/aboutAtom';
import { createAboutApi, editAboutApi } from 'services/requests/Features/about';
import { createAboutResponse, createAboutType, editAboutParams, editAboutResponse } from 'services/requests/Features/about/schema';
import { useAtomValue, useSetAtom } from 'jotai';

import { AppFeatIsSaving } from 'store/CustomizeApp/Appearance';
import { AxiosError } from 'axios';
import ContentMutation from '../Content';
import React from 'react';
import { showFailedAlert } from 'utility/alerts';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { FIsAboutChanges } from 'store/CustomizeApp/Features';

const AboutMutation = () => {
	const { id } = useParams();
	//Atoms for About
	const aboutDesc         = useAtomValue(FAbout);
	const aboutID           = useAtomValue(FAboutID);
	const setIsAboutRefresh = useSetAtom(FIsAboutRefresh);
	const setIsSaving       = useSetAtom(AppFeatIsSaving);
	const isThereChanges    = useAtomValue(FIsAboutChanges);
	const {saveContent}		= ContentMutation();

	//Create About
	const { mutate: aboutFormMu } = useMutation<
		createAboutResponse,
		AxiosError,
		createAboutType
	>((data) => createAboutApi(id, data), {
		onSuccess: () => {
			setIsAboutRefresh(true);
			saveContent();
		},
		onError: () => {
			setIsSaving(false);
			showFailedAlert('Cannot save about');
		},
	});

	//Edit About
	const { mutate: editAbout } = useMutation<
		editAboutResponse,
		AxiosError,
		editAboutParams
	>((data) => editAboutApi(id, aboutID, data), {
		onSuccess: () => {
			setIsAboutRefresh(true);
			saveContent();
		},
		onError: () => {
			setIsSaving(false);
			showFailedAlert('Cannot edit about.');
		},
	});

	const updateAbout = () => {
		if (id)
			editAbout({
				description: aboutDesc,
				isTurnedOn: true,
			});
	};
    
	const saveAbout = () => {
		if(isThereChanges){
			if (aboutID) {
				updateAbout();
			} else {
				aboutFormMu({ description: aboutDesc, isTurnedOn: true });
			}
		}else saveContent();
		
	};

	return {saveAbout,};
};

export default AboutMutation;