import { EditWelcomeScreenByAppIdParamsSchema, EditWelcomeScreenByAppIdResponse, GetAllWelcomeScreenByAppIDResponse, GetSubCategoryIndustryParamsSchema, GetSubCategoryIndustryResponse, UploadFlashScreenLogoByAppIDResponse } from './schema';

import { axiosInstance } from 'services/axios';

export const getIndustrySubCategoriesByIndustryID = async (industryID: number, param?: GetSubCategoryIndustryParamsSchema) => {
	const response = await axiosInstance.get(`/organizations/industries/${industryID}/subcategories`, {params: param});
    
	return response.data as GetSubCategoryIndustryResponse;
};

export const getFlashScreenByAppID = async (appID?: string) => {
	const response = await axiosInstance.get(`/organizations/apps/${appID}/welcomeScreens`);
    
	return response.data as GetAllWelcomeScreenByAppIDResponse;
};

export const getFlashScreenByAppIDLive = async (appID?: string) => {
	const response = await axiosInstance.get(`/organizations/apps/${appID}/welcomeScreens?env=LIV`);
    
	return response.data as GetAllWelcomeScreenByAppIDResponse;
};

export const editFlashScreenByAppID = async (appID?: string, flashID?: number, param?: EditWelcomeScreenByAppIdParamsSchema) => {
	const response = await axiosInstance.put(`/organizations/apps/${appID}/welcomeScreens/${flashID}`, param);
    
	return response.data as EditWelcomeScreenByAppIdResponse;
};

export const uploadFlashScreenLogoByAppID = async (appID?: string, file?: any) => {
	const formData = new FormData();
	formData.append('image', file);
	const response = await axiosInstance.post(`/organizations/apps/${appID}/imageUploads/logos`, formData, {
		headers: {
			'Content-type': 'multipart/form-data'
		}});
    
	return response.data as UploadFlashScreenLogoByAppIDResponse;
};

export const uploadFlashScreenCustomBackgroundByAppID = async (appID?: string, file?: any) => {
	const formData = new FormData();
	formData.append('image', file);
	const response = await axiosInstance.post(`/organizations/apps/${appID}/imageUploads/backgrounds`, formData, {
		headers: {
			'Content-type': 'multipart/form-data'
		}});
    
	return response.data as UploadFlashScreenLogoByAppIDResponse;
};


