import { FContact, FContactID, FIsContactRefresh } from 'store/CustomizeApp/Features/contact';
import { FIsContactChanges, FIsFeaturesChange } from 'store/CustomizeApp/Features';
import React, { useEffect } from 'react';
import { getContactByAppID, getContactByAppIDLive } from 'services/requests/Features/Contact';
import { useAtom, useSetAtom } from 'jotai';

import { AxiosError } from 'axios';
import { GetContactFormResponse } from 'services/requests/Features/Contact/schema';
import { PIsPublishShowContact } from 'store/CustomizeApp/Publish/publishAtom';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { log } from 'utility/log';

export const ContactUseQuery = () => {

	//Atoms for Contact
	const [desc, setContactDesc]      			  = useAtom(FContact);
	const [contactID, setContactID]               = useAtom(FContactID);
	const [isContactRefresh, setIsContactRefresh] = useAtom(FIsContactRefresh);
	const setFeatureChange = useSetAtom(FIsFeaturesChange);
	const setContactChanges = useSetAtom(FIsContactChanges);
	const setPublishShow = useSetAtom(PIsPublishShowContact);

	const {id} = useParams();

	const {data: contactLive, refetch: refetchContactLive} = useQuery<GetContactFormResponse, AxiosError>({
		queryKey: ['contactLive', id],
		queryFn: () => getContactByAppIDLive(id),
		enabled: false,
		retry: 0,
		onError: () => setIsContactRefresh(false)
	});

	//get request
	const {data, refetch, isFetching, isFetched} = useQuery<GetContactFormResponse, AxiosError>({
		queryKey: ['Contact Us', id],
		queryFn: () => getContactByAppID(id),
		enabled: false,
		retry: 0,
		onSuccess: (data) => {
			if(data?.contact){
				setContactDesc(data?.contact?.description);
				setContactID(data?.contact?.contactID);
				setIsContactRefresh(false);
			}else{
				setContactDesc('');
				setContactID(0);
				setIsContactRefresh(false);
			}
		},
		onError: () => setIsContactRefresh(false)
	});

	useEffect(() =>{
		if(isContactRefresh){
			refetch();
			refetchContactLive();
		}
	},[isContactRefresh]);

	// useEffect(() =>{
	// 	if(isFetching){
	// 		setFeatureChange(false);
	// 	}
	// },[isFetching]);

	useEffect(() => {
		if((data?.contact?.description || desc) && isFetched){
			if(data?.contact?.description === undefined && desc === '<p><br></p>'){
				// setFeatureChange(false);
				setContactChanges(false);
			}
			else if(data?.contact?.description !== desc){
				// setFeatureChange(true);
				setContactChanges(true);
				log('ContactChangesTrue');
			} else {
				// setFeatureChange(false);
				setContactChanges(false);
			}
		} else {
			// setFeatureChange(false);
			setContactChanges(false);
		}
	},[desc, data, isFetched]);

	useEffect(() => {
		if(data && contactLive){
			if(data?.contact?.description !== contactLive?.contact?.description){
				setPublishShow(true);
				log('ContactPublishTrue');
			}else setPublishShow(false);
		}
	},[data, contactLive]);
};