import React, { useCallback, useEffect, useRef, useState } from 'react';
import { SCardBackgroundColor, SCardBorderColor, SCardContentColor, SCardIconColor, SCardTitleColor, SNavigationColor, SNavigationHighlightColor, SNavigationIconActiveColor, SNavigationIconColor, SNavigationTextActiveColor, SNavigationTextColor, STopBarColor, STopBarTextColor } from 'store/CustomizeApp/Appearance/colorAtom';
import { classNames, useClickOutside } from 'utility';
import { useAtom, useAtomValue } from 'jotai';

import { ChromePicker } from 'react-color';
import { IMAGES } from 'utility/constant';
import { PlusIcon } from '@heroicons/react/24/outline';
import { SIsNavigationTextCheck } from 'store/CustomizeApp/Appearance/navigationAtom';
import { SThemeType } from 'store/CustomizeApp/Appearance/themeAtom';
import rgbHex from 'rgb-hex';
import Tooltip from 'components/Common/Tooltip';
import { log } from 'utility/log';

const Color: React.FC = () => {

	const popover = useRef(null);

	const [topBarColor, setTopBarColor] = useAtom(STopBarColor);
	const [topBarTextColor, setTopBarTextColor] = useAtom(STopBarTextColor);

	const [navigationColor, setNavigationColor] = useAtom(SNavigationColor);
	const [navigationIconColor, setNavigationIconColor] = useAtom(SNavigationIconColor);
	const [navigationIconActiveColor, setNavigationIconActiveColor] = useAtom(SNavigationIconActiveColor);
	const [navigationTextColor, setNavigationTextColor] = useAtom(SNavigationTextColor);
	const [navigationTextActiveColor, setNavigationTextActiveColor] = useAtom(SNavigationTextActiveColor);
	const [navigationHighlightColor, setNavigationHighlightColor] = useAtom(SNavigationHighlightColor);
	const isNavTextCheck = useAtomValue(SIsNavigationTextCheck);
	const themeType = useAtomValue(SThemeType);


	const [cardBackgroundColor, setCardBackgroundColor] = useAtom(SCardBackgroundColor);
	const [cardBorderColor, setCardBorderColor] = useAtom(SCardBorderColor);
	const [cardIconColor, setCardIconColor] = useAtom(SCardIconColor);
	const [cardTitleColor, setCardTitleColor] = useAtom(SCardTitleColor);
	const [cardContentColor, setCardContentColor] = useAtom(SCardContentColor);
	const isMaxSmScreen = window.innerWidth <= 640;

	const [customColor, setCustomColor] = useState([
		{ name: 'Top Bar', color: topBarColor, isOpen: false },
		{ name: 'Top Bar Text', color: topBarTextColor, isOpen: false },

		{ name: 'Navigation Background', color: navigationColor, isOpen: false },
		{ name: 'Navigation Icon', color: navigationIconColor, isOpen: false },
		{ name: 'Navigation Icon Active State', color: navigationIconActiveColor, isOpen: false },
		{ name: 'Navigation Text', color: navigationTextColor, isOpen: false },
		{ name: 'Navigation Text Active State', color: navigationTextActiveColor, isOpen: false },
		{ name: 'Navigation Highlight', color: navigationHighlightColor, isOpen: false },


		{ name: 'Card Background', color: cardBackgroundColor, isOpen: false },
		{ name: 'Card Border', color: cardBorderColor, isOpen: false },
		{ name: 'Card Icon', color: cardIconColor, isOpen: false },
		{ name: 'Card Title', color: cardTitleColor, isOpen: false },
		{ name: 'Card Content Text', color: cardContentColor, isOpen: false },
	]);

	// Define the onChange handler function
	const handleColorChange = (c: any, item: any) => {
		const colorHex = '#' + rgbHex(c.rgb.r, c.rgb.g, c.rgb.b, c.rgb.a);

		// Check the item name and update the corresponding state accordingly
		switch (item.name) {
			case 'Top Bar':
				setTopBarColor(colorHex);
				break;
			case 'Top Bar Text':
				setTopBarTextColor(colorHex);
				break;
			case 'Navigation Background':
				setNavigationColor(colorHex);
				break;
			case 'Navigation Icon':
				setNavigationIconColor(colorHex);
				break;
			case 'Navigation Icon Active State':
				setNavigationIconActiveColor(colorHex);
				break;
			case 'Navigation Text':
				setNavigationTextColor(colorHex);
				break;
			case 'Navigation Text Active State':
				setNavigationTextActiveColor(colorHex);
				break;
			case 'Navigation Highlight':
				setNavigationHighlightColor(colorHex);
				break;
			case 'Card Background':
				setCardBackgroundColor(colorHex);
				break;
			case 'Card Border':
				setCardBorderColor(colorHex);
				break;
			case 'Card Icon':
				setCardIconColor(colorHex);
				break;
			case 'Card Title':
				setCardTitleColor(colorHex);
				break;
			case 'Card Content Text':
				setCardContentColor(colorHex);
				break;
			default:
				// Handle any other cases here, if needed
				break;
		}
	};


	const closeCustomBackground = useCallback(() => { customColor.map((e, idx) => { if (e.isOpen) onClickAddCustomColor(idx); }); }, []);

	useClickOutside(popover, closeCustomBackground);

	const onClickAddCustomColor = (idx: number) => {
		const color = [...customColor];
		(idx === 5 && !isNavTextCheck) ? color[idx].isOpen = false : color[idx].isOpen = !color[idx].isOpen;
		setCustomColor(color);
	};

	log(cardBorderColor)
	useEffect(() => {
		const newColor = [...customColor];
		newColor[0].color = topBarColor;
		newColor[1].color = topBarTextColor;

		newColor[2].color = navigationColor;
		newColor[3].color = navigationIconColor;
		newColor[4].color = navigationIconActiveColor;
		newColor[5].color = navigationTextColor;
		newColor[6].color = navigationTextActiveColor;
		newColor[7].color = navigationHighlightColor;

		newColor[8].color = cardBackgroundColor;
		newColor[9].color = cardBorderColor;
		newColor[10].color = cardIconColor;
		newColor[11].color = cardTitleColor;
		newColor[12].color = cardContentColor;
		setCustomColor(newColor);
	}, [topBarColor, topBarTextColor,
		navigationColor, navigationIconColor, navigationIconActiveColor, navigationTextColor, navigationTextActiveColor, navigationHighlightColor,
		cardBackgroundColor, cardBorderColor, cardIconColor, cardTitleColor, cardContentColor]);

	return (
		<div className='bg-white rounded-lg my-8 mr-6 w-full'>
			<div className='w-full px-10 rounded'>
				<div className='py-5 flex-row'>
					<div className='flex justify-between items-center pt-2'>
						<span className='text-[#707070] font-bold text-2xl block'>Colors</span>
						<div className='flex gap-3 items-baseline'>
							{/* -----------Video Tutorial and Help Icons------------ */}
							<Tooltip className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
								<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.CameraIcon} alt='camera icon' />
							</Tooltip>
							<Tooltip className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
								<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.InfoIcon} alt='info icon' />
							</Tooltip>
							{/* ---------------------------------------------------- */}
						</div>
					</div>
					<hr className="w-1/2 border-gray-500"></hr>
					{customColor.map((item, idx) => (
						<div key={idx} className='flex gap-6 sm:gap-1 max-sm:gap-1 sm: items-center py-2'
						style={
							item.name === 'Top Bar Text' || item.name === 'Navigation Highlight'
							  ? {
									width: '50%;', 
									borderBottom: 'solid #d7d7d7',
								}
							  : { border: 'none' }
						  }>
							<span className={classNames('text-sm font-light w-44 text-[#707070]', ((item.name === 'Navigation Text' || item.name === 'Navigation Text Active State') && !isNavTextCheck) || (item.name === 'Navigation Highlight' && themeType != 'Fill') ? 'text-gray-400' : '')}>{item.name}</span>
							<hr className={classNames('w-10 border-gray-700', ((item.name === 'Navigation Text' || item.name === 'Navigation Text Active State') && !isNavTextCheck) || (item.name === 'Navigation Highlight' && themeType != 'Fill') ? 'border-gray-400' : '')}></hr>
							<div className='flex gap-2 items-center'>
								<div className="border" style={{borderColor: 'orange'}}>
									<div className={'w-7 h-7 flex justify-center items-center border'} style={{ backgroundColor: `${((item.name === 'Navigation Text' || item.name === 'Navigation Text Active State') && !isNavTextCheck) || (item.name === 'Navigation Highlight' && themeType != 'Fill') ? '#E4E4E4' : item.color}` }} />
								</div>
								<div className="border" style={{borderColor: 'orange'}}>
									<Tooltip
										className={'p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10'}
										message={((item.name === 'Navigation Text' || item.name === 'Navigation Text Active State') && !isNavTextCheck) ? 'Include Navigation Text must be checked.' : (item.name === 'Navigation Highlight' && themeType != 'Fill') ? 'Theme must be Fill.' : ''}
										isVisible={((item.name === 'Navigation Text' || item.name === 'Navigation Text Active State') && !isNavTextCheck) || (item.name === 'Navigation Highlight' && themeType != 'Fill')}>
										<PlusIcon
											className={classNames('h-7 w-7', ((item.name === 'Navigation Text' || item.name === 'Navigation Text Active State') && !isNavTextCheck) || (item.name === 'Navigation Highlight' && themeType != 'Fill') ? 'text-gray-400 cursor-not-allowed' : 'text-red-900 cursor-pointer')} aria-hidden='true'
											onClick={() => {
												if (!(item.name === 'Navigation Text' || item.name === 'Navigation Text Active State' || item.name === 'Navigation Highlight')) {
													onClickAddCustomColor(idx);
												} else {
													if ((item.name === 'Navigation Text' || item.name === 'Navigation Text Active State') && isNavTextCheck) {
														onClickAddCustomColor(idx);
													} else if (item.name === 'Navigation Highlight' && themeType === 'Fill') {
														onClickAddCustomColor(idx);
													}
												}
											}}
										/>
									</Tooltip>
								</div>
							</div>
							<Tooltip
								className={'p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10 -mt-1'}
								message={((item.name === 'Navigation Text' || item.name === 'Navigation Text Active State') && !isNavTextCheck) ? 'Include Navigation Text must be checked.' : (item.name === 'Navigation Highlight' && themeType != 'Fill') ? 'Theme must be Fill.' : ''}
								isVisible={((item.name === 'Navigation Text' || item.name === 'Navigation Text Active State') && !isNavTextCheck) || (item.name === 'Navigation Highlight' && themeType != 'Fill')}>
								<span
									className={classNames('text-sm font-light', ((item.name === 'Navigation Text' || item.name === 'Navigation Text Active State') && !isNavTextCheck) || (item.name === 'Navigation Highlight' && themeType != 'Fill') ? 'text-gray-400 cursor-not-allowed' : 'text-[#707070] cursor-pointer')}
									onClick={() => {
										if (!(item.name === 'Navigation Text' || item.name === 'Navigation Text Active State' || item.name === 'Navigation Highlight')) {
											onClickAddCustomColor(idx);
										} else {
											if ((item.name === 'Navigation Text' || item.name === 'Navigation Text Active State') && isNavTextCheck) {
												onClickAddCustomColor(idx);
											} else if (item.name === 'Navigation Highlight' && themeType === 'Fill') {
												onClickAddCustomColor(idx);
											}
										}
									}}>
									Add Custom Color
								</span>
							</Tooltip>
							{item.isOpen && (
								isMaxSmScreen ? (									
									<div ref={popover} className='absolute z-10 pl-5 mb-16'>
										<ChromePicker color={item.color} onChange={(c) => handleColorChange(c, item)} />
									</div>
								) : (
									// Render the div with ChromePicker when isMaxSmScreen is false
									<div ref={popover} className='absolute z-10 ml-96 md:ml-80 pl-28 mb-16'>
										<ChromePicker color={item.color} onChange={(c) => handleColorChange(c, item)} />
									</div>
								)
							)}						
						</div>
					)
					)}
				</div>
			</div>
		</div>
	);
};

export default Color;