import { FContents, FContentsRefresh } from 'store/CustomizeApp/Features/contentAtom';
import { GetContentFormResponse, GetContentFormType } from 'services/requests/Content/schema';
import { createContent, deleteContentById, editContentByAppID } from 'services/requests/Content';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { AppFeatIsSaving } from 'store/CustomizeApp/Appearance';
import { AxiosError } from 'axios';
import PrivacyMutation from '../Privacy';
import React from 'react';
import { showFailedAlert } from 'utility/alerts';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { FIsContentChanges } from 'store/CustomizeApp/Features';

const ContentMutation = () => {
	const { id } = useParams();
	//Atoms for Content
	const [contentList, setContentList] = useAtom(FContents);
	const setIsContentRefresh = useSetAtom(FContentsRefresh);
	const setIsSaving = useSetAtom(AppFeatIsSaving);
	const isThereChanges = useAtomValue(FIsContentChanges);
	const {savePrivacy} = PrivacyMutation();

	const queryClient = useQueryClient();
	
	//Post Content
	const { mutate: createContentMU } = useMutation<
		GetContentFormResponse,
		AxiosError,
		GetContentFormType
	>((data) => createContent(id, data), {
		onSuccess: () => {
			queryClient.invalidateQueries('content');
			setIsContentRefresh(true);
		},
		onError: () => {
			setIsSaving(false);
			showFailedAlert('Cannot save content');
		},
	});

	//Put Content
	const { mutate: editContent } = useMutation<
		GetContentFormResponse,
		AxiosError,
		GetContentFormType
	>((data) => editContentByAppID(id, data.id, data), {
		onSuccess: () => {
			queryClient.invalidateQueries('content');
			setIsContentRefresh(true);
		},
		onError: () => {
			setIsSaving(false);
			showFailedAlert('Cannot send successfully.');
		},
	});

	//Delete Content
	const { mutate: deleteContent } = useMutation<
		null,
		AxiosError,
		number
	>((contentID) => deleteContentById(id, contentID), {
		onSuccess: () => {
			queryClient.invalidateQueries('content');
			setIsContentRefresh(true);
		},
		onError: () => {
			setIsSaving(false);
			showFailedAlert('Cannot save content');
		},
	});

	const saveContent = () => {
		if(isThereChanges){
			contentList.map((item, idx) => {
				if (item.id) {
					if (item.toDelete == true) {
						deleteContent(item.id);
					} else {
						editContent({
							id: item.id,
							icon: item.icon,
							title: item.title,
							body: item.description,
						});
					}
				} else if (item.id === 0 && item.toDelete === false) {
					createContentMU({
						icon: item.icon,
						title: item.title,
						body: item.description,
					});
				}
			
				if (idx === contentList.length - 1) {
					const updatedContents = contentList.filter((content) => content.toDelete === false);
					
					setContentList(updatedContents);
					savePrivacy();
				} 
			});
		} else {
			savePrivacy();
		}
		
	};

	return {saveContent,};
    
};

export default ContentMutation;