import Button from 'components/Common/Buttons/Button';
import React, { useState } from 'react';
import Modal from '../Modal';

interface SubCategoryModal {
	isSubCategModalShow: boolean;
	setSubCategModalShow: React.Dispatch<React.SetStateAction<boolean>>;
	onClickOK?: (e: any) => void;
}

export const SubCategoryModal: React.FC<SubCategoryModal> = ({isSubCategModalShow, setSubCategModalShow, onClickOK}) => {
	
	const renderSubCategModal = (): JSX.Element => {
		return(
			<>
				<div className='sm:mx-auto sm:w-full sm:max-w-md'>
					<div className='py-[28px] px-[60px] sm:rounded sm:px-10'>
						<div className='flex items-center justify-center' />
                        
						<p className='text-base font-bold text-[#707070] text-center mt-[15px]'> 
                            Choose an Industry Subcategory
						</p>

						<p className='text-base text-[#707070] text-center mt-[15px]'> 
                            Please select an Industry Subcategory first before proceeding to other customization section.
						</p>
						<div className='bg- flex flex-row gap-[42px] justify-center mt-[25px]'>
							<Button variant='orange' className=' px-[42px] py-1.5' onClick={onClickOK}>Ok</Button>
						</div>
					</div>
				</div>
			</>
		);
	};

	return (
		<div>
			<Modal open={isSubCategModalShow} onClose={setSubCategModalShow} className='sm:max-w-md'>
				{renderSubCategModal()}
			</Modal>
		</div>
	);
};

