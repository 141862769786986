import * as React from 'react';
import { styled } from '@mui/material/styles';
import Radio, { RadioProps } from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useState } from 'react';
import { classNames } from 'utility';
import { PaymentFormModal } from '../Modals/PaymentFormModal';
import { useAtom, useAtomValue } from 'jotai';
import { payFormModal, saveCardInfo } from 'store/Payment/successPaymentAtom';
import { AddCardRequest, CardResponse, SetDefaultPaymentMethodRequest, SetDefaultPaymentMethodResponse } from 'services/requests/EditProfile/CardManagement/schema';
import { AxiosError } from 'axios';
import { addCard, getDefaultCard, setDefaultCard } from 'services/requests/EditProfile/CardManagement';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { showFailedAlert, showSuccessAlert } from 'utility/alerts';
import { isDefaultSelected, paymentMethodID } from 'store/CustomizeApp/Payment/subscription';
import { TErrorResponse } from 'services/requests/auth/schema';
import { log } from 'utility/log';

const BpIcon = styled('span')(({ theme }) => ({
	borderRadius: '50%',
	width: 16,
	height: 16,
	boxShadow:
    theme.palette.mode === 'dark' ? '0 0 0 1px rgb(16 22 26 / 40%)' : 'inset 0 0 0 1px rgba(224,120,29), inset 0 -1px 0 rgba(16,22,26,.1)',
	backgroundColor: theme.palette.mode === 'dark' ? '#E0781D' : '#f5f8fa',
	backgroundImage:
    theme.palette.mode === 'dark' ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))' : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
	'.Mui-focusVisible &': {
		outline: '2px auto rgba(19,124,189,.6)',
		outlineOffset: 2,
	},
	'input:hover ~ &': {
		backgroundColor: theme.palette.mode === 'dark' ? '#E0781D' : '#E0781D',
	},
	'input:disabled ~ &': {
		boxShadow: 'none',
		background:
      theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
	},
}));

const BpCheckedIcon = styled(BpIcon)({
	backgroundColor: '#E0781D',
	backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
	'&:before': {
		display: 'block',
		width: 16,
		height: 16,
		backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
		content: '""',
	},
	'input:hover ~ &': {
		backgroundColor: '#E0781D',
	},
});

export const RadioPaymentMethod: React.FC = () => {

	const [activeOne, setActiveOne]     = useState(false);
	const [activeTwo, setActiveTwo]     = useState(false);
	const [value, setValue]             = useState('existing');
	const [formShow, setFormShow]       = useAtom(payFormModal);
	const isSavedCard										= useAtomValue(saveCardInfo);
	const queryClient 									= useQueryClient();
	const [defaultId, setDefaultId] 		= useAtom(paymentMethodID);
	const [defaultSelected, setDefaultSelected] = useAtom(isDefaultSelected);
	//const [pmError, setPmError] = useState<string>(''); 

	const { mutate: addCardMu, isLoading: addCardLoading} = 
		useMutation<CardResponse, AxiosError, AddCardRequest>(
			data => addCard(data), {
				onSuccess: (res) => {
					log('Card added successfully!',res);
					setFormShow(!formShow);
					setDefaultCardMu({
						paymentMethodId: res.Id,
					});
					setDefaultId(res.Id);
					showSuccessAlert('You have saved your card information');
					// setActiveOne(true);
					// setActiveTwo(false);
				},
				onError: (err) => {
					log('Error adding card: ', err);
					setFormShow(!formShow);

					const errData: TErrorResponse = err.response?.data as TErrorResponse;
					const er = errData.error?.message;
					const hasError = er.includes('string=');
					let pmError = '';

					if (hasError) {
						if (er.includes('invalid_number')) {
							pmError = 'Invalid number.';
						} else if (er.includes('expired_card')) {
							pmError = 'Your card has expired.' ;
						} else if (er.includes('incorrect_cvc')) {
							pmError = 'Your card\'s security code is incorrect.';
						} else if (er.includes('processing_error')) {
							pmError = 'An error occurred while processing your card. Try again in a little bit.';
						} else if (er.includes('card_declined')) {
							pmError = 'Your card was declined.';
						}
					}

					log('errStr:', pmError);
					showFailedAlert(`Error saving card: ${pmError}`);
				}
			}
		);

	const { data: defaultCard } = useQuery<CardResponse, AxiosError>({
		queryKey: ['ListCards'],
		queryFn: () => getDefaultCard(),
		staleTime: 5000,
		retry: 0
	});

	// const { data: defaultCard } = useQuery(['ListCards'], getDefaultCard);

	const { mutate: setDefaultCardMu, isLoading: defaultCardLoad } = 
	useMutation<SetDefaultPaymentMethodResponse, AxiosError, SetDefaultPaymentMethodRequest>(
		data => setDefaultCard(data), {
			onSuccess: () => {
				queryClient.invalidateQueries(['ListCards']);
				// setValue('existing');
				setActiveOne(true);
				setActiveTwo(false);
				setDefaultSelected(true);
				showSuccessAlert('New default payment method is set.');
				// setDefaultId('');
			},
			onError: (err) => {
				// showFailedAlert('Error in setting new payment method.');
				log('Error:', err);
			}
		}	
	);

	function BpRadio(props: RadioProps) {
		return (
			<Radio
				disableRipple
				color='default'
				// checkedIcon={value === 'existing' ? <BpCheckedIcon /> : value === 'new' ? <BpCheckedIcon /> : <BpIcon />}
				// icon={value === 'existing' ? <BpIcon /> : value === 'new' ? <BpIcon /> : <BpCheckedIcon /> }
				checkedIcon={<BpCheckedIcon />}
				icon={<BpIcon />}
				{...props}
			/>
		);
	}
	const handleShowModal = () => {
		setFormShow(!formShow);
	};

	const handleSaveModal = (pmId: string, cardName: string) => {
		// log('payment method Id: ', pmId);
		// log('cardName: ', cardName);
		addCardMu({
			paymentMethodId: pmId,
			cardHolderName: cardName
		});
		
		
		setFormShow(!formShow);
	};

	const handleClick = (event: any) => {
		if (event.target.value === 'existing') {
			setValue('existing');
			setActiveOne(true);
			setActiveTwo(false);
			const pmId = defaultCard?.Id ?? '';
			setDefaultId(pmId);
			setDefaultSelected(true);
		} else {
			setValue('new');
			setActiveTwo(true);
			setActiveOne(false);
			setFormShow(!formShow);
			setDefaultId('');
			setDefaultSelected(false);
		}
	};

	const handleClickModal = () => {
		if (activeTwo === true){
			setFormShow(!formShow);
		}
	};

	// log(defaultCard?.CardHolderName);

	return (
		<div>
			<FormControl>
				<RadioGroup
					// defaultValue='new'
					aria-labelledby='demo-customized-radios'
					name='customized-radios'
					// sx={{styles}}
				>
					<div className='flex flex-col gap-7 pb-7'>
						{ defaultCard?.IsDefault &&
							<div 
								className={classNames(
									activeOne ? 
										'border-primary border-2' : 
										'border-gray-200 border-2', 
									'rounded-md border px-5 pb-5 pt-4'
								)}>
								<FormControlLabel 
									value='existing' 
									control={<BpRadio checked={activeOne} onChange={handleClick} />} 
									label='Use current payment method' 
								/>
								<p className='ml-[1.4rem] text-xs text-[#707070] font-normal'>
									{defaultCard?.CardHolderName}
								</p>
								<div className='flex gap-2 ml-[1.4rem] text-xs text-[#707070] font-normal'>
									<p>
										{defaultCard?.Brand}
									</p>
									<p>
										****{defaultCard?.Last4}
									</p>
								</div>
							</div>
						}		
						<div 								
							onClick={handleClickModal}
							className={classNames(
								activeTwo ? 
									'border-primary border-2' : 
									'border-gray-200 border-2', 
								'rounded-md border px-5 pb-5 pt-4 cursor-pointer'
							)}>
							<FormControlLabel 
								value='new' 
								control={<BpRadio checked={activeTwo} onClick={handleClick} /> } 
								label='Enter new payment method'
							/>
							<p className='ml-[1.50rem] text-[#707070] font-normal text-xs'>
								New payment information will replace the current
							</p>
						</div>
					</div>				
				</RadioGroup>
			</FormControl>
			<PaymentFormModal 
				isPaymentModalShow={formShow} 
				setPaymentModalShow={handleShowModal} 
				onClickCancel={handleShowModal} 
				onClickSave={handleSaveModal}
			/>
		</div>	
	);
};