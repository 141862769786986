import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import React, { useEffect, useState } from 'react';

import Button from 'components/Common/Buttons/Button';
import ReactPaginate from 'react-paginate';
import { classNames } from '../../../utility';
import { useMediaQuery } from 'react-responsive';

// import useWindowDimensions from '../../../hooks/window';
// import { MOBILE_MAX_WIDTH } from '../../../constants/layout';

interface PaginationProps {
  stripped?: boolean;
  totalPages?: number;
  page: number;
  className?: string;
  isLoading?: boolean;
  onChange: ({ selected }: { selected: number }) => void;
}

const Pagination = (props: PaginationProps) => {
	// const { width } = useWindowDimensions();
	const [page, setPage] = useState(0);
	const lowRes = useMediaQuery({ maxWidth: 800 });

	const isFirstPage = props.page === 1;
	const isLastPage = props.page === (props.totalPages ? props.totalPages : 0);
  
	useEffect(() => {
		setPage(props.page);
	}, [props.page]);
  
	function onClickJumpToFirst() {
		props.onChange({selected: 0});
	}
  
	function onClickJumpToLast() {  
		props.onChange({selected: props.totalPages ? props.totalPages - 1 : 0});
	}
  
	return (
		<div className={classNames(
			'flex flex-row flex-1 items-end',
			props.className || '',
			!props.totalPages ? 'hidden' : ''
		)}>
			{/* <button 
				onClick={onClickJumpToFirst}
				disabled={isFirstPage} 
				className={classNames(
					'border border-gray-300 rounded py-1 px-2 m-1',
					isFirstPage ? 'bg-custom-gray-120 text-custom-gray-200' : 'bg-orange-500 text-white',
					props.stripped ? 'hidden' : ''
				)}
				style={{ cursor: isFirstPage ? 'not-allowed' : 'pointer' }}
			>
				<ChevronDoubleLeftIcon className="h-6 w-5 text-custom-gray-200" aria-hidden="true" />
			</button> */}
			<ReactPaginate 
				className={`${props.isLoading ? 'hidden' : 'flex'} items-center flex-row justify-center`}
				pageClassName="relative z-0 inline-flex shadow-sm -space-x-px m-1 font-bold"
				pageLinkClassName="border-1 w-[35px] h-[35px] border-orange-500 relative inline-flex items-center justify-center px-2 min-w-pagination-item py-1 border text-sm rounded-lg text-orange-500 hover:bg-orange-600 hover:text-white"
				disabledClassName="bg-custom-gray-120 text-[#F6F6F6]"
				activeLinkClassName="text-white bg-orange-500 border-orange-500 hover:bg-orange-600"
				previousClassName={`relative inline-flex items-center px-2 py-1 border border-[#F6F6F6] text-sm font-medium rounded-lg text-${isFirstPage ? '[#F6F6F6]' : 'orange-500'} bg-[#F6F6F6]`}
				nextClassName={`relative inline-flex items-center px-2 py-1 border border-[#F6F6F6] text-sm font-medium rounded-lg text-${isLastPage ? '[#F6F6F6]' : 'orange-500'} bg-[#F6F6F6]`}
				breakClassName="relative inline-flex items-center px-2 py-1 border border-[#F6F6F6] text-lg font-bold rounded-lg text-orange-500"
				breakLabel="..."
				nextLabel={
					<>
						{/* <span className="sr-only">Next</span>
						<ChevronRightIcon className="h-5 w-5 text-white" aria-hidden="true" /> */}
						<button
							className={`pr-[10px] pl-[10px] relative text-sm font-semibold rounded 2xl:w-[50px] 2xl:h-[20px] xl:w-[50px] xl:h-[20px] lg:w-[50px] lg:h-[20px] md:w-[50px] md:h-[20px] sm:h-[20px] sm:w-[50px] max-sm:h-[20px] max-sm:w-full whitespace-nowrap text-${isLastPage ? 'gray-400' : 'orange-500'} bg-[#F6F6F6]`}
							disabled={isLastPage}
							style={{ cursor: isLastPage ? 'not-allowed' : 'pointer' }}>
							{lowRes ? '>' : 'Next>'}
						</button>
					</>
				}
				previousLabel={
					<>
						{/* <span className="">Previous</span>
						<ChevronLeftIcon className="h-5 w-5 text-white" aria-hidden="true" /> */}
						<button
							className={`pr-[10px] pl-[10px] relative text-sm font-semibold rounded 2xl:w-[50px] 2xl:h-[20px] xl:w-[50px] xl:h-[20px] lg:w-[50px] lg:h-[20px] md:w-[50px] md:h-[20px] sm:h-[20px] sm:w-[50px] max-sm:h-[20px] max-sm:w-full whitespace-nowrap text-${isFirstPage ? 'gray-400' : 'orange-500'} bg-[#F6F6F6]`}
							disabled={isFirstPage}
							style={{ cursor: isFirstPage ? 'not-allowed' : 'pointer' }}>
							{lowRes ? '<' : '<Prev'}
						</button>
					</>
				}
				onPageChange={props.onChange}
				pageRangeDisplayed={1}
				pageCount={props.totalPages ? props.totalPages : 0}
				forcePage={page-1}
				renderOnZeroPageCount={() => null}
			/>
			{/* <button 
				type="button"
				onClick={onClickJumpToLast}
				disabled={isLastPage} 
				className={classNames(
					'border border-gray-300 rounded py-1 px-2 m-8',
					isLastPage ? 'bg-custom-gray-120 text-custom-gray-200' : 'bg-orange-500 text-white',
					props.stripped ? 'hidden' : ''
				)}
				style={{ cursor: isFirstPage ? 'not-allowed' : 'pointer' }}
			>
				<ChevronDoubleRightIcon className="h-6 w-5 text-custom-gray-200" aria-hidden="true" />
			</button> */}
		</div>
	);
};

export default Pagination;