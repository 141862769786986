import z from 'zod';
const passwordSchema = z
	.string()
	.min(8, { message: 'Password must contain a minimum of 8 characters.' })
	.regex(/[a-z]/, { message: 'Password should have at least 1 lowercase character.' })
	.regex(/[A-Z]/, { message: 'Password should have at least 1 uppercase character.' })
	.regex(/\d/, { message: 'Password should have at least 1 number.' })
	.regex(/[@$!%*#?&.]/, { message: 'Password should have at least 1 special character.' });
//ResetPassSchema Object
export const ChangePasswordSchema = z.object({
	email: z.string().email(),
	currentPassword: z.string(),
	newPassword: passwordSchema,
	confirmPassword: z.string(),
}).superRefine(({ confirmPassword, newPassword }, ctx) => {
	if (confirmPassword !== newPassword) {
		ctx.addIssue({
			code: 'custom',
			message: 'The password did not match',
			path: ['confirmPassword'],
		});
	}
});


const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/;


export type ChangePasswordResponseType = z.infer<typeof ChangePasswordSchema>;