import { zodResolver } from '@hookform/resolvers/zod';
import { AxiosError } from 'axios';
import Button from 'components/Common/Buttons/Button';
import FormInput from 'components/Common/Forms/Inputs';
import { useSetAtom } from 'jotai';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { resetPasswordSendEmailApi } from 'services/requests/EditProfile/PasswordSettings/ResetPassword';
import { ResetPasswordSendEmailRequest, ResetPasswordSendEmailResponse, resetPasswordSendEmailSchema } from 'services/requests/EditProfile/PasswordSettings/ResetPassword/schema';
import { resetEmailAtom } from 'store/EditProfile/resetPassword';
import { showFailedAlert } from 'utility/alerts';

const ResetPasswordEmail:React.FC = () => {
	const navigate = useNavigate();
	const setResetEmail = useSetAtom(resetEmailAtom);

	const userEmail = localStorage.getItem('email')?.replaceAll('"','');

	const { control, handleSubmit, formState, setError } = useForm<ResetPasswordSendEmailRequest>({
		defaultValues: { email: ''},
		mode: 'onBlur',
		resolver: zodResolver(resetPasswordSendEmailSchema),
	});

	const { mutate: resetPasswordEmailSendMU, isLoading: resetEmailSending } = useMutation<ResetPasswordSendEmailResponse, AxiosError, ResetPasswordSendEmailRequest>(
		(data) => resetPasswordSendEmailApi(data), {
			onSuccess: (res) => {
				console.log(res);
				
				if(res.success) {
					setResetEmail(res.email);
					navigate('/reset-password-verify');
					
				} 
				else {
					setResetEmail('');
					showFailedAlert('Error sending email. Please try again'); 
				}
			},
			onError: (err) => {
				setResetEmail('');
				console.log(err);
				showFailedAlert('Error sending email. Please try again');
			}
		}
	);

	const onSubmit = (data: ResetPasswordSendEmailRequest) => {
		if(userEmail !== data.email) { //validate if the inputed email is the same with currently logged-in email
			console.log('userEmail: ', userEmail, 'local email: ', data.email);
			setError('email', {
				type: 'value',
				message: 'The email you provide is not match with your currently logged-in email.'
			});
			return;
		}

		resetPasswordEmailSendMU(data);
	};

	return (
		<div className='flex justify-center w-full h-full my-[150px]'>
			<div className='p-[40px] h-fit w-[532px] bg-[#FFFFFF] rounded-[10px] shadow-xl'>
				<p className='text-[#464255] text-[28px] font-bold font-[montserrat] mt-9 h-fit mb-[27px] flex justify-center items-center leading-[34px]'>Reset Your Password</p>
				<p className='text-[#707070] font-poppins flex justify-center items-center'>Enter your email address and we&apos;ll send an email.</p>
				<p className='text-[#707070] font-poppins flex mb-[13px] justify-center items-center'>with instructions to reset your password.</p>
            
				<form className='w-full'>
					<div className='mt-7'>
						<Controller
							control={control}
							name='email'
							render={({field: {name, onChange}, fieldState: { error }}) =>(
								<div>
									<FormInput
										label='Email Address'
										onChange={onChange}
										name={name}
										error={error?.message}
										type='email'
										placeholder='johndoe@gmail.com'
									/>
								</div>
							)}
						/>
					</div>
					<div className='mt-7'>
						<Button
							className='w-full py-1.5'
							onClick={handleSubmit(onSubmit)}
							type='submit'
							variant='primary'
							disabled={!formState.dirtyFields.email || !!formState.errors.email || resetEmailSending}
						>
							<span className='text-sm font-semibold'>
								{resetEmailSending ? 'Sending. . . Please wait.'  : 'Continue'}
							</span>
						</Button>
					</div>
					<div className='mt-3'>
						<Button
							type='button'
							variant='secondary'
							onClick={()=> { navigate('/edit-profile');}}
							className='w-full py-1.5'
						>
							<span className='text-sm font-semibold'>Cancel</span>
						</Button>
					</div>
				</form>
			</div>
			
		</div>
	);

};

export default ResetPasswordEmail;