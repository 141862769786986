import { axiosInstance } from 'services/axios';

import { AddCardSchema, AddCardRequest, CardResponse, GetListCardResponse, DeleteCardResponse, DeleteCardRequest, SetDefaultPaymentMethodRequest, SetDefaultPaymentMethodSchema, SetDefaultPaymentMethodResponse } from './schema';
import { PAYMENT_CARD, PAYMENT_CARD_ATTACH, PAYMENT_CARD_DEFAULT, PAYMENT_CARD_DELETE } from 'services/api';

export const addCard = async (data?: AddCardRequest) => {
	const addCard = AddCardSchema.parse(data);
	const response = await axiosInstance.post(PAYMENT_CARD_ATTACH, addCard);

	return response.data as CardResponse;
};

export const getListCards = async () => {
	const response = await axiosInstance.get(PAYMENT_CARD);
	return response.data as GetListCardResponse;
};

export const deleteCard = async (paymentMethodId?: string) => {
	const response = await axiosInstance.delete(`${PAYMENT_CARD_DELETE}/${paymentMethodId}`);
	return response.data as DeleteCardResponse;
};

export const setDefaultCard = async (data?: SetDefaultPaymentMethodRequest) => {
	const setDefault = SetDefaultPaymentMethodSchema.parse(data);
	const response = await axiosInstance.put(PAYMENT_CARD_DEFAULT, setDefault);

	return response.data as SetDefaultPaymentMethodResponse;
};

export const getDefaultCard = async () => {
	const response = await axiosInstance.get(PAYMENT_CARD_DEFAULT);
	return response.data as CardResponse;
};