import React, {useState} from 'react';

const AccordionFAQs = (props: { datas: any; }) => {

	const [item, setItem] = useState(props.datas);
	const handletoggleActive = () => {
		const newActive = item.active === 1 ? 0 : 1;
		setItem({...item, active: newActive});
	};

	return(
		<div className=
			{`bg-white lg:p-2 md:px-[8rem] border mb-5 border-white rounded-md w-[900px] max-sm:w-[320px] duration-500 group 
			${item.active === 1 ? 'is-active bg-white': ''}`
			}>
			<div className='flex items-center gap-5'>
				<div className='w-full duration-500 group-[.is-active]: font-bold bg-white text-[#c34115]'>
					{item.question}
				</div>
				<div className='text-xl duration-500 cursor-pointer'
					style={{
						transform: `${
						  item.active === 1
							? 'rotate(90deg) scale(-1)'
							: 'rotate(90deg) scale(1)'
						}`,
					  }}
					onClick={handletoggleActive}>{'>'}
				</div>
			</div>
			<div className='
				overflow-hidden 
				bg-white 
				max-h-0 
				duration-500 
				group-[.is-active]:max-h-[150px] 
				sm:group-[.is-active]:max-h-[100px] 
				md:group-[.is-active]:max-h-[150px] 
				lg:group-[.is-active]:max-h-[100px] 
				text-[12px]
				sm:text-[16px]'>
				{item.answer}
			</div>
		</div>
	);
};
export default AccordionFAQs;