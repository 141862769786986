import {
	FIsFeaturesChange,
	FIsSocialMediaChanges,
} from 'store/CustomizeApp/Features';
import {
	FSocialMediaInitVal,
	FSocialMediasAtom,
	FsocialMediasAtom,
	FsocialMediasRefreshAtom,
} from 'store/CustomizeApp/Features/socialMediaAtom';
import {
	getAllSocialAction,
	getAllSocialActionLive,
} from 'services/requests/Features/SocialMedia';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { AxiosError } from 'axios';
import { PIsPublishShowSocMed } from 'store/CustomizeApp/Publish/publishAtom';
import { getAllSocialMediaResponse } from 'services/requests/Features/SocialMedia/schema';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { log } from 'utility/log';

export const SocialMediaUseQuery = () => {
	const [socialMedias, setSocialMedias] = useAtom(FSocialMediasAtom);
	const [isSocMedRefresh, setIsSocMedRefresh] = useAtom(
		FsocialMediasRefreshAtom
	);
	const defaultInit = useAtomValue(FSocialMediaInitVal);
	const setFeaturesChanges = useSetAtom(FIsFeaturesChange);
	const setSocialMediaChanges = useSetAtom(FIsSocialMediaChanges);
	const setPublishShow = useSetAtom(PIsPublishShowSocMed);
	const { id } = useParams();

	const { data: socialMediaLive, refetch: refetchSocMedLive } = useQuery<
		getAllSocialMediaResponse,
		AxiosError
	>({
		queryKey: ['SocialMediasLive', id],
		queryFn: () => getAllSocialActionLive(Number(id)),
		enabled: false,
		retry: 0,
		onError: () => setIsSocMedRefresh(false),
	});

	//get request
	const { data, refetch, isFetching, isFetched } = useQuery<
		getAllSocialMediaResponse,
		AxiosError
	>({
		queryKey: ['SocialMedias', id],
		queryFn: () => getAllSocialAction(Number(id)),
		enabled: false,
		retry: 0,
		onSuccess: (data) => {
			setSocialMedias([]);
			if (data?.rows) {
				data?.rows?.map((socmed) =>
					setSocialMedias((prev) => [
						...prev,
						{
							appId: String(socmed.appID),
							socialMediaID: String(socmed.socialMediaID),
							icon: socmed.icon,
							label: socmed.label,
							WebsiteLink: socmed.websiteLink,
							sortOrder: socmed.sortOrder,
						},
					])
				);
				setIsSocMedRefresh(false);
			} else {
				setSocialMedias([]);
				setIsSocMedRefresh(false);
			}
		},
		onError: () => {
			setSocialMedias([defaultInit]);
			setIsSocMedRefresh(false);
		},
	});

	useEffect(() => {
		if (isSocMedRefresh) {
			// setSocialMedias([]);
			refetch();
			refetchSocMedLive();
		}
	}, [isSocMedRefresh]);

	// useEffect(() => {
	// 	if (isFetching) {
	// 		setFeaturesChanges(false);
	// 	}
	// }, [isFetching]);

	useEffect(() => {
		if (
			(data?.rows !== undefined || socialMedias.length !== 0) &&
			isFetched
		) {
			const hasBlankFields = socialMedias.some((temp) => {
				const trimLabel = temp.label?.trim();
				const trimWebsiteLink = temp.WebsiteLink?.trim();
				return trimLabel?.length === 0 || trimWebsiteLink?.length === 0;
			});

			if (hasBlankFields) {
				// setFeaturesChanges(false);
				setSocialMediaChanges(false);
			} else {
				const checkChanges = data?.rows?.map((current) => ({
					match: socialMedias?.some(
						(temp) =>
							temp.label === current.label &&
							temp.icon === current.icon &&
							temp.WebsiteLink === current.websiteLink
					),
				}));

				const orderChanges = data?.rows?.map((current, idx) => ({
					match:
						socialMedias[idx]?.label === current.label &&
						socialMedias[idx]?.icon === current.icon &&
						socialMedias[idx].WebsiteLink === current.websiteLink,
				}));

				const checkSocMed = socialMedias?.filter(
					(data) => data.WebsiteLink === ''
				);

				if (
					(data?.rows?.length !== socialMedias?.length &&
						checkSocMed.length === 0) ||
					checkChanges?.find((check) => check.match === false) ||
					orderChanges?.find((check) => check.match === false) !==
						undefined
				) {
					// setFeaturesChanges(true);
					setSocialMediaChanges(true);
					log(
						'SocMedChangeTrue',
						data?.rows?.length,
						socialMedias?.length,
						checkSocMed.length
					);
				} else {
					// setFeaturesChanges(false);
					setSocialMediaChanges(false);
				}
			}
		}
	}, [data, socialMedias, isFetched]);

	useEffect(() => {
		if (data?.rows !== undefined && socialMediaLive?.rows !== undefined) {
			const checkDraftLive = data?.rows?.map((current) => ({
				match: socialMediaLive?.rows?.some(
					(live) =>
						live.label === current.label &&
						live.icon === current.icon &&
						live.websiteLink === current.websiteLink
				),
			}));

			if (
				checkDraftLive.find((check) => check.match === false) !==
					undefined ||
				data?.rows?.length !== socialMediaLive?.rows?.length
			) {
				setPublishShow(true);
				log('SocMedPublishShowTrue');
			} else setPublishShow(false);
		}
	}, [data?.rows, socialMediaLive?.rows]);
};
