import { AxiosError } from 'axios';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getFaviconByID, getFaviconByIDLive } from 'services/requests/appearance/images/favicon';
import { GetFaviconResponse } from 'services/requests/appearance/images/favicon/schema';
import { getOgTagsByID, getOgTagsByIDLive } from 'services/requests/appearance/images/og_tags';
import { GetOgTagsResponse } from 'services/requests/appearance/images/og_tags/schema';
import { SFaviconID, SIsAppearanceChanges, SIsFaviconChanges, SisFaviconDone, SIsOgTagsChanges, SisOgTagsDone, SOgTagsID } from 'store/CustomizeApp/Appearance';
import { SFaviconImgID, SFaviconPreviewImage, SFaviconUploadFile, SIsFaviconRefresh } from 'store/CustomizeApp/Appearance/imagesAtoms/favicon';
import { SOgTagsPreviewImage, SOgTagsImgID, SIsOgTagsRefresh, SOgTagsUploadFile } from 'store/CustomizeApp/Appearance/imagesAtoms/ogTagsAtoms';
import { PIsPublishShowFavicon } from 'store/CustomizeApp/Publish/publishAtom';
import { log } from 'utility/log';

export const ImagesUseQuery = () => {
	
	const [faviconID, setFaviconID]                 = useAtom(SFaviconID);
	const [previewFavicon, setFaviconImg]           = useAtom(SFaviconPreviewImage);
	const [uploadIDFavicon, setFaviconImgID]	    = useAtom(SFaviconImgID);
	const [isFaviconRefresh, setIsFaviconRefresh]   = useAtom(SIsFaviconRefresh);
	const [, setIsFaviconDone]         				= useAtom(SisFaviconDone);
	const uploadFileFavicon					        = useAtomValue(SFaviconUploadFile);
	const setAppearanceChanges 				        = useSetAtom(SIsAppearanceChanges);
	const setFaviconChanges                         = useSetAtom(SIsFaviconChanges);
	const setPublishShow                            = useSetAtom(PIsPublishShowFavicon);

	const [ogTagsID, setOgTagsID]                 = useAtom(SOgTagsID);
	const [preview, setOgTagsImg]                 = useAtom(SOgTagsPreviewImage);
	const [uploadID, setOgTagsImgID]			  = useAtom(SOgTagsImgID);
	const [isOgTagsRefresh, setIsOgTagsRefresh]   = useAtom(SIsOgTagsRefresh);
	const [, setIsOgTagsDone]         			  = useAtom(SisOgTagsDone);
	const uploadFileOgTags					      = useAtomValue(SOgTagsUploadFile);
	const setOgTagsChanges                        = useSetAtom(SIsOgTagsChanges);
    
	const {id} = useParams();

	// Favicon
	const {data: faviconLive, refetch: refetchFaviconLive} = useQuery<GetFaviconResponse, AxiosError>({
		queryKey: ['faviconLive', id],
		queryFn: () => getFaviconByIDLive(id),
		enabled: false,
		retry: 0,
		onError: (error) => setIsFaviconRefresh(false)
	});

	const {data: faviconData, refetch: refetchFavData, isFetching: isFavDataFetching, isFetched: isFavDataFetched} = useQuery<GetFaviconResponse, AxiosError>({
		queryKey: ['favicon', id],
		queryFn: () => getFaviconByID(id),
		enabled: false,
		retry: 0,
		onSuccess: (data) => {
			if(data?.favicon){
				setFaviconID(data?.favicon?.faviconID);
				setFaviconImg(data?.favicon?.imageURL);
				setFaviconImgID(data?.favicon?.imageUploadID);
				setIsFaviconRefresh(false);
				setIsFaviconDone(true);
			}else{
				setFaviconID(0);
				setFaviconImg('');
				setFaviconImgID(0);
				setIsFaviconRefresh(false);
			}
		},
		onError: (error) => setIsFaviconRefresh(false)
	});

	//OG Tags
	const {data: ogTagsLive, refetch: refetchOgTagsLive} = useQuery<GetOgTagsResponse, AxiosError>({
		queryKey: ['ogTagsLive', id],
		queryFn: () => getOgTagsByIDLive(id),
		enabled: false,
		retry: 0,
		onError: (error) => setIsOgTagsRefresh(false)
	});

	const {data: ogTagsData, refetch: refetchOgTagsData, isFetching: isOgTagsFetching, isFetched: isOgTagsFetched} = useQuery<GetOgTagsResponse, AxiosError>({
		queryKey: ['OGTag', id],
		queryFn: () => getOgTagsByID(id),
		enabled: false,
		retry: 0,
		onSuccess: (data) => {
			if(data?.OGTag){
				setOgTagsID(data?.OGTag?.OGTagID);
				setOgTagsImg(data?.OGTag?.imageURL);
				setOgTagsImgID(data?.OGTag?.imageUploadID);
				setIsOgTagsRefresh(false);
				setIsOgTagsDone(true);
			}else{
				setOgTagsID(0);
				setOgTagsImg('');
				setOgTagsImgID(0);
				setIsOgTagsRefresh(false);
			}
		},
		onError: (error) => setIsOgTagsRefresh(false)
	});

	// Favicon UseEffects
	useEffect(() =>{
		if(isFaviconRefresh){
			refetchFavData();
			refetchFaviconLive();
		}
	},[isFaviconRefresh]);

	useEffect(() =>{
		if(isFavDataFetching){
			setAppearanceChanges(false);
		}
	},[isFavDataFetching]);

	useEffect(() => {
		if(faviconData?.favicon && faviconLive?.favicon){
			const imgId = faviconData?.favicon?.imageUploadID && faviconData?.favicon?.imageUploadID + 1;
			if(faviconData?.favicon?.imageUploadID && imgId !== faviconLive?.favicon?.imageUploadID){
				setPublishShow(true);
				log('faviconpublishtrue');
			}else setPublishShow(false);
		}
	},[faviconData, faviconLive]);

	// OG Tags UseEffects
	useEffect(() =>{
		if(isOgTagsRefresh){
			refetchOgTagsData();
			refetchOgTagsLive();
		}
	},[isOgTagsRefresh]);

	useEffect(() =>{
		if(isOgTagsFetching){
			setAppearanceChanges(false);
		}
	},[isOgTagsFetching]);

	useEffect(() => {
		if(ogTagsData?.OGTag && ogTagsLive?.OGTag){
			const imgId = ogTagsData?.OGTag?.imageUploadID && ogTagsData?.OGTag?.imageUploadID + 1;
			if(ogTagsData?.OGTag?.imageUploadID && imgId !== ogTagsLive?.OGTag?.imageUploadID){
				setPublishShow(true);
				log('ogTagspublishtrue');
			}else setPublishShow(false);
		}
	},[ogTagsData, ogTagsLive]);

	useEffect(() => {
		if(((faviconData?.favicon || previewFavicon || uploadFileFavicon) && (isFavDataFetched))
            || ((ogTagsData?.OGTag || preview || uploadFileOgTags) && isOgTagsFetched)){
			if(faviconData?.favicon?.imageURL && faviconData?.favicon?.imageURL !== previewFavicon){
				setAppearanceChanges(true);
				setFaviconChanges(true);
				log('fav 1');
				if(ogTagsData?.OGTag?.imageURL && ogTagsData?.OGTag?.imageURL !== preview){
					setOgTagsChanges(true);
					log('og 1');
				}
			}else if(faviconData?.favicon?.imageUploadID === undefined && uploadFileFavicon !== null){
				setAppearanceChanges(true);
				setFaviconChanges(true);
				log('fav 2');
				if(ogTagsData?.OGTag?.imageUploadID === undefined && uploadFileOgTags !== null){
					setOgTagsChanges(true);
					log('og 2');
				}
			}else if(ogTagsData?.OGTag?.imageURL && ogTagsData?.OGTag?.imageURL !== preview){
				setAppearanceChanges(true);
				setOgTagsChanges(true);
				log('fav 3');
				if(faviconData?.favicon?.imageURL && faviconData?.favicon?.imageURL !== previewFavicon){
					setFaviconChanges(true);
					log('og 3');
				}
			}else if(ogTagsData?.OGTag?.imageUploadID === undefined && uploadFileOgTags !== null){
				setAppearanceChanges(true);
				setOgTagsChanges(true); 
				log('fav 4');
				if(faviconData?.favicon?.imageUploadID === undefined && uploadFileFavicon !== null){
					setFaviconChanges(true);
					log('og 4');
				}
			}
			// }else if((faviconData?.favicon?.imageUploadID === undefined && uploadFileFavicon !== null) 
			// 	|| (ogTagsData?.OGTag?.imageUploadID === undefined && uploadFileOgTags !== null)){
			// 	setAppearanceChanges(true);
			// 	setFaviconChanges(true);
			// 	setOgTagsChanges(true);
			// }
			else {setAppearanceChanges(false); setFaviconChanges(false); setOgTagsChanges(false);}
		}else {setAppearanceChanges(false); setFaviconChanges(false); setOgTagsChanges(false);}
	},[uploadIDFavicon, faviconData, previewFavicon, uploadFileFavicon, isFavDataFetched, uploadID, ogTagsData, preview, uploadFileOgTags, isOgTagsFetched]);
};