import { AxiosError } from 'axios';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { createFaviconByID, editFaviconByID, uploadFaviconImageApi } from 'services/requests/appearance/images/favicon';
import { CreateEditFaviconImgUploadType, GetFaviconResponse, UploadFaviconImageParams, UploadFaviconImageResponse } from 'services/requests/appearance/images/favicon/schema';
import { AppFeatIsSaving, SFaviconID, SIsFaviconChanges } from 'store/CustomizeApp/Appearance';
import { SFaviconImgID, SFaviconUploadFile, SIsFaviconRefresh } from 'store/CustomizeApp/Appearance/imagesAtoms/favicon';
import { showFailedAlert } from 'utility/alerts';
import ThemeLayoutMutation from '../../ThemeAndLayout';
import OgTagsMutation from '../ogtags';
import { log } from 'utility/log';

const FaviconMutation = () => {
    
	const { id } = useParams();
	//Atoms for Favicon
	const faviconID 					= useAtomValue(SFaviconID);
	const [faviconImgID, setImageID] 	= useAtom(SFaviconImgID);
	const setIsFaviconRefresh 			= useSetAtom(SIsFaviconRefresh);
	const setIsSaving 					= useSetAtom(AppFeatIsSaving);
	const imageFile 					= useAtomValue(SFaviconUploadFile);
	const isThereChanges 				= useAtomValue(SIsFaviconChanges);
	// const {saveThemeLayout} 			= ThemeLayoutMutation();
	const {saveOgTags} 					= OgTagsMutation();


	const {mutate: uploadFaviconImg, isSuccess} = useMutation<
	UploadFaviconImageResponse,
	AxiosError,
	UploadFaviconImageParams
	>((data) => uploadFaviconImageApi(id, data.file), {
		onSuccess: (data) => {
			setImageID(data.ImageUpload.imageUploadID);
		},
		onError: () => {
			setIsSaving(false);
			showFailedAlert('Image cannot be uploaded');
		},
	});

	//Create Favicon
	const { mutate: faviconFormMu } = useMutation<
		GetFaviconResponse,
		AxiosError,
		CreateEditFaviconImgUploadType
	>((data) => createFaviconByID(id, data), {
		onSuccess: () => {
			setIsFaviconRefresh(true);
			saveOgTags();
		},
		onError: () => {
			setIsSaving(false);
			showFailedAlert('Cannot save favicon.');
		},
	});

	//Edit Favicon
	const { mutate: editFavicon } = useMutation<
		GetFaviconResponse,
		AxiosError,
		CreateEditFaviconImgUploadType
	>((data) => editFaviconByID(id, faviconID, data), {
		onSuccess: (res) => {
			log('edit Favicon', res);
			setIsFaviconRefresh(true);
			saveOgTags();
		},
		onError: () => {
			setIsSaving(false);
			showFailedAlert('Cannot send successfully.');
		},
	});

	const updateFavicon = () => {
		if (id)
			editFavicon({
				imageUploadID: faviconImgID ?? 0,
			});
	};
    
	const saveFavicon = () => {
		if(isThereChanges){
			if(imageFile){
				uploadFaviconImg({file: imageFile});
			}else{
				if (faviconID) {
					updateFavicon();
				} else {
					faviconFormMu({
						imageUploadID: faviconImgID ?? 0,
					});
				}
			}
		}else saveOgTags();
	};

	useEffect(() => {
		if (faviconImgID && isSuccess)
			if (faviconID) {
				updateFavicon();
			} else {
				faviconFormMu({
					imageUploadID: faviconImgID ?? 0,
				});
			}
	}, [faviconImgID, isSuccess]);

	return {saveFavicon,};
};

export default FaviconMutation;