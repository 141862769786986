import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import { forgotResetPasswordApi, validateResetPasswordTokenApi } from 'services/requests/auth';
import {
	forgotResetPasswordSchema,
	ForgotResetPasswordSchema,
	ForgotResetPasswordResponse,
	ValidateResetPasswordTokenSchema,
	ValidateResetPasswordTokenResponse,
} from 'services/requests/auth/schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { AxiosError } from 'axios';

import { Button } from 'components/Common/Buttons';
import FormInputPassword from 'components/Common/Forms/Password';
import { useNavigate } from 'react-router-dom';
import { showFailedAlert } from 'utility/alerts';
import { log } from 'utility/log';

const ForgotInputPassword: React.FC = () => {
	const tokenID = new URLSearchParams(window.location.search).get('id');
	const navigate = useNavigate();
	const [showCriteria, setShowCriteria] = useState(false);
	const [dateStamp, setDateStamp] = useState('');

	const handleHideCriteria = () => {
		setShowCriteria(true);
	};

	const { control, handleSubmit, formState } =
		useForm<ForgotResetPasswordSchema>({
			defaultValues: {
				tokenID: tokenID?.trim() ?? undefined,
				newPassword: '',
				confirmPassForgot: '',
			},
			mode: 'onBlur',
			resolver: zodResolver(forgotResetPasswordSchema),
		});

	const { mutate: forgotResetPasswordMU } = useMutation<
		ForgotResetPasswordResponse,
		AxiosError,
		ForgotResetPasswordSchema
	>((data) => forgotResetPasswordApi(data), {
		onSuccess: () => {
			navigate('/success-forgot-password');
		},
		onError: (error) => {
			if (error.response?.status == 400) {
				showFailedAlert('Session expired.');
				navigate('/error-403');
			} else {
				showFailedAlert('New password must be different from current password.');
			}
			log(error.message);
		},
	});

	const { mutate: validateResetPasswordTokenMU } = useMutation<
		ValidateResetPasswordTokenResponse,
		AxiosError,
		ValidateResetPasswordTokenSchema
	>((data) => validateResetPasswordTokenApi(data), {
		onSuccess: (data) => {
			const unixDate = new Date(data.tokenExpiration * 1000);
			const strDate = unixDate.toLocaleString();
			setDateStamp(strDate);
		},
		onError: () => {
			showFailedAlert('Session expired.');
			navigate('/error-403');
		},
	});

	const onSubmit = (data: ForgotResetPasswordSchema) => {
		forgotResetPasswordMU(data);
	};

	useEffect(()=>{
		validateResetPasswordTokenMU({
			tokenID: tokenID ?? ''
		});
	},[]);

	return (
		<div className='max-md:p-6 max-sm:p-6 w-full h-[90vh] flex justify-center items-center bg-[#F6F6F6]'>
			<div className='max-md:w-full max-sm:w-full w-[35%] p-6 pl-12 pr-12 bg-white rounded-xl'>
				<h2 className='text-center text-[#464255] text-2xl font-bold'>
					Reset Your Password
				</h2>
				<form className='w-full'>
					<div className='mt-7'>
						<Controller
							control={control}
							name='newPassword'
							render={({ field: { name, onChange }, fieldState: { error } }) => (
								<FormInputPassword
									onChange={onChange}
									name={name}
									error={error?.message}
									type='password'
									label='New Password'
									onFocus={handleHideCriteria}
									criteria={showCriteria}
									placeholder='********'
									className='font-normal placeholder:text-[11px]'
								/>
							)}
						/>
					</div>
					<div className='mt-7'>
						<Controller
							control={control}
							name='confirmPassForgot'
							render={({ field: { name, onChange }, fieldState: { error } }) => (
								<FormInputPassword
									onChange={onChange}
									name={name}
									error={error?.message}
									type='password'
									label='Confirm New Password'
									placeholder='********'
									className='font-normal placeholder:text-[11px]'
								/>
							)}
						/>
					</div>
					<div className='mt-7 pb-16'>
						<Button
							type='submit'
							variant='primary'
							onClick={handleSubmit(onSubmit)}
							className='w-full py-1.5'
							disabled={
								!formState.dirtyFields.newPassword ||
								!formState.dirtyFields.confirmPassForgot
							}
						>
							<span className='text-sm font-semibold'>Reset Password</span>
						</Button>
					</div>
					<div className='text-center text-[#464255] text-sm'>
						<span>Session will expire on: {dateStamp}</span>
					</div>
				</form>
			</div>
		</div>
	);
};

export default ForgotInputPassword;
