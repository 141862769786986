import { Button } from 'components/Common/Buttons';
import Envelope from '../../../../assets/ThankyouMessage/envelope.png';
import React from 'react';
import Shadow from '../../../../assets/ThankyouMessage/shadow.png';
import { Link } from 'react-router-dom';

const ThankyouMessage: React.FC = () =>{
	return(
		<div className='max-md:h-full max-sm:h-full flex flex-col h-screen justify-center items-center bg-[#F6F6F6]'>
			<div className='p-4'>
				<img className='w-[230px] h-[200px]' src={Envelope} alt='envelope image' />
				<img className='w-[180px] -mt-2' src={Shadow} alt='shadow image' />
			</div>
			<div className='p-2 font-light'>
				<h1 className='text-center font-bold text-[#464255] text-[18px]'>Thank you for creating your account!</h1>
			</div>
			<div className='p-2 w-1/2 mt-1 text-[#464255] text-[15px] font-light tracking-wide'>
				<p className='text-center'> Your account is pending email verification.</p>
			</div>
			<div className='max-md:w-3/4 max-sm:w-3/4 p-2 w-1/2 mb-4 mt-1 text-[#464255] text-[15px] font-light tracking-wide'>
				<p className='text-center'>Click the link we just sent to your email to verify your email <br /> address. Once verified, you may sign in to your SnapToApp <br /> account with your account information.</p>
			</div>
			<Link id='nav-link' to='/login' className='max-md:w-1/2 max-sm:w-1/2 w-1/4 py-2 text-sm flex justify-center items-center bg-[#E0781D] border rounded-[5px] shadow-sm focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed hover:bg-[#e98923] border-transparent disabled:bg-primary text-white'>
				Back To Sign-In
			</Link>
		</div>
	);
};

export default ThankyouMessage;
