import React, { useEffect, useState } from 'react';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import ReactPaginate from 'react-paginate';
import { classNames } from '../../../utility';
// import useWindowDimensions from '../../../hooks/window';
// import { MOBILE_MAX_WIDTH } from '../../../constants/layout';

interface PaginationProps {
  stripped?: boolean;
  totalPages?: number;
  page: number;
  className?: string;
  isLoading?: boolean;
  onChange: ({ selected }: { selected: number }) => void;
}

const Pagination = (props: PaginationProps) => {
	// const { width } = useWindowDimensions();
	const [page, setPage] = useState(0);

	const isFirstPage = page === 0;
	const isLastPage = page === (props.totalPages ? props.totalPages - 1 : 0);
  
	useEffect(() => {
		setPage(props.page);
	}, [props.page]);
  
	function onClickJumpToFirst() {
		props.onChange({selected: 0});
	}
  
	function onClickJumpToLast() {  
		props.onChange({selected: props.totalPages ? props.totalPages - 1 : 0});
	}
  
	return (
		<div className={classNames(
			'flex flex-row items-center',
			props.className || '',
			!props.totalPages ? 'hidden' : ''
		)}>
			<button 
				type="button"
				onClick={onClickJumpToFirst}
				disabled={isFirstPage} 
				className={classNames(
					'border border-gray-300 rounded py-0.5 px-1 mr-2',
					isFirstPage ? 'bg-custom-gray-120 text-custom-gray-200' : '',
					props.stripped ? 'hidden' : ''
				)}
			>
				<ChevronDoubleLeftIcon className="h-6 w-5 text-custom-gray-200" aria-hidden="true" />
			</button>
			<ReactPaginate 
				className={`${props.isLoading ? 'hidden' : 'flex'} py-3 items-center flex-row justify-center`}
				pageClassName="relative z-0 inline-flex shadow-sm -space-x-px m-1"
				pageLinkClassName="bg-white border-gray-300 relative inline-flex items-center justify-center px-2 min-w-pagination-item py-1 border text-sm rounded"
				disabledClassName="bg-custom-gray-120 text-custom-gray-200"
				activeLinkClassName="text-white bg-primary border-primary hover:bg-green-700"
				previousClassName="relative inline-flex items-center px-1 py-1 rounded border border-gray-300 bg-white text-sm font-medium mr-1 my-1"
				nextClassName="relative inline-flex items-center px-1 py-1 rounded border border-gray-300 bg-white text-sm font-medium ml-1 my-1"
				breakClassName="relative inline-flex items-center px-2 py-1 border border-gray-300 bg-white text-sm font-medium rounded"
				breakLabel="..."
				nextLabel={
					<>
						<span className="sr-only">Next</span>
						<ChevronRightIcon className="h-5 w-5 text-custom-gray-200" aria-hidden="true" />
					</>
				}
				previousLabel={
					<>
						<span className="sr-only">Previous</span>
						<ChevronLeftIcon className="h-5 w-5 text-custom-gray-200" aria-hidden="true" />
					</>
				}
				onPageChange={props.onChange}
				pageRangeDisplayed={1}
				// marginPagesDisplayed={width <= MOBILE_MAX_WIDTH || props.stripped ? 1 : 3}
				pageCount={props.totalPages ? props.totalPages : 0}
				forcePage={page-1}
				renderOnZeroPageCount={() => null}
			/>
			<button 
				type="button"
				onClick={onClickJumpToLast}
				disabled={isLastPage} 
				className={classNames(
					'border border-gray-300 rounded py-0.5 px-1 ml-2',
					isLastPage ? 'bg-custom-gray-120 text-custom-gray-200' : '',
					props.stripped ? 'hidden' : ''
				)}
			>
				<ChevronDoubleRightIcon className="h-6 w-5 text-custom-gray-200" aria-hidden="true" />
			</button>
		</div>
	);
};

export default Pagination;