import { axiosInstance } from 'services/axios';
import { CreateEditOgTagsImgUploadSchema, CreateEditOgTagsImgUploadType, GetOgTagsResponse, UploadOgTagsImageResponse } from './schema';

export const createOgTagsByID = async (appID?: string, data?: CreateEditOgTagsImgUploadType) => {
	const validatedOgTagsForm = CreateEditOgTagsImgUploadSchema.parse(data);
	const response = await axiosInstance.post(`/organizations/apps/${appID}/ogtags`, validatedOgTagsForm); 
	return response.data as GetOgTagsResponse;
};

export const editOgTagsByID = async (appID?: string, ogTagsID?: number, param?: CreateEditOgTagsImgUploadType) => {
	const response = await axiosInstance.put(`/organizations/apps/${appID}/ogtags/${ogTagsID}`, param); 
	return response.data as GetOgTagsResponse;
};

export const getOgTagsByID = async (appID?: string) => {
	const response = await axiosInstance.get(`/organizations/apps/${appID}/ogtags`); 
	return response.data as GetOgTagsResponse;
};

export const getOgTagsByIDLive = async (appID?: string) => {
	const response = await axiosInstance.get(`/organizations/apps/${(appID)}/ogtags?env=LIV`); 
	return response.data as GetOgTagsResponse;
};

export const uploadOgTagsImageApi = async (id?: string, file?: any) => {
	const formData = new FormData();
	formData.append('image', file);
	const response = await axiosInstance.post(`/organizations/apps/${id}/imageUploads/ogtag`, formData, {
		headers: {
			'Content-type': 'multipart/form-data'
		}});
    
	return response.data as UploadOgTagsImageResponse;
};