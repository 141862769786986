import { API_URL } from './api';
import axios from 'axios';

export const axiosInstance = axios.create({
	baseURL: API_URL,
	headers: {
		'Content-Type': 'application/json',
	},
});

axiosInstance.interceptors.request.use(
	(config) => {
		const bearerToken = localStorage.getItem('bearerToken');
		if (bearerToken) {
			config.headers.Authorization = `Bearer ${bearerToken}`;
		}
		return config;
	}
);

axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	async function (error) {
		if (error.response.status === 401) {
			localStorage.clear();
			window.location.href = '/login';
		}
		return Promise.reject(error);
	}
);