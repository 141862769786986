import React, { useEffect, useState } from 'react';

import Editor from 'components/Common/TextEditor';
import { FAbout } from 'store/CustomizeApp/Features/aboutAtom';
import { IMAGES } from 'utility';
import { useAtom } from 'jotai';
import { AboutUseQuery } from '../UseQuery/AboutQueries';
import Tooltip from 'components/Common/Tooltip';

const About: React.FC = () => {

	AboutUseQuery();
	
	const [value, setValue] = useAtom(FAbout);
	const [editorHeight, setEditorHeight] = useState(900);
	useEffect(() => {
		setTimeout(() => {
			const quill = document.querySelector('.ql-container');
			const resizeObserver = new ResizeObserver(entries => {
				if (entries.length > 0) {
					const editorHeight = entries[0].contentRect.height;
					if (editorHeight > 0) setEditorHeight(editorHeight);
					else setEditorHeight(900);
				}
			});
			if(quill) resizeObserver.observe(quill);

			return () => {
				if(quill) resizeObserver.unobserve(quill);
			};
		}, 250);
	}, [editorHeight]);

	return (
		<div className='bg-white w-full rounded-lg my-8'>
			<div className='bg-white px-3 mx-3 rounded max-sm:pb-[270px]'>
				<div className='py-5 flex-row'>
					<div className='flex justify-between items-center py-2'>
						<span className='text-[#707070] font-bold text-2xl'>About</span>
						<div className='flex gap-3 items-center'>
							{/* -----------Video Tutorial and Help Icons------------ */}
							<Tooltip className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
								<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.CameraIcon} alt='camera icon' />
							</Tooltip>
							<Tooltip className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
								<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.InfoIcon} alt='info icon' />
							</Tooltip>
							{/* ---------------------------------------------------- */}
						</div>
					</div>
					<div className='flex-row py-5'>
						<div className='flex-row py-1 w-full xl:w-[58.5rem] 2xl:w-[68rem] 3xl:w-full'>
							<div className='w-full h-[22rem] sm:h-[21rem] md:h-[19rem] lg:h-[19rem] mb-10' style={{height: editorHeight}}>
								<Editor 
									value={value} 
									setValue={setValue} 
									label='Description'
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default About;