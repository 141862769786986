import { FEditNotif, FNotif, FaddNotification, FeditNotification, } from 'store/CustomizeApp/Features/notificationAtom';
import { useAtom, useAtomValue } from 'jotai';

import { Button } from 'components/Common/Buttons';
import { GetNotificationType } from 'services/requests/Features/Notification/schema';
import { IMAGES } from 'utility';
import NotificationAccordion from 'components/Accordion/AccordionNotification';
import React, {  } from 'react';
import { NotificationQuery } from '../UseQuery/NotificationQueries';
import Tooltip from 'components/Common/Tooltip';

export type NotifAccordionDataType = {
  notificationID: number,
	appID: number,
	icon: string,
	title: string,
	message: string,
	url: string,
	status: string,
	whoAdded: number,
	whenAdded: string,
	whenUpdated: string
};

const Notification: React.FC = () => {

	// NotificationQuery();

	const notifData = useAtomValue(FNotif);
	const [, setEditNotifData] = useAtom(FEditNotif);
	const [,createNotif] = useAtom(FaddNotification);
	const [,editNotif] = useAtom(FeditNotification);


	const handleSaved = (data: GetNotificationType) => {
		setEditNotifData({
			title: data.title,
			message: data.message,
			icon: data.icon,
			url: data.url,
			notificationID: data.notificationID,
			appID: data.appID,
			status: data.status,
			whenAdded: data.whenAdded,
			whenUpdated: data.whenUpdated,
			whoAdded: data.whoAdded,
		});
		editNotif();

	};

	const handleAddNotificationData = () => {
		createNotif();
	};

	return (
		<div className='bg-white w-full rounded-lg my-8 h-auto pt-2'>
			<div className='bg-white px-6 rounded'>
				<div className='py-5 flex-row max-sm:pb-[270px]'>
					<div className='flex justify-between items-center'>
						<span className='text-[#707070] font-bold text-2xl'>Notification
						</span>
						<div className='flex gap-2 items-center'>
							{/* -----------Video Tutorial and Help Icons------------ */}
							<Tooltip className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
								<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.CameraIcon} alt='camera icon' />
							</Tooltip>
							<Tooltip className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
								<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.InfoIcon} alt='info icon' />
							</Tooltip>
							{/* ---------------------------------------------------- */}
						</div>
					</div>
					<div className=' flex justify-end mt-3'>
						<Button variant='primary'
							className='font-semibold text-[16px] py-[7px] px-[16px] max-sm:py-[4px] max-sm:px-[13px] max-sm:text-[12px]'
							onClick={handleAddNotificationData}
						>
							+ Add More
						</Button>
					</div>
					<div className='mt-7'>
						<div className='flex flex-col gap-[210px] z-10'>
							<div className='accordion'>
								{notifData.rows && notifData?.rows.map((notifData, index) => (
									<NotificationAccordion
										id={notifData.notificationID}
										items={notifData}
										key={notifData.notificationID}
										index={index}
										handleSaved={handleSaved}
									>
									</NotificationAccordion>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
    
	);
};

export default Notification;