import { atom } from 'jotai';

export const SAppearanceTab = atom('flashScreen');
export const SAppName       = atom('');
export const SAppID         = atom<number>(0);
export const SIndustryID    = atom<number>(0);
export const SAppearanceID  = atom<number>(0);
export const SFlashScreenID = atom<number>(0);
export const SBackgroundID  = atom<number>(0);
export const SNavigationID  = atom<number>(0);
export const SSideBarID     = atom<number>(0);
export const SColorID       = atom<number>(0);
export const SFontID        = atom<number>(0);
export const SFaviconID     = atom<number>(0);
export const SOgTagsID      = atom<number>(0);

export const SFlashScreenIDLive = atom<number>(0);
export const SBackgroundIDLive  = atom<number>(0);
export const SNavigationIDLive  = atom<number>(0);
export const SSideBarIDLive     = atom<number>(0);
export const SColorIDLive       = atom<number>(0);
export const SFontIDLive        = atom<number>(0);
export const SFaviconIDLive     = atom<number>(0);
export const SOgTagsLive        = atom<number>(0);

export const SUserID        = atom<number>(0);
export const SAppearanceSuccess = atom<boolean>(false);
export const AppFeatIsSaving = atom<boolean>(false);
export const SIsLeavingSectionOpen = atom<boolean>(false);
export const SIsUnsavedModalOpen = atom<boolean>(false);

export const SIsAppearanceChanges   = atom<boolean>(false);
export const SIsThemeLayoutRefresh = atom<boolean>(true);

export const SisAppDone = atom<boolean>(false);
export const SisFlashDone = atom<boolean>(false);
export const SisThemeLayoutDone = atom<boolean>(false);
export const SisBackgroundDone = atom<boolean>(false);
export const SisNavigationDone = atom<boolean>(false);
export const SisSideBarDone = atom<boolean>(false);
export const SisColorDone = atom<boolean>(false);
export const SisFontDone = atom<boolean>(false);
export const SisFaviconDone = atom<boolean>(false);
export const SisOgTagsDone = atom<boolean>(false);

export const SIsAppearanceRefresh = atom<boolean>(true);
export const SIsAppRefresh = atom<boolean>(true);

export const SAppNameChanges = atom<boolean>(false);
export const SIsFlashChanges = atom<boolean>(false);
export const SIsLayoutChanges = atom<boolean>(false);
export const SIsThemeChanges = atom<boolean>(false);
export const SIsBackgroundChanges = atom<boolean>(false);
export const SIsNavigationChanges = atom<boolean>(false);
export const SIsSideBarChanges = atom<boolean>(false);
export const SIsColorChanges = atom<boolean>(false);
export const SIsFontChanges = atom<boolean>(false);
export const SIsFaviconChanges = atom<boolean>(false);
export const SIsOgTagsChanges = atom<boolean>(false);