import z from 'zod';

export const GetContactSchema = z.object({
	description: z.string(),
	isTurnedOn: z.boolean(),
});


export const EditContactByAppIdResponse = z.object({
	contact: z.object({
		contactID: z.number(),
		companyTenantID: z.string(),
		appTenantID: z.string(),
		appID: z.string(),
		description: z.string(),
		isTurnedOn: z.boolean(),
		status: z.string(),
		whoAdded: z.number(),
		whoUpdated: z.number(),
		whenAdded: z.string(),
		whenUpdated: z.string()
	})
});



export type GetContactFormType = z.infer<typeof GetContactSchema>;
export type GetContactFormResponse = z.infer<typeof EditContactByAppIdResponse>;