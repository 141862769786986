import { AlphaPicker, ChromePicker } from 'react-color';
import { ArrowUpTrayIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { BlurLinear, Opacity } from '@mui/icons-material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { SBBackgroundColor, SBBackgroundFile, SBBackgroundImage, SBBlurBackgroundImage, SBCustomBackgroundID, SBDefaultBackgroundImageID, SBIsCustomBackgroundFocus, SBOpacityBackgroundImage, SBOpacityValue, SBPreviewBackgroundImage } from 'store/CustomizeApp/Appearance/backgroundAtom';
import { classNames, useClickOutside } from 'utility';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useQuery } from 'react-query';

import { AxiosError } from 'axios';
import { GetDefaultBackgroundsResponse } from 'services/requests/appearance/background/schema';
import { IMAGES } from 'utility/constant';
import { SIndustrySubCategory } from 'store/CustomizeApp/Appearance/flashScreenAtom';
import { Slider } from '@mui/material';
import { getDefaultBackgrounds } from 'services/requests/appearance/background';
import rgbHex from 'rgb-hex';
import { useParams } from 'react-router-dom';
import { showFailedAlert } from 'utility/alerts';
import Tooltip from 'components/Common/Tooltip';


const BackgroundPage: React.FC = () => {

	const popover 				  = useRef(null);
	const backgroundInput 		  = useRef<HTMLInputElement>(null);

	const {id}                    = useParams();
	const imageTypeRegex          = /image\/(png|jpg|jpeg)/gm;
	const [opacity, setOpacity]   = useAtom(SBOpacityValue);
	const flashScreenSubCategory  = useAtomValue(SIndustrySubCategory);

	const [opacityBackgroundImage, setOpacityBackgroundImage] = useAtom(SBOpacityBackgroundImage);
	const [blur, setBlur]         						  = useAtom(SBBlurBackgroundImage);
	const [color, setColor]         					  = useAtom(SBBackgroundColor);
	const [backgroundImage, setBackgroundImage]           = useAtom(SBBackgroundImage);
	const [previewBackground, setPreviewBackground] 	  = useAtom(SBPreviewBackgroundImage);
	const [defaultBackgroundID, setBackgroundID] 		  = useAtom(SBDefaultBackgroundImageID);
	const [isCustomBgFocus, setIsCustomBgFocus] 		  = useAtom(SBIsCustomBackgroundFocus);
	const [, setCustomBackgroundID]						  = useAtom(SBCustomBackgroundID);
	const setBackgroundFile 							  = useSetAtom(SBBackgroundFile);

	const [isOpenOverlay, toggleOverlay] 		  		  = useState(false);
	const closeOverlay 			= useCallback(() => toggleOverlay(false), []);
	useClickOutside(popover, closeOverlay);

	const {data } = useQuery<GetDefaultBackgroundsResponse, AxiosError>({
		queryKey: ['backgrounds'],
		queryFn: () => getDefaultBackgrounds({limit: 1000}),
		retry: 0
	});

	const onClickBackgroundImage = (url: string, idx: number) => {
		defaultBackgroundID === idx ?
			(setBackgroundImage(null), setBackgroundID(0))
			:
			(setBackgroundImage(url), setBackgroundID(idx), setIsCustomBgFocus(false));
	};

	const onClickUploadBackgroundHandler = () => {
		previewBackground === null ?
			(backgroundInput.current?.click(),
			setIsCustomBgFocus(false))
			:
			isCustomBgFocus ? (setIsCustomBgFocus(false), setBackgroundImage(null)) :
				(setIsCustomBgFocus(true),
				setBackgroundID(0),
				setBackgroundImage(previewBackground)
				);
	};
	
	const handleSelectImage = (event: any) => {
		const file = event.target.files[0];
		const fileReader = new FileReader();
	
		fileReader.onload = (event : any) => {
			const image : any = new Image();
			image.src = event.target.result;
		
			if (file.type.match(imageTypeRegex)) {
				image.onload = () => {
					if (file.size <= (1048576 * 5)) {
						setBackgroundFile(file);
						setIsCustomBgFocus(true);
						setPreviewBackground(fileReader.result);
						setBackgroundImage(fileReader.result);
						setBackgroundID(0);
					} else if (file.size > (1048576 * 5)) {
						showFailedAlert('Please upload an image file that is less than 5MB in size.');
					}
				};
			} else{
				showFailedAlert('File must be JPEG or PNG.');
			}
		};
		fileReader.readAsDataURL(file);
	};

	const handleSliderChange = (event: Event, newValue: number | number[]) => {
		setBlur(newValue);
	};

	useEffect(() => {
		if (backgroundImage === null) {
			setOpacityBackgroundImage(1);
			setBlur(0);	
		}
	}, [backgroundImage]);

	return (
		<div className='rounded-lg my-4 w-full'>
			<div className='w-full rounded bg-white p-2'>
				<div className='py-5 flex-row'>
					<div className='flex justify-between items-center pt-2'>
						<span className='text-[#707070] font-bold text-2xl block'>Background</span>
						<div className='flex gap-3 items-baseline flex-row'>
							{/* -----------Video Tutorial and Help Icons------------ */}
							<Tooltip className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
								<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.CameraIcon} alt='camera icon' />
							</Tooltip>
							<Tooltip className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
								<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.InfoIcon} alt='info icon' />
							</Tooltip>
							{/* ---------------------------------------------------- */}
						</div>
					</div>
						

					{/* Parent Background Content */}
					<div className='grid grid-cols-3 py-6 pl-2 border-t border-[#FDF2F2] w-[60%] 2xl:grid-cols-3 2xl:px-4 2xl:w-[736px] xl:grid-cols-3 xl:pl-2 xl:w-[60%] lg:grid-cols-3 lg:pl-2 lg:w-[624px] md:grid-cols-2 md:pl-2 md:w-[468px] sm:grid-cols-1 sm:pl-0 sm:w-[60%] max-sm:grid-cols-1 max-sm:pl-0 max-sm:w-[180px]'>
							

						{/* Custom Background Image */}
						<div className='flex flex-row mr-0'> 	
							<div className='flex flex-col'>
									
								<span className='text-[#464255] opacity-80 '>Custom</span>
									
								<div className='flex w-[180px] relative pt-[50px]'>
									<div onClick={onClickUploadBackgroundHandler}
										className={classNames('w-[180px] h-[280px] 2xl:w-[180px] 2xl:h-[280px] xl:w-[140.62px] xl:h-[240.98px] lg:w-[147.1px] lg:h-[246.72px] md:w-[142.1px] md:h-[246.72px] sm:w-[180px] sm:h-[265px] max-sm:w-[142px] max-sm:h-[247px] border bg-[#F5F5F5] cursor-pointer flex items-center justify-center', 
											isCustomBgFocus ? 'border-0 outline outline-[5px] outline-primary' : 'outline-none')}>
										<input ref={backgroundInput} type="file" className='h-0 absolute hidden' accept='.jpg, .jpeg, .png' onChange={(e) => handleSelectImage(e)} onClick={(event)=> { 
											event.currentTarget.value = '';
										}}/>
										{
											previewBackground ?
												<div className='flex'>
													<img src={previewBackground} alt="preview-background" className='w-[180px] h-[280px] 2xl:w-[180px] 2xl:h-[280px] xl:w-[140.62px] xl:h-[240.98px] lg:w-[147.1px] lg:h-[246.72px] md:w-[142.1px] md:h-[246.72px] sm:w-[180px] sm:h-[265px] max-sm:w-[142px] max-sm:h-[247px] object-cover'/>
												</div>
												:
												<div className='flex justify-center'>
													<div className='flex-row'>
														<div className=' flex justify-center'>
															<ArrowUpTrayIcon className='w-8 h-8 text-primary'/> 
														</div>
														<span className='text-primary text-xs block text-center'>Upload</span>
														<span className='text-primary text-xs block text-center'>Custom</span>
														<span className='text-primary text-xs block text-center'>Background</span>	
													</div>
												</div>
										}
									</div>
									{previewBackground && 
										<div className='rounded-full text-[#D9D7D7] bg-white border-2 h-7 w-7 flex items-center justify-center cursor-pointer -ml-4 -mt-3'
											onClick={() => { setBackgroundFile(null);
												setPreviewBackground(null); 
												setBackgroundImage(null); 
												setIsCustomBgFocus(false);
												setCustomBackgroundID(0);
											}
											}
										>
											<XMarkIcon className='w-5 h-5 cursor-pointer'/>
										</div>}
								</div>
							</div>

								
							{/* Divider 1 */}
							<div className='pt-[75px] 2xl:block 2xl:px-[25px] xl:hidden lg:block lg:px-[0px] md:block sm:hidden max-sm:hidden'>
								<div className='rounded bg-gray-400 w-0.5 h-[280px] 2xl:h-[280px] lg:h-[240.98px] md:h-[240.98px] sm:h-[240.98px] max-sm:h-[240.98px]'/>
							</div>
						</div>

						{/* Box for Background Color */}
						<div className='flex flex-row 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-0 sm:mt-10 max-sm:mt-10'>
							<div className='flex flex-col'>
								
								<span className='text-[#464255] opacity-80 '>Background Color</span>
									
								<div className='my-4 flex items-center'>
											
									<div className={'w-5 h-5 mr-2 border-2 cursor-pointer'} style={{backgroundColor: `${color}`}} onClick={() => toggleOverlay(true)}/>
									{/* <AlphaPicker width='150px' color={flashOverlay} onChange={(c) => {
												setFlashOverlay('#' + rgbHex(c.rgb.r, c.rgb.g, c.rgb.b, c.rgb.a));
												if(c.rgb.a) setOverlayValue(c.rgb.a);
											}}/>		 */}
									{isOpenOverlay &&
													<div ref={popover} className='absolute z-10 mt-52 ml-7'>
														<ChromePicker color={color} onChange={(c) => {
															setColor('#' + rgbHex(c.rgb.r, c.rgb.g, c.rgb.b, c.rgb.a));
														}}/>
													</div> }
								</div>

								{/* Background Color */}
								<div className={'rounded w-[180px] h-[280px] 2xl:w-[180px] 2xl:h-[280px] xl:w-[140.62px] xl:h-[240.98px] lg:w-[147.1px] lg:h-[246.72px] md:w-[142.1px] md:h-[246.72px] sm:w-[180px] sm:h-[265px] max-sm:w-[142px] max-sm:h-[247px]  border'} style={{backgroundColor: `${color}`}}/>
									

							</div>	

							{/* Divider 2 */}
							<div className='pt-[75px] 2xl:block 2xl:px-[25px] xl:hidden lg:block lg:px-[25px] md:hidden sm:hidden max-sm:hidden'>
								<div className='rounded bg-gray-400 w-0.5 h-[280px] 2xl:h-[280px] lg:h-[240.98px] md:h-[240.98px] sm:h-[240.98px] max-sm:h-[240.98px]'/>
							</div>	
						</div>	

								
						<div className='flex flex-col 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-10 sm:mt-10 max-sm::mt-10'>
							<div className='flex flex-row 2xl:w-[300%] 2xl:flex-row xl:w-[300%] xl:flex-row lg:w-[300%] lg:flex-row md:w-[300%] md:flex-row sm:w-full sm:flex-col max-sm:w-full max-sm:flex-col '>
								{/* Opacity */}
									
								<div className='flex flex-col md:mr-10'>
									<span className='text-[#464255] opacity-80 '>Opacity</span>
									<div className='my-4 flex items-center'>
										<Opacity fontSize='medium' className='mr-2'/>
										<AlphaPicker className={classNames(backgroundImage === null ? 'pointer-events-none' : '')} width='150px' color={'#' +rgbHex(0,0,0,opacityBackgroundImage)} onChange={(e) => {
											setOpacity(e.rgb);
											if(e.rgb.a) setOpacityBackgroundImage(e.rgb.a);}}/>
									</div>
								</div>
									
								{/* Blur */}
								<div className='flex flex-col'>
									<span className='text-[#464255] opacity-80 '>Blur</span>
									<div className='flex items-center 2xl:pt-[13px] xl:pt-[13px] lg:pt-[6px] md:pt-[6px] sm:pt-0 max-sm:pt-0'>	
										<BlurLinear fontSize='medium' className='mr-2'/>
										<input type='number' min={0} max={10} value={blur}
											className={classNames(backgroundImage === null ? 'pointer-events-none' : '', 'lg:ml-6', 'border-b w-8 border-black text-center focus:outline-none')}
											onChange={(value) => {
												if(parseInt(value.currentTarget.value) < 0 || !value.currentTarget.value){ setBlur(0);}
												else if(parseInt(value.currentTarget.value) > 10){ setBlur(10);}
												else setBlur(parseInt(value.currentTarget.value));
											}}/>
										<Slider className={classNames(backgroundImage === null ? 'pointer-events-none' : '', 'ml-6')} min={0} max={10} value={blur} onChange={handleSliderChange} size='small' style={{color: '#D9D7D7', width: 150}}/>
									</div>
								</div>
							</div>

							
							{/* Background Image Presets */}
							<div className=' grid grid-cols-3 col-span-2 gap-6 mr-4 2xl:grid-cols-3 2xl:gap-6 2xl:w-[240%] xl:grid-cols-3 xl:gap-6 xl:w-[240%] lg:grid-cols-3 lg:gap-6 lg:w-[240%] md:grid-cols-3 md:gap-6 md:w-[240%] sm:grid-cols-2 sm:gap-6 sm:w-[130%] max-sm:grid-cols-2 max-sm:gap-2 max-sm:w-[160%]'>
								{data?.rows.map((item, idx) => 
									item.subcategoryID === flashScreenSubCategory && <img key={idx} onClick={() => onClickBackgroundImage(item.imageURL, item.defaultBackgroundID)} 
										className={classNames('w-[180px] h-[280px] 2xl:w-[180px] 2xl:h-[280px] xl:w-[140.62px] xl:h-[240.98px] lg:w-[147.1px] lg:h-[246.72px] md:w-[142.1px] md:h-[246.72px] sm:w-[180px] sm:h-[265px] max-sm:w-[142px] max-sm:h-[247px] w-cursor-pointer', 
											defaultBackgroundID ===  item.defaultBackgroundID ? ' outline-[5px] outline-primary outline ': 'outline-none')} 
										src={item.imageURL} alt='backgrounds' />
								)}
							</div>
						</div>
							
							
							
							
					</div>
						
						
						
				</div>
			</div>
		</div>
	);
};

export default BackgroundPage;