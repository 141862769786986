import { axiosInstance } from 'services/axios';
import { CreateHelpParams, CreateHelpResponse, CreateHelpSchema, EditHelpParams, EditHelpResponse, GetHelpResponse } from './schema';

export const createHelpApi = async (id?: string, data?: CreateHelpParams) => {
	const validatedHelpForm = CreateHelpSchema.parse(data);
	const response = await axiosInstance.post(`/organizations/apps/${id}/help`, validatedHelpForm);
	return response.data as CreateHelpResponse;
};

export const getHelpApiLive = async (id?: string) => {
	const response = await axiosInstance.get(`/organizations/apps/${id}/help?env=LIV`);
	return response.data as GetHelpResponse;
};

export const getHelpApi = async (id?: string) => {
	const response = await axiosInstance.get(`/organizations/apps/${id}/help`);
	return response.data as GetHelpResponse;
};

export const editHelpApi = async (id?: string, helpID?: number, param?: EditHelpParams) => {
	const response = await axiosInstance.put(`/organizations/apps/${id}/help/${helpID}`, param);
	return response.data as EditHelpResponse;
};