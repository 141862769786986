import React from 'react';
import { IMAGES } from 'utility';

export const GetInTouch = () => {
	return (
		<div className='py-[95px] pl-[75px] w-[600px] pr-[95px] bg-primary text-white 2xl:w-[600px] 2xl:h-[110vh] xl:h-[130vh] xl:w-[500px] xl:pl-[120px] lg:h-[130vh] lg:pl-[50px] lg:w-[300px] md:w-full sm:w-full min-sm:w-full max-sm:w-[100%] max-sm:py-[50px]'>
			<div>
				<h1 className='text-[25px] font-semibold'>Get in Touch</h1>
				<p className='mt-[22px] text-[15px]'>We&apos;d love to hear from you. Our friendly team is<br />always here to help.</p>
			</div>
			<div className='flex mt-[60px] gap-[23px]'>
				<div className='flex flex-col gap-[100px]'>
					<img className='w-[30px]' src={ IMAGES.MailIcon } alt='Mail Icon' />
				</div>
				<div className='flex flex-col mt-[-7px] gap-[44px] max-sm:gap-[20px]'>
					<p className='text-[18px] leading-7 max-sm:text-[13px]'>Email us<br />Our friendly team is here to help.<br />info@snaptoapp.com.</p>
				</div>
			</div>
		</div>
	);
};
