import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import Button from 'components/Common/Buttons/Button';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { getAppsById, publishApp } from 'services/requests/apps';
import { GetAppResponse } from 'services/requests/apps/schema';
import { useAtomValue } from 'jotai';
import {
	SIndustrySubCategory,
	SIndustySubCategories,
} from 'store/CustomizeApp/Appearance/flashScreenAtom';
import { PIndustryName } from 'store/CustomizeApp/Publish/publishAtom';
import { IMAGES } from 'utility/constant';
import { showFailedAlert } from 'utility/alerts';
import PubishTabPreviewLink from '../PreviewLink';
import { log } from 'utility/log';

const PublishTabContent = () => {
	const queryClient				           = useQueryClient();
	const navigate 					           = useNavigate();
	const { id } 						           = useParams();
	const categoryID                   = useAtomValue(SIndustrySubCategory);
	const categories                   = useAtomValue(SIndustySubCategories);
	const industryName 					       = useAtomValue(PIndustryName);
	const [category, setCategory]      = useState('');

	useEffect(() => {
		categories.map((cat) => {
			if (cat.value === categoryID) setCategory(cat.label);
		});
		log(categoryID, categories);
	}, [categoryID]);

	//App
	const { data: appData } = useQuery<GetAppResponse, AxiosError>({
		queryKey: ['app'],
		queryFn: () => getAppsById(id),
	});

	const { mutate: publish } = useMutation<unknown, AxiosError, unknown>(
		() => publishApp(id),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('pwa-apps');
				queryClient.invalidateQueries('app');
				navigate(`/app-in-review/${id}`);
			},
			onError: () => {
				showFailedAlert('Cannot publish app');
			},
		}
	);

	const checklist = [
		{
			id: 1,
			src: IMAGES.snapLogoIcon,
			description: 'Update your app\'s \'Terms and Conditions.\'',
		},
		{
			id: 2,
			src: IMAGES.snapLogoIcon,
			description: 'Review your app features.',
		},
		{
			id: 3,
			src: IMAGES.snapLogoIcon,
			description:
				'Ensure that all your app\'s content (e.g. images) are not copyrighted.',
		},
		{
			id: 4,
			src: IMAGES.snapLogoIcon,
			description:
				'Do you have a store? We can work with you to integrate it with the app.',
		},
	];

	return (
		<div className='h-auto bg-[#E6F1FF] py-11 rounded-md px-[62px] max-sm:px-[10px]'>
			<div className='text-[#707070]'>
				<p className='text-lg text-[#26355E] font-bold'>
					Publish Your App
				</p>
				<p className='mt-6'>
					Your app name is <span className='font-bold'> {appData && appData.app.name}. </span> Under{' '}
					{industryName} -{' '} 
					<span className='text-[#FF7F20]'>
						Sub Category: {category}
					</span>
				</p>
				<p className='my-6'>
					Are you ready to publish your app? Here’s checklist that you
					can use as guide
				</p>
				{checklist.map(({ id, src, description }) => (
					<div
						key={id}
						className='mt-[10px] flex flex-row items-center'
					>
						<img
							className='w-5 h-5'
							src={src}
							alt='envelope image'
						/>
						<p className='ml-[14.36px]'>{description}</p>
					</div>
				))}
			</div>
			<div className='w-[45%] 2xl:w-[45%] xl:w-[45%] lg:w-[45%] md:w-[70%] sm:w-full max-sm:w-full'>
				<p className='text-lg text-[#26355E] font-bold my-6'>Preview Link</p>
				<PubishTabPreviewLink />
			</div>
			<div className='w-full items-center justify-center flex mt-10'>
				<Button
					onClick={publish}
					variant='primary'
					className='px-[96px] py-[12px] font-semibold'>
					Publish
				</Button>
			</div>
		</div>
	);
};

export default PublishTabContent;
