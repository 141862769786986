import 'react-quill/dist/quill.snow.css';

import React, { useEffect, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';

import { FEditorError } from 'store/createAppAtom';
import { ImageActions } from '@xeger/quill-image-actions';
import { ImageFormats } from '@xeger/quill-image-formats';
import { classNames } from 'utility';
import { keyframes } from '@emotion/react';
import { showFailedAlert } from 'utility/alerts';
import { useAtom } from 'jotai';
import { useMediaQuery } from 'react-responsive';

// import { useMediaQuery } from 'react-responsive';

interface Option {
	value: string;
	label: string;
}

interface IProps {
	value: string;
	setValue: React.Dispatch<React.SetStateAction<string>>;
	label: string;
}
const Editor: React.FC<IProps> = ({ value, setValue, label }) => {
	const lowerRes = useMediaQuery({ maxWidth: 468 });

	const [editorError, setEditorError] = useAtom(FEditorError);



	const quillRef = useRef<ReactQuill | null>(null);

	const Parchment = Quill.import('parchment');

	const customFontFamilyAttributor = new Parchment.Attributor.Style('custom-family-attributor', 'font-family');
	const customSizeAttributor = new Parchment.Attributor.Style('custom-size-attributor', 'font-size');
	const customColorAttributor = new Parchment.Attributor.Style('custom-color-attributor', 'color');

	const ListItemBlot = Quill.import('formats/list/item');

	class CustomListItem extends ListItemBlot {
		constructor(domNode: any) {
			super(domNode);
			this.initialLoad = true;
		}

		optimize(context: any) {
			super.optimize(context);

			if (this.initialLoad) {
				this.initialLoad = false;

				if (this.children.length >= 1) {
					const child = this.children.head;
					const attributes = child?.attributes?.attributes;

					if (attributes) {
						setTimeout(() => {
							for (const key in attributes) {
								const element = attributes[key];
								const name = element.keyName;
								const value = element.value(child.domNode);

								const isRestricted = value.includes('--');

								if (name === 'color' && !isRestricted) this.format('custom-color-attributor', value);
								else if (name === 'font-family') this.format('custom-family-attributor', value);
								else if (name === 'font-size') this.format('custom-size-attributor', value);
							}
						}, 0);
					}
				}
			} else {
				if (this.children.length >= 1) {
					const child = this.children.head;
					const attributes = child?.attributes?.attributes;

					if (attributes) {
						for (const key in attributes) {
							const element = attributes[key];
							const name = element.keyName;
							const value = element.value(child.domNode);

							const isRestricted = value.includes('--');

							if (name === 'color' && !isRestricted) super.format('custom-color-attributor', value);
							else if (name === 'font-family') super.format('custom-family-attributor', value);
							else if (name === 'font-size') super.format('custom-size-attributor', value);
						}
					}
				}
			}
		}
	}


	Quill.register(customColorAttributor, true);
	Quill.register(customFontFamilyAttributor, true);
	Quill.register(customSizeAttributor, true);
	Quill.register(CustomListItem, true);


	const Font = Quill.import('formats/font');
	Font.whitelist = [
		'abel',
		'alegreya',
		'alice',
		'amatic-sc',
		'antic-slab',
		'anton',
		'arimo',
		'arvo',
		'asapcondensed',
		'barlow',
		'bitter',
		'bodoni-moda',
		'bree-serif',
		'cabin',
		'cardo',
		'cinzel',
		'comme',
		'cormorant-garamond',
		'cormorant',
		'crete-round',
		'crimson-pro',
		'crimson-text',
		'delius-swash-caps',
		'dm-serif-display',
		'dm-serif-text',
		'dmsans',
		'domine',
		'dosis',
		'eb-garamond',
		'eczar',
		'epilogue',
		'exo',
		'exo2',
		'faustina',
		'frank-ruhl-libre',
		'gelasio',
		'gloria-hallelujah',
		'handlee',
		'heebo',
		'ibm-plex-serif',
		'ibmplexsans',
		'indie-flower',
		'inter',
		'istokweb',
		'josefin-slab',
		'jost',
		'kalam',
		'kanit',
		'kristi',
		'lato',
		'libre-baskerville',
		'lora',
		'marcellus',
		'marck-script',
		'martel',
		'mavenpro',
		'merriweather',
		'Monospace',
		'montserrat',
		'mukta',
		'mulish',
		'nanum-myeongjo',
		'neuton',
		'nobile',
		'noticia-text',
		'noto-serif',
		'notosans',
		'nunito',
		'nunitosans',
		'old-standard-tt',
		'opensans',
		'oswald',
		'oxygen',
		'patrick-hand-sc',
		'patrick-hand',
		'permanent-marker',
		'playfair',
		'poppins',
		'prata',
		'prompt',
		'pt-serif',
		'ptsans',
		'ptsansnarrow',
		'publicsans',
		'quattrocento',
		'quicksand',
		'raleway',
		'roboto-slab',
		'roboto',
		'robotocondensed',
		'rock-salt',
		'rokkitt',
		'rubik',
		'Sans serif',
		'sawarabi-mincho',
		'Serif',
		'shadows-into-light',
		'slabo-27px',
		'source-serif-pro',
		'sourcesanspro',
		'spectral',
		'sue-ellen-francisco',
		'tinos',
		'titilliumweb',
		'ubuntu',
		'ultra',
		'unna',
		'vidaloka',
		'vollkorn',
		'worksans',
		'ysabeau',
		'zilla-slab',
	];
	Quill.register(Font, true);

	Quill.register('modules/imageActions', ImageActions, true);
	Quill.register('modules/imageFormats', ImageFormats, true);

	const handleImageUpload = (file: File) => {
		if (file.size > 5 * 1024 * 1024) {
			showFailedAlert('Please upload an image file that is less than 5MB in size.');
			return;
		}

		const reader = new FileReader();
		const editor = quillRef.current?.getEditor();

		reader.onload = () => {
			const imageUrl = reader.result;

			if (editor) {
				const range = editor.getSelection(true);
				editor.insertEmbed(range?.index || 0, 'image', imageUrl, 'user');
			}
		};

		reader.readAsDataURL(file);
	};


	const [showToolbar, setShowToolbar] = useState<boolean>(true);
	const [key, setKey] = useState<number>(0);

	const modules = {
		imageActions: {},
		imageFormats: {},
		toolbar: {
			container: [
				['bold', 'italic', 'underline', 'strike'],
				[{ size: [] }],				
				showToolbar ? [
					{ color: ['red', '#785412', 'blue', 'green', 'yellow', 'orange', 'purple', 'pink', 'brown', 'black', 'white', 'gray', 'silver', 'gold', 'navy', 'teal', 'olive', 'maroon', 'turquoise', 'magenta'] },
					{ background: ['#00000000', 'red', '#785412', 'blue', 'green', 'yellow', 'orange', 'purple', 'pink', 'brown', 'black', 'white', 'gray', 'silver', 'gold', 'navy', 'teal', 'olive', 'maroon', 'turquoise', 'magenta'] },
					{ font: Font.whitelist },
					{ list: 'ordered' },
					{ list: 'bullet' },
					{ script: 'sub' },
					{ script: 'super' },
					{ align: [] },
					{ link: 'link' },
					{ image: 'image' },
				] : []
			]
		}
	};

	useEffect(() => {
		setKey(key + 1);
	}, [showToolbar]);

	useEffect(()=> {
		if(lowerRes){
			setShowToolbar(false);
		}else{
			setShowToolbar(true);
		}
	}, [lowerRes]);


	useEffect(() => {
		quillRef.current
			?.getEditor()
			.getModule('toolbar')
			.addHandler('image', () => {
				const input = document.createElement('input');
				input.setAttribute('type', 'file');
				input.setAttribute('accept', 'image/*');
				input.click();

				input.onchange = async () => {
					const file = input.files && input.files[0];

					if (file) {
						handleImageUpload(file);
					}
				};
			});
	}, [quillRef]);



	const formats = [
		'align',
		'header',
		'background',
		'bold',
		'list',
		'bullet',
		'color',
		'float',
		'font',
		'height',
		'width',
		'image',
		'italic',
		'link',
		'script',
		'strike',
		'size',
		'underline'
	];
	const handleProcedureContentChange = (content: React.SetStateAction<string>) => {
		setValue(content);
	};

	// Dropdown

	// const [quillValue, setQuillValue] = useState<string>('');
	// const [selectedOption, setSelectedOption] = useState<Option | null>(null);

	// const handleQuillChange = (value: string) => {
	// setQuillValue(value);
	// };

	// const handleDropdownChange = (selectedOption: Option | null) => {
	// setSelectedOption(selectedOption);
	// applyFormat(selectedOption?.value);
	// };

	// const applyFormat = (format: string | undefined) => {
	// if (!format) return;
	// const quill = quillRef.current?.getEditor();
	// if (!quill) return;
	// const selection = quill.getSelection();
	// if (selection) {
	// 	quill.format(format, !quill.getFormat(selection)[format]);
	// }
	// };

	// const quillRef = useRef<ReactQuill | null>(null);

	// const dropdownOptions: Option[] = [
	// { value: 'strike', label: 'Strike' },
	// ];

	// const CustomToolbar = () => (
	// <div id="toolbar">
	// 	<Select
	// 		value={selectedOption}
	// 		options={dropdownOptions}
	// 		onChange={handleDropdownChange}
	// 	/>
	// </div>
	// );


	const lowerResolutionModules = {
		imageActions: {},
		imageFormats: {},
		toolbar: [
			[{ font: Font.whitelist }, 'bold', 'italic', 'underline'],

			// [{'dropdown': 'strike',
			// 	[{ size: [] }],
			// 	[{ header: [1, 2, 3, 4, 5, 6, false] }],
			// 	[{ color: ['red', '#785412', 'blue', 'green', 'yellow', 'orange', 'purple', 'pink', 'brown', 'black', 'white', 'gray', 'silver', 'gold', 'navy', 'teal', 'olive', 'maroon', 'turquoise', 'magenta'] }],
			// 	[{ background: ['red', '#785412', 'blue', 'green', 'yellow', 'orange', 'purple', 'pink', 'brown', 'black', 'white', 'gray', 'silver', 'gold', 'navy', 'teal', 'olive', 'maroon', 'turquoise', 'magenta'] }],

			// 	[{ list: 'ordered' }, { list: 'bullet' }],
			// 	[{ script: 'sub' }, { script: 'super' }],

			// 	[{ align: [] }],
			// 	['link', 'image']
			// }]

		]
	};

	const Link = Quill.import('formats/link');

	class CustomLink extends Link {

		static sanitize(url: string) {
			const value = super.sanitize(url);
			if (value) {
				for (const element of this.PROTOCOL_WHITELIST)
					if (value.startsWith(element))
						return value;

				return `https://${value}`;
			}
			return value;
		}
	}
	Quill.register(CustomLink, true);

	const byteSize = (text: string) => {
		const blob = new Blob([text]);
		const sizeInBytes = blob.size;
		const sizeInMB = sizeInBytes / (1024 * 1024);

		return sizeInMB;
	};

	useEffect(() => {
		if (byteSize(value) > 10) {
			setEditorError(true);
		} else {
			setEditorError(false);
		}
	}, [value]);

	return (
		<div className='flex flex-col relative mb-20'>
			<div className='flex flex-row' >
				<span className='text-sm text-black font-bold'>{label}</span>
				{/* <div className='block max-sm:hidden'> */}
				<p className='w-full pr-1 text-right text-sm'>Total Size: <span className={classNames(editorError ? 'text-red-500' : 'text-[#707070]')}>{byteSize(value).toFixed(2)}MB</span></p>
			</div>
			
			{ lowerRes ? <button className='absolute w-12 h-7 top-8 left-60 z-20 text-sm' onClick={() => setShowToolbar(!showToolbar)}>{showToolbar ? 'Less ' : 'More▼'}</button> : <></>}
			<ReactQuill
				key={key}
				theme='snow'
				ref={(e) => (quillRef.current = e)}
				modules={modules}
				formats={formats}
				value={value}
				onChange={(text: string, delta: any, source: string, editor: any) => {
					if (source == 'user') {
						// place whatever function you want to execute when user types here:
						setValue(text);
					}
				}}
			/>
		</div>
	);
};

export default Editor;
