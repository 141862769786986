import { Button } from 'components/Common/Buttons';
import Envelope from '../../../../assets/ThankyouMessage/envelope.png';
import React from 'react';
import Shadow from '../../../../assets/ThankyouMessage/shadow.png';
import { Link } from 'react-router-dom';

const RequestEmailForgot: React.FC = () =>{
	return(
		<div className='max-md:h-full max-sm:h-full w-full h-[90vh] flex flex-col justify-center items-center bg-[#F6F6F6]'>
			<div className='p-4'>
				<img className='w-[230px] h-[200px]' src={Envelope} alt='envelope image' />
				<img className='w-[180px] -mt-2' src={Shadow} alt='shadow image' />
			</div>
			<div className='p-2 font-light'>
				<h1 className='text-center font-bold text-[#464255] tracking-wide'>Password Reset Request Sent!</h1>
			</div>
			<div className='max-md:w-3/4 max-sm:w-3/4 p-2 w-1/2 mb-7 text-[#464255] text-[15px] font-normal tracking-wide'>
				<p className='text-center'>We have sent a password recovery link to your email.</p>
				<p className='mt-4 text-center'>If you didn&apos;t receive it in your primary inbox, please check your email&apos;s spam or junk folder. If it&apos;s not there, you can re-request a password reset link. Just make sure you&apos;re entering the correct email address linked to your SnapToApp account.</p>
			</div>
			<Link id='nav-link' to='/login' className='max-md:w-1/2 max-sm:w-1/2 w-1/4 py-1.5 text-sm flex justify-center font-semibold items-center bg-primary border rounded-[5px] shadow-sm focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed hover:bg-[#e98923] border-transparent disabled:bg-primary text-white'>
				Back To Sign In
			</Link>
		</div>
	);
};

export default RequestEmailForgot;
