import { AppFeatIsSaving, SAppNameChanges, SIsAppearanceChanges, SIsLeavingSectionOpen } from 'store/CustomizeApp/Appearance';
import {
	CreditCardIcon,
	DevicePhoneMobileIcon,
	GlobeAltIcon,
	PaintBrushIcon
} from '@heroicons/react/24/outline';
import { FIsFeaturesChange, SFeaturesTab } from 'store/CustomizeApp/Features';
import { SSubCategErrorMessage, SSubCategErrorModalShow } from 'store/subCategEmptyAtom';
import { stepperAtom, tempStepper } from 'store/CustomizeApp/Stepper';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { FIsAlreadySubscribed } from 'store/CustomizeApp/Payment/subscription';
import React, { useEffect } from 'react';
import { SIndustrySubCategory } from 'store/CustomizeApp/Appearance/flashScreenAtom';
import { SIsSidebarOpen } from 'store/CustomizeApp/Appearance/sideBarAtom';
import { SubCategoryModal } from 'components/Common/Modals/SubCategoryModal';
import { classNames } from '../../../../utility';
import { useMediaQuery } from 'react-responsive';
import { useQueryClient } from 'react-query';
import { GetAppResponse } from 'services/requests/apps/schema';
import { FContentError, FShowContentErrorModal } from 'store/CustomizeApp/Features/contentAtom';
import { FEditorError, FShowEditorErrorModal } from 'store/createAppAtom';
import * as subscriptionAtom from '../../../../store/CustomizeApp/Payment/subscription';

const Stepper: React.FC = () => {
	const queryClient = useQueryClient();
	const cachedApp = queryClient.getQueryData<GetAppResponse>('app');

	const [currentStep, setCurrentStep] = useAtom(stepperAtom);
	const [tempStep, setTempStep] = useAtom(tempStepper);
	const isAlreadySubscribed = useAtomValue(FIsAlreadySubscribed);
	const [category, setCategory] 			= useAtom(SIndustrySubCategory);
	const setIsSideBarOpen = useSetAtom(SIsSidebarOpen);
	const [featTab,setFeatTab] = useAtom(SFeaturesTab);
	const setOpenLeaveSectionModal = useSetAtom(SIsLeavingSectionOpen);
	const isAppNameChange = useAtomValue(SAppNameChanges);
	const isAppearanceChanges = useAtomValue(SIsAppearanceChanges);
	const isFeaturesChanges = useAtomValue(FIsFeaturesChange);
	const isSaving = useAtomValue(AppFeatIsSaving);

	const mdViewPortAlready = useMediaQuery({ maxWidth: 768 });
	const maxSmViewPortTextChange = useMediaQuery({ maxWidth: 475 });

	const NAV_CONTAINER_CLASS = 'w-1/4 h-14 flex items-center justify-center';
	const NAV_CONTENT_CLASS = 'flex items-center gap-1';

	const [ subCategModalShow, setSubCategModalShow ] = useAtom(SSubCategErrorModalShow);
	const [subCategErrorMessage, setSubCategErrorMessage] = useAtom(SSubCategErrorMessage);
	
	const contentError = useAtomValue(FContentError);
	const setContentErrorModalShow = useSetAtom(FShowContentErrorModal);

	const editorError = useAtomValue(FEditorError);
	const setEditorErrorModalShow = useSetAtom(FShowEditorErrorModal);
	const setDefaultSelected = useSetAtom(subscriptionAtom.isDefaultSelected);

	const handleClickSubCateg = () => {
		setSubCategErrorMessage(true);
		setSubCategModalShow(false);
	};

	const onClickFeaturesTab = () => {
		if (contentError) setContentErrorModalShow(true);
		else if (editorError) setEditorErrorModalShow(true);
		else if(category){
			if(isAppearanceChanges || isAppNameChange){
				setOpenLeaveSectionModal(true);
				setTempStep(1);
			}else setCurrentStep(1);
		} else setSubCategModalShow(true);
	};

	const onClickPayTab = () => {
		setDefaultSelected(false);
		if (contentError) setContentErrorModalShow(true);
		else if (editorError) setEditorErrorModalShow(true);
		else if(category){
			if(isAppearanceChanges || isFeaturesChanges || isAppNameChange ){
				setOpenLeaveSectionModal(true);
				setTempStep(2);
			}else setCurrentStep(2);
		} else setSubCategModalShow(true);
	};

	useEffect(() => {
		if(subCategModalShow) {
			setTempStep(0);
			setCurrentStep(0);
		}
	}, [subCategModalShow]);

	return (
		<>
			<div className='bg-[#F6F6F6] mt-4'>
				
				<SubCategoryModal isSubCategModalShow={subCategModalShow} setSubCategModalShow={setSubCategModalShow} onClickOK={handleClickSubCateg} />
				
				<div className='flex justify-around gap-1.5 2xl:gap-1.5 xl:gap-1.5 lg:gap-1.5 md:gap-1.5 sm:gap-1.5 max-sm:gap-[0.5px]'>
					<div
						className={classNames(
							(isSaving || cachedApp?.app.status === 'TRI') ? 'bg-[#E6E4E4] cursor-not-allowed' : 'cursor-pointer',
							currentStep === 0 
								? 'bg-[#007CA8] cursor-pointer'
								: cachedApp?.app.status !== 'TRI' ? 'bg-white cursor-pointer' : '',
							NAV_CONTAINER_CLASS
						)}
						onClick={() => {
							(contentError) ? setContentErrorModalShow(true) : 
								(editorError) ? setEditorErrorModalShow(true) : 
									(cachedApp?.app.status !== 'TRI') && (isFeaturesChanges || isAppNameChange ? (setOpenLeaveSectionModal(true), setTempStep(0)) : (setCurrentStep(0), setIsSideBarOpen(true), setFeatTab('Home')));
						}}
					>
						<div className={classNames(NAV_CONTENT_CLASS, 'flex flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col max-sm:flex-col')}>
							<PaintBrushIcon
								className={classNames(
									currentStep === 0
										? 'text-white pt-0 2xl:pt-0 xl:pt-0 lg:pt-0 md:pt-0 sm:pt-2 max-sm:pt-2'
										: 'text-[#1C180F] pt-0 2xl:pt-0 xl:pt-0 lg:pt-0 md:pt-0 sm:pt-2 max-sm:pt-2',
									'w-8 h-8')}
							/>
							<span className={classNames(
								currentStep === 0 
									? 'text-white 2xl:text-lg xl:text-lg lg:text-lg md:text-lg sm:text-md max-sm:text-md'
									: 'text-[#1C180F] 2xl:text-lg xl:text-lg lg:text-lg md:text-lg sm:text-md max-sm:text-md',
								// mdViewPortAlready
								// 	? 'text-md'
								// 	: 'text-lg',
								maxSmViewPortTextChange 
									? 'text-xs' 
									: ''
								
							)}>
								Appearance
							</span>
						</div>
					</div>

					<div
						className={classNames(
							(isSaving || cachedApp?.app.status === 'TRI') ? 'bg-[#E6E4E4] cursor-not-allowed' : 'cursor-pointer',
							currentStep === 1 
								? 'bg-[#007CA8] cursor-pointer'
								: cachedApp?.app.status !== 'TRI' ? 'bg-white cursor-pointer' : '',
							NAV_CONTAINER_CLASS
						)}
						onClick={() => (cachedApp?.app.status !== 'TRI') && onClickFeaturesTab()}
					>
						<div className={classNames(NAV_CONTENT_CLASS, 'flex flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col max-sm:flex-col')}>
							<DevicePhoneMobileIcon className={classNames(
								currentStep === 1
									? 'text-white pt-0 2xl:pt-0 xl:pt-0 lg:pt-0 md:pt-0 sm:pt-2 max-sm:pt-2'
									: 'text-[#1C180F] pt-0 2xl:pt-0 xl:pt-0 lg:pt-0 md:pt-0 sm:pt-2 max-sm:pt-2',
								'w-8 h-8')} />
							<span className={classNames(
								currentStep === 1 
									? 'text-white 2xl:text-lg xl:text-lg lg:text-lg md:text-lg sm:text-md max-sm:text-md' 
									: 'text-[#1C180F] 2xl:text-lg xl:text-lg lg:text-lg md:text-lg sm:text-md max-sm:text-md',
								// mdViewPortAlready
								// 	? 'text-md'
								// 	: 'text-lg',
								maxSmViewPortTextChange 
									? 'text-xs' 
									: ''
							)}>
								Features
							</span>
						</div>
					</div>
					<div
						className={classNames(
							isSaving ? 'pointer-events-none bg-[#E6E4E4] cursor-not-allowed' : 'cursor-pointer',
							currentStep === 2 
								? 'bg-[#007CA8] cursor-pointer'
								: 'bg-white cursor-pointer',
							NAV_CONTAINER_CLASS
						)}
						onClick={onClickPayTab}
					>
						<div className={classNames(NAV_CONTENT_CLASS, 'flex flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col max-sm:flex-col')}>
							<CreditCardIcon className={classNames(
								currentStep === 2
									? 'text-white pt-0 2xl:pt-0 xl:pt-0 lg:pt-0 md:pt-0 sm:pt-2 max-sm:pt-2'
									: 'text-[#1C180F] pt-0 2xl:pt-0 xl:pt-0 lg:pt-0 md:pt-0 sm:pt-2 max-sm:pt-2',
								'w-8 h-8 ')} />
							<span className={classNames(
								currentStep === 2 
									? 'text-white 2xl:text-lg xl:text-lg lg:text-lg md:text-lg sm:text-md max-sm:text-md'
									: 'text-[#1C180F] 2xl:text-lg xl:text-lg lg:text-lg md:text-lg sm:text-md max-sm:text-md',
								// mdViewPortAlready
								// 	? 'text-md'
								// 	: 'text-lg',
								maxSmViewPortTextChange 
									? 'text-xs' 
									: ''
							)}>
								Pay
							</span>
						</div>
					</div>
					<div
						className={classNames(
							isSaving ? 'pointer-events-none bg-[#E6E4E4] cursor-not-allowed' : '',
							isAlreadySubscribed ? 
								currentStep === 3 ? 'bg-[#007CA8] cursor-pointer' 
									: 'bg-white cursor-pointer' 
								: 'bg-[#E6E4E4] cursor-not-allowed',
							NAV_CONTAINER_CLASS
						)}
						onClick={() => {
							(contentError) ? setContentErrorModalShow(true) :
								(editorError) ? setEditorErrorModalShow(true) :
									isAlreadySubscribed && (isAppearanceChanges || isFeaturesChanges ? (setOpenLeaveSectionModal(true), setTempStep(3)) : (setCurrentStep(3)), setIsSideBarOpen(false), setFeatTab(featTab));
						}}
					>
						<div className={classNames(NAV_CONTENT_CLASS, 'flex flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col max-sm:flex-col')}>
							<GlobeAltIcon
								className={classNames(
									currentStep === 3
										? 'text-white pt-0 2xl:pt-0 xl:pt-0 lg:pt-0 md:pt-0 sm:pt-2 max-sm:pt-2'
										: 'text-[#1C180F] pt-0 2xl:pt-0 xl:pt-0 lg:pt-0 md:pt-0 sm:pt-2 max-sm:pt-2',
									'w-8 h-8'
								)}
							/>
							<span className={classNames(
								currentStep === 3 
									? 'text-white 2xl:text-lg xl:text-lg lg:text-lg md:text-lg sm:text-md max-sm:text-md'
									: 'text-[#1C180F] 2xl:text-lg xl:text-lg lg:text-lg md:text-lg sm:text-md max-sm:text-md',
								// mdViewPortAlready
								// 	? 'text-md'
								// 	: 'text-lg',
								maxSmViewPortTextChange 
									? 'text-xs' 
									: ''
							)}>
								Publish
							</span>
						</div>
					</div>
				</div>
			</div>
			<div className='w-full h-1.5 bg-[#007CA8]' />
		</>
	);
};

export default Stepper;