import { FContents, FContentsRefresh, IContent } from '../../../../../store/CustomizeApp/Features/contentAtom';
import { FIsContentChanges, FIsFeaturesChange } from 'store/CustomizeApp/Features';
import React, { useEffect } from 'react';
import { getAllContent, getAllContentLive } from 'services/requests/Content';
import { useAtom, useSetAtom } from 'jotai';

import { AxiosError } from 'axios';
import { GetContentFormResponse } from 'services/requests/Content/schema';
import { PIsPublishShowContent } from 'store/CustomizeApp/Publish/publishAtom';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { log } from 'utility/log';

export const ContentQuery = () => {

	const [contentRefresh, setContentRefresh]	     = useAtom(FContentsRefresh);
	const setFeaturesChange 		 				 = useSetAtom(FIsFeaturesChange);
	const setContentChanges 		 				 = useSetAtom(FIsContentChanges);
	const setPublishShow 							 = useSetAtom(PIsPublishShowContent);

	const [contentList, setContentList]				 = useAtom(FContents);
	
	const {id} = useParams();

	const {data: contentLive, refetch: refetchLive} = useQuery<GetContentFormResponse, AxiosError>({
		queryKey: ['contentLive', id],
		queryFn: () => getAllContentLive(id),
		enabled: false,	
		retry: 0,
		onError: () => setContentRefresh(false)
	});

	const {data, refetch, isFetching, isFetched, isSuccess} = useQuery<GetContentFormResponse, AxiosError>({
		queryKey: ['content', id],
		queryFn: () => getAllContent(id),
		retry: 0,
		staleTime: Infinity,
		onSuccess: (data) => {
			if (data?.rows) {
				const contents = (data.rows.map((content) => {
					return {
						id: content.contentID,
						icon: content.icon,
						title: content.title,
						description: content.body,
						toDelete: false,
					};
				}));

				setContentList(contents);
			}
		},
	});
	
	useEffect(() =>{
		if(contentRefresh){
			refetch();
			refetchLive();
		}
	},[contentRefresh]);

	// useEffect(() =>{
	// 	if(isFetching){
	// 		setFeaturesChange(false);
	// 	}
	// },[isFetching]);

	useEffect(() => {
		if (contentList.length < 1) {
			const updatedContents: IContent[] = [{
				id: 0,
				icon: 'far fa-file-lines fa-solid',
				title: 'Content',
				description: '',
				toDelete: false
			}];
			setContentList(updatedContents);
		}
	}, [contentList]);

	useEffect(() => {
		if (isFetched && isSuccess) {
			if ((data?.rows !== undefined && (data.rows.length !== contentList.length)))
			{
				// setFeaturesChange(true);
				setContentChanges(true);
				log('content 1');
			} else if (data?.rows !== undefined) {
				let changes = 0;

				data.rows.map((row, index) => {
					const content = contentList.find((content) => content.id === row.contentID);

					const changed = (content) && (
						row.title !== contentList[index]?.title ||
						row.icon !== contentList[index]?.icon ||
						(row.body !== contentList[index]?.description && !(row.body === undefined &&
							(contentList[index]?.description === '<p><br></p>' ||
								(contentList[index]?.description === undefined)))) ||
						content.toDelete === true
					);

					if (changed) {
						changes++;
					}

					if (data.rows.length - 1 === index && changes > 0) {
						// setFeaturesChange(true);
						setContentChanges(true);
						log('content 2');
					} else {
						// setFeaturesChange(false);
						setContentChanges(false);
					}
				});
			}
		} else if (data?.rows && data.rows.length !== contentList.length || data?.rows === undefined && contentList.length > 0) {
			// setFeaturesChange(true);
			setContentChanges(true);
			log('content 3');
		}
	},[data?.rows, contentList, isFetched, isSuccess]);

	useEffect(() => {
		if(data?.rows !== undefined && contentLive?.rows !== undefined){
			const checkDraftLive = data.rows.map((current) => ({
				match: contentLive.rows.some((live) => live.title === current.title && live.icon === current.icon && live.body === current.body),
			}));
			
			if(checkDraftLive.find((check) => check.match === false) !== undefined){
				setPublishShow(true);
			}else setPublishShow(false);
		}
	},[data, contentLive]);
};