import React from 'react';
import Modal from '../Modal';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
interface IVideoModal {
	isVideoModalShow: boolean;
	setVideoModalShow: React.Dispatch<React.SetStateAction<boolean>>;
	onClickCancel: (e: any) => void;
}

export const VideoModal: React.FC<IVideoModal> = ({isVideoModalShow, setVideoModalShow, onClickCancel}) => {

	const renderVideoModal = (): JSX.Element => {
		return(
			<>
			  <div className='sm:mx-auto sm:w-full sm:h-full sm:max-w-screen-lg sm:max-h-screen-md flex lg:max-w-[1000px] bg-[#e6e4e4] rounded'>
				<button
				  onClick={onClickCancel}
				  className='fixed z-50 border-solid border-[1px] border-[#636363] top-2 right-2 text-[#636363] bg-white py-1 px-3 rounded-full'
				>
				  <FontAwesomeIcon icon={faXmark} />
				</button>
				<div className="w-full h-full flex justify-center items-center object-cover">
				  <video
					src={'https://snaptoapp-dev.s3.us-west-2.amazonaws.com/public/demo/SNAP+TO+APP.mp4'}
					controls
					autoPlay
					className="muted:unmute w-full h-full rounded"
				  />
				</div>
			  </div>
			</>
		  );
		};
	  
		return (
		  <div>
			<Modal open={isVideoModalShow} onClose={setVideoModalShow} className='max-w-full max-h-full '>
			  {renderVideoModal()}
			</Modal>
		  </div>
		);
	  };