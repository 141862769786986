import { RGBColor } from 'react-color';
import { atom } from 'jotai';

export const SIcon                        = atom<any | null>(null);
export const SLogoID                      = atom<number>(0);
export const SFlashLogoFile               = atom<any | null>(null);
export const SCustomBackgroundID          = atom<number>(0);
export const SFlashCustomBackgroundFile   = atom<any | null>(null);
export const SBackgroundImage             = atom<any | null>(null);
export const SFlashBackgroundID           = atom<number>(0);
export const SPreviewBackgroundImage      = atom<any | null>(null);
export const SIndustrySubCategory         = atom<number>(0);
export const SBackgroundColor             = atom<string>('');
export const SBackgroundColorOptions      = atom<string>('');
export const SOverlayColor                = atom<string>('#000');
export const SOverlayValue                = atom<number>(0);
export const SOpacityFlashBackgroundImage = atom<number>(1);
export const SOpacityValue                = atom<RGBColor>({r: 0, g: 0, b: 0, a: 1});
export const SBlurBackgroundImage         = atom<any>('0');
export const SCustomBgFocus               = atom<boolean>(false);
export const SIsFlashRefresh              = atom<boolean>(true);
export const SIsSubCatFetched             = atom<boolean>(false);
	
export const SIndustySubCategories 		  = atom([{label: '', value: 0}]);