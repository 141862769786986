import { GetAccessibilityFormType, GetAccessibilitySchema, GetAccessibilityFormResponse } from './schema';

import { axiosInstance } from 'services/axios';

export const getAccessibilityByAppIDLive = async (appID?: string) => {
	const response = await axiosInstance.get(`/organizations/apps/${appID}/accessibility?env=LIV`);
    
	return response.data as GetAccessibilityFormResponse;
};

export const getAccessibilityByAppID = async (appID?: string) => {
	const response = await axiosInstance.get(`/organizations/apps/${appID}/accessibility`);
    
	return response.data as GetAccessibilityFormResponse;
};

export const createAccessibility = async (appID?: string, data?: GetAccessibilityFormType) => {
	const validatedPrivacyPolicy = GetAccessibilitySchema.parse(data);
	const response = await axiosInstance.post(`/organizations/apps/${appID}/accessibility`, validatedPrivacyPolicy);
	return response.data as GetAccessibilityFormResponse;
};

export const editAccessibilityByAppID = async (appID?: string, accessibilityID?: number, param?: GetAccessibilityFormType) => {
	const response = await axiosInstance.put(`/organizations/apps/${appID}/accessibility/${accessibilityID}`, param);
    
	return response.data as GetAccessibilityFormResponse;
};