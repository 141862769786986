import { SLayoutId, SLayoutType } from 'store/CustomizeApp/Appearance/layoutAtom';
import { SThemeId, SThemeType } from 'store/CustomizeApp/Appearance/themeAtom';
import { useAtom, useSetAtom } from 'jotai';

import { AxiosError } from 'axios';
import { GetAllLayoutResponse } from 'services/requests/appearance/layout/schema';
import { GetLayoutResponse } from 'services/requests/appearance/layout/schema';
import { IMAGES } from 'utility/constant';
import React from 'react';
import { classNames } from 'utility';
import { getAllLayout } from 'services/requests/appearance/layout';
import { useQuery } from 'react-query';
import Tooltip from 'components/Common/Tooltip';

const Layout: React.FC = () => {

	const setLayoutType = useSetAtom(SLayoutType);
	const [layoutID, setLayoutID] = useAtom(SLayoutId);

	const [themeType, setThemeType] = useAtom(SThemeType);
	const [themeID, setThemeID] = useAtom(SThemeId);
	
	const onClickLayout = (layout: GetLayoutResponse) => {
		setLayoutType(layout.layout.name);
		setLayoutID(layout.layout.layoutID);

		if ((layout.layout.name === 'Buttons and Side Bar' && themeType === 'Borderless') || (layout.layout.name === 'Matrix' && themeType === 'Solid')) {
			setThemeType('Fill');
			setThemeID(1);
		}
	};

	const {data} = useQuery<GetAllLayoutResponse, AxiosError>({
		queryKey: ['layouts'],
		queryFn: () => getAllLayout(),
		retry: 0
	});
	
	return(
		<div className='bg-white rounded-lg my-8 mr-6 w-full'>
			<div className='w-full px-10 rounded'>
				<div className='py-5 flex-row'>
					<div className='flex justify-between items-center pt-2 2xl:pb-0 xl:pb-0 lg:pb-0 md:pb-10 sm:pb-10 max-sm:pb-10'>
						<span className='text-[#707070] font-bold text-2xl block 2xl:text-2xl xl:text-2xl lg:text-2xl md:text-xl sm:text-xl max-sm:text-xl'>Layout</span>
						<div className='flex gap-3 items-baseline'>
							{/* -----------Video Tutorial and Help Icons------------ */}
							<Tooltip className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
								<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.CameraIcon} alt='camera icon' />
							</Tooltip>
							<Tooltip className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
								<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.InfoIcon} alt='info icon' />
							</Tooltip>
							{/* ---------------------------------------------------- */}
						</div>
					</div>
					<div className='flex gap-16 py-auto max-sm:flex-col max-sm:gap-10 max-sm:items-center'>
						{data?.rows.map((layout, idx) => (
							layout.status === 'ACT' && 
							<div key={idx}>
								<img onClick={() => onClickLayout({layout: layout})} className={classNames('w-[170px] h-[304px] cursor-pointer', layoutID === layout.layoutID ? 'outline outline-primary rounded' : '')} src={layout.imageURL} alt='layout' />
								<div className='text-xs font-medium text-primary text-center pt-2'> {layout.name} </div>
							</div>
						))}
					</div>
					{/* <div className='flex gap-8 py-5 flex items-center 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-row sm: max-sm:flex-col'>
						{data?.rows.map((layout, idx) => (
							layout.status === 'ACT' && 
							<div key={idx} className={classNames('relative z-[1]')}>
								<img onClick={() => onClickLayout({layout: layout})} className={classNames('w-[180px] h-[319px]', layoutID === layout.layoutID ? 'outline outline-8 outline-primary rounded' : '', layout.layoutID === 1 ? 'brightness-75 pointer-events-none' : 'cursor-pointer')} src={layout.imageURL} alt='layout' />
								<div className='text-xs font-medium text-primary text-center pt-2 max-sm:pb-[40px]'> {layout.name} </div>
								
								{layout.layoutID === 1 && 
								<div className="ribbon absolute top-5 -right-3 drop-shadow-[2px_3px_2px_rgba(0,0,0,0.4)]">
									<div className="clip-path-polygon-[0_0,_100%_0,_100%_calc(100%_-_8px),100%,_0_calc(100%_-_8px)] flex h-8 w-28 justify-center bg-[#007CA8] py-2 px-1 text-center text-xs text-white">
										<p>Coming Soon</p>
									</div>
								</div>}
							</div>
						))}<div className='px-1'>
							<div className='hidden rounded bg-[#D9D7D7] w-[265px] h-0.5 -mt-[460px] max-sm:block'/>
						</div>
					</div> */}
					<div className='max-sm:mt-[100px]' />
				</div>
			</div>
		</div>
	);
};

export default Layout;