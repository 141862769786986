import { z } from 'zod';

export const GetHomeSchema = z.object({
	home: z.object({
		homeID:         z.number(),
		appID:          z.number(),
		imageUploadID:  z.number(),
		imageURL:       z.string(),
		description:    z.string(),
		isTurnedOn:     z.boolean(),
		status:         z.string(),
		whoAdded:       z.number(),
		whoUpdated:     z.number(),
		whenAdded:      z.string(),
		whenUpdated:    z.string(),
	})
});   

export const CreateEditHomeSchema = z.object({
	imageUploadID:   z.number().optional(), 
	description:     z.string().optional(), 
	isTurnedOn:      z.boolean(),
});

export const UploadHomeImageSchema = z.object({
	ImageUpload: z.object({
		imageUploadID:  z.number(),
		appID:          z.number(),
		name:           z.string(),
		imageURL:       z.string(),
		status:         z.string(),
		whoAdded:       z.number(),
		whoUpdated:     z.number(),
		whenAdded:      z.string(),
		whenUpdated:    z.string(),
	})
});

export const UploadHomeImageParamsSchema = z.object({
	file: z.any()
});

export type CreateHomeType      = z.infer<typeof CreateEditHomeSchema>;
export type CreateHomeResponse  = z.infer<typeof GetHomeSchema>;
export type CreateHomeParams    = z.infer<typeof CreateEditHomeSchema>;

export type GetHomeType       = z.infer<typeof GetHomeSchema>;
export type GetHomeResponse   = z.infer<typeof GetHomeSchema>;

export type EditHomeType      = z.infer<typeof CreateEditHomeSchema>;
export type EditHomeResponse  = z.infer<typeof GetHomeSchema>;
export type EditHomeParams    = z.infer<typeof CreateEditHomeSchema>;

export type UploadHomeImageResponse  = z.infer<typeof UploadHomeImageSchema>;
export type UploadHomeImageParams    = z.infer<typeof UploadHomeImageParamsSchema>;