import React from 'react';


const UserPanel: React.FC = () => {
	return (
		<div className='bg-[#FAFAFA] w-full p-10'>
			<div className='text-[#2C3E6B] text-[20px] font-bold'>User</div>
		</div>
	);
};

export default UserPanel;
