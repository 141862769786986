import * as stripeApis from '../../../../services/requests/Payment/index';
import * as subscriptionAtom from '../../../../store/CustomizeApp/Payment/subscription';
import * as typeSchemas from '../../../../services/requests/Payment/schema';

import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getAllPricing } from 'services/requests/Pricing';
import { GetAllPricing } from 'services/requests/Pricing/schema';
import { getDefaultCard } from 'services/requests/EditProfile/CardManagement';
import { CardResponse } from 'services/requests/EditProfile/CardManagement/schema';
import { log } from 'utility/log';

export const PaymentUseQueries = () => {

	const freePriceId = import.meta.env.VITE_FREE_PRICE_ID;

	const [, setSubscriptionData] 									= useAtom(subscriptionAtom.FSubscription);
	const [, setIsAlreadySubscribed]								= useAtom(subscriptionAtom.FIsAlreadySubscribed);
	const [, setInlineError] 										= useAtom(subscriptionAtom.FInlineError);
	const [, setloadPayPage] 										= useAtom(subscriptionAtom.loadPayPage);

	const FREE_TRIAL_PRICE_ID = freePriceId;

	const { id } = useParams();

	// const { data: plans } = useQuery('pricing-plans', getAllPricing);
	
	const { data: plans } = useQuery<GetAllPricing, AxiosError>({
		queryKey: ['pricing-plans'],
		queryFn: () => getAllPricing(),
		staleTime: Infinity,
		retry: 0
	});

	const { data: getSubscriptionByAppId, isFetching: fetchSubData} =
	useQuery<typeSchemas.GetSubscriptionByIdResponse, AxiosError>({
		queryKey: ['getSubscriptionByAppId', id],
		queryFn: () => stripeApis.getSubscriptionByAppId(id),
		// staleTime: Infinity,
		onSuccess: (data) => {
			setSubscriptionData(data);
			setInlineError('');
			if (data.PriceId !== FREE_TRIAL_PRICE_ID) {
				setloadPayPage(fetchSubData);
				setIsAlreadySubscribed(true);
			}
			else {
				setIsAlreadySubscribed(false);
			}
		},
		onError: (error) => {
			log(error);
		},
		retry: 0,
	});
};