import React from 'react';
import { IMAGES } from 'utility';

export const SearchNotFound = () => {
	return (
		<div className=' flex justify-center items-center flex-col mt-[4rem]'>
			<img src={IMAGES.SearchNotFound} alt='Search Not Found' />
			<div className='mt-10 text-[#46638C] text-center' >
				<h1 className='text-[1.25rem] font-medium'>No Results Found</h1>
				<p className='text-base mt-2 font-medium'>We couldn’t find what you searched <br /> for. Try searching again.</p>
			</div>
		</div>
	);
};