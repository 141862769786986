import z from 'zod';

export const AddCardSchema = z.object({
	paymentMethodId: z.string(),
	cardHolderName: z.string(),
});

export const CardResp = z.object({
	Id: z.string(),
	CustomerId: z.string(),
	Last4: z.string(),
	ExpMonth: z.number(),
	ExpYear: z.number(),
	FingerPrint: z.string(),
	Funding: z.string(),
	Brand: z.string(),
	Country: z.string(),
	CardHolderName : z.string(),
	IsDefault: z.boolean(),
});

export const ListCardResp = z.object({
	CardPaymentMethods: z.array( CardResp)
});


export const DeleteCardSchema = z.object({
	paymentMethodId: z.string(),
});

export const DeleteCardResp = z.object({
	paymentMethodId: z.string(),
});


export const SetDefaultPaymentMethodSchema  = z.object({
	paymentMethodId: z.string(),
});

export const SetDefaultPaymentMethodResp  = z.object({
	customerId: z.string(),
	paymentMethodId: z.string(),
});



export type AddCardRequest = z.infer<typeof AddCardSchema>;
export type CardResponse = z.infer<typeof CardResp>;
export type GetListCardResponse = z.infer<typeof ListCardResp>;
export type DeleteCardResponse = z.infer<typeof DeleteCardResp>;
export type DeleteCardRequest = z.infer<typeof DeleteCardSchema>;
export type SetDefaultPaymentMethodRequest = z.infer<typeof SetDefaultPaymentMethodSchema>;
export type SetDefaultPaymentMethodResponse = z.infer<typeof SetDefaultPaymentMethodResp>;
