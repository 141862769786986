import { axiosInstance } from 'services/axios';
import { UploadUserByAppIDResponse, UserTypeTokenSchema, UserTokenResponse, tokenSchema, UserTokenSchema, UploadUserByAppIDResponse2 } from './schema';


export const getUserByToken = async (token?: UserTokenSchema) => {
	try {
		const response = await axiosInstance.post('/auth/validateToken', token);
		return response.data;
	} catch (error) {
		console.error(error);
	}
};
  
export const getUserById = async (id : any) => {
	const response = await axiosInstance.get(`/organizations/users/${id}`);
	return response.data as UploadUserByAppIDResponse2;
};

export const getAllUsers = async () => {
	const response = await axiosInstance.get('/organizations/users');
	return response.data;
};

export const editUserProfile = async (userID: number, item: UploadUserByAppIDResponse, file?: any, imageUploadID?: any) => {
	const formData = new FormData();
	if(imageUploadID === undefined){
		if(typeof file !== 'string' && file !== undefined){
			formData.append('image', file);
		}
	}else{
		formData.append('imageUploadID', imageUploadID);
		if(typeof file !== 'string' && file !== undefined){
			formData.append('image', file);
		}
	}
	formData.append('firstName', item.firstName);
	formData.append('lastName', item.lastName);
	if (item.phoneNumber) {
		formData.append('phoneNumber', item.phoneNumber);
	}
	if(item.gender){
		formData.append('gender', item.gender);
	}
	if(item.birthDate){
		const dateObj = new Date(item.birthDate);
		const formattedDate = dateObj.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }).replace(/\//g, '-');
		formData.append('birthDate', formattedDate);
	}
	const response = await axiosInstance.put(`/organizations/users/${userID}`, formData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});

	return response.data as UploadUserByAppIDResponse2;
};
