import { Button } from 'components/Common/Buttons';
import Cartoon from 'assets/Cartoon/cartoon.svg';
import { Link } from 'react-router-dom';
import React from 'react';

const Publish: React.FC = () =>{
	return(
		<div className='pt-20 flex flex-col h-screen justify-start items-center'>
			<div className='p-5'>
				<img className='w-[350px] h-[300px]' src={Cartoon} alt='cartoon' />
				
			</div>
			<div className='p-0 font-extrabold text-4xl'>
				<h1 className='text-center text-primary p-2'>Congrats!</h1>
			</div>

			<div className='p-2 w-1/2 mb-1 text-zinc-700 text-[20px] font-medium tracking-wide'>
				<p className='text-center'>You have successfully submitted your Mobile App.
				</p>
			</div>

			<div className='p-0 w-1/2 mb-10 text-zinc-600 text-[18px] font-normal tracking-wide'>
				<p className='text-center'>We will notify you once we have completed our review of your mobile app. <br />
                In the meantime, please check your dashboard for updates on your mobile app’s status.</p>
			</div>


			<Link id='nav-link' to='/dashboard' className='w-full flex justify-center items-center'>
				<Button variant='primary' className='w-1/4 py-2.5 font-semibold text-base'>Check Dashboard</Button> 
			</Link>
		</div>
	);
};

export default Publish;
