import { atom } from 'jotai';

export const STopBarColor          = atom<string>('#E4E4E4');
export const STopBarTextColor      = atom<string>('#000');

export const SNavigationColor      = atom<string>('#E4E4E4');
export const SNavigationIconColor  = atom<string>('#000');
export const SNavigationIconActiveColor = atom<string>('#000');
export const SNavigationTextColor  = atom<string>('#000');
export const SNavigationTextActiveColor  = atom<string>('#000');
export const SNavigationHighlightColor  = atom<string>('#000');

export const SCardBackgroundColor  = atom<string>('#000');
export const SCardBorderColor = atom<string>('#000');
export const SCardIconColor  = atom<string>('#000');
export const SCardTitleColor  = atom<string>('#000');
export const SCardContentColor  = atom<string>('#000');


export const SIsColorRefresh       = atom<boolean>(true);