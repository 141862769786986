import React from 'react';
import ApproverTopbar from './UserApproverTopBar';
import { useNavigate } from 'react-router-dom';

const TopbarApprover: React.FC = () => {
	const navigate = useNavigate();
	const options = [{ value: 'Logout', label: 'Log Out' }];

	const handleSelect = (value: string) => {
		if (value === 'Logout') {
			localStorage.removeItem('token');
			localStorage.removeItem('bearerToken');
			localStorage.removeItem('userData');
			setTimeout(() => {
				window.location.reload();
				navigate('/');
			}, 100);
		}
	};

	return (
		<div className='shadow-md w-full relative z-10 top-0 left-0'>
			<div className='flex items-end justify-end bg-[#3C5491] py-4 px-10'>
				<ul className='flex list-none items-center'>
					<ApproverTopbar options={options} onSelect={handleSelect} />
				</ul>
			</div>
		</div>
	);
};

export default TopbarApprover;
