import React from 'react';
import AppLayout from 'components/AppLayout';
import { useAtomValue } from 'jotai';
import { Navigate, Outlet } from 'react-router';
import { authAtom } from 'store/authAtom';

const Container: React.FC = () => {

	const auth = useAtomValue(authAtom);

	if(auth.access_token === '') {
		return <Navigate to="/" />;
	}

	return <AppLayout isLogin={true} />;
    
};

export default Container;