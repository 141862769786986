import React, { useState } from 'react';
import { showFailedAlert, showSuccessAlert } from 'utility/alerts';
import { useAtom, useSetAtom } from 'jotai';
import { useQuery, useQueryClient } from 'react-query';

import { Button } from 'components/Common/Buttons';
import { CheckCircle } from '@mui/icons-material';
import { IMAGES } from 'utility/constant';
import { PIndustryName } from 'store/CustomizeApp/Publish/publishAtom';
import { SIsColorRefresh } from 'store/CustomizeApp/Appearance/colorAtom';
import { SIsFontRefresh } from 'store/CustomizeApp/Appearance/fontAtom';
import { SIsNavRefresh } from 'store/CustomizeApp/Appearance/navigationAtom';
import { SIsSidebarRefresh } from 'store/CustomizeApp/Appearance/sideBarAtom';
import { SignUpType } from 'services/requests/auth/schema';
import Spinner from 'components/Common/Spinner';
import { classNames } from 'utility';
import { createAppApi } from 'services/requests/AccountManagement/CreateApp';
import { createAppAtom } from 'store/createAppAtom';
import { createAppSchema } from 'services/requests/AccountManagement/CreateApp/schema';
import { getAllIndustry } from 'services/requests/industry';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

const ChooseIndustryPage: React.FC = () => {
	const [isLoading, setIsLoading]      = useState(false);
	const [isDisabled, setIsDisabled]    = useState(false);
	const [userId, setuserId]            = useState(1);
	const [industryId, setIndustryId]    = useState<null | number>(null);
	const [, setIndustryName]            = useAtom(PIndustryName);
	const [name, setName]                = useState('');
	const [websiteUrl, setWebsiteUrl]    = useState('');
	const [logoUrl, setLogoUrl]          = useState('');
	const [click, setClick]              = useState(false);
	const navigate                       = useNavigate();

	//Atoms for Navigation
	const setNavRefresh                   = useSetAtom(SIsNavRefresh);
          
	//Atoms for Sidebar              
	const setSidebarRefresh               = useSetAtom(SIsSidebarRefresh);
          
	//Atoms for Color              
	const setIsColorRefresh               = useSetAtom(SIsColorRefresh);
          
	//Atoms for Font              
	const setIsFontRefresh                = useSetAtom(SIsFontRefresh);
          
	const { data }                        = useQuery('industries', getAllIndustry);
          
	const addFormData                     = createAppApi();

	const [formDataList, setFormDataList] = useAtom(createAppAtom);
	const queryClient = useQueryClient();

	const submitCreateApp = async () => {
		setIsLoading(true);
		setIsDisabled(true);
		const formDataToAdd = {
			userID: userId,
			industryID: industryId,
			name: name,
			websiteUrl: websiteUrl,
			logoUrl: logoUrl,
		};
		const validatedFormData = createAppSchema.parse(formDataToAdd);

		const result = await addFormData.mutateAsync(validatedFormData, {
			onSuccess: (data) => {
				const message = 'You have saved your business name and business category.';
				setIsLoading(false);
				setIsDisabled(false);
				setNavRefresh(true);
				setSidebarRefresh(true);
				setIsColorRefresh(true);
				setIsFontRefresh(true);
				queryClient.invalidateQueries('pwa-apps');
				navigate(`/edit/${data?.app?.appID}`);
				showSuccessAlert(message);
			},
			onError: (data) => {
				setIsLoading(false);
				setIsDisabled(false);
				const message = 'Please type another name.';
				showFailedAlert(message);
			},
		});

		setFormDataList([...formDataList, result]);
		setIndustryId(0);
		setName('');
		setWebsiteUrl('');
		setLogoUrl('');
	};

	
	const smAdjustIconsViewport = useMediaQuery({ maxWidth: 475 });

	return (
		<div className='bg-[#F6F6F6]'> 
			<div className='w-full h-fit 2xl:px-[161px] xl:px-[161px] lg:px-[10px] md:px-[20px] md:py-[46px] sm:px-[20px] sm:py-[46px] max-sm:px-[20px] max-sm:py-[40px] max-sm:items-center'>
				<div className='w-full flex flex-col h-full items-center'>
					<span className=' font-bold text-[#B35916] text-xl max-sm:text-lg'>
						Tell Us About Your Business
					</span>
					<div className='w-full flex flex-col'>
						<div className='md:text-left text-center mb-5 mt-8'>
							<span className='text-sm text-[#2E2424] font-normal'>
								Choose your industry
							</span>
						</div>
					
						<div className={classNames('w-full h-full grid grid-cols-9 select-none 2xl:grid-cols-9 xl:grid-cols-9 lg:grid-cols-8 md:grid-cols-6 sm:grid-cols-4',
							smAdjustIconsViewport
								? 'mx-3 max-sm:grid-cols-2 max-sm:gap-4 max-sm:justify-center max-sm:items-center'
								: 'max-sm:grid-cols-3 max-sm:gap-4 max-sm:justify-center max-sm:items-center'
						)}>
							{data?.rows.map((industry) => (
								<div
									onClick={() => {
										if (industry.industryID !== industryId) {
											setIndustryId(industry.industryID);
											setIndustryName(industry.name);
											setClick(!click);
										}
										setClick(true);
									}}
									key={industry.industryID}
									className={`flex flex-col items-center w-[130px] h-[130px] my-[15px] cursor-pointer relative  ${
										click && industry.industryID === industryId
											? 'bg-[#FFB469] rounded-[9px] bg-opacity-[0.54]'
											: ''
									}`}
								>
									
									<img src={industry.imageURL} 
										className={classNames(
											smAdjustIconsViewport
												? 'max-sm:w-[60px] max-sm:h-[60px]'
												: 'w-[70px] h-[70px] 2xl:w-[70px] 2xl:h-[70px] xl:w-[70px] xl:h-[70px] max-sm:w-[70px] max-sm:h-[70px]',
											industry.name.length > 14
												? 'pt-6'
												: 'pt-5'
										)
										}
									/>

									<span className='text-sm w-[73%] font-normal text-center mt-[10px]'>
										{industry.name}
									</span>
									{
										(click && industry.industryID === industryId) &&
										<div className='ribbon absolute bottom-24 right-2 text-sm -mt-[5rem] ml-[4.25rem]'>
											<CheckCircle className='!w-5 !h-5 text-[#48B16E]'/>
										</div>
									}
								</div>
							))}
						</div>
						<div className='w-full flex md:flex-row flex-col mt-5'>
							<div className='flex flex-col items-start'>
								<p className='font-semibold text-[#969696] mb-[11px]'>Business Name</p>
								<input
									type='text'
									value={name}
									onChange={(e) => setName(e.target.value)}
									className='flex-auto 2xl:w-[225%] xl:w-[190%] lg:w-[225%] md:w-[160%] sm:w-full max-sm:w-full h-[50px] w-full rounded-[5px] pl-[17px] border border-[#D4D4D4] focus:ring-1 focus:border-input-active outline-none'
									placeholder='Business Name'
									maxLength={20}
									pattern='[a-zA-Z\s&\d]'
								/>
							</div>
							<div className='flex flex-col items-end 2xl:items-end xl:items-end lg:items-end md:items-end sm:items-start max-sm:items-start md:ml-auto ml-0 md:mt-0 mt-4'>
								<p className='font-semibold text-[#969696] 2xl:w-[225%] xl:w-[190%] lg:w-[225%] md:w-[160%] sm:w-full max-sm:w-full mb-[11px]'>
									Business Website
								</p>
								<input
									type='text'
									value={websiteUrl}
									onChange={(e) => setWebsiteUrl(e.target.value)}
									className='flex-auto 2xl:w-[225%] xl:w-[190%] lg:w-[225%] md:w-[160%] sm:w-full max-sm:w-full h-[50px] rounded-[5px] pl-[17px] border border-[#D4D4D4] focus:ring-1 focus:border-input-active outline-none'
									placeholder='Business App URL (optional)'
								/>
							</div>
						</div>
					</div>
					<div className='mt-[60px] 2xl:mb-[15.6%] xl:mb-[2.2%] md:mb-[2.6%]'>
						<Button
							type='submit'
							disabled={!industryId || !name || !name.trim() || isLoading}
							variant='primary'
							className='py-[8px] px-[61px] rounded-[7px] font-semibold bg-[#E0781D]'
							onClick={submitCreateApp}
						>
							{isLoading ? (
								<div className='flex flex-row items-center justify-center'>
									Start Building Your App
									<Spinner className='ml-2' />
								</div>
							) : (
								<p>Start Building Your App</p>
							)}
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ChooseIndustryPage;