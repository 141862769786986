import { FHomeDescription, FHomeID, FHomeImgID, FHomePreviewImage, FHomeUploadFile, FIsHomeRefresh } from 'store/CustomizeApp/Features/homeAtom';
import { FIsFeaturesChange, FIsHomeChanges } from 'store/CustomizeApp/Features';
import React, { useEffect } from 'react';
import { getHomeApi, getHomeApiLive } from 'services/requests/Features/Home';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { AxiosError } from 'axios';
import { GetHomeResponse } from 'services/requests/Features/Home/schema';
import { PIsPublishShowHome } from 'store/CustomizeApp/Publish/publishAtom';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { log } from 'utility/log';

export const HomeUseQuery = () => {

	const [, setHomeID]                       = useAtom(FHomeID);
	const [preview, setHomeImg]               = useAtom(FHomePreviewImage);
	const [description, setHomeDesc]          = useAtom(FHomeDescription);
	const [uploadID, setHomeImgID]			  = useAtom(FHomeImgID);
	const [isHomeRefresh, setIsHomeRefresh]   = useAtom(FIsHomeRefresh);
	const uploadFile					      = useAtomValue(FHomeUploadFile);
	const setFeaturesChanges 				  = useSetAtom(FIsFeaturesChange);
	const setHomeChanges = useSetAtom(FIsHomeChanges);
	const setPublishShow = useSetAtom(PIsPublishShowHome);
	
	const {id} = useParams();

	const {data: homeLive,refetch: refetchHomeLive} = useQuery<GetHomeResponse, AxiosError>({
		queryKey: ['homeLive', id],
		queryFn: () => getHomeApiLive(id),
		enabled: false,
		retry: 0,
		onError: (error) => setIsHomeRefresh(false)
	});


	const {data, refetch, isFetching, isFetched} = useQuery<GetHomeResponse, AxiosError>({
		queryKey: ['home', id],
		queryFn: () => getHomeApi(id),
		enabled: false,
		retry: 0,
		onSuccess: (data) => {
			if(data?.home){
				setHomeDesc(data?.home?.description);
				setHomeID(data?.home?.homeID);
				setHomeImg(data?.home?.imageURL);
				setHomeImgID(data?.home?.imageUploadID);
				setIsHomeRefresh(false);
			}else{
				setHomeDesc('');
				setHomeID(0);
				setHomeImg('');
				setHomeImgID(0);
				setIsHomeRefresh(false);
			}
		},
		onError: (error) => setIsHomeRefresh(false)
	});

	useEffect(() =>{
		if(isHomeRefresh){
			refetch();
			refetchHomeLive();
		}
	},[isHomeRefresh]);

	// useEffect(() =>{
	// 	if(isFetching){
	// 		setFeaturesChanges(false);
	// 	}
	// },[isFetching]);

	useEffect(() => {
		if((data?.home || description || preview || uploadFile) && isFetched){
			if(data?.home?.description === undefined && description === '<p><br></p>'){
				// setFeaturesChanges(false);
				setHomeChanges(false);
			}else if(data?.home?.description !== description){
				// setFeaturesChanges(true);
				setHomeChanges(true);
				log('home 1');
			}else {setFeaturesChanges(false); setHomeChanges(false);}
			
			if(data?.home?.imageURL && data?.home?.imageURL !== preview){
				// setFeaturesChanges(true);
				setHomeChanges(true);
				log('home 2');
			}else if(data?.home?.imageUploadID === undefined && uploadFile !== null){
				// setFeaturesChanges(true);
				setHomeChanges(true);
				log('home 3');
			}
		} else {
			// setFeaturesChanges(false);
			setHomeChanges(false);
		}
	},[uploadID, description, data, preview, uploadFile, isFetched]);

	useEffect(() => {
		if(data?.home && homeLive?.home){
			const imgId = data?.home?.imageUploadID && data?.home?.imageUploadID + 1;
			if(data?.home?.description !== homeLive?.home?.description || data?.home?.imageUploadID && imgId !== homeLive?.home?.imageUploadID){
				setPublishShow(true);
				log('homepublishtrue');
			}else setPublishShow(false);
		}
	},[data, homeLive]);
};