import { CreateHomeResponse, CreateHomeType, EditHomeParams, EditHomeResponse, UploadHomeImageParams, UploadHomeImageResponse } from 'services/requests/Features/Home/schema';
import { FHomeDescription, FHomeID, FHomeImgID, FHomeUploadFile, FIsHomeRefresh } from 'store/CustomizeApp/Features/homeAtom';
import React, { useEffect } from 'react';
import { createHomeApi, editHomeApi, uploadHomeImageApi } from 'services/requests/Features/Home';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { AppFeatIsSaving } from 'store/CustomizeApp/Appearance';
import { AxiosError } from 'axios';
import StoreMutation from '../Store';
import { showFailedAlert } from 'utility/alerts';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { FIsHomeChanges } from 'store/CustomizeApp/Features';
import { log } from 'utility/log';

const HomeMutation = () => {
    
	const { id } = useParams();
	//Atoms for Home
	const homeID = useAtomValue(FHomeID);
	const homeDesc = useAtomValue(FHomeDescription);
	const [homeImgID, setImageID] = useAtom(FHomeImgID);
	const setIsHomeRefresh = useSetAtom(FIsHomeRefresh);
	const setIsSaving = useSetAtom(AppFeatIsSaving);
	const imageFile = useAtomValue(FHomeUploadFile);
	const isThereChanges = useAtomValue(FIsHomeChanges);
	const {saveStore} = StoreMutation();

	const {mutate: uploadHomeImg, isSuccess} = useMutation<
	UploadHomeImageResponse,
	AxiosError,
	UploadHomeImageParams
	>((data) => uploadHomeImageApi(id, data.file), {
		onSuccess: (data) => {
			setImageID(data.ImageUpload.imageUploadID);
		},
		onError: () => {
			setIsSaving(false);
			showFailedAlert('Image cannot be uploaded');
		},
	});

	//Create Home
	const { mutate: homeFormMu } = useMutation<
		CreateHomeResponse,
		AxiosError,
		CreateHomeType
	>((data) => createHomeApi(id, data), {
		onSuccess: () => {
			setIsHomeRefresh(true);
			saveStore();
		},
		onError: () => {
			setIsSaving(false);
			showFailedAlert('Cannot save home.');
		},
	});

	//Edit Home
	const { mutate: editHome } = useMutation<
		EditHomeResponse,
		AxiosError,
		EditHomeParams
	>((data) => editHomeApi(id, homeID, data), {
		onSuccess: (res) => {
			log('edit home', res);
			setIsHomeRefresh(true);
			saveStore();
		},
		onError: () => {
			setIsSaving(false);
			showFailedAlert('Cannot send successfully.');
		},
	});

	const updateHome = () => {
		if (id)
			editHome({
				imageUploadID: homeImgID ?? 0,
				description: homeDesc,
				isTurnedOn: true,
			});
	};
    
	const saveHome = () => {
		if(isThereChanges){
			if(imageFile){
				uploadHomeImg({file: imageFile});
			}else{
				if (homeID) {
					updateHome();
				} else {
					homeFormMu({
						imageUploadID: homeImgID ?? 0,
						description: homeDesc,
						isTurnedOn: true,
					});
				}
			}
		}else saveStore();
		
	};

	useEffect(() => {
		if (homeImgID && isSuccess)
			if (homeID) {
				updateHome();
			} else {
				homeFormMu({
					imageUploadID: homeImgID ?? 0,
					description: homeDesc,
					isTurnedOn: true,
				});
			}
	}, [homeImgID, isSuccess]);

	return {saveHome,};
};

export default HomeMutation;