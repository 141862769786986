import { Button } from 'components/Common/Buttons';
import React, {useEffect, useState} from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import FormInputPassword from 'components/Common/Forms/Password';
import {ChangePasswordResponseType} from 'services/requests/EditProfile/schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { AddPasswordRequest, AddPasswordResponse, addPasswordSchema } from 'services/requests/EditProfile/PasswordSettings/ResetPassword/schema';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { addPasswordApi } from 'services/requests/auth';
import { showFailedAlert, showSuccessAlert } from 'utility/alerts';
import { codeValidity } from 'store/EditProfile/resetPassword';
import { useAtom } from 'jotai';
import dayjs from 'dayjs';

const ResetYourPassword: React.FC = () => {
	const navigate = useNavigate();
	
	const userEmail = localStorage.getItem('email')?.replaceAll('"','');
	const [codeExpiry, setCodeExpiry] = useAtom(codeValidity);
	

	// useEffect(()=> {
	// 	console.log('codeExpiry: ', codeExpiry?.valueOf());

	// },[]);

	const { control, handleSubmit, formState } = useForm<AddPasswordRequest>({
		defaultValues: { email: '', newPassword: '',confirmPassword: ''},
		mode: 'onBlur',
		resolver: zodResolver(addPasswordSchema),
	});


	const { mutate: addPasswordMu, isLoading: addPasswordLoading } = useMutation<AddPasswordResponse, AxiosError, AddPasswordRequest>(
		(data) => addPasswordApi(data), {
			onSuccess: (res) => {
				console.log(res);
				
				if(res.success) {
					navigate('/dashboard');
					showSuccessAlert('Your password has been updated.');
				} 
				else {
					showFailedAlert('Failed to create password.');
				}
			},
			onError: (err) => {
				console.log(err);
				showFailedAlert(`Failed to create password. ${err.message}`);
			}
		}
	);

	const onSubmit = (data: AddPasswordRequest) => {
		console.log(data);
		data.email = userEmail ? userEmail : '' ;
		const dt = Date.now();
		if (dt >= (codeExpiry ? codeExpiry.valueOf() : 0)) {
			showFailedAlert('Session is already expired. Please try again a new verification code.');
			return;
		}

		addPasswordMu(data);
	};

	return( 
		<form className='w-full'>
			<div className='flex justify-center item-center w-full h-full my-[150px] max-md:flex-shrink'>
				<div className='p-[40px] h-fit w-[532px] mx-8 bg-[#FFFFFF] rounded-[10px] shadow-xl'>
					<Link to={'/reset-password-verify'} className='no-underline'>
						<button className='text-[#1C180F] text-[1rem] '>&lt; Back</button>
					</Link> 
					<div className='text-center mt-4 mx-4'>
						<p className='text-[#464255] text-[28px] font-bold font-[montserrat] h-fit mb-[27px] leading-[34px]'>Reset Your Password</p>
						<p className='text-[#707070] font-poppins flex mx-7'>Please set a new password for your account.</p>
					</div> 
					<div className='font-light my-8'>
						<Controller
							control={control}
							name='newPassword'
							render={({ field: { name, onChange }, fieldState: {error} }) => (
								<FormInputPassword
									onChange={(value) => {
										onChange(value);
										
									}}
									name={name}
									error={error?.message}
									placeholder='********'
									type='password'
									label='New Password'
									className='font-normal placeholder:text-[11px]'
								/>
							)}
						/>
					</div>
					<div className='font-light mb-6'>
						<Controller
							control={control}
							name='confirmPassword'
							render={({ field: { name, onChange }, fieldState: {error} }) => (
								<FormInputPassword
									onChange={(value) =>{
										onChange(value);
									}}
									name={name}
									error={error?.message}
									placeholder='********'
									type='password'
									label='Confirm New Password'
									className='font-normal placeholder:text-[11px]'
								/>
							)}
						/>
					</div>
					<div className='mt-5 pb-5'>
						<Button
							type='submit'
							variant='primary'
							onClick={handleSubmit(onSubmit)}
							className='w-full py-1.5'
							disabled={!formState.dirtyFields.newPassword || 
								!formState.dirtyFields.confirmPassword || 
								!!formState.errors.newPassword || 
								!!formState.errors.confirmPassword || 
								addPasswordLoading
							}
						>
							<span className='text-sm'>{ addPasswordLoading ? 'Please wait. . .' : 'Reset Password'}</span>
						</Button>
					</div>
					<p className='text-[#707070] font-poppins flex text-center mx-16 text-[14px]'>Session will expire on: {dayjs(codeExpiry).format('MM/DD/YYYY hh:mm:ss A')}</p>
				</div>
			</div>
		</form>
	);
};

export default ResetYourPassword;
