import 'react-datepicker/dist/react-datepicker.css';

import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import {
	globalFormSubmitted,
	globalVariableAtom,
} from '../../../../store/authAtom';

import { AiOutlineCalendar } from 'react-icons/ai';
import DatePicker from 'react-datepicker';
import React from 'react';
import { classNames } from '../../../../utility';
import { format } from 'date-fns';
import { useAtom } from 'jotai';
import { PatternFormat } from 'react-number-format';

interface IProps {
	type: string;
	name?: string;
	disabled?: boolean;
	value?: any;
	onChange?: (value: any) => void;
	placeholder?: string;
	className?: string;
	error?: string;
	label?: string;
	label2?: string;
	errorEdit?: string;
	maxLength?: number;
}

const FormInput = (props: IProps) => {
	const [value, setValue] = React.useState(props.value || '');
	const [globalVariable, setGlobalVariable] = useAtom(globalVariableAtom);
	const [formSubmitted, setFormSubmitted] = useAtom(globalFormSubmitted);
	const [showPassword, setShowPassword] = React.useState(false);

	const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (props.maxLength !== undefined && props.type === 'number') {
			if (event.key === '+' || event.key === 'e' || event.key === 'E' || event.key === '.') {
				event.preventDefault();
			}
		}
	};
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

		if (props.maxLength !== undefined) {
			const currentValue = event.target.value;
			if (currentValue.length > props.maxLength) {
				setValue(value);
			}
			else {
				setValue(currentValue);
			}
		}
		else {
			setValue(event.target.value);
		}
		props.onChange && props.onChange(event.target.value);
		if (value.length < 1) {
			setGlobalVariable('initial value');
			setFormSubmitted(false);
		}
	};

	React.useEffect(() => {
		setValue(props.value || '');
	}, [props.value]);

	const handleTogglePassword = () => {
		setShowPassword(!showPassword);
	};

	const handleDateChange = (date: Date) => {
		const formattedDate = format(date, 'yyyy-MM-dd'); // Format the date as a string in 'yyyy-MM-dd' format
		setValue(formattedDate); // Update the state with the formatted date		
		props.onChange && props.onChange(formattedDate); // Call the onChange prop with the formatted date
	};

	
	const calculateMinDate = () => { // to calculate the minimum allowed date
		if (props.label2 === 'Date of Birth') {
			const minDate = new Date();
			minDate.setFullYear(minDate.getFullYear() - 100);
			return minDate;
		}
		return null;
	};

	
	const calculateMaxDate = () => { // to calculate the maximum allowed date
		if (props.label2 === 'Date of Birth') {
			const maxDate = new Date();
			maxDate.setFullYear(maxDate.getFullYear() - 18);
			return maxDate;
		}
		return null;
	};

	return (
		<>
			<label className='text-[#363636] text-xs font-semibold'>
				{props.label2}
			</label>
			<label className='text-[#707070] text-xs'>{props.label}</label>
			<div className='relative shadow-none'>
				{props.type === 'date' ? (
					<div className='border rounded-md focus-within:border-input-active'>
						<DatePicker
							selected={value ? new Date(value) : null}
							onChange={handleDateChange}
							dateFormat='MM/dd/yyyy'
							minDate={calculateMinDate()}
							maxDate={calculateMaxDate()}
							showMonthDropdown
							showYearDropdown
							dropdownMode="select"
							wrapperClassName='w-full'
							className={classNames(
								props.className ? props.className : '',
								props.error || props.errorEdit ? 'border-[#DC3545]' : 'border-gray-300 bg-white',
								'text-black w-full text-sm rounded-md border-none py-1.5 shadow-none',
								'pl-10 focus:ring-2 focus:border-input-active outline-none'
							)}
						/>
						<span className='absolute left-3 top-1/2 transform -translate-y-1/2'>
							<AiOutlineCalendar />
						</span>
					</div>
				) : props.type === 'phoneNumber' ? (
					<PatternFormat
						format='+# (###) ###-####'
						disabled={props.disabled}
						name={props.name}
						value={value}
						maxLength={props.maxLength}
						placeholder={props.placeholder}
						onChange={handleChange}
						onKeyDown={onKeyPress}
						className={classNames(
							props.className ? props.className : '',
							props.error || props.errorEdit ? 'border-[#DC3545]' : 'border-gray-300 bg-white',
							'text-black w-full text-sm rounded-md border py-1.5 shadow-none',
							'pl-2 focus:ring-1 focus:border-input-active outline-none'
						)}
					/>
				) : (
					<input
						disabled={props.disabled}
						type={showPassword ? 'text' : props.type}
						name={props.name}
						value={value}
						maxLength={props.maxLength}
						placeholder={props.placeholder}
						onChange={handleChange}
						onKeyDown={onKeyPress}
						className={classNames(
							props.className ? props.className : '',
							props.error || props.errorEdit ? 'border-[#DC3545]' : 'border-gray-300 bg-white',
							'text-black w-full text-sm rounded-md border py-1.5 shadow-none',
							'pl-2 focus:ring-1 focus:border-input-active outline-none'
						)}
					></input>
				)}
				{props.type === 'password' && (
					<button
						type='button'
						onClick={handleTogglePassword}
						className='absolute inset-y-0 right-0 px-2 py-1.5'
					>
						<>
							{showPassword ? (
								<EyeIcon text-black className='w-8 h-6' />
							) : (
								<EyeSlashIcon text-black className='w-8 h-6' />
							)}
						</>
					</button>
				)}

			</div>
			{props.error && (
				<p className='text-[#DC3545] text-xs pl-1 py-1'>{props.error}</p>
			)}
			{props.errorEdit && (
				<p className='absolute text-[#DC3545] text-xs pl-1 py-1'>{props.errorEdit}</p>
			)}
		</>
	);
};

export default FormInput;