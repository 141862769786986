import z from 'zod';

const textFirstNameRegex = z
	.string()
	.min(1, { message: 'Input field required' })
	.regex(/^[a-zA-Z\s]*$/, { message: 'Input must be letters only' })
	.regex(/^(?!\s*$)(\s*\S+\s*)+$/, { message: 'First name is required.' })
	.transform((val) => val.trim().replace(/\s+/g, ' '));

const textLastNameRegex = z
	.string()
	.min(1, { message: 'Input field required' })
	.regex(/^[a-zA-Z\s]*$/, { message: 'Input must be letters only' })
	.regex(/^(?!\s*$)(\s*\S+\s*)+$/, { message: 'Last name is required.' })
	.transform((val) => val.trim().replace(/\s+/g, ' '));

const phoneRegex = z
	.string()
	.regex(/^(?!\s*$)(\s*\S+\s*)+$/, { message: 'Phone number is required.' })
	.transform((val) => val.trim().replace(/\s+/g, ' '))
	.optional();

const futureDateErrorMessage = 'Birth date cannot be a future date.';

const birthDateRegex = z.string().optional();

const birthDateRefined = birthDateRegex.refine((val) => {
	if (!val) return true; // allow empty values
	const birthDate = new Date(val);
	const currentDate = new Date();
	if (birthDate > currentDate) {
		throw new z.ZodError([
			{
				code: 'custom',
				path: ['birthDate'],
				message: futureDateErrorMessage,
			},
		]);
	}
	return true;
});

export const UploadEditProfileSchema = z.object({
	firstName: textFirstNameRegex,
	lastName: textLastNameRegex,
	phoneNumber: phoneRegex,
	gender : z.string().optional(),
	birthDate : birthDateRefined,
	imageURL: z.string().optional()
});

export const UploadEditProfileResponse = z.object({
	user: z.object({
		firstName : textFirstNameRegex,
		lastName : textLastNameRegex,
		phoneNumber: phoneRegex,
		gender : z.string().optional(),
		birthDate : birthDateRefined,
		imageURL: z.string().optional()
	})
});

export const UserTypeSchema = z.object({
	userID: z.number(),
	firstName : z.string(),
	lastName : z.string(),
	email : z.string().email(),
	birthDate : z.string(),
	gender: z.string(),
	phoneNumber: z.string(),
});

export const tokenSchema = z.object({
	token: z.any()
});

export const UserTypeTokenSchema = z.object({
	userID: z.number(),
	companyID: z.number(),
	companyTenantID: z.string(),
	email: z.string(),
});


export type UploadUserByAppIDResponse = z.infer<typeof UploadEditProfileSchema>;
export type UploadUserByAppIDParamasSchema = z.infer<typeof UploadEditProfileSchema>;
export type UserType = z.infer<typeof UserTypeSchema>;
export type UserTokenResponse = z.infer<typeof UserTypeTokenSchema>;
export type UploadUserByAppIDResponse2 = z.infer<typeof UploadEditProfileResponse>;

export type UserTokenSchema = z.infer<typeof tokenSchema>;



