import { axiosInstance } from 'services/axios';
import { GetPricing, GetAllPricing } from './schema';


export const getPricingById = async (id: number) => {
	const response = await axiosInstance.get(`/billing/plans/${id}`);
	return response.data as GetPricing;
};

export const getAllPricing = async () => {
	const response = await axiosInstance.get('/billing/plans');
	return response.data as GetAllPricing;
};