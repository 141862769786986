import { Dialog, Transition } from '@headlessui/react';
import { IMAGES, classNames } from 'utility';
import React, { Fragment, useEffect, useState } from 'react';

interface ISideBarProps {
	children: React.ReactNode;
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SideBarAnimation: React.FC<ISideBarProps> = ({ open, setOpen, children }) => {

	const [isScrolledDown, setIsScrolledDown] = useState(false);

	const handleScroll = () => {
		const scrollPosition = window.scrollY;
		setIsScrolledDown(scrollPosition > 70);
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as='div' className='relative overflow-hidden overflow-y-auto' onClose={setOpen}>
				<Transition.Child
					as={Fragment}
					enter='ease-in-out duration-500'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in-out duration-500'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'
				>
					<div className='' />
				</Transition.Child>

				<div className='fixed inset-0 overflow-hidden'>
					<div className='inset-0 overflow-hidden'>
						<div className='pointer-events-none fixed inset-y-0 right-0 flex w-[600px] 2xl:w-[600px] xl:w-[600px] lg:w-[600px] md:w-full sm:w-full max-sm:w-full'> {/* max-w-full */}
							<Transition.Child
								as={Fragment}
								enter='transform transition ease-in-out duration-500'
								enterFrom='translate-x-full'
								enterTo='translate-x-0'
								leave='transform transition ease-in-out duration-500'
								leaveFrom='translate-x-0'
								leaveTo='translate-x-full'
							>
								<Dialog.Panel className={classNames('pointer-events-auto border relative w-[600px] 2xl:w-[600px] xl:w-[600px] lg:w-[600px] md:w-full sm:w-full max-sm:w-full z-0 bg-white', isScrolledDown ? 'pt-[6.5rem]' : 'pt-[6.5rem]')}>
									<Transition.Child
										as={Fragment}
										enter='ease-in-out duration-500'
										enterFrom='opacity-0'
										enterTo='opacity-100'
										leave='ease-in-out duration-500'
										leaveFrom='opacity-100'
										leaveTo='opacity-0'
									>
										<div className='absolute xl:hidden 2xl:hidden lg:hidden z-20 top-2 right-2 pr-4 flex pt-4'>
											<button
												type='button'
												className='rounded-md text-black font-bold hover:text-red focus:outline-none focus:ring-2 focus:ring-white'
												onClick={() => setOpen(false)}
											>
												<span className='sr-only'>Close panel</span>
												<img src={IMAGES.userTopBarXIcon}></img>
											</button>
										</div>
									</Transition.Child>
									<div className='flex h-full flex-col w-[600px] bg-white pt-0 pl-1 shadow-xl 2xl:w-[600px] xl:w-[600px] lg:w-[600px] md:w-full sm:w-full max-sm:w-full overflow-y-scroll'>
										<div className='px-4 sm:px-6'>
										</div>
										<div>
											{children}
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default SideBarAnimation;