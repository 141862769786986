import {axiosInstance} from 'services/axios';
import { ResetPasswordSendEmailRequest, ResetPasswordValidateVerificationCodeRequest, resetPasswordSendEmailSchema, resetPasswordValidateVerificationCodeSchema } from './schema';
import { SEND_VERIFICATION_CODE, VALIDATE_VERIFICATION_CODE } from 'services/api';

export const resetPasswordSendEmailApi = async (data: ResetPasswordSendEmailRequest) => {
	const params = resetPasswordSendEmailSchema.parse(data);
	const response = await axiosInstance.post(SEND_VERIFICATION_CODE, params);
	return response.data;
};

export const resetPasswordValidateCodeApi = async (data: ResetPasswordValidateVerificationCodeRequest) => {
	const params = resetPasswordValidateVerificationCodeSchema.parse(data);
	const response = await axiosInstance.post(VALIDATE_VERIFICATION_CODE, params);
	return response.data;
};

