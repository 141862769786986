import { GetContactFormType, GetContactSchema, GetContactFormResponse } from './schema';

import { axiosInstance } from 'services/axios';


export const getContactByAppIDLive = async (appID?: string) => {
	const response = await axiosInstance.get(`/organizations/apps/${appID}/contact?env=LIV`);
    
	return response.data as GetContactFormResponse;
};

export const getContactByAppID = async (appID?: string) => {
	const response = await axiosInstance.get(`/organizations/apps/${appID}/contact`);
    
	return response.data as GetContactFormResponse;
};

export const createContact = async (appID?: string, data?: GetContactFormType) => {
	const validatedPrivacyPolicy = GetContactSchema.parse(data);
	const response = await axiosInstance.post(`/organizations/apps/${appID}/contact`, validatedPrivacyPolicy);
	return response.data as GetContactFormResponse;
};

export const editContactByAppID = async (appID?: string, contactID?: number, param?: GetContactFormType) => {
	const response = await axiosInstance.put(`/organizations/apps/${appID}/contact/${contactID}`, param);
    
	return response.data as GetContactFormResponse;
};