import React, { FC, useEffect, useRef, useState} from 'react';
// import Icons from '../../../theme/icons';
import { XCircleIcon } from '@heroicons/react/24/solid';
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import { getExtension } from 'utility';

export const BYTE_IN_MB = 1048576;
export const BYTE_IN_KB = 1024;

type TFileTypes = {
  IMAGE: 'image/*';
  VIDEO: 'video/*';
  APPLICATION: 'application/*';
};

export interface IFile {
  id: string;
  name: string;
  url: string;
  type: string;
  size: string;
  file: File;
}

interface IProps {
  multiple?: boolean;
  isDisabled?: boolean;
  accept?: TFileTypes | string;
  hasError?: (error: string) => void
  maxSize?: number;
  onFilesChange: (files: IFile[]) => void;
  onRemoveFile?: (file: IFile) => void;
  existingFiles?: IFile[];
}

const Uploader : React.FC<IProps> = (props) => {
	const { multiple = false, isDisabled = false, accept = '*', maxSize = 10, onFilesChange, onRemoveFile,hasError, existingFiles } = props;
	const fileInput = useRef<HTMLInputElement>(null);
	const [files, setFiles] = useState<IFile[]>([]);
	const [error, setError] = useState('');
  
	useEffect(() => {
		if(files.length > 0){
			onFilesChange(files);
			setFiles([]);
		}
	}, [files]);

	useEffect(() => {
		if(existingFiles?.length) {
			setFiles(existingFiles);
		}
	},[existingFiles]);
  
	const onClickHandler = () => {
		fileInput.current?.click();
	};

	const onChange = (e: any) => {
		addMedia(e.target.files, () => {
			e.target.value = null;
		});
	};

	function trimFileName(fileName: string) {
		if (fileName.length <= 50) {
			return fileName;
		}
	
		const fileParts = fileName.split('.');
		const extension = fileParts.pop(); 
		const truncatedFileName = fileName.slice(0, 50 - (extension ? extension.length : 0) - 1);
	
		return `${truncatedFileName}.${extension}`;
	}

	const addMedia = (fileList: FileList, callback = () => { return; }) => {
		let index = 0;

		const tmpMedias: IFile[] = [];
    
		const validTypes = accept.toString().split(',');
    
		setError('');
    
		for(const file of fileList) {
			if(!validTypes.includes(getExtension(file.name).toLowerCase())) {
				const message = validTypes.length > 1 
					? '*File must be one of'
					: '*File must be ';

				setError(`${message} ${validTypes.join(',')}`);
				setFiles([]);
				break;
			}

			// if(!validTypes.includes(file.type)) continue;
      
			if(file.size > (BYTE_IN_MB * maxSize)) {
				setError(`*File must not be greater than ${maxSize}mb`);
				hasError &&	hasError('Your file must be 5MB or less to be uploaded. Please compress or reduce the file size.');

				setFiles([]);

				break;
			}

			const trimmedFileName = trimFileName(file.name);
			const trimmedFile = new File([file], trimmedFileName, { type: file.type });
      
			tmpMedias.push({
				id: (index + trimmedFile.size).toString(),
				name: trimmedFile.name,
				url: URL.createObjectURL(trimmedFile),
				type: trimmedFile.type,
				size: trimmedFile.size > BYTE_IN_KB
					? trimmedFile.size > BYTE_IN_MB
						? Math.round(trimmedFile.size / BYTE_IN_MB) + 'mb'
						: Math.round(trimmedFile.size / BYTE_IN_KB) + 'kb'
					: trimmedFile.size + 'b',
				file: trimmedFile,
			});
      
			index++;
      
			if(!multiple) break;
		}  
    
		if (multiple)
		{
			setFiles([...files, ...tmpMedias]);
		}
		else
		{
			setFiles(tmpMedias);
		}    
    
		callback && callback();
	};

	const removeMedia = (file: IFile) => {
		const temp = files.filter((m: IFile) => m.id !== file.id);
		setFiles(temp);

		if (onRemoveFile) onRemoveFile(file);
	};

	const onDragOverHandler = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
    
		if(isDisabled) return;
	};

	const onDropHandler = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
    
		if(isDisabled) return;
    
		const file: FileList = e.dataTransfer.files;
    
		addMedia(file);
	};

	return (
		<div className="tw-w-full ">
			<div
				className="tw-mt-1 tw-h-12 tw-w-auto tw-flex tw-justify-between tw-items-center tw-border tw-border-custom-gray-100 tw-rounded-md tw-tracking-wide"
				onDrop={onDropHandler}
				onDragOver={onDragOverHandler}
				onClick={onClickHandler}
			>
				<button >
					<div className='flex flex-col w-[6rem] h-[6rem] md:w-[8.5rem] md:h-[8.5rem] rounded-md border bg-[#F5F5F5] cursor-pointer flex items-center justify-center'>
						<ArrowUpTrayIcon className='w-8 h-8 text-[#B35916] cursor-pointer'/> 
						<div className='flex-row absolute justify-center'>			
							<div className=' flex justify-center'>
								<input
									ref={fileInput} 
									onChange={onChange} 
									multiple={multiple}
									type="file" 
									className="invisible h-0"
									accept={accept as string}
									disabled={isDisabled}
								/>
							</div>
						</div>
						<div className='text-[#B35916] text-xs block text-center mt-2'>Upload Image</div>		
					</div>
				</button>
			</div>

      

			{/* {error && (
        <section className="mt-2">
          <p className="text-xs text-danger break-words">{error}</p>
        </section>
      )} */}
		</div>
	);
};

export default Uploader;