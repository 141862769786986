import { CreateHelpResponse, CreateHelpType, EditHelpParams, EditHelpResponse } from 'services/requests/Features/help/schema';
import { FHelp, FHelpID, FIsHelpRefresh } from 'store/CustomizeApp/Features/helpAtom';
import { createHelpApi, editHelpApi } from 'services/requests/Features/help';
import { useAtomValue, useSetAtom } from 'jotai';

import { AppFeatIsSaving } from 'store/CustomizeApp/Appearance';
import { AxiosError } from 'axios';
import NotificationMutation from '../Notification';
import React from 'react';
import { showFailedAlert } from 'utility/alerts';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import OfferMutation from '../Offers';
import { FIsHelpChanges } from 'store/CustomizeApp/Features';

const HelpMutation = () => {
	const { id } = useParams();
	//Atoms for Help
	const helpDesc = useAtomValue(FHelp);
	const helpID = useAtomValue(FHelpID);
	const setIsHelpRefresh = useSetAtom(FIsHelpRefresh);
	const setIsSaving = useSetAtom(AppFeatIsSaving);
	const isThereChanges = useAtomValue(FIsHelpChanges);
	const {saveOffer} = OfferMutation();
	//Create Help
	const { mutate: helpFormMu } = useMutation<
		CreateHelpResponse,
		AxiosError,
		CreateHelpType
	>((data) => createHelpApi(id, data), {
		onSuccess: () => {
			setIsHelpRefresh(true);
			saveOffer();
		},
		onError: () => {
			setIsSaving(false);
			showFailedAlert('Cannot save help.');
		},
	});

	//Edit Help
	const { mutate: editHelp } = useMutation<
		EditHelpResponse,
		AxiosError,
		EditHelpParams
	>((data) => editHelpApi(id, helpID, data), {
		onSuccess: () => {
			setIsHelpRefresh(true);
			saveOffer();
		},
		onError: () => {
			setIsSaving(false);
			showFailedAlert('Cannot edit help.');
		},
	});
	const updateHelp = () => {
		if (id)
			editHelp({
				description: helpDesc,
				isTurnedOn: true,
			});
	};
    
	const saveHelp = () => {
		if(isThereChanges){
			if (helpID) {
				updateHelp();
			} else {
				helpFormMu({ description: helpDesc, isTurnedOn: true });
			}
		}else saveOffer();
		
	};

	return {saveHelp,};
};

export default HelpMutation;