import { atom } from 'jotai';
import { GetSubscriptionByIdType } from 'services/requests/Payment/schema';


export const FSubscription 				= atom<GetSubscriptionByIdType>({
	SubscriptionId: '',
	PriceId: ''
});

export const FIsAlreadySubscribed 		= atom<boolean>(false);
export const FCardBrand					= atom<string>('');
export const FInlineError 				= atom<any>('');

///////  Stripe Card Error State ///////////

export const FCardNumberError	  		= atom<string>('');
export const FExpiryError 		 		= atom<string>('');
export const FCVCError				    = atom<string>('');

///////

export const planPriceID 			= atom<string>('');
export const changePlanID 	 	    = atom<boolean>(false);
export const backToChangePlanPage 	= atom<boolean>(false);

export const paymentMethodID       	= atom<string>('');
export const paymentMethodError     = atom<any>(undefined);

export const promoID                = atom<string>('');
export const couponAmountCode      	= atom<number>(0);
export const couponPercentCode      = atom<number>(0);

export const loadPayPage            = atom<boolean>(false);

export const promoCodeValue 		= atom<string>('');

export const isDefaultSelected 		= atom<boolean>(false);