import { SIsAppearanceChanges, SIsSideBarChanges, SSideBarID, SSideBarIDLive, SisSideBarDone } from 'store/CustomizeApp/Appearance';
import { SIsSidebarRefresh, SSideBar, STempSideBar } from 'store/CustomizeApp/Appearance/sideBarAtom';
import { getSidebarByID, getSidebarByIDLive } from 'services/requests/appearance/side_bar';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { AxiosError } from 'axios';
import { PIsPublishShowSidebar } from 'store/CustomizeApp/Publish/publishAtom';
import { TSidebar } from 'services/requests/appearance/side_bar/schema';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { log } from 'utility/log';

export const SideBarUseQuery = () => {
	const {id} = useParams();
	//Atoms for Sidebar
	const selectedSidebarID      				  = useAtomValue(SSideBarID);
	const [sideBarOrder, setSidebarOrder] 	      = useAtom(SSideBar);
	const [isSidebarRefresh, setIsSidebarRefresh] = useAtom(SIsSidebarRefresh);
	const [tempSideBar, setTempSideBar]           = useAtom(STempSideBar);
	const setAppearanceChanges                    = useSetAtom(SIsAppearanceChanges);
	const setSideBarChanges = useSetAtom(SIsSideBarChanges);
	const sidebarIdLive = useAtomValue(SSideBarIDLive);
	const setPublishShow = useSetAtom(PIsPublishShowSidebar);
	const setSidebarDone = useSetAtom(SisSideBarDone);
    
	//Sidebar
	const {data: sideBarLive, refetch: refetchSidebarLive} = useQuery<TSidebar, AxiosError>({
		queryKey: ['sidebarLive', sidebarIdLive],
		queryFn: () => getSidebarByIDLive(id, sidebarIdLive),
		enabled: false,
	});
	
	//Sidebar
	const {data, refetch: refetchSidebar, isFetched} = useQuery<TSidebar, AxiosError>({
		queryKey: ['sidebar', selectedSidebarID],
		queryFn: () => getSidebarByID(id, selectedSidebarID),
		enabled: false,
		onSuccess: (data) => {
			if(data){
				const fetchedSidebarList = (data?.sidebar?.items)?.map((item) => {
					return { 
						id: item?.sidebarOrderID, 
						label: item?.name, 
						isHidden: !item?.visible,
						isSub: item?.isSub
					};
				});
				setTempSideBar(fetchedSidebarList);
				setSidebarOrder(fetchedSidebarList);
				setIsSidebarRefresh(false);
				setSidebarDone(true);
			}
		}
	});

	useEffect(() => {
		if (selectedSidebarID && isSidebarRefresh && sidebarIdLive) {
			refetchSidebar();
			refetchSidebarLive();
		}
	}, [selectedSidebarID, isSidebarRefresh, sidebarIdLive]);

	useEffect(() => {
		if(data?.sidebar && isFetched){
			if(sideBarOrder[0]?.label !== tempSideBar[0]?.label || sideBarOrder[1]?.label !== tempSideBar[1]?.label || 
                sideBarOrder[2]?.label !== tempSideBar[2]?.label || sideBarOrder[3]?.label !== tempSideBar[3]?.label || 
                sideBarOrder[0]?.isSub !== tempSideBar[0]?.isSub || sideBarOrder[1]?.isSub !== tempSideBar[1]?.isSub || 
                sideBarOrder[2]?.isSub !== tempSideBar[2]?.isSub || sideBarOrder[3]?.isSub !== tempSideBar[3]?.isSub || 
                sideBarOrder[0]?.isHidden !== tempSideBar[0]?.isHidden || sideBarOrder[1]?.isHidden !== tempSideBar[1]?.isHidden || 
                sideBarOrder[2]?.isHidden !== tempSideBar[2]?.isHidden || sideBarOrder[3]?.isHidden !== tempSideBar[3]?.isHidden){
				setAppearanceChanges(true);
				setSideBarChanges(true);
				log('side 1');
			}else {setAppearanceChanges(false); setSideBarChanges(false);}
		}
	},[sideBarOrder, tempSideBar, isFetched]);

	useEffect(() => {
		// sideBarLive doesn't have items on old apps (SNAP-1540)
		if (sideBarLive?.sidebar?.items) {
			setPublishShow(true);
		}
		if(data && sideBarLive && sideBarLive?.sidebar?.items){
			if(data?.sidebar?.items[0]?.name !== sideBarLive?.sidebar?.items[0]?.name || data?.sidebar?.items[1]?.name !== sideBarLive?.sidebar?.items[1]?.name || 
				data?.sidebar?.items[2]?.name !== sideBarLive?.sidebar?.items[2]?.name || data?.sidebar?.items[3]?.name !== sideBarLive?.sidebar?.items[3]?.name || 
				data?.sidebar?.items[0]?.isSub !== sideBarLive?.sidebar?.items[0]?.isSub || data?.sidebar?.items[1]?.isSub !== sideBarLive?.sidebar?.items[1]?.isSub || 
				data?.sidebar?.items[2]?.isSub !== sideBarLive?.sidebar?.items[2]?.isSub || data?.sidebar?.items[3]?.isSub !== sideBarLive?.sidebar?.items[3]?.isSub || 
				data?.sidebar?.items[0]?.visible !== sideBarLive?.sidebar?.items[0]?.visible || data?.sidebar?.items[1]?.visible !== sideBarLive?.sidebar?.items[1]?.visible || 
				data?.sidebar?.items[2]?.visible !== sideBarLive?.sidebar?.items[2]?.visible || data?.sidebar?.items[3]?.visible !== sideBarLive?.sidebar?.items[3]?.visible ){
				setPublishShow(true);
			}else setPublishShow(false);
		}
	},[data, sideBarLive]);
};