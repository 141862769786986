import { FAbout, FAboutID, FIsAboutRefresh } from 'store/CustomizeApp/Features/aboutAtom';
import { FIsAboutChanges, FIsFeaturesChange } from 'store/CustomizeApp/Features';
import React, { useEffect } from 'react';
import { getAboutApi, getAboutApiLive } from 'services/requests/Features/about';
import { useAtom, useSetAtom } from 'jotai';

import { AxiosError } from 'axios';
import { PIsPublishShowAbout } from 'store/CustomizeApp/Publish/publishAtom';
import { getAboutResponse } from 'services/requests/Features/about/schema';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { log } from 'utility/log';

export const AboutUseQuery = () => {

	//Atoms for About 
	const [about, setAboutDesc]                     = useAtom(FAbout);
	const [aboutID, setAboutID]                       = useAtom(FAboutID);
	const [isAboutRefresh, setIsAboutRefresh]  = useAtom(FIsAboutRefresh);
	const setFeatureChange = useSetAtom(FIsFeaturesChange);
	const setAboutChanges = useSetAtom(FIsAboutChanges);
	const setPublishShow = useSetAtom(PIsPublishShowAbout);
	
	const { id } = useParams();

	const {data: aboutLive, refetch: refetchLive} = useQuery<getAboutResponse, AxiosError>({
		queryKey: ['aboutLive', id],
		queryFn: () => getAboutApiLive(id),
		enabled: false,
		retry: 0,
		onError: () => setIsAboutRefresh(false)
	});

	const {data, refetch, isFetching, isFetched} = useQuery<getAboutResponse, AxiosError>({
		queryKey: ['about', id],
		queryFn: () => getAboutApi(id),
		enabled: false,
		retry: 0,
		onSuccess: (data) => {
			if(data?.about){
				setAboutDesc(data?.about?.description);
				setAboutID(data?.about?.aboutID);
				setIsAboutRefresh(false);
			}else{
				setAboutDesc('');
				setAboutID(0);
				setIsAboutRefresh(false);
			}
		},
		onError: () => setIsAboutRefresh(false)
	});

	useEffect(() =>{
		if(isAboutRefresh){
			refetch();
			refetchLive();
		}
	},[isAboutRefresh]);

	// useEffect(() =>{
	// 	if(isFetching){
	// 		setFeatureChange(false);
	// 	}
	// },[isFetching]);

	useEffect(() => {
		if((data?.about?.description || about) && isFetched){
			if(data?.about?.description === undefined && about === '<p><br></p>'){
				// setFeatureChange(false);
				setAboutChanges(false);
			}
			else if(data?.about?.description !== about){
				// setFeatureChange(true);
				setAboutChanges(true);
				log('AboutChangesTrue');
			} else {
				// setFeatureChange(false);
				setAboutChanges(false);
			}
		} else {
			// setFeatureChange(false);
			setAboutChanges(false);
		}
	},[about, data, isFetched]);

	useEffect(() => {
		if(data?.about && aboutLive?.about){
			if(data?.about?.description !== aboutLive?.about?.description){
				setPublishShow(true);
				log('AboutPublishTrue');
			}else setPublishShow(false);
		}
	},[data, aboutLive]);
};