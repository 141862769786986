import { FIsPrivacyRefresh, FPrivacy, FPrivacyID } from 'store/CustomizeApp/Features/privacy';
import { GetPrivacyPolicyFormType, GetPrivacyPolicyrFormResponse } from 'services/requests/Features/PrivacyPolicy/schema';
import { createPrivacyPolicy, editPrivacyPolicyByAppID } from 'services/requests/Features/PrivacyPolicy';
import { useAtomValue, useSetAtom } from 'jotai';

import { AppFeatIsSaving } from 'store/CustomizeApp/Appearance';
import { AxiosError } from 'axios';
import React from 'react';
import TermsOfUseMutation from '../TermsOfUse';
import { showFailedAlert } from 'utility/alerts';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { FIsPrivacyChanges } from 'store/CustomizeApp/Features';

const PrivacyMutation = () => {
	const { id } = useParams();
	//Atom for Privacy
	const privacyDesc = useAtomValue(FPrivacy);
	const privacyID = useAtomValue(FPrivacyID);
	const setIsPrivacyRefresh = useSetAtom(FIsPrivacyRefresh);
	const setIsSaving = useSetAtom(AppFeatIsSaving);
	const isThereChanges = useAtomValue(FIsPrivacyChanges);
	const {saveTerms} = TermsOfUseMutation();
	
	//Create Privacy Policy
	const { mutate: createPrivacy } = useMutation<
		GetPrivacyPolicyrFormResponse,
		AxiosError,
		GetPrivacyPolicyFormType
	>((data) => createPrivacyPolicy(id, data), {
		onSuccess: () => {
			setIsPrivacyRefresh(true);
			saveTerms();
		},
		onError: () => {
			setIsSaving(false);
			showFailedAlert('Cannot save privacy');
		},
	});

	//Put Privacy Policy
	const { mutate: editPrivacyPolicy } = useMutation<
		GetPrivacyPolicyrFormResponse,
		AxiosError,
		GetPrivacyPolicyFormType
	>((data) => editPrivacyPolicyByAppID(id, privacyID, data), {
		onSuccess: () => {
			setIsPrivacyRefresh(true);
			saveTerms();
		},
		onError: () => {
			setIsSaving(false);
			showFailedAlert('Cannot edit privacy.');
		},
	});

	const updatePrivacy = () => {
		if (id)
			editPrivacyPolicy({
				description: privacyDesc,
				isTurnedOn: true,
			});
	};
    
	const savePrivacy = () => {
		if(isThereChanges){
			if (privacyID) {
				updatePrivacy();
			} else {
				createPrivacy({ description: privacyDesc, isTurnedOn: true });
			}
		}else saveTerms();
		
	};

	return {savePrivacy,};
};

export default PrivacyMutation;