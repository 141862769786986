import React, { useEffect, useRef, useState } from 'react';
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import FormInput from 'components/Common/Forms/Inputs';
import { TrashIcon } from '@heroicons/react/24/outline';
import { useAtom, useAtomValue } from 'jotai';
import { selectedImages } from 'pages/CustomizeApp/Features/Gallery';
import { FGalleryImageAtom, IAccordionImages, IImageUrls } from 'store/CustomizeApp/Features/galleryAtom';
import Uploader, { IFile } from 'components/Common/Uploader';
import { showFailedAlert } from 'utility/alerts';
import { classNames } from 'utility';
import { DeleteModal } from 'components/Common/Modals/DeleteModal';

// type AccordionDataType = {
// 	title : string,
// 	images : IFile[] 
// }

type AccordionProps = {
	items : IAccordionImages;
	index : number;
	handleImageUpload:(file:IFile[] ,index : number)=>void;
	handleChange?: (value: string , index:number) => void;
	handleDeletePhoto: (index: number, images: IImageUrls[]) => void;
	handleSetAlbumCover: (coverIndex: number, index: number) => void;
}

const Accordion = (props: AccordionProps) => {
	const [selected,setSelected] = useAtom(selectedImages);
	const [currentSelected,setCurrentSelected] = useState<[] | IImageUrls[]>([]);
	const [isActive, setIsActive] = useState(false);
	const accordionImages = useAtomValue(FGalleryImageAtom);
	const [ deleteModalShow, setDeleteModalShow ] = useState(false);

	const handleChange = (value:string) =>
	{
		props.handleChange && props.handleChange(value , props.index);
	};

	const handleHasError = (error: string) => {
		showFailedAlert(error);
	};

	const handleSelectAll = (checked: boolean) => {
		if (checked) {
			if (accordionImages[props.index].images) {
				setSelected([...selected, ...accordionImages[props.index].images]);
				setCurrentSelected(accordionImages[props.index]?.images);
			}
		} else {
			const updatedData = selected.filter(img => !accordionImages[props.index]?.images.some(item => img === item));
			setSelected(updatedData);
			setCurrentSelected(updatedData);
		}
	};

	const handleDelete = () => {
		setCurrentSelected([]);
		props.handleDeletePhoto(props.index, accordionImages[props.index]?.images);
	};

	const handleClick = () => {
		setDeleteModalShow(!deleteModalShow);
	};

	const divRef = useRef<HTMLDivElement | null>(null);
	const [fillers, setFillers] = useState<JSX.Element[]>([]);

	function customRound(number: number) {
		const integerPart = Math.floor(number);
		const decimalPart = number - integerPart;
	
		if (decimalPart >= 0.89) {
			return Math.ceil(number); // Round up if decimalPart is 0.8 or above
		} else {
			return Math.floor(number); // Round down otherwise
		}
	}
	
	const updateFillers = () => {
		if (divRef.current) {
			const width = divRef.current.offsetWidth;

			const fillerWidth = width > 514 ? 8.5 * 16 : 6 * 16;
			const gap = 0.25 * 16; 	
			
			const columns = customRound(width / ((fillerWidth + (4 * gap))));
			const length = width > 514
				? (props?.items?.images?.length + 1) % columns && columns - (props?.items?.images?.length + 1) % columns
				: (props?.items?.images?.length) % columns && columns - (props?.items?.images?.length + 1) % columns;
			
			const newFillers = Array.from({ length: length }, (_, index) => (
				<div key={index} className='w-[6rem] h-[6rem] md:w-[8.5rem] md:h-[8.5rem] gap-4 invisible'></div>
			));
			
			setFillers(newFillers);
		}
	};

	useEffect(() => {
		updateFillers();

		window.addEventListener('resize', updateFillers);

		return () => {
			window.removeEventListener('resize', updateFillers);
		};
	}, []);

	useEffect(() => {
		setIsActive(false);
	}, [accordionImages.length]);
	
	useEffect(() => {
		updateFillers();
	}, [props?.items?.images?.length]);

	return (
		<div className="accordion-item m-4 w-full max-sm:m-0">
			<div className="accordion-title" onClick={() => setIsActive(!isActive)}>
				<div className=' relative cursor-pointer w-full h-[55px] pl-5 flex items-center border border-gray-200 rounded-md bg-white text-center text-lg font-bold  text-gray-500'>{props.items.title}				
					<div className='w-6 h-6 right-2 absolute '>{isActive ? <ChevronDownIcon/> : <ChevronRightIcon/>}</div>
				</div>
			</div>
			{isActive && 
			<div className="accordion-content -mt-[1px] border min-h-[350px] h-fit p-4">
				<div className='mt-[35px] ml-7 max-sm:ml-0 '> 
					<div className='mb-[40px] '>
						<label className='block text-[#5A7184] font-medium text-[16px] mb-[10px]' htmlFor='title'>Title<span className='text-red-600'>*</span>
						</label>
						<FormInput type='text' placeholder='Album Name' value={props.items.title} className='text-[14px] w-[50%] h-[40px]' onChange={(data)=>handleChange(data)} />	
					</div>
					<div className='flex justify-between w-[97%] text-[#B35916] max-sm:flex-col' >
						<div className='max-sm:mb-10'>
							{props?.items?.images?.length<2?'Item ':'Items '}
						({props?.items?.images?.length})
						</div>
						<div className='flex flex-row justify-between items-center gap-8 font-sm pr-5 2xl:pr-5 xl:pr-14 lg:pr-5 md:pr-10 sm:pr-5 max-sm:pr-5'>			
							<div className={((currentSelected.length == 1 && selected[0]?.index != props.items.coverIndex) ? '' : 'cursor-not-allowed')}>
								<label htmlFor={`setCover${props.index}`} className={classNames((currentSelected.length == 1 && currentSelected[0]?.index != props.items.coverIndex) ? 'cursor-pointer' : 'text-[#D1D1D1] pointer-events-none cursor-not-allowed')} onClick={() => props.handleSetAlbumCover(currentSelected[0].index, props.index)}>Set as Album Cover</label>
							</div>
							<div className='flex items-center'>
								<input
									className='accent-orange-700'
									checked={currentSelected.length === props?.items?.images?.length}
									disabled={props?.items?.images?.length<1}
									id={`selectAll${props.index}`}
									type='checkbox'
									onChange={(e)=>handleSelectAll(e.target.checked)}
								/>
								<label htmlFor={`selectAll${props.index}`} className={classNames('ml-1.5 sm:text-base lg:text-lg', props?.items?.images?.length<1 ? 'text-[#D1D1D1]' : 'text-[#B35916] cursor-pointer mt-1')}>Select All</label>
							</div>
							<div className='ml-3'>
								<button 
									id={`delete${props.index}`} 
									onClick={()=>handleClick()} 
									disabled={props?.items?.images?.length<1}
									className={classNames('max-sm:-ml-10 sm:text-base lg:text-lg', props?.items?.images?.length<1 ? 'text-[#D1D1D1]' : 'text-[#B35916]')}>
									<TrashIcon className='pt-[1px] w-5 h-5 max-sm:ml-0'/>
								</button>
								<label htmlFor={`delete${props.index}`} className={classNames('absolute justify-between ml-0.5 sm:text-base lg:text-lg', props?.items?.images?.length<1 ? 'text-[#D1D1D1]' : 'cursor-pointer')}>Delete</label>
							</div>
						</div>
					</div>
					<hr className='w-full' />
					<div className='mt-4 md:hidden'>
						<Uploader 
							onFilesChange={(data)=>props.handleImageUpload(data, props.index)}
							maxSize={4.9}
							hasError={handleHasError}
							multiple
							accept=".png,.jpeg,.jpg" 
						/>
					</div>
					<div
						ref={divRef}
						className="flex flex-row flex-wrap gap-4 mt-4 justify-start items-center">
						{props?.items?.images?.map((img, index)=>
							<div
								key={index}
								className={
									classNames(
										currentSelected.some((curr)=>curr === img) ? 'ring-4 ring-primary' : '',
										'w-[6rem] h-[6rem] md:w-[8.5rem] md:h-[8.5rem] bg-[#F5F5F5] border rounded-md overflow-hidden'
									)
								}
							>
								<div className='flex flex-col justify-left items-left gap-y-1 w-full bg-white'>
									<div className='flex flex-row justify-between mt-1 mx-1 p-0.5'>
										<input
											type='checkbox'
											className='mr-auto accent-orange-700'
											checked={currentSelected.some((curr)=>curr === img)}
											onChange={(e) => {
												const checked = [...currentSelected];
												checked.push(img);
												
												const unchecked = currentSelected.filter((img) => img.index != index);

												setCurrentSelected(e.target.checked ? checked : unchecked);
												setSelected(e.target.checked ? [...selected,img] : selected.filter((curr)=> curr !== img) );
											}}
										/>
									
										<p className={classNames(
											(props?.items.coverIndex == index) ? 'visible' : 'invisible',
											'flex justfy-center items-center w-fit bg-[#FDDDCB] text-primary text-xs px-2 rounded'
										)}>
											Cover
										</p>
									</div>
									
									<img 
										src={img?.url}
										// alt="Uploaded Image"
										className="object-cover w-auto h-[4.5rem] md:h-[7.5rem]"
									/>
								</div>
							</div>							
						)}
						<div className='invisible md:visible'>
							<Uploader 
								onFilesChange={(data)=>props.handleImageUpload(data, props.index)}
								maxSize={4.9}
								hasError={handleHasError}
								multiple
								accept=".png,.jpeg,.jpg" 
							/>
						</div>
						{/* {fillers} */}
					</div>
				</div>
			</div>}
			<DeleteModal type='Image' isDeleteModalShow={deleteModalShow} setDeleteModalShow={handleClick} onClickCancel={handleClick} onClickDelete={handleDelete} />
		</div>
	);
};

export default Accordion;