import React, { useEffect } from 'react';
import AboutBanner from '../../assets/AboutUs/aboutUs1.png';
import AboutValues from '../../assets/AboutUs/aboutUs2.png';

const AboutUs: React.FC = () => {

	useEffect(() => {
		window.scrollTo(0,0);
	},[]);
	
	return (
		<div className='bg-[#F6F6F6] flex flex-col justify-between h-full '>
			<div className='flex-row  mt-5'>
				<span className='text-[28px] lg:text-[32px] flex justify-center font-semibold text-[#333333] py-2 text-center'>About Us</span>
				<span className='text-xs lg:text-sm flex justify-center text-[#666666] py-2 text-center'>
				At SnapToApp, our intuitive interface empowers users to build apps through drag-and-drop<br/> elements quickly. With cutting-edge customization tools, integration options, and collaborative<br/> features, app development becomes a speedy, cost-effective process for businesses of all<br/> sizes.
				</span>
				<div className="flex justify-center w-full">
					<img src={AboutBanner} alt="about1" className=" w-full h-auto max-w-[665px] pt-3" />
				</div>
				<div className="flex flex-col md:flex-row sm:flex-row justify-center items-center space-y-6 md:space-y-0 md:space-x-12 py-8">
					<div className="w-[310px] text-left max-w-[400px]  ">
						<div className="font-bold text-[28px] text-[#333333]">Our History</div>
						<p className="text-xs lg:text-sm text-[#666666] py-2">
						We honed our expertise in app building with our team&apos;s expertise in serving numerous clients. Our pursuit of simplifying app development led us to create a platform that streamlines the process for all, regardless of experience. That&apos;s how we came up with an idea that would become SnapToApp.
						</p>
					</div>
					<div className="w-[310px] text-left max-w-[400px] ">
						<div className=" font-bold text-[28px] text-[#333333]">Our Vision</div>
						<p className="text-xs lg:text-sm text-[#666666] py-2">
						We&apos;re dedicated to providing everyone, from small businesses to startups, with a competitive and user-friendly app-building solution. Our vision is to empower them to create personalized apps effortlessly, compete with larger players, and unlock their full potential for success in the digital world.
						</p>
					</div>
				</div>
			</div>
			
			<div className='flex flex-col sm:flex-row justify-center bg-[#FDEFD6] items-center gap-12 pb-10 py-5'>
				<div className=''>
					<img src={AboutValues} 
						alt="about2" 
						className="w-full h-auto max-w-[360px] " />
				</div>

				<div className="w-[320px] text-left max-w-[400px]">
					<div><h1 className='text-[#171414] py-7'>Our Values</h1></div>
					<div className="grid grid-rows-3 gap-5">
						<div className="flex flex-col">
							<div className="flex items-center">
								<span className="font-bold text-[#171414] text-[18px]">01</span>
								<span className="pl-3 font-bold text-[#171414] text-[18px]">Intuitive App Building</span>
							</div>
							<div className="mt-2 text-[#171414] pl-9 text-xs lg:text-sm ">
                                        Create apps effortlessly with our user-friendly interface and drag-and-drop functionality, simplifying the app-building process for both beginners and experts.
							</div>
						</div>
						<div className="flex flex-col">
							<div className="flex items-center">
								<span className="font-bold text-[#171414] text-[18px]">02</span>
								<span className="pl-3  font-bold text-[#171414] text-[18px]">Customization for Your Brand</span>
							</div>
							<div className="mt-2 text-[#171414] pl-9 text-xs lg:text-sm ">
                                        Personalize your app to reflect your unique brand identity and design preferences using our advanced customization tools, ensuring a tailor-made solution that aligns with your business needs.
							</div>
						</div>
						<div className="flex flex-col">
							<div className="flex items-center">
								<span className="font-bold text-[#171414] text-[18px]">03</span>
								<span className="pl-3 font-bold text-[#171414] text-[18px]">Seamless Integration</span>
							</div>
							<div className="mt-2 text-[#171414] pl-9 text-xs lg:text-sm ">
                                        Boost your app&apos; functionality by seamlessly integrating it with popular tools, databases, payment gateways, and third-party APIs, streamlining the app-building process and eliminating the need for manual integrations.
							</div>
						</div>
					</div>
				</div>	
			</div>
		</div>



			
			
		
	);
};

export default AboutUs;
{/* <div className="bg-[#FDEFD6] py-4">
				<div className="max-w-screen-lg mx-auto">
					<div className="flex items-center justify-center">
						<div><img src="/src/assets/AboutUs/aboutUs2.png" alt="about2"/></div>
						
					</div>
				</div>
			</div> */}