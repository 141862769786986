import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { Listbox, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useMemo, useState } from 'react';

import { classNames } from 'utility';
import { Tooltip } from 'components/Tooltip';

export interface IOptionItem {
  label: string;
  alternate?: string;
  value: string | number;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  color?: string;
  excluded?: boolean;
}

interface IProps {
  value: string | number;
  options: IOptionItem[];
  excluded?: Partial<IOptionItem>[];
  onChange: (value: string) => void;
  onClick?: () => void;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  isTruncated?: boolean;
  className?: string;
}

const Dropdown: React.FC<IProps> = (props) => {

	const { options, excluded, value,  onChange, label, placeholder = 'Select Value', disabled = false, isTruncated = true } = props;
  
	const [selectedItem, setSelectedItem] = useState<IOptionItem | null>();
  
	const list: IOptionItem[] = useMemo(() => {
		if(excluded) {
			const values = excluded.map(i => i.value);
      
			return options.filter(option => !values.includes(option.value) || selectedItem?.value === option.value);
		}
    
		return options;
	}, [options, excluded]);

	useEffect(() => {
		const option = options.find((option) => option.value === value);
		if (option) 
			setSelectedItem(option);
		else
			setSelectedItem(null);
	}, [options, value]);
  
	return (
		<div>
			<Listbox value={value} onChange={onChange} disabled={disabled || selectedItem?.excluded}>
				{({ open }) => (
					<>
						<div className='relative'>
							<Listbox.Button className={classNames(
								'text-sm bg-white relative w-full rounded-md pl-3 py-2 text-left cursor-pointer 2xl:text-sm xl:text-sm lg:text-sm md:text-sm sm:text-sm max-sm:text-sm',
								selectedItem?.color 
									? `bg-${selectedItem?.color} bg-opacity-20 border border-transparent`
									: `border ${props.className} shadow-sm focus:outline-none focus:ring-1 focus:border-input-active hover:bg-gray-50`,
								disabled 
									? `bg-${selectedItem?.color || 'custom-gray-100'} 
                    ${selectedItem?.color ? 'hover:bg-opacity-20' : 'hover:bg-custom-gray-100'}`
									: '',
							)}>
								<div className={classNames(
									'flex items-center justify-between w-5/6',
									value === '' || value === -1 ? 'text-custom-gray-300' : '', 
									selectedItem?.color ? `text-${selectedItem?.color}` : '', 
								)}>
									{selectedItem?.icon && <selectedItem.icon className='h-4 w-4 -ml-1.5 mr-2' />}
									<p className='truncate'>
										{label && <span className='font-bold mr-1'>{label}:</span>} 
										{selectedItem?.label || <span className='text-custom-gray-300'>{placeholder}</span>}
									</p>
									{(!selectedItem?.excluded && !disabled) && (
										<span className='absolute right-0 flex items-center pr-2 pointer-events-none'>
											<ChevronDownIcon className='h-5 w-5 text-custom-gray-300' aria-hidden='true' />
										</span>
									)} 
								</div>
								
							</Listbox.Button>

							<Transition
								show={open}
								as={Fragment}
								leave='transition ease-in duration-100'
								leaveFrom='opacity-100'
								leaveTo='opacity-0'
							>
								<Listbox.Options className='absolute z-10 mt-1 w-[270px] bg-white shadow-lg max-h-60 rounded-md py-1 ring-1 ring-primary ring-opacity-5 overflow-auto focus:outline-none text-sm
                scrollbar-thin scrollbar-thumb-custom-gray-300 scrollbar-track-white scrollbar-thumb-rounded-full scrollbar-track-rounded-full list-none max-sm:w-[85%]'>
									{list.map((option, index) => {
										if (option.excluded) return;

										return (
											<Listbox.Option
												key={index}
												className={({ active }) =>
													classNames(
														active ? 'bg-gray-100' : '',
														'cursor-default select-none relative -ml-5 py-2 pl-3 pr-9 text-gray-700 text-sm'
													)
												}
												value={option.value}
												onClick={props.onClick}
											>
												{({ selected }) => (
													<Tooltip
														title={option.label.length >= 30 ? option.label : ''}
														bgColor='#9CA3AF'
														textColor='white'
														placement={'right'}
														offsetX={30}
														offsetY={0}
													>
														<div className='group'>
															<span className={classNames(
																'block',
																option.label.length >= 30 ? 'truncate' : '',
																selected ? 'font-semibold text-primary' : 'font-normal'
															)}>
																{option.alternate || option.label}
															</span>
															{/* <span className={classNames(option.label.length >= 30 ? 'group-hover:opacity-100 transition-opacity bg-gray-400 text-[13px]  p-2 shadow-sm absolute font-medium text-blue-800 rounded-md opacity-0 z-50 right-0' : 'hidden')}>{option.label}</span> */}
															
															{selected && (
																<span className='absolute inset-y-0 right-0 flex items-center pr-4'>
																	<CheckIcon className='h-5 w-5 text-primary' aria-hidden='true' />
																</span>
															)}
														</div>
													</Tooltip>
												)}
											</Listbox.Option>
										);
									})}
								</Listbox.Options>
							</Transition>
						</div>
					</>
				)}
			</Listbox>
		</div>
	);
};

export default Dropdown;
