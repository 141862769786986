import React, { ReactNode } from 'react';
import { classNames } from 'utility';

interface TooltipProps {
    message: string | null,
    children: ReactNode,
    className: string,
    isVisible?: boolean
}

const Tooltip: React.FC<TooltipProps> = ({ message, children, className, isVisible = true }) => {

	return (
		<div className='group relative flex justify-center'>
			{children}
			<span className={classNames('absolute top-8 scale-0 transition-all rounded', className, isVisible ? 'group-hover:scale-100' : '')}>
				{isVisible ? message : null}
			</span>
		</div>
	);
};

export default Tooltip;