import React from 'react';
import LandingPageHome from './Home';
import LandingPageFeatures from './Features';
import LandingPageHowItWorks from './HowItWorks';
import LandingPageFooter from './Footer';
import LandingPagePersonalize from './Personalize';

const LandingPage = () => {
	return (
		<div>
			<LandingPageHome />
			<LandingPageFeatures />
			<LandingPageHowItWorks />
			<LandingPagePersonalize />
			<LandingPageFooter />
		</div>
	);
};

export default LandingPage;
