import { GetBackgroundByAppIdResponse, GetDefaultBackgroundsResponse } from 'services/requests/appearance/background/schema';
import { SBBackgroundColor, SBBackgroundImage, SBBackgroundRefresh, SBBlurBackgroundImage, SBCustomBackgroundID, SBDefaultBackgroundImageID, SBIsCustomBackgroundFocus, SBOpacityBackgroundImage, SBPreviewBackgroundImage } from 'store/CustomizeApp/Appearance/backgroundAtom';
import { SBackgroundID, SBackgroundIDLive, SIsAppearanceChanges, SIsBackgroundChanges, SisBackgroundDone } from 'store/CustomizeApp/Appearance';
import { getBackgroundByAppID, getBackgroundByAppIDLive, getDefaultBackgrounds } from 'services/requests/appearance/background';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { AxiosError } from 'axios';
import { PIsPublishShowBackground } from 'store/CustomizeApp/Publish/publishAtom';
import { SIndustrySubCategory } from 'store/CustomizeApp/Appearance/flashScreenAtom';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { log } from 'utility/log';

export const BackgroundUseQuery = () => {
	const {id} = useParams();
	const setCategory					 = useSetAtom(SIndustrySubCategory);
	
	//Atoms for Background
	const selectedBackgroundID			= useAtomValue(SBackgroundID);
	const [backgroundOpacity, setOpacityBackgroundImage] = useAtom(SBOpacityBackgroundImage);
	const [blur, setBlurBackgroundValue]    = useAtom(SBBlurBackgroundImage);
	const [backgroundColor, setColorBackground]      	= useAtom(SBBackgroundColor);
	const [backgroundID, setBackgroundID]		    = useAtom(SBDefaultBackgroundImageID);
	const [customBgID, setCustomBackgroundID]		= useAtom(SBCustomBackgroundID);
	const [, setAppPreviewBackground]   = useAtom(SBPreviewBackgroundImage);
	const [isBgRefresh, setIsBgRefresh] = useAtom(SBBackgroundRefresh);
	const [isCustomBgFocus, setCustomBackgroundFocus]  = useAtom(SBIsCustomBackgroundFocus);
	const [backgroundImage, setBackgroundImage] 		= useAtom(SBBackgroundImage);
	const setPublishShow = useSetAtom(PIsPublishShowBackground);

	const backgroundIDLive = useAtomValue(SBackgroundIDLive);

	const setAppearanceChanges = useSetAtom(SIsAppearanceChanges);
	const setBackgroundChanges = useSetAtom(SIsBackgroundChanges);
	const setBackgroundDone = useSetAtom(SisBackgroundDone);
    
	const {data: defaultBacgkrounds, isFetched: successPhotos} = useQuery<GetDefaultBackgroundsResponse, AxiosError>({
		queryKey: ['flashScreenbackgrounds'],
		queryFn: () => getDefaultBackgrounds({limit: 1000}),
	});

	//Background
	const {data: backgroundLive, refetch: refetchBackgroundLive} = useQuery<GetBackgroundByAppIdResponse, AxiosError>({
		queryKey: ['backgroundLive', backgroundIDLive],
		queryFn: () => getBackgroundByAppIDLive(id, backgroundIDLive),
		enabled: false
	});
    
	//Background
	const {data, refetch: refetchBackground, isFetched: successBackground} = useQuery<GetBackgroundByAppIdResponse, AxiosError>({
		queryKey: ['background', selectedBackgroundID],
		queryFn: () => getBackgroundByAppID(id, selectedBackgroundID),
		enabled: false,
		onSuccess: (data) => {
			if(data){
				if(data.background.backgroundColor === '#FFFFFF'){
					setColorBackground('#ffffffff');
				}else setColorBackground(data.background.backgroundColor);
				setOpacityBackgroundImage(data.background.opacity);
				setBlurBackgroundValue(data.background.blur ?? 0);
				setBackgroundID(data.background.defaultBackgroundID ?? 0);
				setCustomBackgroundID(data.background.imageUploadID);
				setBackgroundImage(data.background.imageURL);
				defaultBacgkrounds?.rows.map((bg) => {
					if (bg.defaultBackgroundID === data.background.defaultBackgroundID || data.background.imageUploadID){
						setAppPreviewBackground(data.background.imageURL); 
						setCustomBackgroundFocus(true);
					} else {
						setCustomBackgroundFocus(false); 
						setAppPreviewBackground(null);}
				});
				// defaultBacgkrounds?.rows.map((bg) => {
				// 	if (bg.defaultBackgroundID === data.background.defaultBackgroundID){
				// 		setCategory(bg.subcategoryID);
				// 	}
				// });
				setIsBgRefresh(false);
				setBackgroundDone(true);
			}
		}
	}); 

	useEffect(() => {
		if(isBgRefresh && selectedBackgroundID && backgroundIDLive){
			refetchBackground();
			refetchBackgroundLive();
		}
	},[ isBgRefresh, selectedBackgroundID, backgroundIDLive]);

	useEffect(() => {
		if(data?.background && successBackground && successPhotos){
			if(data.background.defaultBackgroundID && data.background.defaultBackgroundID !== backgroundID){
				setAppearanceChanges(true);
				setBackgroundChanges(true);
				log('bg 1');
			}else if(data.background.defaultBackgroundID === undefined && backgroundID !== 0){
				setAppearanceChanges(true);
				setBackgroundChanges(true);
				log('bg 2');
			}else if(data.background.backgroundColor.toUpperCase() !== backgroundColor.toUpperCase()){
				setAppearanceChanges(true);
				setBackgroundChanges(true);
				log('bg 3', data.background.backgroundColor, backgroundColor.toUpperCase());
			}else if(data.background.opacity && data.background.opacity !== backgroundOpacity){
				setAppearanceChanges(true);
				setBackgroundChanges(true);
				log('bg 4');
			}else if(data.background.blur ? data.background.blur !== blur : 0 !== blur){
				setAppearanceChanges(true);
				setBackgroundChanges(true);
				log('bg 5');
			}else if(data.background.imageUploadID && data.background.imageUploadID !== customBgID){
				setAppearanceChanges(true);
				setBackgroundChanges(true);
				log('bg 6');
			}else if(data.background.imageUploadID && !isCustomBgFocus){
				setAppearanceChanges(true);
				setBackgroundChanges(true);
				log('bg 7');
			}else if(data.background.imageUploadID === undefined && isCustomBgFocus){
				setAppearanceChanges(true);
				setBackgroundChanges(true);
				log('bg 8');
			}else if(data.background.opacity ? (typeof data.background.opacity != 'undefined' && typeof backgroundOpacity != 'undefined' && data.background.opacity !== backgroundOpacity) :(typeof backgroundOpacity != 'undefined' && 0 != backgroundOpacity)){
				setAppearanceChanges(true);
				setBackgroundChanges(true);
				log('bg 9');
			}
			else{ setAppearanceChanges(false); setBackgroundChanges(false);}
		}
	},[backgroundColor, backgroundID, backgroundOpacity, blur, backgroundImage, customBgID, successBackground, successPhotos]);

	useEffect(() => {
		if(data && backgroundLive){
			if(data.background.backgroundColor !== backgroundLive.background.backgroundColor || 
				data.background.opacity !== backgroundLive.background.opacity || 
				data.background.blur && (data.background.blur !== backgroundLive.background.blur) ||
				// data.background.defaultBackgroundID !== backgroundLive.background.defaultBackgroundID ||
				// data.background.imageUploadID !== backgroundLive.background.imageUploadID ||
				data.background.imageURL !== backgroundLive.background.imageURL){
				setPublishShow(true);
			}else {setPublishShow(false);} 
		}
	},[data, backgroundLive]);

};