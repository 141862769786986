import { atom } from 'jotai';

export const SFeaturesTab = atom('Home');
export const FIsFeaturesChange   = atom<boolean>(false);

export const FIsHomeChanges = atom<boolean>(false);
export const FIsStoreChanges = atom<boolean>(false);
export const FIsAboutChanges = atom<boolean>(false);
export const FIsContentChanges = atom<boolean>(false);
export const FIsPrivacyChanges = atom<boolean>(false);
export const FIsTermsChanges = atom<boolean>(false);
export const FIsAccessChanges = atom<boolean>(false);
export const FIsContactChanges = atom<boolean>(false);
export const FIsGalleryChanges = atom<boolean>(false);
export const FIsHelpChanges = atom<boolean>(false);
export const FIsOffersChanges = atom<boolean>(false);
export const FIsNotifChanges = atom<boolean>(false);
export const FIsSocialMediaChanges = atom<boolean>(false);