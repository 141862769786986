import { SIsAppearanceChanges, SIsNavigationChanges, SNavigationID, SNavigationIDLive, SisNavigationDone } from 'store/CustomizeApp/Appearance';
import { SIsNavRefresh, SIsNavigationTextCheck, SNavigation, STempNavigation } from 'store/CustomizeApp/Appearance/navigationAtom';
import { getNavigationByID, getNavigationByIDLive } from 'services/requests/appearance/navigation';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { AxiosError } from 'axios';
import { PIsPublishShowNavigation } from 'store/CustomizeApp/Publish/publishAtom';
import { TNavigation } from 'services/requests/appearance/navigation/schema';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { log } from 'utility/log';

export const NavigationUseQuery = () => {
	const {id} = useParams();

	//Atoms for Navigation
	const selectedNavigationID   	  	  = useAtomValue(SNavigationID);
	const [navOrder, setNavigationOrder]  = useAtom(SNavigation);
	const [isNavTextCheck, setIsNavigationTextCheck] = useAtom(SIsNavigationTextCheck);
	const [isNavRefresh, setIsNavRefresh] = useAtom(SIsNavRefresh);
	const setAppearanceChanges            = useSetAtom(SIsAppearanceChanges);
	const setNavChanges = useSetAtom(SIsNavigationChanges);
	const [tempNavOrder, setTempNavOrder] = useAtom(STempNavigation);
	const navIDLive = useAtomValue(SNavigationIDLive);
	const setPublishShow = useSetAtom(PIsPublishShowNavigation);
	const setNavDone = useSetAtom(SisNavigationDone);
    
	//Navigation
	const {data: navigationLive, refetch: refetchNavigationLive} = useQuery<TNavigation, AxiosError>({
		queryKey: ['navigationsLive', navIDLive],
		queryFn: () => getNavigationByIDLive(id, navIDLive),
		enabled: false,
	});


	//Navigation
	const {data, refetch: refetchNavigation, isFetched} = useQuery<TNavigation, AxiosError>({
		queryKey: ['navigations', selectedNavigationID],
		queryFn: () => getNavigationByID(id, selectedNavigationID),
		enabled: false,
		onSuccess: (data) => {
			if(data){
				const fetchedNavList = (data?.navigation?.items)?.map((item) => {
					return { 
						id: item?.navigationOrderID, 
						label: item?.name, 
						isHidden: !item?.visible,
					};
				});
				setTempNavOrder(fetchedNavList);
				setNavigationOrder(fetchedNavList);
				setIsNavigationTextCheck(data?.navigation?.showLabel);
				setIsNavRefresh(false);
				setNavDone(true);
			}
		}
	});

	useEffect(() => {
		if (selectedNavigationID && isNavRefresh && navIDLive) {
			refetchNavigation();
			refetchNavigationLive();
		}
	}, [selectedNavigationID, isNavRefresh, navIDLive]);

	useEffect(() => {
		if(data?.navigation && isFetched){
			if(tempNavOrder[0]?.label !== navOrder[0]?.label || tempNavOrder[1]?.label !== navOrder[1]?.label || 
                tempNavOrder[2]?.label !== navOrder[2]?.label || tempNavOrder[3]?.label !== navOrder[3]?.label ||
                tempNavOrder[0]?.isHidden !== navOrder[0]?.isHidden || tempNavOrder[1]?.isHidden !== navOrder[1]?.isHidden || 
                tempNavOrder[2]?.isHidden !== navOrder[2]?.isHidden || tempNavOrder[3]?.isHidden !== navOrder[3]?.isHidden ||
				data?.navigation?.showLabel === undefined && isNavTextCheck || data?.navigation?.showLabel && !isNavTextCheck){
				setAppearanceChanges(true);
				setNavChanges(true);
				log('nav 1');
			}else {setAppearanceChanges(false); setNavChanges(false);}
		}
	},[navOrder, tempNavOrder, isNavTextCheck, data, isFetched]);

	useEffect(() => {
		// navigationLive doesn't have items on old apps (SNAP-1540)
		if (navigationLive?.navigation?.items) {
			setPublishShow(true);
		}
		if(data && navigationLive && navigationLive?.navigation?.items){
			if(data?.navigation?.items[0]?.name !== navigationLive?.navigation?.items[0]?.name || 
				data?.navigation?.items[1]?.name !== navigationLive?.navigation?.items[1]?.name || 
				data?.navigation?.items[2]?.name !== navigationLive?.navigation?.items[2]?.name || 
				data?.navigation?.items[3]?.name !== navigationLive?.navigation?.items[3]?.name || 
				data?.navigation?.items[0]?.visible !== navigationLive?.navigation?.items[0]?.visible || 
				data?.navigation?.items[1]?.visible !== navigationLive?.navigation?.items[1]?.visible || 
				data?.navigation?.items[2]?.visible !== navigationLive?.navigation?.items[2]?.visible || 
				data?.navigation?.items[3]?.visible !== navigationLive?.navigation?.items[3]?.visible ){
				setPublishShow(true);
			}else setPublishShow(false);
		}
	},[data, navigationLive]);
};