import { BackgroundUseQuery } from './Background';
import { ColorUseQuery } from './Color';
import { FlashScreenUseQuery } from './FlashScreen';
import { FontUseQuery } from './Font';
import { ImagesUseQuery } from './Images';
import { LayoutThemeUseQuery } from './LayoutAndTheme';
import { NavigationUseQuery } from './Navigation';
import { SideBarUseQuery } from './SideBar';

export const UseAppearanceQueries = () => {

	FlashScreenUseQuery();
	LayoutThemeUseQuery();
	BackgroundUseQuery();
	NavigationUseQuery();
	SideBarUseQuery();
	ColorUseQuery();
	FontUseQuery();
	ImagesUseQuery();
};
