import { FIsFeaturesChange, FIsPrivacyChanges } from 'store/CustomizeApp/Features';
import { FIsPrivacyRefresh, FPrivacy, FPrivacyID } from 'store/CustomizeApp/Features/privacy';
import React, { useEffect } from 'react';
import { getPrivacyPolicyByAppID, getPrivacyPolicyByAppIDLive } from '../../../../../services/requests/Features/PrivacyPolicy/index';
import { useAtom, useSetAtom } from 'jotai';

import { AxiosError } from 'axios';
import { GetPrivacyPolicyrFormResponse } from '../../../../../services/requests/Features/PrivacyPolicy/schema';
import { PIsPublishShowPrivacy } from 'store/CustomizeApp/Publish/publishAtom';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { log } from 'utility/log';

export const PrivacyUseQuery = () => {

	//Atoms for Privacy
	const [desc, setPrivacyDesc]       				  = useAtom(FPrivacy);
	const [, setPrivacyID]          			  = useAtom(FPrivacyID);
	const [isPrivacyRefresh, setIsPrivacyRefresh] = useAtom(FIsPrivacyRefresh);
	const setFeatureChange = useSetAtom(FIsFeaturesChange);
	const setPrivacyChanges = useSetAtom(FIsPrivacyChanges);
	const setPublishShow = useSetAtom(PIsPublishShowPrivacy);

	const {id} = useParams();
	//get request
	const {data: privacyLive, refetch: refetchPrivacyLive} = useQuery<GetPrivacyPolicyrFormResponse, AxiosError>({
		queryKey: ['privacyPolicyLive', id],
		queryFn: () => getPrivacyPolicyByAppIDLive(id),
		enabled: false,
		retry: 0,
		onError: () => setIsPrivacyRefresh(false)
	});

	//get request
	const {data, refetch, isFetching, isFetched} = useQuery<GetPrivacyPolicyrFormResponse, AxiosError>({
		queryKey: ['privacyPolicy', id],
		queryFn: () => getPrivacyPolicyByAppID(id),
		enabled: false,
		retry: 0,
		onSuccess: (data) => {
			if(data?.privacyPolicy){
				setPrivacyDesc(data?.privacyPolicy?.description);
				setPrivacyID(data?.privacyPolicy?.privacyPolicyID);
				setIsPrivacyRefresh(false);
			}else{
				setPrivacyDesc('');
				setPrivacyID(0);
				setIsPrivacyRefresh(false);
			}
		},
		onError: () => setIsPrivacyRefresh(false)
	});

	useEffect(() =>{
		if(isPrivacyRefresh){
			refetch();
			refetchPrivacyLive();
		}
	},[isPrivacyRefresh]);

	// useEffect(() =>{
	// 	if(isFetching){
	// 		setFeatureChange(false);
	// 	}
	// },[isFetching]);

	useEffect(() => {
		if((data?.privacyPolicy?.description || desc) && isFetched){
			if(data?.privacyPolicy?.description === undefined && desc === '<p><br></p>'){
				// setFeatureChange(false);
				setPrivacyChanges(false);
			}
			else if(data?.privacyPolicy?.description !== desc){
				// setFeatureChange(true);
				setPrivacyChanges(true);
				log('PrivacyChangesTrue', data?.privacyPolicy?.description, desc);
			} else if (data?.privacyPolicy?.description == desc) {
				// setFeatureChange(false);
				setPrivacyChanges(false);
			}
		}
	},[desc, data, isFetched]);

	useEffect(() => {
		if(data?.privacyPolicy && privacyLive?.privacyPolicy){
			if(data?.privacyPolicy?.description !== privacyLive?.privacyPolicy?.description){
				setPublishShow(true);
				log('PrivacyPublishTrue');
			}else setPublishShow(false);
		}
	},[data, privacyLive]);
};