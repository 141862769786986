import { AppFeatIsSaving, SAppName, SIsThemeLayoutRefresh } from 'store/CustomizeApp/Appearance';
import { PIsJustPublished, PIsPublishShowAbout, PIsPublishShowAccess, PIsPublishShowBackground, PIsPublishShowColor, PIsPublishShowContact, PIsPublishShowContent, PIsPublishShowFavicon, PIsPublishShowFlashScreen, PIsPublishShowFont, PIsPublishShowGallery, PIsPublishShowHelp, PIsPublishShowHome, PIsPublishShowNavigation, PIsPublishShowNotif, PIsPublishShowOffer, PIsPublishShowOgTags, PIsPublishShowPrivacy, PIsPublishShowSidebar, PIsPublishShowSocMed, PIsPublishShowStore, PIsPublishShowTerms, PIsPublishShowThemeLayout } from 'store/CustomizeApp/Publish/publishAtom';
import { showFailedAlert, showSuccessAlert } from 'utility/alerts';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';

import { AxiosError } from 'axios';
import { Button } from 'components/Common/Buttons';
import { ENVIRONMENT } from 'services/api';
import { FContentsRefresh } from 'store/CustomizeApp/Features/contentAtom';
import { FIsAboutRefresh } from 'store/CustomizeApp/Features/aboutAtom';
import { FIsAccessibilityRefresh } from 'store/CustomizeApp/Features/accessibility';
import { FIsContactRefresh } from 'store/CustomizeApp/Features/contact';
import { FIsGalleryRefresh } from 'store/CustomizeApp/Features/galleryAtom';
import { FIsHelpRefresh } from 'store/CustomizeApp/Features/helpAtom';
import { FIsHomeRefresh } from 'store/CustomizeApp/Features/homeAtom';
import { FIsNotifcationRefresh } from 'store/CustomizeApp/Features/notificationAtom';
import { FIsOfferRefresh } from 'store/CustomizeApp/Features/offersAtom';
import { FIsPrivacyRefresh } from 'store/CustomizeApp/Features/privacy';
import { FIsStoreRefresh } from 'store/CustomizeApp/Features/storeAtom';
import { FIsTermsRefresh } from 'store/CustomizeApp/Features/terms';
import { FsocialMediasRefreshAtom } from 'store/CustomizeApp/Features/socialMediaAtom';
import { IMAGES } from 'utility/constant';
import { PSrcQR } from 'store/CustomizeApp/Publish/publishAtom';
import PubishTabPreviewLink from '../PreviewLink';
import QRCode from 'qrcode.react';
import React from 'react';
import { SBBackgroundRefresh } from 'store/CustomizeApp/Appearance/backgroundAtom';
import { SIsColorRefresh } from 'store/CustomizeApp/Appearance/colorAtom';
import { SIndustrySubCategory, SIsFlashRefresh } from 'store/CustomizeApp/Appearance/flashScreenAtom';
import { SIsFontRefresh } from 'store/CustomizeApp/Appearance/fontAtom';
import { SIsNavRefresh } from 'store/CustomizeApp/Appearance/navigationAtom';
import { SIsSidebarRefresh } from 'store/CustomizeApp/Appearance/sideBarAtom';
import { SLayoutId } from 'store/CustomizeApp/Appearance/layoutAtom';
import { SThemeId } from 'store/CustomizeApp/Appearance/themeAtom';
import { classNames } from 'utility';
import { rePublishApp } from 'services/requests/apps';
import { useMediaQuery } from 'react-responsive';
import { SIsFaviconRefresh } from 'store/CustomizeApp/Appearance/imagesAtoms/favicon';
import { SIsOgTagsRefresh } from 'store/CustomizeApp/Appearance/imagesAtoms/ogTagsAtoms';
import { GetAppResponse } from 'services/requests/apps/schema';

const PubishTabAppLink = () => {

	const { id } = useParams();
	const [pwaUrl, setPWAUrl] = useState('');
	const isSaving = useAtomValue(AppFeatIsSaving);
	const [isPublishShowFlash, setIsPublishShowFlash] = useAtom(PIsPublishShowFlashScreen);
	const [isPublishShowThemeLayout, setIsPublishShowThemeLayout] = useAtom(PIsPublishShowThemeLayout);
	const [isPublishShowBackground, setPublishShowBackground] = useAtom(PIsPublishShowBackground);
	const [isPublishShowNavigation, setPublishShowNavigation] = useAtom(PIsPublishShowNavigation);
	const [isPublishShowSidebar, setPublishShowSidebar] = useAtom(PIsPublishShowSidebar);
	const [isPublishShowColor, setPublishShowColor] = useAtom(PIsPublishShowColor);
	const [isPublishShowFont, setPublishShowFont] = useAtom(PIsPublishShowFont);
	const [, setPublishShowFavicon] = useAtom(PIsPublishShowFavicon);
	const [, setPublishShowOgTags] = useAtom(PIsPublishShowOgTags);

	const [isPublishShowHome, setPublishShowHome] = useAtom(PIsPublishShowHome);
	const [isPublishShowStore, setPublishShowStore] = useAtom(PIsPublishShowStore);
	const [isPublishShowAbout, setIsPublishShowAbout] = useAtom(PIsPublishShowAbout);
	const [isPublishShowContent, setIsPublishShowContent] = useAtom(PIsPublishShowContent);
	const [isPublishShowPrivacy, setIsPublishShowPrivacy] = useAtom(PIsPublishShowPrivacy);
	const [isPublishShowTerms, setIsPublishShowTerms] = useAtom(PIsPublishShowTerms);
	const [isPublishShowAccess, setIsPublishShowAccess] = useAtom(PIsPublishShowAccess);
	const [isPublishShowContact, setIsPublishShowContact] = useAtom(PIsPublishShowContact);
	const [isPublishShowGallery, setIsPublishShowGallery] = useAtom(PIsPublishShowGallery);
	const [isPublishShowHelp, setIsPublishShowHelp] = useAtom(PIsPublishShowHelp);
	const [isPublishShowOffer, setIsPublishShowOffer] = useAtom(PIsPublishShowOffer);
	const [isPublishShowNotif, setIsPublishShowNotif] = useAtom(PIsPublishShowNotif);
	const [isPublishShowSocMed, setIsPublishShowSocMed] = useAtom(PIsPublishShowSocMed);

	const setIsFlashRefresh = useSetAtom(SIsFlashRefresh);
	const setThemeLayoutRefresh = useSetAtom(SIsThemeLayoutRefresh);
	const setSelectedLayoutID = useSetAtom(SLayoutId);
	const setSelectedThemeID = useSetAtom(SThemeId);
	const setIsBackgroundRefresh = useSetAtom(SBBackgroundRefresh);
	const setIsNavigationRefresh = useSetAtom(SIsNavRefresh);
	const setIsSideBarRefresh = useSetAtom(SIsSidebarRefresh);
	const setIsColorRefresh = useSetAtom(SIsColorRefresh);
	const setIsFontRefresh = useSetAtom(SIsFontRefresh);
	const setIsFaviconRefresh = useSetAtom(SIsFaviconRefresh);
	const setIsOgTagsRefresh = useSetAtom(SIsOgTagsRefresh);

	const setHomeRefresh = useSetAtom(FIsHomeRefresh);
	const setStoreRefresh = useSetAtom(FIsStoreRefresh);
	const setAboutRefresh = useSetAtom(FIsAboutRefresh);
	const setContentRefresh = useSetAtom(FContentsRefresh);
	const setPrivacyRefresh = useSetAtom(FIsPrivacyRefresh);
	const setTermsRefresh = useSetAtom(FIsTermsRefresh);
	const setAccessRefresh = useSetAtom(FIsAccessibilityRefresh);
	const setContactRefresh = useSetAtom(FIsContactRefresh);
	const setGalleryRefresh = useSetAtom(FIsGalleryRefresh);
	const setHelpRefresh = useSetAtom(FIsHelpRefresh);
	const setOfferfRefresh = useSetAtom(FIsOfferRefresh);
	const setNotifRefresh = useSetAtom(FIsNotifcationRefresh);
	const setSocMedRefresh = useSetAtom(FsocialMediasRefreshAtom);

	// const [isPublishShow, setPublishShow] = useState(false);
	const [isJustPublished, setJustPublished] = useAtom(PIsJustPublished);
	const subcategoryID = useAtomValue(SIndustrySubCategory);
	const appName = useAtomValue(SAppName);

	const queryClient = useQueryClient();

	const cachedApp = queryClient.getQueryData<GetAppResponse>('app');

	const updateCachedApp = () => {
		queryClient.setQueryData<GetAppResponse | undefined>(
			['app'],
			(cached) =>
				cached ? {
					...cached,
					app: {
						...cached.app,
						name: appName,
						isJustPublished: true,
						subcategoryID: subcategoryID,
					}
				} : undefined
		);
	};

	useEffect(() => {
		if (ENVIRONMENT === 'development') {
			setPWAUrl('dapp.snaptoapp.com');
		} else if (ENVIRONMENT === 'test') {
			setPWAUrl('tapp.snaptoapp.com');
		} else {
			setPWAUrl('app.snaptoapp.com');
		}
	}, []);

	useEffect(() => {
		if (cachedApp?.app) {
			setJustPublished(cachedApp?.app.isJustPublished);
		}
	}, [cachedApp?.app.isJustPublished]);

	const textToCopy = `${pwaUrl}/app/${id}`;

	const setRefreshTrue = () => {

		setIsFlashRefresh(true);
		setThemeLayoutRefresh(true);
		setIsBackgroundRefresh(true);
		setIsNavigationRefresh(true);
		setIsSideBarRefresh(true);
		setIsColorRefresh(true);
		setIsFontRefresh(true);
		setIsFaviconRefresh(true);
		setIsOgTagsRefresh(true);

		setHomeRefresh(true);
		setStoreRefresh(true);
		setAboutRefresh(true);
		setContentRefresh(true);
		setPrivacyRefresh(true);
		setTermsRefresh(true);
		setAccessRefresh(true);
		setContactRefresh(true);
		setGalleryRefresh(true);
		setHelpRefresh(true);
		setOfferfRefresh(true);
		setNotifRefresh(true);
		setSocMedRefresh(true);
	};

	const setPublishShowFalse = () => {
		setIsPublishShowFlash(false);
		setIsPublishShowThemeLayout(false);
		setPublishShowBackground(false);
		setPublishShowNavigation(false);
		setPublishShowSidebar(false);
		setPublishShowColor(false);
		setPublishShowFont(false);
		setPublishShowFavicon(false);
		setPublishShowOgTags(false);
		setPublishShowHome(false);
		setPublishShowStore(false);
		setIsPublishShowAbout(false);
		setIsPublishShowContent(false);
		setIsPublishShowPrivacy(false);
		setIsPublishShowTerms(false);
		setIsPublishShowAccess(false);
		setIsPublishShowContact(false);
		setIsPublishShowGallery(false);
		setIsPublishShowOffer(false);
		setIsPublishShowHelp(false);
		setIsPublishShowNotif(false);
		setIsPublishShowSocMed(false);
	};

	const { mutate: rePublish } = useMutation<unknown, AxiosError, unknown>(
		() => rePublishApp(id),
		{
			onSuccess: () => {
				showSuccessAlert('Your app has been published.');
				setRefreshTrue();
				setPublishShowFalse();
				updateCachedApp();
				// setPublishShowFalse();

			},
			onError: () => {
				showFailedAlert('Cannot publish app');
			},
		}
	);

	// const { data: appData } = useQuery<GetAppResponse, AxiosError>({
	// 	queryKey: ['app'],
	// 	queryFn: () => getAppsById(id),
	// 	onSuccess: () => {
	// 		if(appData) {
	// 			if(appData?.app?.isJustPublished){
	// 				setJustPublished(true);
	// 			}
	// 		}
	// 	}
	// });

	// if(appData) {
	// 	setJustPublished(appData?.app.IsJustPublished);
	// 	log(isJustPublished, 'yo');
	// }

	// useEffect(() => {
	// 	if (isPublishShowFlash || isPublishShowThemeLayout || isPublishShowBackground || isPublishShowNavigation ||
	// 		isPublishShowSidebar || isPublishShowColor || isPublishShowFont || isPublishShowHome || isPublishShowStore || isPublishShowAbout ||
	// 		isPublishShowContent || isPublishShowPrivacy || isPublishShowTerms || isPublishShowAccess || isPublishShowContact || isPublishShowGallery ||
	// 		isPublishShowHelp || isPublishShowOffer || isPublishShowNotif || isPublishShowSocMed) {
	// 		setPublishShow(true); 
	// 		// setJustPublished(true);
	// 	} else {
	// 		setPublishShow(false); 
	// 		// setJustPublished(false);
	// 	}

	// }, [isPublishShowFlash, isPublishShowThemeLayout, isPublishShowBackground, isPublishShowNavigation,
	// 	isPublishShowSidebar, isPublishShowColor, isPublishShowFont, isPublishShowHome, isPublishShowStore, isPublishShowAbout,
	// 	isPublishShowContent, isPublishShowPrivacy, isPublishShowTerms, isPublishShowAccess, isPublishShowContact, isPublishShowGallery, isPublishShowSocMed,
	// 	isPublishShowNotif, isPublishShowOffer]);
	

	//App
	const downloadQRCode = () => {
		const canvas = document.getElementById('qrcode') as HTMLCanvasElement;
		const pngUrl = canvas
			.toDataURL('image/png')
			.replace('image/png', 'image/octet-stream');
		const downloadLink = document.createElement('a');
		downloadLink.href = pngUrl;
		downloadLink.download = `snaptoapp-qrcode-${id}.png`;
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	};

	const lowerSmViewPort = useMediaQuery({ maxWidth: 540 });

	return (
		<div className='h-auto bg-[#E6F1FF] rounded-md pb-[5rem] px-[62px] max-sm:px-[20px] max-sm:w-[100%]'>
			<div className='flex 2xl:flex-row 2xl:space-x-[10%] xl:flex-col-reverse xl:space-x-0 lg:flex-row lg:space-x-[10%] md:flex-col-reverse md:space-x-0 sm:flex-col-reverse sm:space-x-0 max-sm:flex-col-reverse max-sm:space-x-0 self-center'>
				<div className='w-1/2 max-sm:w-full lg:w-[80%] lg:mx-auto sm:mx-auto'>
					<div className='pt-[3.5rem] w-full'>
						<p className='text-lg text-[#26355E] font-bold'>Preview Link</p>
						<p className='mt-4 text-[#1C180F] mb-5 max-sm:text-sm'>Share this link among your team to test</p>
						<PubishTabPreviewLink />
					</div>
					<div className='mt-8'>
						<p className='text-lg text-[#26355E] font-bold'>Published Link</p>
						<p className='mt-4 text-[#1C180F] max-sm:text-sm'>Share this link to your users to go live</p>
						<div className='flex w-full items-center mt-5'>
							<div className='w-full cursor-pointer max-sm:text-sm' onClick={() => window.open(`https://${textToCopy}`, '_blank')}>
								<input
									disabled
									className='
											py-3 
											w-full 
											text-base										 
											px-[30px] 
											rounded-md 
											bg-[#F2F2F2] 
											text-[#171414] 
											font-medium 
											pointer-events-none 
											underline
											border-2
											border-[#E6E4E4]
											max-sm:text-sm
											max-sm:px-[10px]
											overflow-visible
											'
									value={textToCopy}
									type={'text'}
								/>
							</div>
						</div>
						{!isJustPublished &&
							<div className='w-full flex flex-col justify-end mt-[2rem] items-center gap-7'>
								<span className='text-black text-base mr-auto'>Do you want to update your app with changes?</span>
								<Button variant='primary' onClick={rePublish} className='py-[10px] px-[5.5rem] font-semibold text-base'>
									Publish
								</Button>
							</div>
						}
					</div>
				</div>
				<div className='pt-[55px] w-1/2 max-sm:w-full lg:w-full sm:w-full'>
					<div className='flex flex-col justify-center items-center max-sm:w-full'>
						<div className={classNames('w-[22rem] h-[22rem] bg-white rounded-md flex flex-col justify-center items-center',
							lowerSmViewPort ? 'max-sm:w-[200px] max-sm:h-[200px]' : '')}>
							<span className='pt-2 font-semibold text-xl text-[#464255]'>Scan QR Code</span>
							<div className='py-5'>
								<QRCode
									value={textToCopy}
									size={lowerSmViewPort ? 100 : 200}
									id='qrcode'
								/>
							</div>

							<span className='text-[#26355E] font-semibold'>Published Link</span>
						</div>
						<div className='mt-[2.75rem]'>
							<Button variant='primary' className='py-[10px] px-[3rem] font-semibold text-base max-sm:text-sm' onClick={downloadQRCode}>Download QR Code
							</Button>
						</div>
					</div>
					{/* <Button variant='primary' onClick={handleCopyClick}>
						<img
							src={IMAGES.copyClipboard}
							className='py-[12px] px-[14px]'
							alt='copyclipboard'
						/>
					</Button> */}
				</div>
			</div>
		</div>
	);
};

export default PubishTabAppLink;