import React, { useState } from 'react';
import Approval from './Tabs/Approval';
import Approved from './Tabs/Approved';
import Rejected from './Tabs/Rejected';
import AllApplication from './Tabs/All';
import { useQuery } from 'react-query';
import { getAllAppsNA } from 'services/requests/apps';

const AppPanel: React.FC = () => {
	const [activeTab, setActiveTab] = useState(0);

	const { data } = useQuery('pwa-apps', getAllAppsNA);

	const inrCount = data?.app.filter((website) => website.status === 'INR');
	const aprCount = data?.app.filter((website) => website.status === 'LIV');
	const rejCount = data?.app.filter((website) => website.status === 'REJ');
	const allCount = data?.app.filter((website) => website.status !== 'DEL');

	const tabs = [
		{ label: `For Approval (${inrCount?.length})`, content: <Approval app={data?.app} /> },
		{ label: `Approved (${aprCount?.length})`, content:  <Approved app={data?.app} /> },
		{ label: `Rejected (${rejCount?.length})`, content:  <Rejected app={data?.app} /> },
		{ label: `All (${allCount?.length})`, content:  <AllApplication app={data?.app} /> },
	];

	return (
		<>
			<div className='bg-[#FAFAFA] w-full p-10'>
				<div className='text-[#2C3E6B] text-[20px] font-bold'>App</div>
				<div className='w-full'>
					<div className='border-b border-gray-200'>
						<nav className='-mb-px flex' aria-label='Tabs'>
							{tabs.map((tab, index) => (
								<button
									key={index}
									className={`${index === activeTab
										? 'border-b-4 border-[#3C5491] text-[#3C5491]'
										: 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'} whitespace-nowrap py-4 border-b-4 font-medium text-sm min-w-[120px] ${index > 0 ? 'ml-4' : ''} ${index === tabs.length - 1 ? 'ml-auto' : ''}`}
									onClick={() => setActiveTab(index)}
								>
									{tab.label}
								</button>
							))}
						</nav>
					</div>
					<div className='mt-4'>
						{tabs.map((tab, index) => (
							<div
								key={index}
								className={`${index === activeTab ? '' : 'hidden'} py-2`}
							>
								{tab.content}
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	);
};

export default AppPanel;
