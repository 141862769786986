import React from 'react';
import { PricingCard } from 'components/PaymentTab/Content/PricingCard';
import { PaymentMethodForm } from 'components/PaymentTab/Content/PaymentMethodForm';
import { FormGroup } from '@mui/material';
import SuccessPaymentCard from 'components/PaymentTab/Content/SuccessPaymentCard';

interface IPaymentStepperContent {
  handleNext: (e: any) => void;
  stepNum: number;
}

export const PaymentStepperContent: React.FC<IPaymentStepperContent> = ({handleNext, stepNum}) => {

	return (
		<div>
			<FormGroup sx={{display : stepNum === 0 ? '' : 'none'}}>
				<div className='py-5'>
					<PricingCard handleNext={handleNext}/>
				</div>
			</FormGroup>
			<FormGroup sx={{display: stepNum === 1 ? '' : 'none'}}>		
				<div className='py-5 flex justify-center items-center'>
					<PaymentMethodForm />
				</div>
			</FormGroup>	
			<FormGroup sx={{display: stepNum === 2 ? '' : 'none'}}>
				<div className='flex justify-center items-center'>
					<SuccessPaymentCard />
				</div>
			</FormGroup>
		</div>
	);
};