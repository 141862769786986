import z from 'zod';

export const errorResponse = z.object({
	error: z.object({
		message: z.string(),
	})
});

export const loginSchema = z.object({
	username: z.string().email(),
	password: z.string(),
});

export const loginVerifiedSchema = z.object({
	email: z.string().email(),
});

export const loginVerifiedFinalSchema = z.object({
	username: z.string().email(),
	password: z.string(),
	email: z.string().email(),
});

export const loginVerifiedResponse = z.object({
	UserId: z.string(),
	Username: z.string(),
	Email: z.string().email(),
	FirstName: z.string(),
	LastName: z.string(),
	EmailVerified: z.boolean(),
	Enabled: z.boolean()
});


export const verifyEmailSchema = z.object({
	tokenID: z.string()
});
export const verifyEmailResponseSchema = z.object({
	success: z.boolean(),
	email: z.string()
});

export const loginResponseSchema = z.object({
	access_token: z.string(),
	expires_in: z.number(),
	refresh_expires_in: z.number(),
	refresh_token: z.string(),
	token_type: z.string(),
	identityProvider: z.array(z.string()).optional(),
});

// const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/;

const passwordSchema = z
	.string()
	.min(8, { message: 'Password must contain a minimum of 8 characters.' })
	.regex(/[a-z]/, { message: 'Password should have at least 1 lowercase character.' })
	.regex(/[A-Z]/, { message: 'Password should have at least 1 uppercase character.' })
	.regex(/\d/, { message: 'Password should have at least 1 number.' })
	.regex(/[@$!%*#?&]/, { message: 'Password should have at least 1 special character.' });

const signUpCredentialsSchema = z.object({
	type: z.string(),
	value: passwordSchema,
	confirmPass: z.string(),
	temporary: z.boolean(),
}).superRefine(({ confirmPass, value }, ctx) => {
	if (confirmPass !== value) {
		ctx.addIssue({
			code: 'custom',
			message: 'The password did not match',
			path: ['confirmPass'],
		});
	}
});

const textFirstNameRegex = z
	.string()
	.regex(/^[a-zA-Z\s]*$/, { message: 'Input must be letters only.' })
	.regex(/^(?!\s*$)(\s*\S+\s*)+$/, { message: 'First Name is Required.' })
	.transform((val) => val.trim().replace(/\s+/g, ' '));

const textLastNameRegex = z
	.string()
	.regex(/^[a-zA-Z\s]*$/, { message: 'Input must be letters only.' })
	.regex(/^(?!\s*$)(\s*\S+\s*)+$/, { message: 'Last Name is Required.' })
	.transform((val) => val.trim().replace(/\s+/g, ' '));

export const signUpSchema = z.object({
	email: z.string().email(),
	firstName: textFirstNameRegex,
	lastName: textLastNameRegex,
	enabled: z.boolean(),
	credentials: z.array(signUpCredentialsSchema),
	attributes: z.object({
		Tenant_ID: z.string(),
	}),
});

export const signUpSchemaResponse = z.object({
	userId: z.number(),
	industryID: z.number(),
	name: z.string(),
	websiteUrl: z.string(),
	logoUrl: z.string()
});

export const forgotEmailSendingSchema = z.object({
	email: z.string().email(),
});

export const forgotResetPasswordSchema = z.object({
	tokenID: z.string(),
	newPassword: passwordSchema,
	confirmPassForgot: z.string()
}).superRefine(({ confirmPassForgot, newPassword }, ctx) => {
	if (confirmPassForgot !== newPassword) {
		ctx.addIssue({
			code: 'custom',
			message: 'The password did not match',
			path: ['confirmPassForgot'],
		});
	}
});

export const validateResetPasswordTokenSchema = z.object({
	tokenID: z.string(),
});

export const validateResetPasswordTokenResponse = z.object({
	success: z.boolean(),
	tokenExpiration: z.number(),
});

export const getGoogleAuthUrlSchema = z.object({
	url: z.string(),
});

export const getGoogleTokenSchema = z.object({
	code: z.string(),
});

export const authorizeGoogleUserResponse = z.object({
	access_token: z.string(),
	expires_in: z.number(),
	refresh_expires_in: z.number(),
	refresh_token: z.string(),
	token_type: z.string(),
	redirect_url: z.string(),
	email: z.string(),
	identityProvider: z.array(z.string()).optional(),
});

export const getUserCredentialsResponse = z.object({
	ID: z.string(),
	type: z.string(),
	createdDate: z.number(),
	credentialData: z.string()
});

export const disconnectSsoResponse = z.object({
	success: z.boolean(),
	email: z.string()
});

export const disconnectSsoSchema = z.object({
	email: z.string()
});

export type TErrorResponse =  z.infer<typeof errorResponse>;

export type LoginType = z.infer<typeof loginSchema>;
export type LoginResponseType = z.infer<typeof loginResponseSchema>;
export type SignUpType = z.infer<typeof signUpSchema>;
export type SignUpTypeResponse = z.infer<typeof signUpSchema>;
export type SignUpTypeResponse2 = z.infer<typeof signUpSchemaResponse>;
export type VerifyEmailType = z.infer<typeof verifyEmailSchema>;
export type VerifyEmailResponseType = z.infer<typeof verifyEmailResponseSchema>;

export type ForgotEmailSchema = z.infer<typeof forgotEmailSendingSchema>;
export type ForgotEmailResponse = z.infer<typeof forgotEmailSendingSchema>;
export type ForgotResetPasswordSchema = z.infer<typeof forgotResetPasswordSchema>;
export type ForgotResetPasswordResponse = z.infer<typeof forgotResetPasswordSchema>;
export type ValidateResetPasswordTokenSchema = z.infer<typeof validateResetPasswordTokenSchema>;
export type ValidateResetPasswordTokenResponse = z.infer<typeof validateResetPasswordTokenResponse>;

export type GetGoogleAuthUrlResponse = z.infer<typeof getGoogleAuthUrlSchema>;
export type GetGoogleTokenType = z.infer<typeof getGoogleTokenSchema>;
export type AuthorizeGoogleUserResponse = z.infer<typeof authorizeGoogleUserResponse>;

export type LoginVerifiedType = z.infer<typeof loginVerifiedSchema>;
export type LoginVerifiedResponse = z.infer<typeof loginVerifiedResponse>;
export type LoginVerifiedFinalResponse = z.infer<typeof loginVerifiedFinalSchema>;

export type GetUserCredentialsResponse = z.infer<typeof getUserCredentialsResponse>;

export type DisconnectSsoResponse = z.infer<typeof disconnectSsoResponse>;
export type DisconnectSsoRequest = z.infer<typeof disconnectSsoSchema>;