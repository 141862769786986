import React, { useEffect, useState } from 'react';
import { SAppNameChanges, SIsAppearanceChanges } from 'store/CustomizeApp/Appearance';

import AppPreviewComponent from 'components/AppPreview';
import { Button } from 'components/Common/Buttons';
import { FCPopUp } from 'store/CustomizeFeatures/contentAtom';
import { FIsFeaturesChange } from 'store/CustomizeApp/Features';
import Spinner from 'components/Common/Spinner';
import { useAtomValue } from 'jotai';

interface IButtonProps {
	onClickSave: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onClickCancel: (e: React.ChangeEvent<HTMLInputElement>) => void;
	isSaving: boolean;
}

const AppPreview: React.FC<IButtonProps> = ({ onClickSave, onClickCancel, isSaving }) => {
	const isAppNameChanges = useAtomValue(SAppNameChanges);
	const isThereAppearanceChanges = useAtomValue(SIsAppearanceChanges);
	const isThereFeaturesChanges = useAtomValue(FIsFeaturesChange);

	const [isButtonDisabled, setButtonDisabled] = useState(true);

	useEffect(() => {
		if (isSaving) {
			setButtonDisabled(true);
		}
		else if (isThereAppearanceChanges) {
			setButtonDisabled(false);
		}
		else if (isThereFeaturesChanges) {
			setButtonDisabled(false);
		}
		else if (isAppNameChanges) {
			setButtonDisabled(false);
		}
		else setButtonDisabled(true);
	}, [isAppNameChanges, isThereAppearanceChanges, isThereFeaturesChanges, isSaving]);

	return (
		<div className='w-full mt-6 py-3 flex 2xl:flex-col 2xl:flex 2xl:mt-3 xl:flex-col xl:flex xl:mt-3 lg:py-0 lg:mt-0 lg:flex-col-reverse md:py-0 md:mt-0 sm:flex-col-reverse sm:py-0 sm:mt-0 max-sm:flex-col-reverse max-sm:py-0 max-sm:mt-0'>
			<div className='lg:hidden xl:hidden 2xl:hidden md:fixed md:bg-white md:bottom-0 md:left-0 md:h-[70px] md:w-[100vw] sm:fixed sm:bg-white sm:bottom-0 sm:left-0 sm:h-[70px] sm:w-[100vw] max-sm:fixed max-sm:bg-white max-sm:bottom-0 max-sm:left-0 max-sm:h-[70px] max-sm:w-[100vw]' />
			<div className='bg-white lg:z-0 xl:z-0 md:z-40 max-sm:z-40 sm:z-40 px-2 flex flex-row gap-[30px] w-[350px] max-sm:mb-0 max-sm:bottom-0 max-sm:fixed max-sm:items-center max-sm:left-1/2 max-sm:-translate-x-1/2 max-sm:w-full max-sm:h-[8%] sm:mb-0 sm:bottom-0 sm:fixed sm:items-center sm:left-1/2 sm:-translate-x-1/2 sm:w-full sm:h-[8%] md:bottom-0 md:fixed md:items-center md:left-1/2 md:w-[40vw] md:h-[6%] lg:mb-0 xl:mb-0 2xl:mb-0 lg:relative xl:relative 2xl:relative lg:items-center xl:items-center 2xl:items-center 2xl:-translate-x-1/2 xl:-translate-x-1/2 lg:-translate-x-1/2 lg:w-[350px] xl:w-[350px] 2xl:w-[350px] lg:h-[10%] xl:h-[10%] 2xl:h-[10%]'>
				<Button
					onClick={onClickCancel}
					variant='secondary'
					className='w-[100%] h-10 border-[#F97316] text-[#F97316]'
					disabled={isSaving}
				>
					Cancel
				</Button>
				<Button onClick={onClickSave} variant='primary' className='w-[100%] h-10 flex items-center justify-center gap-4' disabled={isButtonDisabled}>
					{isSaving && <Spinner className='' />}
					{isSaving ? 'Saving' : 'Save'}
				</Button>
			</div>

			<div className='bg-white rounded-lg mt-4 h-[720px] w-[350px] lg:h-[720px] lg:w-[350px] md:h-[600px] sm:h-[600px] max-sm:h-[600px]'>
				<div className='px-7 lg:px-7 lg:ml-0 md:ml-10 sm:ml-14 max-sm:px-16'>
					<div className='text-center text-[#464255] font-bold text-lg pt-4 pb-2'>
						App Preview
					</div>
					<div
						className={
							'w-full h-[600px] mt-8 rounded-3xl border-8 border-gray-600 relative bg-[#F7F7F7] 2xl:h-[600px] 2xl:w-full xl:h-[600px] xl:w-full lg:w-full lg:h-[600px] md:w-[95%] md:h-[450px] sm:w-[220px] sm:h-[430px] max-sm:w-[220px] max-sm:h-[430px] min-sm:w-[220px] min-sm:h-[430px] z-0'
							// 'w-full h-[600px] mt-8 rounded-3xl border-8 border-gray-600 relative bg-[#F7F7F7] lg:w-full lg:h-[600px] md:w-[95%] md:h-[450px] sm:w-[92%] sm:h-[430px] max-sm:w-[92%] max-sm:h-[420px] max-sm:items-center max-sm:left-1/2 max-sm:-translate-x-1/2'
						}
					>
						<div className='w-full flex justify-center absolute z-20'>
							<div className='rounded-lg bg-gray-600 w-[115px] h-[20px] -mt-1 flex justify-center items-center gap-2 '>
								<div className='rounded-lg bg-white w-[30px] h-[4px]' />
								<div className='rounded-full bg-white w-[4px] h-[4px]' />
							</div>
						</div>
						<AppPreviewComponent />
					</div>
				</div>
			</div>
		</div>
	);
};
export default AppPreview;