import { atom } from 'jotai';

export const  SSideBar = atom ([
	{id:0, label: 'Contact Us', isHidden: false, isSub: false},
	{id:0, label: 'Privacy and Policy', isHidden: false, isSub: false},
	{id:0, label: 'Terms of Use', isHidden: false, isSub: false},
	{id:0, label: 'Accessibility', isHidden: false, isSub: false},
]);


export const  STempSideBar = atom ([
	{id:0, label: 'Contact Us', isHidden: false, isSub: false},
	{id:0, label: 'Privacy and Policy', isHidden: false, isSub: false},
	{id:0, label: 'Terms of Use', isHidden: false, isSub: false},
	{id:0, label: 'Accessibility', isHidden: false, isSub: false}]);

export const SCountHidden 	   = atom<number>(0);
export const SCountSub    	   = atom<number>(0);
export const SIsSidebarRefresh = atom<boolean>(true);
export const SIsSidebarOpen	   = atom<boolean>(true);