import React, { useEffect, useState } from 'react';

import { ENVIRONMENT } from 'services/api';
import { useParams } from 'react-router-dom';

const PubishTabPreviewLink = () => {

	const { id } = useParams();
	const [pwaUrl, setPWAUrl] = useState('');

	useEffect(() => {
		if (ENVIRONMENT === 'development') {
			setPWAUrl('dapp.snaptoapp.com');
		} else if (ENVIRONMENT === 'test') {
			setPWAUrl('tapp.snaptoapp.com');
		} else {
			setPWAUrl('app.snaptoapp.com');
		}
	}, []);

	const textToCopy = `${pwaUrl}/preview/${id}`;

	return (
		<div className='h-auto text-[#707070]'>
			<div className='flex w-full items-center'>
				<div className='w-full cursor-pointer' onClick={() => window.open(`https://${textToCopy}`, '_blank')}>
					<input
						disabled
						className='
								py-3 
								w-full 								
								lg:text-base 
								px-[30px] 
								rounded-md 
								bg-[#F2F2F2] 
								text-[#171414] 
								font-medium 
								pointer-events-none
								underline
								border-2
								border-[#E6E4E4]
								max-sm:text-xs
								max-sm:px-[10px]'
						value={textToCopy}
						type={'text'}
					/>
				</div>
			</div>
		</div>
	);
};

export default PubishTabPreviewLink;