import { AppFeatIsSaving, SAppearanceID, SBackgroundID, SColorID, SFaviconID, SFlashScreenID, SFontID, SIsLayoutChanges, SIsThemeChanges, SIsThemeLayoutRefresh, SNavigationID, SOgTagsID, SSideBarID } from 'store/CustomizeApp/Appearance';
import { EditApperanceByAppIdParamsSchema, EditApperanceByAppIdResponse } from 'services/requests/appearance/schema';
import { useAtomValue, useSetAtom } from 'jotai';

import { AxiosError } from 'axios';
import HomeMutation from 'pages/CustomizeApp/Features/UseMutation/Home';
import { SLayoutId } from 'store/CustomizeApp/Appearance/layoutAtom';
import { SThemeId } from 'store/CustomizeApp/Appearance/themeAtom';
import { editAppearanceByAppID } from 'services/requests/appearance';
import { showFailedAlert } from 'utility/alerts';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

const ThemeLayoutMutation = () => {
	const { id } = useParams();
	const appearanceID = useAtomValue(SAppearanceID);
	const selectedFlashScreenID = useAtomValue(SFlashScreenID);
	const selectedThemeID = useAtomValue(SThemeId);
	const selectedLayoutID = useAtomValue(SLayoutId);
	const selectedBackgroundID = useAtomValue(SBackgroundID);
	const selectedNavigationID = useAtomValue(SNavigationID);
	const selectedSideBarID = useAtomValue(SSideBarID);
	const selectedColorID = useAtomValue(SColorID);
	const selectedFontID = useAtomValue(SFontID);
	const selectedFaviconID = useAtomValue(SFaviconID);
	const selectedOgTagsID = useAtomValue(SOgTagsID);
	const setThemeLayoutRefresh = useSetAtom(SIsThemeLayoutRefresh);
	const setIsSaving = useSetAtom(AppFeatIsSaving);
	const isThereChangesLayout = useAtomValue(SIsLayoutChanges);
	const isThereChangesTheme = useAtomValue(SIsThemeChanges);
	const {saveHome} = HomeMutation();
	//Edit Appearance
	const { mutate: editAppearance } = useMutation<
		EditApperanceByAppIdResponse,
		AxiosError,
		EditApperanceByAppIdParamsSchema
	>((data) => editAppearanceByAppID(id, appearanceID, data), {
		onSuccess: () => {
			setThemeLayoutRefresh(true);
			saveHome();
		},
		onError: () => {
			setIsSaving(false);
			showFailedAlert('Cannot save appearances, please try again.');
		},
	});

	const saveThemeLayout = () => {
		if(isThereChangesLayout || isThereChangesTheme){
			editAppearance({
				welcomeScreenID: selectedFlashScreenID,
				layoutID: selectedLayoutID,
				themeID: selectedThemeID,
				backgroundID: selectedBackgroundID,
				navigationID: selectedNavigationID,
				sidebarID: selectedSideBarID,
				colorSchemeID: selectedColorID,
				fontSettingID: selectedFontID,
				// faviconID: selectedFaviconID,
				// OGTagID: selectedOgTagsID,
			});
		}else saveHome();

	};

	return {saveThemeLayout,};
    
};

export default ThemeLayoutMutation;