import { FContact, FContactID, FIsContactRefresh } from 'store/CustomizeApp/Features/contact';
import { GetContactFormResponse, GetContactFormType } from 'services/requests/Features/Contact/schema';
import { createContact, editContactByAppID } from 'services/requests/Features/Contact';
import { useAtomValue, useSetAtom } from 'jotai';

import { AppFeatIsSaving } from 'store/CustomizeApp/Appearance';
import { AxiosError } from 'axios';
import GalleryMutation from '../Gallery';
import React from 'react';
import { showFailedAlert } from 'utility/alerts';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { FIsContactChanges } from 'store/CustomizeApp/Features';

const ContactMutation = () => {
	const { id } = useParams();
	//Atoms for Contact
	const contactDesc = useAtomValue(FContact);
	const contactID = useAtomValue(FContactID);
	const setIsContactRefresh = useSetAtom(FIsContactRefresh);
	const setIsSaving = useSetAtom(AppFeatIsSaving);
	const isThereChanges = useAtomValue(FIsContactChanges);
	const {saveGallery} = GalleryMutation();
	//Create Contact
	const { mutate: createContactMU } = useMutation<
		GetContactFormResponse,
		AxiosError,
		GetContactFormType
	>((data) => createContact(id, data), {
		onSuccess: () => {
			setIsContactRefresh(true);
			saveGallery();
		},
		onError: () => {
			setIsSaving(false);
			showFailedAlert('Cannot save contact');
		},
	});

	//Put Contact
	const { mutate: editContact } = useMutation<
		GetContactFormResponse,
		AxiosError,
		GetContactFormType
	>((data) => editContactByAppID(id, contactID, data), {
		onSuccess: () => {
			setIsContactRefresh(true);
			saveGallery();
		},
		onError: () => {
			setIsSaving(false);
			showFailedAlert('Cannot edit contact.');
		},
	});

	const updateContact = () => {
		if (id)
			editContact({
				description: contactDesc,
				isTurnedOn: true,
			});
	};

	const saveContact = () => {
		if(isThereChanges){
			if (contactID) {
				updateContact();
			} else {
				createContactMU({ description: contactDesc, isTurnedOn: true });
			}
		}else saveGallery();
		
	};   

	return {saveContact,};
};

export default ContactMutation;