import React, { InputHTMLAttributes } from 'react';
import { useAtom } from 'jotai';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';

import { classNames } from '../../../../utility';
import { setGlobalVariableAtom, globalVariableAtom } from '../../../../store/authAtom';

interface IProps {
	type: string;
    name?: string;
    disabled?: boolean;
    value?: string;
    onChange?: (value: string) => void;
    placeholder?: string;
    className?: string;
    error?: string;
	label?: string;
    rows?: number;
	maxLength?: number;
}

const FormInputTextArea = (props: IProps) => {
	const [value, setValue] = React.useState(props.value || '');
	const [globalVariable, setGlobalVariable] = useAtom(globalVariableAtom);
	

	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setValue(event.target.value);
		props.onChange && props.onChange(event.target.value);
		if(value.length < 1){
			setGlobalVariable('initial value');
		}
	};
	
	React.useEffect(() => {
		setValue(props.value || '');
		
	}, [props.value]);

	

	return(
		<>
			<label className='text-[#707070] text-xs'>{props.label}</label>
			<div className='relative shadow-none'>
				<textarea
					name={props.name}
					value={value}
					rows={props.rows}
					placeholder={props.placeholder}
					onChange={handleChange}
					className={classNames(
						props.className ? props.className : '',
						props.error ? 'border-[#DC3545]' : 'border-gray-300 bg-white',
						'text-black w-full rounded-md border py-1.5 shadow-none',
						'pl-2 focus:ring-1 focus:border-input-active outline-none'
					)}
					maxLength={props.maxLength}
				>
				</textarea>	
				{ 			
				props.maxLength? 
					<p className='absolute bottom-2 right-1 text-xs text-gray-600 w-full bg-transparent text-end px-2'>
						{props.value?.length}/{props.maxLength}
					</p> 
				: 
				<></>
				}
			</div>
			{props.error && (
				<p className='text-[#DC3545] text-xs pl-1 py-1'>{props.error}</p>
			)}
		</>
	);
};

export default FormInputTextArea;