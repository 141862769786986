import React, { useState } from 'react';
import FormInput from 'components/Common/Forms/Inputs';
import { Button } from 'components/Common/Buttons';
import { TrashIcon } from '@heroicons/react/24/outline';
import { PlusSmallIcon } from '@heroicons/react/24/outline';
import { IMAGES, stripeCardExpiry, stripeCardMaskingBranded } from 'utility';
import DeleteCreditCard from 'components/CardsManagement/DeleteModal';
import { PaymentFormModal } from 'components/Common/Modals/PaymentFormModal';
import { showDeleteModal, showModal, toDeletePaymentMethodId } from 'store/EditProfile/cardManagement';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { payFormModal, paySaving } from 'store/Payment/successPaymentAtom';
import { paymentMethodID } from 'store/CustomizeApp/Payment/subscription';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AddCardRequest, CardResponse, DeleteCardRequest, DeleteCardResp, DeleteCardResponse, GetListCardResponse, SetDefaultPaymentMethodRequest, SetDefaultPaymentMethodResponse} from 'services/requests/EditProfile/CardManagement/schema';
import {  AxiosError } from 'axios';
import { addCard, deleteCard, getListCards, setDefaultCard } from 'services/requests/EditProfile/CardManagement';
import { showFailedAlert, showSuccessAlert } from 'utility/alerts';
import { LoadCardList } from './LoadCardList';
import { log } from 'utility/log';

type FormDataType = {
	cardHolderName: string;
	cardNumber: string;
	expiryDate: string;
	isDefault: boolean;
};

const CardManagementPanel: React.FC = () => {
	//const [showDeleteCardModal, setShowDeleteCardModal] = useState<boolean>(false);
	const PaymentMethodID				 = useAtomValue(paymentMethodID);
	const [toDeletePmId, setToDeletePmId] = useAtom(toDeletePaymentMethodId);
	const [showDeleteConfirm, setShowDeleteConfirm] = useAtom(showDeleteModal);
	
	const [formShow, setFormShow]       = useAtom(payFormModal);
	const setIsSaving					= useSetAtom(paySaving);
	const queryClient = useQueryClient();
	
	const {data: ListCards, isFetching: fetchCards} = useQuery(['ListCards'], getListCards);

	const { mutate: addCardMu, } = 
		useMutation<CardResponse, AxiosError, AddCardRequest>(
			data => addCard(data), {
				onSuccess: (res) => {
					queryClient.invalidateQueries(['ListCards']);
					log('add card success!',res);
					setFormShow(!formShow);
					setIsSaving(false);
				},
				onError: (err) => {
					log('error adding card: ', err);
					setFormShow(!formShow);
					setIsSaving(false);
				}
			}
		);

	const { mutate: deleteCardMu, isLoading: loadDelete } = 
		useMutation<DeleteCardResponse,AxiosError,string>(
			(pmId) => deleteCard(pmId), {
				onSuccess: () => {
					queryClient.invalidateQueries(['ListCards']);
					setShowDeleteConfirm(false);
					showSuccessAlert('Card has been deleted successfully.');
				},
				onError: (err) => {
					log('error:', err);
					showFailedAlert('Error: ' + err);
				}
			}
			
		);	

	const { mutate: setDefaultCardMu } = 
		useMutation<SetDefaultPaymentMethodResponse, AxiosError, SetDefaultPaymentMethodRequest>(
			data => setDefaultCard(data), {
				onSuccess: () => {
					queryClient.invalidateQueries(['ListCards']);
					showSuccessAlert('New default payment method is set.');
				},
				onError: (err) => {
					showFailedAlert('Error in setting new payment method.');
					log('Error:', err);
				}
			}	
		);

	const handleShowModal = () => {
		setFormShow(!formShow);
	};

	const handleSaveModal = (pmId: string, cardName: string) => {
		log('payment method Id: ', pmId);
		log('cardName: ', cardName);
		addCardMu({
			paymentMethodId: pmId,
			cardHolderName: cardName
		});
		setFormShow(!formShow);
	};

	
	const DeleteCard = (pmId: string) => {
		log('delete pm: ', pmId);
		deleteCardMu(pmId);
	};

	const handleOnClickDelete = (pmId: string) => {
		log('delete button clicked!', pmId);
		setToDeletePmId(pmId);
		setShowDeleteConfirm(true);
	};

	const HandleSetDefaultCard = (pmId:string) => {
		setDefaultCardMu({
			paymentMethodId: pmId,
		});
	};
	
	return (
		<div className='w-full h-full p-10'>
			<div className='flex flex-row items-center justify-between pb-4'>
				<div className='text-[#707070] text-[18px] font-bold self-start'>
					Cards Management
				</div>
				<div className='-mt-[5px] mb-[-6px] self-start '>
					{ListCards?.CardPaymentMethods?.length === 0 ? (
						''
					) : (
						<Button
							className='bg-[#E0781D] px-[20px] py-1 text-[15px] font-semibold
       							flex items-center'
							variant='primary'
							onClick={handleShowModal}
						>
							<PlusSmallIcon className='w-4 h-4 font-[20px]' />
							<span>Add Card</span>
						</Button>
					)}
				</div>
			</div>
			{/* Table */}
			<div className='w-full h-full pb-8 border-t-[1px]'>
				{ListCards?.CardPaymentMethods?.length === 0 ? (
					<div className='flex flex-col items-center justify-end w-full h-full'>
						<img
							className='w-[260px] h-[260px]'
							src={IMAGES.addCreditCard}
							alt='Landing Image'
						/>
						<span className='font-bold'>
							You haven&apos;t set up any payment options yet.
						</span>
						<Button
							className='bg-[#E0781D] px-[20px] py-1 text-[15px] mt-4 font-semibold
       							flex items-center'
							variant='primary'
							onClick={handleShowModal}
						>
							<PlusSmallIcon className='w-4 h-4 font-[20px]' />
							<span>Add Card</span>
						</Button>

					</div>
				) : (
					<div className=' h-full w-full overflow-x-auto shadow-md sm:rounded-lg mt-[13.5px]'>
						<table className='w-full text-sm text-left text-gray-500'>
							<thead className='text-xs top-0 sticky text-gray-700 uppercase bg-[#F7F9FB]'>
								<tr>
									<th scope='col' className='px-6 py-3'>
										Cardholder Name
									</th>
									<th scope='col' className='px-6 py-3'>
										Card Number
									</th>
									<th scope='col' className='px-6 py-3'>
										Expiry Date
									</th>
									<th scope='col' className='px-6 py-3'></th>
									<th scope='col' className='px-4 py-3'>
										Actions
									</th>
								</tr>
							</thead>
							{fetchCards ?
								<div>
									<LoadCardList/>
								</div>	 : 
								<tbody className=' overflow-hidden'>
									{ListCards?.CardPaymentMethods?.map((data: CardResponse, index: number) => (
										<tr key={data.Id} className='bg-white border-b text-black '>
											<td scope='row' className='px-6 py-4 font-medium whitespace-nowrap'>
												{data.CardHolderName}
											</td>
											<td className='px-6 py-4'>
												{stripeCardMaskingBranded(data.Last4,data.Brand)}
											</td>
											<td className='px-6 py-4'>{stripeCardExpiry(data.ExpMonth,data.ExpYear)}</td>
											<td className='px-6 py-4 whitespace-nowrap text-right text-sm font-medium'>
												<button
													className='text-red-600 hover:text-red-900'
													onClick={() => handleOnClickDelete(data.Id)}
												>
													<TrashIcon className='w-5 h-5' />
												</button>
											</td>
											<td className='px-4 py-4'>
												{data.IsDefault ? (
													<Button className='text-[16px] bg-[#E0781D] font-semibold text-white py-[4px] px-[12px] transition duration-100 ease-in'>
													Default
													</Button>
												) : (
													<Button
														className='text-[16px] font-semibold text-[#E0781D] py-[4px] px-[12px] border-[#E0781D] transition duration-100 ease-out'
														onClick={() => HandleSetDefaultCard(data.Id)}
													>
													Set as Default
													</Button>
												)}
											</td>
										</tr>
									))}
								</tbody>
							}
						</table>	
					</div>
				)}
			</div>
			<PaymentFormModal 
				isPaymentModalShow={formShow} 
				setPaymentModalShow={handleShowModal} 
				onClickCancel={handleShowModal} 
				onClickSave={handleSaveModal}
			/>
			
			<DeleteCreditCard 
				onConfirm={DeleteCard}
				isDeleteCardModalShow={showDeleteConfirm}
				disable={loadDelete}
			/>
			
		</div> 
	);
};

export default CardManagementPanel;
