import { AppFeatIsSaving, SColorID, SFlashScreenID, SFontID, SIsFlashChanges, SNavigationID, SSideBarID } from 'store/CustomizeApp/Appearance';
import { EditWelcomeScreenByAppIdParamsSchema, EditWelcomeScreenByAppIdResponse, UploadFlashScreenCustomBackgroundByAppIDParamasSchema, UploadFlashScreenCustomBackgroundByAppIDResponse, UploadFlashScreenLogoByAppIDParamasSchema, UploadFlashScreenLogoByAppIDResponse } from 'services/requests/appearance/flash_screen/schema';
import { SBackgroundColor, SBackgroundColorOptions, SBlurBackgroundImage, SCustomBackgroundID, SCustomBgFocus, SFlashBackgroundID, SFlashCustomBackgroundFile, SFlashLogoFile, SIsFlashRefresh, SLogoID, SOpacityFlashBackgroundImage, SOverlayColor, SOverlayValue } from 'store/CustomizeApp/Appearance/flashScreenAtom';
import { editFlashScreenByAppID, uploadFlashScreenCustomBackgroundByAppID, uploadFlashScreenLogoByAppID } from 'services/requests/appearance/flash_screen';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { AxiosError } from 'axios';
import BackgroundMutation from '../Background';
import { showFailedAlert } from 'utility/alerts';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

const FlashScreenMutation = () => {
	const { id } = useParams();
	const [flashLogoID, setFlashLogoID]         = useAtom(SLogoID);
	const [flashCustomBgID, setFlashCustomBgID] = useAtom(SCustomBackgroundID);
	const selectedFlashScreenID                 = useAtomValue(SFlashScreenID);
	const flashBackgroundColor                  = useAtomValue(SBackgroundColor);
	const flashBackgroundColorOptions			= useAtomValue(SBackgroundColorOptions);
	const flashOverlayColor                     = useAtomValue(SOverlayColor);
	const flashOverlayOpacity                   = useAtomValue(SOverlayValue);
	const flashBackgroundImageOpacity           = useAtomValue(SOpacityFlashBackgroundImage);
	const flashBackgroundImageBlur              = useAtomValue(SBlurBackgroundImage);
	const flashBackgroundID                     = useAtomValue(SFlashBackgroundID);
	const isFlashCustomBgFocus                  = useAtomValue(SCustomBgFocus);
	const flashCustomBackgroundFile             = useAtomValue(SFlashCustomBackgroundFile);
	const flashLogoFile                         = useAtomValue(SFlashLogoFile);
	const setFlashRefresh  						= useSetAtom(SIsFlashRefresh);
	const [isSaving, setIsSaving] 	   			= useAtom(AppFeatIsSaving);
	const isThereChanges   						= useAtomValue(SIsFlashChanges);
	const {saveBackground} 						= BackgroundMutation();

	//upload Flash Logo
	const {mutate: uploadLogo ,isSuccess: isUploadFlashLogoSuccess, reset: resetSuccessLogo} = useMutation<
	UploadFlashScreenLogoByAppIDResponse,
	AxiosError,
	UploadFlashScreenLogoByAppIDParamasSchema
	>((data) => uploadFlashScreenLogoByAppID(id, data.file), {
		onSuccess: (data) => {
			setFlashLogoID(data.ImageUpload.imageUploadID);
		},
		onError: () => {
			setIsSaving(false);
			showFailedAlert('Cannot upload logo.');
		},
	});

	//UploadCustomBackground
	const {
		mutate: uploadCustomBackground,
		isSuccess: isUploadFlashCustomBackgroundSuccess,
	} = useMutation<
		UploadFlashScreenCustomBackgroundByAppIDResponse,
		AxiosError,
		UploadFlashScreenCustomBackgroundByAppIDParamasSchema
	>((data) => uploadFlashScreenCustomBackgroundByAppID(id, data.file), {
		onSuccess: (data) => {
			setFlashCustomBgID(data.ImageUpload.imageUploadID);
		},
		onError: () => {
			setIsSaving(false);
			showFailedAlert('Cannot upload flash background.');
		},
	});

	//Edit Flash Screen
	const { mutate: editWelcomeScreen } = useMutation<
		EditWelcomeScreenByAppIdResponse,
		AxiosError,
		EditWelcomeScreenByAppIdParamsSchema
	>((data) => editFlashScreenByAppID(id, selectedFlashScreenID, data), {
		onSuccess: () => {
			setFlashRefresh(true);
			saveBackground();
		},
		onError: () => {
			setIsSaving(false);
			showFailedAlert('Cannot save flash screen, please try again.');
		},
	});

	const updateFlashCustomBackground = () => {
		if(flashCustomBackgroundFile){
			uploadCustomBackground({
				file: flashCustomBackgroundFile,
			});
		}else updateFlashScreen();
	};
    
	const updateFlashScreen = () => {
		if (id)
			editWelcomeScreen({
				appID: parseInt(id),
				logoID: flashLogoID,
				uploadedBackgroundID: isFlashCustomBgFocus ? flashCustomBgID : 0,
				defaultBackgroundID: flashBackgroundID,
				overlayColor: flashOverlayColor,
				overlayOpacity: flashOverlayOpacity,
				backgroundColor: flashBackgroundColor,
				backgroundColorOptions: flashBackgroundColorOptions,
				backgroundOpacity: flashBackgroundImageOpacity,
				blur: parseFloat(flashBackgroundImageBlur),
			});
	};
    
	const saveFlashScreen = () => {
		if(isThereChanges){
			if(flashLogoFile){
				uploadLogo({
					file: flashLogoFile
				});
			}else updateFlashCustomBackground();
		}else saveBackground();
		
	};

	useEffect(() => {
		if (!isSaving)
			resetSuccessLogo();
	}, [isSaving]);

	useEffect(() => {
		if (flashLogoFile && isUploadFlashLogoSuccess)
			updateFlashCustomBackground();
	}, [flashLogoFile, isUploadFlashLogoSuccess]);

	useEffect(() => {
		if (flashCustomBgID && isUploadFlashCustomBackgroundSuccess)
			updateFlashScreen();
	}, [flashCustomBgID, isUploadFlashCustomBackgroundSuccess]);

	return {saveFlashScreen,};
    
};

export default FlashScreenMutation;