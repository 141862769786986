import { AppFeatIsSaving, SAppName, SAppNameChanges, SIsAppRefresh } from 'store/CustomizeApp/Appearance';
import { UpdateAppResponse, UpdateAppSchema } from 'services/requests/apps/schema';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useMutation, useQueryClient } from 'react-query';

import { AxiosError } from 'axios';
import FlashScreenMutation from '../FlashScreen';
import React from 'react';
import { SIndustrySubCategory } from 'store/CustomizeApp/Appearance/flashScreenAtom';
import { editAppByID } from 'services/requests/apps';
import { showFailedAlert } from 'utility/alerts';
import { useParams } from 'react-router-dom';

const AppMutation = () => {

	const { id } = useParams();
	const appName = useAtomValue(SAppName);
	const subcategoryID = useAtomValue(SIndustrySubCategory);
	const setIsSaving = useSetAtom(AppFeatIsSaving);
	const [isThereChanges, setIsThereChanges] = useAtom(SAppNameChanges);
	
	const setIsAppRefresh = useSetAtom(SIsAppRefresh);
	const {saveFlashScreen} = FlashScreenMutation();

	const queryClient = useQueryClient();
	//Edit App Name
	const { mutate: editApp } = useMutation<
		UpdateAppResponse,
		AxiosError,
		UpdateAppSchema
	>((data) => editAppByID(id, data), {
		onSuccess: () => {
			queryClient.invalidateQueries('pwa-apps');
			setIsAppRefresh(true);
			saveFlashScreen();
		},
		onError: () => {
			setIsSaving(false);
			setIsThereChanges(false);
			showFailedAlert('Please type another app name.');
		},
	});
    
	const saveApp = () => {
		setIsSaving(true);
		if(isThereChanges){
			if (appName.length < 1 || !appName.trim()) {
				setIsSaving(false);
				setIsThereChanges(false);
				showFailedAlert('Please input an app name.');
			} else {
				if(id){
					editApp({
						subcategoryID: subcategoryID,
						name: appName,
					});
				}
			}
		}else saveFlashScreen();
		
	};

	return {saveApp,};
};

export default AppMutation;