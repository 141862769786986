import { CreateEditStoreType, StoreResponse } from 'services/requests/Features/Store/schema';
import { FIsStoreRefresh, FStore, FStoreID } from 'store/CustomizeApp/Features/storeAtom';
import { createStoreApi, editStoreApi } from 'services/requests/Features/Store';
import { useAtomValue, useSetAtom } from 'jotai';

import AboutMutation from '../About';
import { AppFeatIsSaving } from 'store/CustomizeApp/Appearance';
import { AxiosError } from 'axios';
import React from 'react';
import { showFailedAlert } from 'utility/alerts';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { FIsStoreChanges } from 'store/CustomizeApp/Features';

const StoreMutation = () => {
	const { id } = useParams();
	const storeName         = useAtomValue(FStore);
	const storeID           = useAtomValue(FStoreID);
	const setIsStoreRefresh = useSetAtom(FIsStoreRefresh);
	const setIsSaving = useSetAtom(AppFeatIsSaving);
	const isThereChanges = useAtomValue(FIsStoreChanges);
	const {saveAbout} = AboutMutation();
	//Create Store
	const { mutate: storeFormMu } = useMutation<
		StoreResponse,
		AxiosError,
		CreateEditStoreType
	>((data) => createStoreApi(id, data), {
		onSuccess: () => {
			setIsStoreRefresh(true);
			saveAbout();
		},
		onError: () => {
			setIsSaving(false);
			showFailedAlert('Cannot save store.');
		},
	});
    
	//Edit Store
	const { mutate: editStore } = useMutation<
		StoreResponse,
		AxiosError,
		CreateEditStoreType
	>((data) => editStoreApi(id, storeID, data), {
		onSuccess: () => {
			setIsStoreRefresh(true);
			saveAbout();
		},
		onError: () => {
			setIsSaving(false);
			showFailedAlert('Cannot edit store.');
		},
	});

	const updateStore = () => {
		if (id)
			editStore({
				storeName: storeName,
				isTurnedOn: true,
			});
	};
    
	const saveStore = () => {
		if(isThereChanges){
			if (storeID) {
				updateStore();
			} else {
				storeFormMu({ storeName: storeName, isTurnedOn: true });
			}
		}else saveAbout();
		
	};

	return {saveStore,};
};

export default StoreMutation;