export const API_URL = import.meta.env.VITE_API_URL;
export const STRIPE_PUBLISHABLE_KEY = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY;
export const STRIPE_SECRET_KEY = import.meta.env.VITE_STRIPE_SECRET_KEY;
export const ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT;

//auth
export const LOGIN = '/auth/login'; //POST
export const USER_CREDENTIALS = '/auth/userCredentials'; //GET //auth/userCredentials/:email
export const ADD_PASSWORD = '/auth/addPassword'; //PUT
export const REMOVE_FEDERATED_IDENTITY = '/auth/removeFederatedIdentity'; //DELETE
export const SEND_VERIFICATION_CODE = '/auth/sendVerificationCode'; //POST
export const VALIDATE_VERIFICATION_CODE = '/auth/validateVerificationCode'; //POST

//org
export const APP = '/organizations/apps'; //GET, POST
export const APP_BY_ID = '/organizations/apps/:id'; //GET,PUT,DELETE


//billing
export const PAYMENT_CARD_ATTACH = '/billing/payment/card/attach'; //POST
export const PAYMENT_CARD = '/billing/payment/card'; //GET
export const PAYMENT_CARD_DELETE = '/billing/payment/card'; //DELETE /billing/payment/card/:paymentMethodId
export const PAYMENT_CARD_DEFAULT = '/billing/payment/default'; // GET //PUT


//notif
