import React,{ useState } from 'react';
import { IMAGES } from 'utility';
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { useQuery } from 'react-query';
import { getAllIndustry } from 'services/requests/industry';
import { getUserById } from 'services/requests/user';
import { formatDate } from 'utility';
import { ApproveModal } from 'components/Common/Modals/ApproveModal';
import { getAllUsers } from 'services/requests/user';
import { capitalizeWords } from 'utility';
interface App {
	appID?: number;
	companyTenantID?: string;
	appTenantID?: string;
	industryID?: number;
	name?: string;
	logoUrl?: string;
	websiteUrl?: string;
	status?: string;
	whoUpdated?: number;
	whenSubmitted?: string;
	whenReviewed?: string;
  }
  
  interface AppList {
	app: App[] | undefined;
  }

const Rejected: React.FC<AppList> = ({ app }) => {
	const [appIds, setAppIds ] = useState<any>();
	const [showDetails, setShowDetails] = useState(null);
	const [ approveModalShow, setApproveModalShow ] = useState(false);

	const livWebsites = app?.filter((website) => website.status === 'REJ');
	const { data } = useQuery('industry', getAllIndustry);
	const { data: userData } = useQuery('users', getAllUsers);

	const handleDetailsClick = (id : any) => {
		setShowDetails(showDetails === id ? null : id);
	};

	const handleClick = (items : any) => {
		setApproveModalShow(!approveModalShow);
		setAppIds(items);
	};

	return(
		<div className='relative flex-col bg-white w-full h-[60vh] shadow-md p-5'>
			<ApproveModal isApproveModalShow={approveModalShow} setApproveModalShow={handleClick} onClickCancel={handleClick} appID={appIds} />
			<div className='relative flex flex-col'>
				{/* <form className='absolute right-4 bg-white rounded-[9px] w-[250px]'>
					<input className='w-full h-full block rounded-[5px] text-xs py-[8px] pl-[15px] pr-[41px] border focus:ring-1 focus:border-input-active outline-none' type='search' placeholder='Search..' />
					<img className='flex absolute top-2 right-3 cursor-pointer' src={IMAGES.SearchIcon} alt='Search Icon' />
				</form> */}
				<div className='mt-2'>
					<div className='flex flex-col'>
						<div className='overflow-x-auto sm:-mx-6 lg:-mx-8'>
							<div className='inline-block min-w-full py-2 sm:px-6 lg:px-8'>
								<div className='h-[50vh] overflow-y-auto'>
									<table className='min-w-full text-left text-sm font-light'>
										<thead
											className='border-b bg-white font-medium text-xs'>
											<tr>
												<th scope='col' className='px-2 py-2'>App Name</th>
												<th scope='col' className='px-6 py-2'>Created by</th>
												<th scope='col' className='px-6 py-2'>App Industry</th>
												<th scope='col' className='px-6 py-2'>Date Rejected</th>
												<th scope='col' className='px-6 text-start py-2'>Link</th>
												<th scope='col' className='px-12 text-center py-2'>ACTIONS</th>
											</tr>
										</thead>
										<tbody className='border-b bg-white font-medium text-xs'>
											{livWebsites?.map((app, index) => (
												<React.Fragment key={app.appID}>
													<tr key={app.appID} className={`py-2 ${index % 2 === 0 ? 'bg-gray-100 shadow-sm' : 'bg-white'}`}>
														<td className='px-2 py-2'>{app.name}</td>
														{userData?.user.filter((item : any) => item.userID === app.whoUpdated).map((item : any, key : any) => (	
															<td key={key} className='px-6 py-2 text-[#3C5491]'>{capitalizeWords(item.firstName + ' ' + item.lastName)}</td>
														))}
														{data?.rows.filter((item) => item.industryID === app.industryID).map((item, key) => (	
															<td key={key} className='px-6 py-2 text-[#3C5491]'>{item.name}</td>
														))}
														<td className='px-6 py-2'>{formatDate(app.whenReviewed)}</td>
														<td className='px-6 text-start py-2 text-[#3C5491] underline'>
															<a  id='nav-link' href={app.websiteUrl} target='_blank' rel='noreferrer'>
																{app.websiteUrl}
															</a>
														</td>
														<td className='px-6 flex text-center py-2 font-normal text-xs'>
															<button onClick={() => handleClick(app.appID)} className='ml-2 px-2 bg-[#3EAB45] text-white p-1'>Approve</button>
															<button className='ml-2 px-2' onClick={() => handleDetailsClick(app.appID)}>
																{showDetails === app.appID && showDetails !== null ?
																	<ChevronDownIcon className='w-4 h-4' />
																	:
																	<ChevronRightIcon className='w-4 h-4' />
																}
																
															</button>
														</td>
													</tr>
													{showDetails === app.appID && (
														<>
															<tr className={`${index % 2 === 0 ? 'bg-gray-100 shadow-sm' : 'bg-white'} h-[6vh] border-t`}>
																<td colSpan={2} className='px-2 text-[#000000]'>
																	<span>Rejection Notes:</span>
																</td>
																<td colSpan={2} className='px-2 text-[#000000]'>
																	<span>Rejected by: 
																		{userData?.user.filter((item : any) => item.userID === app.whoUpdated).map((item : any, key : any) => (	
																			<td key={key} className='px-2 py-2 text-[#3C5491]'>{capitalizeWords(item.firstName + ' ' + item.lastName)}</td>
																		))}
																	</span>
																</td>
																<td colSpan={4} className='px-2 text-right text-[#000000]'>
																	<span>Date Created: {formatDate(app.whenSubmitted)}</span>
																</td>
															</tr>
															<tr className={`${index % 2 === 0 ? 'bg-gray-100 shadow-sm' : 'bg-white'} w-full h-[8vh] border-b`}>
																<td colSpan={6} className='px-2 w-full text-[#000000]'>
																	<div className={`w-full ${index % 2 === 0 ? 'bg-white shadow-sm border' : 'bg-gray-100 shadow-sm border'} bg-white h-[5vh] p-2`}>
																		Sample rejection notes.
																	</div>
																</td>
															</tr>
														</>
													)}
												</React.Fragment>
											))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};


export default Rejected;