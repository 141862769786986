import z from 'zod';

export const GetContentSchema = z.object({
	id: z.number().optional(),
	icon: z.string(),
	title: z.string(),
	body: z.string(),
});

export const SchemaForAllContent = z.object({ 
	contentID: z.number(),
	companyTenantID: z.string(),
	appTenantID: z.string(),
	appID: z.string(),
	icon: z.string(),
	title: z.string(),
	body: z.string(),
	status: z.string(),
	whoAdded: z.number(),
	whoUpdated: z.number(),
	whenAdded: z.string(),
	whenUpdated: z.string()
});

export const EditCotentByAppIdResponse = z.object({
	rows: z.array(
		SchemaForAllContent
	)
});

export type GetContentFormType = z.infer<typeof GetContentSchema>;
export type GetContentFormResponse = z.infer<typeof EditCotentByAppIdResponse>;
export type DeleteContentFormResponse = z.infer<typeof SchemaForAllContent>;
