import { GetAllThemeResponse, GetThemeResponse } from 'services/requests/appearance/theme/schema';
import { SThemeId, SThemeType } from 'store/CustomizeApp/Appearance/themeAtom';
import { useAtom, useSetAtom } from 'jotai';

import { AxiosError } from 'axios';
import { IMAGES } from 'utility/constant';
import React from 'react';
import { classNames } from 'utility';
import { getAllTheme } from 'services/requests/appearance/theme';
import { useQuery } from 'react-query';
import { SLayoutType } from 'store/CustomizeApp/Appearance/layoutAtom';
import Tooltip from 'components/Common/Tooltip';

const ThemePage: React.FC = () => {

	const setThemeType = useSetAtom(SThemeType);
	const [themeID, setThemeID] = useAtom(SThemeId);

	const [layoutType] = useAtom(SLayoutType);

	const onClickTheme = (theme: GetThemeResponse) => {
		setThemeType(theme.theme.name);
		setThemeID(theme.theme.themeID);
	};

	const {data} = useQuery<GetAllThemeResponse, AxiosError>({
		queryKey: ['themes'],
		queryFn: () => getAllTheme(),
		retry: 0
	});

	function filterTheme(theme: GetThemeResponse) {
		if (layoutType === 'Matrix') {
			return theme.theme.name != 'Solid';
		}
		else if (layoutType === 'Buttons and Side Bar') {
			return theme.theme.name != 'Borderless';
		}
	}
	
	return (
		<div className='bg-white rounded-lg my-8 mr-6 w-full '>
			<div className='w-full rounded px-8 2xl:px-8 xl:px-8 lg:px-8 md:px-16 sm:px-8 max-sm:px-8'>
				<div className='py-5 flex flex-col max-sm:items-center'>
					<div className='flex justify-between pt-2 2xl:justify-between 2xl:flex-row xl:justify-between xl:flex-row lg:flex-row lg:justify-between md:flex-row md:justify-between sm:flex-col-reverse sm:justify-between max-sm:flex-col-reverse max-sm:justify-between'>
						<span className='text-[#707070] font-bold text-2xl block max-sm:text-center'>Theme</span>
						<div className='w-full flex gap-3 items-baseline sm:justify-end max-sm:justify-end max-sm:items-end max-sm:w-[70vw]'>
							{/* -----------Video Tutorial and Help Icons------------ */}
							<Tooltip className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
								<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.CameraIcon} alt='camera icon' />
							</Tooltip>
							<Tooltip className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
								<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.InfoIcon} alt='info icon' />
							</Tooltip>
							{/* ---------------------------------------------------- */}

						</div>
					</div>
					<div className='w-[600px] flex py-5 grid 2xl:grid-cols-3 2xl:flex-row 2xl:w-[600px] xl:grid-cols-3 xl:gap-5 xl:w-[600px] lg:grid-cols-3 lg:gap-5 lg:w-[600px] md:gap-5 md:grid-cols-3 md:w-[600px] sm:grid-cols-3 sm:gap-1 sm:w-[550px] max-sm:gap-5 max-sm:w-[170px] max-sm:mt-10 '>
						{data?.rows
							.filter((theme) => filterTheme({theme: theme}))
							.map((theme, idx) => (
								<div key={idx}>
									<img onClick={() => onClickTheme({theme: theme})} className={classNames('w-[180px] h-[319px]', themeID === theme.themeID ? 'outline outline-8 outline-primary rounded' : '')} src={layoutType === 'Buttons and Side Bar' ? theme.imageURL : theme.matrixImageURL} alt='theme' />
									<div className='text-xs font-medium text-primary text-center pt-2'> {theme.name} </div>
								</div>
							))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ThemePage;