import { Controller, useForm } from 'react-hook-form';
import { GetResellerFormResponse, GetResellerFormSchema, GetResellerFormType } from 'services/requests/ResellerForm/schema';
import React, { useEffect, useLayoutEffect, useState } from 'react';

import { AxiosError } from 'axios';
import Button from 'components/Common/Buttons/Button';
import FormInput from 'components/Common/Forms/Inputs';
import FormInputTextArea from 'components/Common/Forms/TextArea';
import ReCAPTCHA from 'react-google-recaptcha';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { countryList } from 'utility';
import { log } from 'utility/log';
import { resellerFormApi } from 'services/requests/ResellerForm';
import { showSuccessAlert } from 'utility/alerts';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';

export const ResellerPage = () => {
	const navigate = useNavigate();

	useLayoutEffect(() => {
		document.body.style.backgroundColor = '#f6f6f6';
	});

	const { control, handleSubmit, formState } = useForm<GetResellerFormType>({
		defaultValues: {name: '', email: '', phoneNumber: '', message: ''},
		mode: 'onBlur',
		resolver: zodResolver(GetResellerFormSchema)
	});

	const {
		mutate: resellerFormMU,
	} = useMutation<GetResellerFormResponse, AxiosError, GetResellerFormType>(
		data => resellerFormApi(data), {
			onSuccess: (data) => {
				log(data);
				showSuccessAlert('Message successfully sent!');
				setTimeout(() => {
					navigate('/');
				},500);
			},
			onError: (error) => {
				log(error);
			}
		}
	);

	const [captChaCheck, setCaptChaCheck] = useState(true);

	const onChangeCaptcha = () => {
		setCaptChaCheck(false);
	};

	const onExpiredCaptcha = () => {
		setCaptChaCheck(true);
	};

	const onSubmit = (data: GetResellerFormType) => {
		resellerFormMU(data);
	};

	useEffect(() => {
		window.scrollTo(0,0);
	},[]);

	return (
		<div className='flex justify-center items-center py-7 sm:p-6 max-sm:p-6'>
			<div className=' bg-white w-[800px] rounded-[10px] py-[18px] px-[53px] max-sm:px-[5px] sm:px-[5px]'>
				<h1 className='text-[18px] text-center font-semibold  text-[#464255]'>Grow Your Business - Become an Affiliate</h1>
				<p className='text-center text-sm mt-2 text-[#464255]'>Turn your client's ideas into reality and earn big.</p>
				<p className='text-center text-sm text-[#464255] mb-[40px]'>Build stunning web and mobile apps for clients with our platform.</p>
				<form>
					<div className='flex gap-[42px] mb-[17px] max-sm:flex-col max-sm:gap-[22px]'>
						<div className='w-full md:w-1/2 px-3 mb-6 md:mb-0 '>
							<label className='block text-[#5A7184] text-xs mb-[10px]' htmlFor='grid-first-name'>
              Name<span className='text-[#BF1616]'>*</span>
							</label>
							<Controller
								control={control}
								name='name'
								render={({ field: {name, onChange}, fieldState: { error } }) => (
									<FormInput onChange={e => onChange(e.trim())} name={name}  error={error?.message} type='text' placeholder='Full Name' className='rounded-[5px] text-[14px] placeholder:text-[11px]'/>
								)}
							/>
						</div>
						<div className='w-full md:w-1/2 px-3 mb-6 md:mb-0 '>
							<label className='block text-[#5A7184] text-xs mb-[10px]' htmlFor='grid-website'>
								Website
							</label>
							<Controller
								control={control}
								name='website'
								render={({ field: {name, onChange}, fieldState: { error } }) => (
									<FormInput onChange={e => onChange(e.trim())} name={name}  error={error?.message} type='text' placeholder='https://domain.sample.com/' className='rounded-[5px] text-[14px] placeholder:text-[11px]'/>
								)}
							/>
						</div>
					</div>
					<div className='flex gap-[42px] mb-[17px] max-sm:flex-col max-sm:gap-[22px]'>
						<div className='w-full md:w-1/2 px-3 mb-6 md:mb-0 '>
							<label className='block text-[#5A7184] text-xs mb-[10px]' htmlFor='grid-email'>
              Email Address<span className='text-[#BF1616]'>*</span>
							</label>
							<Controller
								control={control}
								name='email'
								render={({ field: {name, onChange}, fieldState: { error } }) => (
									<FormInput onChange={e => onChange(e.trim())} name={name}  error={error?.message} type='text' placeholder='you@example.com' className='rounded-[5px] text-[14px] placeholder:text-[11px]'/>
								)}
							/>
						</div>
						<div className='w-full md:w-1/2 px-3 mb-6 md:mb-0 '>
							<label className='block text-[#5A7184] text-xs mb-[10px]' htmlFor='grid-country'>
              Country<span className='text-[#BF1616]'>*</span>
							</label>
							<div className='text-black'>
								<Controller
									control={control}
									name='country'
									render={({ field: { onChange, value }, fieldState: { error } }) => (
										<div className='w-full relative'>
											<select
												className={`text-[#183B56] pl-2 h-[34px] w-[100%] text-xs focus:outline-none rounded-[5px] border
												border-gray-300 focus:ring-1 focus:border-input-active ${error ? 'border-red-700 bg-red-100' : 'border-gray-300 bg-white'}`}
												onChange={onChange}
												value={value}
												style={{ appearance: 'none', /* Additional styling */ }}
											>
												<option value='' hidden>Select a country</option>
												{
													countryList.map((index, key) => (
														<option key={key} value={index.value}>{index.name}</option>
													))
												}
											</select>
											<RiArrowDropDownLine size={28} className='absolute right-2 top-1 text-[#183B56]' />
											<div className='mt-1 ml-1'>
												{error && <p className='text-red-700 text-xs'>{error.message}</p>}
											</div>
										</div>
									)}
								/>
							</div>
						</div>
					</div>
					<div className='flex gap-[42px] mb-[17px] max-sm:flex-col max-sm:gap-[22px]'>
						<div className='w-full md:w-1/2 px-3 mb-6 md:mb-0 '>
							<label className='block text-[#5A7184] text-xs mb-[10px]' htmlFor='grid-phone-number'>
              Phone Number<span className='text-[#BF1616]'>*</span>
							</label>
							<Controller
								control={control}
								name='phoneNumber'
								render={({ field: {name, onChange}, fieldState: { error } }) => (
									<FormInput onChange={onChange} name={name}  error={error?.message} type='phoneNumber' placeholder='e.g. +1 (123) 456-7890' className='[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none rounded-[5px] text-[14px] placeholder:text-[11px]'/>
								)}
							/>
						</div>
						<div className='w-full md:w-1/2 px-3 mb-6 md:mb-0 '>
						
						</div>
					</div>

					<div className='px-3 mt-[31px]'>
						<label className='block text-[#5A7184] text-xs mb-[10px]' htmlFor='grid-message'>
            Message<span className='text-[#BF1616]'>*</span>
						</label>
						<Controller
							control={control}
							name='message'
							render={({ field: {name, onChange}, fieldState: { error } }) => (
								<FormInputTextArea rows={2} onChange={e => onChange(e.trim())} name={name} error={error?.message} className='resize-none h-[110px]pr-[16px] pl-[16px] pr-[16px]  pb-[75px] text-sm rounded-[5px]' type='text' placeholder='Type your message...'/>
								
							)}
						/>
					</div>
					<div>
						<Controller
							control={control}
							name='adminEmail'
							defaultValue='limuel.fernando@hoolisoftware.com'
							render={({ field }) => <input type="hidden" {...field} />}
						/>
					</div>
					<div className=' px-3 mt-[31px]'>
						<ReCAPTCHA
							onChange={onChangeCaptcha}
							onExpired={onExpiredCaptcha}
							sitekey={import.meta.env.VITE_RECAPTCHA_PROD}
							size={'normal'}
						/>
					</div>
				</form>
				<div className='flex justify-center mt-[25px]'>
					<Button type='submit' disabled={!formState.dirtyFields.name || !formState.dirtyFields.email || !formState.dirtyFields.phoneNumber || !formState.dirtyFields.message || !formState.dirtyFields.country || captChaCheck} onClick={handleSubmit(onSubmit)} className='font-semibold text-[16px] w-[160px] h-[43px]' variant='primary'>Submit</Button>
				</div>
			</div>
		</div>
	); 
};