import { SIsAppearanceChanges, SIsLayoutChanges, SIsThemeChanges, SIsThemeLayoutRefresh, SisThemeLayoutDone } from 'store/CustomizeApp/Appearance';
import { SLayoutId, SLayoutType } from 'store/CustomizeApp/Appearance/layoutAtom';
import { SThemeId, SThemeType } from 'store/CustomizeApp/Appearance/themeAtom';
import { getAppearanceByAppID, getAppearanceByAppIDLive } from 'services/requests/appearance';
import { useAtom, useSetAtom } from 'jotai';

import { AxiosError } from 'axios';
import { GetAppearanceByAppIdResponse } from 'services/requests/appearance/schema';
import { GetLayoutResponse } from 'services/requests/appearance/layout/schema';
import { GetThemeResponse } from 'services/requests/appearance/theme/schema';
import { PIsPublishShowThemeLayout } from 'store/CustomizeApp/Publish/publishAtom';
import { getLayoutByID } from 'services/requests/appearance/layout';
import { getThemeByID } from 'services/requests/appearance/theme';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { log } from 'utility/log';

export const LayoutThemeUseQuery = () => {

	const {id} = useParams();
	const setIsPublishShow = useSetAtom(PIsPublishShowThemeLayout);
	//Atoms for Layout
	const [layoutID, setLayoutID] 		    = useAtom(SLayoutId);
	const [, setLayoutType] = useAtom(SLayoutType);

	//Atoms for Theme
	const [themeID, setThemeID] 		   = useAtom(SThemeId);
	const [, setThemeType] = useAtom(SThemeType);

	const setIsAppearanceChanges = useSetAtom(SIsAppearanceChanges);
	const setLayoutChanges = useSetAtom(SIsLayoutChanges);
	const setThemeChanges = useSetAtom(SIsThemeChanges);
	const [isThemeLayoutRefresh, setIsThemeLayoutRefresh] = useAtom(SIsThemeLayoutRefresh);
	const setLayoutThemeDone = useSetAtom(SisThemeLayoutDone);

	//Appearance Live
	const { data: appearanceLive, refetch: refetchLive } = useQuery<GetAppearanceByAppIdResponse, AxiosError>({
		queryKey: ['appearancesLive'],
		queryFn: () => getAppearanceByAppIDLive(id, { limit: 100 }),
	});
	

	//Appearance
	const { data , refetch, isFetched: successAppearance} = useQuery<GetAppearanceByAppIdResponse, AxiosError>({
		queryKey: ['appearances'],
		queryFn: () => getAppearanceByAppID(id, { limit: 1000 }),
		enabled: false,
		onSuccess: (data) => {
			if(data?.rows){
				setLayoutID(data?.rows[0]?.layoutID);
				setThemeID(data?.rows[0]?.themeID);
				setIsThemeLayoutRefresh(false);
				setLayoutThemeDone(true);
			}
		}
	});
	
	//Layoout
	const {data: layout ,refetch : refetchLayout, isFetched: successLayout} = useQuery<GetLayoutResponse, AxiosError>({
		queryKey: ['layout', layoutID],
		queryFn: () => getLayoutByID(layoutID),
		enabled: false,
		onSuccess: (data) => {
			if(data){
				setLayoutType(data?.layout.name);
			}
		}
	});

	//Theme
	const {data: theme, refetch: refetchTheme, isFetched: successTheme} = useQuery<GetThemeResponse, AxiosError>({
		queryKey: ['theme', themeID],
		queryFn: () => getThemeByID(themeID),
		enabled: false,
		onSuccess: (data) => {
			if(data){
				setThemeType(data?.theme.name); 
			}
		}
	});

	useEffect(() => {
		if(layoutID){
			refetchLayout();
		}
	},[layout, layoutID]);

	useEffect(() => {
		if(themeID){
			refetchTheme();
		}
	}, [theme, themeID]);

	useEffect(() => {
		if(isThemeLayoutRefresh){
			refetch();
			refetchLive();
		}
	},[isThemeLayoutRefresh]);

	useEffect(() => {
		if(data?.rows && successAppearance && successLayout && successTheme){
			data?.rows?.map((app) => {
				if(app.layoutID !== layoutID || app.themeID !== themeID){
					setIsAppearanceChanges(true);
					setLayoutChanges(true);
					setThemeChanges(true);
					log('lt 1');
				}else {
					setIsAppearanceChanges(false);
					setLayoutChanges(false);
					setThemeChanges(false);
				}
			});
		}
	},[layoutID, themeID, successAppearance, successLayout, successTheme]);

	useEffect(() => {
		if(data?.rows && appearanceLive?.rows){
			if(data?.rows[0]?.layoutID !== appearanceLive?.rows[0]?.layoutID || 
				data?.rows[0]?.themeID  !== appearanceLive?.rows[0]?.themeID){
				setIsPublishShow(true);
			}else setIsPublishShow(false);
		}
	},[data, appearanceLive]);
};