import { GetAppResponse } from 'services/requests/apps/schema';
import PubishTabAppLink from './AppLink';
import PublishTabContent from './Content';
import React from 'react';
import { useQueryClient } from 'react-query';

const PublishTab = () => {
	//App
	const queryClient = useQueryClient();
	const cachedApp = queryClient.getQueryData<GetAppResponse>('app');

	return (
		<div className='flex h-full w-full max-sm:pr-0'>
			<div className='h-auto tw-shadow-md w-full py-[50px]'>
				{cachedApp?.app.status === 'LIV' ? (
					<PubishTabAppLink />
				) : (
					<PublishTabContent />
				)}
			</div>
		</div>
	);
};

export default PublishTab;
