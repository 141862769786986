import { FHelp, FHelpID, FIsHelpRefresh } from 'store/CustomizeApp/Features/helpAtom';
import { FIsFeaturesChange, FIsHelpChanges } from 'store/CustomizeApp/Features';
import React, { useEffect } from 'react';
import { getHelpApi, getHelpApiLive } from 'services/requests/Features/help';
import { useAtom, useSetAtom } from 'jotai';

import { AxiosError } from 'axios';
import { GetHelpResponse } from 'services/requests/Features/help/schema';
import { PIsPublishShowHelp } from 'store/CustomizeApp/Publish/publishAtom';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { log } from 'utility/log';

export const HelpUseQuery = () => {

	const [desc, setHelpDesc]                       = useAtom(FHelp);
	const [, setHelpID]                         = useAtom(FHelpID);
	const [isHelpRefresh, setIsHelpRefresh]		= useAtom(FIsHelpRefresh);
	const setFeatureChange = useSetAtom(FIsFeaturesChange);
	const setHelpChanges = useSetAtom(FIsHelpChanges);
	const setPublishShow = useSetAtom(PIsPublishShowHelp);

	const {id} = useParams();

	const {data: helpLive, refetch: refetchHelpLive} = useQuery<GetHelpResponse, AxiosError>({
		queryKey: ['helpLive', id],
		queryFn: () => getHelpApiLive(id),
		enabled: false,
		retry: 0,
		onError: () => setIsHelpRefresh(false)
	});

	const {data, refetch, isFetching, isFetched} = useQuery<GetHelpResponse, AxiosError>({
		queryKey: ['help', id],
		queryFn: () => getHelpApi(id),
		enabled: false,
		retry: 0,
		onSuccess: (data) => {
			if(data?.help){
				setHelpDesc(data?.help?.description);
				setHelpID(data?.help?.helpID);
				setIsHelpRefresh(false);
			}else{
				setHelpDesc('');
				setHelpID(0);
				setIsHelpRefresh(false);
			}
		},
		onError: () => setIsHelpRefresh(false)
	});


	useEffect(() =>{
		if(isHelpRefresh){
			refetch();
			refetchHelpLive();
		}
	},[isHelpRefresh]);

	// useEffect(() =>{
	// 	if(isFetching){
	// 		setFeatureChange(false);
	// 	}
	// },[isFetching]);

	useEffect(() => {
		if((data?.help?.description || desc) && isFetched){
			if(data?.help?.description === undefined && desc === '<p><br></p>'){
				// setFeatureChange(false);
				setHelpChanges(false);
			}
			else if(data?.help?.description !== desc){
				// setFeatureChange(true);
				setHelpChanges(true);
				log('HelpChangesTrue');
			} else {
				// setFeatureChange(false);
				setHelpChanges(false);
			}
		} else {
			// setFeatureChange(false);
			setHelpChanges(false);
		}
	},[desc, data, isFetched]);

	useEffect(() => {
		if(data?.help && helpLive?.help){
			if(data?.help?.description !== helpLive?.help?.description){
				setPublishShow(true);
				log('HelpPublishTrue');
			}else setPublishShow(false);
		}
	},[data, helpLive]);
};