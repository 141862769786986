import React from 'react';

export const Background = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="25.904" height="25.904" viewBox="0 0 25.904 25.904" {...props}>
			<path id="Path_2546" data-name="Path 2546" d="M26.83,3.115,3.185,26.76a2.983,2.983,0,0,0,.734,1.3,2.983,2.983,0,0,0,1.3.734L28.873,5.144A2.882,2.882,0,0,0,26.83,3.115ZM15.849,3,3.07,15.779v4.073L19.922,3Zm-9.9,0A2.887,2.887,0,0,0,3.07,5.878V8.756L8.826,3ZM26.1,28.9a2.9,2.9,0,0,0,2.878-2.878V23.147L23.217,28.9Zm-13.974,0h4.073L28.974,16.125V12.052Z" transform="translate(-3.07 -3)" fill={props.fill}/>
		</svg>
	);
};

export const Color = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="23.196" height="27.994" viewBox="0 0 23.196 27.994" {...props}>
			<path id="Path_2548" data-name="Path 2548" d="M23.8,10.475,15.6,2.27,7.392,10.475a11.6,11.6,0,1,0,16.411,0ZM15.6,27.379A8.693,8.693,0,0,1,9.451,12.534L15.6,6.373Z" transform="translate(-4 -2.27)" fill={props.fill}/>
		</svg>

	);
};

export const Font = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="31.951" height="25.224" viewBox="0 0 31.951 25.224" {...props}>
			<path id="Path_2550" data-name="Path 2550" d="M3,17.453H8.045V29.224H13.09V17.453h5.045V12.408H3ZM13.09,9.045H21.5v20.18h5.045V9.045h8.408V4H13.09Z" transform="translate(-3 -4)" fill={props.fill}/>
		</svg>

	);
};

export const Layout = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="26.449" height="26.449" viewBox="0 0 26.449 26.449" {...props}>
			<g id="Group_9167" data-name="Group 9167" transform="translate(-3 -3)">
				<path id="Path_2543" data-name="Path 2543" d="M3,11.469A1.469,1.469,0,0,1,4.469,10H7.408a1.469,1.469,0,0,1,1.469,1.469v2.939a1.469,1.469,0,0,1-1.469,1.469H4.469A1.469,1.469,0,0,1,3,14.408ZM14.755,10a1.469,1.469,0,0,0-1.469,1.469v2.939a1.469,1.469,0,0,0,1.469,1.469h2.939a1.469,1.469,0,0,0,1.469-1.469V11.469A1.469,1.469,0,0,0,17.694,10Zm0,10.286a1.469,1.469,0,0,0-1.469,1.469v2.939a1.469,1.469,0,0,0,1.469,1.469h2.939a1.469,1.469,0,0,0,1.469-1.469V21.755a1.469,1.469,0,0,0-1.469-1.469Zm8.816-8.816A1.469,1.469,0,0,1,25.041,10H27.98a1.469,1.469,0,0,1,1.469,1.469v2.939a1.469,1.469,0,0,1-1.469,1.469H25.041a1.469,1.469,0,0,1-1.469-1.469Zm1.469,8.816a1.469,1.469,0,0,0-1.469,1.469v2.939a1.469,1.469,0,0,0,1.469,1.469H27.98a1.469,1.469,0,0,0,1.469-1.469V21.755a1.469,1.469,0,0,0-1.469-1.469Zm-20.571,0A1.469,1.469,0,0,0,3,21.755v2.939a1.469,1.469,0,0,0,1.469,1.469H7.408a1.469,1.469,0,0,0,1.469-1.469V21.755a1.469,1.469,0,0,0-1.469-1.469Z" transform="translate(0 3.286)" fillRule="evenodd" opacity="0.4"/>
				<path id="Path_2544" data-name="Path 2544" d="M3,4.469A1.469,1.469,0,0,1,4.469,3H7.408A1.469,1.469,0,0,1,8.878,4.469V7.408A1.469,1.469,0,0,1,7.408,8.878H4.469A1.469,1.469,0,0,1,3,7.408Zm10.286,0A1.469,1.469,0,0,1,14.755,3h2.939a1.469,1.469,0,0,1,1.469,1.469V7.408a1.469,1.469,0,0,1-1.469,1.469H14.755a1.469,1.469,0,0,1-1.469-1.469ZM25.041,3a1.469,1.469,0,0,0-1.469,1.469V7.408a1.469,1.469,0,0,0,1.469,1.469H27.98a1.469,1.469,0,0,0,1.469-1.469V4.469A1.469,1.469,0,0,0,27.98,3Z" fillRule="evenodd"/>
			</g>
		</svg>

	);
};


export const Theme = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="26.837" height="24.64" viewBox="0 0 26.837 24.64" {...props}>
			<g id="Group_9166" data-name="Group 9166" transform="translate(-74.426 -227.25)">
				<path id="Path_2540" data-name="Path 2540" d="M36.584,28.074l-.434-13.046a2.124,2.124,0,0,0-4.242,0l-.44,13.1c-1.346,2.1-.817,3.12-.07,4.14a3.119,3.119,0,0,1,.529.906c.351,1.155-1,2.552-1.537,2.954A.638.638,0,0,0,30.7,37.26a7.419,7.419,0,0,0,1.053.07,5.741,5.741,0,0,0,4.829-9.257Zm-3.407-13a.848.848,0,0,1,1.7,0l.421,12.6H32.744ZM36,33.6a4.357,4.357,0,0,1-3.726,2.431,3.617,3.617,0,0,0,.893-3.234,4.134,4.134,0,0,0-.727-1.276c-.523-.7-.836-1.148,0-2.552h3.19A4.389,4.389,0,0,1,36,33.6ZM29.127,22.619a6.6,6.6,0,0,0-.785-6.883,7.273,7.273,0,0,0-6.928-2.966,12.357,12.357,0,0,0,1.914,24.567,12.044,12.044,0,0,0,2.016-.166,2.756,2.756,0,0,0,2.1-1.6,2.89,2.89,0,0,0-.147-2.692.938.938,0,0,0-.147-.2c-3.273-3.056-1.78-4.931.108-7.3A15.744,15.744,0,0,0,29.127,22.619ZM26.192,33.547a1.646,1.646,0,0,1,.1,1.493,1.518,1.518,0,0,1-1.148.874A11.081,11.081,0,0,1,16.987,15.9a10.845,10.845,0,0,1,4.625-1.863,5.582,5.582,0,0,1,.855-.07,6.073,6.073,0,0,1,4.848,2.552,5.333,5.333,0,0,1,.638,5.569,14.233,14.233,0,0,1-1.735,2.52c-1.895,2.392-4.07,5.116-.026,8.944Z" transform="translate(63.471 214.552)" fill={props.fill}/>
				<path id="Path_2541" data-name="Path 2541" d="M25.3,17.231A3.19,3.19,0,1,0,23.35,22.89h.4A3.19,3.19,0,0,0,25.3,17.25Zm-.453,3.655a1.876,1.876,0,0,1-1.276.714,1.914,1.914,0,0,1-1.716-3.03,1.831,1.831,0,0,1,1.276-.714,1.474,1.474,0,0,1,.236,0,1.879,1.879,0,0,1,1.869,1.671,1.856,1.856,0,0,1-.389,1.359Zm-6.022,6.367a2.889,2.889,0,0,0,.332,0,2.673,2.673,0,1,0-.332,0ZM18.668,23.2h.172a1.227,1.227,0,1,1-.172,0Zm3,7.107a2.4,2.4,0,1,0-2.36,2.7h.306a2.4,2.4,0,0,0,2.054-2.7Zm-2.239,1.41a1.123,1.123,0,1,1-.281-2.226h.14a1.123,1.123,0,0,1,.14,2.239Zm4.1.185a2.23,2.23,0,0,0,.242,4.44h.287a2.233,2.233,0,0,0-.555-4.427Zm.4,3.19a.964.964,0,1,1-.236-1.914h.121a.938.938,0,0,1,.581.2.957.957,0,0,1-.466,1.7Z" transform="translate(61.529 213.172)" fill={props.fill}/>
			</g>
		</svg>

	);
};

export const Welcome = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg id="Group_9163" data-name="Group 9163" xmlns="http://www.w3.org/2000/svg" width="33.596" height="26.744" viewBox="0 0 33.596 26.744 " {...props}>
			<path id="Path_2339" data-name="Path 2339" d="M118.35,100.119a29.191,29.191,0,0,1-3.561-.616,27.089,27.089,0,0,0-3.438-.655,10.664,10.664,0,0,0-3.534.192q-4.235,1-5.349,4.016a12.083,12.083,0,0,0-.261,5.673.516.516,0,0,1-.467.564.6.6,0,0,1-.412-.1.443.443,0,0,1-.206-.334,12.86,12.86,0,0,1,.316-6.147q1.306-3.555,6.119-4.658a12.033,12.033,0,0,1,3.919-.218,27.821,27.821,0,0,1,3.575.667,29.419,29.419,0,0,0,3.41.6,10.3,10.3,0,0,0,3.438-.154,4.334,4.334,0,0,0,1.98-1.065,3.484,3.484,0,0,0,.523-.526.52.52,0,0,1,.619-.167.447.447,0,0,1,.358.487,8.275,8.275,0,0,1-1.224,4.376l-.014.038-.426.642a14.076,14.076,0,0,1-4.125,3.888,16.054,16.054,0,0,1-4.8,2.066,29.25,29.25,0,0,1-4.868.449,18.614,18.614,0,0,0-5.28.757.563.563,0,0,1-.426-.013.477.477,0,0,1-.275-.3.436.436,0,0,1,.014-.385.534.534,0,0,1,.316-.269,19.057,19.057,0,0,1,5.61-.821,26.957,26.957,0,0,0,4.648-.423,15.1,15.1,0,0,0,4.455-1.925l.44-.269-.151.038a30.773,30.773,0,0,1-5.321.4,29.894,29.894,0,0,0-4.689.244,9.781,9.781,0,0,0-5.211,2.836,6.991,6.991,0,0,0-1.114,1.527,6.855,6.855,0,0,0-.784,2.887.505.505,0,0,1-.165.346.524.524,0,0,1-.371.141h-.027a.563.563,0,0,1-.385-.166.49.49,0,0,1-.138-.372,7.749,7.749,0,0,1,.894-3.285,7.356,7.356,0,0,1,1.265-1.758,10.7,10.7,0,0,1,5.761-3.144,29.65,29.65,0,0,1,4.95-.282,28.928,28.928,0,0,0,5.06-.372,6.382,6.382,0,0,0,3.809-2.387,3.047,3.047,0,0,0,.289-.449l.11-.154a7.471,7.471,0,0,0,.949-2.5,5.875,5.875,0,0,1-1.994.911,11.331,11.331,0,0,1-3.809.168Z" transform="translate(-96.348 -91.701)" fillRule="evenodd"/>
			<g id="Path_2340" data-name="Path 2340" fill="none">
				<path d="M7.9,0H25.691a7.655,7.655,0,0,1,7.9,7.378V19.366a7.655,7.655,0,0,1-7.9,7.378H7.9A7.655,7.655,0,0,1,0,19.366V7.378A7.655,7.655,0,0,1,7.9,0Z" stroke="none"/>
				<path d="M 7.904985427856445 2 C 4.648965835571289 2 1.999996185302734 4.412420272827148 1.999996185302734 7.377689361572266 L 1.999996185302734 19.36643981933594 C 1.999996185302734 22.33170890808105 4.648965835571289 24.74413871765137 7.904985427856445 24.74413871765137 L 25.69120597839355 24.74413871765137 C 28.94722557067871 24.74413871765137 31.59619522094727 22.33170890808105 31.59619522094727 19.36643981933594 L 31.59619522094727 7.377689361572266 C 31.59619522094727 4.412420272827148 28.94722557067871 2 25.69120597839355 2 L 7.904985427856445 2 M 7.904985427856445 0 L 25.69120597839355 0 C 30.05701637268066 0 33.59619522094727 3.303110122680664 33.59619522094727 7.377689361572266 L 33.59619522094727 19.36643981933594 C 33.59619522094727 23.4410285949707 30.05701637268066 26.74413871765137 25.69120597839355 26.74413871765137 L 7.904985427856445 26.74413871765137 C 3.539186477661133 26.74413871765137 -3.814697265625e-06 23.4410285949707 -3.814697265625e-06 19.36643981933594 L -3.814697265625e-06 7.377689361572266 C -3.814697265625e-06 3.303110122680664 3.539186477661133 0 7.904985427856445 0 Z" stroke="none" fill={props.fill}/>
			</g>
		</svg>

	);
};


export const Info = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"  {...props}>
			<g id="Layer_2" data-name="Layer 2" transform="translate(-2 -2)">
				<path id="Path_2552" data-name="Path 2552" d="M8,2a6,6,0,1,0,6,6A6,6,0,0,0,8,2ZM8,13a5,5,0,1,1,5-5,5,5,0,0,1-5,5Z" fill={props.fill}/>
				<path id="Path_2553" data-name="Path 2553" d="M8,6.85a.5.5,0,0,0-.5.5v3.4a.5.5,0,0,0,1,0V7.35A.5.5,0,0,0,8,6.85ZM8,4.8a.53.53,0,0,0-.51.52V5.4A.47.47,0,0,0,8,5.87a.52.52,0,0,0,.5-.5V5.25A.45.45,0,0,0,8,4.8Z" fill={props.fill}/>
			</g>
		</svg>


	);
};

export const Video = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16.086" height="13.522" viewBox="0 0 16.086 13.522"  {...props}>
			<path id="Path_2551" data-name="Path 2551" d="M10.936,3.969A2.447,2.447,0,0,0,8.648,5.4a1.924,1.924,0,0,0-1.057-.332A1.749,1.749,0,0,0,5.736,6.683a1.566,1.566,0,0,0,.537,1.146H4.887A1.3,1.3,0,0,0,3.5,9.034l.052,7.236a1.314,1.314,0,0,0,1.387,1.206l9.343.015A1.3,1.3,0,0,0,15.668,16.3s0-.01,0-.015V14.778L19.135,16.5a.314.314,0,0,0,.279,0,.237.237,0,0,0,.137-.211l.035-7.236a.23.23,0,0,0-.1-.2.31.31,0,0,0-.243-.045.3.3,0,0,0-.087.03L15.686,10.6V9.049A1.308,1.308,0,0,0,14.3,7.843h-.035c-.84,0-1.173,0-1.785,0a2.085,2.085,0,0,0,.936-1.7A2.342,2.342,0,0,0,10.936,3.969Zm0,.482A1.813,1.813,0,0,1,12.86,6.14a1.813,1.813,0,0,1-1.924,1.688,1.99,1.99,0,0,1-1.629-.784.291.291,0,0,0-.268-.118A.272.272,0,0,0,8.8,7.09,1.254,1.254,0,0,1,7.8,7.828H7.591a1.23,1.23,0,0,1-1.3-1.145,1.056,1.056,0,0,1,.379-.8,1.4,1.4,0,0,1,1.892.078.3.3,0,0,0,.287.075.255.255,0,0,0,.2-.2A1.877,1.877,0,0,1,10.936,4.451ZM9.1,7.511a3.008,3.008,0,0,0,.364.317H8.822A2.043,2.043,0,0,0,9.1,7.512Zm-4.212.8c1.28,0,1.889,0,2.7,0,2.021,0,3.533.009,6.708.015a.776.776,0,0,1,.832.724v1.96s0,.01,0,.014l-.018,3.332s0,.01,0,.015v1.914a.777.777,0,0,1-.832.724l-9.343-.015a.892.892,0,0,1-.59-.21.673.673,0,0,1-.242-.514L4.055,9.034A.672.672,0,0,1,4.3,8.52a.891.891,0,0,1,.591-.21ZM19.031,9.472,19,15.864l-3.328-1.643V12.713l.018-1.553Zm-9.222.3a.251.251,0,0,0-.234.279.278.278,0,0,0,.321.2h3.883a.244.244,0,1,0,0-.482H9.9A.318.318,0,0,0,9.809,9.773ZM5.961,14.6a.731.731,0,1,0,0,1.447H10.4a.731.731,0,1,0,0-1.447H5.961Zm0,.482H10.4a.244.244,0,1,1,0,.482H5.961a.293.293,0,0,1-.2-.069.22.22,0,0,1-.079-.172.255.255,0,0,1,.277-.241Z" transform="translate(-3.5 -3.969)" fill={props.fill}/>
		</svg>


	);
};