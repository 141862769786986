import { zodResolver } from '@hookform/resolvers/zod';
import { AxiosError } from 'axios';
import Button from 'components/Common/Buttons/Button';
import FormInput from 'components/Common/Forms/Inputs';
import { useAtom } from 'jotai';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { resetPasswordSendEmailApi, resetPasswordValidateCodeApi } from 'services/requests/EditProfile/PasswordSettings/ResetPassword';
import { ResetPasswordSendEmailRequest, ResetPasswordSendEmailResponse, ResetPasswordValidateVerificationCodeRequest, 
	ResetPasswordValidateVerificationCodeResponse, resetPasswordValidateVerificationCodeSchema } 
	from 'services/requests/EditProfile/PasswordSettings/ResetPassword/schema';
import { codeValidity, isCodeResent } from 'store/EditProfile/resetPassword';
import { globalVariableAtom } from 'store/authAtom';
import { showFailedAlert } from 'utility/alerts';
import Countdown, { zeroPad} from 'react-countdown';
import dayjs from 'dayjs';

const ResetPasswordEmailVerification: React.FC =() => {
	const navigate =useNavigate();
	const [globalVariable, setGlobalVariable] = useAtom(globalVariableAtom);
	const [isResent, setIsResent] = useAtom(isCodeResent);
	const [codeExpiry, setCodeExpiry] = useAtom(codeValidity);

	const userEmail = localStorage.getItem('email')?.replaceAll('"','');

	const time = React.useMemo(() => {
		return Date.now() + 120 * 1000;
	}, []);

	const { control, handleSubmit, formState, setError, clearErrors } = useForm<ResetPasswordValidateVerificationCodeRequest>({
		defaultValues: { verificationCode: undefined },
		mode: 'onBlur',
		resolver: zodResolver(resetPasswordValidateVerificationCodeSchema),
	});


	const { mutate: resetPasswordValidateCodeMu, isLoading: resetValidatingCode } = useMutation<ResetPasswordValidateVerificationCodeResponse, AxiosError, ResetPasswordValidateVerificationCodeRequest>(
		(data) => resetPasswordValidateCodeApi(data), {
			onSuccess: (res) => {
				console.log(res);
				
				if(res.success) {
					const now = dayjs();
					console.log('now:',now.valueOf());
					setCodeExpiry(now.add(2,'minute'));
					navigate('/reset-your-password');
				} 
				else {
					setError('verificationCode', {
						type:'value',
						message:'Verification code is invalid.'
					});
				}
			},
			onError: (err) => {
				console.log(err);
				setError('verificationCode', {
					type:'value',
					message:'Verification code is invalid.'
				});
			}
		}
	);

	const { mutate: resetPasswordEmailSendMU} = useMutation<ResetPasswordSendEmailResponse, AxiosError, ResetPasswordSendEmailRequest>(
		(data) => resetPasswordSendEmailApi(data), {
			onSuccess: (res) => {
				console.log(res);
				
				if(res.success) {
					//navigate('/reset-password-verify');
					setIsResent(true);
				} 
				else {
					showFailedAlert('Error sending email. Please try again'); 
				}
			},
			onError: (err) => {
				console.log(err);
				showFailedAlert('Error sending email. Please try again');
			}
		}
	);

	const onSubmit = (data: ResetPasswordValidateVerificationCodeRequest) => {

		if( userEmail === ''){
			setGlobalVariable('Email is not valid for this action.');
			return ;
		}
		data.email = userEmail;
		resetPasswordValidateCodeMu(data);
	};

	const handleResend = () => {
		if( userEmail === ''){
			setGlobalVariable('Email is not valid for this action.');
			return;
		}
		// date = Date.now() + 120000;
		//console.log('date:', date);
		resetPasswordEmailSendMU({email: userEmail  ? userEmail : ''});
	};

	const renderer = ({minutes, seconds}:{minutes:number, seconds:number, completed:boolean}) => {
		return(
			<span>{zeroPad(minutes,2)}:{zeroPad(seconds,2)}</span>
		);

	}; 
	
	return(
		
		<div className='flex justify-center w-full h-full my-[150px]'>
			<div className='p-[40px] h-fit w-[532px] bg-[#FFFFFF] rounded-[10px] shadow-xl mx-8'>
				<button onClick={()=>{navigate('/Reset-Password-Email');}} className='text-[#1C180F] text-[1rem] '>&lt; Back</button>
				<p className='text-[#464255] text-[28px] font-bold font-[montserrat] mt-9 h-fit mb-[27px] flex justify-center items-center leading-[34px]'>Verify It&apos;s You</p>
				<p className='text-[#707070] font-poppins flex text-center mx-7'>To secure your account, enter the code we just sent to {userEmail}.</p>
				<form className='w-full'>
					<div className='mt-7'>
						<Controller
							control={control}
							name='verificationCode'
							render={({ field: { name, onChange }, fieldState: { error } }) => (
								<>
									<FormInput
										label='Code'
										onChange={(e) => {
											onChange(e);
											setGlobalVariable('');
											clearErrors('verificationCode');
										}}
										name={name}
										error={error?.message}
										type='number'
										maxLength={6}
										className='[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none'
										placeholder='123456'
										
									/>
									<div className='mt-2'>
										{globalVariable === 'Email is not valid for this action.' ? (
											<p className='text-red-700 text-xs pl-1'>{globalVariable}</p>
										) : null}
									</div>
								</>
							)}
						/>
					</div>

					<div className='mt-7'>
						<Button
							type='submit'
							variant='primary'
							onClick={handleSubmit(onSubmit)}
							className='w-full py-1.5'
							disabled={!formState.dirtyFields.verificationCode || !!formState.errors.verificationCode || resetValidatingCode}
						>
							<span className='text-sm font-semibold'> {resetValidatingCode ? 'Please wait. . .' : 'Continue'}</span>
						</Button>
					</div>

					<p className='text-[#464255] text-center my-8 mx-8' >
						Didn&apos;t get the code? &nbsp;
						{ isResent ? 
							<Countdown renderer={renderer} date={time} autoStart onComplete={()=> setIsResent(false)}  />  //countdown from 2minutes to avoid spam
							: 
							<button type='button' onClick={handleResend} className='underline text-[#ff7f20]'>Resend code</button>
						}
					</p>                
					
				</form>
			</div>
		</div>    
	); 
  
};
export default ResetPasswordEmailVerification;