import Button from 'components/Common/Buttons/Button';
import React, { useState } from 'react';
import Modal from '../Modal';
import { useMutation, useQueryClient } from 'react-query';
import { rejectApproval } from 'services/requests/Approver/approval';
import FormInput from 'components/Common/Forms/Inputs';

interface IRejectionModal {
	isRejectionModalShow: boolean;
	setRejectionModalShow: React.Dispatch<React.SetStateAction<boolean>>;
	onClickCancel: (e: any) => void;
	onClickRejection?: (e: any) => void;
    appID: number;
}

export const RejectionModal: React.FC<IRejectionModal> = ({isRejectionModalShow, setRejectionModalShow, onClickCancel, onClickRejection, appID}) => {
	
	const queryClient = useQueryClient();
	const { mutateAsync } = useMutation(rejectApproval);

	const rejectApp = async () => {
		await mutateAsync(appID);
		queryClient.invalidateQueries('pwa-apps');
		setRejectionModalShow(false);
	};

	const renderRejectionModal = (): JSX.Element => {
		return(
			<>
				<div className='sm:mx-auto sm:w-full sm:max-w-md'>
					<div className='py-[28px] px-[60px] sm:rounded sm:px-10'>
						<div className='flex items-center justify-center'>
							<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth='1.5' stroke='#4663AC' className='w-[70px] h-[70px]'>
								<path strokeLinecap='round' strokeLinejoin='round' d='M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z' />
							</svg>
						</div>
						<p className='text-base text-[#707070] text-center mt-[15px]'> 
								Are you sure you want to Reject this application?
						</p>
						<div className='mt-5'>
							<FormInput className='h-[10vh] m-0' type='text' label='Rejection Notes' />
						</div>
						<div className='flex flex-row gap-[42px] justify-center mt-[25px]'>
							<Button variant='secondary' className='px-[42px] py-1.5' onClick={onClickCancel}>Cancel</Button>
							<Button variant='primary' className='px-[42px] py-1.5' onClick={rejectApp}>Rejection</Button>
						</div>
					</div>
				</div>
			</>
		);
	};

	return (
		<div>
			<Modal open={isRejectionModalShow} onClose={setRejectionModalShow} className='sm:max-w-md'>
				{renderRejectionModal()}
			</Modal>
		</div>
	);
};

