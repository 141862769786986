import React, { useState, useEffect } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { getGoogleAuthUrl, loginApi } from 'services/requests/auth';
import { LoginResponseType, loginSchema, LoginType, LoginVerifiedType, LoginVerifiedResponse, GetGoogleAuthUrlResponse } from 'services/requests/auth/schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { AxiosError } from 'axios';
import { useSetAtom } from 'jotai';
import { authAtom } from 'store/authAtom';
import { useAtom } from 'jotai';
import { globalVariableAtom, globalFormSubmitted } from 'store/authAtom';
import { IMAGES } from 'utility/constant';
import { Button } from 'components/Common/Buttons';
import FormInput from 'components/Common/Forms/Inputs';
import { Link } from 'react-router-dom';

import { getUserVerified } from 'services/requests/auth';
import { log } from 'utility/log';


const SignIn: React.FC = () => {
	const [globalVariable, setGlobalVariable] = useAtom(globalVariableAtom);
	const setAuth = useSetAtom(authAtom);
	const [emailVerified, setEmailVerified] = useState<boolean>();
	const [isEmailExisting, setIsEmailExisting] = useState<boolean>();
	const [formSubmitted, setFormSubmitted] = useAtom(globalFormSubmitted);

	const { control, handleSubmit, formState } = useForm<LoginType>({
		defaultValues: { username: '', password: '' },
		mode: 'onBlur',
		resolver: zodResolver(loginSchema),
	});

	const { data: googleAuthUrl } = useQuery<GetGoogleAuthUrlResponse, AxiosError>({
		queryKey: ['getAuthUrl'],
		queryFn: getGoogleAuthUrl,
	});
	
	const {
		mutate: loginMu,
	} = useMutation<LoginResponseType, AxiosError, LoginType>(
		data => loginApi(data), {
			onSuccess: (data) => {
				log('this is your data: ', data);
				setAuth(data);
				localStorage.removeItem('userData');
			},
			onError: (error) => {
				log('error in login:', error);
				setGlobalVariable('The email and/or password you entered is incorrect.');
				setFormSubmitted(false);
			}
		});

	const { mutate: userVerified } = useMutation<LoginVerifiedResponse, AxiosError, string>(
		email => getUserVerified(email), {
			onSuccess: (data) => {
				setEmailVerified(data.EmailVerified);
				setIsEmailExisting(true);
			},
			onError: (error) => {
				if(error.response?.status === 500){
					setIsEmailExisting(false);
					setGlobalVariable('The email and/or password you entered is incorrect.');
					setFormSubmitted(false);
				}else{
					setGlobalVariable('Please verify your email before logging in.');
					setFormSubmitted(false);
				}
			}
		});

	const onSubmit: SubmitHandler<LoginType> = async (data) => {
		try {
			await userVerified(data.username);
			setTimeout(() => {
				setFormSubmitted(true);
			},1000);
		} catch (error) {
			setGlobalVariable('Please verify your email before logging in.');
			setFormSubmitted(false);
		}
	};

	useEffect(() => {
		if (emailVerified === true) {
			handleSubmit(handleLogin)();
		} else if (formSubmitted && isEmailExisting) {
			setGlobalVariable('Please verify your email before logging in.');
			setFormSubmitted(false);
		} else if (formSubmitted && !isEmailExisting) {
			setGlobalVariable('The email and/or password you entered is incorrect.');
			setFormSubmitted(false);
		}
	}, [emailVerified, formSubmitted]);

	const handleLogin = async (data: LoginType) => {
		try {
			setFormSubmitted(false);
			await loginMu(data);
		} catch (error) {
			setGlobalVariable('The email and/or password you entered is incorrect.');
			setFormSubmitted(false);
		}
	};
	
	return (
		<div className='w-full h-[90vh] flex justify-center items-center bg-[#F6F6F6] max-sm:pl-3 max-sm:pr-3 sm:pl-3 sm:pr-3'>
			<div className='max-w-[531px] xl:w-[531px] p-6 pl-12 pr-12 bg-white rounded-xl'>
				<h2 className='text-center text-gray-600 text-lg font-bold'>Sign In</h2>
				<form className='w-full'>
					<div className='mt-7'>
						<Controller
							control={control}
							name="username"
							render={({ field: {name, onChange}, fieldState: { error } }) => (
								<FormInput label='Email Address' onChange={onChange} name={name} error={error?.message} type='email'/>
							)}
						/>
					</div>

					<div className='mt-3'>
						<Controller
							control={control}
							name="password"
							render={({ field: {name, onChange}, fieldState: { error } }) => (
								<FormInput label='Password' onChange={onChange} name={name} error={error?.message} type='password'/>
							)}
						/>
					</div>

					<div className='mt-7'>
						<div className='text-sm flex '>
							<p className='text-gray-500 pr-1'>
                                Forgot your password? 
							</p>
							<Link id='nav-link' to='/forgot-email' className='text-primary hover:text-primary underline hover:cursor-pointer'>
                                Click here.
							</Link>
						</div>
					</div>

					<div className='mt-7'>
						<Button
							type='submit'
							variant='primary'
							onClick={handleSubmit(onSubmit)}
							className='w-full py-1.5'
							disabled={!formState.dirtyFields.username || !formState.dirtyFields.password}>
							<span className='text-sm'>Sign In</span>
						</Button>
					</div>
					<div className='flex items-center my-4 before:flex-1 before:border-t before:border-gray-300 before:mt-0.5 after:flex-1 after:border-t after:border-gray-300 after:mt-0.5'>
						<p className='text-center mx-2 mb-0 text-gray-700 font-medium text-sm'>OR</p>
					</div>
					<a
						id='nav-link'
						className='px-7 py-2 text-gray font-light text-sm rounded hover:shadow-md focus:shadow-md focus:outline-none focus:ring-0 active:shadow-md transition duration-150 ease-in-out w-full flex justify-center items-center mb-3 border-2'
						role='button'
						href={googleAuthUrl?.url}
						data-mdb-ripple='true'
						data-mdb-ripple-color='light'
					>
						<img
							className='pr-2'
							src={IMAGES.GoogleButtonIcon}
							alt='Google Icon'
						
						/>
							Sign in with Google
					</a>

					<div className='mt-3'>
						{globalVariable === 'The email and/or password you entered is incorrect.' ?
							<p className='flex justify-center items-center text-red-700 text-xs pl-1 py-2'>{globalVariable}</p>
							:
							globalVariable === 'Please verify your email before logging in.' && !emailVerified ?
								<p className='flex justify-center items-center text-red-700 text-xs pl-1 py-2'>{globalVariable}</p>
								:
								null
						}		
					</div>
					<hr className='my-7'/>
					<div className='mt-7 flex justify-center pb-12 text-sm flex-col items-center sm:flex-row'>
						<p className='text-gray-500 pr-1'>
							Don&apos;t have an account yet? 
						</p>
						<Link id='nav-link' to='/signup'
							className='text-primary hover:text-primary underline hover:cursor-pointer'>
							Sign Up.
						</Link>
					</div>
				</form>
			</div>
		</div>
	);  
};

export default SignIn;