import z from 'zod';

export const CreateHelpSchema = z.object({
	description:  z.string(),
	isTurnedOn:   z.boolean()
});

export const GetHelpSchema = z.object({
	help: z.object({
		helpID:        z.number(),
		appID:         z.number(),
		description:   z.string(),
		isTurnedOn:    z.boolean(),
		status:        z.string(),
		whoAdded:      z.number(),
		whoUpdated:    z.number(),
		whenAdded:     z.string(),
		whenUpdated:   z.string()
	})
});

export const EditHelpSchema = z.object({
	description: z.string(),
	isTurnedOn:  z.boolean()
});

export type CreateHelpType = z.infer<typeof CreateHelpSchema>;
export type CreateHelpResponse = z.infer<typeof GetHelpSchema>;
export type CreateHelpParams = z.infer<typeof CreateHelpSchema>;

export type GetHelpType = z.infer<typeof GetHelpSchema>;
export type GetHelpResponse = z.infer<typeof GetHelpSchema>;

export type EditHelpType = z.infer<typeof EditHelpSchema>;
export type EditHelpResponse = z.infer<typeof GetHelpSchema>;
export type EditHelpParams = z.infer<typeof EditHelpSchema>;