import React from 'react';
import Button from '@mui/material/Button';
import { useAtom } from 'jotai';
import * as subscriptionAtom from '../../../store/CustomizeApp/Payment/subscription';
import { ISubscriptionDetails, subscriptionPlans } from 'pages/CustomizeApp/Payment/Content/oldIndex';

export const SubscribedPlan: React.FC = () => {

	const [subscriptionData ]      						   = useAtom(subscriptionAtom.FSubscription);
	const [, setChangePlan]											 = useAtom(subscriptionAtom.changePlanID);

	const handleChangePlan = () => {
		setChangePlan(true);
	};

	return (
		<>
			{subscriptionPlans.map((data: ISubscriptionDetails, index: number) => {
				if (data.PriceId === subscriptionData.PriceId)
					return (
						<div key={index}>
							<div className='w-80 py-4 bg-[#FDDDCB] rounded-md mt-8'>
								<div className='mx-4 flex justify-between items-center'>
									<div className='text-[#1C180F] text-lg font-bold'> {data.planTitle} Plan </div>
									<div className='text-[#1C180F] text-4xl font-bold'> $ {data.planPrice.toLocaleString()} </div>
								</div>
								<div className='mx-4 mt-5'>
									<hr className='bg-gray-500 h-[1px] border-0 opacity-20'/>
									<div className='mt-1 flex justify-end'>
										<Button onClick={handleChangePlan}
											sx={
												{ color: '#4663AC', 
													fontSize: '16px', 
													fontWeight: 'semibold', 
													fontFamily: 'Poppins', 
													paddingX: '0px', 
													'&:hover': 
                          {background: 'none'},
												}}>
                      Change Plan
										</Button>
									</div>
								</div>
							</div>
						</div>
					);
			})}
		</>
	);
};