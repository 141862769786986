import { z } from 'zod';

export const createNotificationSchema = z.object({
	icon: z.string(),
	title: z.string(),
	message: z.string(),
	url: z.string()
});

export const getNotificationSchema = z.object({
	notificationID: z.number(),
	appID: z.number(),
	icon: z.string(),
	title: z.string(),
	message: z.string(),
	url: z.string(),
	status: z.string(),
	whoAdded: z.number(),
	whenAdded: z.string(),
	whenUpdated: z.string()
});

export const getAllNotificationsSchema = z.object({
	limit: z.number(),
	page: z.number(),
	sort: z.string(),
	totalRows: z.number(),
	totalPages: z.number(),
	rows: z.array(
		z.object({
			notificationID: z.number(),
			appID: z.number(),
			icon: z.string(),
			title: z.string(),
			message: z.string(),
			url: z.string(),
			status: z.string(),
			whoAdded: z.number(),
			whenAdded: z.string(),
			whenUpdated: z.string()
		})
	)
});

export const editNotificationSchema = z.object({
	icon: z.string(),
	title: z.string(),
	message: z.string(),
	url: z.string(),
	notificationId: z.number()
});

export const createBulkNotificationSchema = z.object({
	notifications: z.array(editNotificationSchema)
});


export type CreateNotificationType = z.infer<typeof createNotificationSchema>
export type CreateNotificationResponse = z.infer<typeof getNotificationSchema>
export type CreateNotificationParams = z.infer<typeof createNotificationSchema>

export type GetNotificationType = z.infer<typeof getNotificationSchema>
export type GetNotificationResponse = z.infer<typeof getNotificationSchema>

export type GetAllNotificationsType = z.infer<typeof getAllNotificationsSchema>
export type GetAllNotificationsResponse = z.infer<typeof getAllNotificationsSchema>

export type EditNotificationType = z.infer<typeof editNotificationSchema>
export type EditNotificationResponse = z.infer<typeof getNotificationSchema>
export type EditNotificationParams = z.infer<typeof editNotificationSchema>

export type CreateBulkNotificationType = z.infer<typeof createBulkNotificationSchema>