import z from 'zod';

export const OffersSchema = z.object({
	offerID:           z.number(),
	companyTenantID:   z.string(),
	appTenantID:       z.string(),
	appID:             z.number(),
	couponType:        z.number(),
	// minimumBasketPrice:z.string(),
	title:             z.string(),
	description:       z.string(),
	termsAndCondition: z.string(),
	discountType:      z.string(),
	discount:          z.string(),
	buy:               z.number(),
	get:               z.number(),
	code:              z.string(),
	status:            z.string(),
	whoAdded:          z.number(),
	whoUpdated:        z.string(),
	whenAdded:         z.string(),
	whenUpdated:       z.string()
});

export const TempOffersSchema = z.object({
	offerID: z.number(),
	title:  z.string(),
	description:  z.union([z.string(), z.undefined()]).optional(),
	termsAndCondition:  z.union([z.string(), z.undefined()]).optional(),
	discount: z.union([z.string(), z.undefined()]).optional(),
	discountType:  z.union([z.string(), z.undefined()]).optional(),
	buy:   z.union([z.number(), z.undefined()]).optional(),
	get:  z.union([z.number(), z.undefined()]).optional(),
	code:z.union([z.string(), z.undefined()]).optional(),
	couponType: z.number(),
	// minimumBasketPrice:  z.string(),
});

export const OffersSchemaPayload = z.object({
	couponType:         z.number(),
	title:              z.string(),
	description:        z.union([z.string(), z.undefined()]).optional(),
	termsAndCondition:  z.union([z.string(), z.undefined()]).optional(),
	discountType:       z.union([z.string(), z.undefined()]).optional(),
	discount:           z.union([z.string(), z.undefined()]).optional(),
	// minimumBasketPrice: z.string(),
	buy:                z.union([z.number(), z.undefined()]).optional(),
	get:                z.union([z.number(), z.undefined()]).optional(),
	code:               z.union([z.string(), z.undefined()]).optional()
});

export const EditOfferSchemaPayload = z.object({
	offerID:            z.number(),
	couponType:         z.number(),
	title:              z.string(),
	description:        z.union([z.string(), z.undefined()]).optional(),
	termsAndCondition:  z.union([z.string(), z.undefined()]).optional(),
	discountType:       z.union([z.string(), z.undefined()]).optional(),
	discount:           z.union([z.string(), z.undefined()]).optional(),
	// minimumBasketPrice: z.string(),
	buy:                z.union([z.number(), z.undefined()]).optional(),
	get:                z.union([z.number(), z.undefined()]).optional(),
	code:               z.union([z.string(), z.undefined()]).optional()
	// offerID: z.number(),
	// title:  z.string(),
	// description:  z.string(),
	// termsAndCondition:  z.string(),
	// discount:  z.string(),
	// discountType:  z.string(),
	// buy: z.number(),
	// get: z.number(),
	// code: z.string(),
	// couponType: z.number(),
	// minimumBasketPrice:  z.string(),
});

export const OffersSchemaWithPagination = z.object({
	limit:      z.number(),
	page:       z.number(),
	sort:       z.string(),
	totalRows:  z.number(),
	totalPages: z.number(),
	rows:       z.array(OffersSchema)
});


export const DeleteOfferIds = z.object({
	offerID: z.number()
});

// export const offersSchema = z.object({ // to add schema on offers form
// 	email: z.string().email(),
// 	firstName: z.string().regex(/^[a-zA-Z\s]*$/, {message: 'Input must be letters only'}),
// 	lastName: z.string().regex(/^[a-zA-Z\s]*$/, {message: 'Input must be letters only'}),
// 	enabled: z.boolean(),
// 	credentials: z.array(signUpCredentialsSchema),
// 	attributes: z.object({
// 		Tenant_ID: z.string(),
// 	}),
// });


export type GetOffersType = z.infer<typeof OffersSchema>;
export type GetTempOffersType = z.infer<typeof TempOffersSchema>;
export type GetOffersWithPaginationType = z.infer<typeof OffersSchemaWithPagination>;

export type GetOffersParams = z.infer<typeof OffersSchemaPayload>;
export type EditOfferParams = z.infer<typeof EditOfferSchemaPayload>;

export type GetOffersWithPaginationResponse = z.infer<typeof OffersSchemaWithPagination>;
export type GetOffersResponse = z.infer<typeof OffersSchema>;

export type DeleteOfferType = z.infer<typeof DeleteOfferIds> 