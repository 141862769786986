import { AppFeatIsSaving, SIsNavigationChanges, SNavigationID } from 'store/CustomizeApp/Appearance';
import { EditNavigationSchema, TNavigation } from 'services/requests/appearance/navigation/schema';
import { SIsNavRefresh, SIsNavigationTextCheck, SNavigation } from 'store/CustomizeApp/Appearance/navigationAtom';
import { useAtomValue, useSetAtom } from 'jotai';

import { AxiosError } from 'axios';
import SideBarMutation from '../SideBar';
import { editNavigationByID } from 'services/requests/appearance/navigation';
import { showFailedAlert } from 'utility/alerts';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

const NavigationMutation = () => {
	const { id } = useParams();
	const selectedNavigationID = useAtomValue(SNavigationID);
	//Atoms for Navigation
	const navigationOrder = useAtomValue(SNavigation);
	const isNavigationTextCheck = useAtomValue(SIsNavigationTextCheck);
	const setIsSaving = useSetAtom(AppFeatIsSaving);
	const setIsNavigationRefresh = useSetAtom(SIsNavRefresh);
	const isThereChanges = useAtomValue(SIsNavigationChanges);
	const {saveSideBar} = SideBarMutation();
	//Edit Navigation
	const { mutate: editNavigation } = useMutation<
		TNavigation,
		AxiosError,
		EditNavigationSchema
	>((data) => editNavigationByID(id, selectedNavigationID, data), {
		onSuccess: () => {
			setIsNavigationRefresh(true);
			saveSideBar();
		},
		onError: () => {
			setIsSaving(false);
			showFailedAlert('Cannot save navigation, please try again');
		},
	});
    
	const saveNavigation = () => {
		if(isThereChanges){
			if(id){
				const items = navigationOrder.map((item, index) => {
					return {
						navigationOrderID: item.id,
						sortOrder: index + 1,
						visible: !item.isHidden,
					};
				});

				editNavigation({
					showLabel: isNavigationTextCheck,
					items: items,
				});
			}
		}else saveSideBar();
		
	};

	return {saveNavigation,};

};

export default NavigationMutation;