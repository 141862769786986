import { FIsFeaturesChange, FIsTermsChanges } from 'store/CustomizeApp/Features';
import { FIsTermsRefresh, FTerms, FTermsID } from '../../../../../store/CustomizeApp/Features/terms';
import React, { useEffect } from 'react';
import { getTermsOfUseByAppID, getTermsOfUseByAppIDLive } from 'services/requests/Features/TermsOfUse';
import { useAtom, useSetAtom } from 'jotai';

import { AxiosError } from 'axios';
import { GetTermsOfUseFormResponse } from 'services/requests/Features/TermsOfUse/schema';
import { PIsPublishShowTerms } from 'store/CustomizeApp/Publish/publishAtom';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { log } from 'utility/log';

export const TermsUseQuery = () => {

	//Atoms for Terms
	const [desc, setTermsDesc]                     = useAtom(FTerms);
	const [, setTermsID]                       = useAtom(FTermsID);
	const [isTermsRefresh, setIsTermsRefresh]  = useAtom(FIsTermsRefresh);
	const setFeatureChange = useSetAtom(FIsFeaturesChange);
	const setTermsChanges = useSetAtom(FIsTermsChanges);
	const setPublishShow = useSetAtom(PIsPublishShowTerms);

	const {id} = useParams();

	const {data: termsLive, refetch: refetchTermsLive} = useQuery<GetTermsOfUseFormResponse, AxiosError>({
		queryKey: ['termsOfUseLive', id],
		queryFn: () => getTermsOfUseByAppIDLive(id),
		enabled: false,
		retry: 0,
		onError: () => setIsTermsRefresh(false)
	});

	//get request
	const {data, refetch, isFetching, isFetched} = useQuery<GetTermsOfUseFormResponse, AxiosError>({
		queryKey: ['termsOfUse', id],
		queryFn: () => getTermsOfUseByAppID(id),
		enabled: false,
		retry: 0,
		onSuccess: (data) => {
			if(data?.termsOfUse){
				setTermsDesc(data?.termsOfUse?.description);
				setTermsID(data?.termsOfUse?.termsOfUseID);
				setIsTermsRefresh(false);
			}else{
				setTermsDesc('');
				setTermsID(0);
				setIsTermsRefresh(false);
			}
		},
		onError: () => setIsTermsRefresh(false)
	});

	useEffect(() =>{
		if(isTermsRefresh){
			refetch();
			refetchTermsLive();
		}
	},[isTermsRefresh]);

	// useEffect(() =>{
	// 	if(isFetching){
	// 		setFeatureChange(false);
	// 	}
	// },[isFetching]);

	useEffect(() => {
		if((data?.termsOfUse?.description || desc) && isFetched){
			if(data?.termsOfUse?.description === undefined && desc === '<p><br></p>'){
				// setFeatureChange(false);
				setTermsChanges(false);
			}
			else if(data?.termsOfUse?.description !== desc){
				// setFeatureChange(true);
				setTermsChanges(true);
				log('TermsChangesTrue');
			} else {
				// setFeatureChange(false);
				setTermsChanges(false);
			}
		} else {
			// setFeatureChange(false);
			setTermsChanges(false);
		}
	},[desc, data, isFetched]);

	useEffect(() => {
		if(data?.termsOfUse && termsLive?.termsOfUse){
			if(data?.termsOfUse?.description !== termsLive?.termsOfUse?.description){
				setPublishShow(true);
				log('TermsPublishTrue');
			}else setPublishShow(false);
		}
	},[data, termsLive]);
};
