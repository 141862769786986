import React, { ReactElement, ReactNode } from 'react';
import { Tooltip as MUITooltip, Zoom } from '@mui/material';

interface ITooltip {
	title: string
	bgColor?: string
	textColor?: string
	placement?: "bottom" | "bottom-end" | "bottom-start" | "left-end" | "left-start" | "left" | "right-end" | "right-start" | "right" | "top-end" | "top-start" | "top" | undefined
	children: ReactNode
	offsetX?: number
	offsetY?: number
}

export const Tooltip = (props: ITooltip) => {
	return (
		<MUITooltip
			title={props.title}
			placement={props.placement}
			TransitionComponent={Zoom}
			PopperProps={{
				modifiers: [
					{
						name: 'offset',
						options: {
							offset: [props.offsetY ? props.offsetY : 0, props.offsetX ? props.offsetX : -20],
						},
					},
				],
			}}
			componentsProps={{
				tooltip: {
					sx: {
						bgcolor: props.bgColor ? props.bgColor : '#9CA3AF',
						color: props.textColor ? props.textColor : '#FFFFFF'
					}
				}
			}}
		>
			<div>
				{props.children}
			</div>
		</MUITooltip>
	);
};