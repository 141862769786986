import { FAccessibility, FAccessibilityID, FIsAccessibilityRefresh } from '../../../../../store/CustomizeApp/Features/accessibility';
import { FIsAccessChanges, FIsFeaturesChange } from 'store/CustomizeApp/Features';
import React, { useEffect } from 'react';
import { getAccessibilityByAppID, getAccessibilityByAppIDLive } from 'services/requests/Features/Accessibility';
import { useAtom, useSetAtom } from 'jotai';

import { AxiosError } from 'axios';
import { GetAccessibilityFormResponse } from 'services/requests/Features/Accessibility/schema';
import { PIsPublishShowAccess } from 'store/CustomizeApp/Publish/publishAtom';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { log } from 'utility/log';

export const AccessibilityUseQuery = () => {

	//Atoms for Privacy
	const [desc, setAccessibilityDesc]       		= useAtom(FAccessibility);
	const [accessibilityID, setAccessibilityID]           		= useAtom(FAccessibilityID);
	const [isAccessRefresh, setIsAccessRefresh] = useAtom(FIsAccessibilityRefresh);
	const setFeatureChange = useSetAtom(FIsFeaturesChange);
	const setAccessChanges = useSetAtom(FIsAccessChanges);
	const setPublishShow = useSetAtom(PIsPublishShowAccess);

	const {id} = useParams();
	const {data: accessLive, refetch: refetchAccessLive} = useQuery<GetAccessibilityFormResponse, AxiosError>({
		queryKey: ['accessibilityLive', id],
		queryFn: () => getAccessibilityByAppIDLive(id),
		enabled: false,
		retry: 0,
		onError: () => setIsAccessRefresh(false)
	});

	//get request
	const {data, refetch, isFetching, isFetched} = useQuery<GetAccessibilityFormResponse, AxiosError>({
		queryKey: ['accessibility', id],
		queryFn: () => getAccessibilityByAppID(id),
		enabled: false,
		retry: 0,
		onSuccess: (data) => {
			if(data?.accessibility){
				setAccessibilityDesc(data?.accessibility?.description);
				setAccessibilityID(data?.accessibility?.accessibilityID);
				setIsAccessRefresh(false);
			}else{
				setAccessibilityDesc('');
				setAccessibilityID(0);
				setIsAccessRefresh(false);
			}
		},
		onError: () => setIsAccessRefresh(false)
	});


	useEffect(() =>{
		if(isAccessRefresh){
			refetch();
			refetchAccessLive();
		}
	}, [isAccessRefresh]);

	// useEffect(() =>{
	// 	if(isFetching){
	// 		setFeatureChange(false);
	// 	}
	// },[isFetching]);

	useEffect(() => {
		if((data?.accessibility?.description || desc )&& isFetched){
			if(data?.accessibility?.description === undefined && desc === '<p><br></p>'){
				// setFeatureChange(false);
				setAccessChanges(false);
			}
			else if(data?.accessibility?.description !== desc){
				// setFeatureChange(true);
				setAccessChanges(true);
				log('AccessChangesTrue');
			} else {
				// setFeatureChange(false);
				setAccessChanges(false);
			}
		} else {
			// setFeatureChange(false);
			setAccessChanges(false);
		}
	},[desc, data, isFetched]);

	useEffect(() => {
		if(data?.accessibility && accessLive?.accessibility){
			if(data?.accessibility?.description !== accessLive?.accessibility?.description){
				setPublishShow(true);
				log('AccessPublishTrue');
			}else setPublishShow(false);
		}
	},[data, accessLive]);
};