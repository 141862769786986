import { GetTermsOfUseFormType, GetTermsOfUseSchema, GetTermsOfUseFormResponse } from './schema';

import { axiosInstance } from 'services/axios';

export const getTermsOfUseByAppIDLive = async (appID?: string) => {
	const response = await axiosInstance.get(`/organizations/apps/${appID}/termsofuse?env=LIV`);
    
	return response.data as GetTermsOfUseFormResponse;
};

export const getTermsOfUseByAppID = async (appID?: string) => {
	const response = await axiosInstance.get(`/organizations/apps/${appID}/termsofuse`);
    
	return response.data as GetTermsOfUseFormResponse;
};

export const createTermsOfUse = async (appID?: string, data?: GetTermsOfUseFormType) => {
	const validatedTermsOfUse = GetTermsOfUseSchema.parse(data);
	const response = await axiosInstance.post(`/organizations/apps/${appID}/termsofuse`, validatedTermsOfUse);
	return response.data as GetTermsOfUseFormResponse;
};

export const editTermsOfUseByAppID = async (appID?: string, termsID?: number, param?: GetTermsOfUseFormType) => {
	const response = await axiosInstance.put(`/organizations/apps/${appID}/termsofuse/${termsID}`, param);
    
	return response.data as GetTermsOfUseFormResponse;
};