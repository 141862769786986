import {
	CreateBulkNotificationType,
	EditNotificationResponse,
} from 'services/requests/Features/Notification/schema';
import {
	FAddNotifPayload,
	FEditNotifPayload,
	FIsNotifcationRefresh,
	FNotif,
} from 'store/CustomizeApp/Features/notificationAtom';
import {
	createBulkNotification,
	editBulkNotification,
} from 'services/requests/Features/Notification';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { AppFeatIsSaving } from 'store/CustomizeApp/Appearance';
import { AxiosError } from 'axios';
import React from 'react';
import SocialMediaMutation from '../SocialMedia';
import { showFailedAlert } from 'utility/alerts';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { FIsNotifChanges } from 'store/CustomizeApp/Features';

const NotificationMutation = () => {
	const { id } = useParams();
	const [notificationData, setNotifData] = useAtom(FNotif);
	const [addNotificationData, setAddNotificationData] =
		useAtom(FAddNotifPayload);
	const [editNotificationData, setEditNotificationData] =
		useAtom(FEditNotifPayload);
	const setIsNotifRefresh = useSetAtom(FIsNotifcationRefresh);
	const isThereChanges = useAtomValue(FIsNotifChanges);
	const { saveSocialMedia } = SocialMediaMutation();
	const setIsSaving = useSetAtom(AppFeatIsSaving);
	
	const queryClient = useQueryClient();

	//Create Notification
	const { mutate: addNotificationDataMU } = useMutation<
		EditNotificationResponse[],
		AxiosError,
		CreateBulkNotificationType
	>((data) => createBulkNotification(id, data), {
		onSuccess: () => {
			queryClient.invalidateQueries('notifications');
			setIsNotifRefresh(true);
			setAddNotificationData({
				notifications: [],
			});
		},
		onError: (error) => {
			setIsSaving(false);
			showFailedAlert('Cannot add Notification.');
		},
	});

	//Put Notification
	const { mutate: sendEditNotificationMU } = useMutation<
		EditNotificationResponse,
		AxiosError,
		CreateBulkNotificationType
	>((data) => editBulkNotification(id, data), {
		onSuccess: (data) => {
			queryClient.invalidateQueries('notifications');
			setIsNotifRefresh(true);
			setEditNotificationData({
				notifications: [],
			});
		},
		onError: (error) => {
			setIsSaving(false);
			showFailedAlert('Cannot edit Notification.');
		},
	});

	const saveNotifications = () => {
		if (isThereChanges) {
			if (
				!notificationData?.rows?.length &&
				!addNotificationData?.notifications?.length
			) {
				return saveSocialMedia();
			}
			if (addNotificationData?.notifications?.length != 0) {
				const addNotifFiltered =
					addNotificationData.notifications.filter(
						(notif) =>
							notif.title !== '' &&
							notif.message !== '' &&
							notif.url !== ''
					);
				if (addNotifFiltered.length)
					addNotificationDataMU({ notifications: addNotifFiltered });
			}

			let newNotifications = [...editNotificationData.notifications];
			for (
				let index = 0;
				index < notificationData?.rows.length;
				index++
			) {
				if (notificationData?.rows[index].appID.toString() === id) {
					const pushNotif = {
						message: notificationData?.rows[index].message,
						icon: notificationData?.rows[index].icon,
						title: notificationData?.rows[index].title,
						url: notificationData?.rows[index].url,
						notificationId:
							notificationData?.rows[index].notificationID,
					};
					newNotifications = [...newNotifications, pushNotif];
				}
			}

			const params = {
				notifications: newNotifications,
			};
			params.notifications.map((editNotif, idx) => {
				if (
					editNotif.title !== '' ||
					editNotif.message !== '' ||
					editNotif.url !== ''
				) {
					sendEditNotificationMU(params);
				}
			});

			saveSocialMedia();
		} else saveSocialMedia();
	};

	return { saveNotifications };
};

export default NotificationMutation;
