import FormInput from 'components/Common/Forms/Inputs';
import { CameraIcon } from '@heroicons/react/24/solid';
import { Button } from 'components/Common/Buttons';
import { TrashIcon } from '@heroicons/react/24/outline';
import { PlusSmallIcon } from '@heroicons/react/24/outline';
const SubscriptionPanel: React.FC = () => {
	return (
		<div className='w-full p-10'>
			<div className='flex flex-row items-center justify-between'>
				<div className='text-[#707070] text-[18px] font-bold self-start'>
					Subscription
				</div>
			</div>
			<hr className='mt-4' />
			<div className='flex flex-row justify-center items-center w-full'>
				<div className=' overflow-x-auto shadow-md w-full sm:rounded-lg mt-[13.5px]'>
					<table className='w-full text-sm text-left text-gray-500 dark:text-gray-400'>
						<thead className='text-xs text-gray-700 uppercase bg-[#F7F9FB] border-[#F0F0F0] border-2'>
							<tr>
								<th scope='col' className='px-6 py-3'>
									Company Name
								</th>
								<th scope='col' className='px-6 py-3'>
									Date Created
								</th>
								<th scope='col' className='px-6 py-3'>
									Plan Type
								</th>
								<th scope='col' className='px-6 py-3'>
									Amount
								</th>
								<th scope='col' className='px-4 py-3 text-center'>
									Action
								</th>
							</tr>
						</thead>
						<tbody>
							<tr className='bg-white border-b text-gray-500'>
								<th
									scope='row'
									className='px-6 py-4 font-medium whitespace-nowrap flex items-center'
								>
									<img
										className='w-[70px] h-[70px] bg-slate-300 rounded-full'
										src={
											'https://th.bing.com/th/id/OIP.NFIZCCGR7OqwvCrpxQX-1AHaE7?w=254&h=180&c=7&r=0&o=5&pid=1.7'
										}
									/>
									<span className='ml-12'>Yo-Kai</span>
								</th>
								<td className='px-6 py-4'>11-23-2023</td>
								<td className='px-6 py-4'>Monthly</td>
								<td className='pl-6 py-4'>$600.00</td>
								<td className='px-4 py-4 text-center'>
									<Button
										className='text-[16px] text-black font-semibold py-[4px] px-[17px]'
										variant='secondary'
									>
										Cancel Subscription
									</Button>
								</td>
							</tr>
							<tr className='border-b text-gray-500'>
								<th
									scope='row'
									className='px-6 py-4 font-medium whitespace-nowrap flex items-center'
								>
									<img
										className='w-[70px] h-[70px] bg-slate-300 rounded-full'
										src={
											'https://th.bing.com/th/id/OIP.NFIZCCGR7OqwvCrpxQX-1AHaE7?w=254&h=180&c=7&r=0&o=5&pid=1.7'
										}
									/>
									<span className='ml-12'>Hooli</span>
								</th>
								<td className='px-6 py-4'>11-23-2023</td>
								<td className='px-6 py-4'>Yearly</td>
								<td className='pl-6 py-4'>$1200.00</td>
								<td className='px-4 py-4 text-center'>
									<Button className='text-[16px] self-center text-white font-semibold py-[4px] px-[17px] bg-[#FF7F00] '>
										Upgrade
									</Button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default SubscriptionPanel;
