import { IMAGES } from 'utility';
import FormInput from 'components/Common/Forms/Inputs';
import React from 'react';
import { FStore } from 'store/CustomizeApp/Features/storeAtom';
import { useAtom } from 'jotai';
import { StoreUseQuery } from '../UseQuery/StoreQueries';
import Tooltip from 'components/Common/Tooltip';

const StorePage: React.FC = () => {

	const [storeName, setStoreName]                   = useAtom(FStore);

	const handleChange = (value: string) => {
		setStoreName(value);
	};

	return (
		<div className='bg-white w-full rounded-lg my-8'>
			<div className='bg-white px-3 mx-3 rounded'>
				<div className='py-5 pb-20 flex-row'>
					<div className='flex justify-between items-center py-2'>
						<span className='text-[#707070] font-bold text-2xl'>Store</span>
						<div className='flex gap-3 items-center'>
							{/* -----------Video Tutorial and Help Icons------------ */}
							<Tooltip className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
								<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.CameraIcon} alt='camera icon' />
							</Tooltip>
							<Tooltip className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
								<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.InfoIcon} alt='info icon' />
							</Tooltip>
							{/* ---------------------------------------------------- */}
						</div>
					</div>
					
					<div className='flex flex-col text-center items-center py-5 mt-7'>
						<p className='text-[#707070] text-base max-sm:text-sm font-medium opacity-90'> Do you already have an online store? If so, please share with us <br /> the store you wish to integrate in the field below. <br /> </p>
						<p className='text-[#707070] text-base max-sm:text-sm font-medium opacity-90 mt-12'>Our technical team will assist you in incorporating your <br /> storefront upon publishing your application.</p> 
						<div className='w-[25rem] max-sm:w-[95%]'>
							<FormInput value={storeName} onChange={handleChange} type='text' 
								placeholder='Ex. Shopify, WooCommerce, Etsy, SquareSpace' className='text-lg font-medium text-black text-center h-12 max-sm:w-[90%] mt-14'></FormInput>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default StorePage;