//import { FAQs } from 'pages/FAQs';
import AmazonPay from 'assets/PaymentMethodIcons/amazon-pay.svg';
import ArtDesignLogo from 'assets/SelectIndustry/ArtDesignLogo.svg';
import AutoTransportationLogo from 'assets/SelectIndustry/AutoTransportationLogo.svg';
import BeautyLogo from 'assets/SelectIndustry/BeautyLogo.svg';
import Bg1 from 'assets/CustomizeApp/Appearance/Background/Bg1.png';
import Bg2 from 'assets/CustomizeApp/Appearance/Background/Bg2.png';
import Bg3 from 'assets/CustomizeApp/Appearance/Background/Bg3.png';
import BusinessFinanceLogo from 'assets/SelectIndustry/BusinessFinanceLogo.svg';
import CameraIcon from 'assets/CustomizeApp/Appearance/Navigation/camera.svg';
import ConstructionLogo from 'assets/SelectIndustry/ConstructionLogo.svg';
import DeleteIcon from 'assets/DeleteIcon.svg';
import ECommerceLogo from 'assets/SelectIndustry/ECommerceLogo.svg';
import EditIcon from 'assets/EditIcon.svg';
import EducationLogo from 'assets/SelectIndustry/EducationLogo.svg';
import EmptyDashboard from 'assets/EmptyDashboard.svg';
import Favicon from 'assets/CustomizeApp/Appearance/Images/favicon.png';
import FooterFacebook from 'assets/LandingPage/facebook.svg';
import FooterInstagram from 'assets/LandingPage/instagram.svg';
import FooterLinkedIn from 'assets/LandingPage/LinkedIn.svg';
import FooterPinterest from 'assets/LandingPage/Pinterest.svg';
import FooterTwitter from 'assets/LandingPage/twitter.svg';
import FooterYoutube from 'assets/LandingPage/youtube.svg';
import GoogleButtonIcon from 'assets/GoogleButtonIcon.svg';
import GraphMetrics from 'assets/GraphMetrics.png';
import GooglePay from 'assets/PaymentMethodIcons/google-pay.svg';
import GreenUpIcon from 'assets/GreenUpIcon.png';
import HealthLogo from 'assets/SelectIndustry/HealthLogo.svg';
import Home1 from 'assets/CustomizeApp/Appearance/Theme/HomePage1.png';
import Home2 from 'assets/CustomizeApp/Appearance/Theme/HomePage2.png';
import Home3 from 'assets/CustomizeApp/Appearance/Theme/HomePage3.png';
import HomeServiceLogo from 'assets/SelectIndustry/HomeServiceLogo.svg';
import InfoIcon from 'assets/CustomizeApp/Appearance/Navigation/info.svg';
import LandingImage from 'assets/LandingImage.png';
import LandingImageLaptop from 'assets/LandingPage/PreviewBuildingMB.png';
import LandingImageLaptop2 from 'assets/LandingPage/PreviewBuildingMB2.png';
import LandingImageSocial from 'assets/LandingPage/SocialImages.png';
import LinkedInIcon from 'assets/GetInTouch/LinkedInIcon.svg';
import MailIcon from 'assets/GetInTouch/Mail.svg';
import MetricsIcon from 'assets/MetricsIcon.svg';
import Nav1 from 'assets/CustomizeApp/Appearance/Navigation/Nav1.png';
import Nav2 from 'assets/CustomizeApp/Appearance/Navigation/Nav2.png';
import Nav3 from 'assets/CustomizeApp/Appearance/Navigation/Nav3.png';
import Nav4 from 'assets/CustomizeApp/Appearance/Navigation/Nav4.png';
import NonProfitCausesLogo from 'assets/SelectIndustry/NonProfitCausesLogo.svg';
import Ogtags from 'assets/CustomizeApp/Appearance/Images/ogtags.png';
import PaypalPay from 'assets/PaymentMethodIcons/paypal.svg';
import PersonalFamilyLogo from 'assets/SelectIndustry/PersonalFamilyLogo.svg';
import PhoneIcon from 'assets/GetInTouch/Phone.svg';
import PlaceholderPreview from 'assets/AppPreview.png';
import PlusCircleIcon from 'assets/PlusCircleIcon.svg';
import PrivacyPolicyImage from 'assets/privacyPolicy.png';
import ProfessionalServicesLogo from 'assets/SelectIndustry/ProfessionalServicesLogo.svg';
import RealEstateLogo from 'assets/SelectIndustry/RealEstateLogo.svg';
import RecreationLogo from 'assets/SelectIndustry/RecreationLogo.svg';
import RestaurantLogo from 'assets/SelectIndustry/RestaurantLogo.svg';
import SearchIcon from 'assets/SearchIcon.svg';
import ShopPay from 'assets/PaymentMethodIcons/shop-pay.svg';
import SnapToAppLogo from 'assets/LandingPage/Snap2App.svg';
import SportsLogo from 'assets/SelectIndustry/SportsLogo.svg';
import TermsOfUseImage from 'assets/termsOfUse.png';
import TravelLogo from 'assets/SelectIndustry/TravelLogo.svg';
import Triangle from 'assets/CustomizeApp/Appearance/Font/Triangle.svg';
import Upload from 'assets/CustomizeApp/Appearance/Welcome/upload.png';
import UploadFeat from 'assets/CustomizeApp/Features/TabIcons/upload.png';
import YokaiExpressLogo from 'assets/YokaiExpressLogo.svg';
import addCreditCard from 'assets/addCreditCard.png';
import copyClipboard from 'assets/CustomizeApp/Publish/copyClipboard.svg';
import inReviewImage from 'assets/InReviewImage.svg';
import landingPageFeatures1 from 'assets/LandingPage/Features/IntuitiveInterface.png';
import landingPageFeatures2 from 'assets/LandingPage/Features/CuttingEdge.png';
import landingPageFeatures3 from 'assets/LandingPage/Features/Integration.png';
import landingPageFeatures4 from 'assets/LandingPage/Features/Speed.png';
import landingPageFeatures5 from 'assets/LandingPage/Features/Collaborative.png';
import landingPageFeatures6 from 'assets/LandingPage/Features/CostEffective.png';
import landingPageFeatures7 from 'assets/LandingPage/Features/Group 10522.png';
import landingPageHome1 from 'assets/LandingPage/Home/MobAppPreview1.png';
import landingPageHome2 from 'assets/LandingPage/Home/MobAppPreview2.png';
import landingPageHome3 from 'assets/LandingPage/Home/MobAppPreview3.png';
import landingPageHome4 from 'assets/LandingPage/Home/Boba.png';
import landingPageWorks1 from 'assets/LandingPage/HowItWorks/CustomizeAppJiffy.png';
import landingPageWorks2 from 'assets/LandingPage/HowItWorks/DragDrop.png';
import landingPageWorks3 from 'assets/LandingPage/HowItWorks/SubmitApp.png';
import snapLogoIcon from 'assets/SnapLogoIcon.svg';
import TelemetryRateUpIcon from 'assets/Dashboard/TelemetryRateUpIcon.png';
import DashboardTelemetryCalendarIcon from 'assets/Dashboard/DashboardTelemetry-CalendarIcon.svg';
import DashboardTelemetrySelectedDateCheck from 'assets/Dashboard/DashboardTelemetrySelectedDateCheck.svg';
import PlaceholderIcon from 'assets/Dashboard/PlaceholderIcon.svg';
import userIcon from 'assets/user-icon.png';
import userTopBarHamburgerIcon from 'assets/HamburgerIcon.svg';
import userTopBarModalBuildYourApp from 'assets/TopBarModal/buildyourapp.svg';
import userTopBarModalContactUs from 'assets/TopBarModal/contactus.svg';
import userTopBarModalDashbaord from 'assets/TopBarModal/dashboard.svg';
import userTopBarModalLandingPageSubscription from 'assets/TopBarModal/creditcard.svg';
import userTopBarModalLogout from 'assets/TopBarModal/logout.svg';
import userTopBarModalMyAccount from 'assets/TopBarModal/myaccount.svg';
import userTopBarModalReseller from 'assets/TopBarModal/reseller.svg';
import userTopBarModalSubscription from 'assets/TopBarModal/subscription.svg';
import userTopBarXIcon from 'assets/x-icon.svg';
import SearchNotFound from 'assets/SearchNotFound.svg';
import subscriptionLiteIcon from 'assets/SubscriptionIcons/SubscriptionLiteIcon.svg';
import subscriptionPremiumIcon from 'assets/SubscriptionIcons/SubscriptionPremiumIcon.svg';
import subscriptionStandardIcon from 'assets/SubscriptionIcons/SubscriptionStandardIcon.svg';
import FooterTwitterX from 'assets/FooterTwitterX.svg';
import FacebookIcon from 'assets/FacebookIcon(NB).svg';
import LinkedInIconNB from 'assets/LinkedInIcon(NB).svg';
import FaqsImage from 'assets/FAQsImage.png';
import EmptyState from 'assets/EmptyState.svg';
import AccessibilityImage from 'assets/AccessibilityImage.png';

export const IMAGES = {
	//UserTopBar
	userTopBarHamburgerIcon,
	userTopBarXIcon,
	userTopBarModalLogout,
	userTopBarModalMyAccount,
	userTopBarModalReseller,
	userTopBarModalSubscription,
	userTopBarModalBuildYourApp,
	userTopBarModalDashbaord,
	userTopBarModalContactUs,
	userTopBarModalLandingPageSubscription,

	//LandingPage
	LandingImage,
	LandingImageLaptop,
	LandingImageLaptop2,
	LandingImageSocial,
	FooterFacebook,
	FooterInstagram,
	FooterYoutube,
	FooterTwitter,
	FooterLinkedIn,
	FooterPinterest,
	FooterTwitterX,
	FacebookIcon,
	LinkedInIconNB,

	//LandingPageHome
	landingPageHome1,
	landingPageHome2,
	landingPageHome3,
	landingPageHome4,

	//LandingPageFeatures
	landingPageFeatures1,
	landingPageFeatures2,
	landingPageFeatures3,
	landingPageFeatures4,
	landingPageFeatures5,
	landingPageFeatures6,
	landingPageFeatures7,

	//LandingPageHowItWorks
	landingPageWorks1,
	landingPageWorks2,
	landingPageWorks3,

	//Dashboard
	TelemetryRateUpIcon,
	DashboardTelemetryCalendarIcon,
	DashboardTelemetrySelectedDateCheck,
	SearchNotFound,
	PlaceholderIcon,

	//Industry Images
	ArtDesignLogo,
	AutoTransportationLogo,
	BeautyLogo,
	BusinessFinanceLogo,
	ConstructionLogo,
	ECommerceLogo,
	EducationLogo,
	HealthLogo,
	HomeServiceLogo,
	NonProfitCausesLogo,
	PersonalFamilyLogo,
	ProfessionalServicesLogo,
	RealEstateLogo,
	RecreationLogo,
	RestaurantLogo,
	SportsLogo,
	TravelLogo,

	//App Preview
	PlaceholderPreview,

	//Background
	CameraIcon,
	InfoIcon,
	Bg1,
	Bg2,
	Bg3,

	//Font
	Triangle,

	//Images
	Favicon,
	Ogtags,

	//Navigation
	Nav1,
	Nav2,
	Nav3,
	Nav4,

	//Theme
	Home1,
	Home2,
	Home3,

	//Welcome
	Upload,

	//SideBar
	YokaiExpressLogo,
	SearchIcon,
	PlusCircleIcon,
	SnapToAppLogo,

	//MetricCard
	GraphMetrics,
	MetricsIcon,
	GreenUpIcon,

	//TopBar
	userIcon,

	//ManageAppCard
	EditIcon,
	DeleteIcon,

	//Features
	UploadFeat,

	//GetInTouch
	MailIcon,
	PhoneIcon,
	LinkedInIcon,

	//cardsManagement
	addCreditCard,

	//Publish
	snapLogoIcon,
	inReviewImage,
	copyClipboard,

	//ManageApp
	EmptyDashboard,

	//Google SSO button
	GoogleButtonIcon,

	//PrivacyPolicy
	PrivacyPolicyImage,

	//TermsOfUse
	TermsOfUseImage,

	//FaQs
	FaqsImage,
	//Accessibility
	AccessibilityImage,

	//Payment Method Icons
	PaypalPay,
	ShopPay,
	AmazonPay,
	GooglePay,

	//Subscription Icons
	subscriptionLiteIcon,
	subscriptionPremiumIcon,
	subscriptionStandardIcon,

	//Empty State
	EmptyState,
};

export const lowercaseRegex = /(?=.*[a-z])/;
export const uppercaseRegex = /(?=.*[A-Z])/;
export const numericRegex = /(?=.*\d)/;
export const specialRegex = /(?=.*[@$!%*#?&])/;

export const passwordRegex = [
	{
		regex: lowercaseRegex,
		description: 'At least 1 lower case character.',
		accessibility: 'You need to input at least 1 lower case character.',
	},
	{
		regex: uppercaseRegex,
		description: 'At least 1 upper case character.',
		accessibility: 'You need to input at least 1 upper case character.',
	},
	{
		regex: numericRegex,
		description: 'At least 1 numerical number character.',
		accessibility: 'You need to input at least 1 number.',
	},
	{
		regex: specialRegex,
		description: 'At least 1 special character.',
		accessibility: 'You need to input at least 1 special character.',
	},
];

export const countryList = [
	{ name: 'Argentina', value: 'Argentina' },
	{ name: 'Australia', value: 'Australia' },
	{ name: 'Austria', value: 'Austria' },
	{ name: 'Bangladesh', value: 'Bangladesh' },
	{ name: 'Belgium', value: 'Belgium' },
	{ name: 'Brazil', value: 'Brazil' },
	{ name: 'Canada', value: 'Canada' },
	{ name: 'China', value: 'China' },
	{ name: 'Denmark', value: 'Denmark' },
	{ name: 'Egypt', value: 'Egypt' },
	{ name: 'Ethiopia', value: 'Ethiopia' },
	{ name: 'Finland', value: 'Finland' },
	{ name: 'France', value: 'France' },
	{ name: 'Germany', value: 'Germany' },
	{ name: 'Ghana', value: 'Ghana' },
	{ name: 'Greece', value: 'Greece' },
	{ name: 'India', value: 'India' },
	{ name: 'Indonesia', value: 'Indonesia' },
	{ name: 'Iran', value: 'Iran' },
	{ name: 'Iraq', value: 'Iraq' },
	{ name: 'Ireland', value: 'Ireland' },
	{ name: 'Israel', value: 'Israel' },
	{ name: 'Italy', value: 'Italy' },
	{ name: 'Japan', value: 'Japan' },
	{ name: 'Kenya', value: 'Kenya' },
	{ name: 'Malaysia', value: 'Malaysia' },
	{ name: 'Mexico', value: 'Mexico' },
	{ name: 'Morocco', value: 'Morocco' },
	{ name: 'Netherlands', value: 'Netherlands' },
	{ name: 'New Zealand', value: 'New Zealand' },
	{ name: 'Nigeria', value: 'Nigeria' },
	{ name: 'Norway', value: 'Norway' },
	{ name: 'Pakistan', value: 'Pakistan' },
	{ name: 'Philippines', value: 'Philippines' },
	{ name: 'Portugal', value: 'Portugal' },
	{ name: 'Russia', value: 'Russia' },
	{ name: 'Saudi Arabia', value: 'Saudi Arabia' },
	{ name: 'Singapore', value: 'Singapore' },
	{ name: 'South Africa', value: 'South Africa' },
	{ name: 'South Korea', value: 'South Korea' },
	{ name: 'Spain', value: 'Spain' },
	{ name: 'Sri Lanka', value: 'Sri Lanka' },
	{ name: 'Sweden', value: 'Sweden' },
	{ name: 'Switzerland', value: 'Switzerland' },
	{ name: 'Tanzania', value: 'Tanzania' },
	{ name: 'Thailand', value: 'Thailand' },
	{ name: 'Tunisia', value: 'Tunisia' },
	{ name: 'Turkey', value: 'Turkey' },
	{ name: 'Uganda', value: 'Uganda' },
	{ name: 'United Arab Emirates', value: 'United Arab Emirates' },
	{ name: 'United Kingdom', value: 'United Kingdom' },
	{ name: 'United States', value: 'United States' },
	{ name: 'Vietnam', value: 'Vietnam' },
];

export const formatDate = (dateString: any) => {
	const date = new Date(dateString);
	const outputDateString = `${date.getMonth() + 1
	}/${date.getDate()}/${date.getFullYear()}  ${date.toLocaleTimeString([], {
		hour: 'numeric',
		minute: 'numeric',
	})}`;
	return outputDateString;
};

export const capitalizeWords = (str: any) => {
	if (str) {
		const words = str.split(' ');
		for (let i = 0; i < words.length; i++) {
			words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
		}
		return words.join(' ');
	}
};
