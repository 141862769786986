import {
	FGallery,
	FGalleryAlbumIdAtom,
	FGalleryImageAtom,
	FIsGalleryRefresh,
	FUpdateGallery,
} from 'store/CustomizeApp/Features/galleryAtom';
import {
	FIsFeaturesChange,
	FIsGalleryChanges,
} from 'store/CustomizeApp/Features';
import {
	getAllGalleryApi,
	getAllGalleryApiLive,
} from 'services/requests/Features/Gallery';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { AxiosError } from 'axios';
import { GetGalleryResponseType } from 'services/requests/Features/Gallery/schema';
import { PIsPublishShowGallery } from 'store/CustomizeApp/Publish/publishAtom';
import { useParams } from 'react-router-dom';
import { log } from 'utility/log';

export const GalleryUseQuery = () => {
	//Atoms for Gallery
	const [isGalleryRefresh, setIsGalleryRefresh] = useAtom(FIsGalleryRefresh);
	const [accordionImages, setAccordionImages] = useAtom(FGalleryImageAtom);
	const accordionDatas = useAtomValue(FGallery);
	const albumsToDelete = useAtomValue(FGalleryAlbumIdAtom);
	const setToUpdate = useSetAtom(FUpdateGallery);
	const setFeaturesChanges = useSetAtom(FIsFeaturesChange);
	const setGalleryChanges = useSetAtom(FIsGalleryChanges);
	const setPublishShow = useSetAtom(PIsPublishShowGallery);

	const queryClient = useQueryClient();

	const { id } = useParams();

	const { data: galleryLive, refetch: refetchGalleryLive } = useQuery<
		GetGalleryResponseType,
		AxiosError
	>({
		queryKey: ['galleryLive', id],
		queryFn: () => getAllGalleryApiLive(id),
		enabled: false,
		retry: 0,
		onError: () => setIsGalleryRefresh(false),
	});

	//get request
	const { data, refetch, isFetching, isFetched } = useQuery<
		GetGalleryResponseType,
		AxiosError
	>({
		queryKey: ['gallery', id],
		queryFn: () => getAllGalleryApi(id),
		retry: 0,
		staleTime: Infinity,
		onSuccess: (data) => {
			if (data) {
				if (accordionImages?.length > 0) {
					data?.rows?.map((album, i) => {
						if (album.images) {
							album?.images?.map((img, j) => {
								accordionImages[i].images[j].imageUploadId = img.imageUploadID;
							});
						}

						accordionImages[i].albumId = album.albumID;
						accordionImages[i].coverIndex = album?.coverIndex ? album?.coverIndex : 0,
						accordionImages[i].isCoverIndexNew = false;
						accordionImages[i].isTitleNew = false;
						accordionImages[i].hasImgToUpload = false;
						accordionImages[i].hasImgToDelete = false;
					});	
				} else {
					const albums = data?.rows?.map((album) => {
						const albumImages = album?.images?.map((img, index) => {
							return {
								index: index,
								imageUploadId: img?.imageUploadID,
								name: img?.name,
								url: img?.imageURL,
							};
						});
	
						return {
							albumId: album?.albumID,
							coverIndex: album?.coverIndex ? album?.coverIndex : 0,
							title: album?.title,
							images: albumImages,
							isCoverIndexNew: false,
							isTitleNew: false,
							hasImgToUpload: false,
							hasImgToDelete: false
						};
					});
	
					setAccordionImages(albums);				
				}

				const toUpdate = data?.rows?.map((album) => {
					return {
						albumId: album?.albumID,
						coverIndex: album?.coverIndex ? album?.coverIndex : 0,
						title: album?.title,
						images: [],
					};
				});

				setToUpdate(toUpdate);
				setIsGalleryRefresh(false);
			}
		},
		onError: () => {
			queryClient.setQueryData(['gallery', id], {});
			setIsGalleryRefresh(false);
		},
	});

	useEffect(() => {
		if (isGalleryRefresh) {
			refetch();
			refetchGalleryLive();
		}
	}, [isGalleryRefresh]);

	// useEffect(() => {
	// 	if (isFetching) {
	// 		setFeaturesChanges(false);
	// 	}
	// }, [isFetching]);

	useEffect(() => {
		if (data?.rows && isFetched) {
			const changes = accordionImages.filter((album) => {
				return album.isCoverIndexNew === true || album.isTitleNew === true || album.hasImgToDelete === true || album.hasImgToUpload === true;
			});

			const albumIdsToDelete = albumsToDelete.filter((id) => id !== undefined);
			
			if (changes.length > 0 || albumIdsToDelete.length > 0) {
				// setFeaturesChanges(true);
				setGalleryChanges(true);
				log('gallery 1');
			} else {
				// setFeaturesChanges(false);
				setGalleryChanges(false);
			}
		} else if (accordionDatas.length > 0) {
			// setFeaturesChanges(true);
			setGalleryChanges(true);
			log('gallery 2');
		} else {
			// setFeaturesChanges(false);
			setGalleryChanges(false);
		} 
	}, [data, accordionDatas, accordionImages, isFetched]);

	useEffect(() => {
		if (data?.rows !== undefined && galleryLive?.rows !== undefined) {
			const checkDraftLive = data?.rows?.map((current) => ({
				match: galleryLive?.rows?.some(
					(live) =>
						live?.title === current?.title &&
						live?.images?.length === current?.images?.length
				),
			}));
			let checkImages = [{ matchImage: false }];

			data?.rows?.map((current) => {
				galleryLive?.rows?.map((live) => {
					checkImages = current?.images?.map((currentImage) => ({
						matchImage: live?.images?.some(
							(liveImage) =>
								liveImage.imageURL === currentImage.imageURL
						),
					}));
				});
			});

			if (
				data?.rows?.length !== galleryLive?.rows?.length ||
				checkDraftLive.find((check) => check.match === false) !==
					undefined ||
				checkImages?.find(
					(checkImage) => checkImage.matchImage === false
				) !== undefined
			) {
				setPublishShow(true);
				log('galleryPublishShow');
			} else setPublishShow(false);
		}
	}, [data, galleryLive]);
};
