import { atom } from 'jotai';

export const SHeadlineTextFont   = atom('Arial');
export const SHeadlineTextFontID   = atom<number>(1);
export const SNavigationTextFont = atom('Arial');
export const SNavigationTextFontID = atom<number>(1);
export const SFonts              = atom([{label: '', value: ''}]);
export const SFontData			 = atom([{fontID: 0, name: '', description: '' , status: ''}]);
export const SDefaultFontSize    = atom<number>(9);
export const SIsFontRefresh      = atom<boolean>(true);
export const SFontSizes           = atom([
	{label: '8', value: '8'},
	{label: '9', value: '9'},
	{label: '10', value: '10'},
	{label: '11', value: '11'},
	{label: '12', value: '12'},
	{label: '13', value: '13'},
	{label: '14', value: '14'},
]);