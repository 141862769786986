/* eslint-disable no-mixed-spaces-and-tabs */

import {
  DocumentTextIcon,
  ExclamationCircleIcon,
  HomeIcon,
  ShoppingCartIcon,
} from '@heroicons/react/24/outline';
import {
  DocumentTextIcon as DocumentTextIconSolid,
  ExclamationCircleIcon as ExclamationCircleIconSolid,
  HomeIcon as HomeIconSolid,
  ShoppingCartIcon as ShoppingCartIconSolid,
} from '@heroicons/react/24/solid';
import React, { useEffect, useState } from 'react';
import {
  SNavigationColor,
  SNavigationHighlightColor,
  SNavigationIconActiveColor,
  SNavigationIconColor,
  SNavigationTextActiveColor,
  SNavigationTextColor,
} from 'store/CustomizeApp/Appearance/colorAtom';
import {
  SIsNavigationTextCheck,
  SNavigation,
} from 'store/CustomizeApp/Appearance/navigationAtom';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { FContents } from 'store/CustomizeApp/Features/contentAtom';
import { Layouts } from 'enums/appearance/layouts';
import { NavigationItems } from 'enums/appearance/navigation';
import { FIsFeaturesChange, SFeaturesTab } from 'store/CustomizeApp/Features';
import { SLayoutType } from 'store/CustomizeApp/Appearance/layoutAtom';
import { SNavigationTextFont } from 'store/CustomizeApp/Appearance/fontAtom';
import { SThemeType } from 'store/CustomizeApp/Appearance/themeAtom';
import { Themes } from 'enums/appearance/themes';
import { classNames } from 'utility';
import { stepperAtom } from 'store/CustomizeApp/Stepper';
import {
  SAppNameChanges,
  SIsAppearanceChanges,
  SIsLeavingSectionOpen,
} from 'store/CustomizeApp/Appearance';

const Footer: React.FC = () => {
  const layoutType = useAtomValue(SLayoutType);
  const themeType = useAtomValue(SThemeType);

  const navigation = useAtomValue(SNavigation);
  const [isHiddenCount, setIsHiddenCount] = useState(4);

  const navigationColor = useAtomValue(SNavigationColor);
  const navigationIconColor = useAtomValue(SNavigationIconColor);
  const navigationActiveColor = useAtomValue(SNavigationIconActiveColor);
  const navigationTextColor = useAtomValue(SNavigationTextColor);
  const navigationTextActiveColor = useAtomValue(SNavigationTextActiveColor);
  const navigationHighlightColor = useAtomValue(SNavigationHighlightColor);

  const isAppNameChange = useAtomValue(SAppNameChanges);
  const isAppearanceChange = useAtomValue(SIsAppearanceChanges);
  const isFeatureChange = useAtomValue(FIsFeaturesChange);
  const setOpen = useSetAtom(SIsLeavingSectionOpen);

  const navTextFont = useAtomValue(SNavigationTextFont);
  const isNavTextCheck = useAtomValue(SIsNavigationTextCheck);

  const [stepper, setStepper] = useAtom(stepperAtom);
  const [featTab, setFeatTab] = useAtom(SFeaturesTab);

  const allContents = useAtomValue(FContents);
  const contents = allContents.filter((content) => content.toDelete === false);

  const onClickHome = () => {
    if (isAppNameChange || isAppearanceChange || isFeatureChange) {
      setOpen(true);
    } else if (stepper === 3) {
      setFeatTab('Home');
    } else {
      setStepper(1);
      setFeatTab('Home');
    }
  };

  const onClickStore = () => {
    if (isAppNameChange || isAppearanceChange || isFeatureChange) {
      setOpen(true);
    } else if (stepper === 3) {
      setFeatTab('Store');
    } else {
      setStepper(1);
      setFeatTab('Store');
    }
  };

  const onClickAbout = () => {
    if (isAppNameChange || isAppearanceChange || isFeatureChange) {
      setOpen(true);
    } else if (stepper === 3) {
      setFeatTab('About');
    } else {
      setStepper(1);
      setFeatTab('About');
    }
  };

  const onClickContent = () => {
    if (isAppNameChange || isAppearanceChange || isFeatureChange) {
      setOpen(true);
    } else if (stepper === 3) {
      setFeatTab('Content[0]');
    } else {
      setStepper(1);
      setFeatTab('Content[0]');
    }
  };

  useEffect(() => {
    const count = navigation.filter((e) => e.isHidden === false);
    setIsHiddenCount(count.length);
  }, [navigation]);

  const navList = navigation.map((nav, index) => {
    return {
      icon:
        nav.label === NavigationItems.Home ? (
          !nav.isHidden && themeType === Themes.Solid ? (
            <div
              key={index}
              onClick={() => onClickHome()}
              className={classNames(
                isNavTextCheck
                  ? 'flex-row text-center w-full h-full -mt-1'
                  : 'flex-row text-center w-full h-full',
                'cursor-pointer'
              )}
            >
              <HomeIconSolid
                className={classNames(
                  isNavTextCheck
                    ? 'w-full h-3/4 -mb-1.5 px-1 pt-1 mt-[0.1rem]'
                    : 'w-full h-full p-0.5'
                )}
                style={{
                  color: `${
                    featTab === 'Home'
                      ? navigationActiveColor
                      : navigationIconColor
                  }`,
                }}
              />
              {isNavTextCheck && (
                <span
                  className="w-full text-sm"
                  style={{
                    color: `${
                      featTab === 'Home'
                        ? navigationTextActiveColor
                        : navigationTextColor
                    }`,
                    fontFamily: `${navTextFont}`,
                  }}
                >
                  Home
                </span>
              )}
            </div>
          ) : (
            !nav.isHidden && (
              <div
                key={index}
                onClick={() => onClickHome()}
                className={classNames(
                  isNavTextCheck
                    ? 'flex-row text-center w-full h-full -mt-1'
                    : 'flex-row text-center w-full h-full',
                  'cursor-pointer'
                )}
              >
                <HomeIcon
                  className={classNames(
                    isNavTextCheck
                      ? 'w-full h-3/4 -mb-1.5 px-1 pt-1 mt-[0.1rem]'
                      : 'w-full h-full p-0.5'
                  )}
                  style={{
                    color: `${
                      featTab === 'Home'
                        ? navigationActiveColor
                        : navigationIconColor
                    }`,
                    background: `${
                      featTab === 'Home' && themeType === Themes.Fill
                        ? navigationHighlightColor
                        : ''
                    }`,
                  }}
                />
                {isNavTextCheck && (
                  <div
                    style={{
                      background: `${
                        featTab === 'Home' && themeType === Themes.Fill
                          ? navigationHighlightColor
                          : ''
                      }`,
                    }}
                  >
                    <span
                      className="w-full text-sm"
                      style={{
                        color: `${
                          featTab === 'Home'
                            ? navigationTextActiveColor
                            : navigationTextColor
                        }`,
                        fontFamily: `${navTextFont}`,
                      }}
                    >
                      Home
                    </span>
                  </div>
                )}
              </div>
            )
          )
        ) : nav.label === NavigationItems.Cart ? (
          !nav.isHidden && (
            <div
              key={index}
              onClick={() => onClickStore()}
              className={classNames(
                isNavTextCheck
                  ? 'flex-row text-center w-full h-full -mt-1'
                  : 'flex-row text-center w-full h-full',
                'cursor-pointer'
              )}
            >
              {themeType === Themes.Solid ? (
                <ShoppingCartIconSolid
                  className={classNames(
                    isNavTextCheck
                      ? 'w-full h-3/4 -mb-1.5 px-1 pt-1 mt-[0.1rem]'
                      : 'w-full h-full p-0.5'
                  )}
                  style={{
                    color: `${
                      featTab === 'Store'
                        ? navigationActiveColor
                        : navigationIconColor
                    }`,
                  }}
                />
              ) : (
                <ShoppingCartIcon
                  className={classNames(
                    isNavTextCheck
                      ? 'w-full h-3/4 -mb-1.5 px-1 pt-1 mt-[0.1rem]'
                      : 'w-full h-full p-0.5'
                  )}
                  style={{
                    color: `${
                      featTab === 'Store'
                        ? navigationActiveColor
                        : navigationIconColor
                    }`,
                    background: `${
                      featTab === 'Store' && themeType === Themes.Fill
                        ? navigationHighlightColor
                        : ''
                    }`,
                  }}
                />
              )}
              {isNavTextCheck && (
                <div
                  style={{
                    background: `${
                      featTab === 'Store' && themeType === Themes.Fill
                        ? navigationHighlightColor
                        : ''
                    }`,
                  }}
                >
                  <span
                    className="w-full text-sm"
                    style={{
                      color: `${
                        featTab === 'Store'
                          ? navigationTextActiveColor
                          : navigationTextColor
                      }`,
                      fontFamily: `${navTextFont}`,
                    }}
                  >
                    Cart
                  </span>
                </div>
              )}
            </div>
          )
        ) : nav.label === NavigationItems.About ? (
          !nav.isHidden && (
            <div
              key={index}
              onClick={() => onClickAbout()}
              className={classNames(
                isNavTextCheck
                  ? 'flex-row text-center w-full h-full -mt-1'
                  : 'flex-row text-center w-full h-full',
                'cursor-pointer'
              )}
            >
              {themeType === Themes.Solid ? (
                <ExclamationCircleIconSolid
                  className={classNames(
                    isNavTextCheck
                      ? 'w-full h-3/4 -mb-1.5 px-1 pt-[0.1rem] mt-[0.1rem]'
                      : 'w-full h-full px-0.5'
                  )}
                  style={{
                    color: `${
                      featTab === 'About'
                        ? navigationActiveColor
                        : navigationIconColor
                    }`,
                  }}
                />
              ) : (
                <ExclamationCircleIcon
                  className={classNames(
                    isNavTextCheck
                      ? 'w-full h-3/4 -mb-1.5 px-1 pt-[0.1rem] mt-[0.1rem]'
                      : 'w-full h-full px-0.5 '
                  )}
                  style={{
                    color: `${
                      featTab === 'About'
                        ? navigationActiveColor
                        : navigationIconColor
                    }`,
                    background: `${
                      featTab === 'About' && themeType === Themes.Fill
                        ? navigationHighlightColor
                        : ''
                    }`,
                  }}
                />
              )}
              {isNavTextCheck && (
                <div
                  style={{
                    background: `${
                      featTab === 'About' && themeType === Themes.Fill
                        ? navigationHighlightColor
                        : ''
                    }`,
                  }}
                >
                  <span
                    className="w-full text-sm"
                    style={{
                      color: `${
                        featTab === 'About'
                          ? navigationTextActiveColor
                          : navigationTextColor
                      }`,
                      fontFamily: `${navTextFont}`,
                    }}
                  >
                    About
                  </span>
                </div>
              )}
            </div>
          )
        ) : nav.label === NavigationItems.Content ? (
          !nav.isHidden && (
            <div
              key={index}
              onClick={() => onClickContent()}
              className={classNames(
                isNavTextCheck
                  ? 'flex-row text-center w-full h-full -mt-1'
                  : 'flex-row text-center w-full h-full',
                'cursor-pointer'
              )}
            >
              {themeType === Themes.Solid ? (
                contents[0]?.icon === undefined ||
                contents[0]?.icon === 'default' ? (
                  <DocumentTextIconSolid
                    className={classNames(
                      isNavTextCheck
                        ? 'w-full h-3/4 -mb-1.5 px-1 pt-1'
                        : 'w-full h-full p-0.5'
                    )}
                    style={{
                      color: `${
                        featTab === 'Content[0]'
                          ? navigationActiveColor
                          : navigationIconColor
                      }`,
                    }}
                  />
                ) : (
                  <i
                    style={{
                      color: `${
                        featTab === 'Content[0]'
                          ? navigationActiveColor
                          : navigationIconColor
                      }`,
                    }}
                    className={classNames(
                      `${contents[0]?.icon} fa-solid fa-2xl pt-5`,
                      isNavTextCheck ? 'mt-[0.1rem]' : ''
                    )}
                  ></i>
                )
              ) : contents[0]?.icon === undefined ||
                contents[0]?.icon === 'default' ? (
                <DocumentTextIcon
                  className={classNames(
                    isNavTextCheck
                      ? 'w-full h-3/4 -mb-1.5 px-1 pt-1'
                      : 'w-full h-full p-0.5'
                  )}
                  style={{
                    color: `${
                      featTab === 'Content[0]'
                        ? navigationActiveColor
                        : navigationIconColor
                    }`,
                    background: `${
                      featTab === 'Content[0]' && themeType === Themes.Fill
                        ? navigationHighlightColor
                        : ''
                    }`,
                  }}
                />
              ) : (
                <i
                  style={{
                    color: `${
                      featTab === 'Content[0]'
                        ? navigationActiveColor
                        : navigationIconColor
                    }`,
                    background: `${
                      featTab === 'Content[0]' && themeType === Themes.Fill
                        ? navigationHighlightColor
                        : ''
                    }`,
                  }}
                  className={classNames(
                    `${contents[0]?.icon.replace(
                      'fa-solid',
                      ''
                    )} fa-2xl pt-[1.15rem]`,
                    themeType === 'Fill' && featTab === 'Content[0]'
                      ? ' w-full h-full -mb-5'
                      : '',
                    isNavTextCheck ? 'mt-[0.2rem]' : ''
                  )}
                ></i>
              )}
              {isNavTextCheck && (
                <div
                  style={{
                    background: `${
                      featTab === 'Content[0]' && themeType === Themes.Fill
                        ? navigationHighlightColor
                        : ''
                    }`,
                  }}
                >
                  <span
                    className="w-full text-sm"
                    style={{
                      color: `${
                        featTab === 'Content[0]'
                          ? navigationTextActiveColor
                          : navigationTextColor
                      }`,
                      fontFamily: `${navTextFont}`,
                    }}
                  >
                    {contents[0]?.title.length > 7
                      ? contents[0]?.title.substring(0, 7) + '...'
                      : contents[0]?.title}
                  </span>
                </div>
              )}
            </div>
          )
        ) : (
          ''
        ),
    };
  });

  const navListMatrix = navigation.map((nav) => {
    return {
      icon:
        nav.label === NavigationItems.Home
          ? !nav.isHidden && (
              <div className="flex-row text-center">
                <div
                  onClick={() => onClickHome()}
                  className="w-20 h-16 rounded flex items-center justify-center"
                  style={
                    featTab === 'Home' && themeType === Themes.Fill
                      ? {
                          background: navigationHighlightColor,
                          border: 'solid',
                          borderColor: navigationIconColor,
                          borderWidth: '2px',
                        }
                      : themeType === Themes.Outline
                      ? {
                          backgroundColor: navigationColor,
                          border: 'solid',
                          borderColor: navigationIconColor,
                          borderWidth: '2px',
                        }
                      : { backgroundColor: `${navigationColor}` }
                  }
                >
                  {/* {themeType === Themes.Solid ? 
							<HomeIconSolid className='w-full h-full rounded p-4' style={featTab === 'Home' ? {color: `${navigationActiveColor}`} : {color: `${navigationIconColor}`}}/> :  */}
                  <HomeIcon
                    className={classNames('w-12 h-12 rounded text-gray-500')}
                    style={
                      featTab === 'Home' && themeType === Themes.Outline
                        ? { color: navigationActiveColor }
                        : featTab === 'Home'
                        ? { color: `${navigationActiveColor}` }
                        : { color: `${navigationIconColor}` }
                    }
                  />
                  {/* } */}
                </div>
                <span
                  className={classNames(isNavTextCheck ? '' : 'hidden')}
                  style={{
                    color: `${
                      featTab === 'Home'
                        ? navigationTextActiveColor
                        : navigationTextColor
                    }`,
                    fontFamily: `${navTextFont}`,
                  }}
                >
                  Home
                </span>
              </div>
            )
          : nav.label === NavigationItems.Cart
          ? !nav.isHidden && (
              <div className="flex-row text-center">
                <div
                  onClick={() => onClickStore()}
                  className="w-20 h-16 rounded flex items-center justify-center"
                  style={
                    featTab === 'Store' && themeType === Themes.Fill
                      ? {
                          background: navigationHighlightColor,
                          border: 'solid',
                          borderColor: navigationIconColor,
                          borderWidth: '2px',
                        }
                      : themeType === Themes.Outline
                      ? {
                          backgroundColor: navigationColor,
                          border: 'solid',
                          borderColor: navigationIconColor,
                          borderWidth: '2px',
                        }
                      : { backgroundColor: `${navigationColor}` }
                  }
                >
                  {/* {themeType === Themes.Solid ?
							<ShoppingCartIconSolid className='w-12 h-12 text-gray-500' style={featTab === 'Store' ? {color: `${navigationActiveColor}`} : {color: `${navigationIconColor}`}}/> : */}
                  <ShoppingCartIcon
                    className="w-12 h-12 text-gray-500"
                    style={
                      featTab === 'Store' && themeType === Themes.Outline
                        ? { color: navigationActiveColor }
                        : featTab === 'Store'
                        ? { color: `${navigationActiveColor}` }
                        : { color: `${navigationIconColor}` }
                    }
                  />

                  {/* } */}
                </div>
                <span
                  className={classNames(isNavTextCheck ? '' : 'hidden')}
                  style={{
                    color: `${
                      featTab === 'Store'
                        ? navigationTextActiveColor
                        : navigationTextColor
                    }`,
                    fontFamily: `${navTextFont}`,
                  }}
                >
                  Cart
                </span>
              </div>
            )
          : nav.label === NavigationItems.About
          ? !nav.isHidden && (
              <div className="flex-row text-center">
                <div
                  onClick={() => onClickAbout()}
                  className="w-20 h-16 rounded flex items-center justify-center"
                  style={
                    featTab === 'About' && themeType === Themes.Fill
                      ? {
                          background: navigationHighlightColor,
                          border: 'solid',
                          borderColor: navigationIconColor,
                          borderWidth: '2px',
                        }
                      : themeType === Themes.Outline
                      ? {
                          backgroundColor: navigationColor,
                          border: 'solid',
                          borderColor: navigationIconColor,
                          borderWidth: '2px',
                        }
                      : { backgroundColor: `${navigationColor}` }
                  }
                >
                  {/* {themeType === Themes.Solid ?
								<ExclamationCircleIconSolid className='w-12 h-12 text-gray-500' style={featTab === 'About' ? {color: `${navigationActiveColor}`} :{color: `${navigationIconColor}`}}/> : */}
                  <ExclamationCircleIcon
                    className="w-12 h-12 text-gray-500"
                    style={
                      featTab === 'About' && themeType === Themes.Outline
                        ? { color: navigationActiveColor }
                        : featTab === 'About'
                        ? { color: `${navigationActiveColor}` }
                        : { color: `${navigationIconColor}` }
                    }
                  />
                  {/* } */}
                </div>
                <span
                  className={classNames(isNavTextCheck ? '' : 'hidden')}
                  style={{
                    color: `${
                      featTab === 'About'
                        ? navigationTextActiveColor
                        : navigationTextColor
                    }`,
                    fontFamily: `${navTextFont}`,
                  }}
                >
                  About
                </span>
              </div>
            )
          : nav.label === NavigationItems.Content
          ? !nav.isHidden && (
              <div className="flex-row text-center">
                <div
                  onClick={() => onClickContent()}
                  className="w-20 h-16 rounded flex items-center justify-center"
                  style={
                    featTab === 'Content[0]' && themeType === Themes.Fill
                      ? {
                          background: navigationHighlightColor,
                          border: 'solid',
                          borderColor: navigationIconColor,
                          borderWidth: '2px',
                        }
                      : themeType === Themes.Outline
                      ? {
                          backgroundColor: navigationColor,
                          border: 'solid',
                          borderColor: navigationIconColor,
                          borderWidth: '2px',
                        }
                      : { backgroundColor: `${navigationColor}` }
                  }
                >
                  {
                    // themeType === Themes.Solid ? (
                    // 	contents[0]?.icon === undefined || contents[0]?.icon === 'default' ?
                    // 		<DocumentTextIconSolid className={classNames(isNavTextCheck ? 'w-12 h-12 text-gray-500': 'w-12 h-12 p-0.5')} style={{color: `${featTab === 'Content[0]' ? navigationActiveColor : navigationIconColor}`}}/>
                    // 		:
                    // 		<i style={{ color: `${featTab === 'Content[0]' ? navigationActiveColor : navigationIconColor}` }} className={`${contents[0]?.icon} fa-solid text-[2.5rem] py-[0.3rem] w-12 h-12 text-gray-500`}></i>
                    // ) :
                    contents[0]?.icon === undefined ||
                    contents[0]?.icon === 'default' ? (
                      <DocumentTextIcon
                        className={classNames(
                          isNavTextCheck
                            ? 'w-12 h-12 text-gray-500'
                            : 'w-12 h-12 p-0.5'
                        )}
                        style={{
                          color: `${
                            featTab === 'Content[0]'
                              ? navigationActiveColor
                              : navigationIconColor
                          }`,
                          background: `${
                            featTab === 'Content[0]' &&
                            themeType === Themes.Fill
                              ? navigationHighlightColor
                              : ''
                          }`,
                        }}
                      />
                    ) : (
                      <i
                        style={{
                          color: `${
                            featTab === 'Content[0]'
                              ? navigationActiveColor
                              : navigationIconColor
                          }`,
                          background: `${
                            featTab === 'Content[0]' &&
                            themeType === Themes.Fill
                              ? navigationHighlightColor
                              : ''
                          }`,
                        }}
                        className={classNames(
                          `${contents[0]?.icon.replace(
                            'fa-solid',
                            ''
                          )} text-[2.5rem] py-[0.3rem] w-12 h-12 text-gray-500`
                        )}
                      ></i>
                    )
                  }
                </div>
                {isNavTextCheck && (
                  // style={{background: `${featTab === 'Content[0]' &&  themeType === Themes.Fill ? navigationHighlightColor : ''}`}}
                  <div>
                    <span
                      className="w-full text-sm"
                      style={{
                        color: `${
                          featTab === 'Content[0]'
                            ? navigationTextActiveColor
                            : navigationTextColor
                        }`,
                        fontFamily: `${navTextFont}`,
                      }}
                    >
                      {contents[0]?.title.length > 7
                        ? contents[0]?.title.substring(0, 7) + '...'
                        : contents[0]?.title}
                    </span>
                  </div>
                )}
              </div>
            )
          : '',
    };
  });

  return (
    <>
      {layoutType === Layouts.Matrix && (
        <div className="absolute bottom-0 rounded-bl-xl rounded-br-xl w-full flex items-center justify-around overflow-hidden pb-5">
          <div
            className={
              isHiddenCount === 1 ? '' : 'grid grid-cols-2 gap-x-10 gap-y-6'
            }
          >
            {navListMatrix.map((nav, index) => {
              return nav.icon === false ? (
                <></>
              ) : (
                <div key={index} className="w-full h-full">
                  {nav.icon}
                </div>
              );
            })}
          </div>
        </div>
      )}
      {layoutType === Layouts.ButtonsAndSideBar && (
        <div
          className={classNames(
            'absolute bottom-0 rounded-bl-xl rounded-br-xl w-full flex items-center justify-around overflow-hidden',
            isNavTextCheck ? 'h-[52px]' : 'h-[40px]'
          )}
          style={{ backgroundColor: `${navigationColor}` }}
        >
          {navList.map((nav, index) => {
            return nav.icon === false ? (
              <></>
            ) : (
              <div key={index} className="w-full h-full">
                {nav.icon}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Footer;
