import z from 'zod';

export const PromoCodeByIdSchema = z.object({
	code:                   z.string(),
});

export const PromoCodeByIdResponse = z.object({
	Id:                     z.string(),
	Active:                 z.boolean(),
	Code:                   z.string(),
	CouponValid:            z.boolean(),
	CouponPercentOff:       z.number(),
	CouponAmountOff:        z.number(),
});

export type PromoCodeParams   = z.infer<typeof PromoCodeByIdSchema>;
export type PromoCodeResponse = z.infer<typeof PromoCodeByIdResponse>;