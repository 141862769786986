import React, { useLayoutEffect, useState, useEffect } from 'react';
import Button from 'components/Common/Buttons/Button';
import FormInput from 'components/Common/Forms/Inputs';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { GetLetUsBuildFormResponse, GetLetUsBuildFormType, GetLetUsBuildFormSchema } from 'services/requests/LetUsBuildYourApp/schema';
import { buildYourAppFormApi } from 'services/requests/LetUsBuildYourApp';
import FormInputTextArea from 'components/Common/Forms/TextArea';
import BuildDropdown from 'components/Common/Dropdown/BuildYourAppDropdown';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { showSuccessAlert } from 'utility/alerts';
import { log } from 'utility/log';


const TimeframeOptions = [
	{ label: 'Not Defined at the moment', value: 'Not Defined at the moment' },
	{ label: 'As soon as possible', value: 'As soon as possible' },
	{ label: 'In a month', value: 'In a month' },
	{ label: 'Within the next 2 months', value: 'Within the next 2 months' },
	{ label: 'Within the next 3 months', value: 'Within the next 3 months' },
	{ label: '6 months or later from now', value: '6 months or later from now' },
];

export const BuildYourApp = () => {
	const [selected,setSelected] = useState<string>('Select ');
	const navigate = useNavigate();
	useLayoutEffect(() => {
		document.body.style.backgroundColor = '#f6f6f6';
	});

	const { control, handleSubmit, formState } = useForm<GetLetUsBuildFormType>({
		defaultValues:{name:'', website:'', email:'', phoneNumber: '', message:''},
		mode: 'onBlur',
		resolver: zodResolver(GetLetUsBuildFormSchema)
	});

	const {
		mutate: buildYourAppFormMU, isLoading: isSubmitLoading
	} = useMutation<GetLetUsBuildFormResponse, AxiosError, GetLetUsBuildFormType>(
		data => buildYourAppFormApi(data), {
			onSuccess: (data) => {
				showSuccessAlert('Message successfully sent!');
				navigate('/');
			},
			onError: (error) => {
				log(error);
			}
		}
	);

	const onSubmit = (data: GetLetUsBuildFormType) => {
		buildYourAppFormMU(data);
	};

	const onChangeTimeframe = (value: string, name: string) => {
		setSelected(value);
	};

	const [captChaCheck, setCaptChaCheck] = useState(true);

	const onChangeCaptcha = () => {
		setCaptChaCheck(false);
	};

	const onExpiredCaptcha = () => {
		setCaptChaCheck(true);
	};
	
	useEffect(() => {
		window.scrollTo(0,0);
	},[]);
	
	return (
		<div className='flex justify-center items-center py-7 sm:p-6 max-sm:p-6'>
			<div className='h-full bg-white w-[800px] h-[720px] rounded-[10px] py-[18px] px-[53px] max-sm:px-[5px] sm:px-[5px]'>
				<h1 className='text-[18px] text-center font-semibold mb-[67px] text-[#464255]'>Let Us Help You Build Your Business App</h1>
				<form>
					<div className='flex gap-[42px] mb-[17px] max-sm:flex-col max-sm:gap-[22px]'>
						<div className='w-full md:w-1/2 px-3 mb-6 md:mb-0 '>
							<label className='block text-[#5A7184] text-xs mb-[10px]' htmlFor='grid-first-name'>
              Name<span className='text-[#BF1616]'>*</span>
							</label>
							<Controller
								control={control}
								name='name'
								render={({ field: {name, onChange}, fieldState: { error } }) => (
									<FormInput onChange={e => onChange(e.trim())} name={name}  error={error?.message} type='text' placeholder='Full Name' className='rounded-[5px] text-[14px] placeholder:text-[11px]'/>
								)}
							/>
						</div>
						<div className='w-full md:w-1/2 px-3 mb-6 md:mb-0 '>
							<label className='block text-[#5A7184] text-xs mb-[10px]' htmlFor='grid-website'>
              Website<span className='text-[#BF1616]'>*</span>
							</label>
							<Controller
								control={control}
								name='website'
								render={({ field: {name, onChange}, fieldState: { error } }) => (
									<FormInput onChange={e => onChange(e.trim())} name={name}  error={error?.message} type='text' placeholder='https://domain.sample.com/' className='rounded-[5px] text-[14px] placeholder:text-[11px]'/>
								)}
							/>
						</div>
					</div>
					<div className='flex gap-[42px] mb-[17px] max-sm:flex-col max-sm:gap-[22px]'>
						<div className='w-full md:w-1/2 px-3 mb-6 md:mb-0 '>
							<label className='block text-[#5A7184] text-xs mb-[10px]' htmlFor='grid-email'>
              Email Address<span className='text-[#BF1616]'>*</span>
							</label>
							<Controller
								control={control}
								name='email'
								render={({ field: {name, onChange}, fieldState: { error } }) => (
									<FormInput onChange={e => onChange(e.trim())} name={name}  error={error?.message} type='text' placeholder='you@example.com' className='rounded-[5px] text-[14px] placeholder:text-[11px]'/>
								)}
							/>
						</div>
						<div className='w-full md:w-1/2 px-3 mb-6 md:mb-0 '>
							<label className='block text-[#5A7184] text-xs mb-[10px]' htmlFor='phone-number'>
             Phone Number<span className='text-[#BF1616]'>*</span>
							</label>
							<Controller
								control={control}
								name='phoneNumber'
								render={({ field: {name, onChange}, fieldState: { error } }) => (
									<FormInput onChange={onChange} name={name}  error={error?.message} type='phoneNumber' placeholder='e.g. +1 (123) 456-7890' className='[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none rounded-[5px] text-[14px] placeholder:text-[11px]'/>
								)}
							/>
						</div>
					</div>
					<div className='flex gap-[42px] mb-[17px] max-sm:flex-col max-sm:gap-[22px]'>
						<div className='w-full md:w-1/2 px-3 mb-6 md:mb-0 sm:mb-0 max-sm:mb-0'>
							<label className='block text-[#5A7184] text-xs mb-[10px]' htmlFor='grid-email'>
							When would you like your mobile app delivered?<span className='text-[#BF1616]'>*</span>
							</label>
							<div className='text-black'>
								<Controller
									control={control}
									name='timeFrame'
									render={({ field: { onChange, value }, fieldState: { error } }) => (
										<div className='w-full relative'>
											<select
												className={`appearance-none bg-transparent pl-3 text-[#183B56] h-[35px] w-[100%] text-sm focus:outline-none rounded-[5px] border
												border-gray-300 focus:ring-1 focus:border-input-active ${error ? 'border-red-700 bg-red-100' : 'border-gray-300 bg-white'}`}
												onChange={onChange}
												value={value}
											>
												<option value='' hidden>Select</option>
												{
													TimeframeOptions.map((index, key) => (
														<option key={key} value={index.value}>{index.value}</option>
													))
												}
											</select>
											<ChevronDownIcon className="h-5 w-5 text-primary absolute top-0 right-0 mt-2 mr-2 pointer-events-none" aria-hidden="true" />
											<div className='mt-1 ml-1'>
												{error && <p className='text-red-700 text-xs'>{error.message}</p>}
											</div>
										</div>
									)}
								/>
							</div>
						</div>
						<div className=' w-full md:w-1/2 px-3 mb-6 md:mb-0 '>
						
						</div>
					</div>
					<div className='px-3 mt-[31px]'>
						<label className='block text-[#5A7184] text-xs mb-[10px]' htmlFor='grid-message'>
            Message<span className='text-[#BF1616]'>*</span>
						</label>
						<Controller
							control={control}
							name='message'
							render={({ field: {name, onChange}, fieldState: { error } }) => (
								<FormInputTextArea rows={2} onChange={e => onChange(e.trim())} name={name} error={error?.message} className='resize-none h-[110px]pr-[16px] pr-[16px] pl-[16px] pb-[75px] text-sm rounded-[5px]' type='text' placeholder='Type your message...'/>
								
							)}
						/>
					</div>
					<div className=' px-3 mt-[31px]'>
						<ReCAPTCHA
							onChange={onChangeCaptcha}
							onExpired={onExpiredCaptcha}
							sitekey={import.meta.env.VITE_RECAPTCHA_PROD}
							size={'normal'}
						/>
					</div>
				</form>
				<div className='flex justify-center mt-[25px]'>
					<Button type='submit' 
						disabled={
							!formState.dirtyFields.name || 
							!formState.dirtyFields.website || 
							!formState.dirtyFields.phoneNumber || 
							!formState.dirtyFields.email || 
							!formState.dirtyFields.message || 
							captChaCheck || 
							isSubmitLoading
						} 
						onClick={handleSubmit(onSubmit)} className='font-semibold text-[16px] w-[160px] h-[43px] ' variant='primary'>Submit</Button>
				</div>
			</div>
		</div>
	); 
};