import { ArrowUpTrayIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { FHomeDescription, FHomeImgID, FHomePreviewImage, FHomeUploadFile } from 'store/CustomizeApp/Features/homeAtom';
import React, { useEffect, useRef, useState } from 'react';

import Editor from 'components/Common/TextEditor';
import { HomeUseQuery } from '../UseQuery/HomeQueries';
import { IMAGES } from 'utility';
import Tooltip from 'components/Common/Tooltip';
import { showFailedAlert } from 'utility/alerts';
import { useAtom } from 'jotai';

const HomePage: React.FC = () => {

	// HomeUseQuery();
	
	const [previewImage, setPreviewImage] = useAtom(FHomePreviewImage);
	const [, setImageFile]           = useAtom(FHomeUploadFile);
	const [description, setDescription]   = useAtom(FHomeDescription);
	const [, setImgID]               = useAtom(FHomeImgID);

	const homeInput 		  = useRef<HTMLInputElement>(null);
	
	const imageTypeRegex = /image\/(png|jpg|jpeg)/gm;

	const handleImageUpload = (event : any) => {
		const file = event.target.files[0];
		const reader = new FileReader();
	
		reader.onload = (event : any) => {
			const image : any = new Image();
			image.src = event.target.result;
		
			if (file.type.match(imageTypeRegex)) {
				image.onload = () => {
					if (file.size <= 5 * 1024 * 1024) {
						setImageFile(file);
						setPreviewImage(reader.result);
					} else if (file.size > 5 * 1024 * 1024) {
						showFailedAlert('Please upload an image file that is less than 5MB in size.');
					}
				};
			} else{
				showFailedAlert('File must be JPEG or PNG.');
			}
			
		};

		reader.readAsDataURL(file);
	};

	const [editorHeight, setEditorHeight] = useState(900);
	useEffect(() => {
		setTimeout(() => {
			const quill = document.querySelector('.ql-container');
			const resizeObserver = new ResizeObserver(entries => {
				if (entries.length > 0) {
					const editorHeight = entries[0].contentRect.height;
					if (editorHeight > 0) setEditorHeight(editorHeight);
					else setEditorHeight(900);
				}
			});
			if(quill) resizeObserver.observe(quill);

			return () => {
				if(quill) resizeObserver.unobserve(quill);
			};
		}, 250);
	}, [editorHeight]);


	return (
		<div className='bg-white w-full rounded-lg my-8'>
			<div className='bg-white px-3 mx-3 rounded'>
				<div className='py-5 flex-row'>
					<div className='flex justify-between items-center py-2'>
						<span className='text-[#707070] font-bold text-2xl'>Home</span>
						<div className='flex gap-3 items-center'>
							{/* -----------Video Tutorial and Help Icons------------ */}
							<Tooltip className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
								<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.CameraIcon} alt='camera icon' />
							</Tooltip>
							<Tooltip className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
								<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.InfoIcon} alt='info icon' />
							</Tooltip>
							{/* ---------------------------------------------------- */}
						</div>
					</div>
					<div className='flex-row py-5'>
						<div className='relative flex flex-row 2xl:flex-row xl:flex-row md:flex-row sm:flex-col max-sm:flex-col w-full'>
							
							<div className='flex flex-row relative'>
								<div className='relative border 2xl:h-[250px] 2xl:w-[350px] xl:h-[250px] xl:w-[350px] lg:h-[250px] lg:w-[350px] md:h-[250px] md:w-[350px] sm:h-[250px] sm:w-[350px] max-sm:h-[150px] max-sm:w-[210px] flex justify-center items-center py-2 rounded-lg overflow-hidden bg-opacity-50 backdrop-blur-md cursor-pointer bg-[#F5F5F5]'>
									{
										previewImage ?
											<img
												src={previewImage}
												alt='Uploaded Image'
												className='absolute rounded-lg object-contain w-full h-full backdrop-blur-md'
											/> :
											<div className='flex justify-center'>
												<div className='flex-row'>
													<div className=' flex justify-center'>
														<ArrowUpTrayIcon className='w-8 h-8 text-primary'/> 
													</div>
													<span className='text-primary text-xs block text-center mb-2 mt-2'>Upload Image</span>
												</div>
											</div>
									}
									<input
										ref={homeInput}
										type='file'
										accept='.jpg, .jpeg, .png'
										className='absolute inset-0 rounded-lg w-full h-full opacity-0 cursor-pointer'
										onChange={(e) => handleImageUpload(e)} onClick={(event)=> { 
											event.currentTarget.value = '';
										}}
									/>
								</div>
								<div>
									{previewImage && 
										<div className='rounded-full text-[#D9D7D7] bg-white border-2 h-7 w-7 flex items-center justify-center cursor-pointer -mt-2 z-10 lg:-right-3 absolute'>
											<XMarkIcon className='w-5 h-5 cursor-pointer' onClick={() => {
												setPreviewImage(null); setImgID(0); setImageFile(null);} }/>
										</div>
									}
								</div>
							</div>

							<div className='2xl:ml-8 xl:ml-8 lg:ml-8 md:ml-8 sm:ml-0 max-sm:ml-0'>
								<span className='flex text-sm text-gray-500 font-light items-center justify-center 2xl:mt-0 2xl:justify-center xl:mt-0 xl:justify-center lg:mt-0 lg:justify-center md:mt-0 md:justify-center sm:mt-[30px] sm:justify-start max-sm:mt-[30px] max-sm:justify-start'>
									Recommended Resolution and Size
								</span>
								<span className='flex text-sm text-gray-500 items-center py-2 font-bold'>604 x 428px and less then 5MB</span>
							</div>
						</div>
						<div className='mt-6 flex-row py-2 w-full xl:w-[58.5rem] 2xl:w-[68rem] 3xl:w-full max-sm:pb-[70px] max-sm:w-[105%] max-sm:-ml-3'>
							<div className='w-full h-[22rem] sm:h-[21rem] md:h-[19rem] lg:h-[19rem] mb-10' style={{height: editorHeight}}>
								<Editor 
									value={description} 
									setValue={setDescription}
									label='Description'
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HomePage;