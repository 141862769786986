import { axiosInstance } from 'services/axios';
import { GetResellerParamsSchema } from './schema';

export const updateApproval = async (id : any) => {
	const response = await axiosInstance.put(`/organizations/apps/${id}/approve`);
	return response.data;
};

export const rejectApproval = async (id : any) => {
	const response = await axiosInstance.put(`/organizations/apps/${id}/reject`);
	return response.data;
};
export const getAllReseller = async (param?: GetResellerParamsSchema) => {
	const response = await axiosInstance.get('/notifications/reseller', {params: param});
	return response.data;
};