import React from 'react';
import { FSocialMediasAtom } from 'store/CustomizeApp/Features/socialMediaAtom';
import { SCardBackgroundColor, SCardBorderColor, SCardIconColor, SCardTitleColor } from 'store/CustomizeApp/Appearance/colorAtom';
import { GlobeAsiaAustraliaIcon } from '@heroicons/react/24/solid';
import { classNames } from 'utility/common';
import { useAtomValue } from 'jotai';

const SocialMedia:React.FC = () => {
	const socialMedias 				= useAtomValue(FSocialMediasAtom); 
	
	const cardBackgroundColor 		= useAtomValue(SCardBackgroundColor);
	const cardBorderColor 			= useAtomValue(SCardBorderColor);
	const cardIconColor 			= useAtomValue(SCardIconColor);
	const cardTitleColor 			= useAtomValue(SCardTitleColor);

	return (
		<div className='absolute w-full h-[100%] xl:h-[98%] 2xl:h-[99%] overflow-y-auto overflow-x-hidden'>
			<div className='py-[60px] px-3 grid grid-cols-2 gap-2'>
				{socialMedias.map((social, index)=>(
					<div key={index} className='text-center'>
						<div
							className='border border-gray-200 mb-1 rounded-lg w-[100%] h-[7rem] mr-[8px] flex items-center justify-center text-xs shadow-sm'
							style={{ backgroundColor: cardBackgroundColor, borderColor: cardBorderColor }}
						>
							<div className='text-5xl'>
								{social.icon !== 'fas fa-globe'
									? <i className={classNames(social.icon, 'text-7xl')} style={{ color: cardIconColor }}/>
									: <GlobeAsiaAustraliaIcon className='h-[5rem] w-auto text-5xl' style={{ fill: cardIconColor }}/> 
								}
							</div>
						</div>
						<label style={{ color: cardTitleColor }}>
							{social.label}
						</label>
					</div>
				))}
			</div>
		</div>
	);
};

export default SocialMedia;