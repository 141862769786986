import React, { useState } from 'react';
import { useAtom } from 'jotai';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import Button from '../../../Common/Buttons/Button';
import * as subscriptionAtom from '../../../../store/CustomizeApp/Payment/subscription';
import { AxiosError } from 'axios';
import { GetSubscriptionByIdResponse } from 'services/requests/Payment/schema';
import { getSubscriptionByAppId } from 'services/requests/Payment';
import { freePriceId, ISubscriptionDetails, subscriptionPlans } from 'pages/CustomizeApp/Payment/Content/oldIndex';
import { classNames, IMAGES } from 'utility';
import { set } from 'lodash';

interface IPricingCard {
  handleNext: (e: any) => void;
}

export const PricingCard: React.FC<IPricingCard> = ({handleNext}) => {
	// const navigate = useNavigate();
	// const [ pricing, setPricing ] = useAtom(pricingID);
	const [currentPlanId, setCurrentPlanId] = useState(-1);
	const {id} = useParams();
	const FREE_TRIAL_PRICE_ID = freePriceId;

	const [subscriptionData, ]       										 = useAtom(subscriptionAtom.FSubscription);
	const [, setSubscriptionData]    										 = useAtom(subscriptionAtom.FSubscription);
	const [isAlreadySubscribed, setIsAlreadySubscribed]  = useAtom(subscriptionAtom.FIsAlreadySubscribed);

	// const { data: plans } = useQuery('pricing-plans', getAllPricing);

	// useQuery<GetSubscriptionByIdResponse, AxiosError>({
	// 	queryKey: ['getSubscriptionByAppId', id],
	// 	queryFn: () => getSubscriptionByAppId(id),
	// 	onSuccess: (data) => {
	// 		setSubscriptionData(data);
	// 		log('data', data);
	// 		if (data.PriceId !== FREE_TRIAL_PRICE_ID) {
	// 			setIsAlreadySubscribed(true);
	// 		} 
	// 		else { 
	// 			setIsAlreadySubscribed(false);
	// 		}
	// 	},
	// 	onError: (error) => {
	// 		log(error);
	// 	},
	// 	retry: 0,
	// });

	React.useEffect(() => {
		const currentPlan = subscriptionPlans.find(plan => plan.PriceId === subscriptionData.PriceId);
		if (currentPlan) {
			setCurrentPlanId(currentPlan.id);
		}
	}, [subscriptionData, subscriptionPlans]);
	

	return (
		<div className='flex max-md:flex-col max-md:justify-center max-md:items-center max-sm:flex-col max-sm:justify-center max-sm:items-center gap-[40px] 2xl:gap-[50px] lg:gap-[50px] md:gap-[15px] sm:gap-[35px] max-sm:gap-[35px] justify-center'>
			{subscriptionPlans.map((plan: ISubscriptionDetails) => {
				return (
					<div key={plan.id} className='group cursor-pointer'>
						<div className='bg-white w-[246px] h-[400px] 2xl:w-[246px] 2xl:h-[400px] xl:w-[246px] xl:h-[400px] lg:w-[246px] lg:h-[400px] md:w-[226px] md:h-[380px] sm:w-[246px] sm:h-[400px] max-sm:w-[246px] max-sm:h-[400px] shadow-lg rounded-[16px] py-4 px-8 flex-row justify-center'>
							{/* <center><img className='w-9 h-[61px] lg:w-16' src={plan.imageURL} alt='Pricing Logo' /></center>  */}

							{plan.planTitle === 'Lite' ? <center><img className='w-16 h-[61px]' src={IMAGES.subscriptionLiteIcon} alt='Pricing Logo' /></center>
								: plan.planTitle === 'Standard' ? <center><img className='w-16 h-[61px]' src={IMAGES.subscriptionStandardIcon} alt='Pricing Logo' /></center>
									: plan.planTitle === 'Premium' ? <center><img className='w-16 h-[61px]' src={IMAGES.subscriptionPremiumIcon} alt='Pricing Logo' /></center>
										: <div />}


							{/* <center><img className='w-9 h-[61px] lg:w-16' src={IMAGES.subscriptionLiteIcon} alt='Pricing Logo' /></center>
							<center><img className='w-9 h-[61px] lg:w-16' src={IMAGES.subscriptionStandardIcon} alt='Pricing Logo' /></center>
							<center><img className='w-9 h-[61px] lg:w-16' src={IMAGES.subscriptionPremiumIcon} alt='Pricing Logo' /></center> */}
							<span className='text-md lg:text-xl flex justify-center font-semibold text-gray-600 py-1 group-hover:text-black mt-2'>{plan.planTitle}</span>
							<span className='text-3xl lg:text-4xl flex justify-center font-bold text-gray-600 py-3 lg:py-4 group-hover:text-black'>${plan.planPrice} <span className='text-xs pt-4 text-[#838383] group-hover:text-black'>/MONTH</span></span>
							<span className='text-xs lg:text-xs flex justify-center text-center font-semibold text-gray-600 py-2 px-[0.47rem] group-hover:text-gray-600'>billed {plan.planInterval}</span>
							<span className='text-xs lg:text-sm flex text-center font-light text-gray-500 py-2 pb-7 px-2 group-hover:text-black'>{plan.planDescription}</span>   
							<div className='flex justify-center items-center'>
								<Button 
									disabled={plan.PriceId === subscriptionData.PriceId}
									type='submit' 
									className={classNames('py-2 lg:py-3 rounded-[4px] border-primary group-hover:border-white bg-[#E0781D] text-white font-semibold text-xs disabled:bg-white disabled:text-[#BABCBC] disabled:border-[#BABCBC] disabled:group-hover:border-[#BABCBC]', plan.PriceId < subscriptionData.PriceId  ? 'px-12' : 'px-10', !isAlreadySubscribed ? 'px-12' : '')}
									onClick={() => handleNext(plan)}> 
									{	
										!isAlreadySubscribed
											? 'Select' : plan.id < currentPlanId 
												? 'Downgrade' : plan.id > currentPlanId
													? 'Upgrade' : plan.id === currentPlanId 
														? 'Current Plan' : ''
									} 
								</Button>   
							</div>       
						</div>
					</div>
				);
			})}
		</div>
	);
};