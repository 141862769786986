import React from 'react';

export const Home = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="29.17" height="25.524" viewBox="0 0 29.17 25.524" {...props}>
			<path id="Icon_open-home" data-name="Icon open-home" d="M14.585,0,0,10.939H3.646V25.524h7.292V18.231h7.292v7.292h7.292V10.829l3.646.109Z"/>
		</svg>

	);
};

export const Store = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="25.372" height="26.57" viewBox="0 0 25.372 26.57" {...props}>
			<path id="Path_2" data-name="Path 2" d="M3.586,1a1.563,1.563,0,1,0,0,3.126H5.52L6,6.036q.007.033.016.066l2.153,8.487L6.757,15.983A3.12,3.12,0,0,0,9,21.318H22.615a1.563,1.563,0,1,0,0-3.126H9l1.586-1.563H21.029a1.588,1.588,0,0,0,1.418-.864L27.2,6.388a1.543,1.543,0,0,0-.069-1.52,1.591,1.591,0,0,0-1.348-.741h-17L8.3,2.183A1.582,1.582,0,0,0,6.757,1ZM24.2,25.226a2.379,2.379,0,1,1-2.379-2.344A2.362,2.362,0,0,1,24.2,25.226ZM9.136,27.57a2.345,2.345,0,1,0-2.379-2.344A2.362,2.362,0,0,0,9.136,27.57Z" transform="translate(-2 -1)"/>
		</svg>

	);
};

export const About = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="30" height="26.57" viewBox="0 0 512 512" {...props}>
			<path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/>
		</svg>
	);
};

export const ContentIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="25.372" height="26.57" viewBox="0 0 384 512" {...props}>
			<path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/>
		</svg>
	);
};

export const PrivacyIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="25.372" height="26.57" viewBox="0 0 448 512" {...props}>
			<path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"/>
		</svg>
	);
};

export const TermsIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="25.372" height="26.57" viewBox="0 0 512 512" {...props}>
			<path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/>
		</svg>
	);
};

export const AccessibilityIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="25.372" height="26.57" viewBox="0 0 512 512" {...props}>
			<path d="M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm161.5-86.1c-12.2-5.2-26.3 .4-31.5 12.6s.4 26.3 12.6 31.5l11.9 5.1c17.3 7.4 35.2 12.9 53.6 16.3v50.1c0 4.3-.7 8.6-2.1 12.6l-28.7 86.1c-4.2 12.6 2.6 26.2 15.2 30.4s26.2-2.6 30.4-15.2l24.4-73.2c1.3-3.8 4.8-6.4 8.8-6.4s7.6 2.6 8.8 6.4l24.4 73.2c4.2 12.6 17.8 19.4 30.4 15.2s19.4-17.8 15.2-30.4l-28.7-86.1c-1.4-4.1-2.1-8.3-2.1-12.6V235.5c18.4-3.5 36.3-8.9 53.6-16.3l11.9-5.1c12.2-5.2 17.8-19.3 12.6-31.5s-19.3-17.8-31.5-12.6L338.7 175c-26.1 11.2-54.2 17-82.7 17s-56.5-5.8-82.7-17l-11.9-5.1zM256 160a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/>
		</svg>
	);
};


export const Contact = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="29.372" height="26.57" viewBox="0 0 512 512" {...props}>
			<path d="M48 64C21.5 64 0 85.5 0 112c0 .1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/>
		</svg>
	);
};

export const Gallery = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="25.372" height="26.57" viewBox="0 0 512 512" {...props}>
			<path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"/>
		</svg>
	);
};

export const Help = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="25.372" height="26.57" viewBox="0 0 512 512" {...props}>
			<path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/>
		</svg>
	);
};

export const Offers = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="18.199" height="26" viewBox="0 0 18.199 26"  {...props}>
			<path id="coupon_discount_price_sale" d="M36.249,13.2H38.2V8.648a.617.617,0,0,0-.325-.552.585.585,0,0,0-.65,0L34.331,9.818l-2.242-1.69a.682.682,0,0,0-.78,0L29.1,9.785l-2.21-1.657a.682.682,0,0,0-.78,0l-2.242,1.69L20.975,8.1a.585.585,0,0,0-.65,0A.617.617,0,0,0,20,8.648V13.2h1.95a.65.65,0,1,1,0,1.3H20V33.346a.617.617,0,0,0,.325.552.585.585,0,0,0,.65,0l2.892-1.722,2.242,1.69a.682.682,0,0,0,.78,0l2.21-1.657,2.21,1.657a.682.682,0,0,0,.78,0l2.242-1.69L37.223,33.9a.552.552,0,0,0,.325.1.682.682,0,0,0,.65-.65V14.5h-1.95a.65.65,0,0,1,0-1.3Zm-7.8,0h1.3a.65.65,0,0,1,0,1.3h-1.3a.65.65,0,0,1,0-1.3ZM27.8,21a1.3,1.3,0,1,1-1.3-1.3A1.3,1.3,0,0,1,27.8,21Zm-3.9-7.149a.65.65,0,0,1,.65-.65h1.3a.65.65,0,0,1,0,1.3h-1.3A.65.65,0,0,1,23.9,13.847ZM31.7,27.5A1.3,1.3,0,1,1,33,26.2,1.3,1.3,0,0,1,31.7,27.5ZM32.8,20.8l-6.5,6.5a.644.644,0,1,1-.91-.91l6.5-6.5a.65.65,0,0,1,.91.91Zm.845-6.3h-1.3a.65.65,0,0,1,0-1.3h1.3a.65.65,0,0,1,0,1.3Z" transform="translate(-20 -7.997)"/>
		</svg>
	);
};

export const Notification = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="25.372" height="26.57" viewBox="0 0 448 512" {...props}>
			<path d="M224 0c-17.7 0-32 14.3-32 32V51.2C119 66 64 130.6 64 208v18.8c0 47-17.3 92.4-48.5 127.6l-7.4 8.3c-8.4 9.4-10.4 22.9-5.3 34.4S19.4 416 32 416H416c12.6 0 24-7.4 29.2-18.9s3.1-25-5.3-34.4l-7.4-8.3C401.3 319.2 384 273.9 384 226.8V208c0-77.4-55-142-128-156.8V32c0-17.7-14.3-32-32-32zm45.3 493.3c12-12 18.7-28.3 18.7-45.3H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7z"/>
		</svg>
	);
};

export const Social = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="25.372" height="26.57" viewBox="0 0 512 512" {...props}>
			<path d="M352 256c0 22.2-1.2 43.6-3.3 64H163.3c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64H348.7c2.2 20.4 3.3 41.8 3.3 64zm28.8-64H503.9c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64H380.8c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32H376.7c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0H167.7c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 21 58.2 27 94.7zm-209 0H18.6C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192H131.2c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64H8.1C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6H344.3c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352H135.3zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6H493.4z"/>
		</svg>
	);
};

export const Settings = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24.196" height="25.276" viewBox="0 0 24.196 25.276" {...props}>
			<path id="Icon_awesome-cog" data-name="Icon awesome-cog" d="M25.2,16.244l-2.171-1.254a9.816,9.816,0,0,0,0-3.577L25.2,10.16a.616.616,0,0,0,.28-.713,12.692,12.692,0,0,0-2.787-4.821.613.613,0,0,0-.754-.117L19.766,5.762a9.617,9.617,0,0,0-3.1-1.789v-2.5a.611.611,0,0,0-.479-.6,12.811,12.811,0,0,0-5.565,0,.611.611,0,0,0-.479.6V3.979a9.921,9.921,0,0,0-3.1,1.789L4.881,4.514a.605.605,0,0,0-.754.117A12.615,12.615,0,0,0,1.339,9.452a.61.61,0,0,0,.28.713L3.79,11.419A9.816,9.816,0,0,0,3.79,15L1.62,16.25a.616.616,0,0,0-.28.713,12.692,12.692,0,0,0,2.787,4.821.613.613,0,0,0,.754.117l2.171-1.254a9.617,9.617,0,0,0,3.1,1.789v2.507a.611.611,0,0,0,.479.6,12.811,12.811,0,0,0,5.565,0,.611.611,0,0,0,.479-.6V22.436a9.921,9.921,0,0,0,3.1-1.789L21.942,21.9a.605.605,0,0,0,.754-.117,12.615,12.615,0,0,0,2.787-4.821A.629.629,0,0,0,25.2,16.244ZM13.406,17.279A4.077,4.077,0,1,1,17.483,13.2,4.082,4.082,0,0,1,13.406,17.279Z" transform="translate(-1.311 -0.569)"/>
		</svg>

	);
};

export const Edit = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="6.047" height="6.047" viewBox="0 0 5.047 5.047" {...props}>
			<path id="Icon_material-edit" data-name="Icon material-edit" d="M4.5,8.492V9.543H5.551l3.1-3.1L7.6,5.391ZM9.465,5.63a.279.279,0,0,0,0-.4l-.656-.656a.279.279,0,0,0-.4,0L7.9,5.091,8.952,6.143Z" transform="translate(-4.5 -4.496)" fill="#fff"/>
		</svg>

	);
};

export const Add = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="6.417" height="6.417" viewBox="0 0 5.417 5.417" {...props}>
			<path id="Icon_ionic-md-add" data-name="Icon ionic-md-add" d="M12.167,9.82H9.82v2.348H9.1V9.82H6.75V9.1H9.1V6.75H9.82V9.1h2.348Z" transform="translate(-6.75 -6.75)" fill="#fff"/>
		</svg>

	);
};

export const Upload = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="32.196" height="28.276" viewBox="0 0 448 512" {...props}>
			<path d="M246.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 109.3V320c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128zM64 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64c0 53 43 96 96 96H352c53 0 96-43 96-96V352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V352z"/>
		</svg>
	);
};

export const Pencil = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 512 512" {...props}>
			<path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"/>
		</svg>
	);
};

