import { axiosInstance } from 'services/axios';
import { CreateBulkNotificationType, CreateNotificationParams, CreateNotificationResponse, EditNotificationParams, EditNotificationResponse, GetAllNotificationsResponse, GetNotificationResponse, createNotificationSchema } from './schema';

export const createNotification = async (id?: string, data?: CreateNotificationParams) => {
	const validatedNotificatonData = createNotificationSchema.parse(data);
	const response = await axiosInstance.post(`/organizations/apps/${id}/notifications`, validatedNotificatonData);
	return response.data.notification as CreateNotificationResponse;
};

export const createBulkNotification = async (id?: string, data?: CreateBulkNotificationType) => {

	const response = await axiosInstance.post(`/organizations/apps/${id}/notifications/bulk`, data );
	return response.data.notification as CreateNotificationResponse[];
};

export const getNotification = async (id: number, notificationID: number) => {
	const response = await axiosInstance.get(`/organizations/apps/${id}/notifications/${notificationID}`);
	return response.data as GetNotificationResponse;
};

export const getAllNotificationsLive = async (id?: string) => {
	const response = await axiosInstance.get(`/organizations/apps/${id}/notifications?env=LIV`);
	return response.data as GetAllNotificationsResponse;
};


export const getAllNotifications = async (id?: string) => {
	const response = await axiosInstance.get(`/organizations/apps/${id}/notifications`);
	return response.data as GetAllNotificationsResponse;
};

export const editNotification = async (id?: string, notificationID?: number, data?: EditNotificationParams) => {
	const response = await axiosInstance.put(`/organizations/apps/${id}/notifications/${notificationID}`, data);
	return response.data.notification as EditNotificationResponse;
};

export const editBulkNotification = async (id?: string, data?: CreateBulkNotificationType) => {
	const response = await axiosInstance.put(`/organizations/apps/${id}/notifications/bulk`, data);
	return response.data.notification as EditNotificationResponse;
};