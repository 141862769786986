import { AxiosError } from 'axios';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { AppFeatIsSaving, SOgTagsID, SIsOgTagsChanges } from 'store/CustomizeApp/Appearance';
import { showFailedAlert } from 'utility/alerts';
import ThemeLayoutMutation from '../../ThemeAndLayout';
import { uploadOgTagsImageApi, createOgTagsByID, editOgTagsByID } from 'services/requests/appearance/images/og_tags';
import { UploadOgTagsImageResponse, UploadOgTagsImageParams, GetOgTagsResponse, CreateEditOgTagsImgUploadType } from 'services/requests/appearance/images/og_tags/schema';
import { SOgTagsImgID, SIsOgTagsRefresh, SOgTagsUploadFile, SOgTagsPreviewImage } from 'store/CustomizeApp/Appearance/imagesAtoms/ogTagsAtoms';
import { log } from 'utility/log';

const OgTagsMutation = () => {
    
	const { id } = useParams();
	//Atoms for OgTags
	const ogTagsID 					    = useAtomValue(SOgTagsID);
	const [ogTagsImgID, setImageID] 	= useAtom(SOgTagsImgID);
	const setIsOgTagsRefresh 			= useSetAtom(SIsOgTagsRefresh);
	const setIsSaving 					= useSetAtom(AppFeatIsSaving);
	const imageFile 					= useAtomValue(SOgTagsUploadFile);
	const isThereChanges 				= useAtomValue(SIsOgTagsChanges);
	const {saveThemeLayout} 			= ThemeLayoutMutation();
	const [previewOgTags, setPreviewOgTags]      = useAtom(SOgTagsPreviewImage);

	const {mutate: uploadOgTagsImg, isSuccess} = useMutation<
	UploadOgTagsImageResponse,
	AxiosError,
	UploadOgTagsImageParams
	>((data) => uploadOgTagsImageApi(id, data.file), {
		onSuccess: (data) => {
			setImageID(data.ImageUpload.imageUploadID);
		},
		onError: () => {
			setIsSaving(false);
			showFailedAlert('Image cannot be uploaded');
		},
	});

	//Create OgTags
	const { mutate: ogTagsFormMu } = useMutation<
		GetOgTagsResponse,
		AxiosError,
		CreateEditOgTagsImgUploadType
	>((data) => createOgTagsByID(id, data), {
		onSuccess: () => {
			setIsOgTagsRefresh(true);
			saveThemeLayout();
		},
		onError: () => {
			setIsSaving(false);
			showFailedAlert('Cannot save OG tags.');
		},
	});

	//Edit OgTags
	const { mutate: editOgTags } = useMutation<
		GetOgTagsResponse,
		AxiosError,
		CreateEditOgTagsImgUploadType
	>((data) => editOgTagsByID(id, ogTagsID, data), {
		onSuccess: (res) => {
			log('edit OgTags', res);
			setIsOgTagsRefresh(true);
			saveThemeLayout();
		},
		onError: () => {
			setIsSaving(false);
			showFailedAlert('Cannot send successfully.');
		},
	});

	const updateOgTags = () => {
		if (id)
			editOgTags({
				imageUploadID: ogTagsImgID ?? 0,
			});
	};
    
	const saveOgTags = () => {
		if(isThereChanges){
			if(imageFile){
				uploadOgTagsImg({file: imageFile});
			}else{
				if (ogTagsID) {
					updateOgTags();
				} else if(ogTagsID > 0) {
					ogTagsFormMu({
						imageUploadID: ogTagsImgID ?? 0,
					});
				}
			}
		}else saveThemeLayout();
	};

	useEffect(() => {
		if (ogTagsImgID && isSuccess)
			if (ogTagsID) {
				updateOgTags();
			} else {
				ogTagsFormMu({
					imageUploadID: ogTagsImgID ?? 0,
				});
			}
	}, [ogTagsImgID, isSuccess]);

	return {saveOgTags,};
};

export default OgTagsMutation;