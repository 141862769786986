import { atom } from 'jotai';

export const PIndustryName = atom<string>('');
export const PIsPublishShowFlashScreen = atom<boolean>(false);
export const PIsPublishShowThemeLayout = atom<boolean>(false);
export const PIsPublishShowBackground  = atom<boolean>(false);
export const PIsPublishShowNavigation  = atom<boolean>(false);
export const PIsPublishShowSidebar     = atom<boolean>(false);
export const PIsPublishShowColor       = atom<boolean>(false);
export const PIsPublishShowFont        = atom<boolean>(false);
export const PIsPublishShowFavicon     = atom<boolean>(false);
export const PIsPublishShowOgTags      = atom<boolean>(false);
export const PSrcQR = atom<string>('');

export const PIsPublishShowHome = atom<boolean>(false);
export const PIsPublishShowStore = atom<boolean>(false);
export const PIsPublishShowAbout = atom<boolean>(false);
export const PIsPublishShowContent = atom<boolean>(false);
export const PIsPublishShowPrivacy = atom<boolean>(false);
export const PIsPublishShowTerms = atom<boolean>(false);
export const PIsPublishShowAccess = atom<boolean>(false);
export const PIsPublishShowContact = atom<boolean>(false);
export const PIsPublishShowGallery = atom<boolean>(false);
export const PIsPublishShowHelp = atom<boolean>(false);
export const PIsPublishShowOffer = atom<boolean>(false);
export const PIsPublishShowNotif = atom<boolean>(false);
export const PIsPublishShowSocMed = atom<boolean>(false);

export const PIsJustPublished = atom<boolean>(false);