import React from 'react';
import { IMAGES } from 'utility/constant';

const LandingPageHowItWorks = () => {
	const items = [
		{
			id: 1,
			src: `${IMAGES.landingPageWorks1}`,
			title: 'Customize Your Apps in a Jiffy',
			description:
				'Our wide selection of templates makes it easier for you establish the look and feel of your app. All you have to do is choose the one that best fits your vision.',
		},
		{
			id: 2,
			src: `${IMAGES.landingPageWorks2}`,
			title: 'Drag and Drop Elements',
			description: 'It\'s easy to integrate icons and texts of your choice in your app interface with our drag-and-drop feature.',
		},
		{
			id: 3,
			src: `${IMAGES.landingPageWorks3}`,
			title: 'Submit Your App',
			description:
				'After creating your app, you can then proceed to submit it for review. Once approved, your app will go live!',
		},
	];

	return (
		<div className='w-full bg-white h-fit max-sm:w-full md:pt-[3.5rem] md:pb-[2rem] xl:h-full xl:pt-[0.5rem] xl:pb-[1.75rem] lg:px-10'>
			<div className='flex flex-col md:items-right items-center xl:flex-row'>
				<div className='lg:w-[55%] xl:w-[47%] w-[70%] h-auto flex items-center justify-center md:mt-0 mt-[40px]'>
					<img
						className='w-full md:max-w-[500px] h-full object-contain'
						src={IMAGES.LandingImageLaptop}
						alt='Landing Image'
					/>
				</div>
				<div className='max-sm:mt-5 w-full xl:w-[50%] lg:pr-[3rem] xl:pr-[7rem] px-[2rem] sm:px-[5rem] xl:px-16 h-full flex flex-col justify-center'>
					<div className='w-full'>
						<div className='w-full'>
							<h1 className='text-4xl sm:text-5xl font-semibold text-black mt-[25px] lg:mt-[3rem] text-center xl:text-left mb-8'>
								How It Works
							</h1>
							<p className='sm:text-lg mb-8 text-[#666666]'>
								SnapToApp revolutionizes app building for everyone whether you&apos;re a
								small business owner or an established organization.
							</p>
						</div>
						{items.map(({ id, src, title, description }) => (
							<div className='flex flex-row w-full items-center mb-8' key={id}>
								<img src={src} className='w-[91px] h-full' />
								<div className='ml-4 flex flex-col'>
									<div className='font-semibold text-[22px] text-black'>{title}</div>
									<div className='mt-2 break-words text-[16px] text-[#666666] '>{description}</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default LandingPageHowItWorks;
