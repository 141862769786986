import { SCardBackgroundColor, SCardBorderColor, SCardContentColor, SCardIconColor, SCardTitleColor } from 'store/CustomizeApp/Appearance/colorAtom';

import { FNotif } from 'store/CustomizeApp/Features/notificationAtom';
import React from 'react';
import { classNames } from 'utility';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';

const Notification: React.FC = () => {
	const notifDatas = useAtomValue(FNotif);
	const cardBackgroundColor = useAtomValue(SCardBackgroundColor);
	const cardBorderColor = useAtomValue(SCardBorderColor);
	const cardIconColor = useAtomValue(SCardIconColor);
	const cardTitleColor = useAtomValue(SCardTitleColor);
	const cardContentColor = useAtomValue(SCardContentColor);
	
	return(
		<div
			className={classNames('py-[60px] px-3 h-full absolute overflow-y-scroll no-scrollbar w-full')}
		>
			{notifDatas.rows && notifDatas.rows.map(({icon,message,title, url, whenAdded, notificationID}, index) => (
				<div key={notificationID} className='w-full flex flex-col py-3 mb-[5px] border border-1 rounded-md' style={{ backgroundColor: `${cardBackgroundColor}`, borderColor: `${cardBorderColor}` }}>
					<div  className='flex m-3'>
						<div className='text-primary text-5xl w-[64px] h-[62.7px] flex items-center' style={{ color: `${cardIconColor}` }}>
							<i className={classNames(icon)}/>
						</div>
						<div className='w-full mr-3 mt-1'>
							<div className='ml-3'>
								<div className=''>
									<p className='text-sm w-48 font-bold break-words line-clamp-none' style={{ color: `${cardTitleColor}` }}> {title} </p>
								</div>
								<p className='font-normal text-xs w-36  break-words line-clamp-3' style={{ color: `${cardContentColor}` }}> {message} </p>
								<p className='font-light text-xs mt-2 ' style={{ color: `${cardContentColor}` }}>{ whenAdded ? dayjs(whenAdded).format('MM/DD/YYYY') : ''}</p>
							</div> 
						</div>
					</div>
				</div>
			))}
		</div>
				
				
		
	);
};

export default Notification;