import { CreateGalleryResponse, CreateGalleryType, UpdateGalleryParamsType, UpdateGalleryResponseType, albumIdType } from 'services/requests/Features/Gallery/schema';
import { FDeleteAlbumImagesAtom, FGallery, FGalleryAlbumIdAtom, FGalleryImageAtom, FIsGalleryRefresh, FUpdateGallery } from 'store/CustomizeApp/Features/galleryAtom';
import React, { useEffect, useState } from 'react';
import { createGalleryApi, deleteAlbumApi, deleteAlbumImageApi, updateGalleryApi } from 'services/requests/Features/Gallery';
import { showFailedAlert } from 'utility/alerts';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { AppFeatIsSaving } from 'store/CustomizeApp/Appearance';
import { AxiosError } from 'axios';
import HelpMutation from '../Help';
import { selectedImages } from '../../Gallery';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { FIsGalleryChanges } from 'store/CustomizeApp/Features';

const GalleryMutation = () => {
	// Gallery
	const accordionAlbums = useAtomValue(FGalleryImageAtom);
	const [album, setAlbum] = useAtom(FGallery);
	const [updateAlbum,setUpdateAlbum] = useAtom(FUpdateGallery);
	const [galleryAlbumId, getGalleryAlbumId] = useAtom(FGalleryAlbumIdAtom);
	const [imageUploadId, setImageUploadId] = useAtom(FDeleteAlbumImagesAtom);
	const [, setIsGalleryRefresh] = useAtom(FIsGalleryRefresh);
	const [, setSelectedImages] = useAtom(selectedImages);
	const setIsSaving = useSetAtom(AppFeatIsSaving);
	const isThereChanges = useAtomValue(FIsGalleryChanges);
	const { id } = useParams();
	const { saveHelp } = HelpMutation();
		
	const [currentIndex, setCurrentIndex] = useState(0);
	
	const queryClient = useQueryClient();
		
	const { mutate: deleteAlbumMu, isSuccess: isDeleteGallerySuccess, reset: resetDeleteGallery} =
		useMutation<null, AxiosError, albumIdType>((data) =>
			deleteAlbumApi(id, data.albumID),
		{
			onSettled: () => {
				queryClient.invalidateQueries('gallery');
			}
		}
		);

	const { mutate: deleteAlbumImageMu, isSuccess: isDeleteImageSuccess, reset: resetDeleteImage } =
		useMutation<null, AxiosError, Array<number>>((data) =>
			deleteAlbumImageApi(id, data),
		{
			onSettled: () => {
				queryClient.invalidateQueries('gallery');
			}
		}
		);

	const { mutate: updateGalleryMu, isSuccess: isUpdateGallerySuccess, reset: resetUpdateGallery } =
		useMutation<UpdateGalleryResponseType, AxiosError, UpdateGalleryParamsType>(
			(data) => updateGalleryApi(data, id),
			{
				onSettled: () => {
					queryClient.invalidateQueries('gallery');
				}
			}
		);

	const { mutate: createGalleryMu, isSuccess: isCreateGallerySuccess, reset: reseCreateGallery } =
		useMutation<CreateGalleryResponse, AxiosError, CreateGalleryType>(
			(data) => createGalleryApi(data, id),
			{
				onSuccess: (data) => {
					accordionAlbums[accordionAlbums.length - 1].albumId = data.album.albumID;

					if (currentIndex == album.length - 1) {
						queryClient.invalidateQueries('gallery');
						resetGalleryChanges();
					}
				},
				onError: () => {
					setIsSaving(false);
					showFailedAlert('Cannot save Gallery');
				},
			}
		);
	
	const resetGalleryChanges = () => {
		accordionAlbums.map((album) => {
			album.isTitleNew = false;
			album.hasImgToDelete = false;
			album.hasImgToUpload = false;
		});

		setAlbum([]);
		saveHelp();
	};

	function splitArrayIntoBatches(images: File[], maxSizeInBytes: number) {
		const batches = [];
		let currentBatch: File[] = [];
		let currentSize = 0;
	
		for (const item of images) {
			const itemSize = item.size;
			if (currentSize + itemSize <= maxSizeInBytes) {
				currentBatch.push(item);
				currentSize += itemSize;
			} else {
				batches.push(currentBatch);
				currentBatch = [item];
				currentSize = itemSize;
			}
		}
	
		if (currentBatch.length > 0) {
			batches.push(currentBatch);
		}
		
		return batches;
	}

	const onSaveGallery = () => {
		if (galleryAlbumId.length > 0) {
			for (let index = 0; index < galleryAlbumId.length; index++) {
				galleryAlbumId[index] !== 0 && deleteAlbumMu({ albumID: galleryAlbumId[index] });
			}
		}

		if (imageUploadId.length > 0) {
			deleteAlbumImageMu(imageUploadId);
		}

		if (updateAlbum && updateAlbum.length) {
			for (let idx = 0; idx < updateAlbum.length; idx++) {
				if (!galleryAlbumId.includes(updateAlbum[idx].albumId)) {
					const images = updateAlbum[idx].images.map((image) => image.file);
					const batchSize = 10 * 1024 * 1024;
					const batches = splitArrayIntoBatches(images, batchSize);

					if (batches.length > 0) {
						batches.map((batch, index) => {
							const params = {
								albumId: updateAlbum[idx].albumId,
								title: updateAlbum[idx].title,
								images: batch,
								coverIndex: -1,
							};
							
							if (index == batches.length - 1) {
								params.coverIndex = updateAlbum[idx].coverIndex;
							}
								
							updateGalleryMu(params);
						});
					} else {
						const params = {
							coverIndex: updateAlbum[idx].coverIndex,
							albumId: updateAlbum[idx].albumId,
							title: updateAlbum[idx].title,
							images: images,
						};
						
						updateGalleryMu(params);
					}
				}
			}
		}

		for (let i = 0; i < album.length; i++) {
			setCurrentIndex(i);

			let file: File[] = [];

			if (album[i].images.length > 0) {
				for (let j = 0; j < album[i].images.length; j++) {
					file = [...file, album[i].images[j].file];
				}
				const params = {
					coverIndex: album[i].coverIndex,
					title: album[i].title,
					images: file,
				};
				createGalleryMu(params);
			} else {
				const params = {
					coverIndex: album[i].coverIndex,
					title: album[i].title,
					images: file,
				};
				createGalleryMu(params);
			}
		}

		!(album.length > 0) && resetGalleryChanges();
	};

	const saveGallery = () => {
		if(isThereChanges){
			if (
				album?.length > 0 ||
				galleryAlbumId?.length > 0 ||
				updateAlbum?.length > 0 ||
				imageUploadId?.length > 0
			) {
				return onSaveGallery();
			} else { 
				saveHelp();
			}
		}else saveHelp();
	};

	useEffect(() => {
		if (
			isUpdateGallerySuccess ||
			isCreateGallerySuccess ||
			isDeleteGallerySuccess ||
			isDeleteImageSuccess
		) {
			setIsGalleryRefresh(true);
			// setAlbum([]);
			setUpdateAlbum([]);
			getGalleryAlbumId([]);
			setImageUploadId([]);
			setSelectedImages([]);
			reseCreateGallery();
			resetUpdateGallery();
			resetDeleteGallery();
			resetDeleteImage();
		}
	}, [
		isUpdateGallerySuccess,
		isDeleteGallerySuccess,
		isDeleteImageSuccess,
		isCreateGallerySuccess,
	]);
    
	return {saveGallery,};
};

export default GalleryMutation;