import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { IMAGES, classNames } from 'utility';
import { Listbox, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Tooltip from 'components/Common/Tooltip';

export interface IOptionItem {
  label: string;
  alternate?: string;
  value: string | number;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  color?: string;
  excluded?: boolean;
}

interface IProps {
  value: string | number;
  options: IOptionItem[];
  excluded?: Partial<IOptionItem>[];
  onChange: (value: string) => void;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  isTruncated?: boolean;
}

const FontDropdown: React.FC<IProps> = (props) => {

	const { options, excluded, value,  onChange, label, placeholder = 'Select Value', disabled = false, isTruncated = true } = props;
  
	const [selectedItem, setSelectedItem] = useState<IOptionItem | null>();
  
	const list: IOptionItem[] = useMemo(() => {
		if(excluded) {
			const values = excluded.map(i => i.value);
      
			return options.filter(option => !values.includes(option.value) || selectedItem?.value === option.value);
		}
    
		return options;
	}, [options, excluded]);

	useEffect(() => {
		const option = options.find((option) => option.value === value);
		if (option) 
			setSelectedItem(option);
		else
			setSelectedItem(null);
	}, [options, value]);
  
	return (
		<div>
			<Listbox value={value} onChange={onChange} disabled={disabled || selectedItem?.excluded}>
				{({ open }) => (
					<>
						<div className='relative'>
							<Tooltip message='Include Navigation Text must be checked.' isVisible={disabled} className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap mt-2'>
								<Listbox.Button style={{fontFamily: `${value}`}}  className={classNames(
									'text-sm relative w-full rounded-md pl-3 py-2 text-left cursor-pointer',
									selectedItem?.color
										? `bg-${selectedItem?.color} bg-opacity-20 border border-transparent`
										: 'border border-gray-300 shadow-sm focus:outline-none focus:ring-1 focus:border-input-active',
									disabled
										? 'border-[#C3CAD9] bg-[#EAECEF] hover:cursor-not-allowed'
										: ''
								)}>
									{disabled && <span className="sr-only">Disabled</span>}
									<div className={classNames(
										'flex items-center justify-between w-56 max-sm:w-[120px]',
										value === '' || value === -1 ? 'text-custom-gray-300' : '',
										selectedItem?.color ? `text-${selectedItem?.color}` : '',
									)}>
										{selectedItem?.icon && <selectedItem.icon className='h-4 w-4 -ml-1.5 mr-2' />}
										<p className='truncate'>
											{label && <span className='font-bold mr-1'>{label}:</span>}
											{selectedItem?.label || <span className='text-custom-gray-300'>{placeholder}</span>}
										</p>
										{(!selectedItem?.excluded && !disabled) && (
											<span className='pointer-events-none absolute top-[5px] bottom-0 right-0 flex items-center pr-2'>
												<img className='cursor-pointer' src={IMAGES.Triangle} alt="" />
											</span>
										)}
									</div>
								
								</Listbox.Button>
							</Tooltip>

							<Transition
								show={open}
								as={Fragment}
								leave='transition ease-in duration-100'
								leaveFrom='opacity-100'
								leaveTo='opacity-0'
							>
								
								<Listbox.Options className='absolute z-10 mt-1 w-full bg-white shadow-lg sm:max-h-60 max-h-40 rounded-md py-1 ring-1 ring-primary ring-opacity-5 overflow-auto focus:outline-none text-sm
                scrollbar-thin scrollbar-thumb-custom-gray-300 scrollbar-track-white scrollbar-thumb-rounded-full scrollbar-track-rounded-full list-none'>
									{list.map((option, index) => {
										if (option.excluded) return;

										return (
											<Listbox.Option
												key={index}
												className={({ active }) =>
													classNames(
														active ? 'bg-gray-100' : '',
														'cursor-default select-none relative py-2 pl-3 pr-9 text-gray-700 text-sm -ml-5'
													)
												}
												value={option.value}
												style={{fontFamily: `${option.value}`}}
											>
												{({ selected }) => (
													<>
														<span className={classNames(
															'block',
															isTruncated ? 'truncate' : '',
															selected ? 'font-semibold text-primary' : 'font-normal'
														)}>
															{option.alternate || option.label}
														</span>

														{selected && (
															<span className='absolute inset-y-0 right-0 flex items-center pr-4'>
																<CheckIcon className='h-5 w-5 text-primary' aria-hidden='true' />
															</span>
														)}
													</>
												)}
											</Listbox.Option>
										);
									})}
								</Listbox.Options>
							</Transition>
						</div>
					</>
				)}
			</Listbox>
		</div>
	);
};

export default FontDropdown;
