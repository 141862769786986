import React, { useEffect, useState } from 'react';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import { 
	StripeCardCvcElementChangeEvent,
	StripeCardExpiryElementChangeEvent,
	StripeCardNumberElementChangeEvent
} from '@stripe/stripe-js';
import { useAtom } from 'jotai';
import { classNames } from 'utility';
import * as subscriptionAtom from '../../../../store/CustomizeApp/Payment/subscription';
import FormInput from 'components/Common/Forms/Inputs';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { SubscriptionResponseSchema } from 'services/requests/Payment/schema';
import { isCardCvcComplete, isCardExpiryComplete, cardHolderName } from 'store/EditProfile/cardManagement';
import { log } from 'utility/log';


const StripeCardInputElement = () => {

	const [cardBrand ,setCardBrand] = useAtom(subscriptionAtom.FCardBrand);

	const [cardNumberError, setCardNumberError] = useAtom(subscriptionAtom.FCardNumberError);
	const [cardExpiryError, setCardExpiryError] = useAtom(subscriptionAtom.FExpiryError);
	const [cardCVCError, setCardCVCError] = useAtom(subscriptionAtom.FCVCError);

	const [cardOutlineError, setCardOutlineError] = useState(false);
	const [expiryOutlineError, setExpiryOutlineError] = useState(false);
	const [CVCOutlineError, setCVCOutlineError] = useState(false);
	const [cardName,setCardName] = useAtom(cardHolderName);
	const [isCardExpiryComp, setIsCardExpiryComp] = useAtom(isCardExpiryComplete);
	const [isCardCvcComp, setIsCardCvcComp] = useAtom(isCardCvcComplete);


	useEffect(() => {
		setCardNumberError('');
		setCardExpiryError('');
		setCardCVCError('');
		setCardName('');
		setCardBrand('');
		setIsCardExpiryComp(false);
		setIsCardCvcComp(false);
	},[]);

	const onChangeCardNumber = (event: StripeCardNumberElementChangeEvent) => {
		log('cardNumber:', event);
		log('card number error: ',event.error);

		if (event.error?.code === 'invalid_number') {
			setCardNumberError('invalid number');
			setCardOutlineError(true);
		}

		if (event.error?.code === 'incomplete_number') {
			setCardNumberError('incomplete number');
			setCardOutlineError(true);
		}

		if (event.error === undefined) {
			setCardNumberError('');
			setCardOutlineError(false);
		}

		if(event.empty === true) {
			setCardNumberError('');
			setCardOutlineError(false);
		}

		if (event.complete) {
			setCardBrand(event?.brand);
		}
	};

	const onChangeCardExpiry = (event: StripeCardExpiryElementChangeEvent) => {
		
		if (event.error?.code === 'invalid_expiry_year_past') {
			setCardExpiryError('invalid expiry year past');
			setExpiryOutlineError(true);
		}

		if (event.error === undefined) {
			setCardExpiryError('');
			setExpiryOutlineError(false);
		}

		if(event.empty === true) {
			setCardExpiryError('');
			setExpiryOutlineError(false);
		}

		setIsCardExpiryComp(event.complete); //final validation if card expiry is valid

	};

	const onChangeCardCVC = (event: StripeCardCvcElementChangeEvent) => {
	
		if (event.error?.code === 'incomplete_cvc') {
			setCardCVCError('incomplete cvc');
			setCVCOutlineError(true);
		}

		if (event.error === undefined) {
			setCardCVCError('');
			setCVCOutlineError(false);
		}

		if(event.empty === true) {
			setCardCVCError('');
			setCVCOutlineError(false);
		}

		setIsCardCvcComp(event.complete); //final validation if card cvc is valid

	};

	
	const { control } = useForm({
		defaultValues: { name: ''},
		mode: 'onBlur',
		resolver: zodResolver(SubscriptionResponseSchema),
	});

	return (
		<form id='payment-form'>
			<div className='tw-p-2 tw-mt-4 tw-h-14 relative text-[#464255] tw-border tw-rounded-xl'>
				<p className='my-3 font-normal text-sm'> Name on the card </p>
				{/* <Controller
					control={control}
					name='name'
					render={({ field: { name, onChange }, fieldState: { error } }) => (
						<FormInput
							onChange={onChange}
							name={name}
							error={error?.message}
							className='text-base py-4 rounded'
							type='text'
							placeholder='Enter Name'
						/>
					)}
				/> */}
				<FormInput
					onChange={(e) => {
						setCardName(e);
						log('onchange Name: ' + e);
					} }
					// name={name}
					// error={error?.message}
					className='text-base py-4 rounded'
					type='text'
					placeholder='Enter Name'
					value={cardName}
				/>
				<p className='my-3 font-normal text-sm'> Credit Card Number </p>
				<CardNumberElement
					id='card-element'
					className={classNames(
						cardOutlineError 
							? 'border-red-600'
							: '',
						'py-4 px-3 w-full border rounded-[5px]'
					)}
					options={{
						showIcon: true,
						iconStyle: 'solid',
						placeholder: 'XXXX XXXX XXXX XXXX',
						style: {
							base: {
								fontSize: '16px',
								fontFamily:'Poppins, sans-serif',
								letterSpacing: '.30em',
								color: '#000000',
								'::placeholder': {
									color: '#95929D',  // custom gray - to ask
								},
							},
							invalid: {
								iconColor: '#BBB8B8', // custom gray - to ask
								color: 'gray',
							},
						},
					}}
					onChange={onChangeCardNumber}
				/>
				<p className='mt-2 text-sm text-red-600'>
					{cardNumberError !== ''
						? 'Please enter a valid card number.'
						: ''
					}
				</p>
				<div className='flex mt-5'>	
					<div className='w-2/3 mr-4'>
						<p className='mb-4 font-normal text-sm'> Expiry Date </p>
						<CardExpiryElement
							id='card-expiry-element'
							className={classNames(
								expiryOutlineError
									? 'border-red-600'
									: '',
								'py-4 px-3 w-full border rounded-[5px]'
							)}
							options={{
								style: {
									base: {
										fontSize: '16px',
										fontFamily:'Poppins, sans-serif',
										letterSpacing: '.2em',
										color: '#000000',
										'::placeholder': {
											color: '#95929D',  // custom gray - to ask
										},
									},
									invalid: {
										iconColor: '#BBB8B8', // custom gray - to ask
										color: 'gray',
									},
								}
							}}
							onChange={onChangeCardExpiry}
						/>
						<p className='mt-2 text-sm text-red-600'>
							{cardExpiryError === 'invalid expiry year past'
								? 'Please enter a valid expiry date.'
								: ''
							}		
						</p>
					</div>  
					<div className='w-2/5'>
						<p className='mb-4 font-normal text-sm'> CVC </p>
						<CardCvcElement
							id='card-cvc-element'
							className={classNames(
								CVCOutlineError
									? 'border-red-600'
									: '',
								'py-4 px-3 w-full border rounded-[5px]'
							)}
							options={{
								placeholder: cardBrand === 'amex' ? 'XXXX' : 'XXX',
								style: {
									base: {
										fontSize: '16px',
										fontFamily:'Poppins, sans-serif',
										letterSpacing: '.2em',
										color: '#000000',
										'::placeholder': {
											color: '#95929D',  // custom gray - to ask
										},
									},
									invalid: {
										iconColor: '#BBB8B8', // custom gray - to ask
										color: 'gray',
									},
								}
							}}
							onChange={onChangeCardCVC}
						/>
						<p className='mt-2 text-sm text-red-600'>
							{cardCVCError === 'incomplete cvc'
								? 'Please enter a valid cvc.'
								: ''
							}
						</p>
					</div>  
				</div>
			</div>
		</form>
	);
};

export default StripeCardInputElement;