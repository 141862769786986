import Button from 'components/Common/Buttons/Button';
import Footer from 'components/Common/Footer';
import { IMAGES } from 'utility';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const LandingPageFooter = () => {
	const navigate = useNavigate();
	return (
		<div className='max-sm:w-[100%] w-full md:h-fit sm:h-[617px] h-[865px] bg-white'>
			<div className='flex flex-col w-full h-fit md:h-[500px] md:flex-row items-center md:items-start text-white bg-[#E0781D]  '>
				<div className='w-full text-center md:text-left flex items-center md:items-start flex-col justify-center h-full mt-[43px] md:mt-[0] md:px-[0px] sm:px-[110px] px-[32px]  md:w-[50%]  md:pl-[110px]'>
					<h1 className='text-5xl font-normal'>Try SnapToApp Today</h1>
					<p className='mt-6 text-lg'>
						We’re thrilled to see your creations using our platform and how it can
						help your brand and business thrive. Build your app today with SnapToApp!
					</p>
					<Button
						className='text-base bg-white w-[186px] font-bold text-black px-[26px] py-[12px] mt-[25px]'
						onClick={() => navigate('/signup')}
					>
						Sign up for free
					</Button>
				</div>
				<div className='w-[50%] flex justify-center items-end mt-[23px] lg:mt-[5%] lg:mb-0 md:mt-[10%] sm:mt-[11px] md:mb-[0px] sm:mb-[-200px] mb-[-150px]'>
					<img
						className='z-1 w-[285px] h-auto sm:h-[549px]'
						src={IMAGES.landingPageHome4}
						alt='Landing Image'
					/>
				</div>
			</div>
			{/* <div className='z-30'>
				<Footer />
			</div> */}
		</div>
	);
};

export default LandingPageFooter;
