import { Bars2Icon, DocumentTextIcon, ExclamationCircleIcon, EyeIcon, EyeSlashIcon, HomeIcon, ShoppingCartIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useRef, useState } from 'react';
import { SIsNavigationTextCheck, SNavHiddenCount, SNavigation } from 'store/CustomizeApp/Appearance/navigationAtom';

import { IMAGES } from 'utility/constant';
import { ReactSortable } from "react-sortablejs";
import Tooltip from 'components/Common/Tooltip';
import { useAtom } from 'jotai';
import { useMediaQuery } from 'react-responsive';

const Navigation: React.FC = () => {

	const [navigationStatus, setNavigationStatus] = useAtom(SNavigation);
	const [isNavigationCheck, setIsNavigationCheck] = useAtom(SIsNavigationTextCheck);
	const [hiddenCount, setHiddenCount] = useAtom(SNavHiddenCount);
	const [isChecked, setIsChecked] = useState(isNavigationCheck);
	const lowerRes = useMediaQuery({ maxWidth: 468 });

	const Home = () => {
		return (
			<HomeIcon className='w-6 h-6 text-[#707070]' />
		);
	};
	const Cart = () => {
		return (
			<ShoppingCartIcon className='w-6 h-6 text-[#707070]' />
		);
	};
	const About = () => {
		return (
			<ExclamationCircleIcon className='w-6 h-6 text-[#707070]' />
		);
	};
	const Content = () => {
		return (
			<DocumentTextIcon className='w-6 h-6 text-[#707070]' />
		);
	};

	const navListPreview = (navigationStatus.map((nav) => {
		return {
			id: nav.id,
			label: nav.label,
			isHidden: nav.isHidden,
			icon: nav.label === 'Home' ? Home()
				: nav.label === 'Cart' ? Cart()
					: nav.label === 'About' ? About()
						: nav.label.startsWith('Content') ? Content()
							: ''
		};
	}));

	const onDragEnd = (result: any) => {
		if (!result.destination) {
			return; // Item dropped outside the list
		}

		const copyListItems = [...navListPreview];
		const [reorderedItem] = copyListItems.splice(result.source.index, 1);
		copyListItems.splice(result.destination.index, 0, reorderedItem);

		setNavigationStatus(copyListItems);
	};

	const onClickEyeIcon = (index: number) => {
		const newNavList = [...navListPreview];

		if (hiddenCount < 3 && !newNavList[index].isHidden) {
			setHiddenCount(hiddenCount + 1);
			newNavList[index].isHidden = true;
			setNavigationStatus(newNavList);
		} else if (hiddenCount <= 3 && newNavList[index].isHidden) {
			setHiddenCount(hiddenCount - 1);
			newNavList[index].isHidden = false;
			setNavigationStatus(newNavList);
		}
	};

	// atom SIsNavigationTextCheck sometimes becomes undefined after saving causing checkbox to be checked
	// temporary fix is to add a local state isChecked to render value of checkbox

	const onChangeCheckbox = () => {
		setIsChecked(!isChecked);
	};

	useEffect(() => {
		setIsNavigationCheck(isChecked);
	}, [isChecked]);

	useEffect(() => {
		setHiddenCount(navListPreview.filter((side) => side.isHidden).length);
	}, [navListPreview]);

	useEffect(() => {
		setNavigationStatus(navListPreview);
	}, []);


	return (
		<div className='bg-white rounded-lg my-8 mr-6 w-full 2xl:my-8 2xl:mr-6 2xl:w-full lg:w-full lg:-ml-0 md:w-[93.5vw] md:-ml-[10vw] sm:w-[93.5vw] sm:-ml-[10vw] max-sm:w-[85vw]'>
			<div className='w-full px-10 rounded 2xl:px-10 2xl:w-full md:px-10 md:w-full sm:px-8 sm:w-full max-sm:w-[78vw] max-sm:px-[4vw]'>
				<div className='py-5 flex-row'>
					<div className='flex justify-between items-center pt-2'>
						<span className='text-[#707070] font-bold text-2xl block 2xl:text-2xl sm:text-md max-sm:text-xl'>Nav Bar</span>
						<div className='flex gap-3 items-baseline'>
							{/* -----------Video Tutorial and Help Icons------------ */}
							<Tooltip className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
								<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.CameraIcon} alt='camera icon' />
							</Tooltip>
							<Tooltip className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
								<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.InfoIcon} alt='info icon' />
							</Tooltip>
							{/* ---------------------------------------------------- */}
						</div>
					</div>
					<div className='w-1/2 flex justify-end items-baseline 2xl:w-1/2 sm:w-full max-sm:w-[110%]'>
						<div className='flex items-center'>
							<input type='checkbox' checked={isChecked} className='mr-1' onChange={() => onChangeCheckbox()} />
							<span className='font-normal text-[#707070] text-sm 2xl:text-sm sm:text-xs max-sm:text-xs'>Include Navigation Text</span>
						</div>
					</div>

					<div>
						<ReactSortable							
							dragClass="sortableDrag"
							list={navListPreview}
							setList={setNavigationStatus}
							animation={200}
							easing="ease-out"
						>
							{navListPreview.map((nav, index) => (
								<div								
									className="rounded-md border-2 border-gray-300 w-1/2 h-16 flex justify-between px-3 items-center my-2 cursor-grab hover:bg-gray-300 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-full sm:w-full max-sm:w-[110%]"
								>
									<div className='flex gap-4 items-center'>
										{nav.icon}
										<span className='text-md text-[#707070] max-sm:text-sm'>{nav.label}</span>
									</div>
									<div className='flex gap-3 items-center 2xl:gap-3 max-sm:gap-1'>
										<div onClick={() => onClickEyeIcon(index)}>
											{nav.isHidden ?
												<EyeSlashIcon className='w-6 h-6 text-[#707070] cursor-pointer 2xl:w-6 2xl:h-6 max-sm:w-5 max-sm:h-5' />
												:
												<EyeIcon className='w-6 h-6 text-[#707070] cursor-pointer 2xl:w-6 2xl:h-6 max-sm:w-5 max-sm:h-5' />
											}
										</div>
										<Bars2Icon className='w-5 h-5 text-[#707070] 2xl:w-5 2xl:h-5 max-sm:w-4 max-sm:h-4 ' />
									</div>
								</div>
							))}
						</ReactSortable>
					</div>


				</div>
			</div>
		</div>
	);
};

export default Navigation;