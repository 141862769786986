import { z } from 'zod';

export const createAboutSchema = z.object({
	description:       z.string(),
	isTurnedOn:        z.boolean(),
});

export const getAboutSchema = z.object({
	about: z.object({
		aboutID:        z.number(),
		appID:          z.number(),
		description:    z.string(),
		isTurnedOn:     z.boolean(),
		status:         z.string(),
		whoAdded:       z.number(),
		whoUpdate:      z.number(),
		whenAdded:      z.string(),
		whenUpdated:    z.string(),
	})
});   
   
export const editAboutSchema = z.object({
	description: z.string(), 
	isTurnedOn: z.boolean(), 
});

export type createAboutType      = z.infer<typeof createAboutSchema>;
export type createAboutResponse  = z.infer<typeof getAboutSchema>;
export type createAboutParams  = z.infer<typeof createAboutSchema>;

export type getAboutType      = z.infer<typeof getAboutSchema>;
export type getAboutResponse  = z.infer<typeof getAboutSchema>;

export type editAboutType      = z.infer<typeof editAboutSchema>;
export type editAboutResponse  = z.infer<typeof getAboutSchema>;
export type editAboutParams  = z.infer<typeof editAboutSchema>;