import { Button } from 'components/Common/Buttons';
import React from 'react';
import Shadow from '../../../../assets/ThankyouMessage/shadow.png';
import { Link } from 'react-router-dom';
import Success from '../../../../assets/ThankyouMessage/SuccessImage.png';

const SuccessfullRedirectionForgot: React.FC = () =>{
	return(
		<div className='w-full h-[90vh] flex flex-col justify-center items-center bg-[#F6F6F6]'>
			<div className='p-4'>
				<img className='w-[260px] h-[200px]' src={Success} alt='envelope image' />
				<img className='w-[180px] -mt-2' src={Shadow} alt='shadow image' />
			</div>
			<div className='p-2 font-light'>
				<h1 className='text-center font-bold text-[#464255] tracking-wide'>Password Reset Successfully!</h1>
			</div>
			
			<div className='p-2 w-1/2 mb-7 text-[#464255] text-[15px] font-normal tracking-wide'>
				<p className='mt-4 text-center text-[13px] tracking-wider'>Your password has been changed.</p>
				<p className='mt-8 text-center text-[13px] tracking-wider'>You can now use your new password to sign in to your account.</p>
			</div>
			<Link id='nav-link' to='/login' className='w-1/4 py-1.5 text-sm flex justify-center font-semibold items-center bg-primary border rounded-[5px] shadow-sm focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed hover:bg-[#e98923] border-transparent disabled:bg-primary text-white'>
				Sign In
			</Link>
		</div>
	);
};

export default SuccessfullRedirectionForgot;
