import { AppFeatIsSaving, SFontID, SIsFontChanges } from 'store/CustomizeApp/Appearance';
import { EditFontSettingResponse, EditFontSettingSchema } from 'services/requests/appearance/font/font_setting/schema';
import { SDefaultFontSize, SHeadlineTextFontID, SIsFontRefresh, SNavigationTextFontID } from 'store/CustomizeApp/Appearance/fontAtom';
import { useAtomValue, useSetAtom } from 'jotai';

import { AxiosError } from 'axios';
import ThemeLayoutMutation from '../ThemeAndLayout';
import { editFontSettingByAppID } from 'services/requests/appearance/font/font_setting';
import { showFailedAlert } from 'utility/alerts';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import FaviconMutation from '../Images/favicon';

const FontMutation = () => {
	const { id } = useParams();
	const selectedFontID = useAtomValue(SFontID);

	//Atoms for Font
	const headlineTextFontID = useAtomValue(SHeadlineTextFontID);
	const navTextFontID = useAtomValue(SNavigationTextFontID);
	const fontSize = useAtomValue(SDefaultFontSize);
	const setFontRefresh = useSetAtom(SIsFontRefresh);
	const setIsSaving = useSetAtom(AppFeatIsSaving);
	const isThereChanges = useAtomValue(SIsFontChanges);
	// const {saveThemeLayout} = ThemeLayoutMutation();
	const {saveFavicon} = FaviconMutation();

	// Edit Font
	const { mutate: editFont } = useMutation<
		EditFontSettingResponse,
		AxiosError,
		EditFontSettingSchema
	>((data) => editFontSettingByAppID(data, id, selectedFontID), {
		onSuccess: () => {
			setFontRefresh(true);
			saveFavicon();
		},
		onError: () => {
			setIsSaving(false);
			showFailedAlert('Cannot save font, please try again.');
		},
	});

	const saveFont = () => {
		if(isThereChanges){
			if (id) {
				editFont({
					headlineTextFontID: headlineTextFontID,
					bodyTextFontID: navTextFontID,
					size: fontSize,
				});
			}
		}else saveFavicon();
	};

	return {saveFont,};

};

export default FontMutation;