import { EditAppColorScehemeByIdResponse, EditAppColorSchemeByIdSchema, GetAllAppColorSchemeResponse, GetAppColorSchemeResponse } from './schema';

import { axiosInstance } from 'services/axios';

export const getAllAppColorScheme = async (appID: number) => {
	const response = await axiosInstance.get(`/organizations/apps/${appID}/colors`);
    
	return response.data as GetAllAppColorSchemeResponse;
};

export const getAppColorSchemeByIDLive = async (appID?: string, colorSchemeID?: number) => {
	const response = await axiosInstance.get(`/organizations/apps/${appID}/colorScheme/${colorSchemeID}?env=LIV`);
    
	return response.data as GetAppColorSchemeResponse;
};

export const getAppColorSchemeByID = async (appID?: string, colorSchemeID?: number) => {
	const response = await axiosInstance.get(`/organizations/apps/${appID}/colorScheme/${colorSchemeID}`);
    
	return response.data as GetAppColorSchemeResponse;
};

export const editAppColorSchemeByID = async (params: EditAppColorSchemeByIdSchema, appID?: string, colorSchemeID?: number) => {
	const response = await axiosInstance.put(`/organizations/apps/${appID}/colorScheme/${colorSchemeID}`, params);
    
	return response.data as EditAppColorScehemeByIdResponse;
};