import { atom } from 'jotai';

const userDataHolder = localStorage.getItem('userData');
const userParse = userDataHolder ? JSON.parse(userDataHolder) : null;

export const SEditProfileUserId           = atom<number | undefined>(undefined);
export const SEditProfileFirstName        = atom<string | undefined>('');
export const SEditProfileLastName         = atom<string | undefined>('');
export const SEditProfilePhoneNumber      = atom<string | undefined | null>('');
export const SEditProfileBirthDate        = atom<string | undefined | null>('');
export const SEditProfileGender           = atom<string | undefined | null>('');
export const SEditProfileImage            = atom<any | null>(null);
export const SEditProfileImagePreview     = atom<any | null>(null);
export const SEditProfileLoading          = atom<boolean>(false);