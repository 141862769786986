import { atom } from 'jotai';
import * as types from 'services/requests/Features/SocialMedia/schema';

export const FSocialMediaInitVal = atom({
	icon: 'fas fa-globe',
	label: 'Website',
	WebsiteLink: '',
	sortOrder: 0
});

export const FsocialMediasAtom = atom<types.socialMediaType [] | []>([]);

export const FSocialMediasAtom = atom<types.SocialMediaType [] | []>([]);
export const FDeleteSocialIdsAtom = atom<types.DeleteSocialMediaType [] | []>([]);


export const updateSocialMedia = atom(
	null,
	(get,set, update: types.socialMediaType)=>{
		set(FsocialMediasAtom, 
			get(FsocialMediasAtom)
				.map(social => 
					social.socialMediaID === update.socialMediaID 
						? update 
						: social));
	}
);
export const FsocialMediasRefreshAtom = atom<boolean>(true);
