/* eslint-disable no-fallthrough */
import { AxiosError } from 'axios';
import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router';
import { verifyEmailToken } from 'services/requests/auth';
import { VerifyEmailResponseType, VerifyEmailType } from 'services/requests/auth/schema';
import { showFailedAlert, showSuccessAlert } from 'utility/alerts';

const VerifyEmail: React.FC = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const tokenID = searchParams.get('id');

	const { 
		mutate: verifyEmailMu 
	} = useMutation<VerifyEmailResponseType, AxiosError,VerifyEmailType>(
		(data) => verifyEmailToken(data),
		{
			onSuccess:(data) => {
				if (data.success && data.email) {
					showSuccessAlert('The account has been verified successfully!');
					navigate('/login');
				}
			},
			onError: (err) => {
				switch (err.response?.status) {
				case 400:
					showFailedAlert('The link has expired.');
					navigate('/login');
					return;
				case 404:
					showFailedAlert('The account has already been verified.');
					navigate('/login');
					return;
				default:
					showFailedAlert('Something went wrong!');
					navigate('/login');
					return;
				}
			}
		}
	);

	useEffect(()=>{

		if(tokenID && tokenID?.length > 0){

			const params = {
				tokenID: tokenID
			};

			verifyEmailMu(params);

		}
	},[]);

	return null;
};

export default VerifyEmail;