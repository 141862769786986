import * as offerAtoms from 'store/CustomizeApp/Features/offersAtom';
import * as typeSchemas from 'services/requests/Features/Offers/schema';

import { IMAGES, classNames } from 'utility';
import React, { useState } from 'react';

import Accordion from 'components/Common/Accordion';
import AccordionContent from './AccordionItem';
import { Button } from 'components/Common/Buttons';
import { DeleteModal } from 'components/Common/Modals/DeleteModal';
import { OfferUseQuery } from '../UseQuery/OfferQueries';
import { TrashIcon } from '@heroicons/react/24/outline';
import { useAtom } from 'jotai';
import Tooltip from 'components/Common/Tooltip';

const Offers: React.FC = () => {

	OfferUseQuery();

	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [temp, setTemp] = useAtom(offerAtoms.FTempOffers);

	const [, setTempIDs] = useAtom(offerAtoms.FTempIds);
	const [a, setA] = useState<number[]>([]);


	const handleAddOfferData = () => {
		setTemp(prev => [...prev, {
			offerID: 0,
			title: 'Offer Name',
			description: '',
			termsAndCondition: '',
			discount: '',
			discountType: 'Amount',
			buy: 1,
			get: 1,
			code: '',
			couponType: 1,
			minimumBasketPrice: '',
		}]);
	};

	const handleEditOfferData = (data: typeSchemas.EditOfferParams, index: number) => {
		// editOfferData(data);
		const tempEdit = [...temp];

		tempEdit[index] = {
			offerID: tempEdit[index].offerID ?? 0,
			buy: data.buy ?? 1,
			code: data.code,
			couponType: data.couponType,
			description: data.description,
			discount: data.discount,
			discountType: data.discountType,
			get: data.get ?? 1,
			// minimumBasketPrice: data.minimumBasketPrice,
			termsAndCondition: data.termsAndCondition,
			title: data.title,
		};
		setTemp(tempEdit);
	};
	const showDeleteModal = (index: number) => {
		setIsDeleteModalOpen(true);
		setA([...a, index]);
	};

	const handleDeleteItem = () => {
		setIsDeleteModalOpen(false);
		const tempDelete = [...temp];
		if (tempDelete[a[0]].offerID !== 0) {
			setTempIDs(prev => [...prev, {
				offerID: tempDelete[a[0]].offerID
			}]);
		}
		tempDelete.splice(a[0], 1);
		setTemp(tempDelete);
		setA([]);
	};

	return (
		<>
			<div className='my-8 w-full h-auto bg-white rounded-lg overflow-hidden'>
				<div className='max-sm:pb-[270px]'>
					{/* <div className='flex justify-end relative'>
						<div className='flex absolute top-1 right-7 gap-3 pr-10'>
						
							<Tooltip className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
								<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.CameraIcon} alt='camera icon' />
							</Tooltip>
							<Tooltip className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
								<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.InfoIcon} alt='info icon' />
							</Tooltip>
				
						</div>
					</div>
					<div className='mt-6 mb-4 mx-4 flex justify-between items-center'>
						<div className='text-[#707070] text-2xl font-bold'>
							Offers
						</div>
						<div className='max-sm:items-center max-sm:mt-[60px]'>
							<Button
								variant='primary'
								// disabled={isFetching}
								onClick={handleAddOfferData}
								className='mr-14 w-[139px] h-[39px] text-white font-semibold rounded-md'
							>
								Create Offer
							</Button>
							
						</div>
					</div> */}
					<div className='flex flex-col justify-between mx-5 pt-7'>
						<div className='flex gap-3 items-center w-full float-right justify-between'>
							<span className='text-[#707070] font-bold text-2xl'>Offers</span>
							{/* -----------Video Tutorial and Help Icons------------ */}
							<div className='flex flex-row gap-2'>
								<Tooltip className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
									<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.CameraIcon} alt='camera icon' />
								</Tooltip>
								<Tooltip className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
									<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.InfoIcon} alt='info icon' />
								</Tooltip>
							</div>
							{/* ---------------------------------------------------- */}
						</div>
						<div className='flex justify-end items-center mt-3'>
							<Button className=' font-semibold text-[16px] py-[7px] px-[16px] max-sm:py-[4px] max-sm:px-[13px] max-sm:text-[12px]'
								variant='primary'
								onClick={handleAddOfferData}
							> Create Offer
							</Button>
						</div>

					</div>
					<div className='mx-5 h-full bg-white max-sm:mx-2'>
						{/* {isDeleting && <div className='w-full h-80 overflow-hidden flex justify-center items-center'> <DottedLoading /> </div>}
						{!isDeleting && */}
						<div className='mt-4 w-full'>
							{temp?.map((data: typeSchemas.GetTempOffersType, index: number) => (
								<div key={index} className='flex relative'>
									<div className='rounded-lg w-full'>
										<Accordion
											title={<span className="ml-2 font-bold text-gray-500">
												{data.title}
											</span>}
											activeMaxHeight="1000px"
											content={<AccordionContent
												items={data}
												key={data.offerID}
												index={index}
												value={data.title} // ?
												handleChange={handleEditOfferData} />}
											onClick={() => null} />
									</div>
									<div className={classNames(
										temp.length === 1
											? 'hidden'
											: ''
									)}>
										<button
											className='mt-5 mx-4'
											onClick={() => showDeleteModal(index)}
										>
											<TrashIcon className='w-6 h-6 text-[#B35916]' />
										</button>
									</div>
								</div>
							))}
						</div>
						{/* } */}
						<DeleteModal
							isDeleteModalShow={isDeleteModalOpen}
							setDeleteModalShow={() => setIsDeleteModalOpen(false)}
							onClickCancel={() => { setIsDeleteModalOpen(false); setA([]); }}
							onClickDelete={() => handleDeleteItem()}
							type='offer' />
					</div>
				</div>
			</div>

		</>
	);
};

export default Offers;