import React from 'react';


const DashboardPanel: React.FC = () => {
	return (
		<div className='bg-[#FAFAFA] w-full p-10'>
			<div className='text-[#2C3E6B] text-[20px] font-bold'>Dashboard</div>
		</div>
	);
};

export default DashboardPanel;
