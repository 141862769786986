import { SDefaultFontSize, SHeadlineTextFont } from 'store/CustomizeApp/Appearance/fontAtom';
import { STopBarColor, STopBarTextColor } from 'store/CustomizeApp/Appearance/colorAtom';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { Bars3Icon } from '@heroicons/react/24/solid';
import { FContents } from 'store/CustomizeApp/Features/contentAtom';
import { Layouts } from 'enums/appearance/layouts';
import React, { } from 'react';
import { SAppearanceTab } from 'store/CustomizeApp/Appearance';
import { SFeaturesTab } from 'store/CustomizeApp/Features';
import { SIsSidebarOpen } from 'store/CustomizeApp/Appearance/sideBarAtom';
import { SLayoutType } from 'store/CustomizeApp/Appearance/layoutAtom';
import { classNames } from 'utility';
import { stepperAtom } from 'store/CustomizeApp/Stepper';
import { useMediaQuery } from 'react-responsive';

/* eslint-disable no-mixed-spaces-and-tabs */


const Head: React.FC = () => {

	const layoutType = useAtomValue(SLayoutType);
	const [featTitle, setFeatTab] = useAtom(SFeaturesTab);
	const topBarColor = useAtomValue(STopBarColor);
	const topBarTextColor = useAtomValue(STopBarTextColor);

	const headlineFont = useAtomValue(SHeadlineTextFont);
	const headlineFontSize = useAtomValue(SDefaultFontSize);
	const [stepper, setStepper] = useAtom(stepperAtom);
	const setAppTab = useSetAtom(SAppearanceTab);
	const setIsSidebarOpen = useSetAtom(SIsSidebarOpen);
	const contents = useAtomValue(FContents);
	const mdViewPort = useMediaQuery({ maxWidth: 1020 });
	const lowerSmViewPort = useMediaQuery({ maxWidth: 800 });

	const contentIndex = featTitle.match(/\d+/);
	const content = contents[contentIndex ? +contentIndex : 0];
	const lowRes = useMediaQuery({ maxWidth: 800 });

	const onClickMenu = () => {
		if (stepper === 3) {
			setIsSidebarOpen(true);
			setAppTab('sideBar');
		} else {
			setIsSidebarOpen(true);
			setStepper(0);
			setAppTab('sideBar');
		}
	};

	const onClickBack = () => {
		setIsSidebarOpen(true);
		setAppTab('sideBar');
		setFeatTab('Home');
		
		if (stepper !== 3) {
			setStepper(0);
		}
	};

	const fontSizeMultiplier = lowRes ? 0.8 : (headlineFont === 'Ultra' || headlineFont === 'Permanent Marker' || headlineFont === 'Rock Salt' ? 1.35 : 1.75);
	
	return (
		<>
		  <div className='absolute w-full h-[40px] flex items-center z-40' style={{ backgroundColor: `${topBarColor}` }}>
				{
					featTitle === 'Home' || featTitle === 'Store' || featTitle === 'About' || featTitle === 'Content[0]'
						? <Bars3Icon onClick={() => onClickMenu()} className='absolute z-10 w-7 h-7 text-gray-500 ml-2 cursor-pointer' style={{ color: `${topBarTextColor}` }} />
						: <ArrowLeftIcon onClick={() => onClickBack()} style={{ color: `${topBarTextColor}` }} className='absolute z-10 ml-2 w-6 h-6 cursor-pointer' />
				}
				
				<div
					onClick={() => { setIsSidebarOpen(false); }}
					className={classNames('absolute z-0 right-0.5 text-center w-full',
						headlineFontSize > 11 && mdViewPort && (featTitle === 'Terms of Use' || featTitle === 'Accessibility' || featTitle === 'Contact Us' || featTitle === 'Notifications' || featTitle === 'Social Media') ?
							'ml-5 whitespace-nowrap text-ellipsis overflow-hidden' : '',
						headlineFontSize > 11 && lowerSmViewPort && (featTitle === 'Terms of Use' || featTitle === 'Accessibility' || featTitle === 'Notifications' || featTitle === 'Social Media') ?
							'whitespace-nowrap text-ellipsis overflow-hidden' :
							'whitespace-nowrap text-ellipsis overflow-hidden',
						headlineFontSize > 11 && mdViewPort && (featTitle === 'Privacy and Policy') ? 'ml-[2rem]' : 'ml-[1rem]')}
					style={{ color: `${topBarTextColor}`, fontFamily: `${headlineFont}`, fontSize: headlineFontSize * fontSizeMultiplier }}
				>
					{
						stepper === 0 ? 'Home'
							: (featTitle.startsWith('Content') && content) ? (content.title.length > 11 ? (content.title.substring(0, 11) + '...') : (content.title))
								: featTitle
					}
				</div>
			</div>
		</>
	);


};

export default Head;