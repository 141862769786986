import React, { useEffect } from 'react';
import { SDefaultFontSize, SFontData, SFontSizes, SFonts, SHeadlineTextFont, SHeadlineTextFontID, SNavigationTextFont, SNavigationTextFontID } from 'store/CustomizeApp/Appearance/fontAtom';
import { useAtom, useAtomValue } from 'jotai';

import FontDropdown from 'components/Common/Dropdown/FontDropdown';
import { IMAGES } from 'utility/constant';
import { SIsNavigationTextCheck } from 'store/CustomizeApp/Appearance/navigationAtom';
import Tooltip from 'components/Common/Tooltip';

const FontPage = () => {

	const data 							  = useAtomValue(SFontData);
	const [fonts, setFonts]               = useAtom(SFonts);
	const [, setHeadlineFontID] 		  = useAtom(SHeadlineTextFontID);
	const [headlineFont, setHeadlineFont] = useAtom(SHeadlineTextFont);
	const [, setnavTextFontID]            = useAtom(SNavigationTextFontID);
	const [navTextFont, setNavTextFont]   = useAtom(SNavigationTextFont);
	const fontSizes 					  = useAtomValue(SFontSizes);
	const [fontSize, setFontSize]		  = useAtom(SDefaultFontSize);
	const isNavigationTextCheck			  = useAtomValue(SIsNavigationTextCheck);

	const onChangeHeadline = (font: string) => {
		setHeadlineFont(font);
		data?.map((item) => {
			if(item.name === font) setHeadlineFontID(item.fontID);
		});
	};

	const onChangeBody = (font: string) => {
		setNavTextFont(font);
		data?.map((item) => {
			if(item.name === font) setnavTextFontID(item.fontID);
		});
	};

	const onChangeFontSize = (fontSize: string) => {
		setFontSize(parseInt(fontSize));
	};

	useEffect(() => {
		if(data) setFonts(data.map((font) => {return {label: font.name, value: font.name};}));
	},[data]);

	return (
		<div className='bg-white rounded-lg my-8 mr-6 w-full '>
			<div className='w-full px-10 rounded 2xl:px-10 md:px-10 sm:px-10 max-sm:px-[4vw]'>
				<div className='py-5 flex-row'>
					<div className='flex justify-between items-center pt-2'>
						<span className='text-[#707070] font-bold text-2xl block'>Fonts</span>
						<div className='flex gap-3 items-baseline'>
							{/* -----------Video Tutorial and Help Icons------------ */}
							<Tooltip className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
								<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.CameraIcon} alt='camera icon' />
							</Tooltip>
							<Tooltip className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
								<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.InfoIcon} alt='info icon' />
							</Tooltip>
							{/* ---------------------------------------------------- */}
						</div>
					</div>
					<div className='flex-row py-8'>
						<div className='flex w-full'>
							<div className='w-1/3 2xl:w-[300px] xl:w-[290px] lg:w-[290px] md:w-[290px] sm:w-[290px] max-sm:w-[138px]'>
								<div className='text-[#5e5a6b] font-semibold mb-4'>
								Headline Text
								</div>
								<div className='w-full mb-4 mr-10 2xl:w-[270px] lg:w-[270px] md:w-[270px] sm:w-[270px] max-sm:w-[125.93px]'>
									<FontDropdown placeholder='Select Headline Font' onChange={(value) => onChangeHeadline(value)} options={fonts} value={headlineFont}/>
								</div>	
							</div>
							<div className='w-1/3 2xl:w-[300px] xl:w-[290px] lg:w-[290px] md:w-[290px] sm:w-[130px] max-sm:w-[130px]'>
								{/*  ml-4 2xl:-ml-[160px] xl:ml-[160px] lg:-ml-[160px] md:ml-[80px] sm:ml-[80px] max-sm:ml-[80px]  */}
								<div className='text-[#5e5a6b] font-semibold mb-4'>
								Size
								</div>
								<div className='w-1/2 mb-4 2xl:w-[130.77px] lgw-[130.77px] md:w-[130.77px] sm:w-[130.77px] max-sm:w-[130.77px]'>
									<FontDropdown placeholder='Select Font Size' onChange={(value) => onChangeFontSize(value)} options={fontSizes} value={fontSize.toString()}/>
								</div>	
							</div>
						</div>

						<div className='text-[#5e5a6b] font-semibold mb-4'>
							Navigation Text
						</div>
						<div className='w-1/3 mb-4 2xl:w-[270px] xl:w-[270px] lg:w-[270px] md:w-[270px] sm:w-[270px] max-sm:w-[125.93px]'>
							<FontDropdown disabled={!isNavigationTextCheck} placeholder='Select Navigation Font' onChange={(value) => onChangeBody(value)} options={fonts} value={navTextFont}/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FontPage;