import { axiosInstance } from 'services/axios';
import { CreateEditStoreSchema, CreateEditStoreType, StoreResponse } from './schema';

export const createStoreApi = async (id?: string, data?: CreateEditStoreType) => {
	const storeData = CreateEditStoreSchema.parse(data);
	const response = await axiosInstance.post(`/organizations/apps/${id}/stores`, storeData);
	return response.data as StoreResponse;
};

export const getStoreApiLive = async (id?: string) => {
	const response = await axiosInstance.get(`/organizations/apps/${id}/stores?env=LIV`);
	return response.data as StoreResponse;
};

export const getStoreApi = async (id?: string) => {
	const response = await axiosInstance.get(`/organizations/apps/${id}/stores`);
	return response.data as StoreResponse;
};

export const editStoreApi = async (id?: string, storeID?: number, param?: CreateEditStoreType ) => {
	const response = await axiosInstance.put(`/organizations/apps/${id}/stores/${storeID}`, param);
	return response.data as StoreResponse;
};