import { FIsTermsRefresh, FTerms, FTermsID } from 'store/CustomizeApp/Features/terms';
import { GetTermsOfUseFormResponse, GetTermsOfUseFormType } from 'services/requests/Features/TermsOfUse/schema';
import { createTermsOfUse, editTermsOfUseByAppID } from 'services/requests/Features/TermsOfUse';
import { useAtomValue, useSetAtom } from 'jotai';

import AccessibilityMutation from '../Accessibility';
import { AppFeatIsSaving } from 'store/CustomizeApp/Appearance';
import { AxiosError } from 'axios';
import React from 'react';
import { showFailedAlert } from 'utility/alerts';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { FIsTermsChanges } from 'store/CustomizeApp/Features';

const TermsOfUseMutation = () => {
	const { id } = useParams();
	//Atom for Terms
	const termsDesc = useAtomValue(FTerms);
	const termsID = useAtomValue(FTermsID);
	const setIsTermsRefresh = useSetAtom(FIsTermsRefresh);
	const setIsSaving = useSetAtom(AppFeatIsSaving);
	const isThereChanges = useAtomValue(FIsTermsChanges);
	const {saveAccessibility} = AccessibilityMutation();
	//Create Terms Of Use
	const { mutate: createTerms } = useMutation<
		GetTermsOfUseFormResponse,
		AxiosError,
		GetTermsOfUseFormType
	>((data) => createTermsOfUse(id, data), {
		onSuccess: () => {
			setIsTermsRefresh(true);
			saveAccessibility();
		},
		onError: (error) => {
			setIsSaving(false);
			showFailedAlert('Cannot save terms of use.');
		},
	});

	//Edit Terms Of Use
	const { mutate: editTermsOfUse } = useMutation<
		GetTermsOfUseFormResponse,
		AxiosError,
		GetTermsOfUseFormType
	>((data) => editTermsOfUseByAppID(id, termsID, data), {
		onSuccess: () => {
			setIsTermsRefresh(true);
			saveAccessibility();
		},
		onError: () => {
			setIsSaving(false);
			showFailedAlert('Cannot edit terms of use.');
		},
	});

	const updateTerms = () => {
		if (id)
			editTermsOfUse({
				description: termsDesc,
				isTurnedOn: true,
			});
	};

	const saveTerms = () => {
		if(isThereChanges){
			if (termsID) {
				updateTerms();
			}else {
				createTerms({ description: termsDesc, isTurnedOn: true });
			}
		}else saveAccessibility();
		
	};
    
	return {saveTerms,};
};

export default TermsOfUseMutation;