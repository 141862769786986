import React, { useEffect } from 'react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
	About,
	AccessibilityIcon,
	Contact,
	Gallery,
	Help,
	Home,
	Notification,
	Offers,
	PrivacyIcon,
	Social,
	Store,
	TermsIcon,
} from 'assets/CustomizeApp/Features/TabIcons/FeaturesIcons';
import {
	FContentError,
	FShowContentErrorModal,
	FContents,
	FShowAddPageModal,
	FContentErrorDescription,
} from 'store/CustomizeApp/Features/contentAtom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { FIsAboutChanges, FIsAccessChanges, FIsContactChanges, FIsContentChanges, FIsFeaturesChange, FIsGalleryChanges, FIsHelpChanges, FIsHomeChanges, FIsNotifChanges, FIsOffersChanges, FIsPrivacyChanges, FIsSocialMediaChanges, FIsStoreChanges, FIsTermsChanges, SFeaturesTab } from 'store/CustomizeApp/Features';
import { classNames } from 'utility';
import { Tooltip } from 'components/Tooltip';
import AddPageModal from 'components/Common/Modals/AddPageModal';
import { FEditorError, FShowEditorErrorModal } from 'store/createAppAtom';
import { PencilIcon, PlusIcon } from '@heroicons/react/24/solid';
import { useQueryClient } from 'react-query';
import { GetHomeResponse } from 'services/requests/Features/Home/schema';
import { StoreResponse } from 'services/requests/Features/Store/schema';
import { getAboutResponse } from 'services/requests/Features/about/schema';
import { GetAccessibilityFormResponse } from 'services/requests/Features/Accessibility/schema';
import { GetTermsOfUseFormResponse } from 'services/requests/Features/TermsOfUse/schema';
import { GetPrivacyPolicyrFormResponse } from 'services/requests/Features/PrivacyPolicy/schema';
import { GetContactFormResponse } from 'services/requests/Features/Contact/schema';
import { GetAllNotificationsResponse } from 'services/requests/Features/Notification/schema';
import { GetOffersWithPaginationResponse } from 'services/requests/Features/Offers/schema';
import { getAllSocialMediaResponse } from 'services/requests/Features/SocialMedia/schema';
import { GetGalleryResponseType } from 'services/requests/Features/Gallery/schema';
import { GetHelpResponse } from 'services/requests/Features/help/schema';
import { useParams } from 'react-router-dom';
import { GetContentFormResponse } from 'services/requests/Content/schema';

const FeaturesTab: React.FC = () => {
	const [selectedOption, setSelectedOption] = useAtom(SFeaturesTab);
	const [showAddPageModal, setShowAddPageModal] = useAtom(FShowAddPageModal);

	const contents = useAtomValue(FContents);
	const setShowContentErrorModal = useSetAtom(FShowContentErrorModal);
	const setContentErrorDescription = useSetAtom(FContentErrorDescription);
	const [contentError, setContentError] = useAtom(FContentError);
	const editorError = useAtomValue(FEditorError);
	const setShowEditorErrorModal = useSetAtom(FShowEditorErrorModal);
	
	const setFeatureChanges = useSetAtom(FIsFeaturesChange);

	const { id } = useParams();
	
	const queryClient = useQueryClient();
	const cachedHome = queryClient.getQueryData<GetHomeResponse>(['home', id]);
	const cachedStore = queryClient.getQueryData<StoreResponse>(['store', id]);
	const cachedAbout = queryClient.getQueryData<getAboutResponse>(['about', id]);
	const cachedContents = queryClient.getQueryData<GetContentFormResponse>(['content', id]);
	const cachedAccessibility = queryClient.getQueryData<GetAccessibilityFormResponse>(['accessibility', id]);
	const cachedTermsOfUse = queryClient.getQueryData<GetTermsOfUseFormResponse>(['termsOfUse', id]);
	const cachedPrivacyPolicy = queryClient.getQueryData<GetPrivacyPolicyrFormResponse>(['privacyPolicy', id]);
	const cachedContactUs = queryClient.getQueryData<GetContactFormResponse>(['Contact Us', id]);
	const cachedNotifs = queryClient.getQueryData<GetAllNotificationsResponse>(['notifications', id]);
	const cachedOffers = queryClient.getQueryData<GetOffersWithPaginationResponse>(['getOffers', id]);
	const cachedSocMed = queryClient.getQueryData<getAllSocialMediaResponse>(['SocialMedias', id]);
	const cachedGallery = queryClient.getQueryData<GetGalleryResponseType>(['gallery', id]);
	const cachedHelp = queryClient.getQueryData<GetHelpResponse>(['help', id]);
	
	const homeChanges = useAtomValue(FIsHomeChanges);
	const storeChanges = useAtomValue(FIsStoreChanges);
	const aboutChanges = useAtomValue(FIsAboutChanges);
	const contentChanges = useAtomValue(FIsContentChanges);
	const privacyChanges = useAtomValue(FIsPrivacyChanges);
	const termsChanges = useAtomValue(FIsTermsChanges);
	const accessChanges = useAtomValue(FIsAccessChanges);
	const contactChanges = useAtomValue(FIsContactChanges);
	const galleryChanges = useAtomValue(FIsGalleryChanges);
	const helpChanges = useAtomValue(FIsHelpChanges);
	const offerChanges = useAtomValue(FIsOffersChanges);
	const notifChanges = useAtomValue(FIsNotifChanges);
	const socMedChanges = useAtomValue(FIsSocialMediaChanges);

	const homeIsTurnedOn  			= (cachedHome?.home?.imageURL != undefined && cachedHome?.home?.imageURL != '') || (cachedHome?.home?.description != undefined && cachedHome?.home?.description != '' && cachedHome?.home?.description != '<p><br></p>');
	const storeIsTurnedOn 			= cachedStore?.store?.storeName != undefined && cachedStore?.store?.storeName != '' && cachedStore?.store?.storeName != '<p><br></p>';
	const aboutIsTurnedOn 			= cachedAbout?.about?.description != undefined && cachedAbout?.about?.description != '' && cachedAbout?.about?.description != '<p><br></p>';
	const accessibilityIsTurnedOn 	= cachedAccessibility?.accessibility?.description != undefined && cachedAccessibility?.accessibility?.description != '' && cachedAccessibility?.accessibility?.description != '<p><br></p>';
	const termsOfUseIsTurnedOn 		= cachedTermsOfUse?.termsOfUse?.description != undefined && cachedTermsOfUse?.termsOfUse?.description != '' && cachedTermsOfUse?.termsOfUse?.description != '<p><br></p>';
	const privacyPolicyIsTurnedOn	= cachedPrivacyPolicy?.privacyPolicy?.description != undefined && cachedPrivacyPolicy?.privacyPolicy?.description != '' && cachedPrivacyPolicy?.privacyPolicy?.description != '<p><br></p>';
	const contactUsIsTurnedOn		= cachedContactUs?.contact?.description != undefined && cachedContactUs?.contact?.description != '' && cachedContactUs?.contact?.description != '<p><br></p>';
	const helpIsTurnedOn			= cachedHelp?.help?.description != undefined && cachedHelp?.help?.description != '' && cachedHelp?.help?.description != '<p><br></p>';
	const galleryIsturnedOn 		= cachedGallery?.rows != undefined && cachedGallery?.rows?.length > 0;
	const offersIsturnedOn 			= cachedOffers?.rows != undefined && cachedOffers?.rows?.length > 0;
	const notifsIsturnedOn 			= cachedNotifs?.rows != undefined && cachedNotifs?.rows?.length > 0;
	const socMedIsturnedOn 			= cachedSocMed?.rows != undefined && cachedSocMed?.rows?.length > 0;
			
	const tabs = [
		{
			label: 'Home',
			icon: (
				<Home
					fill={
						homeIsTurnedOn
							? '#007CA8'
							: '#646464'
					}
				/>
			),
			isSelected: selectedOption === 'Home',
			isTurnedOn: homeIsTurnedOn,
			hasChanges: homeChanges,
			onClick: () => setSelectedOption('Home'),
		},
		{
			label: 'Store',
			icon: (
				<Store
					fill={
						storeIsTurnedOn
							? '#007CA8'
							: '#646464'
					}
				/>
			),
			isSelected: selectedOption === 'Store',
			isTurnedOn: storeIsTurnedOn,
			hasChanges: storeChanges,
			onClick: () => setSelectedOption('Store'),
		},
		{
			label: 'About',
			icon: (
				<About
					fill={
						aboutIsTurnedOn
							? '#007CA8'
							: '#646464'
					}
				/>
			),
			isSelected: selectedOption === 'About',
			isTurnedOn: aboutIsTurnedOn,
			hasChanges: aboutChanges,
			onClick: () => setSelectedOption('About'),
		},
		{
			label: 'Privacy and Policy',
			icon: (
				<PrivacyIcon
					fill={
						privacyPolicyIsTurnedOn
							? '#007CA8'
							: '#646464'
					}
				/>
			),
			isSelected: selectedOption === 'Privacy and Policy',
			isTurnedOn: privacyPolicyIsTurnedOn,
			hasChanges: privacyChanges,
			onClick: () => setSelectedOption('Privacy and Policy'),
		},
		{
			label: 'Terms of Use',
			icon: (
				<TermsIcon
					fill={
						termsOfUseIsTurnedOn
							? '#007CA8'
							: '#646464'
					}
				/>
			),
			isSelected: selectedOption === 'Terms of Use',
			isTurnedOn: termsOfUseIsTurnedOn,
			hasChanges: termsChanges,
			onClick: () => setSelectedOption('Terms of Use'),
		},
		{
			label: 'Accessibility',
			icon: (
				<AccessibilityIcon
					fill={
						accessibilityIsTurnedOn
							? '#007CA8'
							: '#646464'
					}
				/>
			),
			isSelected: selectedOption === 'Accessibility',
			isTurnedOn: accessibilityIsTurnedOn,
			hasChanges: accessChanges,
			onClick: () => setSelectedOption('Accessibility'),
		},
		{
			label: 'Contact Us',
			icon: (
				<Contact
					fill={
						contactUsIsTurnedOn
							? '#007CA8'
							: '#646464'
					}
				/>
			),
			isSelected: selectedOption === 'Contact Us',
			isTurnedOn: contactUsIsTurnedOn,
			hasChanges: contactChanges,
			onClick: () => setSelectedOption('Contact Us'),
		},
		{
			label: 'Help',
			icon: (
				<Help
					fill={
						helpIsTurnedOn
							? '#007CA8'
							: '#646464'
					}
				/>
			),
			isSelected: selectedOption === 'Help',
			isTurnedOn: helpIsTurnedOn,
			hasChanges: helpChanges,
			onClick: () => setSelectedOption('Help'),
		},
		{
			label: 'Gallery',
			icon: (
				<Gallery
					fill={
						galleryIsturnedOn
							? '#007CA8'
							: '#646464'
					}
				/>
			),
			isSelected: selectedOption === 'Gallery',
			isTurnedOn: galleryIsturnedOn,
			hasChanges: galleryChanges,
			onClick: () => setSelectedOption('Gallery'),
		},
		{
			label: 'Offers',
			icon: (
				<Offers
					fill={
						offersIsturnedOn
							? '#007CA8'
							: '#646464'
					}
				/>
			),
			isSelected: selectedOption === 'Offers',
			isTurnedOn: offersIsturnedOn,
			hasChanges: offerChanges,
			onClick: () => setSelectedOption('Offers'),
		},
		{
			label: 'Notifications',
			icon: (
				<Notification
					fill={
						notifsIsturnedOn
							? '#007CA8'
							: '#646464'
					}
				/>
			),
			isSelected: selectedOption === 'Notifications',
			isTurnedOn: notifsIsturnedOn,
			hasChanges: notifChanges,
			onClick: () => setSelectedOption('Notifications'),
		},
		{
			label: 'Social Media',
			icon: (
				<Social
					fill={
						socMedIsturnedOn
							? '#007CA8'
							: '#646464'
					}
				/>
			),
			isSelected: selectedOption === 'Social Media',
			isTurnedOn: socMedIsturnedOn,
			hasChanges: socMedChanges,
			onClick: () => setSelectedOption('Social Media'),
		},
	];

	if (contents.length > 0) {
		const filteredContents = contents.filter(
			(content) => content.toDelete === false
		);

		const contentTabs = filteredContents
			.map((content, index) => {
				const cached = cachedContents?.rows?.filter((row) => row.contentID == content.id);
				
				const contentIsturnedOn = cached != undefined && cached[0]?.contentID > 0 && cached[0]?.body != undefined && cached[0]?.body != '' && cached[0]?.body != '<p><br></p>';
				
				return {
					label: content.title,
					icon: (
						<div className='h-[1.7rem]'>
							<i
								className={classNames(
									content.icon,
									'fa-xl',
									contentIsturnedOn
										? 'text-[#007CA8]'
										: 'text-[#646464]'
								)}
							></i>
						</div>
					),
					isSelected: selectedOption === `Content[${index}]`,
					isTurnedOn: contentIsturnedOn,
					hasChanges: !(content.id > 0) || (cached != undefined && (cached[0]?.icon != content.icon || cached[0]?.body != content.description)),
					onClick: () => setSelectedOption(`Content[${index}]`),
				};
			})
			.filter((item) => item !== null) as unknown as typeof tabs;

		tabs.splice(3, 0, ...contentTabs);
	}

	const handleOnClickAddPage = () => {
		const filteredContents = contents.filter(
			(content) => content.toDelete === false
		);

		if (filteredContents.length === 25) {
			setContentError('Content Limit Reached');
			setContentErrorDescription(
				'You have reached the maximum number of contents allowed (25). Please consider editing or deleting some of your existing content to make room for a new one.'
			);
			setShowContentErrorModal(true);
		} else if (contentError) {
			setShowContentErrorModal(true);
		} else if (editorError) {
			setShowEditorErrorModal(true);
		} else setShowAddPageModal(true);
	};

	const handleShowAddPageModal = () => {
		setShowAddPageModal(!showAddPageModal);
	};
	
	useEffect(() => {
		const featureChanges = homeChanges || storeChanges || aboutChanges || contentChanges || privacyChanges || termsChanges || accessChanges || contactChanges || galleryChanges || helpChanges || offerChanges || notifChanges || socMedChanges;
	
		setFeatureChanges(featureChanges);
	}, [homeChanges, storeChanges, aboutChanges, contentChanges, privacyChanges, termsChanges, accessChanges, contactChanges, galleryChanges, helpChanges, offerChanges, notifChanges, socMedChanges]);

	return (
		<>
			<div className='flex flex-row w-full h-24 py-3 bg-white rounded-sm mt-1'>
				<div className='md:snap-mandatory md:snap-y flex md:flex-wrap ml-1 w-full justify-stretch items-center overflow-x-auto md:gap-y-1 md:overflow-y-auto'>
					<div
						onClick={() => handleOnClickAddPage()}
						className='flex flex-col shrink-0 justify-center items-center font-thin text-primary cursor-pointer mr-1 py-1 w-20 h-full border-r-2 border-[#007CA8] '
					>
						<FontAwesomeIcon
							icon={faPlusSquare}
							className='w-6 h-6'
						/>

						<p className='text-xs font-normal mt-1 line-clamp-1'>
							Add Page
						</p>
					</div>

					{tabs.map((tab, index) => (
						<Tooltip
							key={index}
							title={tab.label.length > 7 ? tab.label : ''}
						>
							<div
								key={index}
								className='relative group justify-stretch items-center'
							>
								<div
									onClick={() => {
										contentError.length > 1
											? setShowContentErrorModal(true)
											: editorError
												? setShowEditorErrorModal(true)
												: tab.onClick();
									}}
									className={classNames(
										tab.isSelected
											? 'bg-[#FDDDCB]'
											: 'bg-white',
										'snap-center flex flex-col justify-center items-center w-20 p-3 font-thin text-[#464255] cursor-pointer'
									)}
								>
									<div className='relative flex justify-center items-center w-7 h-7'>
										{tab.icon}
 
										<div className='absolute flex justify-center items-center h-fit -right-2 top-1'>
											{
												tab.hasChanges && <span className="animate-pulse absolute h-[20px] w-[20px] rounded-full bg-orange-400 opacity-75"></span>
											}
											
											<div className='absolute flex justify-center items-center w-4 h-4 rounded-full border bg-primary'>
												{
													tab.hasChanges ? <div className='p-2 font-normal text-xs text-white text-center'>!</div>
														: tab.isTurnedOn ? (
															<PencilIcon className='w-[0.65rem] h-[0.65rem] text-white cursor-pointer' />
														) : (
															<PlusIcon className='w-4 h-4 text-white cursor-pointer' />
														)
												}
											</div>
										</div>
									</div>

									<p
										className={classNames(
											tab.isTurnedOn
												? 'text-[#007CA8]'
												: 'text-black',
											tab.isSelected
												? 'font-bold'
												: 'font-normal',
											'text-xs mt-1 line-clamp-1'
										)}
									>
										{tab.label.length > 7
											? tab.label.substring(0, 7) + '...'
											: tab.label}
									</p>
								</div>
							</div>
						</Tooltip>
					))}
				</div>
			</div>

			<AddPageModal
				isVisible={showAddPageModal}
				setIsVisible={setShowAddPageModal}
				onCancel={handleShowAddPageModal}
				onCreate={handleShowAddPageModal}
			/>
		</>
	);
};

export default FeaturesTab;
