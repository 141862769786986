import { AxiosError } from 'axios';
import { useAtom, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { SEditProfileUserId, SEditProfileLoading, SEditProfileImage, SEditProfileImagePreview, SEditProfileFirstName, SEditProfileLastName} from 'store/EditProfile/editProfileAtom';
import { getUserById, getUserByToken } from '../user';
import { UserTokenResponse } from '../user/schema';
import { getUserCredentials } from '../auth';

export const UpdateProfile = () => {

	const [editUser, setEditUser]                     = useAtom(SEditProfileUserId);
	const [, setUpdateLoading]                        = useAtom(SEditProfileLoading);
	const [, setEditFirstName]          	 		  = useAtom(SEditProfileFirstName);
	const [, setEditLastName]            	 		  = useAtom(SEditProfileLastName);
	const [, setEditImage] 							  = useAtom(SEditProfileImage);
	const [, setEditImagePreview] 		 			  = useAtom(SEditProfileImagePreview);


	const token                                       = localStorage.getItem('bearerToken');
	

	const { data: editProfileByToken } = useQuery<UserTokenResponse, AxiosError>({
		queryKey: ['editProfileByToken'],
		queryFn: () => getUserByToken({ token: token }),
		retry: 0,
	});

	const { data: editProfileData, isLoading: updateLoading } = useQuery({
		queryKey: ['user', editUser],
		queryFn: () => getUserById(editUser),
		retry: 0,
		enabled: editUser != undefined
	});


	setUpdateLoading(updateLoading);
  
	useEffect(() => {
		if (editProfileByToken) {
			setEditUser(editProfileByToken.userID);
		}
		if (editProfileData) {
			localStorage.setItem('userData', JSON.stringify(editProfileData));
			setEditFirstName(editProfileData.user.firstName);
			setEditLastName(editProfileData.user.lastName);
			// setEditImage(editProfileData.user.imageURL);
			// setEditImagePreview(editProfileData.user.imageURL);
		}


	},[]);
};