import { SDefaultFontSize, SFontData, SHeadlineTextFont, SHeadlineTextFontID, SIsFontRefresh, SNavigationTextFont, SNavigationTextFontID } from 'store/CustomizeApp/Appearance/fontAtom';
import { SFontID, SFontIDLive, SIsAppearanceChanges, SIsFontChanges, SisFontDone } from 'store/CustomizeApp/Appearance';
import { getFontSettingByAppID, getFontSettingByAppIDLive } from 'services/requests/appearance/font/font_setting';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { AxiosError } from 'axios';
import { GetFontSettingResponse } from 'services/requests/appearance/font/font_setting/schema';
import { PIsPublishShowFont } from 'store/CustomizeApp/Publish/publishAtom';
import { UseFeaturesQueries } from 'pages/CustomizeApp/Features/UseQuery';
import { getAllFont } from 'services/requests/Font';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { log } from 'utility/log';

export const FontUseQuery = () => {

	//Atoms for Font
	const selectedFontID        = useAtomValue(SFontID);
	const [, setFontData]       = useAtom(SFontData);
	const [headlineFontID, setHeadlineFontID] = useAtom(SHeadlineTextFontID);
	const [, setHeadlineFont]   = useAtom(SHeadlineTextFont);
	const [navTextFontID, setNavTextFontID]  = useAtom(SNavigationTextFontID);
	const [, setNavTextFont]     = useAtom(SNavigationTextFont);
	const [navTextSize, setFontSize]		= useAtom(SDefaultFontSize);
	const [isFontRefresh, setIsFontRefresh] = useAtom(SIsFontRefresh);
	const setAppearanceChanges              = useSetAtom(SIsAppearanceChanges);
	const setFontChanges = useSetAtom(SIsFontChanges);
	const setFontDone = useSetAtom(SisFontDone);

	const {id} = useParams();
	const setPublishShow = useSetAtom(PIsPublishShowFont);
	const fontIdLive = useAtomValue(SFontIDLive);

	//Fonts
	const { data: fonts } = useQuery('all-fonts', getAllFont);

	//Font Setting Live
	const {data: fontLive, refetch: refetchFontLive} = useQuery<GetFontSettingResponse, AxiosError>({
		queryKey: ['fontLive', fontIdLive],
		queryFn: () => getFontSettingByAppIDLive(id, fontIdLive),
		enabled: false,
	});

	//Font Setting
	const {data, refetch: refetchFont, isFetched} = useQuery<GetFontSettingResponse, AxiosError>({
		queryKey: ['font', selectedFontID],
		queryFn: () => getFontSettingByAppID(id, selectedFontID),
		enabled: false,
		onSuccess: (data) => {
			if(data && fonts){
				setHeadlineFontID(data.fontSetting.headlineTextFontID);
				setNavTextFontID(data.fontSetting.bodyTextFontID);
				fonts.rows.map((font) => {
					if(font.fontID === data.fontSetting.headlineTextFontID) {setHeadlineFont(font.name);}
					if(font.fontID === data.fontSetting.bodyTextFontID) {setNavTextFont(font.name);}
				});
				setFontSize(data.fontSetting.size);
				setIsFontRefresh(false);
				setFontDone(true);
			}
		}
	});

	useEffect(() => {
		if(fonts) setFontData(fonts.rows.map((font) => {return {status: font.status, description: font.description, fontID: font.fontID, name: font.name};}));
	},[fonts, id]);

	useEffect(() => {
		if (selectedFontID && isFontRefresh && fontIdLive) {
			refetchFont();
			refetchFontLive();
		}
	}, [selectedFontID, isFontRefresh, fonts, fontIdLive]);

	useEffect(() => {
		if(data?.fontSetting && isFetched){
			if(data.fontSetting.headlineTextFontID !== headlineFontID || data.fontSetting.bodyTextFontID !== navTextFontID || 
                data.fontSetting.size !== navTextSize){
				setAppearanceChanges(true);
				setFontChanges(true);
				log('font 1');
			}else {setAppearanceChanges(false); setFontChanges(false);}
		}
	},[headlineFontID, navTextFontID, navTextSize, isFetched]);

	useEffect(() => {
		if(data && fontLive){
			if(data.fontSetting.bodyTextFontID !== fontLive.fontSetting.bodyTextFontID || 
				data.fontSetting.headlineTextFontID !== fontLive.fontSetting.headlineTextFontID ||
				data.fontSetting.size !== fontLive.fontSetting.size){
				setPublishShow(true);
			}else setPublishShow(false);
		}
	},[data, fontLive]);
};