import React, { useState, useEffect } from 'react';
import StripeCardInputElement from 'pages/CustomizeApp/Payment/StripeCardInput';
import Modal from '../Modal';
import Button from 'components/Common/Buttons/Button';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import * as subscriptionAtom from '../../../../store/CustomizeApp/Payment/subscription';
import { freePriceId } from 'pages/CustomizeApp/Payment/Content/oldIndex';
import * as typeSchemas from '../../../../services/requests/Payment/schema';
import * as paymentApis from '../../../../services/requests/Payment/index';
import { showFailedAlert, showSuccessAlert } from 'utility/alerts';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { ensure } from 'utility/ensure';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { payFormModal, saveCardInfo, paySaving } from 'store/Payment/successPaymentAtom';
import { cardHolderName, isCardExpiryComplete } from 'store/EditProfile/cardManagement';
import { IMAGES } from 'utility';
import { boolean } from 'zod';
import { log } from 'utility/log';

interface IPaymentFormModal {
	isPaymentModalShow: boolean;
	setPaymentModalShow: React.Dispatch<React.SetStateAction<boolean>>;
	onClickCancel: (e: any) => void;
	onClickSave: (pmId: string, cardName: string) => void;
}

export const PaymentFormModal: React.FC<IPaymentFormModal> = 
({ onClickCancel, onClickSave, isPaymentModalShow}) => {

	const stripe = useStripe();
	const elements = useElements();
	
	const [cardBrand, ]                                  = useAtom(subscriptionAtom.FCardBrand);
	const [cardNumberError ,setCardNumberError]          = useAtom(subscriptionAtom.FCardNumberError);
	const [cardExpiryError ,setCardExpiryError]          = useAtom(subscriptionAtom.FExpiryError);
	const [cardCVCError ,setCardCVCError]                = useAtom(subscriptionAtom.FCVCError);
	const [ ,setInlineError]                             = useAtom(subscriptionAtom.FInlineError);
	const [selectedPlanPriceId] 												 = useAtom(subscriptionAtom.planPriceID);
	const [formShow, setFormShow]                        = useAtom(payFormModal);
	const [paymentMethodID, setPaymentMethodID]														 = useAtom(subscriptionAtom.paymentMethodID);
	const setPaymentMethodError													 = useSetAtom(subscriptionAtom.paymentMethodError);
	const setIsSavedCard  															 = useSetAtom(saveCardInfo);
	const [isSaving, setIsSaving]  						 = useAtom(paySaving);
	const [cardName,] = useAtom(cardHolderName);
	const FREE_TRIAL_PRICE_ID                            = freePriceId;
	const isCardExpiryComp = useAtomValue(isCardExpiryComplete);
	const isCardCvcComp = useAtomValue(isCardExpiryComplete);


	const formValidation = () => {
		if (selectedPlanPriceId === FREE_TRIAL_PRICE_ID //free trial
			|| cardBrand === '' 
			|| cardBrand === 'unknown'
			|| cardName === ''
			|| cardNumberError !== ''
			|| cardExpiryError !== ''
			|| cardCVCError !== ''
			|| isCardExpiryComp !== true
			|| isCardCvcComp !== true
		) { 
			log('formValidation BRAND: ', cardBrand);
			return true; 
		} 

		// if (cardNumberError !== '') return true;
		// if (cardExpiryError !== '') return true;
		// if (cardCVCError !== '') return true;

		return false;
	};

	const { mutate: setPaymentData, isLoading } = 
	useMutation<typeSchemas.SubscriptionResponse, AxiosError<any>, typeSchemas.SubscriptionParams>(
		data => paymentApis.SubscribePerAppId(data), {
			onSuccess: () => {
				// setIsModalOpen(true);
				// setSuccessPayment(true);
				// showSuccessAlert('You have saved your card information');
				// setFormShow(false);
			},
			onError: (error) => {
				const err = error?.response?.data?.error?.message;
				if (err) {
					const errParse = JSON.parse(err);
					//const errCode = errParse?.code;
					const errMsg = errParse?.decline_code ? `${errParse?.message} - ${errParse?.decline_code}` : errParse?.message;
	
					setInlineError(errMsg); // optional, to delete if not needed
					showFailedAlert(errMsg);
				}
				else
				{
					setInlineError(error);
					showFailedAlert('Payment Failed');
				}
			}
		}
	);

	const onClickSaveBtn = async () => {
		// Elements knows how to find your CardElement because there 
		// can only ever be one of each type of element.

		setIsSaving(true);

		const errorMessage = 'This value was promised to be there.'; 
		const cardNumberElement = ensure(elements, errorMessage).getElement(CardNumberElement);

		const cardExpiryElement = ensure(elements, errorMessage).getElement(CardExpiryElement);
		const cardCvcElement = ensure(elements, errorMessage).getElement(CardCvcElement);


		// Card Number Element, to tokenize payment details
		const { error: paymentError, paymentMethod } = await ensure(stripe, errorMessage).createPaymentMethod({
			type: 'card',
			card: ensure(cardNumberElement, errorMessage)
		});

		setPaymentMethodError(paymentError);

		if (paymentError) {

			const errorCode = ensure(paymentError?.code).replaceAll('_', ' ');
		
			if (errorCode === 'invalid number') {
				setCardNumberError(errorCode);
			}

			if (errorCode === 'invalid expiry year past') {
				setCardExpiryError(errorCode);
			}

			if (errorCode === 'incomplete cvc') {
				setCardCVCError(errorCode);
			}

			if (errorCode === 'card declined') {
				showFailedAlert(paymentError?.message);
				return;
			}

			// return (
			// 	setInlineError(errorCode)
			// // setCardError(error), --> this is from stripe, optional to be use
			// // you can throw an error message here
			// );
		}

		const paymentMethodId = ensure(paymentMethod, errorMessage).id;
		setPaymentMethodID(paymentMethodId);

		log('payMethod: ', paymentMethod);
		log('payError: ', paymentError);
		
		if (!paymentError) {
			onClickSave(paymentMethodId, cardName);
			// setPaymentData({
			// 	appId:  id?.toString(),
			// 	priceId:  selectedPlanPriceId, // to change to id int
			// 	paymentMethodId: paymentMethodId.toString()
			// });
			setIsSavedCard(true);
			//setFormShow(false);
			//showSuccessAlert('You have saved your card information');
		}
		
	};

	const renderPaymentFormModal = (): JSX.Element => {
		return (
			<>
				<div className='bg-white shadow-lg pt-[0.35rem] pb-9 px-7 rounded-[0.5rem]'>
					<div className='mt-5 text-base font-semibold text-gray-600 text-center'>
						Choose a Payment Method
					</div>
					<div className='flex flex-row mt-7 mb-7 w-[28%] gap-2 px-3'> 
						<img src={IMAGES.PaypalPay} alt='Paypal' />
						<img src={IMAGES.ShopPay} alt='ShopPay' />
						<img src={IMAGES.AmazonPay} alt='Amazon Pay' />
						<img src={IMAGES.GooglePay} alt='Google Pay' /> 
					</div>
					<div className='relative mx-2'>
						<hr className='border-0 border-t border-gray-300' />
						<span className='px-5 bg-white absolute -top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[#464255]'>
							or
						</span>
					</div>
					<div className='mt-7 mx-5 px-[0.5rem]'>
						<StripeCardInputElement/>
					</div>
					<div className='flex flex-row gap-[30px] justify-center mt-[25px] mb-1'>
						<Button variant='secondary' className='px-[38px] py-1.5' onClick={onClickCancel}>Cancel</Button>
						<Button 
							variant='primary' 
							className='px-[47px] py-1.5' 
							onClick={onClickSaveBtn}
							disabled={formValidation() || isSaving}>
								Save
						</Button>
					</div>
				</div>	
			</>
		);
	};

	return (
		<div>
			<Modal open={isPaymentModalShow} onClose={setFormShow} className='sm:max-w-md'>
				{renderPaymentFormModal()}
			</Modal>
		</div>
	);
};