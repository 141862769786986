import { Navigate, Outlet } from 'react-router';

import AppLayout from 'components/AppLayout';
import Approver from 'components/Common/Approver';
import React from 'react';
import { authAtom } from 'store/authAtom';
import { useAtomValue } from 'jotai';

const Container: React.FC = () => {

	const auth = useAtomValue(authAtom);

	if(auth.access_token) {
		return <Navigate to="/dashboard" />;
	}

	return <AppLayout isLogin={false} />;

	// return <Approver />;
    
};

export default Container;