import React, { useState } from 'react';

import Button from 'components/Common/Buttons/Button';
import Modal from '../Modal';

interface LeaveSectionModal {
	isLeaveSectionModalShow: boolean;
	setLeaveSectioShow: React.Dispatch<React.SetStateAction<boolean>>;
	onClickLeave?: (e: any) => void;
    onClickStay?: (e: any) => void;
}

export const LeaveSectionModal: React.FC<LeaveSectionModal> = ({isLeaveSectionModalShow, setLeaveSectioShow, onClickLeave, onClickStay}) => {
	
	const renderLeaveSectionModal = (): JSX.Element => {
		return(
			<>
				<div className='sm:mx-auto sm:w-full sm:max-w-md'>
					<div className='py-[28px] px-[60px] sm:rounded sm:px-10'>
						<div className='flex items-center justify-center' />
                        
						<p className='text-base font-bold text-[#707070] text-center mt-[15px]'> 
                            Are you sure you want to leave this section?
						</p>

						<p className='text-base text-[#707070] text-center mt-[15px]'> 
                            You have unsaved content, and will be lost unless you save it.					</p>
						<div className='bg- flex flex-row gap-[42px] justify-center mt-[25px]'>
							<Button className='py-1.5 px-3' onClick={onClickLeave}>Leave this Page</Button>
							<Button variant='orange' className='py-1.5 px-3' onClick={onClickStay}>Stay on this Page</Button>
						</div>
					</div>
				</div>
			</>
		);
	};

	return (
		<div>
			<Modal open={isLeaveSectionModalShow} onClose={setLeaveSectioShow} className='sm:max-w-md'>
				{renderLeaveSectionModal()}
			</Modal>
		</div>
	);
};

