import { FAccessibility, FAccessibilityID, FIsAccessibilityRefresh } from 'store/CustomizeApp/Features/accessibility';
import { GetAccessibilityFormResponse, GetAccessibilityFormType } from 'services/requests/Features/Accessibility/schema';
import { createAccessibility, editAccessibilityByAppID } from 'services/requests/Features/Accessibility';
import { useAtomValue, useSetAtom } from 'jotai';

import { AppFeatIsSaving } from 'store/CustomizeApp/Appearance';
import { AxiosError } from 'axios';
import ContactMutation from '../Contact';
import React from 'react';
import { showFailedAlert } from 'utility/alerts';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { FIsAccessChanges } from 'store/CustomizeApp/Features';

const AccessibilityMutation = () => {
	const { id } = useParams();
	//Atom for Accessibility
	const accessibilityDesc = useAtomValue(FAccessibility);
	const accessibilityID = useAtomValue(FAccessibilityID);
	const setIsAccessRefresh = useSetAtom(FIsAccessibilityRefresh);
	const setIsSaving = useSetAtom(AppFeatIsSaving);
	const isThereChanges = useAtomValue(FIsAccessChanges);
	const {saveContact} = ContactMutation();
	//Create Accessibility
	const { mutate: createAccessibilityMU } = useMutation<
		GetAccessibilityFormResponse,
		AxiosError,
		GetAccessibilityFormType
	>((data) => createAccessibility(id, data), {
		onSuccess: () => {
			setIsAccessRefresh(true);
			saveContact();
		},
		onError: () => {
			setIsSaving(false);
			showFailedAlert('Cannot save accessibility');
		},
	});

	//Put Accessibility
	const { mutate: editAccessibility } = useMutation<
		GetAccessibilityFormResponse,
		AxiosError,
		GetAccessibilityFormType
	>((data) => editAccessibilityByAppID(id, accessibilityID, data), {
		onSuccess: () => {
			setIsAccessRefresh(true);
			saveContact();
		},
		onError: () => {
			setIsSaving(false);
			showFailedAlert('Cannot edit accessibility.');
		},
	});

	const updateAccessibility = () => {
		if (id)
			editAccessibility({
				description: accessibilityDesc,
				isTurnedOn: true,
			});
	};
	const saveAccessibility = () => {
		if(isThereChanges){
			if (accessibilityID) {
				updateAccessibility();
			} else {
				createAccessibilityMU({
					description: accessibilityDesc,
					isTurnedOn: true,
				});
			}
		}else saveContact();
		
	};   

	return {saveAccessibility,};
};

export default AccessibilityMutation;