import { zodResolver } from '@hookform/resolvers/zod';
import { AxiosError } from 'axios';
import { Button } from 'components/Common/Buttons';
import FormInput from 'components/Common/Forms/Inputs';
import { useAtom, useSetAtom } from 'jotai';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { approverLoginApi } from 'services/requests/auth';
import { LoginResponseType, LoginType, loginSchema } from 'services/requests/auth/schema';
import { globalVariableAtom, authAtom } from 'store/authAtom';

const ApproverSignIn: React.FC = ()=> {
	const [globalVariable, setGlobalVariable] = useAtom(globalVariableAtom);
	const setAuth = useSetAtom(authAtom);
	const navigate = useNavigate(); //tmp

	const { control, handleSubmit, formState } = useForm<LoginType>({
		defaultValues: {username: '', password: ''},
		mode: 'onBlur',
		resolver: zodResolver(loginSchema)
	});
	const {
		mutate: approverLoginMu,
	} = useMutation<LoginResponseType, AxiosError, LoginType>(
		data => approverLoginApi(data), {
			onSuccess: (data) => {
				setAuth(data);
				localStorage.removeItem('userData');
				navigate('/approver');

			},
			onError: (error) => {
				setGlobalVariable('The email and/or password you entered is incorrect.');
			}
		}
	);

	const onSubmit = (data: LoginType) => {
		approverLoginMu(data);
	};
 
	return (
		<>
		
			<div className="flex min-h-screen flex-1 flex-col justify-center sm:px-6 lg:px-8">
				<div className="sm:mx-auto sm:w-full sm:max-w-md">
					{/* PUT SNAP LOGO HERE IF THERE'S ANY */}
					{/* <img
						className="mx-auto h-10 w-auto"
						src=""
						alt="SNAP APPROVER LOGO"
					/> */}
					<h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
        Sign In to your Approver Account
					</h2>
				</div>

				<div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
					<div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
						<form className="space-y-6" >
							<div>
								<Controller
									control={control}
									name="username"
									render={({ field: {name, onChange}, fieldState: { error } }) => (
										<FormInput label='Email Address' onChange={onChange} name={name} error={error?.message} type='email'/>
									)}
								/>
							</div>

							<div>
								<Controller
									control={control}
									name="password"
									render={({ field: {name, onChange}, fieldState: { error } }) => (
										<FormInput label='Password' onChange={onChange} name={name} error={error?.message} type='password'/>
									)}
								/>
							</div>

							<div className="flex items-center justify-between">
								<div className="flex items-center">
									<input
										id="remember-me"
										name="remember-me"
										type="checkbox"
										className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
									/>
									<label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-900">
                Remember me
									</label>
								</div>
							</div>

							<div>
								<Button
									type='button'
									onClick={handleSubmit(onSubmit)}
									className='w-full py-1.5 bg-[#3C5491] text-white' 
									disabled={!formState.dirtyFields.username || !formState.dirtyFields.password}>
									<span className='text-sm'>Sign In</span>
								</Button>
							</div>
							<div className='mt-3'>
								{globalVariable === 'The email and/or password you entered is incorrect.' ?
									<p className='flex justify-center items-center text-red-700 text-xs pl-1 py-2'>{globalVariable}</p>
									:
									null
								}
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default ApproverSignIn;