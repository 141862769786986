import { EditOfferParams, GetOffersType, GetTempOffersType } from 'services/requests/Features/Offers/schema';
import React, { useEffect, useState } from 'react';

import Dropdown from 'components/Common/Dropdown';
import { FTempOffers } from 'store/CustomizeApp/Features/offersAtom';
import FormInput from 'components/Common/Forms/Inputs';
import FormInputTextArea from 'components/Common/Forms/TextArea';
import { classNames } from 'utility';
import { useForm } from 'react-hook-form';
import { useSetAtom } from 'jotai';

interface IOffersForm {
  couponType: number;
  title: string;
  description: string;
  termsAndCondition: string;
  discountType: string;
  discount: string;
  buy: number;
  get: number;
  code: string;
}

type AccordionProps = {
	items: GetTempOffersType;
	index: number;
	handleChange?: (value: EditOfferParams, index: number) => void;
	value: string;
};

interface DropdownOption {
	label: string;
	value: string;
}
  
const discountTypeOptions: DropdownOption[] = [
	{ label: 'Percentage', value: 'Percentage' },
	{ label: 'Amount', value: 'Amount' }
];

const buyOptions = [
	{ label: '1', value: 1 },
	{ label: '2', value: 2 },
	{ label: '3', value: 3 }
];

const getOptions = [
	{ label: '1', value: 1 },
	{ label: '2', value: 2 },
	{ label: '3', value: 3 }
];

const primaryButtonColor = 'bg-[#ff7f20] text-white';
const secondaryButtonColor = 'bg-transparent text-[#ff7f20] border border-[#ff7f20]';

const AccordionContent: React.FC<AccordionProps> = ({ items, value, index, handleChange }) => {
	const setTempOffer = useSetAtom(FTempOffers);
	const [couponType, setCouponType] = useState<number>(items.couponType);
	const [offerData, setOfferData]= useState<EditOfferParams>({
		title: items.title,
		offerID: items.offerID,
		couponType:items.couponType,
		description: items.description,
		termsAndCondition: items.termsAndCondition,
		discountType: items.discountType,
		discount: items.discount,
		buy: items.buy,
		get: items.get,
		code: items.code
	});

	// useEffect(() => {
	// 	setTempOffer(prev => [...prev, offerData]);
	// },[offerData]);

	const { setValue, watch } = useForm<IOffersForm>({
		defaultValues: {
			couponType: 1,
			title: 'Offer Name',
			description: '',
			termsAndCondition: '',
			discountType: 'Amount',
			discount: '',
			buy: 1,
			get: 1,
			code: ''
		},
		mode: 'onChange',
		// resolver: zodResolver(signUpSchema) - to add this one
	});

	const formValues = watch();

	useEffect(() => {
		if (items.discountType) {
			setValue('discountType', items.discountType);
		}
		if (items.buy) {
			setValue('buy', items.buy);
		}
		if (items.get) {
			setValue('get', items.get);
		}
	},[]);

	const handleEditOfferData = (data: EditOfferParams) => {
		setOfferData(data);    
		handleChange && handleChange(data, index);
	};

	const handleCouponTypeChange = (value: number) => {
		if (couponType !== value) {
			setCouponType(value);
			setValue('couponType', value);
			handleEditOfferData({...offerData, couponType: value});
		}
		if (value === 1) {
			handleEditOfferData({...offerData, couponType: value});
		}
		if (value === 2) {
			handleEditOfferData({...offerData, couponType: value, buy: 1, get: 1});
		}
	};

	const onChangeDiscountType = (value: string) => {
		setValue('discount', '');
		setValue('discountType', value);
		handleEditOfferData({...offerData, discountType: value, discount: ''});
	};

	const onChangeDiscount = (event: React.ChangeEvent<HTMLInputElement>) => {
		let value = event.target.value;
		value = value.replace(/^0+/, '');
		const parsedValue = parseFloat(value);

		if (!isNaN(parsedValue) && parsedValue > 0) {
			if (offerData.discountType == 'Percentage') {
				if (parsedValue >= 100) {
					value = value.slice(0,2);
				}

				if (value.length > 5) {
					value = value.slice(0, 5);
				}
			} else {
				if (parsedValue >= 10000000) {
					value = value.slice(0,7);
				}

				if (value.length > 10) {
					value = value.slice(0, 10);
				}
			}
		} else {
			value = '';
		}
		
		setValue('discount', value);
		handleEditOfferData({...offerData, discount: value});
	};

	const onChangeBuy = (value: string) => {
		setValue('buy', parseInt(value));
		handleEditOfferData({...offerData, buy: parseInt(value)});
	};

	const onChangeGet = (value: string) => {
		setValue('get', parseInt(value));
		handleEditOfferData({...offerData, get: parseInt(value)});
	};

	return (
		<div className='w-full h-auto'> {/* to change the height into "vh" */}
			<div className='sticky top-0 z-10 border-t-2 border-gray-300' />
			<div className='mx-7 h-auto mb-12 max-sm:mx-1'> {/* Accordion Parent Container */}
				<div className='mx-2'>
					<label className='my-4 block text-gray-500 font-bold text-md'>
                      Select Coupon Type
					</label>
				</div>
				<div className='mx-2 space-x-4 max-sm:justify-between max-sm:flex max-sm:flex-row'> {/* Buttons */}
					<button
						className={classNames(
							'w-32 max-sm:w-24 h-9 text-sm max-sm:text-[11px] font-normal rounded-full',
							couponType === 1 
								? primaryButtonColor
								: secondaryButtonColor
						)}
						onClick={() => handleCouponTypeChange(1)}
					>
						Discount
					</button>
					<button
						className={classNames(
							'w-32 max-sm:w-24 h-9 text-sm max-sm:text-[11px] font-normal rounded-full',
							couponType === 2
								? primaryButtonColor
								: secondaryButtonColor
						)}
						onClick={() => handleCouponTypeChange(2)}
					>
						2+1 Buy & Get
					</button>
				</div>
				<div className='mx-2 mt-4'> {/* Title */}
					<p className='font-medium text-sm'>
                      Title <span className='text-red-600'>*</span>
					</p>
					<FormInput
						type='text'
						placeholder='Title'
						value={items.title}
						className='w-[495px] h-[40px] mt-[6px] text-sm'
						maxLength={50}
						onChange={(value) => handleEditOfferData({...offerData, title: value})}
					/>
				</div>
				<div className='mx-2 mt-4'> {/* Brief Desc */}
					<p className='font-medium text-sm'>
                      Brief Description
						<span className='text-red-600'>*</span>
					</p>
					<FormInputTextArea 
						type='text'
						placeholder='Description'
						value={items.description}
						className='w-[498px] h-[79px] mt-[6px] text-sm'
						onChange={(value) => handleEditOfferData({...offerData, description: value})}
						maxLength={500}	
					/>
				</div>
				<div className='mx-2 mt-4'> {/* Terms */}
					<p className='font-medium text-sm'>
                      Terms and Condition
						<span className='text-red-600'>*</span>
					</p>
					<FormInputTextArea 
						type='text'
						placeholder='Description'
						className='w-[498px] h-[79px] mt-[6px] text-sm'
						value={items.termsAndCondition}
						onChange={(value) => handleEditOfferData({...offerData, termsAndCondition: value})}
						maxLength={500}
					/>
				</div>
				<div className='mx-2 mt-4'>
					<label className='my-4 block text-gray-500 font-bold text-md'>
                    Savings
					</label>
				</div>
				<div className='w-auto mx-2 mt-4'>
					<div className='flex space-x-4 max-sm:flex-col max-sm:space-x-0'> {/* Savings */}
						{couponType === 1
							? // If the coupon type is "Discount"
							<>
								<div className='w-full md:w-[16.813rem]'>
									<p className='mb-2 font-medium text-sm'>
                                      Discount Type
										<span className='text-red-600'>*</span>
									</p>
									<div>
										<Dropdown
											value={formValues.discountType}
											options={discountTypeOptions}
											onChange={onChangeDiscountType}
										/>
									</div>
								</div>
								<div className='mx-2 w-full md:w-[16.813rem] max-sm:mt-4'>
									<p className='font-medium text-sm'>
                                      Discount
										<span className='text-red-600'>*</span>
									</p>
									<div className='flex flex-row'>
										<p 	className='py-[7px] max-sm:py-[5px] my-[6px] px-[16px] mr-[-3px] bg-[#E6E4E4] border border-[#D9D7D7]' 
											style={{borderRadius: '10px 0 0 10px'}}
										>
											{formValues.discountType === 'Percentage' ? '%' : '$'}
										</p>
										{/* <FormInput
											type='text'
											placeholder='Add Discount'
											value={items.discount}
											className='w-[240px] h-[40px] mt-[6px] max-sm:w-[170px] [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none'
											onChange={onChangeDiscount}
										/> */}
										<input
											type='number'
											placeholder='Add Discount'
											value={items.discount}
											className='border-gray-300 bg-white text-black text-sm rounded-md border py-1.5 shadow-none pl-2 focus:ring-1 focus:border-input-active outline-none w-[240px] h-[40px] mt-[6px] max-sm:w-full [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none'
											onChange={onChangeDiscount}
											onKeyDown={(e) =>['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
										/>
									</div>
								</div>
							</> 

							: // If the coupon type is "2+1 Buy & Get"
							<>
								<div className='w-full md:w-[16.813rem]'>
									<p className='mb-2 font-medium text-sm'>
                                      Buy
										<span className='text-red-600'>*</span>
									</p>
									<div>
										<Dropdown
											value={formValues.buy}
											options={buyOptions}
											onChange={onChangeBuy}
										/>
									</div>
								</div>
								<div className='w-full md:w-[16.813rem] max-sm:mt-4'>
									<p className='mb-2 font-medium text-sm'>
                                      Get
										<span className='text-red-600'>*</span>
									</p>
									<div>
										<Dropdown
											value={formValues.get}
											options={getOptions}
											onChange={onChangeGet}
										/>
									</div>
								</div>
							</>
						}
					</div>
				</div>
				<div className='mx-2 mt-4 w-full'>
					<label className='mt-4 mb-2 block text-gray-500 font-bold text-md'>
                      Code
					</label>
				</div>
				<div className='mx-2 w-full md:w-[16.813rem] max-sm:w-[90%]'>
					<FormInput
						type='text'
						value={items.code}
						className='w-full h-[40px] text-sm'
						onChange={(value) => handleEditOfferData({...offerData, code: value})}
						maxLength={14}
					/>
				</div>
			</div>
		</div>
	);
};

export default AccordionContent;