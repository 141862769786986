import { GetIndustryResponse, GetAllIndustryResponse } from './schema';

import { axiosInstance } from 'services/axios';

export const getIndustryById = async (id : number) => {
	const response = await axiosInstance.get(`/organizations/industries/${id}`);
	return response.data as GetIndustryResponse;
};
export const getAllIndustry = async () => {
	const response = await axiosInstance.get('/organizations/industries',{
		params: {
			limit : 17
		}
	});
	return response.data as GetAllIndustryResponse;
};