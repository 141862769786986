import { AppFeatIsSaving, SAppName, SAppNameChanges, SIsAppearanceChanges } from 'store/CustomizeApp/Appearance';
import { DeleteSocialMediaType, addMoreSocialResponse, addMoreSocialType, editSocialMediaParams, editSocialMediaResponse } from 'services/requests/Features/SocialMedia/schema';
import { FDeleteSocialIdsAtom, FSocialMediasAtom, FsocialMediasRefreshAtom } from 'store/CustomizeApp/Features/socialMediaAtom';
import { FIsFeaturesChange, FIsSocialMediaChanges } from 'store/CustomizeApp/Features';
import { addMoreSocialAction, deleteSocialAction, updateSocialAction } from 'services/requests/Features/SocialMedia';
import { showFailedAlert, showSuccessAlert } from 'utility/alerts';
import { useAtomValue, useSetAtom } from 'jotai';
import { useMutation, useQueryClient } from 'react-query';

import { AxiosError } from 'axios';
import React from 'react';
import { useParams } from 'react-router-dom';
import { GetAppResponse } from 'services/requests/apps/schema';
import { SIndustrySubCategory } from 'store/CustomizeApp/Appearance/flashScreenAtom';

const SocialMediaMutation = () => {
	const { id } = useParams();
	const setIsSaving = useSetAtom(AppFeatIsSaving);
	// Social Media
	const socialMedias  = useAtomValue(FSocialMediasAtom);
	const socialMediaIds = useAtomValue(FDeleteSocialIdsAtom);
	const isThereChanges = useAtomValue(FIsSocialMediaChanges);
	const setSocMedRefresh = useSetAtom(FsocialMediasRefreshAtom);
	const setAppNameChanges = useSetAtom(SAppNameChanges);
	const setAppearanceChange = useSetAtom(SIsAppearanceChanges);
	const setFeaturesChange = useSetAtom(FIsFeaturesChange);

	const subcategoryID = useAtomValue(SIndustrySubCategory);
	const appName = useAtomValue(SAppName);

	const queryClient = useQueryClient();

	const updateCachedApp = () => {
		queryClient.setQueryData<GetAppResponse | undefined>(
			['app'],
			(cached) =>
				cached ? {
					...cached,
					app: {
						...cached.app,
						name: appName,
						isJustPublished: false,
						subcategoryID: subcategoryID,
					}
				} : undefined
		);
	};

	const { mutate:addMoreMu } = useMutation<addMoreSocialResponse, AxiosError, addMoreSocialType>(
		data => addMoreSocialAction(id, data), {
			onSuccess: () => {
				queryClient.invalidateQueries('SocialMedias');
				setSocMedRefresh(true);
			}
		}
	);

	const {mutate: socialUpdateMu} = useMutation<editSocialMediaResponse, AxiosError, editSocialMediaParams>(
		data => updateSocialAction(data), {
			onSuccess: () => {
				queryClient.invalidateQueries('SocialMedias');
				setSocMedRefresh(true);
			}
		}
	);

	const { mutate: deleteSocialMu } = useMutation<null,AxiosError,DeleteSocialMediaType>(
		(data) => deleteSocialAction(data.appId,data.socialMediaID), {
			onSuccess: () => {
				queryClient.invalidateQueries('SocialMedias');
				setSocMedRefresh(true);
			}
		});

	const saveSocialMedia = async() => {
		if(isThereChanges){
			const checkDuplicate = socialMedias.filter((socmed, idx) => socialMedias.findIndex(s => s.WebsiteLink === socmed.WebsiteLink || s.label === socmed.label) !== idx);
			if (checkDuplicate.length) {
				showFailedAlert('Duplicate social media found.');
				setIsSaving(false);
				setAppearanceChange(false);
				setFeaturesChange(false);
			}else {
				if(socialMedias.length){
					socialMedias.map((socmed, idx) => {
						if(!socmed.appId && !socmed.socialMediaID){
							addMoreMu({
								appId: socmed.appId,
								socialMediaID: socmed.socialMediaID,
								icon: socmed.icon,
								label: socmed.label,
								WebsiteLink: socmed.WebsiteLink,
								sortOrder: idx
							});
						}else{
							socialUpdateMu({
								appId: socmed.appId,
								socialMediaID: socmed.socialMediaID,
								icon: socmed.icon,
								label: socmed.label,
								WebsiteLink: socmed.WebsiteLink,
								sortOrder: idx
							});
						}
						if(idx === socialMedias.length - 1){
							if(socialMediaIds && socialMediaIds.length > 0){
								socialMediaIds.map((items, idx) => {
									deleteSocialMu(items);
									idx === socialMediaIds.length - 1 && (
										// setSocMedRefresh(true),
										showSuccessAlert('Your changes have been saved!'),
										setIsSaving(false),
										updateCachedApp()
									);
								});
							}else {
								// setSocMedRefresh(true);
								showSuccessAlert('Your changes have been saved!');
								setIsSaving(false);
								updateCachedApp();
							}
						}
					});
				}else {
					setSocMedRefresh(true);
					showSuccessAlert('Your changes have been saved!');
					setIsSaving(false);
					setAppNameChanges(false);
					setAppearanceChange(false);
					setFeaturesChange(false);
					updateCachedApp();
				}
			}
		}else {
			setSocMedRefresh(true);
			showSuccessAlert('Your changes have been saved!');
			setIsSaving(false);
			setAppNameChanges(false);
			setAppearanceChange(false);
			setFeaturesChange(false);
			updateCachedApp();
		}
		
	};

	return {saveSocialMedia,};
};

export default SocialMediaMutation;