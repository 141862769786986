import z from 'zod';

export const GetAccessibilitySchema = z.object({
	description: z.string(),
	isTurnedOn: z.boolean(),
});


export const EditAccessibilityByAppIdResponse = z.object({
	accessibility: z.object({
		accessibilityID: z.number(),
		companyTenantID: z.string(),
		appTenantID: z.string(),
		appID: z.string(),
		description: z.string(),
		isTurnedOn: z.boolean(),
		status: z.string(),
		whoAdded: z.number(),
		whoUpdated: z.number(),
		whenAdded: z.string(),
		whenUpdated: z.string()
	})
});



export type GetAccessibilityFormType = z.infer<typeof GetAccessibilitySchema>;
export type GetAccessibilityFormResponse = z.infer<typeof EditAccessibilityByAppIdResponse>;