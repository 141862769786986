import { SCardBackgroundColor, SCardBorderColor, SCardContentColor, SCardIconColor, SCardTitleColor, SIsColorRefresh, SNavigationColor, SNavigationHighlightColor, SNavigationIconActiveColor, SNavigationIconColor, SNavigationTextActiveColor, SNavigationTextColor, STopBarColor, STopBarTextColor } from 'store/CustomizeApp/Appearance/colorAtom';
import { SColorID, SColorIDLive, SIsAppearanceChanges, SIsColorChanges, SisColorDone } from 'store/CustomizeApp/Appearance';
import { getAppColorSchemeByID, getAppColorSchemeByIDLive } from 'services/requests/appearance/color_scheme';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { AxiosError } from 'axios';
import { GetAppColorSchemeResponse } from 'services/requests/appearance/color_scheme/schema';
import { PIsPublishShowColor } from 'store/CustomizeApp/Publish/publishAtom';
import { SIsNavigationTextCheck } from 'store/CustomizeApp/Appearance/navigationAtom';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { log } from 'utility/log';

export const ColorUseQuery = () => {
	const {id} = useParams();
	//Atoms for Color
	const selectedColorID 					            = useAtomValue(SColorID);
	const [topBarColor, setTopBarColor] 				= useAtom(STopBarColor);
	const [topBarTextColor, setTopBarTextColor] 		= useAtom(STopBarTextColor);

	const [navigationColor, setNavigationColor] 	        = useAtom(SNavigationColor);
	const [navigationIconColor, setNavigationIconColor]     = useAtom(SNavigationIconColor);
	const [navigationIconActiveColor, setNavigationIconActiveColor] = useAtom(SNavigationIconActiveColor);
	const [navigationTextColor, setNavigationTextColor]     = useAtom(SNavigationTextColor);
	const [navigationTextActiveColor, setNavigationTextActiveColor] = useAtom(SNavigationTextActiveColor);
	const [navigationHighlightColor, setNavigationHighlightColor] = useAtom(SNavigationHighlightColor);

	
	const [cardBackgroundColor, setCardBackgroundColor] = useAtom(SCardBackgroundColor);
	const [cardBorderColor, setCardBorderColor] = useAtom(SCardBorderColor);
	const [cardIconColor, setCardIconColor] = useAtom(SCardIconColor);
	const [cardTitleColor, setCardTitleColor] = useAtom(SCardTitleColor);
	const [cardContentColor, setCardContentColor] = useAtom(SCardContentColor);

	const [isColorRefresh, setIsColorRefresh]           = useAtom(SIsColorRefresh);
	const setAppearanceChanges                          = useSetAtom(SIsAppearanceChanges);
	const setColorChanges                          = useSetAtom(SIsColorChanges);
	const colorIdLive = useAtomValue(SColorIDLive);
	const setPublishShow = useSetAtom(PIsPublishShowColor);
	const setColorDone = useSetAtom(SisColorDone);

	//Color Scheme Live
	const {data: colorLive, refetch: refetchColorLive} = useQuery<GetAppColorSchemeResponse, AxiosError>({
		queryKey: ['colorLive', colorIdLive],
		queryFn: () => getAppColorSchemeByIDLive(id, colorIdLive),
		enabled: false,
	});

	//Color Scheme
	const {data, refetch: refetchColor, isFetched} = useQuery<GetAppColorSchemeResponse, AxiosError>({
		queryKey: ['color', selectedColorID],
		queryFn: () => getAppColorSchemeByID(id, selectedColorID),
		enabled: false,
		onSuccess: (data) => {
			if(data){
				setTopBarColor(data.colorScheme.topbarColor);
				setTopBarTextColor(data.colorScheme.topbarTextColor);

				setNavigationColor(data.colorScheme.navigationBackgroundColor);
				setNavigationIconColor(data.colorScheme.navigationIconColor);
				setNavigationIconActiveColor(data.colorScheme.navigationIconActiveStateColor);
				setNavigationTextColor(data.colorScheme.navigationTextColor);
				setNavigationTextActiveColor(data.colorScheme.navigationTextActiveStateColor);
				setNavigationHighlightColor(data.colorScheme.navigationHighlightColor);

				setCardBackgroundColor(data.colorScheme.cardBackgroundColor);
				setCardBorderColor(data.colorScheme.cardBorderColor);
				setCardIconColor(data.colorScheme.cardIconColor);
				setCardTitleColor(data.colorScheme.cardTitleColor);
				setCardContentColor(data.colorScheme.cardContentTextColor);

				setIsColorRefresh(false);
				setColorDone(true);
			}
		}
	});
    
	useEffect(() => {
		if (selectedColorID && isColorRefresh && colorIdLive) {
			refetchColor();
			refetchColorLive();
		}
	}, [selectedColorID, isColorRefresh, colorIdLive]);

	useEffect(() => {
		if(data?.colorScheme && isFetched){
			if(
				//Top Bar
				data.colorScheme.topbarColor !== topBarColor || 
				data.colorScheme.topbarTextColor !== topBarTextColor ||
				
				//Navigation
                data.colorScheme.navigationBackgroundColor !== navigationColor || 
				data.colorScheme.navigationIconColor !== navigationIconColor ||
                data.colorScheme.navigationIconActiveStateColor !== navigationIconActiveColor || 
                data.colorScheme.navigationTextColor !== navigationTextColor || 
                data.colorScheme.navigationTextActiveStateColor !== navigationTextActiveColor || 
				data.colorScheme.navigationHighlightColor !== navigationHighlightColor ||
				
				//Card
                data.colorScheme.cardBackgroundColor !== cardBackgroundColor || 
				data.colorScheme.cardBorderColor !== cardBorderColor ||
                data.colorScheme.cardIconColor !== cardIconColor || 
                data.colorScheme.cardTitleColor !== cardTitleColor || 
                data.colorScheme.cardContentTextColor !== cardContentColor


			){
				setAppearanceChanges(true);
				setColorChanges(true);
				log('color 1');
			}else setAppearanceChanges(false);
		}
	},[topBarColor, topBarTextColor, isFetched, 
		navigationColor, navigationIconColor, navigationIconActiveColor, navigationTextColor, navigationTextActiveColor, navigationHighlightColor,
		cardBackgroundColor, cardBorderColor, cardIconColor, cardTitleColor, cardContentColor]);

	useEffect(() => {
		if(data && colorLive){
			if(
				//Top Bar
				data.colorScheme.topbarColor !== colorLive.colorScheme.topbarColor || 
				data.colorScheme.topbarTextColor !== colorLive.colorScheme.topbarTextColor ||

				//Navigation
				data.colorScheme.navigationBackgroundColor !== colorLive.colorScheme.navigationBackgroundColor ||
				data.colorScheme.navigationIconColor !== colorLive.colorScheme.navigationIconColor ||
				data.colorScheme.navigationIconActiveStateColor !== colorLive.colorScheme.navigationIconActiveStateColor ||
				data.colorScheme.navigationTextColor !== colorLive.colorScheme.navigationTextColor ||
				data.colorScheme.navigationTextActiveStateColor !== colorLive.colorScheme.navigationTextActiveStateColor ||
				data.colorScheme.navigationHighlightColor !== colorLive.colorScheme.navigationHighlightColor ||

				//Card
				data.colorScheme.cardBackgroundColor !== colorLive.colorScheme.cardBackgroundColor ||
				data.colorScheme.cardBorderColor !== colorLive.colorScheme.cardBorderColor ||
				data.colorScheme.cardIconColor !== colorLive.colorScheme.cardIconColor ||
				data.colorScheme.cardTitleColor !== colorLive.colorScheme.cardTitleColor ||
				data.colorScheme.cardContentTextColor !== colorLive.colorScheme.cardContentTextColor
			){
				setPublishShow(true);
			}else setPublishShow(false);
		}
	},[data, colorLive]);
};