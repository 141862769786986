import { Bars2Icon, EllipsisVerticalIcon, GlobeAsiaAustraliaIcon } from '@heroicons/react/24/solid';
import { FSocialMediasAtom, updateSocialMedia } from 'store/CustomizeApp/Features/socialMediaAtom';
import React, { useState } from 'react';
import { useAtom, useAtomValue } from 'jotai';

import { ContentModal } from 'components/Common/Modals/ContentModal';
import { DeleteModal } from 'components/Common/Modals/DeleteModal';
import FormInput from 'components/Common/Forms/Inputs';
import { SocialMediaType } from 'services/requests/Features/SocialMedia/schema';
import { TrashIcon } from '@heroicons/react/24/outline';
import { classNames } from 'utility';

interface IProps {
	cardData: SocialMediaType;
	index: number;
	handleDelete: (index: number) => void;
	handleOnChange: (label: string, webLink: string, icon: string, index: number) => void
}

const SocialMediaCards: React.FC<IProps> = ({ cardData, index, handleDelete, handleOnChange }) => {
	const socialMedias = useAtomValue(FSocialMediasAtom);
	const [, editSocMed] = useAtom(updateSocialMedia);
	const [isContentModalOpen, setIsContentModalOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

	return (
		<div
			className='bg-white w-full border border-gray-200 rounded-lg p-5 max-sm:p-0 max-sm:px-3 shadow-md'>
			<div className='bg-white rounded'>
				<div className='flex mt-4 max-sm:flex-col'>
					<div className='mt-1 flex-none mr-2 cursor-grab max-sm:hidden'>
						<Bars2Icon className='text-gray-700 font-bold h-10 w-auto max-sm:h-5' />
					</div>

					<div className='flex-initial w-5/12 max-sm:w-full max-sm:mb-2'>
						<div className="mt-2 flex">
							<div className='hidden mt-1 flex-none mr-2 cursor-grab max-sm:block'>
								<Bars2Icon className='text-gray-700 font-bold h-10 w-auto max-sm:h-5' />
							</div>

							<span
								onClick={() => setIsContentModalOpen(true)}
								className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 max-sm:px-2 text-gray-500  cursor-pointer max-sm:h-[34px]">
								{cardData.icon !== 'fas fa-globe'
									? <i className={classNames(cardData.icon, 'text-md text-gray-600')} />
									: <GlobeAsiaAustraliaIcon className='h-5 w-auto text-gray-600' />
								}
								<div className="px-2 pt-1">
									<div className="w-0 h-0 border-l-[6px] border-l-transparent border-t-[8px] border-t-gray-600 border-r-[6px] border-r-transparent" />
								</div>
							</span>

							<div className='w-full flex-1 max-sm:pr-8'>
								<FormInput
									type='text'
									className='rounded-l-none text-[14px] font-medium max-sm:h-[34px]'
									placeholder='Website'
									value={cardData.label}
									onChange={(value) => { handleOnChange(value, cardData.WebsiteLink, cardData.icon, index); }}
								/>
							</div>
						</div>
					</div>
					<div className='mt-1 flex-none max-sm:hidden'>
						<EllipsisVerticalIcon className='text-gray-300 h-10 w-auto' />
					</div>
					
					<div className="relative w-full flex-1 mt-2 flex flex-row justify-end">
						<div className='w-full max-sm:ml-7'>
							<FormInput
								type='text'
								placeholder='Add your link here'
								className="block w-full rounded-md py-1.5 text-[14px] font-medium"
								value={cardData.WebsiteLink}
								onChange={(value) => { handleOnChange(cardData.label, value, cardData.icon, index); }}


							/>
							<label
								className="absolute -top-1.5 left-10 max-sm:text-xs inline-block bg-white px-1 text-[11px] font-medium text-gray-400"
							>
								Website Link<span className='text-red-600'>*</span>
							</label>
						</div>

						<div className='flex items-center mx-1'>
							{socialMedias && socialMedias?.length > 1 ?
								<button
									onClick={() => setIsDeleteModalOpen(true)}
								>
									<TrashIcon className='text-primary h-6 w-auto' />
								</button>
								:
								<span className='invisible inline-block w-6'> place holder</span>
							}

						</div>
					</div>

				</div>
				<div className='mt-4 max-sm:my-2 ml-8 max-sm:ml-0'>
					<label className='text-gray-400 text-xs font-medium max-sm:text-[10px]'>
						<strong className='text-gray-500'>Note:  </strong>
						Supports secure HTTPS websites only.
					</label>
				</div>
			</div>
			<ContentModal
				isContentModalShow={isContentModalOpen}
				setContentModalShow={() => setIsContentModalOpen(false)}
				onClickCancel={(e) => setIsContentModalOpen(false)}
				onClick={(value) => {
					handleOnChange(cardData.label, cardData.WebsiteLink, value, index);
					setIsContentModalOpen(false);
				}}

			/>

			<DeleteModal
				isDeleteModalShow={isDeleteModalOpen}
				setDeleteModalShow={() => setIsDeleteModalOpen(false)}
				onClickCancel={() => setIsDeleteModalOpen(false)}
				onClickDelete={() => { handleDelete(index); setIsDeleteModalOpen(false); }}
				type='social media'
			/>

		</div>
	);
};

export default SocialMediaCards;


