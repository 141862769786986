import React from 'react';
import Modal from '../Modal';
import Button from 'components/Common/Buttons/Button';

interface IAlertModal {
	title: string;
	description: string;
	isVisible: boolean;
	setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
	onClose: () => void;
}

const AlertModal = (props: IAlertModal) => {
	return (
		<Modal open={props.isVisible} onClose={props.setIsVisible} className='sm:max-w-md'>
			{
				<div className='sm:mx-auto sm:w-full sm:max-w-md'>
					<div className='py-[28px] px-[60px] sm:rounded sm:px-10'>
						<div className='flex items-center justify-center' />
					
						<p className='text-base font-bold text-[#707070] text-center mt-[15px]'> 
							{props.title}
						</p>

						<p className='text-base text-[#707070] text-center mt-[15px]'> 
							{props.description}
						</p>
						
						<div className='bg- flex flex-row gap-[42px] justify-center mt-[25px]'>
							<Button variant='orange' className=' px-[42px] py-1.5' onClick={props.onClose}>Ok</Button>
						</div>
					</div>
				</div>
			}
		</Modal>
	);
};

export default AlertModal;