import z from 'zod';

export const GetPrivacyPolicySchema = z.object({
	description: z.string(),
	isTurnedOn: z.boolean(),
});


export const EditPrivacyPolicyByAppIdResponse = z.object({
	privacyPolicy: z.object({
		privacyPolicyID: z.number(),
		companyTenantID: z.string(),
		appTenantID: z.string(),
		appID: z.string(),
		description: z.string(),
		isTurnedOn: z.boolean(),
		status: z.string(),
		whoAdded: z.number(),
		whoUpdated: z.number(),
		whenAdded: z.string(),
		whenUpdated: z.string()
	})
});



export type GetPrivacyPolicyFormType = z.infer<typeof GetPrivacyPolicySchema>;
export type GetPrivacyPolicyrFormResponse = z.infer<typeof EditPrivacyPolicyByAppIdResponse>;