import { axiosInstance } from 'services/axios';
import { createAboutParams, createAboutResponse, createAboutSchema, editAboutParams, editAboutResponse, getAboutResponse } from './schema';

export const createAboutApi = async (id?: string, data?: createAboutParams) => {
	const validatedAboutForm = createAboutSchema.parse(data);
	const response = await axiosInstance.post(`/organizations/apps/${id}/about`, validatedAboutForm);
	return response.data as createAboutResponse;
};

export const getAboutApiLive = async (id?: string) => {
	const response = await axiosInstance.get(`/organizations/apps/${id}/about?env=LIV`);
	return response.data as getAboutResponse;
};


export const getAboutApi = async (id?: string) => {
	const response = await axiosInstance.get(`/organizations/apps/${id}/about`);
	return response.data as getAboutResponse;
};

export const editAboutApi = async (id?: string, aboutID?: number, param?: editAboutParams) => {
	const response = await axiosInstance.put(`/organizations/apps/${id}/about/${aboutID}`, param);
	return response.data as editAboutResponse;
};