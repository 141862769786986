import React, { useEffect, useRef, useState } from 'react';
import Modal from '../Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { classNames } from 'utility';
import { Button } from 'components/Common/Buttons';
import { ContentModal } from '../ContentModal';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { FContents } from 'store/CustomizeApp/Features/contentAtom';
import { SFeaturesTab } from 'store/CustomizeApp/Features';
import { stepperAtom } from 'store/CustomizeApp/Stepper';

interface IAlertModal {
	title?: string;
	description?: string;
	isVisible: boolean;
	setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
	onCreate: () => void;
	onCancel: () => void;
}

const AddPageModal = (props: IAlertModal) => {
	const [title, setTitle] 									= useState<string>('');
	const [icon, setIcon] 										= useState<string>('far fa-file-lines');
	const [error, setError] 									= useState<boolean>(false);
	const [showIconSelectionModal, setShowIconSelectionModal] = useState<boolean>(false);
	const [clicked, setClicked] 								= useState<boolean>(false);	

	const [contents, setContents] 								= useAtom(FContents);
	const currentStep											= useAtomValue(stepperAtom);
	const setFeatTab 											= useSetAtom(SFeaturesTab);
	
	const inputRef = useRef<HTMLInputElement>(null);

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {		
		const value = e.target.value;

		setTitle(value);

		if (value.length < 1) setError(true);
		else setError(false);
	};

	const handleOnBlur = () => {
		setTitle(title.trim());
	};

	const handleOnCreate = () => {
		setClicked(true);

		if (title.trim().length < 1) {
			if (inputRef.current) {
				inputRef.current.focus();
			} 

			setError(true);
		} else {
			const index = contents.findIndex((content) => content.toDelete === true);

			const updatedContents = [...contents];

			updatedContents.splice(index === -1 ? updatedContents.length : index, 0, {
				id: 0,
				icon: icon,
				title: title,
				description: '',
				toDelete: false,
			});

			setContents(updatedContents);
			(currentStep === 1) && setFeatTab(`Content[${index === -1 ? updatedContents.length - 1 : index}]`);
			props.onCreate();
		}
	};

	const handleShowIconSelectionModal = () => {
		setShowIconSelectionModal(!showIconSelectionModal);
	};

	const handleSelectIcon = (icon : string) => {
		setIcon(icon);
		setShowIconSelectionModal(!showIconSelectionModal);
	};

	useEffect(() => {
		if (props.isVisible) {
			setTitle('');
			setIcon('far fa-file-lines');
			setError(false);
			setClicked(false);
		}
	}, [props.isVisible]);

	return (
		<Modal open={props.isVisible} onClose={props.setIsVisible} className='w-[38.438rem] max-w-md md:max-w-2xl'>
			{
				<div className='sm:mx-auto sm:w-full max-w-2xl h-max rounded bg-slate-100'>
					<div className='relative flex flex-row justify-center items-center px-4 md:px-[4rem] py-5'>
						<button
							onClick={props.onCancel}
							className='absolute font-bold right-2 top-1'
						>
							<FontAwesomeIcon icon={faXmark} />
						</button>
						
						<h1 className='font-semibold text-base'>
							{props.title ? props.title : 'Create a page for any type of content'}
						</h1>
					</div>

					{
						props.description &&
						<div className='flex justify-center items-center w-full bg-white pt-4 text-slate-500 text-sm rounded'>
							<p className='text-base text-[#707070] text-center px-4 md:px-20 my-2'> 
								{props.description}
							</p>
						</div>
					}

					<div className={classNames(
						'flex flex-col justify-center items-center w-full bg-white px-16 text-slate-500 text-sm rounded',
						props.description ? 'pt-4 pb-10' : 'py-16'
					)}>
						<div className='w-[80%] max-w-[20rem]'>
							<p>Content Name<span className='text-red-600'>*</span></p>
						
							<input
								type='text'
								value={title}
								ref={inputRef}
								maxLength={20}
								pattern='[a-zA-Z\s&\d]'
								placeholder='E.g. Portfolio'
								onChange={handleOnChange}
								onBlur={() => handleOnBlur()}
								className={classNames(
									error ? 'border-2 border-red-500 outline-red-500' : 'border-slate-300 outline-primary',
									'text-normal text-[#464255] mt-2 p-2 w-full border-2 rounded'
								)}
							/>

							<p className={classNames(
								error ? 'visible' : 'invisible',
								'my-1 ml-0.5 text-xs text-red-500'
							)}>Content Name is Required.</p>
						</div>

						<div className='relative flex flex-row items-center gap-4 mt-2 mb-8 w-[80%] max-w-[20rem]'>
							<button
								onClick={() => handleShowIconSelectionModal()}
								className='relative flex justify-center items-center border border-slate-300 h-20 w-20 p-4 rounded-xl cursor-pointer hover:bg-gray-500'
							>
								<p className='absolute flex justify-center items-center text-center text-sm font-semibold leading-4 text-white opacity-0 hover:opacity-100'>Change Icon</p>

								<i className={`${icon} text-primary fa-2xl`}></i>
							</button>

							<p>Add Content Icon<span className='text-red-600'>*</span></p>
						</div>
						
						<div className={classNames(
							'bg- flex flex-row justify-center',
							props.description ? '' : 'mb-2'
						)}>
							<Button
								variant='orange'
								className='font-semibold px-10 py-2'
								onClick={handleOnCreate}
								disabled={title.trim().length < 1 || clicked}
							>
								Create Content
							</Button>
						</div>
					</div>
				</div>
			}

			<ContentModal isContentModalShow={showIconSelectionModal} setContentModalShow={handleShowIconSelectionModal} onClickCancel={handleShowIconSelectionModal} onClick={handleSelectIcon} />
		</Modal>
	);
};

export default AddPageModal;