import { axiosInstance } from 'services/axios';
import { GetFont, GetAllFont } from './schema';


export const getFontById = async (id: number) => {
	const response = await axiosInstance.get(`/organizations/apps/fonts/${id}`);
	return response.data as GetFont;
};

export const getAllFont = async () => {
	const response = await axiosInstance.get('/organizations/apps/font', {
		params: {
			sort: 'name asc',
			limit: 118			// ToDo: remove this after making the font dropdown lazy loaded
		}
	});
	return response.data as GetAllFont;
};