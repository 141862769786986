import React, { InputHTMLAttributes } from 'react';
import { useAtom } from 'jotai';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';

import { classNames } from '../../../../utility';
import {
	setGlobalVariableAtom,
	globalVariableAtom,
} from '../../../../store/authAtom';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { passwordRegex } from '../../../../utility';

interface IProps {
	type: string;
	name?: string;
	disabled?: boolean;
	value?: string;
	onChange?: (value: string) => void;
	placeholder?: string;
	className?: string;
	error?: string;
	onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
	criteria?: boolean;
	label?: string;
	label2?: string;
	iconPosition?: string;
	labelTextColor?: string;
	IconColor?: string;
	EyeSlashIconSize?: string;
}

interface IPropsValue {
	values: string;
	hidden2: boolean;
}

const FormInputPassword = (props: IProps) => {
	const [value, setValue] = React.useState(props.value || '');
	const [globalVariable, setGlobalVariable] = useAtom(globalVariableAtom);
	const [showPassword, setShowPassword] = React.useState(false);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValue(event.target.value);
		props.onChange && props.onChange(event.target.value);
		if (value.length < 1) {
			setGlobalVariable('initial value');
		}
	};

	React.useEffect(() => {
		setValue(props.value || '');
	}, [props.value]);

	const handleTogglePassword = () => {
		setShowPassword(!showPassword);
	};

	return (
		<>
			<label className='text-[#363636] text-xs font-semibold'>
				{props.label2}
			</label>
			<label className={`text-[#707070] text-xs ${props.labelTextColor}`}>
				{props.label}
			</label>
			<div className='relative shadow-none'>
				<input
					onFocus={props.onFocus}
					type={showPassword ? 'text' : props.type}
					name={props.name}
					value={value}
					placeholder={props.placeholder}
					onChange={handleChange}
					className={classNames(
						props.className ? props.className : '',
						props.error ? 'border-[#DC3545]' : 'border-gray-300 bg-white',
						'text-black w-full rounded-md border py-1.5 shadow-none',
						'pl-2 focus:ring-1 focus:border-input-active outline-none pr-10'
					)}
				></input>
				{props.type === 'password' && (
					<button
						type='button'
						onClick={handleTogglePassword}
						className={`absolute inset-y-0 right-0 px-2 py-1.5 ${props.iconPosition}`}
					>
						<>
							{showPassword ? (
								<EyeIcon className={`w-8 h-6 text-black ${props.IconColor}`} />
							) : (
								<EyeSlashIcon className={`w-8 h-6 text-black ${props.IconColor}`} />
							)}
						</>
					</button>
				)}
			</div>
			{props.criteria}
			{props.error && (
				<p className='text-[#DC3545] text-xs pl-1 py-2'>{props.error}</p>
			)}
			<PasswordCriteria
				hidden2={
					(props.name === 'credentials.0.value' ||
						(props.name === 'newPassword' &&
							location.pathname === '/reset-password')) &&
					props.criteria === true
						? false
						: true
				}
				values={value}
			/>
		</>
	);
};

const PasswordCriteria = (props: IPropsValue) => {
	const { hidden2, values } = props;

	return (
		<div
			hidden={hidden2}
			aria-hidden={true}
			className='text-gray-500 mt-2 text-sm'
		>
			<h3 className='font-semibold text-black text-xs ml-1'>
				Password must contain the following:
			</h3>
			<ul className='space-y-3 list-none mt-3' aria-live='assertive'>
				<div className='flex items-center ml-1'>
					<div className='w-6'>
						<CheckCircleIcon
							className={`${
								values.length < 8 ? 'hidden' : 'visible'
							} h-4 w-4 mr-2 text-green-700`}
						/>
						<XCircleIcon
							className={`${
								values.length >= 8 ? 'hidden' : 'visible'
							} h-4 w-4 mr-2 text-secondary`}
						/>
					</div>
					<li className='text-xs' aria-hidden={true}>
						Minimum of 8 characters.
					</li>
				</div>

				{passwordRegex.map((data, index) => {
					return (
						<div key={`_${index}`} className='flex items-center ml-1'>
							<div className='w-6'>
								<CheckCircleIcon
									className={`${
										!values.match(data.regex) ? 'hidden' : 'visible'
									} h-4 w-4 mr-2 text-green-700`}
								/>
								<XCircleIcon
									className={`${
										values.match(data.regex) ? 'hidden' : 'visible'
									} h-4 w-4 mr-2 text-secondary`}
								/>
							</div>
							<li className='text-xs' aria-hidden={true}>
								{data.description}
							</li>
						</div>
					);
				})}
			</ul>
		</div>
	);
};

export default FormInputPassword;
