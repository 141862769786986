import { atom } from 'jotai';
export interface IContent {
	id: number;
	icon: string;
	title: string;
	description: string;
	toDelete: boolean;
}

export const  FContents = atom<IContent[]>([]);

export const FContentError 				= atom<string>('');
export const FContentErrorDescription 	= atom<string>('');
export const FShowContentErrorModal 	= atom<boolean>(false);

export const FShowAddPageModal 			= atom<boolean>(false);

export const FContentsRefresh       	= atom<boolean>(true);