import { RGBColor } from 'react-color';
import { atom } from 'jotai';

export const SBBackgroundImage             = atom<any | null>(null);
export const SBBackgroundFile              = atom<any | null>(null);
export const SBBackgroundImageForHome      = atom<any | null>(null);
export const SBPreviewBackgroundImage      = atom<any | null>(null);
export const SBOpacityValue                = atom<RGBColor>({r: 0, g: 0, b: 0, a: 1});
export const SBBackgroundColor             = atom<string>('#fff');
export const SBOpacityBackgroundImage      = atom<number>(1);
export const SBBackgroundCategory          = atom<number>(1);
export const SBBlurBackgroundImage         = atom<any>('0');
export const SBDefaultBackgroundImageID    = atom<number>(0);
export const SBIsCustomBackgroundFocus     = atom<boolean>(false);
export const SBBackgroundRefresh           = atom<boolean>(true);
export const SBCustomBackgroundID          = atom<number>(0);