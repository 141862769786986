import { z } from 'zod';

export const GetFaviconSchema = z.object({
	favicon: z.object({
		faviconID:        z.number(),
		appID:            z.number(),
		imageUploadID:    z.number(),
		imageURL:         z.string(), 
		env:              z.string(), 
		status:           z.string(), 
		whoAdded:         z.number(),
		whoUpdated:       z.number(),
		whenAdded:        z.string(), 
		whenUpdated:      z.string(), 
	})
});

// export const GetAllFaviconSchema = z.object({
// 	rows: z.array(
// 		GetFaviconSchema
// 	)
// });

export const CreateEditFaviconImgUploadSchema = z.object({
	imageUploadID:    z.number().optional(),
});

export const UploadFaviconImageSchema = z.object({
	ImageUpload: z.object({
		imageUploadID:  z.number(),
		appID:          z.number(),
		name:           z.string(),
		imageURL:       z.string(),
		status:         z.string(),
		whoAdded:       z.number(),
		whoUpdated:     z.number(),
		whenAdded:      z.string(),
		whenUpdated:    z.string(),
	})
});

export const UploadFaviconImageParamsSchema = z.object({
	file: z.any()
});

export type CreateEditFaviconImgUploadType    = z.infer<typeof CreateEditFaviconImgUploadSchema>;

// export type GetAllFaviconResponse   		  = z.infer<typeof GetAllFaviconSchema>;
export type GetFaviconResponse   		      = z.infer<typeof GetFaviconSchema>;


export type UploadFaviconImageResponse  	  = z.infer<typeof UploadFaviconImageSchema>;
export type UploadFaviconImageParams    	  = z.infer<typeof UploadFaviconImageParamsSchema>;