import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { LoginResponseType, LoginType, loginSchema } from 'services/requests/auth/schema';
import { useSearchParams } from 'react-router-dom';
import { Button } from 'components/Common/Buttons';
import FormInput from 'components/Common/Forms/Inputs';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { authAtom, globalUserEmail, globalVariableAtom } from 'store/authAtom';
import { AxiosError } from 'axios';
import { linkAccount } from 'services/requests/auth';
import { useMutation } from 'react-query';

const LinkAccount: React.FC = () => {
	const [globalVariable, setGlobalVariable] = useAtom(globalVariableAtom);
	const email = useAtomValue(globalUserEmail);
	const [searchParams] = useSearchParams();
	const setAuth = useSetAtom(authAtom);
	const token = searchParams.get('token');

	const { control, handleSubmit, formState: { isValid } } = useForm<LoginType>({
		defaultValues: {},
		mode: 'onChange',
		resolver: zodResolver(loginSchema),
	});

	const { mutate: authorizeUser } = useMutation<LoginResponseType, AxiosError, LoginType>(
		(data) => linkAccount(data, token || ''),
		{
			onSuccess: (data) => {
				if (data.access_token) {
					setAuth(data);
					localStorage.removeItem('userData');
				}
			},
			onError: () => {
				setGlobalVariable('The email and/or password you entered is incorrect.');
			}
		}
	);

	const onSubmit = (data: LoginType) => {
		authorizeUser(data);
	};
    
	return (
		<div className='w-full h-[90vh] flex justify-center items-center bg-[#F6F6F6]'>
			<div className='w-[35%] p-6 pl-12 pr-12 bg-white rounded-xl'>
				<h2 className='text-center text-gray-600 text-lg font-bold'>Sign In</h2>
				
				<div className='text-sm leading-relaxed px-3 py-3 mb-5 border rounded-md bg-red-100 border-red-300 text-red-700 mt-1'>
                    User with email <span className='font-semibold'>{email}</span> already exists. You need to authenticate to link your account with google.
				</div>
				<form className='w-full' onSubmit={handleSubmit(onSubmit)}>
					<div className='mt-7'>
						<Controller
							control={control}
							name="username"
							defaultValue={email}
							render={({ field: {name, onChange }, fieldState: { error } }) => (
								<FormInput 
									label='Email Address' 
									name={name}
									onChange={onChange} 
									value={email}
									error={error?.message} 
									type='email' />
							)}
						/>
					</div>

					<div className='mt-3'>
						<Controller
							control={control}
							name="password"
							render={({ field: {name, onChange}, fieldState: { error } }) => (
								<FormInput 
									label='Password' 
									onChange={onChange} 
									name={name} 
									error={error?.message} 
									type='password'/>
							)}
						/>
					</div>

		
					<div className='mt-7'>
						<Button
							type='submit'
							variant='primary'
							className='w-full py-1.5'
							disabled={!isValid}>
							<span className='text-sm'>Sign In</span>
						</Button>
					</div>
					<div className='mt-3'>
						{globalVariable === 'The email and/or password you entered is incorrect.' &&
							<p className='flex justify-center items-center text-red-700 text-xs pl-1 py-2'>{globalVariable}</p>
						}
					</div>
				</form>
			</div>
		</div>);
};

export default LinkAccount;