import { SBackgroundColor, SBackgroundColorOptions, SBackgroundImage, SBlurBackgroundImage, SCustomBackgroundID, SCustomBgFocus, SFlashBackgroundID, SFlashLogoFile, SIcon, SIndustrySubCategory, SIsFlashRefresh, SIsSubCatFetched, SLogoID, SOpacityFlashBackgroundImage, SOverlayColor, SOverlayValue, SPreviewBackgroundImage } from 'store/CustomizeApp/Appearance/flashScreenAtom';
import { SFlashScreenIDLive, SIsAppearanceChanges, SIsFlashChanges, SisFlashDone } from 'store/CustomizeApp/Appearance';
import { getFlashScreenByAppID, getFlashScreenByAppIDLive } from 'services/requests/appearance/flash_screen';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { AxiosError } from 'axios';
import { GetAllWelcomeScreenByAppIDResponse } from 'services/requests/appearance/flash_screen/schema';
import { GetDefaultBackgroundsResponse } from 'services/requests/appearance/background/schema';
import { PIsPublishShowFlashScreen } from 'store/CustomizeApp/Publish/publishAtom';
import { getDefaultBackgrounds } from 'services/requests/appearance/background';
import rgbHex from 'rgb-hex';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { log } from 'utility/log';

export const FlashScreenUseQuery = () => {
	//Atoms for Flash Screen
	const [flashLogoID, setFlashLogoID]				                    = useAtom(SLogoID);
	const [flashCustomBgID, setFlashCustomBgID] 			            = useAtom(SCustomBackgroundID);
	const [flashBackgroundColor, setFlashBackgroundColor]     	        = useAtom(SBackgroundColor);
	const [flashBackgroundColorOptions, setFlashBackgroundColorOptions] = useAtom(SBackgroundColorOptions);
	const [flashOverlayColor, setFlashOverlayColor]                     = useAtom(SOverlayColor);
	const [flashOverlayOpacity, setFlashOverlayOpacity] 	  	        = useAtom(SOverlayValue);
	const [flashBackgroundImageOpacity, setFlashBackgroundImageOpacity] = useAtom(SOpacityFlashBackgroundImage);
	const [flashBackgroundImageBlur, setFlashBackgroundImageBlur]       = useAtom(SBlurBackgroundImage);
	const [flashBackgroundID, setFlashBackgroundID] 	      	        = useAtom(SFlashBackgroundID);
	const [flashLogoURL, setFlashLogoURL]		  		                = useAtom(SIcon);
	const [flashCustomBgFocus, setIsFlashCustomBgFocus] 	  	        = useAtom(SCustomBgFocus);
	const [ preview, setFlashPreviewBackground] 	                    = useAtom(SPreviewBackgroundImage);
	const [flashBackgroundImage, setFlashBackgroundImage] 		        = useAtom(SBackgroundImage);
	const [flashCategory, setCategory] 					                = useAtom(SIndustrySubCategory);
	const [ isFlashRefresh, setIsFlashRefresh]                          = useAtom(SIsFlashRefresh);
	const [subCategory, setSubCat]                                      = useAtom(SIndustrySubCategory);	
	const flashLogoFile													= useAtomValue(SFlashLogoFile);					
	const setIsChanges                                                  = useSetAtom(SIsAppearanceChanges);
	const setFlashChanges 												= useSetAtom(SIsFlashChanges);
	const {id}                                                          = useParams();
	const flashScreenIDLive 											= useAtomValue(SFlashScreenIDLive);
	const isSubCatFetched 												= useAtomValue(SIsSubCatFetched);

	const setIsPublishShow = useSetAtom(PIsPublishShowFlashScreen);
	const setFlashDone = useSetAtom(SisFlashDone);
    
	const {data: defaultBacgkrounds, isFetched: successPhotos} = useQuery<GetDefaultBackgroundsResponse, AxiosError>({
		queryKey: ['flashScreenbackgrounds'],
		queryFn: () => getDefaultBackgrounds({limit: 1000}),
	});

	//Flash Screen
	const {data: appFlashScreenLive, refetch: refetchLive} = useQuery<GetAllWelcomeScreenByAppIDResponse, AxiosError>({
		queryKey: ['flashScreenLive'],
		queryFn: () => getFlashScreenByAppIDLive(id),
		enabled: false,
	});


	//Flash Screen
	const {data: appFlashScreen, refetch: refecthFlashScreen, isFetched: successFlashScreen} = useQuery<GetAllWelcomeScreenByAppIDResponse, AxiosError>({
		queryKey: ['flashScreen'],
		queryFn: () => getFlashScreenByAppID(id),
		enabled: false,
		onSuccess: (data) => {
			if(data.rows){
				data?.rows?.map((flash) => {
					if(id && flash.appID === parseInt(id)){
						setFlashLogoID(flash.logoID);
						setFlashLogoURL(flash.logoURL);
						setFlashCustomBgID(flash.uploadedBackgroundID);
						setFlashBackgroundColor(flash.backgroundColor);
						setFlashBackgroundColorOptions(flash.backgroundColorOptions);
						setFlashOverlayColor(flash.overlayColor === '#000000' ? ('#' + rgbHex(0,0,0,0)) : flash.overlayColor);
						setFlashOverlayOpacity(flash.overlayOpacity ?? 0);
						setFlashBackgroundImageOpacity(flash.backgroundOpacity ?? 1);
						setFlashBackgroundImageBlur(flash.blur ?? 0);
						setFlashBackgroundID(flash.defaultBackgroundID ?? 0);
						setFlashBackgroundImage(flash.imageURL);
						if(flash.imageURL) {
							if(flash.uploadedBackgroundID){
								setFlashPreviewBackground(flash.imageURL); setIsFlashCustomBgFocus(true);
							}else{
								setIsFlashCustomBgFocus(false); 
								setFlashPreviewBackground(null);}
						}else{
							setIsFlashCustomBgFocus(false); 
							setFlashPreviewBackground(null);}
						defaultBacgkrounds?.rows.map((dbg) => {
							if(dbg.defaultBackgroundID === flash.defaultBackgroundID){
								setCategory(dbg.subcategoryID);
							}
						});
					}
				});	
				if(subCategory) setSubCat(subCategory);
				setIsFlashRefresh(false);
				setFlashDone(true);
			}
		}
	});
    
	useEffect(() =>{
		if(isFlashRefresh){
			refecthFlashScreen();
			refetchLive();
		}

	}, [isFlashRefresh]);

	useEffect(() => {
		if(appFlashScreen && successFlashScreen && successPhotos && isSubCatFetched){
			appFlashScreen?.rows?.map((flash) => {
				if(flash.backgroundColor &&  flash.backgroundColor !== flashBackgroundColor){
					setIsChanges(true);	
					setFlashChanges(true);
					log('flash 1');
				}else if(flash.backgroundColorOptions !== flashBackgroundColorOptions){
					setIsChanges(true);	
					setFlashChanges(true);
					log('flash 2');
				}else if(flash.overlayColor && flash.overlayColor.toUpperCase() !== flashOverlayColor.toUpperCase()){
					setIsChanges(true);	
					setFlashChanges(true);
					log('flash 3', flash.overlayColor, flashOverlayColor.toUpperCase());
				}else if(flash.defaultBackgroundID && flash.defaultBackgroundID !== flashBackgroundID){
					setIsChanges(true);	
					setFlashChanges(true);
					log('flash 4');
				}else if(flash.defaultBackgroundID === undefined && flashBackgroundID !== 0){
					setIsChanges(true);	
					setFlashChanges(true);
					log('flash 5');
				}else if(flash.blur ? flash.blur !== flashBackgroundImageBlur : 0 !== flashBackgroundImageBlur){
					setIsChanges(true);	
					setFlashChanges(true);
					log('flash 6');
				}else if(flash.backgroundOpacity && flash.backgroundOpacity !== flashBackgroundImageOpacity){
					setIsChanges(true);	
					setFlashChanges(true);
					log('flash 7');
				}else if(flash.uploadedBackgroundID && flash.uploadedBackgroundID !== flashCustomBgID){
					setIsChanges(true);	
					setFlashChanges(true);
					log('flash 8');
				}else if(flash.uploadedBackgroundID && !flashCustomBgFocus){
					setIsChanges(true);	
					setFlashChanges(true);
					log('flash 9');
				}else if(flash.uploadedBackgroundID === undefined && flashCustomBgFocus){
					setIsChanges(true);	
					setFlashChanges(true);
					log('flash 10');
				}else if(flash.logoURL && flash.logoURL !== flashLogoURL){
					setIsChanges(true);	
					setFlashChanges(true);
					log('flash 11');
				}else if(flash.logoID === undefined && flashLogoFile !== null){
					setIsChanges(true);	
					setFlashChanges(true);
					log('flash 12');
				}else {setIsChanges(false); setFlashChanges(false);}

			});
		}
		
	},[flashBackgroundColor, flashBackgroundColorOptions, flashBackgroundImageOpacity, flashBackgroundImageBlur, flashBackgroundID, flashBackgroundImage, 
		flashLogoID, flashLogoURL, flashOverlayColor, flashOverlayOpacity, flashCustomBgID, appFlashScreen, successFlashScreen, successPhotos, isSubCatFetched]);

	useEffect(() => {
		if(appFlashScreenLive?.rows && appFlashScreen?.rows){
			if(appFlashScreenLive?.rows[0].backgroundColor && (appFlashScreenLive?.rows[0].backgroundColor !== appFlashScreen?.rows[0].backgroundColor) || 
				appFlashScreenLive?.rows[0].backgroundOpacity !== appFlashScreen?.rows[0].backgroundOpacity ||
				appFlashScreen?.rows[0].blur && (appFlashScreenLive?.rows[0].blur !== appFlashScreen?.rows[0].blur) || 
				appFlashScreenLive?.rows[0].defaultBackgroundID && (appFlashScreenLive?.rows[0].defaultBackgroundID !== appFlashScreen?.rows[0].defaultBackgroundID) || 
				appFlashScreenLive?.rows[0].logoURL && (appFlashScreenLive?.rows[0].logoURL !== appFlashScreen?.rows[0].logoURL) || 
				appFlashScreenLive?.rows[0].overlayColor !== appFlashScreen?.rows[0].overlayColor ||
				appFlashScreenLive?.rows[0].overlayOpacity !== appFlashScreen?.rows[0].overlayOpacity){
				setIsPublishShow(true);
			}else {setIsPublishShow(false);}
		}

	},[appFlashScreenLive, appFlashScreen]);
};
