import React, { useEffect, useLayoutEffect } from 'react';
import ContactUsForm from 'components/ContactUsForm';
import { GetInTouch } from 'components/GetInTouch';


const ContactUs: React.FC =() => {

	useLayoutEffect(() => {
		document.body.style.backgroundColor = '#f6f6f6';
	});
	const smStyle = 'max-sm:w-[100%] max-sm:flex max-sm:flex-col-reverse max-sm:justify-center max-sm:items-center';
	const mdStyle = 'md:flex md:flex-col-reverse md:justify-center md:items-center';
	const lgStyle = 'lg:flex lg:flex-row lg:justify-center lg:items-center';
	const smStyle2 = 'min-sm:w-[100%] min-sm:flex min-sm:flex-col-reverse min-sm:justify-center min-sm:items-center';
	const smStyle3 = 'sm:w-[100%] sm:flex sm:flex-col-reverse sm:justify-center sm:items-center';

	useEffect(() => {
		window.scrollTo(0,0);
	},[]);

	return (
		<div className={'w-full h-full 2xl:h-[110vh] xl:h-[130vh] lg:h-[130vh]'}>
			<div className={`max-2xl:flex ${lgStyle} ${mdStyle} ${smStyle2} ${smStyle3} ${smStyle}`}>
				<div className='w-full h-full 2xl:h-[110vh] xl:h-[130vh] lg:h-[130vh] max-sm:mt-8'>
					<GetInTouch />
				</div>
				<div className='w-full h-full 2xl:h-[110vh] xl:h-[130vh] lg:h-[130vh] xl:mr-[100px]'>
					<ContactUsForm/>
				</div>
			</div>
		</div>
	);

};

export default ContactUs;