import { EditApperanceByAppIdParamsSchema, EditApperanceByAppIdResponse, GetAppearanceByAppIdParamsSchema, GetAppearanceByAppIdResponse } from './schema';

import { axiosInstance } from 'services/axios';

export const getAppearanceByAppIDLive = async (appID?: string | number, param?: GetAppearanceByAppIdParamsSchema) => {
	const response = await axiosInstance.get(`/organizations/apps/${appID}/appearance?env=LIV`, {params: param});
    
	return response.data as GetAppearanceByAppIdResponse;
};

export const getAppearanceByAppID = async (appID?: string, param?: GetAppearanceByAppIdParamsSchema) => {
	const response = await axiosInstance.get(`/organizations/apps/${appID}/appearance`, {params: param});
    
	return response.data as GetAppearanceByAppIdResponse;
};

export const editAppearanceByAppID = async (appID?: string, appearanceID?: number, param?: EditApperanceByAppIdParamsSchema) => {
	const response = await axiosInstance.put(`/organizations/apps/${appID}/appearance/${appearanceID}`, param);
    
	return response.data as EditApperanceByAppIdResponse;
};