import { axiosInstance } from 'services/axios';
import * as types from './schema';

export const addMoreSocialAction = async (id?: string, params?: types.addMoreSocialType) => {
	const response = await axiosInstance.post(`/organizations/apps/${id}/socialmedia`, {
		icon: params?.icon,
		label:params?.label,
		WebsiteLink: params?.WebsiteLink,
		sortOrder: params?.sortOrder
	});
	return response.data;
};

export const updateSocialAction = async (params?: types.addMoreSocialType) => {
	const response = await axiosInstance.put(`/organizations/apps/${params?.appId}/socialmedia/${params?.socialMediaID}`, {
		icon: params?.icon,
		label:params?.label,
		WebsiteLink: params?.WebsiteLink,
		sortOrder: params?.sortOrder
	});
	return response.data;
};

export const getAllSocialActionLive = async (id?: number) => {
	const response = await axiosInstance.get(`/organizations/apps/${id}/socialmedia?env=LIV`);
	return response.data;
};

export const getAllSocialAction = async (id?: number) => {
	const response = await axiosInstance.get(`/organizations/apps/${id}/socialmedia`);
	return response.data;
};

export const deleteSocialAction = async (id?: string, socialMediaID?: string) => {
	const response = await axiosInstance.delete(`/organizations/apps/${id}/socialmedia/${socialMediaID}`);
	return response.data;
};