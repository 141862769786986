import z from 'zod';


export const SubscriptionDetailsSchema = z.object({
	price: z.object({
		id: 			   z.string(),
		object:    		   z.string(),
		active: 		   z.boolean(),
		billingScheme:     z.string(),
		created: 		   z.number(),
		currency:  		   z.string(),
		nickname:  		   z.string(),
		productId:  	   z.string(),
		type:  		       z.string(),
		unitAmount:  	   z.number(),
		unitAmountDecimal: z.number(),
	})
});

export const SubscriptionSchemaPayload = z.object({
	appId:           z.union([z.string(), z.undefined()]).optional(),
	priceId:         z.string(),
	paymentMethodId: z.string(),
	promoCode: 			 z.string(),
});

export const SubscriptionByIdSchema = z.object({
	SubscriptionId: z.string(),
	PriceId:        z.string(),
});

export const SubscriptionResponseSchema = z.object({
	id: 				  z.string(),
	object:     		  z.string(),
	customer: 			  z.string(),
	currency:             z.string(),
	currentPeriodStart:   z.number(),
	currentPeriodEnd:     z.number(),
	defaultPaymentMethod: z.string(),
	defaultSource:        z.string(),
	items:			      z.array(SubscriptionDetailsSchema),
});

export type GetSubscriptionByIdType = z.infer<typeof SubscriptionByIdSchema>;

export type SubscriptionParams = z.infer<typeof SubscriptionSchemaPayload>;

export type GetSubscriptionByIdResponse = z.infer<typeof SubscriptionByIdSchema>;
export type SubscriptionResponse = z.infer<typeof SubscriptionResponseSchema>;