import {
	ArrowRightOnRectangleIcon,
	UserCircleIcon
} from '@heroicons/react/24/outline';
import { Link, NavLink, useParams } from 'react-router-dom';
import React, { useState } from 'react';
import { useAtom, useSetAtom } from 'jotai';
import { globalVariableAtom } from 'store/authAtom';
import { useLocation, useNavigate } from 'react-router-dom';

import Button from '../Buttons/Button';
import { IMAGES } from 'utility/constant';
import NavBarModal from './NavBarModal';
import UserTopbar from './User-Topbar';
import { classNames } from '../../../utility/common';
import { SAppNameChanges, SIsAppearanceChanges, SIsBackgroundChanges, SIsColorChanges, SIsFaviconChanges, SIsFontChanges, SIsLayoutChanges, SIsNavigationChanges, SIsOgTagsChanges, SIsSideBarChanges, SIsThemeChanges, SIsUnsavedModalOpen } from 'store/CustomizeApp/Appearance';
import { FIsAboutChanges, FIsAccessChanges, FIsContactChanges, FIsContentChanges, FIsFeaturesChange, FIsGalleryChanges, FIsHelpChanges, FIsHomeChanges, FIsNotifChanges, FIsOffersChanges, FIsPrivacyChanges, FIsSocialMediaChanges, FIsStoreChanges, FIsTermsChanges } from 'store/CustomizeApp/Features';
import { LeaveSectionModal } from '../Modals/LeaveSectionModal';

interface TopbarProps {
	isLogin: boolean;
}


const links = [
	{ title: 'Pricing', links: '/pricing' },
	{ title: 'Contact Us', links: '/contact-us' },
	{ title: 'Become an Affiliate', links: '/become-an-affiliate' },
	{ title: 'Let us Build Your App', links: '/build' },
];

const linksSignedIn = [
	{ title: 'Dashboard', links: '/dashboard' },
	// { title: 'Help', links: '/help' },
	{ title: 'Become an Affiliate', links: '/signed/become-an-affiliate'},
	{ title: 'Let us Build Your App', links: '/signed/build'},
];

const Topbar: React.FC<TopbarProps> = ({ isLogin }) => {
	const [isSignedIn, setIsSignedIn] = useState(false);
	const [isShow, setIsShow] = useState(false);
	const [open, setShowUnsavedModal] = useState(false);
	const [isThereAppearanceChanges, setAppearanceChanges] = useAtom(SIsAppearanceChanges);
	const [isThereFeaturesChanges, setFeatureChanges] = useAtom(FIsFeaturesChange);
	const [nav, setNav] = useState('');

	const handleHamburgerClick = () => {
		setIsShow(!isShow);
	};
	const [globalVariable, setGlobalVariable] = useAtom(globalVariableAtom);

	const navigate = useNavigate();
	const {id} = useParams();

	const location = useLocation();

	const activeLink =
		'relative after:content-[""] after:h-[1px] after:w-full hover:after:bg-primary after:absolute after:left-0 after:bottom-[-3px] after:pointer-events-none after:opacity-1';

	const normalLink =
		'relative after:content-[""] after:h-[1px] after:w-full hover:after:bg-primary hover:after:absolute after:left-0 after:bottom-[-3px] after:transition-all after:duration-400 after:pointer-events-none after:opacity-1';

	React.useEffect(() => {
		if (!isSignedIn) {
			setGlobalVariable('initial value');
		}
	}, [isSignedIn]);

	const handleClick = () => {
		navigate('/login');
	};

	const [isAppNameChanges, setAppChanges] = useAtom(SAppNameChanges);
	const setFlashScreenChanges = useSetAtom(SIsAppearanceChanges);
	const setLayoutChanges = useSetAtom(SIsLayoutChanges);
	const setThemeChanges = useSetAtom(SIsThemeChanges);
	const setBackgroundChanges = useSetAtom(SIsBackgroundChanges);
	const setNavChanges = useSetAtom(SIsNavigationChanges);
	const setSidebarChanges = useSetAtom(SIsSideBarChanges);
	const setColorChanges = useSetAtom(SIsColorChanges);
	const setFontChanges = useSetAtom(SIsFontChanges);
	const setFaviconChanges = useSetAtom(SIsFaviconChanges);
	const setOgTagsChanges = useSetAtom(SIsOgTagsChanges);

	const setHomeChanges = useSetAtom(FIsHomeChanges);
	const setStoreChanges = useSetAtom(FIsStoreChanges);
	const setAboutChanges = useSetAtom(FIsAboutChanges);
	const setContentChanges = useSetAtom(FIsContentChanges);
	const setPrivacyChanges = useSetAtom(FIsPrivacyChanges);
	const setTermsChanges = useSetAtom(FIsTermsChanges);
	const setAccessChanges = useSetAtom(FIsAccessChanges);
	const setContactChanges = useSetAtom(FIsContactChanges);
	const setGalleryChanges = useSetAtom(FIsGalleryChanges);
	const setHelpChanges = useSetAtom(FIsHelpChanges);
	const setOfferChanges = useSetAtom(FIsOffersChanges);
	const setNotifChanges = useSetAtom(FIsNotifChanges);
	const setSocMedChanges = useSetAtom(FIsSocialMediaChanges);

	const resetChanges = () => {
		setAppChanges(false);
		setFlashScreenChanges(false);
		setLayoutChanges(false);
		setThemeChanges(false);
		setBackgroundChanges(false);
		setNavChanges(false);
		setSidebarChanges(false);
		setColorChanges(false);
		setFontChanges(false);
		setFaviconChanges(false);
		setOgTagsChanges(false);

		setHomeChanges(false);
		setStoreChanges(false);
		setAboutChanges(false);
		setContentChanges(false);
		setPrivacyChanges(false);
		setTermsChanges(false);
		setAccessChanges(false);
		setContactChanges(false);
		setGalleryChanges(false);
		setHelpChanges(false);
		setOfferChanges(false);
		setNotifChanges(false);
		setSocMedChanges(false);
	};

	const options = [
		{
			value: 'MyAccount',
			icon: <UserCircleIcon className='w-6 h-6' />,
			label: 'My Account',
		},
		// {
		// 	value: 'Subscription',
		// 	icon: <WalletIcon className='w-6 h-6' />,
		// 	label: 'Subscriptions',
		// },
		{
			value: 'Logout',
			icon: <ArrowRightOnRectangleIcon className='w-6 h-6' />,
			label: 'Log Out',
		},
	];

	const handleSelect = (value: string) => {
		if (value === 'Logout') {
			if(isThereAppearanceChanges || isThereFeaturesChanges || isAppNameChanges){
				setShowUnsavedModal(true);
				setNav('Logout');
			}else{
				localStorage.removeItem('token');
				localStorage.removeItem('editFirstName');
				localStorage.removeItem('editLastName');
				localStorage.removeItem('bearerToken');
				localStorage.removeItem('userData');
				localStorage.removeItem('email');
				localStorage.removeItem('federatedIdentity');

				setTimeout(() => {
					window.location.reload();
				}, 100);
			}
		} else if (value === 'MyAccount') {
			if(isThereAppearanceChanges || isThereFeaturesChanges || isAppNameChanges){
				setShowUnsavedModal(true);
				setNav('Profile');
			}else navigate('/edit-profile');
		} else {
			navigate('/subscription');
		}
	};

	const handleBack = () => {
		setGlobalVariable('initial value');
		if(isThereAppearanceChanges || isThereFeaturesChanges || isAppNameChanges){
			setShowUnsavedModal(true);
			setNav('Dashboard');
		}

	};

	const onClickStay = () => {
		setShowUnsavedModal(false);
	};

	const onClickLeave = () =>{
		setShowUnsavedModal(false);
		setAppearanceChanges(false);
		setFeatureChanges(false);
		resetChanges();
		if(nav === 'Dashboard') navigate('/dashboard');
		if(nav === 'Become an Affiliate') navigate('/signed/become-an-affiliate');
		if(nav === 'Let us Build Your App') navigate('/signed/build');
		if(nav === 'Profile') navigate('/edit-profile');
		if(nav === 'Logout'){
			localStorage.removeItem('token');
			localStorage.removeItem('editFirstName');
			localStorage.removeItem('editLastName');
			localStorage.removeItem('bearerToken');
			localStorage.removeItem('userData');
			localStorage.removeItem('email');

			setTimeout(() => {
				window.location.reload();
			}, 100);
		}
		setNav('');
	};
	// Renders Snap Logo only on Topbar add path here
	const excludeDashboardPaths = [
		'/Reset-Password-Email',
		'/ResetYourPassword',
		'/VerifyItsYou',
	];
	
	return (
		<>
			<nav className={classNames(location.pathname == '/verify-email' ? 'hidden' : '', 'w-full max-sm:w-[100%] bg-white shadow-md lg:z-20 relative top-0')}>
				<div className='md:py-[11px] sm:py-[6px] py-[15px] md:px-[54px] sm:px-[30px] px-[20px] flex flex-row items-center w-full'>
					<span>
						<Link
							id='nav-link'
							to={isLogin && (isThereAppearanceChanges || isThereFeaturesChanges || isAppNameChanges) ? `/edit/${id}` : '/dashboard'}
							onClick={handleBack}
						>
							<img
								src={IMAGES.SnapToAppLogo}
								alt='snapLogo'
								draggable='false'
								className='sm:w-[214px] w-[152px] sm:h-[60px] h-[42px] duration-500' />
						</Link>
					</span>

					{!excludeDashboardPaths.includes(location.pathname) &&
					<ul className='flex list-none items-center font-bold gap-8 ml-auto justify-end'>
						{!isLogin ? (
							links.map((link, key) => [
								'/login',
								'/approver/login',
								'/thankyou-signup',
								'/signup',
								'/forgot-email',
								'/forgot-request-email',
								'/reset-password',
								'/success-forgot-password',
								'/login-actions/google-sign-in'
							].includes(location.pathname) ? null : (
									<li key={key} className='lg:block hidden '>
										<NavLink
											id='nav-link'
											className={({ isActive }: { isActive: boolean; }) => isActive ? activeLink : normalLink}
											to={link.links}
										>
											{link.title}
										</NavLink>
									</li>
								)
							)
						) : (
							<ul className='flex flex-row items-center gap-8'>
								{linksSignedIn.map((link, key) => (
									<li key={key} className='lg:block hidden'>
										<NavLink
											id='nav-link'
											className={({ isActive }: { isActive: boolean; }) => isActive ? activeLink : normalLink}
											// className={classNames(nav === link.title ? activeLink : normalLink)}
											to={isThereAppearanceChanges || isThereFeaturesChanges || isAppNameChanges ? `/edit/${id}` : link.links }
											onClick={() => {
												if(isThereAppearanceChanges || isThereFeaturesChanges || isAppNameChanges){
													setShowUnsavedModal(true);
													setNav(link.title);
												}
											}}
										>
											{link.title}
										</NavLink>
									</li>
								))}
								<div onClick={() => setIsShow(true)}>
									<img
										className='cursor-pointer w-[30px] h-[30px] overflow-y-scroll ml-[30px] block lg:hidden'
										src={IMAGES.userTopBarHamburgerIcon} />
								</div>
								{isShow ? (
									<NavBarModal
										isShow={isShow}
										isLogin={isLogin}
										onHamburgerClick={handleHamburgerClick} />
								) : (
									''
								)}
							</ul>
						)}					
						{!isLogin ? (
							[
								'/login',
								'/approver/login',
								'/thankyou-signup',
								'/signup',
								'/forgot-email',
								'/forgot-request-email',
								'/reset-password',
								'/success-forgot-password',
								'/login-actions/google-sign-in'
							].includes(location.pathname) ? null : (
									<div className='flex flex-row items-center'>
										<Button
											className='px-[30px] md:px-[46px] py-[6px] md:py-[10px] sm:visible invisible bg-[#E0781D] hover:bg-[#FF491A]'
											variant='primary'
											onClick={handleClick}
										>
									Sign In
										</Button>
										<div onClick={() => setIsShow(true)}>
											<img
												className='w-[30px] h-[30px] overflow-y-scroll ml-[30px] block lg:hidden'
												src={IMAGES.userTopBarHamburgerIcon} />
										</div>
										{isShow ? (
											<NavBarModal isShow={isShow} onHamburgerClick={handleHamburgerClick} />
										) : (
											''
										)}
									</div>
								)
						) : (
							<div className='lg:block hidden'>
								<UserTopbar options={options} onSelect={handleSelect} />
							</div>
						)}
					</ul>
					}			
				</div>
			</nav>
			<LeaveSectionModal 
				isLeaveSectionModalShow={open}
				setLeaveSectioShow={setShowUnsavedModal}
				onClickLeave={() => onClickLeave()}
				onClickStay={() => onClickStay()} />
		</>
	);
};

export default Topbar;
