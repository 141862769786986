import Button from 'components/Common/Buttons/Button';
import { IMAGES } from 'utility/constant';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const EmptyDashboard = () => {
	const navigate = useNavigate();
	return (
		<div className='flex flex-col flex items-center justify-center max-sm:m-[20%] max-sm:mt-[30%] ' style={{ minHeight: 'calc(100vh - 82px)' }}>
			<div className='flex flex-col items-center text-[#46638C] font-semibold'>
				<img
					className='-mt-40'
					src={IMAGES.EmptyDashboard}
					alt='plus circle icon'
				/>
				<span className='text-[22px] mt-[8px]'>
					You Haven&apos;t Created an App Yet
				</span>
				<span className='text-[22px] mt-[20px] text-base'>
					Create a new app by clicking the button below.
				</span>
				<Button
					variant='primary'
					className='flex w-[200px] h-[56px] mt-[28px] relative text-[14px] rounded-[9px] justify-center items-center bg-[#E0781D]'
					onClick={() => navigate('/SelectIndustry')}
				>
					<img
						className='w-[30px] h-[30px] mr-[10px]'
						src={IMAGES.PlusCircleIcon}
						alt='plus circle icon'
					/>
					<span>Create New App</span>
				</Button>
			</div>
		</div>
	);
};

export default EmptyDashboard;
