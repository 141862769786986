import { atom } from 'jotai';

export const  SNavigation = atom ([
	{ id: 0, label: 'Home', isHidden: false},
	{ id: 0, label: 'Cart', isHidden: false},
	{ id: 0, label: 'About', isHidden: false},
	{ id: 0, label: 'Content', isHidden: false},
]);

export const  STempNavigation = atom ([
	{ id: 0, label: 'Home', isHidden: false},
	{ id: 0, label: 'Cart', isHidden: false},
	{ id: 0, label: 'About', isHidden: false},
	{ id: 0, label: 'Content', isHidden: false},
]);

export const SIsNavigationTextCheck = atom<boolean>(false);
export const SIsNavRefresh          = atom<boolean>(true);
export const SNavHiddenCount		= atom<number>(0);