import React from 'react';

type ApproverSideBarProps = {
  selectedOption: string;
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>;
  items: { title: string; icon: string | React.FunctionComponent<React.SVGProps<SVGSVGElement>>; link: string }[];
};

const ApproverSideBar: React.FC<ApproverSideBarProps> = ({ items, selectedOption, setSelectedOption }) => {
	return (
		<div className="w-1/4 h-[90vh] flex flex-col items-start shadow-xl z-20">
			<div className="mt-7 w-full">
				{items.map((item, index) => (
					<button
						onClick={() => {
							setSelectedOption(item.link);
						}}
						className={`w-full p-4 flex px-7 ${
							item.link === selectedOption
								? 'bg-gray-200 font-bold text-blue-700 border-l-4 border-blue-500'
								: 'focus:bg-[#DAE3F7] focus:font-bold focus:text-[#4663AC] focus:border-l-4 focus:border-[#3C5491]'
						}`}
						key={index}
					>
						<div className="flex">
							<item.icon />
							<span className="ml-4">{item.title}</span>
						</div>
					</button>
				))}
			</div>
		</div>
	);
};

export default ApproverSideBar;
