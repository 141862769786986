import { axiosInstance } from 'services/axios';
import { CreateHomeParams, CreateHomeResponse, CreateEditHomeSchema, EditHomeParams, EditHomeResponse, GetHomeResponse, UploadHomeImageResponse } from './schema';

export const createHomeApi = async (id?: string, data?: CreateHomeParams) => {
	const validatedHomeForm = CreateEditHomeSchema.parse(data);
	const response = await axiosInstance.post(`/organizations/apps/${id}/home`, validatedHomeForm);
	return response.data as CreateHomeResponse;
};

export const getHomeApiLive = async (id?: string) => {
	const response = await axiosInstance.get(`/organizations/apps/${(id)}/home?env=LIV`);
	return response.data as GetHomeResponse;
};

export const getHomeApi = async (id?: string) => {
	const response = await axiosInstance.get(`/organizations/apps/${id}/home`);
	return response.data as GetHomeResponse;
};

export const editHomeApi = async (id?: string, homeID?: number, param?: EditHomeParams) => {
	const response = await axiosInstance.put(`/organizations/apps/${id}/home/${homeID}`, param);
	return response.data as EditHomeResponse;
};

export const uploadHomeImageApi = async (id?: string, file?: any) => {
	const formData = new FormData();
	formData.append('image', file);
	const response = await axiosInstance.post(`/organizations/apps/${id}/imageUploads`, formData, {
		headers: {
			'Content-type': 'multipart/form-data'
		}});
    
	return response.data as UploadHomeImageResponse;
};