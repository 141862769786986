import React, { useEffect, useRef, useState } from 'react';

import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { classNames } from '../../../utility';

interface IProps {
  title: React.ReactNode;
  content: React.ReactNode;
  isDisabled?: boolean;
  onClick?: (active: boolean) => void;
  isInitialOpen?: boolean;
  activeMaxHeight?: string; 
}

const Accordion: React.FC<IProps> = ({ title, content, isDisabled, onClick, isInitialOpen, activeMaxHeight }) => {
	const [active, setActive] = useState(isInitialOpen);

	const contentSpace = useRef<HTMLDivElement>(null);

	useEffect(() => {
		setActive(isInitialOpen);

		if (isInitialOpen && onClick) {
			return onClick(true);
		}
	}, [isInitialOpen]);

	const onToggleAccordion = () => {
		if (isDisabled) return;
		if (onClick) onClick(!active);
		setActive((prevState) => !prevState);
	};
  
	return (
		// <div className="flex flex-col border-2 border-custom-gray-100 rounded-lg mb-5 max-sm:w-[70%]">
		<div className={classNames(active ? 'flex flex-col border-2 border-custom-gray-100 rounded-lg mb-5' : 'flex flex-col border-2 border-custom-gray-100 rounded-lg mb-5 max-sm:w-full')}>
			<button
				type="button"
				className="p-4 box-border appearance-none cursor-pointer focus:outline-none flex items-center bg-custom-gray-100"
				onClick={onToggleAccordion}
			>
				<div className="flex justify-between items-center w-full">
					{title}
					<ChevronRightIcon 
						className={classNames(
							'text-gray-500 h-5 transform duration-700 ease',
							active ? 'rotate-90' : ''
						)}
					/>
				</div>
			</button>
			<div
				ref={contentSpace}
				style={{maxHeight: active ? (activeMaxHeight || `${contentSpace?.current?.scrollHeight}px`) : '0px'}}
				className="rounded-md overflow-auto transition-max-height duration-700 ease-in-out no-scrollbar"
			>
				{content}
			</div>
		</div>
	);
};

export default Accordion;