/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useRef } from 'react';
import { ArrowUpTrayIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { IMAGES, classNames } from 'utility';
import { showFailedAlert } from 'utility/alerts';
import { useAtom } from 'jotai';
import { SFaviconImgID, SFaviconPreviewImage, SFaviconUploadFile } from 'store/CustomizeApp/Appearance/imagesAtoms/favicon';

const Favicon:React.FC = () => {

	const faviconInput 		  					 = useRef<HTMLInputElement>(null);
	const imageTypeRegex      				     = /image\/(png|jpg|jpeg)/gm;
	const [previewFavicon, setPreviewFavicon]    = useAtom(SFaviconPreviewImage);
	const [, setImgID] 							 = useAtom(SFaviconImgID);
	const [, setImageFile]           			 = useAtom(SFaviconUploadFile);

	const onClickUploadFaviconHandler = () => {
		faviconInput.current?.click();
	};

	const handleSelectFavicon = (event: any) => {
		const file = event.target.files[0];
		const fileReader = new FileReader();
	
		fileReader.onload = (event : any) => {
			const image : any = new Image();
			image.src = event.target.result;
		
			if (file.type.match(imageTypeRegex)) {
				image.onload = () => {
					if (file.size <= (1048576 * 10)) {
						setImageFile(file);
						setPreviewFavicon(fileReader.result);
					} else if (file.size > (1048576 * 10)) {
						showFailedAlert('Please upload an image file that is less than 10MB in size.');
					}
				};
			} else{
				showFailedAlert('File must be JPEG or PNG.');
			}
		};
		fileReader.readAsDataURL(file);
	};
    
	return(
		<div className='flex flex-col mb-6'>
			<span className='text-[#707070] font-bold opacity-80 mb-3 text-lg'>Favicon</span>
			{/* <div className='flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row gap-10 sm:flex-col max-sm:flex-col'> */}
			<div className='grid md:grid-flow-col grid-flow-row gap-5'>
				<div className='flex'>
					<div 
						onClick={onClickUploadFaviconHandler}
						className={classNames('w-[160px] h-[150px] rounded-sm border bg-[#F5F5F5] cursor-pointer flex items-center justify-center p-1')}>
						<input ref={faviconInput} type='file' className='h-0 absolute hidden' accept='.jpg, .jpeg, .png' onChange={(e) => handleSelectFavicon(e)} onClick={(event)=> { 
							event.currentTarget.value = '';
						}}/>
						{
							previewFavicon ?
								<div className='flex'>
									<img src={previewFavicon} alt='favicon' className='w-[160px] h-[150px] object-contain'/>
								</div>
								:
								<div className='flex justify-center'>
									<div className='flex-col flex gap-2'>
										<div className=' flex justify-center'>
											<ArrowUpTrayIcon className='w-8 h-8 text-primary'/> 
										</div>
										<span className='text-primary text-xs block text-center'>Upload Favicon</span>
									</div>
								</div>
						}
					</div>
					{previewFavicon && 
                        <div 
                        	className='rounded-full text-[#D9D7D7] bg-white border-2 h-7 w-7 flex items-center justify-center cursor-pointer -ml-4 -mt-3' 
                        	onClick={() => {
                        		setPreviewFavicon(null); 
                        		setImgID(0); 
                        		setImageFile(null);}}>
                        	<XMarkIcon className='w-5 h-5 cursor-pointer hover:text-primary'/>
                        </div>
					}
				</div>								
				<div className='flex flex-col gap-2'>
					<p className='text-[#464255] opacity-80 text-sm lg:text-base'>
						Favicon is a small graphic icon that represents a website. It is typically displayed in the browser’s address bar, next to the page title in the browser tabs, and in bookmarks or favorites lists.
					</p>
					<div className='mt-4 text-sm lg:text-base'>
						<b className='text-[#464255] opacity-80'>Recommended Size:</b>
						<p className='text-[#464255] opacity-80'>ICO, PNG or SVG sizes 16x16, 32x32 and 48x48 Pixels</p>
					</div>
				</div>
				<div className='justify-center items-center flex'>
					<img src={IMAGES.Favicon} className='w-[230px] md:h-[90px] lg:h-[120px] h-[120px]'/>
				</div>
			</div>
		</div>
	);
};

export default Favicon;