import { DeleteOfferType, GetOffersType, GetOffersWithPaginationType, GetTempOffersType } from 'services/requests/Features/Offers/schema';

import { atom } from 'jotai';

export const FOffers = atom<GetOffersWithPaginationType>({
	limit: 0,
	page: 0,
	sort: '',
	totalRows: 0,
	totalPages: 0,
	rows:[]
});

export const FNewOffers = atom<GetOffersType>({
	offerID: 0,
	companyTenantID: '',
	appTenantID: '',
	appID: 0,
	couponType: 0,
	// minimumBasketPrice: '',
	title: '',
	description: '',
	termsAndCondition: '',
	discountType: '',
	discount: '',
	buy: 0,
	get: 0,
	code: '',
	status: '',
	whoUpdated: '',
	whoAdded: 0,
	whenAdded: '',
	whenUpdated: ''
});

export const isOfferDataChanged = atom<boolean>(false);

// export const FEditOffers = atom<GetOffersWithPaginationType | []>([]);
export const FTempOffers = atom<GetTempOffersType[]>([{
	couponType: 1,
	title: 'Offer Name',
	description: '',
	termsAndCondition: '',
	discountType: 'Amount',
	discount: '',
	buy: 1,
	get: 1,
	code: '',
	offerID: 0,
}]);

export const FTempIds = atom<DeleteOfferType[] | []>([]);

export const selectedItemAtom = atom<GetTempOffersType | null>(null);
export const FIsOfferRefresh = atom<boolean>(true);