import { z } from 'zod';

export const CreateEditStoreSchema = z.object({
	storeName:       z.string(),
	isTurnedOn:      z.boolean(),
});

export const GetStoreSchema = z.object({
	store: z.object ({
		storeID:        z.number(),
		appID:          z.number(),
		storeName:      z.string(),
		isTurnedOn:     z.boolean(),
		env:            z.string(),
		status:         z.string(),
		whoAdded:       z.number(),
		whoUpdated:     z.number(),
		whenAdded:      z.string(),
		whenUpdated:    z.string()
	})
});

export type CreateEditStoreType = z.infer<typeof CreateEditStoreSchema>
export type StoreResponse       = z.infer<typeof GetStoreSchema>