import Button from 'components/Common/Buttons/Button';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Error403:React.FC = () => {

	const navigate = useNavigate();
	return(
		<div className='bg-[url(https://snaptoapp-dev.s3.us-west-2.amazonaws.com/public/error_background/man-holding-broken-phone.png)] h-screen bg-no-repeat bg-center bg-cover flex items-center justify-center lg:pr-[45%] lg:pb-[10%]'>
			<div className='text-center'>
				<span className='text-[#007CA8] font-bold max-sm:text-6xl md:text-8xl lg:text-9xl block py-2'>403</span>
				<span className='text-[#007CA8] font-bold max-sm:text-xl md:text-3xl lg:text-4xl block py-2'>You don&apos;t have</span>
				<span className='text-[#007CA8] font-bold max-sm:text-xl md:text-3xl lg:text-4xl block py-2'>permission to access this page</span>
				<Button className='my-2 border-none rounded-md bg-[#007CA8]' onClick={() => navigate('/dashboard')}>
					<span className='text-white font-bold max-sm:text-lg md:text-2xl lg:text-xl block py-2 px-4'>Back to home</span>
				</Button>
			</div>
		</div>
	);
};

export default Error403;