import { EditLayoutByIdResponse, EditLayoutByIdSchema, GetAllLayoutResponse, GetLayoutResponse } from './schema';

import { axiosInstance } from 'services/axios';

export const getLayoutByID = async (id : number) => {
	const response = await axiosInstance.get(`/organizations/layouts/${id}`);
	return response.data as GetLayoutResponse;
};

export const getAllLayout = async () => {
	const response = await axiosInstance.get('/organizations/layouts');
    
	return response.data as GetAllLayoutResponse;
};

export const editLayoutByID = async (params: EditLayoutByIdSchema, id: number) => {
	const response = await axiosInstance.put(`/organizations/layouts/${id}`, params);

	return response.data as EditLayoutByIdResponse;
};