import React from 'react';
import { SEditProfileFirstName, SEditProfileLastName, SEditProfileImagePreview } from '../../../store/EditProfile/editProfileAtom';
import { useAtom } from 'jotai';
import { capitalizeWords } from 'utility';
import { capitalizeFirstLetter } from 'utility';
import { ISideBarItems } from '..';

type EditProfileSideBarProps = {
  selectedOption: string;
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>;
  items:ISideBarItems[] | null;
};

const EditProfileSideBar: React.FC<EditProfileSideBarProps> = ({ items, selectedOption, setSelectedOption }) => {

	const [ editFirstName, setEditFirstName] = useAtom(SEditProfileFirstName);
	const [ editLastName, setEditLastName]   = useAtom(SEditProfileLastName);
	const [ editImage, setEditImage ]        = useAtom(SEditProfileImagePreview);

	return (
		<div className="min-md:mb-8 max-md:mb-8 md:w-[35%] sm:w-full min-sm:mb-8 max-sm:mb-8 min-sm:w-full max-sm:w-full w-[20%] 2xl:pb-[280px] 2xl:w-[20%]  xl:w-[20%] lg:w-[30%]  flex flex-col items-start shadow-xl z-10 border bg-white">
			<div className='mt-2 w-full p-4 px-7 '>
				<div className='flex flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-row max-sm:flex-row'>
					{
						editImage === '' || editImage === undefined ?
							editFirstName !== undefined ? 
								<div className='w-1/4'>
									<p className='w-[85px] h-[85px] flex justify-center items-center rounded-full text-center bg-primary text-white font-bold text-[46px]'>{capitalizeFirstLetter(editFirstName)}</p>
								</div>
								:
								null
							
							:
							<img alt='user icon' className='w-[90px] h-[90px] bg-slate-300 rounded-full border object-cover' src={editImage}/>
					}
					<div className={`${editImage === '' || editImage === undefined ? 'px-11 2xl:px-11 xl:px-11 lg:px-11 md:px-11' : 'px-4'} w-3/4 flex flex-col mt-5`}>
						<p className='text-[#363636] font-bold tracking-wider'>{editFirstName && editFirstName?.length > 10 ? `${capitalizeWords(editFirstName).slice(0,10)}...` : capitalizeWords(editFirstName)}</p>
						<p className='text-[#363636] font-bold tracking-wider'>{editLastName && editLastName?.length > 10 ? `${capitalizeWords(editLastName).slice(0,10)}...` : capitalizeWords(editLastName)}</p>
					</div>
				</div>
			</div>
			<div className='mt-3 flex justify-center items-center w-full'>
				<hr className=' h-[1px] w-[85%] border-[1px] rounded' />
			</div>
			<div className="mt-7 w-full">
				{items?.map((item, index) => (
					item.visible ? 
						<button
							id={item.link}
							onClick={() => {
								setSelectedOption(item.link);
							}}
							className={`w-full p-4 flex px-7 text-[#707070] ${
								item.link === selectedOption
									? 'bg-[#F6F6F6] font-semibold text-[#FF7F20]'
									: 'focus:bg-[#F6F6F6] focus:font-semibold focus:text-[#FF7F20]'
							}`}
							key={index}
						>
							<div className="flex">
								<span className="ml-4">{item.title}</span>
							</div>
						</button>
						:
						<>
						</>
				))}
			</div>
		</div>
	);
};

export default EditProfileSideBar;
