import { GetPrivacyPolicyFormType, GetPrivacyPolicySchema, GetPrivacyPolicyrFormResponse } from './schema';

import { axiosInstance } from 'services/axios';

export const getPrivacyPolicyByAppIDLive = async (appID?: string) => {
	const response = await axiosInstance.get(`/organizations/apps/${appID}/privacyPolicy?env=LIV`);
    
	return response.data as GetPrivacyPolicyrFormResponse;
};

export const getPrivacyPolicyByAppID = async (appID?: string) => {
	const response = await axiosInstance.get(`/organizations/apps/${appID}/privacyPolicy`);
    
	return response.data as GetPrivacyPolicyrFormResponse;
};

export const createPrivacyPolicy = async (appID?: string, data?: GetPrivacyPolicyFormType) => {
	const validatedPrivacyPolicy = GetPrivacyPolicySchema.parse(data);
	const response = await axiosInstance.post(`/organizations/apps/${appID}/privacyPolicy`, validatedPrivacyPolicy);
	return response.data as GetPrivacyPolicyrFormResponse;
};

export const editPrivacyPolicyByAppID = async (appID?: string, privacyID?: number, param?: GetPrivacyPolicyFormType) => {
	const response = await axiosInstance.put(`/organizations/apps/${appID}/privacyPolicy/${privacyID}`, param);
    
	return response.data as GetPrivacyPolicyrFormResponse;
};