import React, { useEffect, useLayoutEffect } from 'react';
import { IMAGES } from 'utility';
import Footer from 'components/Common/Footer';
import { NavLink } from 'react-router-dom';

const AccessibilityLanding: React.FC = () => {

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useLayoutEffect(() => {
		document.body.style.backgroundColor = '#FFFFFF';
	});

	return (
		<div className='w-full h-full'>
			<div id='my-header' className='bg-white flex justify-center items-center flex-col'>
				<div className='bg-white relative w-full h-[50vh] flex justify-center items-center'>
					<img className='object-cover w-full h-full' src={IMAGES.AccessibilityImage} />
					<span className='absolute font-bold text-white text-[60px] max-sm:text-[42px] text-center tracking-wider'>Accessibility</span>
				</div>
				<div className='bg-white relative w-full h-full pl-24 pr-24 max-sm:pl-8 max-sm:pr-8 mt-16 tracking-wider'>
					<p className='font-bold text-[#403E3C]'>SnapToApp Accessibility Statement</p>
					<p className='mt-5'>SnapToApp Accessibility Statement SnapToApp is committed to ensuring our website is accessible to all individuals, including those with disabilities. We work hard to deliver an inclusive and user-friendly experience for everyone.</p>
					<p className='font-bold text-[#403E3C] mt-5'>Our Efforts Towards Accessibility:</p>
					<p className='mt-5'><span className='text-[#403E3C]'>1. </span><span className='font-bold text-[#403E3C]'>Screen Reader Compatibility:</span> We have designed our website with screen reader compatibility in mind, using appropriate markup and alt text to ensure visual content is accessible to users with visual impairments.</p>
					<p className='mt-5'><span className='text-[#403E3C]'>2. </span><span className='font-bold text-[#403E3C]'>Keyboard Navigation:</span> SnapToApp's website can be navigated and used effectively using only a keyboard, ensuring that users who rely on keyboard input can seamlessly interact with our platform.</p>
					<p className='mt-5'><span className='text-[#403E3C]'>3. </span><span className='font-bold text-[#403E3C]'>Color Contrast:</span> We maintain sufficient color contrast throughout our website to aid users with low vision and color blindness in perceiving content and navigating the interface.</p>
					<p className='mt-5'><span className='text-[#403E3C]'>4. </span><span className='font-bold text-[#403E3C]'>Resizable Text:</span> Our website allows users to adjust text size without compromising the functionality or layout, catering to those requiring larger font for readability.</p>
					<p className='mt-5'><span className='text-[#403E3C]'>5. </span><span className='font-bold text-[#403E3C]'>Accessible Forms:</span> All forms on our website are designed with labels and instructions, making them compatible with screen readers and enabling users with disabilities to interact with them easily.</p>
					<p className='font-bold text-[#403E3C] mt-5'>Feedback and Support:</p>
					<p className='mt-5'> SnapToApp dedicates time and effort to improving the accessibility of our website. If you encounter any accessibility problems or want to share feedback concerning the accessibility of our website, you can contact our support team at
						<NavLink to={'mailto:info@snaptoapp.com'}>
							<button className='underline text-orange-500 font-semibold background-transparent pl-2 outline-none focus:outline-none ease-linear transition-all duration-150 hover:no-underline' type='button'>info@snaptoapp.com</button>
						</NavLink>.</p>
					<p className='mt-5 mb-40'> SnapToApp is dedicated to making our mobile app builder website accessible to everyone. We appreciate your input and strive to ensure that all users enjoy a positive experience using our platform.</p>
				</div>
			</div>
		</div>
	);

};

export default AccessibilityLanding;