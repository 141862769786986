import { axiosInstance } from 'services/axios';
import { 
	GetSubscriptionByIdResponse,
	SubscriptionParams,
	SubscriptionResponse,
	SubscriptionSchemaPayload
} from './schema';


export const getSubscriptionByAppId = async (appId?: string) => {
	const response = await axiosInstance.get(`billing/subscription/app/${appId}`);

	return response.data as GetSubscriptionByIdResponse;
};

export const SubscribePerAppId = async (data: SubscriptionParams) => {
	const subscriptionDetailsForm = SubscriptionSchemaPayload.parse(data);
	const response = await axiosInstance.post('/billing/subscription/paid', subscriptionDetailsForm);

	return response.data as SubscriptionResponse;
};