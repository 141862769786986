import AppPanel from './AppPanel';
import DashboardPanel from './DashboardPanel';
import React from 'react';
import ResellerPanel from './ResellerPanel';
import UserPanel from './UserPanel';

type ApproverPanelProps = {
	selectedOption: string;
};

const ApproverPanel: React.FC<ApproverPanelProps> = ({ selectedOption }) => {
	return (
		<div className='bg-[#FAFAFA] w-full'>
			
			{{
				'dashboard' : 	<DashboardPanel />,
				'app' : 	<AppPanel />,
				'users' : 	<UserPanel />,
				'reseller' : 	<ResellerPanel />,


			}[selectedOption]}

		</div>
	);
};

export default ApproverPanel;
