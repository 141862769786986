import z from 'zod';

export const createAppSchema = z.object({
	userID: z.number(),
	industryID: z.number(),
	name: z.string(),
	websiteUrl: z.string().nullable(),
	logoUrl: z.string().nullable()
});

export type CreateAppType = z.infer<typeof createAppSchema>;