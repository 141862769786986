/* eslint-disable no-mixed-spaces-and-tabs */

import { NavLink, useNavigate } from "react-router-dom";

import { IMAGES } from "utility/constant";
import React from "react";

const socialLinks = [
  {
    id: 1,
    src: IMAGES.FooterFacebook,
    link: "https://web.facebook.com/snap2app",
  },
  // Hide The Pinterest Logo for now as per designer's request
  // {
  // 	id: 2,
  // 	src: IMAGES.FooterPinterest,
  // 	link: '',
  // },
  {
    id: 2,
    //src: IMAGES.FooterTwitter,
    src: IMAGES.FooterTwitterX,
    link: "https://twitter.com/Snap2App",
  },
  {
    id: 3,
    src: IMAGES.FooterLinkedIn,
    link: "https://www.linkedin.com/company/snaptoapp/",
  },
];
const services = [
  {
    id: 1,
    title: "About",
    link: "/about",
  },
  {
    id: 2,
    title: "Pricing",
    link: "/pricing",
  },
  {
    id: 3,
    title: "Become an Affiliate",
    link: "/become-an-affiliate",
  },
  {
    id: 4,
    title: "Let Us Build Your App",
    link: "/build",
  },
];
const quickLinks = [
  {
    id: 1,
    title: "Features",
    link: "",
  },
  {
    id: 2,
    title: "Advantages",
    link: "",
  },
];
const help = [
  {
    id: 1,
    title: "FAQs",
    link: "/faqs",
  },
  // {
  // 	id: 1,
  // 	title: 'Help Center',
  // 	link: '',
  // },
  {
    id: 1,
    title: "Contact Us",
    link: "/contact-us",
  },
];

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="mt-auto max-sm:w-[100%] w-full text-white  bg-[#2D2D2D] pb-[1rem]">
      <div className="grid md:grid-cols-3 grid-cols-1 gap-4 w-full h-full pt-[33px]">
        <div className="w-full pl-[76px] grid content-start">
          <span className="font-semibold">SnapToApp</span>
          <div className="flex flex-row gap-2 mt-[11px] ">
            {socialLinks.map(({ id, src, link }) => (
              <div key={id}>
                <NavLink id="nav-link" to={link} target="_blank">
                  <img
                    src={src}
                    alt="Loading Image"
                    className="w-[3vw] h-[3vw] min-w-[35px] min-h-[40px] hover:scale-125 duration-500"
                  />
                </NavLink>
              </div>
            ))}
          </div>
        </div>
        <div className="grid w-full grid-flow-row gap-8 sm:grid-flow-col sm:grid-cols-2 grid-cols-1 px-[76px] md:pl-[0px]  md:mt-[0px] sm:mt-[20px] mt-[29px]">
          <div className="w-full">
            <span className="font-semibold">Services</span>
            <div className="flex flex-col gap-2 mt-[11px]">
              {services.map(({ id, title, link }) => (
                <div key={id}>
                  <NavLink
                    id="nav-link"
                    to={link}
                    className="hover:border-b-2 hover:border-[#FF7F20] text-sm font-light"
                  >
                    {title}
                  </NavLink>
                </div>
              ))}
            </div>
          </div>
          {/* <div className='w-full'>
						<span className='font-semibold'>Quick Links</span>
						<div className='flex flex-col gap-2 mt-[11px]'>
							{quickLinks.map(({ id, title, link }) => (
								<div key={id}>
									<NavLink
										id='nav-link'
										to={link}
										className='hover:border-b-2 hover:border-[#FF7F20] text-[14px] font-thin'
									>
										{title}
									</NavLink>
								</div>
							))}
						</div>
					</div> */}
          <div className="w-full">
            <span className="font-semibold">Help</span>
            <div className="flex flex-col gap-2 mt-[11px]">
              {help.map(({ id, title, link }) => (
                <div key={id}>
                  <NavLink
                    id="nav-link"
                    to={link}
                    className="hover:border-b-2 hover:border-[#FF7F20] text-[14px] font-light"
                  >
                    {title}
                  </NavLink>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-4 w-full px-[76px] max-sm:px-[50px] pt-[4%] sm:place-content-center">
        <div className="font-normal mt-[20px] sm:mt-[0px] justify-center sm:justify-start flex">
          <span>Copyright &copy; 2024 SnapToApp</span>
        </div>
        <div className="grid row-start-1 md:col-start-2 sm:justify-end grid-flow-col mt-[40px] sm:mt-[0px]">
          <NavLink
            id="nav-link"
            to={"/terms-of-use"}
            className="hover:border-b-2 hover:border-[#FF7F20] font-light max-sm:text-[14px]"
          >
            Terms of Use
          </NavLink>
          <span className="mx-5">|</span>
          <NavLink
            id="nav-link"
            to={"/privacy-policy"}
            className="hover:border-b-2 hover:border-[#FF7F20] font-light max-sm:text-[14px]"
          >
            Privacy Policy
          </NavLink>
          <span className="mx-5">|</span>
          <NavLink
            id="nav-link"
            to={"/accessibility"}
            className="hover:border-b-2 hover:border-[#FF7F20] font-light max-sm:text-[14px]"
          >
            Accessibility
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Footer;
