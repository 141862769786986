import React from 'react';
const LoadingScreen: React.FC = () => {
	return (
		<div className='w-full h-full'>
			<div className='flex w-full justify-between'>
				<div className='m-10 w-44 h-16 bg-gray-300 animate-pulse rounded-md'/>
				<div className='flex justify-end mx-10 gap-10'>
					<div className='my-10 w-32 h-14 bg-gray-300 animate-pulse rounded-md'/>
					<div className='my-10 w-32 h-14 bg-gray-300 animate-pulse rounded-md'/>
				</div>
			</div>
			<div className='flex w-full h-full'>
				<div className='w-full h-full mx-10'>
					<div className='flex w-full justify-between gap-4'>
						<div className='mb-2 w-1/4 h-20 bg-gray-300 animate-pulse rounded-md'/>
						<div className='mb-2 w-1/4 h-20 bg-gray-300 animate-pulse rounded-md'/>
						<div className='mb-2 w-1/4 h-20 bg-gray-300 animate-pulse rounded-md'/>
						<div className='mb-2 w-1/4 h-20 bg-gray-300 animate-pulse rounded-md'/>
					</div>
					<div className='mb-10 w-full h-24 bg-gray-300 animate-pulse rounded-md'/>
					<div className='w-full h-[460px] bg-gray-300 animate-pulse rounded-md p-10'/>
				</div>
				<div className='mr-10 h-[680px] w-[370px] bg-gray-300 animate-pulse rounded-md'/>
			</div>
		</div>
	);
};

export default LoadingScreen;