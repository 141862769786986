import { axiosInstance } from 'services/axios';
import { CreateEditFaviconImgUploadType, CreateEditFaviconImgUploadSchema, GetFaviconResponse, UploadFaviconImageResponse } from './schema';

export const createFaviconByID = async (appID?: string, data?: CreateEditFaviconImgUploadType) => {
	const validatedFaviconForm = CreateEditFaviconImgUploadSchema.parse(data);
	const response = await axiosInstance.post(`/organizations/apps/${appID}/favicon`, validatedFaviconForm); 
	return response.data as GetFaviconResponse;
};

export const editFaviconByID = async (appID?: string, faviconID?: number, param?: CreateEditFaviconImgUploadType) => {
	const response = await axiosInstance.put(`/organizations/apps/${appID}/favicon/${faviconID}`, param); 
	return response.data as GetFaviconResponse;
};

export const getFaviconByID = async (appID?: string) => {
	const response = await axiosInstance.get(`/organizations/apps/${appID}/favicon`); 
	return response.data as GetFaviconResponse;
};

export const getFaviconByIDLive = async (appID?: string) => {
	const response = await axiosInstance.get(`/organizations/apps/${(appID)}/favicon?env=LIV`); 
	return response.data as GetFaviconResponse;
};

export const uploadFaviconImageApi = async (id?: string, file?: any) => {
	const formData = new FormData();
	formData.append('image', file);
	const response = await axiosInstance.post(`/organizations/apps/${id}/imageUploads/favicon`, formData, {
		headers: {
			'Content-type': 'multipart/form-data'
		}});
    
	return response.data as UploadFaviconImageResponse;
};