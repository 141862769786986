import './index.css';

import { QueryClient, QueryClientProvider } from 'react-query';

import App from './App';
import { ENVIRONMENT } from 'services/api';
import { Provider } from 'jotai';
import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga';
import { ReactQueryDevtools } from 'react-query/devtools';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	}
});

// if(ENVIRONMENT === 'production'){
// 	ReactGA.initialize('G-SD6ER2P30T');
// }

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(	
		<Provider>
			<QueryClientProvider client={queryClient}>
				<App />
				{
					ENVIRONMENT === 'development' &&
					<ReactQueryDevtools initialIsOpen={false} />
				}
			</QueryClientProvider>
		</Provider>
	,
);
