import * as React from 'react';
import * as subscriptionAtom from '../../../store/CustomizeApp/Payment/subscription';

import { useAtom, useAtomValue } from 'jotai';

import { ActiveStep } from 'store/CustomizeApp/Payment/activeStepAtom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { ISubscriptionDetails } from 'pages/CustomizeApp/Payment/Content/oldIndex';
import { PaymentStepperContent } from 'components/PaymentTab/PaymentStepperContent';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton/StepButton';
import Stepper from '@mui/material/Stepper';
import { classNames } from 'utility';
import { planPriceID } from 'store/CustomizeApp/Payment/subscription';

const steps = ['Choose Plan', 'Choose Payment Method', 'Confirmation'];

const stepStyle = {
	'& .Mui-active' : {
		'&.MuiStepIcon-root' : {
			color: '#E0781D'
		}, 
		'& .MuiStepConnector-line' : {
			borderColor: '#E0781D'
		},
	},
	'& .Mui-completed' : {
		'&.MuiStepIcon-root' : {
			color: '#E0781D'
		}, 
		'& .MuiStepConnector-line' : {
			borderColor: '#E0781D'
		}
	},
	'& .Mui-disabled' : {
		fontSize: '18px',
		color: 'black',
		fontWeight: 'medium'
	},
	'& .MuiStepLabel-label' : {
		fontSize: '18px',
		fontFamily: 'Poppins'
	},
	'& .MuiBox-root' : {
		marginTop: '40px'
	}
};

const mediaQueries = {
	'@media (max-width: 1536px)': {
		'& .MuiStepLabel-label' : {
			fontSize: '18px',
		},
	},
	'@media (max-width: 1280px)': {
		'& .MuiStepLabel-label' : {
			fontSize: '16px',
		},
	},
	'@media (max-width: 1024px)': {
		'& .MuiStepLabel-label' : {
			fontSize: '14px',
		}
	},
	'@media (max-width: 768px)': {
		'& .MuiStepLabel-label' : {
			fontSize: '14px',
		}
	},
	'@media (max-width: 640px)': {
		'& .MuiStepLabel-label' : {
			fontSize: '10px',
		}
	},
};

const responsiveStepStyle = {
	...stepStyle,
	...mediaQueries
};

export const PaymentStepper: React.FC = () => {
  
	const [activeStep, setActiveStep]                      = useAtom(ActiveStep);
	const [selectedPlanPriceId, setSelectedPlanPriceId]    = useAtom(planPriceID);
	const [changePlan, setChangePlan]											 = useAtom(subscriptionAtom.changePlanID);
	const isAlreadySubscribed       											 = useAtomValue(subscriptionAtom.FIsAlreadySubscribed);
	const [, setBackToChangePlan]													 = useAtom(subscriptionAtom.backToChangePlanPage);

	const handleSelect = (dataPrice: ISubscriptionDetails) => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);

		if (dataPrice.PriceId === selectedPlanPriceId) return;
		setSelectedPlanPriceId(dataPrice.PriceId);
	};

	const handleBack = () => {
		if (activeStep === 1){
			setActiveStep((prevActiveStep) => prevActiveStep - 1);
		} else if (activeStep === 0 && changePlan === true){
			setBackToChangePlan(true);
			setChangePlan(false);
		}
	};

	const handleStep = (step: number) => () => {
		if (activeStep === 1){
			setActiveStep((prevActiveStep) => prevActiveStep - 1);
		}
	};
  
	return (
		<div className='flex justify-center items-center'>
			<Box sx={{ width: '95%', paddingY: '1.50rem'}}>
				<Stepper activeStep={activeStep} sx={responsiveStepStyle}>
					{steps.map((label, index) => {
						const stepProps: { completed?: boolean; } = {};
						const labelProps: {
          optional?: React.ReactNode;
        } = {};
						return (
							<Step key={label} {...stepProps}>
								<StepButton color='inherit' onClick={handleStep(index)} {...labelProps}>
									{label}
								</StepButton>
							</Step>
						);
					})}
				</Stepper>
				{activeStep === steps.length ? (
					<React.Fragment>
					</React.Fragment>
				) : (
					<React.Fragment>
						<div className='py-5'>
							<Box sx={{ display: 'flex', pt: 2 }}>
								{ 
									!isAlreadySubscribed && activeStep === 0 
										? <></>
										: 
										<Button
											color='inherit'
											disabled={(!isAlreadySubscribed && activeStep === 0) || activeStep === 2}
											onClick={handleBack}
											sx={{mr: 1, fontFamily: 'Poppins', fontSize: '12px', fontWeight: 'bold', color: '#E0781D', textTransform: 'none',  marginRight: 'auto'}}
										>	
											<i className='fa-solid fa-chevron-left mr-3 text-lg'></i>
											Back
										</Button>
								}
								{ 
									activeStep === 0 &&
										<p className={
											classNames('w-full mx-auto flex items-center justify-center text-center text-sm lg:text-2xl font-semibold text-[#464255]', 
												!isAlreadySubscribed && activeStep === 0 ? 'm-auto' : 'mr-auto'
											)}>
											Choose the plan that&apos;s right for you
										</p>
								}						
								{
									activeStep === 1 &&
										<p className='text-sm lg:text-2xl font-semibold text-[#464255] mr-auto'>
											You&apos;re almost there 
										</p>
								}
							</Box>
						</div>
						<PaymentStepperContent handleNext={handleSelect} stepNum={activeStep} />
					</React.Fragment>
				)}
			</Box>
		</div>
	);
};