import { Background, Color, Font, Layout, Theme, Welcome } from 'assets/AppearanceIcons/AppearanceIcons';
import { Bars3Icon, QueueListIcon, PhotoIcon } from '@heroicons/react/24/solid';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React, { useEffect } from 'react';
import { SAppearanceTab } from 'store/CustomizeApp/Appearance';
import { SIndustrySubCategory } from 'store/CustomizeApp/Appearance/flashScreenAtom';
import { SIsSidebarOpen } from 'store/CustomizeApp/Appearance/sideBarAtom';
import { SSubCategErrorModalShow } from 'store/subCategEmptyAtom';
import { classNames } from 'utility';
import { useMediaQuery } from 'react-responsive';
import { SNavigation } from 'store/CustomizeApp/Appearance/navigationAtom';
import { FContents } from 'store/CustomizeApp/Features/contentAtom';

const AppearanceTab: React.FC = () => {

	const [ subCategModalShow, setSubCategModalShow ] = useAtom(SSubCategErrorModalShow);
	const [category, setCategory] 			= useAtom(SIndustrySubCategory);
	const [selectedOption, setSelectedOption] = useAtom(SAppearanceTab);
	const setIsSidebarOpen = useSetAtom(SIsSidebarOpen);
	const xlViewPortAlready = useMediaQuery({ maxWidth: 1243 });
	// const mdViewPortAlready = useMediaQuery({ maxWidth: 768 });
	const flashScreenWidthExtra = useMediaQuery({ maxWidth: 468 });

	const CONTAINER_CLASS = 'w-full h-full block cursor-pointer p-2 2xl:w-full xl:w-full lg:w-full md:w-full sm:w-full max-sm:w-[120%]'; // w-[20%]
	// const SM_HIDE_TXT_CONTAINER_CLASS = 'flex justify-center items-center';

	const [navigation, setNavigation] 	= useAtom(SNavigation);
	const contents 						= useAtomValue(FContents);

	useEffect(() => {
		const updatedNav = [...navigation];
		const contentNavIndex = updatedNav.findIndex((nav) => nav.label === 'Content');

		if (updatedNav[contentNavIndex].label === 'Content' && contents.length < 1) {
			updatedNav[contentNavIndex].isHidden = true;
			setNavigation(updatedNav);
		}
	}, []);

	return (
		<div className='flex flex-row w-full 2xl:w-full xl:w-full lg:w-full md:w-full sm:w-[113%] max-sm:w-[210%] h-15 bg-white rounded-lg'> {/* flex items-center */}
			{/* w-full */}
			<div
				onClick={() => {setSelectedOption('flashScreen');}}
				className={classNames('w-full h-full block cursor-pointer p-2 2xl:w-full xl:w-full lg:w-full md:w-[170%] sm:w-[190%]',
					selectedOption === 'flashScreen'
						? 'bg-[#FDDDCB]'
						: 'bg-white',
					flashScreenWidthExtra
						? 'max-sm:w-[220%]'
						: 'max-sm:w-[120%]'
					// mdViewPortAlready
					// 	? ''
					// 	: ''
				)}>
				<div className='flex justify-center py-1'>
					<Welcome fill={selectedOption === 'flashScreen' ? '#007CA8' : '#707070'} />
				</div>
				<div className={classNames(
					selectedOption === 'flashScreen'
						? 'text-[#007CA8] font-bold'
						: 'text-[#707070] font-normal',
					xlViewPortAlready
						? 'text-sm'
						: '',
					'text-center'
				)}>
					{/* {mdViewPortAlready ? 'Flash Screen' : 'Flash Screen'} */}
					<pre className='font-poppins'>Flash Screen</pre>
				</div>
			</div>

			<div
				onClick={() => { category ? setSelectedOption('layout') : setSubCategModalShow(true); } }
				className={classNames(CONTAINER_CLASS,
					selectedOption === 'layout'
						? 'bg-[#FDDDCB]'
						: 'bg-white'
				)}>
				<div className='flex justify-center py-1'>
					<Layout fill={selectedOption === 'layout' ? '#007CA8' : '#707070'} />
				</div>
				<div className={classNames(
					selectedOption === 'layout'
						? 'text-[#007CA8] font-bold'
						: 'text-[#707070] font-normal',
					xlViewPortAlready
						? 'text-sm'
						: '',
					'text-center'
				)}>
					{/* {mdViewPortAlready ? '' : 'Layout'} */}
					Layout
				</div>
			</div>

			<div
				onClick={() => { category ? setSelectedOption('theme') : setSubCategModalShow(true); } }
				className={classNames(CONTAINER_CLASS,
					selectedOption === 'theme'
						? 'bg-[#FDDDCB]'
						: 'bg-white'
				)}>
				<div className='flex justify-center py-1'>
					<Theme fill={selectedOption === 'theme' ? '#007CA8' : '#707070'} />
				</div>
				<div
					className={classNames(
						selectedOption === 'theme'
							? 'text-[#007CA8] font-bold'
							: 'text-[#707070] font-normal',
						xlViewPortAlready
							? 'text-sm'
							: '',
						'text-center'
					)}>
					{/* {mdViewPortAlready ? '' : 'Theme'} */}
					Theme
				</div>
			</div>

			<div
				onClick={() => { category ? setSelectedOption('background') : setSubCategModalShow(true); } }
				className={classNames(CONTAINER_CLASS,
					selectedOption === 'background'
						? 'bg-[#FDDDCB]'
						: 'bg-white'
				)}>
				<div className='flex justify-center py-1'>
					<Background fill={selectedOption === 'background' ? '#007CA8' : '#707070'} />
				</div>
				<div
					className={classNames(
						selectedOption === 'background'
							? 'text-[#007CA8] font-bold'
							: 'text-[#707070] font-normal',
						xlViewPortAlready
							? 'text-sm'
							: '',
						'text-center'
					)}>
					{/* {mdViewPortAlready ? '' : 'Background'}	 */}
					Background
				</div>
			</div>

			<div
				onClick={() => { category ? setSelectedOption('navigation') : setSubCategModalShow(true); } }
				className={classNames(CONTAINER_CLASS,
					selectedOption === 'navigation'
						? 'bg-[#FDDDCB]'
						: 'bg-white'
				)}>
				<div className='flex justify-center'>
					<QueueListIcon className={classNames('w-[34px] h-[34px]', selectedOption === 'navigation' ? 'text-[#007CA8]' : 'text-[#707070]')} />
				</div>
				<div
					className={classNames(
						selectedOption === 'navigation'
							? 'text-[#007CA8] font-bold'
							: 'text-[#707070] font-normal',
						xlViewPortAlready
							? 'text-sm'
							: '',
						'text-center'
					)}>
					{/* {mdViewPortAlready ? '' : 'Navigation'}	 */}
					Navigation
				</div>
			</div>

			<div
				onClick={() => { category ? (setSelectedOption('sideBar'), setIsSidebarOpen(true)) : setSubCategModalShow(true); } }
				className={classNames('w-full h-full block cursor-pointer p-2 2xl:w-full xl:w-full lg:w-full md:w-full sm:w-[120%] max-sm:w-[140%]',
					selectedOption === 'sideBar'
						? 'bg-[#FDDDCB]'
						: 'bg-white'
				)}>
				<div className='flex justify-center'>
					<Bars3Icon className={classNames('w-[34px] h-[34px]', selectedOption === 'sideBar' ? 'text-[#007CA8]' : 'text-[#707070]')} />
				</div>
				<div
					className={classNames(
						selectedOption === 'sideBar'
							? 'text-[#007CA8] font-bold'
							: 'text-[#707070] font-normal',
						xlViewPortAlready
							? 'text-sm'
							: '',
						'text-center'
					)}>
					{/* {mdViewPortAlready ? '' : 'Side Bar'}		 */}
					<pre className='font-poppins'>Side bar</pre>
				</div>
			</div>

			<div
				onClick={() => { category ? setSelectedOption('color') : setSubCategModalShow(true); } }
				className={classNames(CONTAINER_CLASS,
					selectedOption === 'color'
						? 'bg-[#FDDDCB]'
						: 'bg-white'
				)}>
				<div className='flex justify-center py-1'>
					<Color fill={selectedOption === 'color' ? '#007CA8' : '#707070'} />
				</div>
				<div
					className={classNames(
						selectedOption === 'color'
							? 'text-[#007CA8] font-bold'
							: 'text-[#707070] font-normal',
						xlViewPortAlready
							? 'text-sm'
							: '',
						'text-center'
					)}>
					{/* {mdViewPortAlready ? '' : 'Color'} */}
					Color
				</div>
			</div>

			<div
				onClick={() => { category ? setSelectedOption('font') : setSubCategModalShow(true); } }
				className={classNames(CONTAINER_CLASS,
					selectedOption === 'font'
						? 'bg-[#FDDDCB]'
						: 'bg-white'
				)}>
				<div className='flex justify-center py-1'>
					<Font fill={selectedOption === 'font' ? '#007CA8' : '#707070'} />
				</div>
				<div
					className={classNames(
						selectedOption === 'font'
							? 'text-[#007CA8] font-bold'
							: 'text-[#707070] font-normal',
						xlViewPortAlready
							? 'text-sm'
							: '',
						'text-center'
					)}>
					{/* {mdViewPortAlready ? '' : 'Font'} */}
					Font
				</div>
			</div>
			{/* Uncomment when Images APi ready */}
			<div
				onClick={() => {category ? setSelectedOption('images') : setSubCategModalShow(true);}}
				className={classNames(CONTAINER_CLASS,
					selectedOption === 'images'
						? 'bg-[#FDDDCB]'
						: 'bg-white',
				)}>
				<div className='flex justify-center'>
					<PhotoIcon className={classNames('w-[34px] h-[34px]', selectedOption === 'images' ? 'text-[#007CA8]' : 'text-[#707070]')}/>
				</div>
				<div
					className={classNames(
						selectedOption === 'images'
							? 'text-[#007CA8] font-bold'
							: 'text-[#707070] font-normal',
						xlViewPortAlready
							? 'text-sm'
							: '',
						'text-center'
					)}>
					{/* {mdViewPortAlready ? '' : 'Images'} */}
					Images
				</div>
			</div>

		</div>
	);

};

export default AppearanceTab;