import { useEffect } from 'react';

export const classNames = (...classes: string[]): string => {
	return classes.filter(Boolean).join(' ');
};

export const useClickOutside = (ref: any, handler: any) => {
	useEffect(() => {
		let startedInside = false;
		let startedWhenMounted = false;

		const listener = (event: { target: any; }) => {
			// Do nothing if `mousedown` or `touchstart` started inside ref element
			if (startedInside || !startedWhenMounted) return;
			// Do nothing if clicking ref's element or descendent elements
			if (!ref.current || ref.current.contains(event.target)) return;

			handler(event);
		};

		const validateEventStart = (event: { target: any; }) => {
			startedWhenMounted = ref.current;
			startedInside = ref.current && ref.current.contains(event.target);
		};

		document.addEventListener('mousedown', validateEventStart);
		document.addEventListener('touchstart', validateEventStart);
		document.addEventListener('click', listener);

		return () => {
			document.removeEventListener('mousedown', validateEventStart);
			document.removeEventListener('touchstart', validateEventStart);
			document.removeEventListener('click', listener);
		};
	}, [ref, handler]);
};

export const convertToTailwind = (html: string): string => {
	// Map Quill classes to tailwind classes
	const classMap: {[key: string]: string} = {
		'ql-align-center': 'text-center',
		'ql-align-right': 'text-right',
		'ql-align-justify': 'text-justify',
		'ql-font-Monospace': 'font-mono',
		'ql-font-Serif': 'font-serif',
		'ql-size-huge': 'text-5xl',
		'ql-size-large': 'text-3xl',
		'ql-size-small': 'text-sm',
		'ql-size-normal': '',
		// Add more class mappings as needed
		'ql-ordered-list': 'list-decimal',
	};

	// Regular expression pattern to match Quill classes
	const classRegex = /class="([^"]*)"/g;

	// Replace Quill classes with corresponding tailwind classes
	const tailwindHtml = html?.replace(classRegex, (match, p1) => {
		const classes = p1.split(' ').map((c : any) => classMap[c] || c).join(' ');
		return `class="${classes}"`;
	});
	return tailwindHtml;
};

export const getExtension = (str: string) => str.slice(str.lastIndexOf('.'));

export const capitalizeFirstLetter = (word: any): any => {
	return word.charAt(0).toUpperCase();
};

export const stripeCurrencyToAmount = (stripeAmount: number) => {
	return stripeAmount / 100;
};

export const stripeCurrencyToPercent = (pricePlan: number, stripeAmount: number) => {
	return pricePlan * (stripeAmount / 100);
};

export const stripeCardMasking = (last4: string) => {
	return `**** **** **** ${last4}`;
};

export const stripeCardMaskingBranded = (last4: string, brand: string) => {
	return `${brand} ending in ${last4}`;
};

export const stripeCardExpiry = (expMonth: number, expYear: number) => {
	return `${expMonth}/${expYear}`;
};