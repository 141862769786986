import { GetRunReportResponse } from './schema';

import { axiosInstance } from 'services/axios';


export const getAudienceByCountry = async (id?: string, startDate?: string, endDate?: string) => {
	const response = await axiosInstance.get(`/organizations/apps/${id}/googleAnalytics/audienceByCountry?startDate=${startDate}&endDate=${endDate}`);
	return response.data as GetRunReportResponse;
};

export const getPageViews = async (id?: string, startDate?: string, endDate?: string) => {
	const response = await axiosInstance.get(`/organizations/apps/${id}/googleAnalytics/pageViews?startDate=${startDate}&endDate=${endDate}`);
	return response.data as GetRunReportResponse;
};


export const getSessionsByChannel = async (id?: string, startDate?: string, endDate?: string) => {
	const response = await axiosInstance.get(`/organizations/apps/${id}/googleAnalytics/sessionsByChannel?startDate=${startDate}&endDate=${endDate}`);
	return response.data as GetRunReportResponse;
};

export const getTotalUsers = async (id?: string, startDate?: string, endDate?: string) => {
	const response = await axiosInstance.get(`/organizations/apps/${id}/googleAnalytics/totalUsers?startDate=${startDate}&endDate=${endDate}`);
	return response.data as GetRunReportResponse;
};
