import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

import Button from 'components/Common/Buttons/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IMAGES } from 'utility/constant';
import { globalVariableAtom } from 'store/authAtom';
import { useAtom } from 'jotai';

let defaultSelectedMenuProfileTabValue = 'profile';

export const selectedMenuProfileTabValue = (updatedProfileTabValue: string) => {
	defaultSelectedMenuProfileTabValue = updatedProfileTabValue;
};

export const getSelectedMenuProfileTabValue = () => {
	return defaultSelectedMenuProfileTabValue;
};

interface NavBarProps {
	isLogin?: boolean;
	isShow: boolean;
	onHamburgerClick: () => void;
	
}

const links = [
	{
		title: 'Pricing',
		links: '/pricing',
		src: IMAGES.userTopBarModalLandingPageSubscription,
	},
	{
		title: 'Contact Us',
		links: '/contact-us',
		src: IMAGES.userTopBarModalContactUs,
	},
	{
		title: 'Become an Affiliate',
		links: '/become-an-affiliate',
		src: IMAGES.userTopBarModalReseller,
	},
	{
		title: 'Let us Build Your App',
		links: '/build',
		src: IMAGES.userTopBarModalBuildYourApp,
	},
];

const linksSignedIn = [
	
	{
		title: 'Dashboard',
		links: '/dashboard',
		src: IMAGES.userTopBarModalDashbaord,
	},
	// { title: 'Help', links: '/help' },
	{
		title: 'Become an Affiliate',
		links: '/signed/become-an-affiliate',
		src: IMAGES.userTopBarModalReseller,
	},
	{
		title: 'Let us Build Your App',
		links: '/signed/build',
		src: IMAGES.userTopBarModalBuildYourApp,
	},
	{
		title: 'My Account',
		links: '/edit-profile',
		src: IMAGES.userTopBarModalMyAccount,
	},
	// {
	// 	title: 'Subscription',
	// 	links: '/subscription',
	// 	src: IMAGES.userTopBarModalSubscription,
	// },
];

const userDataHolder = localStorage.getItem('userData');
const userParse = userDataHolder ? JSON.parse(userDataHolder) : null;

const NavBarModal: React.FC<NavBarProps> = ({
	isLogin,
	isShow,
	onHamburgerClick,
	
}) => {
	const [isSignedIn, setIsSignedIn] = useState(false);
	const [globalVariable, setGlobalVariable] = useAtom(globalVariableAtom);

	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		document.documentElement.classList.add('overflow-hidden');
		document.body.classList.add('overflow-hidden');

		return () => {
			document.documentElement.classList.remove('overflow-hidden');
			document.body.classList.remove('overflow-hidden');
		};
	}, []);

	const activeLink =
		'relative after:content-[""] after:h-[1px] after:w-full hover:after:bg-primary after:absolute after:left-0 after:bottom-[-3px] after:pointer-events-none after:opacity-1';

	const normalLink =
		'relative after:content-[""] after:h-[1px] after:w-full hover:after:bg-primary hover:after:absolute after:left-0 after:bottom-[-3px] after:transition-all after:duration-400 after:pointer-events-none after:opacity-1';
	React.useEffect(() => {
		if (!isSignedIn) {
			setGlobalVariable('initial value');
		}
	}, [isSignedIn]);

	const handleClick = () => {
		navigate('/login');
	};

	const handleSelect = (value: string) => {
		if (value === 'Logout') {
			localStorage.removeItem('token');
			localStorage.removeItem('bearerToken');
			localStorage.removeItem('userData');
			localStorage.removeItem('email');
			localStorage.removeItem('editFirstName');
			localStorage.removeItem('editLastName');
			setTimeout(() => {
				window.location.reload();
			}, 100);
		} else if (value === 'MyAccount') {
			navigate('/edit-profile');
		} else {
			navigate('/subscription');
		}
	};

	const handleBack = () => {
		setGlobalVariable('initial value');
		setTimeout(() => {
			window.location.reload();
		}, 200);
	};
	const logoutUser = () => {
		localStorage.removeItem('token');
		localStorage.removeItem('bearerToken');
		localStorage.removeItem('userData');
		localStorage.removeItem('email');
		localStorage.removeItem('editFirstName');
		localStorage.removeItem('editLastName');
		localStorage.removeItem('federatedIdentity');
		setTimeout(() => {
			window.location.reload();
		}, 100);
	};

	const [isProfileListVisible, setProfileListVisible] = useState(false);

	const toggleProfileList = () => {
	  setProfileListVisible(!isProfileListVisible);
	};

	// const [changeSelectedMenuProfileTabValue, setProfileTabValue] = useState(selectedMenuProfileTabValue);
	
	const [profileDropDownIsOpen, setProfileDropDownIsOpen] = useState(false);

	const profileDropDownClick = () => {
		setProfileDropDownIsOpen(!profileDropDownIsOpen);
	};

	const profileDropDownIcon = profileDropDownIsOpen ? faAngleUp : faAngleDown;


	const [changeSelectedMenuProfileTabValue, setProfileTabValue] = useState(defaultSelectedMenuProfileTabValue);

	const profileTabSelectedP = () => {
		// const profileTabValue = 'passwordReset';
		// setProfileTabValue('passwordReset');
		// selectedMenuProfileTabValue('passwordReset');
		const newProfileTabSelectedValue = 'profile';

		selectedMenuProfileTabValue(newProfileTabSelectedValue);
		setProfileTabValue(newProfileTabSelectedValue);

		navigate('/edit-profile');
		// setTimeout(() => {
		// 	window.location.reload();
		// }, 100);
	};

	const profileTabSelectedPR = () => {
		const newProfileTabSelectedValue = 'passwordReset';

		selectedMenuProfileTabValue(newProfileTabSelectedValue);
		setProfileTabValue(newProfileTabSelectedValue);
	};

	const profileTabSelectedCM = () => {
		const newProfileTabSelectedValue = 'cardManagement';

		selectedMenuProfileTabValue(newProfileTabSelectedValue);
		setProfileTabValue(newProfileTabSelectedValue);
	};
	
	const profileTabSelectedSUB = () => {
		const newProfileTabSelectedValue = 'subscription';

		selectedMenuProfileTabValue(newProfileTabSelectedValue);
		setProfileTabValue(newProfileTabSelectedValue);
	};

	return (
		<div
			className={`w-full h-full inset-0 fixed bg-white z-50 overflow-y-scroll ${
				isShow === true ? '' : 'hidden'
			}`}
		>
			<nav className='h-screen'>
				<div className='md:py-[11px] sm:py-[6px] py-[15px] md:px-[54px] sm:px-[30px] px-[20px] flex flex-row items-center w-full'>
					<span>
						<Link
							id='nav-link'
							to={!isLogin ? '/' : '/dashboard'}
							onClick={handleBack}
						>
							<img
								src={IMAGES.SnapToAppLogo}
								alt='snapLogo'
								draggable='false'
								className='sm:w-[214px] w-[152px] sm:h-[60px] h-[42px] duration-500'
							/>
						</Link>
					</span>
					<div className='flex ml-auto justify-end' onClick={onHamburgerClick}>
						<img
							className='w-[40px] h-[40px] overflow-y-scroll ml-[30px] block'
							src={IMAGES.userTopBarXIcon}
						/>
					</div>
				</div>
				<ul className='list-none text-black flex flex-col justify-center h-[58%] gap-10 mt-[80px] md:mt-[100px]'>
					{!isLogin
						? links.map((link, key) =>
							[
								'/login',
								'/approver/login',
								'/thankyou-signup',
								'/signup',
								'/forgot-email',
								'/forgot-request-email',
								'/reset-password',
								'/success-forgot-password',
							].includes(location.pathname) ? null : (
									<div className='flex flex-row items-center gap-3'>
										<img
											src={link.src}
											alt='snapIcon'
											draggable='false'
											className='w-[30px] h-[30px] '
										/>
										<li key={key} className='' onClick={onHamburgerClick}>
											<NavLink
												id='nav-link'
												className={({ isActive }: { isActive: boolean }) =>
													isActive ? activeLink : normalLink
												}
												to={link.links}
											>
												{link.title}
											</NavLink>
										</li>
									</div>
								)
						  )
						: linksSignedIn.map((link, key) => (
								
							<li
								key={key}
								className='text-xl hover:text-[#FF7F00] py-2'
								onClick={link.title === 'My Account' ? () => {} : onHamburgerClick}
							>
								{link.title === 'My Account' ? (
										
									<div>
										<div className={'flex flex-row items-center gap-3 '} onClick={() => {toggleProfileList(); profileDropDownClick();}}>
											<img
												src={link.src}
												alt='snapIcon'
												draggable='false'
												className='w-[30px] h-[30px] '
											/>
											<span className=' cursor-pointer'>
												{link.title}
											</span>
											<div className=' cursor-pointer'>
												<FontAwesomeIcon icon={profileDropDownIcon} />
											</div>
										</div>
										
										{isProfileListVisible && (
											<ul className='list-none space-y-10 space-x-5 '>
												<li className={'pt-8 pl-5 text-black hover:text-[#FF7F00]'} onClick={() => {profileTabSelectedP(); onHamburgerClick();}}>
													<NavLink
														id='nav-link'
														// className={({ isActive }: { isActive: boolean }) =>
														// 	isActive ? activeLink : normalLink
														// }
														to={'/edit-profile'}
													>
																Profile
													</NavLink>
												</li>
												<li className={'text-black hover:text-[#FF7F00]'} onClick={() => {profileTabSelectedPR(); onHamburgerClick();}}>
													<NavLink
														id='nav-link'
														// className={({ isActive }: { isActive: boolean }) =>
														// 	isActive ? activeLink : normalLink
														// }
														to={'/edit-profile'}
													>
																Password Settings
													</NavLink>
															
												</li>
												<li className={'text-black hover:text-[#FF7F00]'} onClick={() => {profileTabSelectedCM(); onHamburgerClick();}}>
													<NavLink
														id='nav-link'
														to={'/edit-profile'}
													>
																Card Management
													</NavLink>
															
												</li>
												{/* 
														
														My Account Profile additional options - Temporarily disabled (API not yet existing for this)
														
														
														<li className='text-black hover:text-[#FF7F00]' onClick={() => {profileTabSelectedCM(); onHamburgerClick();}}>
															<NavLink
																id='nav-link'
																// className={({ isActive }: { isActive: boolean }) =>
																// 	isActive ? activeLink : normalLink
																// }
																to={'/edit-profile'}
															>
																	Card Management
															</NavLink>
														</li>
														<li className='text-black hover:text-[#FF7F00]' onClick={() => {profileTabSelectedSUB(); onHamburgerClick();}}>
															<NavLink
																id='nav-link'
																// className={({ isActive }: { isActive: boolean }) =>
																// 	isActive ? activeLink : normalLink
																// }
																to={'/edit-profile'}
															>
																	Subscription
															</NavLink>
														</li> */}
											</ul>
										)}	
									</div>
								) : (

									<div className='flex flex-row items-center gap-3'>
										<img
											src={link.src}
											alt='snapIcon'
											draggable='false'
											className='w-[30px] h-[30px] '
										/>
										<NavLink
											id='nav-link'
											className={({ isActive }: { isActive: boolean }) =>
												isActive ? activeLink : normalLink
											}
											to={link.links}
										>
											{link.title}
										</NavLink>
									</div>
								)}

								{/* <div className='flex flex-row items-center gap-3'>
										<img
											src={link.src}
											alt='snapIcon'
											draggable='false'
											className='w-[30px] h-[30px] '
										/>
										<NavLink
											id='nav-link'
											className={({ isActive }: { isActive: boolean }) =>
												isActive ? activeLink : normalLink
											}
											to={link.links}
										>
											{link.title}
										</NavLink>
									</div> */}


							</li>
						  ))}
					{isLogin ? (
						<div className='flex flex-col gap-10'>
							<li onClick={logoutUser} className=' hover:text-red-600 text-xl'>
								<div className='flex flex-row items-center gap-3'>
									<img
										src={IMAGES.userTopBarModalLogout}
										alt='snapLogo'
										draggable='false'
										className='w-[30px] h-[30px] '
									/>

									<NavLink
										id='nav-link'
										className={({ isActive }: { isActive: boolean }) =>
											isActive ? activeLink : normalLink
										}
										to={'/'}
									>
										Logout
									</NavLink>
								</div>
							</li>
						</div>
					) : (
						''
					)}
					{!isLogin ? (
						[
							'/login',
							'/approver/login',
							'/thankyou-signup',
							'/signup',
							'/forgot-email',
							'/forgot-request-email',
							'/reset-password',
							'/success-forgot-password',
						].includes(location.pathname) ? null : (
								<div className='flex flex-row items-center'>
									<Button
										className='px-[30px] md:px-[46px] py-[6px] md:py-[10px] sm:hidden bg-[#E0781D] hover:bg-[#FF491A]'
										variant='primary'
										onClick={handleClick}
									>
									Sign In
									</Button>
								</div>
							)
					) : (
						''
					)}
				</ul>
			</nav>
		</div>
	);
};

export default NavBarModal;