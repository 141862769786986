import { IMAGES } from 'utility/constant';
import React from 'react';

const LandingPagePersonalize = () => {
	return (
		<div className='max-sm:w-[100%] w-full h-fit bg-[#FDEFD6]'>
			<div className='flex flex-col items-center justify-center'>
				<div className='flex flex-col text-[#222222] w-full items-center px-[10px]'>
					<h1 className='text-5xl font-semibold text-center md:w-full sm:w-[420px] w-[274px] md:mt-[3vw] sm:mt-[25px] mt-[40px]'>
						Personalize Your Business
					</h1>
					<p className='break-words md:mt-[15px] sm:mt-[30px] mt-[49px] md:w-[400px] sm:w-[466px] w-full text-center text-[#666666]'>
						Customize your app&apos;s interface to fit your business perfectly with our
						easy-to-use tools.
					</p>
					<img
						className='md:w-[687px] md:h-[387px] sm:w-[610px] sm:h-[337px] w-[291px] h-[161px] mt-[36px] mb-[69px]'
						src={IMAGES.LandingImageLaptop2}
						alt='Landing Image'
					/>
				</div>
			</div>
		</div>
	);
};

export default LandingPagePersonalize;
