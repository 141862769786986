import { atom } from 'jotai';
import { GetUserCredentialsResponse, LoginResponseType } from 'services/requests/auth/schema';

//get the token from local storage
const getAuthenticationToken = (): LoginResponseType => {
	const initialUser: LoginResponseType = {
		access_token: '',
		expires_in: 0,
		refresh_expires_in: 0,
		refresh_token: '',
		token_type: '',
		identityProvider: [],
	};

	const token = localStorage.getItem('token');
    
	if (token) {
		return JSON.parse(token) as LoginResponseType;
	}

	return initialUser;
};

const currentAuthAtom = atom<LoginResponseType>(getAuthenticationToken());

export const authAtom = atom<LoginResponseType, LoginResponseType>(
	(get) => get(currentAuthAtom),
	(get, set, update) => {
		set(currentAuthAtom, update);
		localStorage.setItem('token', JSON.stringify(update));
		localStorage.setItem('bearerToken', update.access_token);
	}
);

export const globalVariableAtom = atom<string>('initial value');

export const setGlobalVariableAtom = atom(
	null,
	(get, set, newValue: string) => {
		set(globalVariableAtom, newValue);
	}
);


export const globalFormSubmitted = atom<boolean>(false);

export const setGlobalFormSubmittedAtom = atom(
	null,
	(get, set, newValue: boolean) => {
		set(globalFormSubmitted, newValue);
	}
);

export const globalUserEmail = atom<string>('');

export const hasUserCredentials = atom<boolean>(false);

export const userCredentialsAtom = atom<GetUserCredentialsResponse | undefined>(undefined);