import { z } from 'zod';

export const GetOgTagsSchema = z.object({
	OGTag: z.object({
		OGTagID:        z.number(),
		appID:            z.number(),
		imageUploadID:    z.number(),
		imageURL:         z.string(), 
		env:              z.string(), 
		status:           z.string(), 
		whoAdded:         z.number(),
		whoUpdated:       z.number(),
		whenAdded:        z.string(), 
		whenUpdated:      z.string(), 
	})
});

// export const GetAllOgTagsSchema = z.object({
// 	rows: z.array(
// 		GetOgTagsSchema
// 	)
// });

export const CreateEditOgTagsImgUploadSchema = z.object({
	imageUploadID:    z.number().optional(),
});

export const UploadOgTagsImageSchema = z.object({
	ImageUpload: z.object({
		imageUploadID:  z.number(),
		appID:          z.number(),
		name:           z.string(),
		imageURL:       z.string(),
		status:         z.string(),
		whoAdded:       z.number(),
		whoUpdated:     z.number(),
		whenAdded:      z.string(),
		whenUpdated:    z.string(),
	})
});

export const UploadOgTagsImageParamsSchema = z.object({
	file: z.any()
});

export type CreateEditOgTagsImgUploadType   = z.infer<typeof CreateEditOgTagsImgUploadSchema>;

// export type GetAllOgTagsResponse   	   = z.infer<typeof GetAllOgTagsSchema>;
export type GetOgTagsResponse   		   = z.infer<typeof GetOgTagsSchema>;

export type UploadOgTagsImageResponse      = z.infer<typeof UploadOgTagsImageSchema>;
export type UploadOgTagsImageParams        = z.infer<typeof UploadOgTagsImageParamsSchema>;