import AppPreviewComponent from '..';
import React from 'react';

const AppPreviewPublish: React.FC = () => {

	return (
		<div className='w-[21.875rem] relative  right-0 top-0'>
			<div className='bg-white rounded-lg mt-4 h-[720px] w-[350px] 2xl:h-[720px] xl:h-[720px] lg:h-[720px] md:h-[590px] sm:h-[590px] max-sm:h-[590px] min-sm:h-[590px]'>
				<div className='px-7 lg:px-7 lg:ml-0 md:ml-10 sm:ml-10 max-sm:ml-10'>
					<div className='text-center text-[#464255] font-bold text-lg pt-4 pb-2'>
						App Preview
					</div>
					<div
						className={
							'w-full h-[600px] mt-8 rounded-3xl border-8 border-gray-600 relative bg-[#F7F7F7] 2xl:h-[600px] 2xl:w-full xl:h-[600px] xl:w-full lg:w-full lg:h-[600px] md:w-[250px] md:h-[450px] sm:w-[220px] sm:h-[430px] max-sm:w-[220px] max-sm:h-[430px] min-sm:w-[220px] min-sm:h-[430px] '
						}
					>
						<div className='w-full flex justify-center absolute z-10'>
							<div className='rounded-lg bg-gray-600 w-[115px] h-[20px] -mt-1 flex justify-center items-center gap-2 '>
								<div className='rounded-lg bg-white w-[30px] h-[4px]' />
								<div className='rounded-full bg-white w-[4px] h-[4px]' />
							</div>
						</div>
						<AppPreviewComponent/>
					</div>
				</div>
			</div>
		</div>
	);
};
export default AppPreviewPublish;