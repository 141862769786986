import * as offerApis from '../../../../../services/requests/Features/Offers/index';
import * as typeSchemas from 'services/requests/Features/Offers/schema';

import {
	FIsFeaturesChange,
	FIsOffersChanges,
} from 'store/CustomizeApp/Features';
import {
	FIsOfferRefresh,
	FOffers,
	FTempIds,
	FTempOffers,
} from 'store/CustomizeApp/Features/offersAtom';
import { useAtom, useSetAtom } from 'jotai';

import { AxiosError } from 'axios';
import { PIsPublishShowOffer } from 'store/CustomizeApp/Publish/publishAtom';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { log } from 'utility/log';

export const OfferUseQuery = () => {
	const { id } = useParams();
	const [isOfferRefresh, setIsOfferRefresh] = useAtom(FIsOfferRefresh);
	const [offers, setOffers] = useAtom(FTempOffers);
	const [tempID, setTempID] = useAtom(FTempIds);
	const setFeaturesChanges = useSetAtom(FIsFeaturesChange);
	const setOfferChanges = useSetAtom(FIsOffersChanges);
	const setPublishShow = useSetAtom(PIsPublishShowOffer);

	const { data: offerLive, refetch: refetchOfferLive } = useQuery<
		typeSchemas.GetOffersWithPaginationResponse,
		AxiosError
	>({
		queryKey: ['getOffersLive', id],
		queryFn: () => offerApis.getOffersApiLive(id),
		enabled: false,
		retry: 0,
		onError: () => {
			setIsOfferRefresh(false);
		},
	});

	const { data, refetch, isFetching, isFetched } = useQuery<
		typeSchemas.GetOffersWithPaginationResponse,
		AxiosError
	>({
		queryKey: ['getOffers', id],
		queryFn: () => offerApis.getOffersApi(id),
		enabled: false,
		retry: 0,
		onSuccess: (data) => {
			if (data.rows) {
				setOffers(data.rows);
				setIsOfferRefresh(false);
			} else {
				setOffers([]);
				setTempID([]);
				setIsOfferRefresh(false);
			}
		},
		onError: () => {
			setIsOfferRefresh(false);
		},
	});

	useEffect(() => {
		if (isOfferRefresh) {
			// setOffers([]);
			// setTempID([]);
			refetch();
			refetchOfferLive();
		}
	}, [isOfferRefresh]);

	// useEffect(() => {
	// 	if (isFetching) {
	// 		setFeaturesChanges(false);
	// 	}
	// }, [isFetching]);

	useEffect(() => {
		if ((data?.rows !== undefined || offers?.length !== 0) && isFetched) {
			const hasBlankFields = offers.some((temp) => {
				const trimTitle = temp.title?.trim();
				const trimDescription = temp.description?.trim();
				const trimTerms = temp.termsAndCondition?.trim();
				const trimCode = temp.code?.trim();
				return (
					trimTitle?.length === 0 ||
					trimDescription?.length === 0 ||
					trimTerms?.length === 0 ||
					trimCode?.length === 0
				);
			});

			if (hasBlankFields) {
				// setFeaturesChanges(false);
				setOfferChanges(false);
			} else {
				const checkChanges = data?.rows?.map((current) => ({
					match: offers?.some(
						(temp) =>
							temp.title === current.title &&
							temp.buy === current.buy &&
							temp.code === current.code &&
							temp.couponType === current.couponType &&
							temp.description === current.description &&
							temp.discount === current.discount &&
							temp.discountType === current.discountType &&
							temp.get === current.get &&
							temp.termsAndCondition === current.termsAndCondition
					),
				}));

				const checkOffer = offers?.filter(
					(data) =>
						data.title === '' ||
						data.description === '' ||
						data.termsAndCondition === '' ||
						(data.couponType === 1 && data.discount === '')
				);

				if (checkOffer.length === 0) {
					if (
						data?.rows?.length !== offers?.length ||
						checkChanges?.find((check) => check.match === false) !==
							undefined
					) {
						// setFeaturesChanges(true);
						setOfferChanges(true);
						log('OffersChangeTrue');
					} else {
						// setFeaturesChanges(false);
						setOfferChanges(false);
					}
				} else {
					// setFeaturesChanges(false);
					setOfferChanges(false);
				}
			}
		}
	}, [data, offers, isFetched]);

	useEffect(() => {
		if (data?.rows !== undefined && offerLive?.rows !== undefined) {
			const checkDraftLive = data?.rows?.map((current) => ({
				match: offerLive?.rows?.some(
					(temp) =>
						temp.title === current.title &&
						temp.buy === current.buy &&
						temp.code === current.code &&
						temp.couponType === current.couponType &&
						temp.description === current.description &&
						temp.discount === current.discount &&
						temp.discountType === current.discountType &&
						temp.get === current.get &&
						temp.termsAndCondition === current.termsAndCondition
				),
			}));

			if (
				checkDraftLive.find((check) => check.match === false) !==
					undefined ||
				data?.rows?.length !== offerLive?.rows?.length
			) {
				setPublishShow(true);
				log('OfferPublishShowTrue');
			} else setPublishShow(false);
		}
	}, [data?.rows, offerLive?.rows]);
};
