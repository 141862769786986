import { CreateBulkNotificationType, CreateNotificationResponse, GetAllNotificationsType, GetNotificationType } from 'services/requests/Features/Notification/schema';

import { atom } from 'jotai';
import dayjs from 'dayjs';

const currentDate = dayjs().format('MM/DD/YY');

export const FIsNotifcationRefresh = atom<boolean>(true);

export const FNotif  = atom<GetAllNotificationsType>({
	limit:0,
	page: 0,
	sort: '',
	totalRows: 0,
	totalPages: 0,
	rows: []
});

export const FAddNotifPayload  = atom<CreateBulkNotificationType>({
	notifications: []
});

export const FEditNotifPayload  = atom<CreateBulkNotificationType>({
	notifications: []
});

export const FNewNotif = atom<GetNotificationType>({
	status: '',
	message: '',
	notificationID: 0,
	appID: 0,
	icon: 'fas fa-globe',
	title: '',
	url: '',
	whoAdded: 0,
	whenAdded: `${currentDate}`,
	whenUpdated:'',
});

export const FTempNotif = atom<GetNotificationType[] | []>([]);


export const FEditNotif = atom<GetNotificationType>({
	status: '',
	message: '',
	notificationID: 0,
	appID: 0,
	icon: '',
	title: '',
	url: '',
	whoAdded: 0,
	whenAdded:'',
	whenUpdated:'',
});

export const FaddNotificationData = (notifications: GetAllNotificationsType, newNotification: CreateNotificationResponse ): GetAllNotificationsType => {
	const newTotalRows = notifications.totalRows + 1;
	if(notifications?.rows?.length) {
		newNotification.notificationID = (Math.max(...notifications.rows.map(item => item.notificationID)));
		newNotification.notificationID += 1;
	}
	else{
		newNotification.notificationID = 1;
	}
	
	const newRows = [...notifications.rows, {
		status: '',
		message: '',
		notificationID: newNotification.notificationID,
		appID: 0,
		icon: 'fas fa-globe',
		title: '',
		url: '',
		whoAdded: 0,
		whenAdded: `${currentDate}`,
		whenUpdated:'',
	}];

	return {
		...notifications,
		totalRows: newTotalRows,
		rows: newRows
	};
};

export const FaddTempNotificationData = (tempNotifications: CreateBulkNotificationType, newNotification: CreateNotificationResponse ): CreateBulkNotificationType => {

	if(!tempNotifications.notifications.length) {
		const initialTempNotifications = {
			icon: newNotification.icon, 
			title: newNotification.title,
			message: newNotification.message,
			url: newNotification.url,
			notificationId: newNotification.notificationID
		};

		tempNotifications.notifications[0] = initialTempNotifications;

		return tempNotifications;
	}

	const existingNotification = tempNotifications.notifications;

	const newNotif = {
		icon: newNotification.icon, 
		title: newNotification.title,
		message: newNotification.message,
		url: newNotification.url,
		notificationId: newNotification.notificationID
	};

	const updatedTemp = {
		notifications: [...existingNotification, newNotif]
	};
	
	return updatedTemp;


};


export const FeditNotificationData = (notifications: GetAllNotificationsType, editNotification: CreateNotificationResponse): GetAllNotificationsType => {
	const { rows } = notifications;
	const index = rows.findIndex(row => row.notificationID === editNotification.notificationID);
	if (index !== -1) {
		rows[index] = editNotification;
	}

	return {
		...notifications,
		rows
	};
};

export const FeditTempNotificationData = (tempNotifications: CreateBulkNotificationType, editNotification: CreateNotificationResponse): CreateBulkNotificationType => {

	const index = tempNotifications.notifications.findIndex(data => data.notificationId === editNotification.notificationID);

	if (index !== -1) {
		tempNotifications.notifications[index].icon = editNotification.icon;
		tempNotifications.notifications[index].title = editNotification.title;
		tempNotifications.notifications[index].message = editNotification.message;
		tempNotifications.notifications[index].url = editNotification.url;
	}

	return tempNotifications;
};

export const FaddNotification = atom(
	null,
	(get, set) => {
		set(FNotif, FaddNotificationData(get(FNotif), get(FNewNotif)));
		set(FAddNotifPayload, FaddTempNotificationData(get(FAddNotifPayload), get(FNewNotif)));
	}
);

export const FeditNotification = atom(
	null,
	(get, set, ) => {
		set(FNotif, FeditNotificationData(get(FNotif), get(FEditNotif)));
		set(FAddNotifPayload, FeditTempNotificationData(get(FAddNotifPayload), get(FEditNotif)));
	}
);