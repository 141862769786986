import Footer from 'components/Common/Footer';
import { Outlet } from 'react-router';
import PartialFooter from 'components/Common/Footer/PartialFooter';
import React from 'react';
import Topbar from 'components/Common/Topbar';
import { useLocation } from 'react-router-dom';

interface AppLayoutProps {
	isLogin: boolean;
}
const AppLayout: React.FC<AppLayoutProps> = ({ isLogin }) => {
	const location = useLocation();

	// "/callback" url for google oauth redirect URI
	const noTopbarURLS = ['/approver', '/oauth2/callback'];

	const shouldRenderPartialFooter = () => {
		const specialPaths = [
			'/login', 
			'/signup', 
			'/forgot-email', 
			'/forgot-request-email', 
			'/reset-password', 
			'/success-forgot-password', 
			'/verify-email', 
			'/thankyou-signup', 
			'/approver/login'
		];
		return isLogin || specialPaths.some(path => location.pathname.includes(path));
	};

	return (
		<div className='flex flex-col min-h-screen'>
			<div className='bg-[#F6F6F6] flex flex-col flex-1'>
				{noTopbarURLS.includes(location.pathname) ? null :
					<Topbar isLogin={isLogin} />
				}
				<main className="flex flex-col flex-1">
					<Outlet />
				</main>				
			</div>
			{ shouldRenderPartialFooter() ? <PartialFooter isLogin={isLogin}/> : <Footer />}
		</div>
	);
};

export default AppLayout;