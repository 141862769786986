import React, { useState } from 'react';
import ApproverSideBar from './ApproverSideBar';
import TopbarApprover from './AprroverTopBar';
import ApproverPanel from './ApproverPanel';
import { House, Mobile, Users, Reseller } from 'assets/Approver';

const Approver: React.FC = () => {
	const [selectedFeaturesOption, setSelectedFeaturesOption] 	  = useState('dashboard');
	const sidebarItems = [
		{
			title: 'Dashboard',
			icon: House,
			link: 'dashboard'
		},
		{
			title: 'App',
			icon: Mobile,
			link: 'app'
		},
		{
			title: 'Users',
			icon: Users,
			link: 'users'
		},
		{
			title: 'Resellers',
			icon: Reseller,
			link: 'reseller'
		},
	];

	return (
		<div className="w-full mx-auto h-[90vh]">
			<TopbarApprover />
			<div className='flex'>
				<ApproverSideBar selectedOption={selectedFeaturesOption} setSelectedOption={setSelectedFeaturesOption} items={sidebarItems} />
				<ApproverPanel selectedOption={selectedFeaturesOption} />
			</div>
			{/* Your Approver content goes here */}
		</div>
	);
};

export default Approver;
