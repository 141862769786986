import React from 'react';
import { useAtomValue } from 'jotai';
import * as subscriptionAtom from '../../../../store/CustomizeApp/Payment/subscription';
import { PaymentStepper } from 'components/PaymentTab/PaymentStepper';
import { SubscribedPlan } from 'components/PaymentTab/SubscribedPlan';

export const PaymentTabContent = () => {

	const isAlreadySubscribed   		= useAtomValue(subscriptionAtom.FIsAlreadySubscribed);
	const changePlan								= useAtomValue(subscriptionAtom.changePlanID);
	const backToChangePlan					= useAtomValue(subscriptionAtom.backToChangePlanPage);

	return (
		<div className='py-6 2xl:mx-10 2xl:px-16 xl:mx-10 xl:px-16 lg:mx-10 lg:px-16 md:mx-0 md:px-0 sm:mx-0 sm:px-0'>
			{/* <div className='py-6 2xl:px-16 xl:px-16 lg:px-0 md:px-0 sm:px-0 max-sm:px-[-40px] max-sm:mx-0  h-auto mx-10'> */}
			<p className='pt-2 text-[#464255] font-semibold text-xl max-sm:text-base'> 
				{isAlreadySubscribed ? 'Change Your Current Plan' : 'Choose Your Subscription Plan'}
			</p>
			<div>
				{
					!isAlreadySubscribed || changePlan
						? <PaymentStepper/>
						: backToChangePlan 
							? <SubscribedPlan/>
							: <SubscribedPlan/>
				}
			</div>
		</div>
	);
};