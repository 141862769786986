import React, { useEffect, useLayoutEffect } from 'react';
import { IMAGES } from 'utility';
import Footer from 'components/Common/Footer';

const TermsOfUseLanding: React.FC =() => {

	useEffect(() => {
		window.scrollTo(0,0);
	},[]);

	useLayoutEffect(() => {
		document.body.style.backgroundColor = '#FFFFFF';
	});
      
	return (
		<div className='w-full h-full'>
			<div id='my-header' className='bg-white flex justify-center items-center flex-col'>
				<div className='bg-white relative w-full h-[50vh] flex justify-center items-center'>
					<img className='object-cover w-full h-full' src={IMAGES.TermsOfUseImage}/>
					<span className='absolute font-bold text-white text-[60px] max-sm:text-[42px] text-center tracking-wider'>Terms Of Use</span>
				</div>
				<div className='bg-white relative w-full h-full pl-24 pr-24 max-sm:pl-8 max-sm:pr-8 mt-16 tracking-wider'>
					<p className='font-bold text-[#403E3C]'>Introduction</p>
					<p className='mt-5'>Welcome to SnapToApp, a mobile app builder platform that enables users to create their own mobile apps without needing vast programming or technical knowledge. By accessing or using the SnapToApp platform, you agree to be bound by the following Terms of Use:</p>
					<p className='mt-5'><span className='font-bold text-[#403E3C]'>1. Eligibility:</span> You must be at least 18 years old or the legal age of majority in your jurisdiction to use the SnapToApp platform. If you are accessing the platform on behalf of a company or organization, you must have the authority to bind that company or organization to these Terms of Use.</p>
					<p className='mt-5'><span className='font-bold text-[#403E3C]'>2. Use of the Platform:</span> You may use the SnapToApp platform solely for the purpose of creating and publishing mobile applications. You may not use the platform for any illegal or unauthorized purpose, or in any way that violates these Terms of Use. SnapToApp reserves the right to suspend or terminate your access to the platform if it believes, in its sole discretion, that you have violated these Terms of Use. </p>
					<p className='mt-5'><span className='font-bold text-[#403E3C]'>3. Intellectual Property:</span> The SnapToApp platform and all content and materials contained therein, including but not limited to text, graphics, images, software, and source code, are the property of SnapToApp or its licensors and are protected by copyright, trademark, and other intellectual property laws. You may not use, copy, reproduce, modify, distribute, transmit, display, or create derivative works of any portion of the platform without the prior written consent of SnapToApp.</p>
					<p className='mt-5'><span className='font-bold text-[#403E3C]'>4. User Content:</span> You are solely responsible for any content that you create or upload to the SnapToApp platform, including but not limited to images, text, and multimedia content. By creating or uploading content, you grant SnapToApp a non-exclusive, royalty-free, worldwide license to use, copy, reproduce, modify, distribute, transmit, display, and create derivative works of such content for the purpose of providing the platform to you and other users.</p>
					<p className='mt-5'><span className='font-bold text-[#403E3C]'>5. Privacy:</span> SnapToApp collects and uses personal information in accordance with its Privacy Policy, which is incorporated into these Terms of Use by reference. By using the platform, you consent to the collection, use, and disclosure of your personal information as described in the Privacy Policy.</p>
					<p className='mt-5'><span className='font-bold text-[#403E3C]'>6. Limitation of Liability:</span> SnapToApp provides the platform on an &quot;as is&quot; and &quot;as available&quot; basis, and makes no warranties or representations of any kind, express or implied, as to the operation of the platform or the information, content, materials, or products included on the platform. To the fullest extent permitted by applicable law, SnapToApp disclaims all warranties, express or implied, including but not limited to implied warranties of merchantability and fitness for a particular purpose. In no event shall SnapToApp be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with the use or inability to use the platform, even if SnapToApp has been advised of the possibility of such damages.</p>
					<p className='mt-5'><span className='font-bold text-[#403E3C]'>7. Indemnification:</span> You agree to indemnify and hold harmless SnapToApp, its affiliates, and their respective directors, officers, employees, and agents from and against any and all claims, liabilities, damages, losses, costs, and expenses, including reasonable attorneys&apos; fees, arising out of or in connection with your use of the platform or your breach of these Terms of Use.</p>
					<p className='mt-5'><span className='font-bold text-[#403E3C]'>8. Modifications to Terms of Use:</span> SnapToApp reserves the right to modify these Terms of Use at any time, without notice to you. Your continued use of the platform after any such modifications constitutes your acceptance of the modified Terms of Use.</p>
					<p className='my-10 mb-40'><span className='font-bold text-[#403E3C]'>9. Governing Law:</span> These Terms of Use shall be governed by and construed in accordance with the laws of the jurisdiction in which SnapToApp is incorporated, without giving effect to any principles of conflicts of law.</p>
				</div>
			</div>
		</div>
	);

};

export default TermsOfUseLanding;