import { EditFontSettingResponse, EditFontSettingSchema, GetAllFontsSettingResponse, GetFontSettingResponse } from './schema';

import { axiosInstance } from 'services/axios';

export const getAllFontsSettingByAppID = async (appID: number) => {
	const response = await axiosInstance.get(`/organizations/apps/${appID}/fonts`);
    
	return response.data as GetAllFontsSettingResponse;
};

export const getFontSettingByAppIDLive = async (appID?: string, fontID?: number) => {
	const response = await axiosInstance.get(`/organizations/apps/${appID}/fontSettings/${fontID}?env=LIV`);
    
	return response.data as GetFontSettingResponse;
};

export const getFontSettingByAppID = async (appID?: string, fontID?: number) => {
	const response = await axiosInstance.get(`/organizations/apps/${appID}/fontSettings/${fontID}`);
    
	return response.data as GetFontSettingResponse;
};

export const editFontSettingByAppID = async (params: EditFontSettingSchema, appID?: string, fontID?: number) => {
	const response = await axiosInstance.put(`/organizations/apps/${appID}/fontSettings/${fontID}`,  params);
    
	return response.data as EditFontSettingResponse;
};