import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../SideBar/SideBarAnimation/range.css';

import { AnalyticsRowSchema, DimensionHeadersSchema, DimensionValuesSchema, GetAnalyticsDataRequest, GetRunReportResponse, MetricHeadersSchema, MetricValuesSchema } from 'services/requests/Telemetry/schema';
import { DateRange, DateRangePicker, DefinedRange } from 'react-date-range';
import { InformationCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import React, { useEffect, useRef, useState } from 'react';
import { getAudienceByCountry, getPageViews, getSessionsByChannel, getTotalUsers } from 'services/requests/Telemetry';

import ArrowDropDownSharpIcon from '@mui/icons-material/ArrowDropDownSharp';
import { AxiosError } from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IMAGES } from 'utility/constant';
import { addDays } from 'date-fns';
import { classNames } from 'utility';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'react-responsive';
import { useQuery } from 'react-query';
import { Button } from 'components/Common/Buttons';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';


// import MetricsGraph from 'components/Common/MetricsCard/MetricsGraph';
// import MetricsNumber from 'components/Common/MetricsCard/MetricsNumber';
// import { useAtom } from 'jotai';

interface AppSidebarTelemetry {
	appID: number;
	appName: string;
	appImage?: string | undefined;

}

type TDateRange = {
	startDate?: Date | undefined;
	endDate?: Date | undefined;
	key?: string | undefined;
}


const AppSideBarTelemetry: React.FC<AppSidebarTelemetry> = ({ appID, appName, appImage }) => {
	const [isNoteVisible, setIsNoteVisible] = useState(true);

	const calendarScrollRef = useRef<HTMLDivElement>(null);
	const [state, setState] = useState<TDateRange[]>([
		{
			startDate: new Date(),
			endDate: new Date(),
			key: 'selection',
		}
	]);

	const [selectedAppID, setSelectedAppID] = useState(appID.toString());
	const [startingDateRange, setStartingDateRange] = useState<string>('today');
	const [endingDateRange, setEndingDateRange] = useState<string>('today');

	const [displayedStartingDateRange, setDisplayedStartingDateRange] = useState<string | undefined>('Loading...');
	const [displayedEndingDateRange, setDisplayedEndingDateRange] = useState<string | undefined>('Loading...');

	const xsViewPort = useMediaQuery({ maxWidth: 420 });
	const lowerSmViewPort = useMediaQuery({ maxWidth: 475 });

	//Total User
	const [totalUserValue, setTotalUserValue] = useState('Loading...');
	const [todaysTotalUserValue, setTodaysTotalUserValue] = useState('Loading...');

	const [totalStartPercentageUserValue, setStartPercentageTotalUserValue] = useState('...%');

	const [totalPercentageUserValue, setPercentageTotalUserValue] = useState('...%');

	//Active User
	const [activeUserValue, setActiveUserValue] = useState('Loading...');
	const [todaysActiveUserValue, setTodaysActiveUserValue] = useState('Loading...');

	const [activeStartPercentageUserValue, setStartPercentageActiveUserValue] = useState('...%');

	const [activePercentageUserValue, setPercentageActiveUserValue] = useState('...%');

	//New User
	const [newUserValue, setNewUserValue] = useState('Loading...');
	const [todaysNewUserValue, setTodaysNewUserValue] = useState('Loading...');

	const [newStartPercentageUserValue, setStartPercentageNewUserValue] = useState('...%');

	const [newPercentageUserValue, setPercentageNewUserValue] = useState('...%');

	const [countryAudience, setCountryAudience] = useState(['...']);
	const updatedAudienceArray = [...countryAudience];
	const [countryPageViews, setCountryPageViews] = useState(['...']);
	const updatedPageViewsArray = [...countryPageViews];
	const [countryBounceRate, setCountryBounceRate] = useState(['...']);
	const updatedBounceRateArray = [...countryBounceRate];

	const [allPageViews, setAllPageViews] = useState('...');

	const [sessionDevice, setSessionDevice] = useState(['...']);
	const updatedDeviceArray = [...sessionDevice];
	const [sessionVisits, setSessionVisits] = useState(['...']);
	const updatedVisitsArray = [...sessionVisits];
	const [sessionAVGTime, setSessionAVGTime] = useState(['...']);
	const updatedAVGTimeArray = [...sessionAVGTime];

	const { data: totalUsers, refetch: totalUsersRefetch } = useQuery<GetRunReportResponse, AxiosError>({
		queryKey: ['totalUsers'],
		queryFn: () => getTotalUsers(selectedAppID, startingDateRange, endingDateRange),
		onSuccess: (data) => {

			if (data?.rows && data?.rows.length > 1) {
				setActiveUserValue(data?.rows[1]?.metricValues[0].value);
				setNewUserValue(data?.rows[1]?.metricValues[1].value);
				setTotalUserValue(data?.rows[1]?.metricValues[2].value);

			}
			else {
				setTotalUserValue('No Data found');
				setActiveUserValue('No Data found');
				setNewUserValue('No Data found');
			}
		},
		onError: () => {
			setTotalUserValue('Failed to load');
			setActiveUserValue('Failed to load');
			setNewUserValue('Failed to load');
		},
		retry: 0

	});

	const { data: startToYesterdayTotalUsers, refetch: startToYesterdayTotalUsersRefetch } = useQuery<GetRunReportResponse, AxiosError>({
		queryKey: ['totalUsers'],
		queryFn: () => getTotalUsers(selectedAppID, startingDateRange, 'yesterday'),
		onSuccess: (data) => {

			if (data?.rows && data?.rows.length > 1) {
				setStartPercentageActiveUserValue(data?.rows[1].metricValues[0].value);
				setStartPercentageNewUserValue(data?.rows[1].metricValues[1].value);
				setStartPercentageTotalUserValue(data?.rows[1].metricValues[2].value);

			}
			else {
				setTotalUserValue('No Data found');
				setActiveUserValue('No Data found');
				setNewUserValue('No Data found');
			}
		},
		onError: () => {
			setTotalUserValue('Failed to load');
			setActiveUserValue('Failed to load');
			setNewUserValue('Failed to load');
		},
		retry: 0

	});

	const { data: todaysTotalUsers, refetch: todaysTotalUsersRefetch } = useQuery<GetRunReportResponse, AxiosError>({
		queryKey: ['totalUsers'],
		queryFn: () => getTotalUsers(selectedAppID, 'today', 'today'),
		onSuccess: (data) => {

			if (data?.rows && data?.rows.length > 1) {
				setTodaysActiveUserValue(data?.rows[1].metricValues[0].value);
				setTodaysNewUserValue(data?.rows[1].metricValues[1].value);
				setTodaysTotalUserValue(data?.rows[1].metricValues[2].value);


				const formattedStartActiveUserPercentage = parseInt(activeStartPercentageUserValue);
				const formattedStartNewUserPercentage = parseInt(newStartPercentageUserValue);
				const formattedStartTotalUserPercentage = parseInt(totalStartPercentageUserValue);

				const formattedTodayActiveUserPercentage = parseInt(todaysActiveUserValue);
				const formattedTodayNewUserPercentage = parseInt(todaysNewUserValue);
				const formattedTodayTotalUserPercentage = parseInt(todaysTotalUserValue);

				const firstCalculationTodayActiveUserPercentage = formattedStartActiveUserPercentage - formattedTodayActiveUserPercentage;
				const firstCalculationTodayNewUserPercentage = formattedStartNewUserPercentage - formattedTodayNewUserPercentage;
				const firstCalculationTodayTotalUserPercentage = formattedStartTotalUserPercentage - formattedTodayTotalUserPercentage;

				const secondCalculationTodayActiveUserPercentage = (formattedStartActiveUserPercentage + formattedTodayActiveUserPercentage) / 2;
				const secondCalculationTodayNewUserPercentage = (formattedStartNewUserPercentage + formattedTodayNewUserPercentage) / 2;
				const secondCalculationTodayTotalUserPercentage = (formattedStartTotalUserPercentage + formattedTodayTotalUserPercentage) / 2;

				const finalCalculationTodayActiveUserPercentage = (firstCalculationTodayActiveUserPercentage / secondCalculationTodayActiveUserPercentage) * 100;
				const finalCalculationTodayNewUserPercentage = (firstCalculationTodayNewUserPercentage / secondCalculationTodayNewUserPercentage) * 100;
				const finalCalculationTodayTotalUserPercentage = (firstCalculationTodayTotalUserPercentage / secondCalculationTodayTotalUserPercentage) * 100;



				if (Number.isNaN(finalCalculationTodayActiveUserPercentage)) {
					setPercentageActiveUserValue('0%');
				} else {
					setPercentageActiveUserValue(finalCalculationTodayActiveUserPercentage.toString() + '%');
				}

				if (Number.isNaN(finalCalculationTodayNewUserPercentage)) {
					setPercentageNewUserValue('0%');
				} else {
					setPercentageNewUserValue(finalCalculationTodayNewUserPercentage.toString() + '%');
				}

				if (Number.isNaN(finalCalculationTodayTotalUserPercentage)) {
					setPercentageTotalUserValue('0%');
				} else {
					setPercentageTotalUserValue(finalCalculationTodayTotalUserPercentage.toString() + '%');
				}
			}
			else {
				setTotalUserValue('No Data found');
				setActiveUserValue('No Data found');
				setNewUserValue('No Data found');
			}
		},
		onError: () => {
			setTotalUserValue('Failed to load');
			setActiveUserValue('Failed to load');
			setNewUserValue('Failed to load');
		},
		retry: 0

	});

	const { data: audienceByCountry, refetch: audienceByCountryRefetch } = useQuery<GetRunReportResponse, AxiosError>({
		queryKey: ['audienceByCountry'],
		queryFn: () => getAudienceByCountry(selectedAppID, startingDateRange, endingDateRange),
		onSuccess: (data) => {
			if (data?.rows) {

				for (let i = 0; i < data?.rows.length; i++) {
					updatedAudienceArray[i] = data?.rows[i].dimensionValues[0].value;
					updatedPageViewsArray[i] = data?.rows[i].metricValues[0].value;
					updatedBounceRateArray[i] = data?.rows[i].metricValues[1].value;
					const percentBounceRate = parseFloat(updatedBounceRateArray[0]) * 100;
					updatedBounceRateArray[i] = percentBounceRate.toString().split('.')[0] + '%';
				}

				setCountryAudience(updatedAudienceArray);
				setCountryPageViews(updatedPageViewsArray);
				setCountryBounceRate(updatedBounceRateArray);
			}
			else {
				setCountryAudience(['No available data']);
				setCountryPageViews(['']);
				setCountryBounceRate(['']);
			}
		},
		onError: () => {
			setCountryAudience(['Failed to load']);
		},
		retry: 0

	});

	const { data: pageViews, refetch: pageViewsRefetch } = useQuery<GetRunReportResponse, AxiosError>({
		queryKey: ['pageViews'],
		queryFn: () => getPageViews(selectedAppID, startingDateRange, endingDateRange),
		onSuccess: (data) => {
			if (data?.rows) {
				setAllPageViews('Existing Value found');
			}
			else {
				setAllPageViews('No Existing Data found');
			}
		},
		onError: () => {
			setAllPageViews('Failed to load');
		},
		retry: 0

	});

	const { data: sessionsByChannel, refetch: sessionsByChannelRefetch } = useQuery<GetRunReportResponse, AxiosError>({
		queryKey: ['sessionsByChannel'],
		queryFn: () => getSessionsByChannel(selectedAppID, startingDateRange, endingDateRange),
		onSuccess: (data) => {
			if (data?.rows) {

				for (let i = 0; i < data?.rows.length; i++) {
					updatedDeviceArray[i] = data?.rows[i].dimensionValues[0].value;
					updatedVisitsArray[i] = data?.rows[i].metricValues[0].value;
					const roundedConversionVisit = parseFloat(updatedVisitsArray[i]).toFixed(1);
					updatedVisitsArray[i] = roundedConversionVisit.toString() + '%';
					// .toString().split('.')[0] + '%';
					updatedAVGTimeArray[i] = data?.rows[i].metricValues[1].value;

					const capitalizedString = data?.rows[i].dimensionValues[0].value.charAt(0).toUpperCase() + data?.rows[i].dimensionValues[0].value.slice(1);

					updatedDeviceArray[i] = capitalizedString;


					const percentAVGTime = parseInt(updatedAVGTimeArray[i]);



					const min = (Math.floor(percentAVGTime / 60)).toString();
					const sec = (percentAVGTime % 60).toString();
					const hour = (Math.floor(parseInt(min) / 60)).toString();

					if (parseInt(min) === 0) {
						updatedAVGTimeArray[i] = sec + 's';
					}
					else if (parseInt(hour) === 0) {
						updatedAVGTimeArray[i] = min + 'm ' + sec + 's';
					}
					else {
						updatedAVGTimeArray[i] = hour + 'h ' + min + 'm ' + sec + 's';
					}

					// updatedAVGTimeArray[i] = percentAVGTime.toString();

					// updatedAVGTimeArray[i] = percentAVGTime.toString().split('.')[0];

					// updatedAVGTimeArray[i] = data?.rows[i].metricValues[1].value;
				}

				setSessionDevice(updatedDeviceArray);
				setSessionVisits(updatedVisitsArray);
				setSessionAVGTime(updatedAVGTimeArray);
			}
			else {
				setSessionDevice(['No available data']);
				setSessionVisits(['']);
				setSessionAVGTime(['']);
			}
		},
		onError: () => {
			setSessionDevice(['Failed to load']);
		},
		retry: 0

	});



	const handleAppliedDateRange = () => {
		totalUsersRefetch();
		startToYesterdayTotalUsersRefetch();
		todaysTotalUsersRefetch();
		audienceByCountryRefetch();
		pageViewsRefetch();
		sessionsByChannelRefetch();
		setDateRangeToggled(!dateRangeToggled);
		console.log('starting date:', startingDateRange);
		console.log('ending date:', endingDateRange);
	};

	// Calendar Positioning when Display Date Range is Clicked

	const calendarPositionToScroll = 190;

	const [scrolledCalendarToPosition, setScrolledCalendarToPosition] = useState(false);
	const scrollCalendarToPosition = () => {

		if (calendarScrollRef.current) {
			calendarScrollRef.current.scrollTo(0, calendarPositionToScroll);
		}
	};

	useEffect(() => {
		if (!scrolledCalendarToPosition) {
			scrollCalendarToPosition();
		}
	}, [calendarScrollRef.current]);

	const [dateRangeToggled, setDateRangeToggled] = useState(false);

	const showDateRangeButtonClick = () => {
		setDateRangeToggled((prevState) => !prevState);

		setTimeout(() => {

			setScrolledCalendarToPosition(true);
		}, 1);

	};

	const cancelDateRangeButtonClick = () => {
		setState([  
			{
				startDate: new Date(),
				endDate: new Date(),
				key: 'selection',
			}
		]);
		setDateRangeToggled(false);
	};

	const formatDateString = (date: Date) => {
		if (!date) return null; // Handle null or undefined dates

		const d = new Date(date);
		const year = d.getFullYear();
		const month = String(d.getDate()).padStart(2, '0');
		const day = String(d.getMonth() + 1).padStart(2, '0');

		return `${year}-${day}-${month}`;
	};

	useEffect(() => {
		const mappedState = state.map(item => {
			if (item.startDate) {
				const startDate = formatDateString(item.startDate);

				setStartingDateRange(startDate || 'today'); // Providing a default empty string if null
			}

			if (item.endDate) {
				const endDate = formatDateString(item.endDate);

				setEndingDateRange(endDate || 'today'); // Providing a default empty string if null
			}


			const startDateFormatted = item.startDate
				? item.startDate.toLocaleDateString('en-US', {
					year: 'numeric',
					month: 'long',
					day: 'numeric',
				})
				: '';
			const endDateFormatted = item.endDate
				? item.endDate.toLocaleDateString('en-US', {
					year: 'numeric',
					month: 'long',
					day: 'numeric',
				})
				: '';

			setDisplayedStartingDateRange(startDateFormatted);
			setDisplayedEndingDateRange(endDateFormatted);

			
			
		});
	}, [state]);


	return (
		<div className='flex flex-col ml-[30px] max-sm:ml-2 md:ml-0 md:px-10 max-sm:px-3 lg:mt-0 md:-mt-20 max-sm:-mt-20 bg-white mb-10 '>
			<div className='fixed bg-white w-full h-fit z-10'>
				<div className='flex w-[90%] flex-row lg:justify-start max-sm:justify-start md:justify-center items-center gap-4'>
					<div className='flex'>
						{/* {image && <img src={image} className='h-full'/>} */}
						{/* {lowerSmViewPort ? 
							<img src={appImage ? appImage : IMAGES.PlaceholderIcon} className='h-[110px] w-[150px] pr-10'/>
							:
							<img src={appImage ? appImage : IMAGES.PlaceholderIcon} className='h-[210px] w-[250px] pr-10'/>
						} */}

						<img src={appImage ? appImage : IMAGES.PlaceholderIcon} className='object-contain h-[6rem] w-[6rem]' />
					</div>

					<div className='flex flex-col'>
						<div>
							<p className='text-[#464255] text-base mt-[5px] font-bold'>{appName}</p>
						</div>

						<div>
							<p className='text-[#464255] text-sm mt-[5px]'>Dashboard Analytics</p>
						</div>
					</div>

					<div className='flex flex-col'>
						{/* <span>Select a Date Range</span> */}
						{/* <button className='fixed mt-[200px] border h-[50px] w-[200px]'> aa</button> */}
					</div>
				</div>
			</div>

			<div className='flex w-full mt-[7rem] pb-6 justify-end md:justify-start pr-6'>
				<button className='flex flex-row border px-2 py-1 hover:bg-[#FFF8F4]' onClick={() => showDateRangeButtonClick()}>
					<img src={IMAGES.DashboardTelemetryCalendarIcon} className='py-[3.3px]' />
					<span className='px-2'>
						{displayedStartingDateRange + ' - ' + displayedEndingDateRange}
					</span>
					<ArrowDropDownSharpIcon />
				</button> 
				<Button className='border-[#E0781D] hover:bg-[#E0781D]  hover:text-white  ml-2 px-[20px] py-1' onClick={ ()=> console.log('export data here!')}>
					<div className='flex'>
						<ArrowDownTrayIcon className='w-[16px] h-[16px] m-auto mr-1'/>
						<span>Export</span> 
					</div>
				</Button>
				
			</div>

			<div className={classNames(isNoteVisible ? '' : 'hidden', 'flex flex-col justify-center items-center bg-[#E1E1E1] p-2 w-[95%]')}>
				<div className='ml-auto'>
					<XMarkIcon className='w-3 h-3 cursor-pointer stroke-[0.25rem]' onClick={() => setIsNoteVisible(!isNoteVisible)} />
				</div>

				<div className='flex flex-row justify-center items-center w-full gap-3 px-3 text-sm mb-2'>
					<FontAwesomeIcon icon={faCircleInfo} className='w-6 h-6 text-[#3d4f4f]' />

					<p>
						It usually takes 24-48 hours for the data to reflect from the Google Analytics.
					</p>
				</div>

			</div>

			<div className={classNames(`${dateRangeToggled ? '' : 'hidden'}`)}>
				{/* This is where the component will be shown */}
				{
					!lowerSmViewPort ?
						<><DateRangePicker
							onChange={(item) => setState([item.selection])}
							shownDate={new Date()}
							moveRangeOnFirstSelection={false}
							minDate={addDays(new Date(), -600)}
							maxDate={addDays(new Date(), 0)}
							months={1}
							scroll={{ enabled: false }}
							ranges={state}
							direction={'vertical'}
							preventSnapRefocus={true}
							calendarFocus="backwards"
							className='w-[95%] border'
							rangeColors={['#E0781D',]}
							showDateDisplay={false}
						/>
						</>
						:
						<div className='w-full flex flex-col'>
							<DefinedRange
								onChange={item => setState([item.selection])}
								ranges={state}
								rangeColors={['#E0781D',]}
								className='rdrDefinedRanges'
							/>
							<DateRange
								className='w-[95%]'
								minDate={addDays(new Date(), -600)}
								maxDate={addDays(new Date(), 0)}
								editableDateInputs={true}
								onChange={(item) => setState([item.selection])}
								moveRangeOnFirstSelection={false}
								ranges={state}
								rangeColors={['#E0781D',]}
								showDateDisplay={false}
							/>

						</div>
				}
				<div className='border w-[95%] items-center flex justify-end'>
					<span className='p-2 cursor-pointer text-[#E0781D] text-xs hover:underline font-semibold' onClick={() => handleAppliedDateRange()}>
						Apply
					</span>
					<span className='p-2 cursor-pointer text-[#707070] text-xs hover:underline font-semibold' onClick={cancelDateRangeButtonClick}>
						Cancel
					</span>
				</div>
			</div>

			<div className='pt-[20px] grid grid-cols-2 gap-10 max-sm:grid-cols-1 max-sm:gap-7'>
				<div className='flex flex-col w-[90%] border border-gray-200 rounded-lg p-5 '>
					<p className='text-[#464255] text-base mt-[5px] font-bold'>Total Users</p>
					<p className='text-primary text-base mt-[5px] font-bold'>{totalUserValue}</p>
					<div className='flex flex-row gap-1'>
						{totalPercentageUserValue === '0%' || totalPercentageUserValue === '...%' ?

							<img />

							:

							<img src={IMAGES.TelemetryRateUpIcon} />

						}
						<p className='text-[#464255] text-base mt-[5px] font-bold'>{totalPercentageUserValue}</p>
						<p className='text-[#464255] text-sm mt-[5px]'>Today</p>
					</div>
				</div>

				<div className='flex flex-col w-[90%] border border-gray-200 rounded-lg p-5 '>
					<p className='text-[#464255] text-base mt-[5px] font-bold'>Active Users</p>
					<p className='text-primary text-base mt-[5px] font-bold'>{activeUserValue}</p>
					<div className='flex flex-row gap-1'>
						{activePercentageUserValue === '0%' || activePercentageUserValue === '...%' ?

							<img />

							:

							<img src={IMAGES.TelemetryRateUpIcon} />

						}
						<p className='text-[#464255] text-base mt-[5px] font-bold'>{activePercentageUserValue}</p>
						<p className='text-[#464255] text-sm mt-[5px]'>Today</p>
					</div>
				</div>

				<div className='flex flex-col w-[90%] border border-gray-200 rounded-lg p-5 '>
					<p className='text-[#464255] text-base mt-[5px] font-bold'>New Users</p>
					<p className='text-primary text-base mt-[5px] font-bold'>{newUserValue}</p>
					<div className='flex flex-row gap-1'>
						{newPercentageUserValue === '0%' || newPercentageUserValue === '...%' ?

							<img />

							:

							<img src={IMAGES.TelemetryRateUpIcon} />

						}
						<p className='text-[#464255] text-base mt-[5px] font-bold'>{newPercentageUserValue}</p>
						<p className='text-[#464255] text-sm mt-[5px]'>Today</p>
					</div>
				</div>

				{/* <div className='flex flex-col w-[90%] border border-gray-200 rounded-lg p-5 '>
            <p className='text-[#464255] text-base mt-[5px] font-bold'>User Retention</p>
            <p className='text-primary text-base mt-[5px] font-bold'>6,542</p>
            <div className='flex flex-row gap-1'>
              <img src={IMAGES.TelemetryRateUpIcon} />
              <p className='text-[#464255] text-base mt-[5px] font-bold'>4.6%</p>
              <p className='text-[#464255] text-sm mt-[5px]'>Today</p>
            </div>
          </div> */}

			</div>

			<div className='flex flex-col w-[90%] border border-gray-200 rounded-lg p-5 mt-10'>
				<div className='font-bold font-poppins'>
					<span>AUDIENCE BY COUNTRY</span>
				</div>
				<div className='grid grid-cols-3 w-full mt-6 text-[#969DAB] text-sm font-semibold font-poppins max-sm:text-xs'>
					<span>COUNTRY</span>
					<span>PAGE VIEWS</span>
					<span>BOUNCE RATE</span>
				</div>

				<hr className="border-t border-gray-300 my-2" />


				{countryAudience[0] ?
					<div className='grid grid-cols-3 w-full mt-2 max-sm:text-xs'>
						<div>
							{/* <img src={IMAGES.PaypalPay} /> */}
							<span>{countryAudience[0]}</span>
						</div>
						<span>{countryPageViews[0]}</span>
						<span>{countryBounceRate[0]}</span>
					</div>

					: <div />
				}

				{countryAudience[1] ?
					<div className='grid grid-cols-3 w-full mt-2 max-sm:text-xs'>
						<div>
							{/* <img src={IMAGES.PaypalPay} /> */}
							<span>{countryAudience[1]}</span>
						</div>
						<span>{countryPageViews[1]}</span>
						<span>{countryBounceRate[1]}</span>
					</div>

					: <div />
				}

				{countryAudience[2] ?
					<div className='grid grid-cols-3 w-full mt-2 max-sm:text-xs'>
						<div>
							{/* <img src={IMAGES.PaypalPay} /> */}
							<span>{countryAudience[2]}</span>
						</div>
						<span>{countryPageViews[2]}</span>
						<span>{countryBounceRate[2]}</span>
					</div>

					: <div />
				}

				{countryAudience[3] ?
					<div className='grid grid-cols-3 w-full mt-2 max-sm:text-xs'>
						<div>
							{/* <img src={IMAGES.PaypalPay} /> */}
							<span>{countryAudience[3]}</span>
						</div>
						<span>{countryPageViews[3]}</span>
						<span>{countryBounceRate[3]}</span>
					</div>

					: <div />
				}

				{countryAudience[4] ?
					<div className='grid grid-cols-3 w-full mt-2 max-sm:text-xs'>
						<div>
							{/* <img src={IMAGES.PaypalPay} /> */}
							<span>{countryAudience[4]}</span>
						</div>
						<span>{countryPageViews[4]}</span>
						<span>{countryBounceRate[4]}</span>
					</div>

					: <div />
				}

			</div>

			<div className='flex flex-col w-[90%] border border-gray-200 rounded-lg p-5 mt-10'>
				<div className='font-bold font-poppins'>
					<span>SESSIONS BY DEVICE</span>
				</div>
				<div className='grid grid-cols-3 w-full mt-6 text-[#969DAB] text-sm font-semibold font-poppins max-sm:text-xs'>
					<span>DEVICE</span>
					<span>VISITS</span>
					<span>AVG. TIME</span>
				</div>

				<hr className="border-t border-gray-300 my-2" />

				{sessionDevice[0] ?
					<div className='grid grid-cols-3 w-full gap-2 mt-2 max-sm:text-xs'>
						<span>{sessionDevice[0]}</span>
						<span>{sessionVisits[0]}</span>
						<span>{sessionAVGTime[0]} </span>
					</div>

					: <div />
				}

				{sessionDevice[1] ?
					<div className='grid grid-cols-3 w-full gap-2 mt-2 max-sm:text-xs'>
						<span>{sessionDevice[1]}</span>
						<span>{sessionVisits[1]}</span>
						<span>{sessionAVGTime[1]} </span>
					</div>

					: <div />
				}

				{sessionDevice[2] ?
					<div className='grid grid-cols-3 w-full gap-2 mt-2 max-sm:text-xs'>
						<span>{sessionDevice[2]}</span>
						<span>{sessionVisits[2]}</span>
						<span>{sessionAVGTime[2]} </span>
					</div>


					: <div />
				}
			</div>


			{/* <footer className='sticky bg-white bottom-0 h-[30px]'>
      </footer> */}

			<footer className='h-[30px] max-sm:h-[200px]'>
			</footer>
		</div>
	);
};

export default AppSideBarTelemetry;