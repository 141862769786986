import { axiosInstance } from 'services/axios';
import { EditNavigationSchema, TNavigation } from './schema';

export const getNavigationByIDLive = async (appID?: string , navID?: number) => {
	const response = await axiosInstance.get(`/organizations/apps/${appID}/navigations/${navID}?env=LIV`);
	return response.data as TNavigation;
};

export const getNavigationByID = async (appID?: string , navID?: number) => {
	const response = await axiosInstance.get(`/organizations/apps/${appID}/navigations/${navID}`);
	return response.data as TNavigation;
};

export const editNavigationByID = async (appID?: string, navID?: number, params?: EditNavigationSchema) => {
	const response = await axiosInstance.put(`/organizations/apps/${appID}/navigations/${navID}`, params);

	return response.data as TNavigation;
};