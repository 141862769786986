import { useAtom } from 'jotai';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FGallery } from 'store/CustomizeApp/Features/galleryAtom';

interface AlbumProps {
  coverImage: string;
}

export const Album: React.FC<AlbumProps> = ({ coverImage }) => {
	const [album]                             = useAtom(FGallery);
	const navigate                            = useNavigate();
	const handleClick = () => {
		// navigate('/photos');
	};

	return (
		<div>
			<div onClick={handleClick}>
				<img className='object-cover w-[8rem] h-[7rem]' src={coverImage} />
			</div>
		</div>
	);
};