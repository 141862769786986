import { GetContentFormType, GetContentSchema, GetContentFormResponse, DeleteContentFormResponse } from './schema';

import { axiosInstance } from 'services/axios';

export const getAllContentLive = async (appID?: string) => {
	const response = await axiosInstance.get(`/organizations/apps/${appID}/contents?env=LIV`);
    
	return response.data as GetContentFormResponse;
};

export const getAllContent = async (appID?: string) => {
	const response = await axiosInstance.get(`/organizations/apps/${appID}/contents`);
    
	return response.data as GetContentFormResponse;
};

export const createContent= async (appID?: string, data?: GetContentFormType) => {
	const validatedContent = GetContentSchema.parse(data);
	const response = await axiosInstance.post(`/organizations/apps/${appID}/contents`, validatedContent);
	return response.data as GetContentFormResponse;
};

export const editContentByAppID = async (appID?: string, contentID?: number, param?: GetContentFormType) => {
	const response = await axiosInstance.put(`/organizations/apps/${appID}/contents/${contentID}`, param);
	return response.data as GetContentFormResponse;
};

export const deleteContentById = async (appID?: string, contentID?: number) => {
	const response = await axiosInstance.delete(`/organizations/apps/${appID}/contents/${contentID}`);
	return response.data;
};