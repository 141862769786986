import Favicon from './Favicon';
import { IMAGES } from 'utility';
import Ogtag from './OgTags';
import React from 'react';
import Tooltip from 'components/Common/Tooltip';

const Images:React.FC = () => {

	return(
		<div className='bg-white rounded-lg my-8 mr-6 w-full max-sm:mb-20'>
			<div className='w-full px-10 rounded'>
				<div className='py-7 flex-row'>
					<div className='flex justify-between items-center'>
						<span className='text-[#707070] font-bold text-2xl block opacity-80'>Images</span>
						<div className='flex gap-3 items-baseline'>
							{/* -----------Video Tutorial and Help Icons------------ */}
							<Tooltip className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
								<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.CameraIcon} alt='camera icon' />
							</Tooltip>
							<Tooltip className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
								<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.InfoIcon} alt='info icon' />
							</Tooltip>
							{/* ---------------------------------------------------- */}
						</div>
					</div>
					<div className='flex flex-col mt-6 gap-5'>
						<Favicon/>
						<Ogtag/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Images;