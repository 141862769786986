import { axiosInstance } from 'services/axios';
import { EditSidebarSchema, TSidebar } from './schema';

export const getSidebarByIDLive = async (appID?: string , sidebarID?: number) => {
	const response = await axiosInstance.get(`/organizations/apps/${appID}/sidebar/${sidebarID}?env=LIV`);
	return response.data as TSidebar;
};

export const getSidebarByID = async (appID?: string , sidebarID?: number) => {
	const response = await axiosInstance.get(`/organizations/apps/${appID}/sidebar/${sidebarID}`);
	return response.data as TSidebar;
};

export const editSideBarByID = async (appID?: string, sidebarID?: number, params?: EditSidebarSchema) => {
	const response = await axiosInstance.put(`/organizations/apps/${appID}/sidebar/${sidebarID}`, params);

	return response.data as TSidebar;
};