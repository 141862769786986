import { PencilIcon } from '@heroicons/react/24/solid';
import { ContentModal } from 'components/Common/Modals/ContentModal';
import { DeleteModal } from 'components/Common/Modals/DeleteModal';
import Editor from 'components/Common/TextEditor';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React, { useEffect, useRef, useState } from 'react';
import { SFeaturesTab } from 'store/CustomizeApp/Features';
import { FContentError, FShowContentErrorModal, FContents, FContentErrorDescription, IContent } from 'store/CustomizeApp/Features/contentAtom';
import { IMAGES, classNames } from 'utility';
import Tooltip from 'components/Common/Tooltip';

const Content = () => {
	const [isEditable, setIsEditable] 							= useState<boolean>(false);
	const [showIconSelectionModal, setShowIconSelectionModal] 	= useState<boolean>(false);
	const [showDeleteContentModal, setShowDeleteContentModal] 	= useState<boolean>(false);
	const [editorHeight, setEditorHeight] 						= useState<number>(900);
	
	const [featTab, setFeatTab] 								= useAtom(SFeaturesTab);
	const [contents, setContents] 								= useAtom(FContents);
	const [error, setError] 									= useAtom(FContentError);
	const contentErrorModalShow 								= useAtomValue(FShowContentErrorModal);
	const setErrorDescription									= useSetAtom(FContentErrorDescription);
	
	const index = featTab.match(/\d+/);
	const [currentContentIndex, setCurrentContentIndex] = useState<number>(0);
	
	const currentContent = contents[currentContentIndex];

	const [value, setValue] 									= useState(currentContent ? currentContent.description : '');

	const inputRef = useRef<HTMLInputElement>(null);

	const activeContents = contents.filter((content) => content.toDelete === false);

	const handleEditClick = () => {
		setIsEditable(true);
		if (inputRef.current) {
			inputRef.current.focus();
		}
	};
	
	const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (inputRef.current) {
			inputRef.current.focus();
		} 

		const value = e.target.value;

		const updatedContents = [...contents];

		updatedContents[currentContentIndex].title = value;

		setContents(updatedContents);

		if (currentContent) {
			if (currentContent.title.length < 1) {
				setError('Content Title Must Not Be Empty');
			} else {
				setError('');
				setErrorDescription('');
			}
		}
	};

	const handleEditTitleBlur = () => {
		const updatedContents = [...contents];

		updatedContents[currentContentIndex].title = updatedContents[currentContentIndex].title.trim();

		setContents(updatedContents);

		if (currentContent) {
			if (currentContent.title.length < 1) {
				setError('Content Title Must Not Be Empty');
				setErrorDescription('Please give your content a title first before proceeding to other customization section.');
			} else {
				setError('');
				setErrorDescription('');
				setIsEditable(false);
			}
		}
	};

	const handleShowIconSelectionModal = () => {
		setShowIconSelectionModal(!showIconSelectionModal);
	};

	const handleShowDeleteContentModal = () => {
		setShowDeleteContentModal(!showDeleteContentModal);
	};

	const handleSelectIcon = (icon : string) => {
		const updatedContents = [...contents];

		updatedContents[currentContentIndex].icon = icon;

		setContents(updatedContents);
		setShowIconSelectionModal(!showIconSelectionModal);
	};

	const handleDeleteContent = () => {
		const updatedContents = [...contents];

		updatedContents[currentContentIndex].toDelete = true;

		const toDelete = updatedContents.splice(currentContentIndex, 1)[0];
		updatedContents.push(toDelete);

		setContents(updatedContents);
		setShowDeleteContentModal(false);

		if (
			updatedContents[0].toDelete === true ||
			updatedContents[currentContentIndex].toDelete === true
		) {
			setFeatTab('Privacy and Policy');
		}
	};
	
	useEffect(() => {
		setTimeout(() => {
			const quill = document.querySelector('.ql-container');
			const resizeObserver = new ResizeObserver(entries => {
				if (entries.length > 0) {
					const editorHeight = entries[0].contentRect.height;
					if (editorHeight > 0) setEditorHeight(editorHeight);
					else setEditorHeight(900);
				}
			});
			if(quill) resizeObserver.observe(quill);

			return () => {
				if(quill) resizeObserver.unobserve(quill);
			};
		}, 250);
	}, [editorHeight]);

	useEffect(() => {
		if (currentContent) {
			const updatedContents = [...contents];
		
			updatedContents[currentContentIndex].description = value;
	
			setContents(updatedContents);
		}
	}, [value]);

	useEffect(() => {
		if (currentContent) {	
			setValue(currentContent.description);

			if (currentContent.title.length < 1) {
				setError('Content Title Must Not Be Empty');
			} else {
				setError('');
				setErrorDescription('');
			}
		}
	}, [currentContent]);

	useEffect(() => {
		index && setCurrentContentIndex(+index);
	}, [index]);

	useEffect(() => {
		if (contentErrorModalShow && error && inputRef.current) {
			inputRef.current.focus();
		} 
	}, [contentErrorModalShow]);

	useEffect(() => {
		if (currentContent) {
			if (currentContent.title.length < 1) {
				setError('Content Title Must Not Be Empty');
				setErrorDescription('Please give your content a title first before proceeding to other customization section.');
			}
		}
	}, [error]);

	return (
		<div className='relative bg-white w-full rounded-lg my-8'>
			<div className='bg-white px-3 mx-3 rounded max-sm:pb-[270px]'>
				<div className='py-5 flex-row'>
					<div className='flex justify-center items-start md:items-center py-2 gap-4 w-full'>
						{
							<div className="flex flex-col md:flex-row justify-between md:items-center w-full">
								<div className='w-full'>
									<label>
										{
											(isEditable || (error === 'Content Title Must Not Be Empty')) ? (
												<input
													type='text'
													value={currentContent && currentContent.title}
													onChange={(e) => handleTitleChange(e)}
													onBlur={() => handleEditTitleBlur()}
													ref={inputRef}
													maxLength={20}
													pattern='[a-zA-Z\s&\d]'
													className={classNames(
														(error === 'Content Title Must Not Be Empty') ? 'border-2 border-red-500 outline-red-500' : 'outline-primary',
														'font-semibold text-[25px] text-[#464255] pl-2 w-full'
													)}
												/>
											) : (
												<div className='flex flex-row justify-between w-full'>
													<div className='flex flex-row items-center'>
														<span className='font-bold text-2xl text-[#707070]'>
															{currentContent && currentContent.title}
														</span>
														
														<PencilIcon
															className='w-4 ml-1 text-primary cursor-pointer'
															onClick={() => handleEditClick()}
														/>
													</div>
													<div className='flex flex-row gap-x-2'>
														<Tooltip className='px-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'At least one content must exist'} isVisible={activeContents.length === 1}>
															{activeContents.length !== 1 && <div
																className={classNames(
																	activeContents.length === 1 ? 'text-orange-300 cursor-not-allowed' : 'text-primary',
																	'mt-1 cursor-pointer'
																)}
																onClick={() => handleShowDeleteContentModal()}
															>
																<i className='fas fa-trash fa-md pr-2'></i>
																<span className='text-sm mt-1 pr-3 max-sm:hidden'>
																Delete {currentContent && currentContent.title}
																</span>
															</div>}
															
														</Tooltip>
															
														<div className='flex justify-between items-center w-fit shrink-0 gap-3 mt-0'>
															{/* -----------Video Tutorial and Help Icons------------ */}
															<Tooltip className='px-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
																<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.CameraIcon} alt='camera icon' />
															</Tooltip>
															<Tooltip className='px-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
																<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.InfoIcon} alt='info icon' />
															</Tooltip>
															{/* ---------------------------------------------------- */}
														</div>
													</div>
												</div>
											)}
									</label>

									<p className={classNames(
										(error === 'Content Title Must Not Be Empty') ? 'visible' : 'invisible',
										'my-1 text-xs text-red-500'
									)}>Content title must not be empty.</p>
								</div>

								
							</div>
						}
						
						{/* <div className='flex justify-between w-fit shrink-0 gap-2 items-center mt-2 md:mt-0'> */}
						{/* -----------Video Tutorial and Help Icons------------ */}
						{/* <Tooltip className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
								<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.CameraIcon} alt='camera icon' />
							</Tooltip>
							<Tooltip className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
								<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.InfoIcon} alt='info icon' />
							</Tooltip> */}
						{/* ---------------------------------------------------- */}
						{/* </div> */}
					</div>

					<div
						onClick={() => handleShowIconSelectionModal()}
						className='group relative w-fit'
					>
						<button className='relative flex justify-center items-center border border-slate-300 h-20 w-20 p-4 rounded-xl cursor-pointer group-hover:bg-gray-500 mt-4'>
							<p className='absolute flex justify-center items-center text-center text-sm font-semibold leading-4 text-white opacity-0 group-hover:opacity-100'>Change Icon</p>

							<div className='mt-[5px]'>
								<i className={`${currentContent && currentContent.icon} text-primary fa-2xl`}></i>
							</div>
						</button>
						
						<div className='absolute flex justify-center items-center w-6 h-6 p-1 text-primary bg-white rounded-full border bg-primary cursor-pointer -right-2 -top-2'>
							<PencilIcon />
						</div>
					</div>

					<div className='flex-row py-5'>
						<div className='flex-row w-full py-2 xl:w-[58.5rem] 2xl:w-[68rem] 3xl:w-full'>
							<div className='w-full h-[22rem] mb-10 py-4 sm:h-[21rem] md:h-[19rem] lg:h-[19rem]' style={{height: editorHeight}}>
								<Editor 
									value={value} 
									setValue={setValue} 
									label='Description'
								/>
							</div>
						</div>
					</div>

				</div>
			</div>
			<ContentModal isContentModalShow={showIconSelectionModal} setContentModalShow={handleShowIconSelectionModal} onClickCancel={handleShowIconSelectionModal} onClick={handleSelectIcon} />
			<DeleteModal isDeleteModalShow={showDeleteContentModal} setDeleteModalShow={handleShowDeleteContentModal} onClickCancel={handleShowDeleteContentModal} onClickDelete={handleDeleteContent} type='content'/>
		</div>
	);
};

export default Content;