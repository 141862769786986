import { axiosInstance } from 'services/axios';
import { createAppSchema, CreateAppType } from './schema';
import { useMutation } from 'react-query';

export const createAppApi = () => {
	const addFormData = async (formData: CreateAppType) => {
		const validatedFormData = createAppSchema.parse(formData);
		const response = await axiosInstance.post('/organizations/apps', validatedFormData);

		return response.data;
	};
	return useMutation(addFormData);
};